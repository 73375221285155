import deep from 'deep-get-set';
import moment from 'moment';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const displayDateFromJson = (json = {}) => ({
	shortDate: deep(json, 'ShortDate'),
	iso8601: deep(json, 'Iso8601'),
	relativeTime: deep(json, 'RelativeTime'),
	shortRelativeTime: deep(json, 'ShortRelativeTime'),
	day: deep(json, 'Day'),
	monthDay: deep(json, 'MonthDay'),
	monthDayYear: deep(json, 'MonthDayYear'),
});

export const displayDateSchema = new schema.Entity(ENTITIES.DISPLAY_DATE);

export const normalizeDisplayDate = (displayDate) => normalize(displayDate, displayDateSchema);

export const getDateForInput = (displayDate) => {
	const date = displayDate && displayDate.iso8601;

	return date ? moment(date).format('YYYY-MM-DD') : '';
};
