import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.LANDING_PAGE], action) => {
	const { landingPage, type } = action;

	switch (type) {
		case ACTIONS.RECEIVE_LANDING_PAGE:
			return {
				...landingPage
			};

		default:
			return state;
	}
};
