import React from 'react';

import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

const IconLink = (props) => (
	<Svg
		{...props}
		name="link"
		viewBox="0 0 24 24"
	>
		<path
			opacity="0.5"
			d="M11.8585 16.6516L13.3637 15.1272C11.8699 15.0143 10.8966 14.5626 10.161 13.8286C8.1806 11.8525 8.19191 9.05205 10.1497 7.09851L13.839 3.41728C15.8194 1.45245 18.6033 1.44116 20.5838 3.41728C22.5642 5.3934 22.5415 8.18256 20.5838 10.1361L18.3657 12.3493C18.6825 13.072 18.7504 13.9077 18.6373 14.6417L21.9644 11.3331C24.6691 8.62295 24.6917 4.79492 21.9531 2.05093C19.2031 -0.693052 15.3554 -0.670467 12.6394 2.03964L8.78039 5.90155C6.06436 8.61166 6.04173 12.451 8.7917 15.1837C9.50466 15.8951 10.41 16.4032 11.8585 16.6516ZM12.1415 7.34694L10.6363 8.87138C12.1301 8.99559 13.1034 9.43598 13.839 10.17C15.8194 12.1461 15.8081 14.9465 13.8503 16.9001L10.1497 20.5813C8.1806 22.5461 5.39668 22.5574 3.41625 20.5926C1.43581 18.6052 1.44713 15.8273 3.41625 13.8625L5.63433 11.6492C5.31746 10.9378 5.23824 10.0909 5.36273 9.35694L2.0356 12.6655C-0.6691 15.3756 -0.691734 19.215 2.04692 21.9477C4.79689 24.6916 8.64458 24.6691 11.3493 21.9702L15.2196 18.097C17.9356 15.3869 17.9583 11.5476 15.2083 8.81492C14.4953 8.10351 13.59 7.59537 12.1415 7.34694Z"
			fill={props.color}
		/>
	</Svg>
);

IconLink.displayName = 'IconLink';

IconLink.propTypes = iconProps;

IconLink.defaultProps = defaultIconProps;

export default IconLink;