import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconAndroid = (props) => (
	<Svg
		{...props}
		name="android"
		viewBox="0 0 50 60"
	>
		<path
			fill="inherit" d="M41.287,45.95c0,1.074-0.873,1.945-1.95,1.945H10.663c-1.077,0-1.951-0.871-1.951-1.945V20.446 c0-1.073,0.874-1.943,1.951-1.943h28.674c1.077,0,1.95,0.87,1.95,1.943V45.95z"
		/>
			<rect x="8.682" y="18.502" fill="inherit" width="32.637" height="13.397" />
			<path fill="inherit" d="M50,36.41c0,1.911-1.553,3.461-3.471,3.461c-1.916,0-3.471-1.55-3.471-3.461V20.856
			c0-1.912,1.555-3.461,3.471-3.461c1.918,0,3.471,1.549,3.471,3.461V36.41z M6.941,36.41c0,1.911-1.553,3.461-3.471,3.461
			C1.555,39.871,0,38.321,0,36.41V20.856c0-1.912,1.555-3.461,3.471-3.461c1.918,0,3.471,1.549,3.471,3.461V36.41z M25,2.86
			C9.177,2.813,8.816,16.586,8.816,16.586h32.367C41.184,16.586,40.673,2.91,25,2.86z M17.896,11.498c-1.076,0-1.947-0.87-1.947-1.942
			s0.873-1.943,1.947-1.943c1.076,0,1.949,0.871,1.949,1.943S18.973,11.498,17.896,11.498z M32.104,11.498
			c-1.076,0-1.949-0.87-1.949-1.942s0.873-1.943,1.949-1.943c1.074,0,1.947,0.871,1.947,1.943S33.178,11.498,32.104,11.498z
			M17.361,4.712c0.057,0.081,0.234,0.056,0.396-0.06C17.916,4.542,18,4.382,17.943,4.302L14.92,0.047
			c-0.059-0.082-0.234-0.055-0.395,0.059s-0.243,0.271-0.187,0.353L17.361,4.712L17.361,4.712z M32.873,4.382
			C32.762,4.369,32.65,4.233,32.2,4.101l2.88-4.053c0.059-0.082,0.236-0.055,0.395,0.059c0.161,0.113,0.246,0.271,0.187,0.353
			L32.873,4.382z M35.169,56.54c0,1.912-1.554,3.461-3.471,3.461s-3.472-1.549-3.472-3.461V40.985c0-1.91,1.555-3.461,3.472-3.461
			s3.471,1.551,3.471,3.461V56.54z M21.771,56.54c0,1.912-1.554,3.461-3.471,3.461s-3.471-1.549-3.471-3.461V40.985
			c0-1.91,1.554-3.461,3.471-3.461s3.471,1.551,3.471,3.461V56.54z"
		/>
	</Svg>
);

IconAndroid.displayName = 'IconAndroid';

IconAndroid.propTypes = iconProps;

IconAndroid.defaultProps = defaultIconProps;

export default IconAndroid;
