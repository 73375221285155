import React from 'react';
import PropTypes from 'prop-types';

import { convertActionUri, convertImageUrl } from '../DuploBlock.helpers';
import Wrapper from '../Wrapper/Wrapper';
import MaybeLinkWrap from '../../MaybeLinkWrap/MaybeLinkWrap';
import './Image.css';

const Image = ({
	actionUri,
	analyticEvent,
	analyticObj,
	image,
}) => (
	<Wrapper className="duplo-block duplo-block-image">
		<MaybeLinkWrap
			analyticEvent={analyticEvent}
			analyticObj={analyticObj}
			path={actionUri && convertActionUri(actionUri)}
		>
			<img
				src={convertImageUrl(image)}
				alt=""
			/>
		</MaybeLinkWrap>
	</Wrapper>
);

Image.propTypes = {
	actionUri: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	image: PropTypes.string,
};

export default Image;
