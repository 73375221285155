import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AccordianHeader from './AccordianHeader';
import './AccordianItem.css';

class AccordianItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
		};

		this.toggleAccordian = this.toggleAccordian.bind(this);
	}

	toggleAccordian() {
		const isOpen = !this.state.isOpen;

		this.setState({ isOpen });

		if (isOpen) {
			this.props.onOpen && this.props.onOpen();
		} else {
			this.props.onClose && this.props.onClose();
		}
	}

	render() {
		return (
			<li className="accordian-item">
				<AccordianHeader
					isOpen={this.state.isOpen}
					onClick={this.toggleAccordian}
					title={this.props.title}
				/>

				{this.state.isOpen && (
					<div className="accordian-content">
						{this.props.renderContent()}
					</div>
				)}
			</li>
		);
	}
}

AccordianItem.propTypes = {
	onClose: PropTypes.func,
	onOpen: PropTypes.func,
	renderContent: PropTypes.func,
	title: PropTypes.string,
};

AccordianItem.displayName = 'AccordianItem';

export default AccordianItem;
