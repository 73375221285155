import { objectHasEveryProperty } from '@chtbks/helpers';

/**
 * Creates a Modal object.
 *
 * @param {object} modal The Modal object.
 * @param {string} modal.id The id of the modal.
 * @param {object} modal.data The data for the modal. (Optional)
 */
export const modalModel = ({id, data}) => ({ id, data });

export const isModal = (possibleAppString) => objectHasEveryProperty(possibleAppString, ['id']);

