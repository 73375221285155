import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import tornBookImg from '../../../../assets/img/torn-book.png';

const TornBookImage = ({ className }) => (
	// eslint-disable-next-line jsx-a11y/img-redundant-alt
	<img
		className={classnames(['TornBookImage', className])}
		src={tornBookImg}
		alt="Torn photo book"
	/>
);

TornBookImage.displayName = 'TornBookImage';

TornBookImage.propTypes = {
	className: PropTypes.string,
};

export default TornBookImage;
