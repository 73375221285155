import deep from 'deep-get-set';
import { omit } from 'lodash';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { isReceiveEntitiesAction } from '../actions/entity.actions';
import { ENTITIES } from '../const/entities/entities';
import { REDUCERS } from '../const/reducers.const';
import { receiveEntityObjects } from '../helpers/entity/entity.helpers';
import { momentFromJson } from '../model/moment.model';

export default (state = defaultState[REDUCERS.MOMENT], action) => {
	const { type } = action;

	if (isReceiveEntitiesAction(type)) {
		const { data } = action;

		return {
			...state,
			...receiveEntityObjects(state, data, ENTITIES.MOMENT)
		};
	}

	switch (type) {
		case ACTIONS.MOMENTS_DELETE: {
			const allMoments = deep(state, `${ENTITIES.MOMENT}`) || {};

			const toDeleteIds = deep(action, 'momentIds') || [];
			const updatedMoments = omit(allMoments, toDeleteIds);

			return {
				...state,
				[ENTITIES.MOMENT]: updatedMoments,
			};
		}

		case ACTIONS.MOMENTS_RECEIVE: {
			const receivedMoments = deep(action, 'moments') || [];
			const existingMoments = deep(state, `${ENTITIES.MOMENT}`) || {};
			const updatedMoments = receivedMoments
				.map(momentFromJson)
				.reduce((momentsMap, moment) => {
					momentsMap[moment.id] = moment;

					return momentsMap;
				}, {...existingMoments});

			return {
				...state,
				[ENTITIES.MOMENT]: updatedMoments
			};
		}

		default:
			return state;
	}
};