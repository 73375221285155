import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './Form.css';
import { FormError, ResetButton, SubmitButton } from './FormComponents';
import { FormContext } from './FormContext';

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formFields: {},
			invalid: false,
			sending: false
		};

		this.handleReset = this.handleReset.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateFormField = this.updateFormField.bind(this);
	}

	handleReset(e) {
		e.preventDefault();
		const { onReset } = this.props;
		this.setState(
			{
				formFields: {},
				invalid: false,
				sending: false
			},
			() => onReset && onReset()
		);
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ sending: true });
		const { formFields } = this.state;
		const promises = Object.keys(formFields).map((name) => {
			const field = formFields[name];

			// Set dirty flag on field to trigger validators
			return field.setDirty ? new Promise((resolve) => field.setDirty(resolve)) : Promise.resolve();
		});

		Promise.all(promises)
			.then(() => {
				const invalid = !this.isValid();
				this.setState({ invalid });
				if (invalid) {
					return;
				}

				const { formFields: currentFormFields } = this.state;
				const values = Object.keys(currentFormFields).reduce((vals, name) => {
					const field = currentFormFields[name];

					// Add to our values object to submit
					return {
						...vals,
						[name]: field.value
					};
				}, {});

				const { onSubmit } = this.props;

				return onSubmit && onSubmit(values);
			})
			.finally(() => this.setState({ sending: false }));
	}

	isValid() {
		const { formFields } = this.state;

		return Object.keys(formFields).reduce((valid, name) => valid && !formFields[name].invalid, true);
	}

	updateFormField(name, formField) {
		this.setState(({ formFields }) => ({
			formFields: {
				...formFields,
				[name]: {
					...formFields[name],
					...formField
				}
			},
			invalid: false
		}));
	}

	render() {
		const {
			backgroundColor,
			children,
			name,
			resetBackgroundColor,
			resetColor,
			resetText,
			submitBackgroundColor,
			submitColor,
			submitted,
			submitText,
			success,
			error,
			errorMessage,
			validationMessage,
			validationClassName
		} = this.props;
		const { invalid, sending } = this.state;
		const errorId = name ? `${name}-error` : `${Date.now().toString(16)}-error`;
		const context = {
			submitted,
			success,
			updateFormField: this.updateFormField
		};

		return (
			<FormContext.Provider value={context}>
				<form
					className="Form grid-container fluid"
					onSubmit={this.handleSubmit}
					aria-describedby={errorId}
					style={{ backgroundColor }}
				>
					{children}
					<div className="grid-x">
						<div className="cell small-12 submit-button">
							<SubmitButton
								backgroundColor={submitBackgroundColor}
								color={submitColor}
								sending={sending}
								submitted={submitted}
								success={success}
								text={submitText}
							/>
							<ResetButton
								backgroundColor={resetBackgroundColor}
								color={resetColor}
								onClick={this.handleReset}
								submitted={submitted}
								success={success}
								text={resetText}
							/>
						</div>
					</div>
					<FormError
						error={error}
						errorId={errorId}
						errorMessage={errorMessage}
						invalid={invalid}
						submitted={submitted}
						validationClassName={validationClassName}
						validationMessage={validationMessage}
					/>
				</form>
			</FormContext.Provider>
		);
	}
}

Form.displayName = 'Form';

Form.propTypes = {
	backgroundColor: PropTypes.string,
	children: PropTypes.node,
	error: PropTypes.bool,
	errorMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	name: PropTypes.string,
	onReset: PropTypes.func,
	onSubmit: PropTypes.func,
	resetBackgroundColor: PropTypes.string,
	resetColor: PropTypes.string,
	resetText: PropTypes.string,
	submitBackgroundColor: PropTypes.string,
	submitColor: PropTypes.string,
	submitted: PropTypes.bool,
	submitText: PropTypes.string,
	success: PropTypes.bool,
	validationClassName: PropTypes.string,
	validationMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Form;
