import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getBgProps, getBoxProps } from './BackgroundWithBox.helpers';
import Button from './Button';
import Caption from './Caption';
import Content from './Content';
import Icon from './Icon';
import PreContent from './PreContent';
import Title from './Title';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import './BackgroundWithBox.css';

export const BackgroundWithBox = ({
	action,
	analyticEvent,
	backgroundColor,
	backgroundImage,
	buttonStyle,
	buttonText,
	caption,
	content,
	iconImage,
	iconImageMargin,
	iconImageText,
	isInset,
	linkElement,
	linkElementPathAttr,
	noBox,
	onClick,
	path,
	preContent,
	side,
	textAlign,
	textColor,
	title
}) => {
	const bgProps = getBgProps(backgroundColor, backgroundImage, noBox);
	const boxProps = getBoxProps(side, !noBox && backgroundColor, textAlign, textColor);
	const className = classnames('BackgroundWithBox align-middle', {
		'text-only': noBox
	});

	return (
		<PageFlowContainer isInset={isInset}>
			<div className={className} {...bgProps}>
				<div className="box-wrapper">
					<div className="grid-x">
						<div {...boxProps}>
							<Icon url={iconImage} text={iconImageText} margin={iconImageMargin} wrapperElem={title ? 'div' : 'h2'} />
							<PreContent preContent={preContent} />
							<Title title={title} />
							<Content content={content} />
							<Button
								action={action}
								analyticEvent={analyticEvent}
								buttonStyle={buttonStyle}
								buttonText={buttonText}
								linkElement={linkElement}
								linkElementPathAttr={linkElementPathAttr}
								onClick={onClick}
								path={path}
							/>
							<Caption caption={caption} />
						</div>
					</div>
				</div>
			</div>
		</PageFlowContainer>
	);
};

BackgroundWithBox.displayName = 'BackgroundWithBox';

BackgroundWithBox.propTypes = {
	action: PropTypes.string,
	analyticEvent: PropTypes.func,
	backgroundColor: PropTypes.string,
	backgroundImage: PropTypes.string,
	buttonStyle: PropTypes.string,
	buttonText: PropTypes.string,
	caption: PropTypes.node,
	content: PropTypes.node,
	iconImage: PropTypes.string,
	iconImageMargin: PropTypes.string,
	iconImageText: PropTypes.string,
	isInset: PropTypes.bool,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	noBox: PropTypes.bool,
	onClick: PropTypes.func,
	path: PropTypes.string,
	preContent: PropTypes.string,
	side: PropTypes.string,
	textAlign: PropTypes.string,
	textColor: PropTypes.string,
	title: PropTypes.string
};

BackgroundWithBox.defaultProps = {
	isInset: false,
	noBox: false,
	side: 'left'
};

export default BackgroundWithBox;
