import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLayoutCaption = (props) => (
	<Svg
		{...props}
		name="layout-caption"
		viewBox="0 0 30 30"
	>
		<rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="inherit" fill="none" />
		<rect x="2" y="7" width="26" height="16" rx="2" fill="inherit" />
	</Svg>

);

IconLayoutCaption.displayName = 'IconLayoutCaption';

IconLayoutCaption.propTypes = iconProps;

IconLayoutCaption.defaultProps = defaultIconProps;

export default IconLayoutCaption;
