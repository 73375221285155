import React from 'react';
import PropTypes from 'prop-types';

import Box from '../../Box/Box';

const BoxBottom = ({
	children,
	className,
}) => (
	<Box
		className={className}
		isRadiusTopLeftVisible={false}
		isRadiusTopRightVisible={false}
	>
		{children}
	</Box>
);

BoxBottom.displayName = 'BoxBottom';

BoxBottom.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default BoxBottom