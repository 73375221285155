import React, { Component } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import Hammer from 'hammerjs';

import CarouselItem from './CarouselItem';
import { getScrollIncrements } from './SelectableCarousel.helpers';
import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import { Provider } from '../../helpers/context';
import './SelectableCarousel.css';

class SelectableCarousel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			increments: [0],
			index: 0,
			context: {
				width: 0,
				scrollLeft: 0
			}
		};

		this.carouselRef = React.createRef();

		this.handleClickLeft = this.handleClickLeft.bind(this);
		this.handleClickRight = this.handleClickRight.bind(this);
		this.setIncrements = this.setIncrements.bind(this);
	}

	componentDidMount() {
		this.setIncrements();

		// Configure Hammer for touch (swipe) events
		this.hammer = new Hammer.Manager(this.carouselRef.current, {
			recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]]
		});
		this.hammer.on('swipeleft', this.handleClickRight);
		this.hammer.on('swiperight', this.handleClickLeft);

		// Add event listener for window resize to recalculate our scroll increments
		window.addEventListener('resize', this.setIncrements);
	}

	componentWillUnmount() {
		this.hammer.off('swipeleft', this.handleClickRight);
		this.hammer.off('swiperight', this.handleClickLeft);
		window.removeEventListener('resize', this.setIncrements);
	}

	handleClickLeft(e) {
		e.preventDefault();
		this.setState((prevState) => {
			const { index: currentIndex } = prevState;
			let index = currentIndex - 1;
			if (index < 0) {
				index = 0;
			}

			return { index };
		}, this.setScrollPosition);
	}

	handleClickRight(e) {
		e.preventDefault();
		this.setState((prevState) => {
			const { increments, index } = prevState;
			const incrementedIndex = index + 1;
			const updatedIndex = incrementedIndex >= increments.length ? index : index + 1;

			return { index: updatedIndex };
		}, this.setScrollPosition);
	}

	setIncrements() {
		const carousel = this.carouselRef.current;
		const { children } = this.props;
		const increments = getScrollIncrements(carousel.offsetWidth, carousel.scrollWidth, children.length);

		this.setState((prevState) => {
			const index = prevState.index > increments.length - 1 ? increments.length - 1 : prevState.index;
			const context = { width: carousel.offsetWidth, scrollLeft: carousel.scrollLeft };

			return { increments, index, context };
		});
	}

	setScrollPosition() {
		const carousel = this.carouselRef.current;
		const { increments, index } = this.state;
		const { duration = 500, easing = 'easeInOutSine' } = this.props;

		anime({
			targets: carousel,
			scrollLeft: increments[index],
			duration,
			easing,
			loop: false
		}).finished.then(() =>
			this.setState((prevState) => ({
				context: {
					...prevState.context,
					scrollLeft: increments[index]
				}
			}))
		);
	}

	render() {
		const { children } = this.props;
		const { context, increments, index } = this.state;
		const leftButton =
			index === 0 ? null : <div className="scroll-button scroll-button-left" onClick={this.handleClickLeft} />;
		const rightButton =
			index === increments.length - 1 ? null : (
				<div className="scroll-button scroll-button-right" onClick={this.handleClickRight} />
			);

		return (
			<PageFlowContainer
				className={'SelectableCarousel'}
				isInset={this.props.isInset}
				paddingTop={this.props.paddingTop}
				paddingBottom={this.props.paddingBottom}
			>
				<div className="selectable-carousel-inner">
					<Provider value={context}>
						<div ref={this.carouselRef} className="carousel-items">
							<div className="flex-container flex-dir-row">{children}</div>
						</div>
						{leftButton}
						{rightButton}
					</Provider>
				</div>
			</PageFlowContainer>
		);
	}
}

SelectableCarousel.displayName = 'SelectableCarousel';

SelectableCarousel.propTypes = {
	children: (props, propsName, componentName) => {
		let error = null;
		React.Children.forEach((prop, child) => {
			if (child.type !== CarouselItem) {
				error = new Error(`'${componentName}' children should be of type 'CarouselItem'`);
			}
		});

		return error;
	},
	duration: PropTypes.number,
	easing: PropTypes.string,
	isInset: PropTypes.bool,
	paddingTop: PropTypes.string,
	paddingBottom: PropTypes.string
};

SelectableCarousel.defaultProps = {
	isInset: false
};

export default SelectableCarousel;
