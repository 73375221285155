import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';
import deep from 'deep-get-set';

import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import './ImageSlider.css';

const defaultDelay = 3000;
const defaultDuration = 0;

class ImageSlider extends Component {
	slideInLeft;
	slideOutLeft;
	slideInRight;
	slideOutRight;
	timer;

	constructor(props) {
		super(props);

		const { children } = props;

		this.state = {
			current: 0,
			previous: children && children.length ? children.length - 1 : 0,
			forced: false,
			rotating: false
		};

		const { duration = defaultDuration } = props;

		this.slideInLeft = styled.img`
			animation: slidein-left ${duration}ms ease-in-out;
		`;

		this.slideOutLeft = styled.img`
			animation: slideout-left ${duration}ms ease-in-out;
		`;

		this.slideInRight = styled.img`
			animation: slidein-right ${duration}ms ease-in-out;
		`;

		this.slideOutRight = styled.img`
			animation: slideout-right ${duration}ms ease-in-out;
		`;

		this.next = this.next.bind(this);
	}

	componentDidMount() {
		const { children, delay = defaultDelay } = this.props;
		if (children && children.length) {
			this.timer = setTimeout(this.next, delay);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	getStyledImage(img, component, key) {
		const StyledImage = component;
		const { src, alt, onClick } = img.props;

		return <StyledImage key={key} src={src} alt={alt} onClick={onClick} className="slider-image" />;
	}

	goTo(index, forced) {
		// Clear the timer
		clearTimeout(this.timer);

		// Update our state with new current value and set previous to old current value
		this.setState((prevState) => ({
			current: index,
			previous: prevState.current,
			forced: !!forced,
			rotating: true
		}));

		// If forced (user clicked a button) don't start the timer again
		if (!forced) {
			const { delay = defaultDelay, duration = defaultDuration } = this.props;
			this.timer = setTimeout(this.next, delay + duration);
		}
	}

	handleButtonClick(index) {
		this.goTo(index, true);
	}

	next() {
		const { current } = this.state;
		const { children } = this.props;

		const index = (current + 1) % children.length;
		this.goTo(index);
	}

	renderImages(children, current, previous, forced, rotating) {
		if (!children) return null;

		// Single image
		if (!children.length || !rotating) {
			const child = children.length ? children[current] : children;
			const { src, alt, onClick } = child.props;

			return <img src={src} alt={alt} onClick={onClick} className="slider-image" />;
		}

		const roundingTheHorn = current === 0 && previous === children.length - 1;
		if (current > previous || (roundingTheHorn && !forced)) {
			return [
				this.getStyledImage(children[previous], this.slideOutLeft, `SliderImage-SlideOutLeft-${previous}`),
				this.getStyledImage(children[current], this.slideInLeft, `SliderImage-SlideInLeft-${current}`)
			];
		}

		return [
			this.getStyledImage(children[previous], this.slideOutRight, `SliderImage-SlideOutRight-${previous}`),
			this.getStyledImage(children[current], this.slideInRight, `SliderImage-SlideInRight-${current}`)
		];
	}

	render() {
		const { children, color } = this.props;
		const { current, previous, forced, rotating } = this.state;
		const firstChild = children && children.length ? children[0] : children;
		const { src: spacerSrc } = firstChild && firstChild.props;

		const spacer = spacerSrc ? <img className="slider-image-spacer" src={spacerSrc} alt="" /> : null;
		const sliderImages = this.renderImages(children, current, previous, forced, rotating);
		const buttons =
			React.Children.count(children) > 1
				? React.Children.map(children, (img, index) => {
						const {
							props: { src }
						} = img;

						return (
							<span
								key={`${src}-${index}-${current}`}
								onClick={this.handleButtonClick.bind(this, index)}
								className={'slider-button' + (index === current ? ' active' : '')}
								style={{ borderColor: color, backgroundColor: index === current ? color : 'tranparent' }}
							/>
						);
				  })
				: null;
		const buttonContainer = buttons ? <div className="slider-buttons">{buttons}</div> : null;
		const className = classnames('ImageSlider');

		return (
			<PageFlowContainer isInset={this.props.isInset}>
				<div className={className}>
					{spacer}
					{sliderImages}
					{buttonContainer}
				</div>
			</PageFlowContainer>
		);
	}
}

ImageSlider.displayName = 'ImageSlider';

ImageSlider.propTypes = {
	children: (props, propsName, componentName) => {
		let error = null;
		props.children.forEach((prop, index) => {
			if (deep(prop, 'type') !== 'img') {
				error = new Error(`'${componentName}' children should be of type 'img'`);
			}
		});

		return error;
	},
	color: PropTypes.string,
	delay: PropTypes.number,
	duration: PropTypes.number,
	isInset: PropTypes.bool
};

ImageSlider.defaultProps = {
	isInset: false
};

export default ImageSlider;
