import React from 'react';
import PropTypes from 'prop-types';

import bulletImg from './ellipse.png';
import './Bullet.css';

const Bullet = ({
	text,
}) => (
	<li className="bullet">
		<img
			alt="bullet"
			className="bullet-image"
			src={bulletImg}
		/>
		<p className="text">{text}</p>
	</li>
);

Bullet.propTypes = {
	text: PropTypes.string,
};

export default Bullet;
