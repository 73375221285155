import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLayoutCrop = (props) => (
	<Svg
		{...props}
		name="layout-crop"
		hasStroke={true}
		viewBox="0 0 30 30"
	>
		<rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="inherit" fill="none" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.5 20.6667H20.6667V22.5C20.6667 22.7761 20.4428 23 20.1667 23H19.1667V23C18.8905 23 18.6667 22.7761 18.6667 22.5V20.6667H10.8333V20.6667C10.0049 20.6667 9.33333 19.9951 9.33333 19.1667V11.3333H7.5V11.3333C7.22386 11.3333 7 11.1095 7 10.8333V9.83333V9.83333C7 9.55719 7.22386 9.33333 7.5 9.33333H9.33333V7.5V7.5C9.33333 7.22386 9.55719 7 9.83333 7H10.8333V7C11.1095 7 11.3333 7.22386 11.3333 7.5V18.5V18.5C11.3333 18.592 11.408 18.6667 11.5 18.6667H22.5V18.6667C22.7761 18.6667 23 18.8905 23 19.1667V20.1667V20.1667C23 20.4428 22.7761 20.6667 22.5 20.6667C22.5 20.6667 22.5 20.6667 22.5 20.6667H22.5ZM19.1667 9.33333V9.33333C19.9951 9.33333 20.6667 10.0049 20.6667 10.8333V17.8333C20.6667 17.9254 20.592 18 20.5 18H18.8333V18C18.7413 18 18.6667 17.9254 18.6667 17.8333V11.5C18.6667 11.408 18.592 11.3333 18.5 11.3333H12.1667V11.3333C12.0746 11.3333 12 11.2587 12 11.1667V9.5V9.5C12 9.40795 12.0746 9.33333 12.1667 9.33333H19.1667Z"
			fill="inherit"
		/>
	</Svg>
);

IconLayoutCrop.displayName = 'IconLayoutCrop';

IconLayoutCrop.propTypes = iconProps;

IconLayoutCrop.defaultProps = defaultIconProps;

export default IconLayoutCrop;
