import debug from 'debug';

export const perfLogger = debug('chtbks:perf');

export const appLogger = debug('chtbks:app');

// eslint-disable-next-line
export const perfHooks = (process.env.SERVER && perfLogger.enabled && eval('require("perf_hooks")')) || {
	performance: { clearMarks: () => {}, mark: () => {}, measure: () => {} }
};

export function clog(...args) {
	console.log(
	  `chtbks |`,
	  ...args.map(
		(arg) => typeof arg === 'object'
		  ? logJson(() => JSON.stringify(arg, null, 2))
		  // ? arg
		  : arg));
  }
  
  export function clogWarn(...args) {
	console.warn(
	  `chtbks |`,
	  ...args.map(
		(arg) => typeof arg === 'object'
		  ? logJson(() => JSON.stringify(arg, null, 2))
		  // ? arg
		  : arg));
  };
  
  export function clogError(...args) {
	console.error(
	  `chtbks |`,
	  ...args.map(
		(arg) => typeof arg === 'object'
		  ? logJson(() => JSON.stringify(arg, null, 2))
		  // ? arg
		  : arg));
  };
  
  function logJson(fn) {
	try {
	  return fn();
	} catch (e) {
	  return clog('Unable to parse JSON');
	}
  }
  