import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCaretRight = (props) => (
	<Svg
		{...props}
		name="caret-right"
		viewBox="0 0 24 24"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.6606 18.1713C10.3676 18.4646 9.89238 18.4649 9.59907 18.172C9.30577 17.879 9.30548 17.4037 9.59843 17.1104L14.7008 12.0142L9.59843 6.91794C9.30548 6.62464 9.30577 6.14938 9.59907 5.85643C9.89238 5.56348 10.3676 5.56376 10.6606 5.85707L16.294 11.4838C16.5869 11.7761 16.5874 12.2506 16.2951 12.5436L10.6606 18.1713Z"
			fill="inherit"
		/>
	</Svg>
);

IconCaretRight.displayName = 'IconCaretRight';

IconCaretRight.propTypes = iconProps;

IconCaretRight.defaultProps = defaultIconProps;

export default IconCaretRight;
