export const getYouTubeEmbedId = (url) => {
	if (!url) return null;

	// Extranct ID from end of short URL (e.g. https://youtu.be/mF2eKaOc3wo)
	let match = url.match(/^http[s]?:\/\/youtu\.be\/([a-zA-Z0-9]+)$/);
	if (match && match.length > 1) {
		return match[1];
	}
	// Extract ID from query string of long URL (e.g. https://www.youtube.com/watch?v=PTTs7ewuDY8)
	match = url.match(/^http[s]?:\/\/www\.youtube.com\/watch\?.*(?:v=([a-zA-Z0-9]+)).*/);
	if (match && match.length > 1) {
		return match[1];
	}

	return null;
};

export const getYouTubeEmbedUrl = (url) => {
	const id = getYouTubeEmbedId(url);

	return id ? `https://www.youtube.com/embed/${id}` : null;
};
