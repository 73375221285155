import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconGridOneActive = (props) => (
	<Svg
		{...props}
		name="grid-one-active"
		hasStroke={true}
		viewBox="0 0 14 14"
	>
		<rect width="6" height="6" rx="1" fill="inherit" stroke="none" />
		<rect x="8.5" y="0.5" width="5" height="5" rx="0.5" fill="none" stroke="inherit" />
		<rect x="0.5" y="8.5" width="5" height="5" rx="0.5" fill="none" stroke="inherit" />
		<rect x="8.5" y="8.5" width="5" height="5" rx="0.5" fill="none" stroke="inherit" />
	</Svg>
);

IconGridOneActive.displayName = 'IconGridOneActive';

IconGridOneActive.propTypes = iconProps;

IconGridOneActive.defaultProps = defaultIconProps;

export default IconGridOneActive;
