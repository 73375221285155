export default [
	{
		username: '@sincerely.milagav',
		image: 'https://cdn.chatbooks.com/mk/20-02-IG-series-PDP-UGC-04.jpg',
	},
	{
		username: '@paris.tews',
		image: 'https://cdn.chatbooks.com/mk/20-02-IG-series-PDP-UGC-01.jpg',
	},
	{
		username: '@arabys',
		image: 'https://cdn.chatbooks.com/mk/20-02-IG-series-PDP-UGC-02.jpg',
	},
];
