import { schema } from 'normalizr';
import deep from 'deep-get-set';

import { ENTITIES } from '../const/entities/entities';

const GROUP_PAGE_KEY = 'groupPageKey';

export const groupExcludedMomentsPageFromJson = (groupId, index) => ({
	groupId,
	index,
	groupExcludedMomentsPageId: makeGroupExcludedMomentsPageId(groupId, index),
});

export const groupExcludedMomentsPageSchema = new schema.Entity(ENTITIES.GROUP_EXCLUDED_MOMENTS_PAGE, {}, {
	idAttribute: () => GROUP_PAGE_KEY,
});

export const makeGroupExcludedMomentsPageId = (groupId, index) => `${groupId}-${index}`;

export const receiveGroupExcludedMomentsPage = (state, data) => {
	const stateKey = ENTITIES.GROUP_EXCLUDED_MOMENTS_PAGE;
	const newGroupExcludedMomentsData = deep(data, `${stateKey}`);

	if (!newGroupExcludedMomentsData) return {};

	const newGroupPage = deep(newGroupExcludedMomentsData, `${GROUP_PAGE_KEY}`) || {};
	const currentGroupPages = deep(state, `${stateKey}.${newGroupPage.groupId}`) || {};

	return {
		[stateKey]: {
			...state[stateKey],
			[newGroupPage.groupId]: {
				...currentGroupPages,
				[newGroupPage.index]: newGroupPage.groupExcludedMomentsPageId,
			},
		},
	};
};