import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import normalizeMeasurement from '../../helpers/normalizeMeasurement';

const PageFlowContainer = ({
	children,
	className: classNameProp,
	isInset,
	onMouseEnter,
	onMouseLeave,
	paddingBottom,
	paddingTop,
	style: styleProps
}) => {
	const className = classnames(classNameProp, 'constrain-width', { 'mobile-padded': isInset });
	const style = {
		...styleProps,
		paddingBottom: normalizeMeasurement(paddingBottom),
		paddingTop: normalizeMeasurement(paddingTop)
	};

	return (
		<div
			className={className}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={style}>
			{children}
		</div>
	);
};

PageFlowContainer.displayName = 'PageFlowContainer';

PageFlowContainer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	isInset: PropTypes.bool,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	style: PropTypes.object
};

export default PageFlowContainer;
