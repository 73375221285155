import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLightBulb = (props) => (
	<Svg
		{...props}
		name="light-bulb"
		viewBox="0 0 20 28"
	>
		<path
			d="M10.3078 0C4.63835 0 0.608878 4.56859 0.615487 9.63813C0.618791 12.0597 1.52414 14.2685 3.01433 15.9567C4.42688 17.5563 5.7838 20.1622 5.9022 21L5.9055 25.1114C5.9055 25.2837 5.95727 25.4516 6.05309 25.5948L7.40286 27.6095C7.56641 27.8534 7.84176 28 8.13639 28H12.4787C12.7738 28 13.0492 27.8534 13.2122 27.6095L14.5619 25.5948C14.6578 25.4516 14.709 25.2831 14.7095 25.1114L14.7134 21C14.8378 20.1403 16.1997 17.5443 17.6013 15.9567C19.0942 14.2652 20.0001 12.0515 20.0001 9.625C20.0001 4.30938 15.6606 0 10.3078 0ZM12.9478 24.8451L12.0067 26.25H8.60834L7.66719 24.8451L7.66664 24.5H12.9478V24.8451ZM12.95 22.75H7.66499L7.66113 21H12.9523L12.95 22.75ZM16.2763 14.8028C15.5058 15.6756 14.2756 17.4333 13.4908 19.2495H7.1253C6.34001 17.4327 5.11029 15.6756 4.33987 14.8028C3.0661 13.3602 2.38488 11.5309 2.38048 9.625C2.37057 5.41461 5.7056 1.75 10.3078 1.75C14.6803 1.75 18.2379 5.28281 18.2379 9.625C18.2379 11.5309 17.5412 13.37 16.2763 14.8028ZM10.3078 3.5C6.90723 3.5 4.13996 6.24805 4.13996 9.625C4.13996 10.1084 4.53426 10.5 5.02108 10.5C5.5079 10.5 5.9022 10.1084 5.9022 9.625C5.9022 7.21273 7.87811 5.25 10.3078 5.25C10.7946 5.25 11.1889 4.85844 11.1889 4.375C11.1889 3.89156 10.7946 3.5 10.3078 3.5Z"
			fill="inherit"
			stroke="none"
		/>
	</Svg>
);

IconLightBulb.displayName = 'IconLightBulb';

IconLightBulb.propTypes = iconProps;

IconLightBulb.defaultProps = defaultIconProps;

export default IconLightBulb;
