import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLayoutSquare = (props) => (
	<Svg
		{...props}
		name="layout-square"
		hasStroke={true}
		viewBox="0 0 30 30"
	>
		<rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="inherit" fill="none" />
		<rect x="7" y="7" width="16" height="16" rx="2" fill="inherit" />
	</Svg>
);

IconLayoutSquare.displayName = 'IconLayoutSquare';

IconLayoutSquare.propTypes = iconProps;

IconLayoutSquare.defaultProps = defaultIconProps;

export default IconLayoutSquare;
