import React from 'react';
import PropTypes from 'prop-types';
import defaultIconProps from '../_defaultIconProps';
import iconProps from '../_iconProps';
import Svg from '../Svg';

export const IconCardBackPortraitImageOnly = (props) => {
	const { size } = props;
	const heightRatio = 140;
	const widthRatio = 100;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="IconCardBackPortraitImageOnly"
			width={width}
			height={height}
			viewBox="0 0 100 140"
			fill="none"
		>
			<rect
				fill={props.color}
				x="0"
				y="0"
				width="100"
				height="140"
			/>
			<rect
				x="4"
				y="4"
				width="92"
				height="132"
				rx="2"
				fill={props.color2}
			/>
		</Svg>
	);
};

IconCardBackPortraitImageOnly.displayName = 'IconCardBackPortraitImageOnly';

IconCardBackPortraitImageOnly.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconCardBackPortraitImageOnly.defaultProps = {
	...defaultIconProps,
	color: '#ffffff',
	color2: '#d8d8d8',
};

export default IconCardBackPortraitImageOnly;
