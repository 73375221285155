import { apiGetData, apiPostRecieveData, apiPost } from './api.http.actions';

export const get_account_expiredToken = () => (dispatch) => {
	return dispatch(apiGetData('/account/expiredToken'));
};

export const post_account_changePassword = ({ currentPassword, newPassword, singleSignOnToken, singleSignOnType }) => (dispatch) => {
	return dispatch(apiPostRecieveData('/account/changePassword', { currentPassword, newPassword, singleSignOnToken, singleSignOnType }));
};

export const post_account_logOff = () => (dispatch) => {
	return dispatch(apiPost('/account/logOff'));
};

export const post_account_logOn = (loginModel) => (dispatch) => {
	return dispatch(apiPostRecieveData('/account/logOn', {
		Username: loginModel.username,
		Password: loginModel.password,
		tempUserToken: loginModel.tempUserToken,
	}));
};

export const post_account_logOnSingleSignOn = (loginModel) => (dispatch) => {
	return dispatch(apiPostRecieveData('/account/logOnSingleSignOn', {
		email: loginModel.email,
		singleSignOnToken: loginModel.ssoToken,
		singleSignOnType: loginModel.ssoType,
		tempUserToken: loginModel.tempUserToken,
		Username: loginModel.username,
	}));
};

export const post_account_merge = (sourceUserToken) => (dispatch) => {
	return dispatch(apiPost('/account/merge', { sourceUserToken }));
};

export const post_account_passwordReset = (email) => (dispatch) => {
	return dispatch(apiPostRecieveData('/account/passwordReset', { email }));
};

export const post_account_setPassword = (id, password) => (dispatch) => {
	return dispatch(apiPostRecieveData('/account/setPassword', { id, password }));
};
