import deep from 'deep-get-set';

import * as ACTIONS from '../actions/actionTypes';
import defaultState from './defaultState';
import { REDUCERS } from '../const/reducers.const';
import { COOKIES } from '../helpers/cookies';

export default (state = defaultState[REDUCERS.APP_COOKIE], action) => {
	const { type, data } = action;

	switch (type) {
		case ACTIONS.TOUCH_REDUCERS_TO_TRIGGER_SELECTORS:
			return {
				...state,
			};
		case ACTIONS.APP_STRING_TRACKER_TOGGLED: {
			return {
				...state,
				isAppStringTrackerEnabled: !deep(state, 'isAppStringTrackerEnabled'),
			};
		}
		case ACTIONS.CLEAR_APP_STRING_OVERRIDES: {
			return {
				...state,
				[COOKIES.APP.APP_STRING_OVERRIDE]: undefined,
			}
		}
		case ACTIONS.DELETE_APP_COOKIE: {
			const newState = { ...state };
			delete newState[action.key];

			return newState;
		}
		case ACTIONS.SET_APP_STRING_OVERRIDE_BY_KEY: {
			const { key, value } = data;
			const currentAppStringOverrides = state[COOKIES.APP.APP_STRING_OVERRIDE] || {};

			const updatedAppStringOverrides = {
				...currentAppStringOverrides,
				[key]: value
			};

			if (value === undefined) {
				delete updatedAppStringOverrides[key];
			}

			return {
				...state,
				[COOKIES.APP.APP_STRING_OVERRIDE]: updatedAppStringOverrides,
			}
		}
		case ACTIONS.SET_CHECKOUT_SOURCE: {
			const currentCheckoutSource = state[COOKIES.APP.CHECKOUT_SOURCE] || {};
			const updatedCheckoutSource = {
				...currentCheckoutSource,
				checkoutSource: data.checkoutSource,
			};

			return {
				...state,
				[COOKIES.APP.CHECKOUT_SOURCE]: updatedCheckoutSource,
			}
		}
		case ACTIONS.GDPR_DISPLAYED:
			return {
				...state,
				[COOKIES.APP.GDPR_DISPLAYED]: true,
			};
		case ACTIONS.MADE_TO_SUFFER:
			return {
				...state,
				[COOKIES.APP.MADE_TO_SUFFER]: true,
			};
		case ACTIONS.SET_APP_COOKIE: {
			const { key, value } = data;

			return {
				...state,
				[key]: value,
			};
		}
		case ACTIONS.VISITING_USER_LANGUAGE_SET:
			return {
				...state,
				[COOKIES.APP.VISITING_USER_LANGUAGE]: data.lcid,
			};
		case ACTIONS.SET_UUID_COOKIE:
			return {
				...state,
				[COOKIES.APP.UUID]: data.uuid,
			};
		default:
			return state;
	}
};
