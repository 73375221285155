import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from 'classnames';

import MaybeLinkWrap from '../../../components/MaybeLinkWrap/MaybeLinkWrap';
import SquareImage from '../../../components/SquareImage/SquareImage';
import { CONFIGURABLE_LINK_TYPES, SPACING } from '../../../const';

const BlogRecommendationDiv = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	column-gap: ${SPACING.MEDIUM};
	justify-items: center;
`;

const MaybeLinkStyled = styled(MaybeLinkWrap)`
	color: inherit;
	text-decoration: none;
	text-transform: none;
	text-decoration-color: none;

	${BlogRecommendationDiv}:hover & {
		color: inherit;
		text-decoration: none;
		text-transform: none;
		text-decoration-color: none;
	}
`;

const BlogImageContainer = styled.div`
	${BlogRecommendationDiv}:hover & {
		border-style: solid;
		border-color: ${(props) => props.highlightColor};
		border-width: 6px;
		margin: -6px;
	}
`;

const BlogPostTitleDiv = styled.div`
	font-size: 20px;
	font-weight: 700;
	letter-spacing: 0px;
	line-height: 27px;
	margin-bottom: ${SPACING.MEDIUM};
	margin-top: ${SPACING.MEDIUM};
	text-align: center;
`;

const BlogPostDescriptionDiv = styled.div`
	font-size: 16px;
	font-weight: 450;
	letter-spacing: 0.25px;
	line-height: 22px;
	text-align: center;
`;

const BlogRecommendation = ({ blogPost }) => (
	<BlogRecommendationDiv
		className={classnames('BlogRecommendation')}>
		<MaybeLinkStyled
			path={blogPost.link}
			linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK}>
			<BlogImageContainer
				highlightColor={blogPost.highlightColor}>
				<SquareImage
					src={blogPost.imageUrl}
					alt={blogPost.imageAlt}/>
			</BlogImageContainer>
			<BlogPostTitleDiv>
				{blogPost.title}
			</BlogPostTitleDiv>
			<BlogPostDescriptionDiv>
				{blogPost.description}
			</BlogPostDescriptionDiv>
		</MaybeLinkStyled>
	</BlogRecommendationDiv>
);

BlogRecommendation.displayName = 'BlogRecommendation';

BlogRecommendation.propTypes = {
	blogPost: PropTypes.object,
};

export default BlogRecommendation;
