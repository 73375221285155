export default {
	BookInfo_Order: {
		name: 'BookInfo_Order',
		screen: 'BookInfo',
		action: 'Order'
	},
	CardsMain_Selection: {
		name: 'CardsMain_Selection',
		screen: 'CardsMain',
		action: 'Selection'
	},
	CardsFront_ChangeTemplate: {
		name: 'CardsFront_ChangeTemplate',
		screen: 'CardsFront',
		action: 'ChangeTemplate'
	},
	CardsCustomDetail_DownloadTemplate: {
		name: 'CardsCustomDetail_DownloadTemplate',
		screen: 'CardsCustomDetail',
		action: 'DownloadTemplate'
	},
	CardsSave_Prompt: {
		name: 'CardsSave_Prompt',
		screen: 'CardsSave',
		action: 'Prompt'
	},
	CardsSave_Selection: {
		name: 'CardsSave_Selection',
		screen: 'CardsSave',
		action: 'Selection'
	},
	createCustomPrintsClicked: 'Create_CustomPrints_Clicked',
	createMainBookSeries: {
		name: 'CreateMain_BookSeries',
		screen: 'CreateMain',
		action: 'BookSeries'
	},
	createMainCustomBooks: {
		name: 'CreateMain_CustomBooks',
		screen: 'CreateMain',
		action: 'CustomBooks'
	},
	CreateMain_LearnMore: {
		name: 'CreateMain_LearnMore',
		screen: 'CreateMain',
		action: 'LearnMore'
	},
	createMainMore: {
		name: 'CreateMain_More',
		screen: 'CreateMain',
		action: 'More'
	},
	CreateMain_RecommededProduct: {
		name: 'CreateMain_RecommededProduct',
		screen: 'CreateMain',
		action: 'RecommededProduct'
	},
	createLayflatBookSeries: {
		name: 'CreateMain_LayflatBook',
		screen: 'CreateMain',
		action: 'LayflatBook'
	},
	createCustom6x6: {
		name: 'CreateCustom_6x6',
		screen: 'CreateCustom',
		action: '6x6'
	},
	createCustom8x8: {
		name: 'CreateCustom_8x8',
		screen: 'CreateCustom',
		action: '8x8'
	},
	createMoreChatbooksCollections: {
		name: 'CreateMore_ChatbooksCollections',
		screen: 'CreateMore',
		action: 'ChatbooksCollections'
	},
	createdChatgroup: {
		name: 'CreatedChatgroup',
		screen: '',
		action: ''
	},
	CustomEditBook_Add: {
		name: 'CustomEditBook_Add',
		event_type: 'CustomEditBook_Add',
		screen: 'CustomEditBook',
		action: 'Add',
	},
	CustomEditBook_AddToCart: {
		name: 'CustomEditBook_AddToCart',
		event_type: 'CustomEditBook_AddToCart',
		screen: 'CustomEditBook',
		action: 'AddToCart'
	},
	CustomEditBook_Preview: {
		name: 'CustomEditBook_Preview',
		event_type: 'CustomEditBook_Preview',
		screen: 'CustomEditBook',
		action: 'Preview',
	},
	CustomEditBook_Rearrange: {
		name: 'CustomEditBook_Rearrange',
		event_type: 'CustomEditBook_Rearrange',
		screen: 'CustomEditBook',
		action: 'Rearrange',
	},
	CustomEditBook_ViewPage: {
		name: 'CustomEditBook_ViewPage',
		event_type: 'CustomEditBook_ViewPage',
		screen: 'CustomEditBook',
		action: 'ViewPage',
	},
	EditBook_AddPhotos: {
		name: 'EditBook_AddPhotos',
		event_type: 'EditBook_AddPhotos',
		screen: 'EditBook',
		action: 'AddPhotos',
	},
	EditBook_EditCover: {
		name: 'EditBook_EditCover',
		event_type: 'EditBook_EditCover',
		screen: 'EditBook',
		action: 'EditCover',
	},
	EditBook_Preview: {
		name: 'EditBook_Preview',
		event_type: 'EditBook_Preview',
		screen: 'EditBook',
		action: 'Preview',
	},
	EditBook_Rearrange: {
		name: 'EditBook_Rearrange',
		event_type: 'EditBook_Rearrange',
		screen: 'EditBook',
		action: 'Rearrange',
	},
	EditBook_ViewPage: {
		name: 'EditBook_ViewPage',
		event_type: 'EditBook_ViewPage',
		screen: 'EditBook',
		action: 'ViewPage',
	},
	EditPage_ChangePageLayout: {
		name: 'EditPage_ChangePageLayout',
		event_type: 'EditPage_ChangePageLayout',
		screen: 'EditPage',
		action: 'ChangePageLayout',
	},
	EditPage_EditCaption: {
		name: 'EditPage_EditCaption',
		event_type: 'EditPage_EditCaption',
		screen: 'EditPage',
		action: 'EditCaption',
	},
	firstPurchase: {
		name: 'First Purchase',
		screen: '',
		action: ''
	},
	homeCreateNew: {
		name: 'Home_CreateNew',
		screen: 'Home',
		action: 'CreateNew'
	},
	IGSeriesLanding_Continue: {
		name: 'IGSeriesLanding_Continue',
		screen: 'IGSeriesLanding',
		action: 'Continue'
	},
	IGSeriesLanding_Expand: {
		name: 'IGSeriesLanding_Expand',
		screen: 'IGSeriesLanding',
		action: 'Expand'
	},
	IGSeriesLanding_Load: {
		name: 'IGSeriesLanding_Load',
		screen: 'IGSeriesLanding',
		action: 'Load'
	},
	IGSeriesPreview_Continue: {
		name: 'IGSeriesPreview_Continue',
		screen: 'IGSeriesPreview',
		action: 'Continue'
	},
	IGSeriesPreview_Load: {
		name: 'IGSeriesPreview_Load',
		screen: 'IGSeriesPreview',
		action: 'Load'
	},
	MarketingHome_Download: {
		screen: 'MarketingHome',
		action: 'Download'
	},
	MarketingHome_GetStarted: {
		screen: 'MarketingHome',
		action: 'GetStarted'
	},
	MarketingHome_SelectProduct: {
		screen: 'MarketingHome',
		action: 'SelectProduct'
	},
	MarketingHome_Testimonial: {
		screen: 'MarketingHome',
		action: 'Testimonial'
	},
	MarketingSite_FooterSelect: {
		screen: 'MarketingSite',
		action: 'FooterSelect'
	},
	MarketingSite_HeaderProductsSelect: {
		screen: 'MarketingSite',
		action: 'HeaderProductsSelect'
	},
	MobileOverlay_ContinueInApp: {
		name: 'MobileOverlay_ContinueInApp',
		screen: 'MobileOverlay',
		action: 'ContinueInApp'
	},
	orderCompletedPage: {
		name: 'OrderConfirmation_Load',
		screen: 'OrderConfirmation',
		action: 'Load'
	},
	OrderConfirmation_Survey: {
		name: 'OrderConfirmation_Survey',
		screen: 'OrderConfirmation',
		action: 'Survey'
	},
	orderCustomPrintsClicked: 'Order_CustomPrints_Clicked',
	orderSeriesPrintsClicked: 'Order_SeriesPrints_Clicked',
	OrderWizard_Purchase: {
		name: 'OrderWizard_Purchase',
		screen: 'OrderWizard',
		action: 'Purchase'
	},
	PhotoBookOptions_ChoosePhotoSource: {
		name: 'PhotoBookOptions_ChoosePhotoSource',
		screen: 'PhotoBookOptions',
		action: 'ChoosePhotoSource'
	},
	PostPurchaseSurvey_Load: {
		name: 'PostPurchaseSurvey_Load',
		screen: 'PostPurchaseSurvey',
		action: 'Load'
	},
	PostPurchaseSurvey_Skip: {
		name: 'PostPurchaseSurvey_Skip',
		screen: 'PostPurchaseSurvey',
		action: 'Skip'
	},
	PostPurchaseSurvey_Submit: {
		name: 'PostPurchaseSurvey_Submit',
		screen: 'PostPurchaseSurvey',
		action: 'Submit'
	},
	printsSetQuantityClicked: 'Prints_SetQuantity_Clicked',
	ProductExplainer_Back: {
		name: 'ProductExplainer_Back',
		screen: 'ProductExplainer',
		action: 'Back'
	},
	productExplainerContinue: {
		name: 'ProductExplainer_Continue',
		screen: 'ProductExplainer',
		action: 'Continue'
	},
	purchase: {
		name: 'Purchase',
		screen: '',
		action: ''
	},
	QuestionnairePage1_Next: {
		name: 'QuestionnairePage1_Next',
		screen: 'QuestionnairePage1',
		action: 'Next'
	},
	QuestionnairePage2_Next: {
		name: 'QuestionnairePage2_Next',
		screen: 'QuestionnairePage2',
		action: 'Next'
	},
	QuestionnairePage3_Next: {
		name: 'QuestionnairePage3_Next',
		screen: 'QuestionnairePage3',
		action: 'Next'
	},
	QuickFlowLanding_ChooseSource: {
		name: 'QuickFlowLanding_ChooseSource',
		screen: 'QuickFlowLanding',
		action: 'ChooseSource'
	},
	QuickFlowLanding_Load: {
		name: 'QuickFlowLanding_Load',
		screen: 'QuickFlowLanding',
		action: 'Load'
	},
	QuickReauthLanding_Action: {
		name: 'QuickReauthLanding_Action',
		screen: 'QuickReauthLanding',
		action: 'Action'
	},
	QuickReauthLanding_Load: {
		name: 'QuickReauthLanding_Load',
		screen: 'QuickReauthLanding',
		action: 'Load'
	},
	QuickReauthStatus_Action: {
		name: 'QuickReauthStatus_Action',
		screen: 'QuickReauthStatus',
		action: 'Action'
	},
	QuickReauthStatus_Load: {
		name: 'QuickReauthStatus_Load',
		screen: 'QuickReauthStatus',
		action: 'Load'
	},
	QuickSeriesLanding_Continue: {
		name: 'QuickSeriesLanding_Continue',
		screen: 'QuickSeriesLanding',
		action: 'Continue'
	},
	QuickSeriesLanding_Load: {
		name: 'QuickSeriesLanding_Load',
		screen: 'QuickSeriesLanding',
		action: 'Load'
	},
	referralsPage: 'Referrals_Page',
	SeriesInfo_EditBook: {
		name: 'SeriesInfo_EditBook',
		event_type: 'SeriesInfo_EditBook',
		screen: 'SeriesInfo',
		action: 'EditBook',
	},
	SeriesInfo_Subscribe: {
		name: 'SeriesInfo_Subscribe',
		event_type: 'SeriesInfo_Subscribe',
		screen: 'SeriesInfo',
		action: 'Subscribe'
	},
	shippingUpgradeChangeShippingMethod: 'ShippingUpgrade_ChangeShippingMethod',
	shippingUpgradePage: 'ShippingUpgrade_Page',
	SubscriptionOptions_ChoosePhotoSource: {
		name: 'SubscriptionOptions_ChoosePhotoSource',
		screen: 'SubscriptionOptions',
		action: 'ChoosePhotoSource'
	},
};
