export default [
	{
		username: 'llvpfelferadams',
		title: 'Amazing for Busy Moms',
		text: 'I always thought i’d be amazing at scrapbooking...turns out I don’t have time. I am a working mom and needed an alternative option. Chatbooks has saved me. I love how easy it is to use, and how you can set it up to automatically send the bookies out. I like it to my Instagram and love that it prints the caption too! It’s a great journal for me and my family. I highly suggest trying them out!! My kids LOVE reading through them. They sit on their bookshelves with all the other books, and our chatbooks get read the most.',
	},
	{
		username: 'cjlattin',
		title: 'Beautiful Memories',
		text: 'I am the proud owner of over 80 copies – and can I tell you how much I LOVE them!!! I’ve tried lots and lots of places, and by far, Chatbooks is the best, both in quality, and customer service. I’ve had a few “user errors”, and have always found this company extremely easy to deal with. My little grandchildren love browsing through the books, and reminiscing about the pictures.',
	},
	{
		username: 'TypicalShopper',
		title: 'Beautiful Memories',
		text: 'Ties the app to Instagram and ordered my first book beginning my instagram series. It was Super easy and I have high hopes! You can customize as much or as little as you want from the book cover and title to captions. I deleted several photos that I don’t care to have printed and they went away, no problem!',
	},
];
