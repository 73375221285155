/*
	MouseFlow

	Mouseflow lets you replay the full visitor experience to identify pain points, boost conversions, and optimize your site.

	https://mouseflow.com/

	Setup in vendors/mouseFlow.js
*/

const isActive = () => window.hasOwnProperty('_mfq');

const pageViewed = (url) => isActive() && window._mfq.push(['newPageView', url]);

const setIdentity = (userId) => isActive() && window._mfq.push(['setVariable', 'person_id', userId]);

export default {
	pageViewed,
	setIdentity
};
