import React from 'react';
import PropTypes from 'prop-types';

import Bullet from './Bullet';
import Wrapper from '../Wrapper/Wrapper';
import './Bullets.css';

const Bullets = ({
	bullets,
}) => (
	<Wrapper className="duplo-block duplo-block-bullets">
		<ul>
			{bullets.map((bullet, index) => (
				<Bullet
					key={index}
					{...bullet}
				/>
			))}
		</ul>
	</Wrapper>
);

Bullets.propTypes = {
	bullets: PropTypes.array,
};

export default Bullets;
