import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import deep from 'deep-get-set';

import MenuLink from '../MenuLink/MenuLink';
import connectContext from '../../../helpers/connectContext';
import { Consumer } from '../../../helpers/context';
import { selectSherpa } from '../../../../selectors';

export const ProductsLink = ({ productsHeader, sherpa }) => (
	<MenuLink
		action="Link"
		label={<span>{productsHeader || ''}</span>}
		path={sherpa.landing.products()}
		analyticObj={{
			attr2: 'products'
		}}
		className="drilldown-link"
	/>
);

ProductsLink.propTypes = {
	productsHeader: PropTypes.string,
	sherpa: PropTypes.object,
};

const mapContextToProps = (context) => {
	const productsHeader = deep(context, 'productsHeader');

	return {
		productsHeader
	};
};

const mapStateToProps = (state) => ({
	sherpa: selectSherpa(state),
});

export default connect(
	mapStateToProps
)(connectContext(Consumer, mapContextToProps)(ProductsLink));
