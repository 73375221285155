import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ConfigurableLink from '../../ConfigurableLink/ConfigurableLink';
import './MultiColumnFooter.css';

const MultiColumnFooter = ({
	action,
	buttonStyle,
	buttonText,
	buttonUrl,
	children,
	color,
	linkElement,
	linkElementPathAttr,
	title
}) => {
	const buttonContent = buttonText ? (
		<div>
			<ConfigurableLink
				action={action}
				linkElement={linkElement}
				linkElementPathAttr={linkElementPathAttr}
				path={buttonUrl}
				className={classnames('button large', buttonStyle)}
			>
				{buttonText}
			</ConfigurableLink>
		</div>
	) : null;
	const titleContent = title ? <p className="caption">{title}</p> : null;
	const captionContent = children ? <div className="caption">{children}</div> : null;

	return (
		<div className="MultiColumnFooter text-center" style={{ color }}>
			{buttonContent}
			{titleContent}
			{captionContent}
		</div>
	);
};

MultiColumnFooter.displayName = 'MultiColumnFooter';

MultiColumnFooter.propTypes = {
	action: PropTypes.string,
	buttonStyle: PropTypes.string,
	buttonText: PropTypes.string,
	buttonUrl: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.string,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	title: PropTypes.string
};

export default MultiColumnFooter;
