import deep from 'deep-get-set';

import { addNumbers } from '../numbers/numbers';
import * as Product from '../../model/product.model';

export const convertOrderItemsToSkuPurchaseMetadataMap = (orderItems = []) => {
	if (!Array.isArray(orderItems)) return {};

	return orderItems.reduce((soFar, item) => {
		const product = item.product;
		const itemSku = Product.getSku(product);

		const skuPriceSoFar = deep(soFar, `${itemSku}.price`);
		const itemPrice = deep(item, 'price.total');
		const price = addNumbers(skuPriceSoFar, itemPrice);

		const skuQuantitySoFar = deep(soFar, `${itemSku}.quantity`);
		const itemQuantity = deep(item, 'quantity');
		const quantity = addNumbers(skuQuantitySoFar, itemQuantity);

		return {
			...soFar,
			[itemSku]: {
				...deep(soFar, `${itemSku}`),
				name: Product.getName(product),
				sku: itemSku,
				category: Product.getCategory(product),
				price,
				quantity,
			}
		}
	}, {})
};

export const getValueByKey = (key, parseKeys) => {
	const parts = key.split('_').slice(0, parseKeys.length);

	if (parts.length <= 1) return {};

	return parts.reduce(
		(obj, part, index) => ({
			...obj,
			[parseKeys[index] || index]: part
		}),
		{}
	);
};

export const getCurrentUrlName = () => deep(window, 'location.pathname');
