import styled from 'styled-components';

import { COLOR } from '../../const';

const DEFAULT_COLOR = COLOR.BLUE_500;
const DEFAULT_HOVER_COLOR = COLOR.BLUE_600;

const Button = styled.button`
	background-color: ${(props) => {
		return props.backgroundColor ||
			DEFAULT_COLOR;
	}};
	border-color: ${(props) => {
		return props.borderColor ||
			props.backgroundColor ||
			DEFAULT_COLOR;
	}};
	border-radius: ${(props) => {
		return props.borderRadius ||
			'7px';
	}};
	border-width: 2px;
	color: ${(props) => {
		return props.color ||
			COLOR.WHITE;
	}};
	font-style: 'normal';
	font-size: ${(props) => {
		return props.fontSize ||
			'14px';
	}};
	font-weight: 700;
	line-height: 20px;
	margin-bottom: ${(props) => props.marginBottom};
	padding: 0.75rem 1rem;
	text-align: center;

	&:hover {
		color: ${(props) => {
			return props.hoverTextColor;
		}};
		background-color: ${(props) => {
			return props.hoverBackgroundColor ||
				props.borderColor ||
				props.backgroundColor ||
				DEFAULT_HOVER_COLOR
		}};
		border-color: ${(props) => {
			return props.hoverBorderColor ||
				props.hoverBackgroundColor ||
				props.borderColor ||
				props.backgroundColor ||
				DEFAULT_HOVER_COLOR
		}};
		cursor: pointer;
	}
`;

Button.displayName = 'Button';

export default Button;
