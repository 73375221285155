import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconX = (props) => (
	<Svg
		{...props}
		name="x"
		viewBox="0 0 18 18"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-21.000000, -21.000000)" fillRule="nonzero" fill="inherit">
				<path d="M38.6614761,38.6810372 C38.4505616,38.8920376 38.1644485,39.0105837 37.8661095,39.0105837 C37.5677706,39.0105837 37.2816575,38.8920376 37.0707429,38.6810372 L29.9900779,31.6003721 L22.9159315,38.6749119 C22.4759877,39.1101951 21.7670484,39.1083013 21.3294364,38.6706739 C20.8918245,38.2330464 20.8899557,37.524107 21.3252545,37.0841786 L28.3996256,30.0098637 L21.326547,22.9367851 C20.8981248,22.4958141 20.903169,21.7925489 21.3378724,21.3577686 C21.7725759,20.9229884 22.4758402,20.91782 22.9168868,21.3461642 L29.9902464,28.4191867 L37.0695066,21.3399827 C37.3523676,21.0495869 37.7696138,20.9338743 38.1616378,21.0371078 C38.5536619,21.1403414 38.8598024,21.4465468 38.9629528,21.8385927 C39.0661033,22.2306387 38.9503022,22.6478603 38.6598465,22.9306597 L31.5809797,30.0098637 L38.6616447,37.0905288 C38.8725816,37.3014419 38.9910727,37.5875249 38.991041,37.8858179 C38.9910094,38.1841109 38.8724577,38.4701687 38.6614761,38.6810372 Z" />
			</g>
		</g>
	</Svg>
);

IconX.displayName = 'IconX';

IconX.propTypes = iconProps;

IconX.defaultProps = defaultIconProps;

export default IconX;
