export const TOUCH_REDUCERS_TO_TRIGGER_SELECTORS = 'TOUCH_REDUCERS_TO_TRIGGER_SELECTORS';

// Fetch Actions
export const FETCH_BLOG_POST = 'FETCH_BLOG_POST';
export const FETCH_BLOG_POSTS = 'FETCH_BLOG_POSTS';

// Receive Actions
export const RECEIVE_BLOG_POST = 'RECEIVE_BLOG_POST';
export const RECEIVE_BLOG_POSTS = 'RECEIVE_BLOG_POSTS';
export const RECEIVE_POPULAR_BLOG_POSTS = 'RECEIVE_POPULAR_BLOG_POSTS';
export const RECEIVE_BLOG_CATEGORIES = 'RECEIVE_BLOG_CATEGORIES';
export const RECEIVE_HOME_PAGE = 'RECEIVE_HOME_PAGE';
export const RECEIVE_LANDING_PAGE = 'RECEIVE_LANDING_PAGE';

// AppString Actions
export const APP_STRING_DISPLAYED = 'APP_STRING_DISPLAYED';
export const APP_STRING_HIDDEN = 'APP_STRING_HIDDEN';
export const APP_STRING_TRACKER_TOGGLED = 'APP_STRING_TRACKER_TOGGLED';
export const FETCH_APP_STRINGS = 'FETCH_APP_STRINGS';
export const RECEIVE_APP_STRINGS = 'RECEIVE_APP_STRINGS';

// Book Actions
export const SET_BOOK_COVER_MEDIA = 'SET_BOOK_COVER_MEDIA';

// Branch Actions
export const RECEIVE_BRANCH_DATA = 'RECEIVE_BRANCH_DATA';

// Card Actions
export const FETCH_CARD_TEMPLATE_FILTERS = 'FETCH_CARD_TEMPLATE_FILTERS';
export const FETCH_CARD_TEMPLATES = 'FETCH_CARD_TEMPLATES';
export const SET_CARD_INITIAL_QUERY_DATA = 'SET_CARD_INITIAL_QUERY_DATA';
export const FETCH_CARD_TEMPLATE_ATTRIBUTES = 'FETCH_CARD_TEMPLATE_ATTRIBUTES';

// Card Template Actions
export const SET_CARD_BACK_TEMPLATES = 'SET_CARD_BACK_TEMPLATES';
export const SET_CARD_TEMPLATE = 'SET_CARD_TEMPLATE';
export const SET_CARD_TEMPLATE_EDGE_TYPE = 'SET_CARD_TEMPLATE_EDGE_TYPE';
export const SET_CARD_TEMPLATE_CAROUSEL_SCREEN = 'SET_CARD_TEMPLATE_CAROUSEL_SCREEN';
export const SET_CARD_TEMPLATE_FOIL_OPTION = 'SET_CARD_TEMPLATE_FOIL_OPTION';
export const SET_CARD_TEMPLATE_ID = 'SET_CARD_TEMPLATE_ID';
export const SET_CARD_TEMPLATE_ORIENTATION_SET = 'SET_CARD_TEMPLATE_ORIENTATION_SET';
export const SET_CARD_TEMPLATE_PREMIUM_TYPE = 'SET_CARD_TEMPLATE_PREMIUM_TYPE';

// Checkout Actions
export const APPLY_COUPON_CODE_INIT = 'APPLY_COUPON_CODE_INIT';
export const APPLY_COUPON_CODE_ERROR = 'APPLY_COUPON_CODE_ERROR';
export const APPLY_COUPON_CODE_SUCCESS = 'APPLY_COUPON_CODE_SUCCESS';
export const CLEAR_COUPON_CODE_INIT = 'CLEAR_COUPON_CODE_INIT';
export const CLEAR_COUPON_CODE_ERROR = 'CLEAR_COUPON_CODE_ERROR';
export const CLEAR_COUPON_CODE_SUCCESS = 'CLEAR_COUPON_CODE_SUCCESS';
export const LOAD_GIFT_NOTE_LAYOUT_SUCCESS = 'LOAD_GIFT_NOTE_LAYOUT_SUCCESS';
export const LOAD_GIFT_NOTE_WRAPPED_TEXT_SUCCESS = 'LOAD_GIFT_NOTE_WRAPPED_TEXT_SUCCESS';
export const LOAD_SHOPPING_CART_ERROR = 'LOAD_SHOPPING_CART_ERROR';
export const LOAD_SHOPPING_CART_SUCCESS = 'LOAD_SHOPPING_CART_SUCCESS';
export const SET_CART_ID = 'SET_CART_ID';
export const SET_CART_ITEM_FOR_REMOVAL = 'SET_CART_ITEM_FOR_REMOVAL';
export const UNSET_CART_ITEM_FOR_REMOVAL = 'UNSET_CART_ITEM_FOR_REMOVAL';

// Config Actions
export const UPDATE_CONFIG_BASE_API_URL = 'UPDATE_CONFIG_BASE_API_URL';

// Cookie Actions
export const CLEAR_APP_STRING_OVERRIDES = 'CLEAR_APP_STRING_OVERRIDES';
export const DELETE_APP_COOKIE = 'DELETE_APP_COOKIE';
export const SET_APP_STRING_OVERRIDE_BY_KEY = 'SET_APP_STRING_OVERRIDE_BY_KEY';
export const GDPR_DISPLAYED = 'GDPR_DISPLAYED';
export const VISITING_USER_LANGUAGE_SET = 'VISITING_USER_LANGUAGE_SET';
export const MADE_TO_SUFFER = 'MADE_TO_SUFFER';
export const SET_APP_COOKIE = 'SET_APP_COOKIE';
export const SET_CHECKOUT_SOURCE = 'SET_CHECKOUT_SOURCE';
export const SET_UUID_COOKIE = 'SET_UUID_COOKIE';

// Analytics Actions
export const ANALYTICS_ENQUEUE_EVENT = 'ANALYTICS_ENQUEUE_EVENT';
export const ANALYTICS_EVENT_TRIGGERED = 'ANALYTICS_EVENT_TRIGGERED';
export const ANALYTICS_FLUSH_EVENT_QUEUE = 'ANALYTICS_FLUSH_EVENT_QUEUE';

// Auth Actions
export const AUTH_PASSTHROUGH_INIT = '@@AUTH_PASSTHROUGH_INIT';
export const ERROR_ACCOUNT_CREATE = 'ERROR_ACCOUNT_CREATE';
export const ERROR_ACCOUNT_LOGOFF = 'ERROR_ACCOUNT_LOGOFF';
export const ERROR_ACCOUNT_LOGON = 'ERROR_ACCOUNT_LOGON';
export const ERROR_REFERRAL_INFO = 'ERROR_REFERRAL_INFO';
export const ERROR_REQUEST_RESET_PASSWORD = 'ERROR_REQUEST_RESET_PASSWORD';
export const ERROR_RESET_PASSWORD = 'ERROR_RESET_PASSWORD';
export const ERROR_TEMPUSER_LOGON = 'ERROR_TEMPUSER_LOGON';
export const ERROR_USER_LOGGEDIN = 'ERROR_USER_LOGGEDIN';
export const INIT_AUTH_USER = 'INIT_AUTH_USER';
export const FETCH_ACCOUNT_CREATE = 'FETCH_ACCOUNT_CREATE';
export const FETCH_REFERRAL_INFO = 'FETCH_REFERRAL_INFO';
export const FETCH_ACCOUNT_LOGOFF = 'FETCH_ACCOUNT_LOGOFF';
export const FETCH_ACCOUNT_LOGON = 'FETCH_ACCOUNT_LOGON';
export const FETCH_REQUEST_RESET_PASSWORD = 'FETCH_REQUEST_RESET_PASSWORD';
export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD';
export const FETCH_TEMPUSER_LOGON = 'FETCH_TEMPUSER_LOGON';
export const FETCH_USER_LOGGEDIN = 'FETCH_USER_LOGGEDIN';
export const RECEIVE_ACCOUNT_CREATE = 'RECEIVE_ACCOUNT_CREATE';
export const RECEIVE_ACCOUNT_LOGOFF = 'RECEIVE_ACCOUNT_LOGOFF';
export const RECEIVE_ACCOUNT_LOGON = 'RECEIVE_ACCOUNT_LOGON';
export const RECEIVE_PASSWORD_CHANGED = 'RECEIVE_PASSWORD_CHANGED';
export const RECEIVE_REFERRAL_INFO = 'RECEIVE_REFERRAL_INFO';
export const RECEIVE_REQUEST_RESET_PASSWORD = 'RECEIVE_REQUEST_RESET_PASSWORD';
export const RECEIVE_RESET_PASSWORD = 'RECEIVE_RESET_PASSWORD';
export const RECEIVE_TEMPUSER_LOGON = 'RECEIVE_TEMPUSER_LOGON';
export const RECEIVE_USER_LOGGEDIN = 'RECEIVE_USER_LOGGEDIN';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';
export const SET_ACCESS_TOKEN_UNSETTER = 'SET_ACCESS_TOKEN_UNSETTER';
export const SET_PREVIEW_URL = 'SET_PREVIEW_URL';

// Blog Actions
export const BLOG_LOADED_ALL_PAGES = 'BLOG_LOADED_ALL_PAGES';

// Entity Actions
export const RECEIVE_ENTITIES = 'RECEIVE_ENTITIES';

// Global App Actions
export const COLLAGE_DELETE_MEDIA = 'COLLAGE_DELETE_MEDIA';
export const DECREMENT_THROBBER_COUNT = 'DECREMENT_THROBBER_COUNT';
export const INCREMENT_THROBBER_COUNT = 'INCREMENT_THROBBER_COUNT';
export const INVALIDATE_TOKEN = 'INVALIDATE_TOKEN';
export const SET_CONTEXT_VISIBLE = 'SET_CONTEXT_VISIBLE';
export const ANGULAR_LOADED = 'ANGULAR_LOADED';
export const ANGULAR_LOADING = 'ANGULAR_LOADING';
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
export const SET_BOOK_COLLAGE_MODAL_OPEN = 'SET_BOOK_COLLAGE_MODAL_OPEN';
export const SET_SCROLLED = 'SET_SCROLLED';
export const SET_SCROLLED_FROM_BOTTOM = 'SET_SCROLLED_FROM_BOTTOM';
export const SET_SCROLLED_TO_BOTTOM = 'SET_SCROLLED_TO_BOTTOM';
export const SET_SCROLLED_TO_TOP = 'SET_SCROLLED_TO_TOP';
export const GROUP_COVER_BUNDLE_UPDATE = 'GROUP_COVER_BUNDLE_UPDATE';

// Happykit
export const FETCH_HAPPYKIT = 'FETCH_HAPPYKIT';
export const RECEIVE_HAPPYKIT = 'RECEIVE_HAPPYKIT';
export const ERROR_HAPPYKIT = 'ERROR_HAPPYKIT';

// Header Actions
export const HEADER_REQUEST_COLLAPSE = 'HEADER_REQUEST_COLLAPSE';
export const HEADER_TOGGLE_DROPDOWN = 'HEADER_TOGGLE_DROPDOWN';
export const HEADER_TOGGLE_EXPANDED = 'HEADER_TOGGLE_EXPANDED';
export const HEADER_SHADOW_HIDE = 'HEADER_SHADOW_HIDE';
export const HEADER_SHADOW_SHOW = 'HEADER_SHADOW_SHOW';

// Form Actions
export const FORM_SUBMIT = 'FORM_SUBMIT';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const FORM_ERROR = 'FORM_ERROR';
export const FORM_RESET = 'FORM_RESET';

// Group Actions
export const BOOK_LAYOUT_OPTIONS_RECEIVE = 'BOOK_LAYOUT_OPTIONS_RECEIVE';
export const COLLAGE_RECEIVE_MEDIA = 'COLLAGE_RECEIVE_MEDIA';
export const COLLAGE_MEDIA_DELETE = 'COLLAGE_MEDIA_DELETE';
export const COLLAGE_ADD_REPLACEMENT_IMAGE = 'COLLAGE_ADD_REPLACEMENT_IMAGE';
export const CROP_RECT = 'CROP_RECT';
export const MOMENTS_DELETE = 'MOMENTS_DELETE';
export const MOMENTS_RECEIVE = 'MOMENTS_RECEIVE';
export const UPDATE_BOOK_COVER_LAYOUT_VERSION = 'UPDATE_BOOK_COVER_LAYOUT_VERSION';
export const UPDATE_GROUP_BOOK_SIZE = 'UPDATE_GROUP_BOOK_SIZE';
export const UPDATE_GROUP_CUSTOM_COVER_TEMPLATE = 'UPDATE_GROUP_CUSTOM_COVER_TEMPLATE';
export const UPDATE_GROUP_IS_DISPLAY_CAPTIONS = 'UPDATE_GROUP_IS_DISPLAY_CAPTIONS';
export const UPDATE_GROUP_IS_DISPLAY_DATE = 'UPDATE_GROUP_IS_DISPLAY_DATE';
export const UPDATE_GROUP_IS_DISPLAY_FRONT_TITLE = 'UPDATE_GROUP_IS_DISPLAY_FRONT_TITLE';
export const UPDATE_GROUP_IS_DISPLAY_LOCATION = 'UPDATE_GROUP_IS_DISPLAY_LOCATION';
export const UPDATE_GROUP_IS_DISPLAY_VOLUME_NUMBER = 'UPDATE_GROUP_IS_DISPLAY_VOLUME_NUMBER';
export const UPDATE_GROUP_IS_HARDCOVER = 'UPDATE_GROUP_IS_HARDCOVER';
export const UPDATE_GROUP_REMOVE_PERSON = 'UPDATE_GROUP_REMOVE_PERSON';
export const UPDATE_GROUP_TITLE = 'UPDATE_GROUP_TITLE';
export const UPDATE_BOOK_COVER_COLOR_ID = 'UPDATE_BOOK_COVER_COLOR_ID';
export const GROUP_COVER_BUNDLE_REMOVED = 'GROUP_COVER_BUNDLE_REMOVED';
export const GROUP_FILE_UPLOADED = 'GROUP_FILE_UPLOADED';

// Loading Actions
export const LOADING_CLEAR = 'LOADING_CLEAR';
export const LOADING_CLEAR_ERRORS = 'LOADING_CLEAR_ERRORS';
export const LOADING = 'LOADING';
export const LOADING_ERROR = 'LOADING_ERROR';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';

// Location Actions
export const SET_LOCATION_PROPS = 'SET_LOCATION_PROPS';

// Order Actions
export const SET_ORDER_ADDRESS_ID = 'SET_ORDER_ADDRESS_ID';
export const SET_ORDER_COUPON_CODE_ID = 'SET_ORDER_COUPON_CODE_ID';
export const SET_ORDER_FORM_SECTION = 'SET_ORDER_FORM_SECTION';
export const SET_ORDER_IS_TRYING_TO_SUBMIT = 'SET_ORDER_IS_TRYING_TO_SUBMIT';
export const SET_ORDER_PAYMENT_METHOD_ID = 'SET_ORDER_PAYMENT_METHOD_ID';
export const SET_ORDER_PREVIOUS_SHIPPING_TITLE = 'SET_ORDER_PREVIOUS_SHIPPING_TITLE';
export const SET_ORDER_PRODUCT_ID = 'SET_ORDER_PRODUCT_ID';
export const SET_ORDER_SHIPPING_OPTION_ID = 'SET_ORDER_SHIPPING_OPTION_ID';
export const TOGGLE_ORDER_FORM_SECTION = 'TOGGLE_ORDER_FORM_SECTION';

// Pricing Actions
export const LOAD_PRICING_INIT = 'LOAD_PRICING_INIT';
export const LOAD_PRICING_SUCCESS = 'LOAD_PRICING_SUCCESS';
export const LOAD_PRICING_ERROR = 'LOAD_PRICING_ERROR';

// View Actions
export const GROUP_SELECTED = 'GROUP_SELECTED';
export const MODAL_POPPED_FROM_MODAL_STACK = 'MODAL_POPPED_FROM_MODAL_STACK';
export const MODAL_PUSHED_ONTO_MODAL_STACK = 'MODAL_PUSHED_ONTO_MODAL_STACK';
export const MOMENT_SELECTED = 'MOMENT_SELECTED';
export const MOMENT_UNSELECTED = 'MOMENT_UNSELECTED';
export const MOMENT_SELECTED_CLEAR = 'MOMENT_SELECTED_CLEAR';
export const MOMENT_ACTIVATED = 'MOMENT_ACTIVATED';
export const COLLAGE_MEDIA_SELECTED_INDEX = 'COLLAGE_MEDIA_SELECTED_INDEX';
