import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce } from 'lodash';

import MaybeLinkWrap from '../MaybeLinkWrap/MaybeLinkWrap';
import AdaptiveImage from '../../prismic/AdaptiveImage/AdaptiveImage';
import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import { CONFIGURABLE_LINK_TYPES } from '../../const';
import './BannerWithVideo.css';

class BannerWithVideo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isMobile: this.props.isMobile
		};

		this.delayedCallback = this.delayedCallback.bind(this);
		this.setMobile = this.setMobile.bind(this);
	}

	componentDidMount() {
		this.setMobile();
		window.addEventListener('resize', this.delayedCallback);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.delayedCallback);
	}

	delayedCallback = debounce(this.setMobile, 500);

	setMobile() {
		this.setState({
			isMobile: window.innerWidth < this.props.breakPoint
		});
	}

	render() {
		const {
			altText,
			analyticEvent,
			breakPoint,
			desktopSrc,
			isInset,
			isMobile,
			linkUrl,
			mobileLinkUrl,
			mobileSrc,
			paddingBottom,
			paddingTop,
			videoFrameSrc,
			videoUrl
		} = this.props;

		const phoneClass = this.state.isMobile ? 'phone-on-mobile' : 'phone-on-desktop';
		const phoneVideoClass = this.state.isMobile ? 'phone-video-on-mobile' : 'phone-video-on-desktop';
		const phoneFrameClass = this.state.isMobile ? 'phone-frame-on-mobile' : 'phone-frame-on-desktop';
		const mobilePath = mobileLinkUrl || linkUrl;
		const path = this.state.isMobile ? mobilePath : linkUrl;

		return (
			<PageFlowContainer isInset={isInset} paddingBottom={paddingBottom} paddingTop={paddingTop}>
				<MaybeLinkWrap analyticEvent={analyticEvent} linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK} path={path}>
					<div className="BannerWithVideo">
						<AdaptiveImage
							altText={altText}
							breakPoint={breakPoint}
							desktopSrc={desktopSrc}
							isMobile={isMobile}
							mobileSrc={mobileSrc}
						/>
						<div className={classnames('phone', phoneClass)}>
							<video className={classnames('video', phoneVideoClass)} muted playsInline autoPlay loop>
								<source type="video/mp4" src={videoUrl} />
							</video>
							<div className={classnames('frame', phoneFrameClass)}>
								<img src={videoFrameSrc} alt="Video Frame" />
							</div>
						</div>
					</div>
				</MaybeLinkWrap>
			</PageFlowContainer>
		);
	}
}

BannerWithVideo.displayName = 'BannerWithVideo';

BannerWithVideo.propTypes = {
	altText: PropTypes.string,
	analyticEvent: PropTypes.func,
	breakPoint: PropTypes.number,
	desktopSrc: PropTypes.string,
	isInset: PropTypes.bool,
	isMobile: PropTypes.bool,
	linkUrl: PropTypes.string,
	mobileLinkUrl: PropTypes.string,
	mobileSrc: PropTypes.string,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	videoFrameSrc: PropTypes.string,
	videoUrl: PropTypes.string
};

BannerWithVideo.defaultProps = {
	breakPoint: 768,
	isMobile: false,
	isInset: true
};

export default BannerWithVideo;
