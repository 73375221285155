import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import BooksAreReady, { QUICK_FLOW_BOOKS_ARE_READY_URL } from './BooksAreReady/BooksAreReady';
import SourceLanding from './SourceLanding/SourceLanding';
import QuickFlow from './QuickFlow/QuickFlow';

export const QUICK_FLOW_URL = '/quick-flow';

export const QuickFlowLanding = ({ match }) => (
	<Fragment>
		<Helmet>
			<title>Quick Flow</title>
			<meta
				name="description"
				content="Quick Flow"
			/>
			<meta name="robots" content="noindex" />
		</Helmet>
		<Switch>
			<Route exact path={`${match.path}${QUICK_FLOW_BOOKS_ARE_READY_URL}`} component={BooksAreReady} />
			<Route exact path={`${match.path}/:source`} component={SourceLanding} />
			<Route component={QuickFlow} />
		</Switch>
	</Fragment>
);

QuickFlowLanding.propTypes = {
	match: PropTypes.object,
};

QuickFlowLanding.displayName = 'QuickFlowLanding';

export default withRouter(QuickFlowLanding);
