import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Checkbox.css';

const Checkbox = ({
	checked,
	disabled,
	className,
	id,
	label,
	name,
	onBlur,
	onChange,
	value,
}) => {
	if (!id) return null;

	return (
		<div
			className={classnames([className, 'Checkbox'])}
		>
			<label
				className="checkbox-label"
				htmlFor={`${id}-checkbox`}
			>
				<input
					type="checkbox"
					checked={checked}
					disabled={disabled}
					className="checkbox-input"
					id={`${id}-checkbox`}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					value={value}
				/>
				<div className="styled-checkbox" />
				{label}
			</label>
		</div>
	);
};

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.node,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Checkbox.defaultProps = {
	checked: false,
	disabled: false,
};

export default Checkbox;
