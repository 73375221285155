import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCheckmarkCircled = (props) => (
	<Svg
		{...props}
		viewBox="0 0 24 24"
		name="checkmark-circled"
	>
		<path
			d="M12 24C18.5799 24 24 18.5825 24 12.0057C24 5.42898 18.5684 0 12 0C5.4201 0 0 5.42898 0 12.0057C0 18.5825 5.4201 24 12 24ZM10.4498 18.089C9.97895 18.089 9.6 17.8709 9.2555 17.4692L5.95981 13.4978C5.71866 13.2224 5.61531 12.924 5.61531 12.6141C5.61531 11.9484 6.14354 11.4319 6.7866 11.4319C7.17703 11.4319 7.48708 11.5925 7.78565 11.9484L10.4153 15.208L15.4794 7.15065C15.755 6.70301 16.111 6.48494 16.5129 6.48494C17.1445 6.48494 17.7416 6.93257 17.7416 7.59828C17.7416 7.87374 17.6038 8.18364 17.4316 8.45911L11.6096 17.4347C11.3225 17.8594 10.9091 18.089 10.4498 18.089Z"
			fill="inherit"
		/>
	</Svg>
);

IconCheckmarkCircled.displayName = 'IconCheckmarkCircled';

IconCheckmarkCircled.propTypes = iconProps;

IconCheckmarkCircled.defaultProps = defaultIconProps;

export default IconCheckmarkCircled;
