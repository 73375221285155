export const BOOK_SIZES = {
	SIX_BY_SIX: 0,
	EIGHT_BY_EIGHT: 1,
	FIVE_BY_FIVE: 2,
	TEN_BY_TEN: 3,
};

export const BOOK_SIZE_STRINGS = {
	[BOOK_SIZES.SIX_BY_SIX]: 'SixBySix',
	[BOOK_SIZES.EIGHT_BY_EIGHT]: 'EightByEight',
	[BOOK_SIZES.FIVE_BY_FIVE]: 'FiveByFive',
	[BOOK_SIZES.TEN_BY_TEN]: 'TenByTen',
};
