import PropTypes from 'prop-types';

import { CHOOSE_PAYMENT_OPTIONS } from '../const/payment.const';

/**
 * Creates a paymentMethodSelectionModalModel data object.
 *
 * @param {object} paymentMethodSelectionModalModel The paymentMethodSelectionModalModel object.
 * @param {func} paymentMethodSelectionModalModel.doneCallback A callback called with the new selectPaymentMethodId
 * @param {object} paymentMethodSelectionModalModel.enabledFields A set of enabled fields
 * @param {string} paymentMethodSelectionModalModel.initialPaymentId A paymentMethod ID to initialize the form
 * @param {func} paymentMethodSelectionModalModel.onPaymentMethodChangeEvent A callback called when an option is tentatively chosen
 */
export const paymentMethodSelectionModalModel = ({
	doneCallback,
	enabledFields,
	initialPaymentId,
	onPaymentMethodChangeEvent,
}) => ({
	doneCallback,
	enabledFields: enabledFields || CHOOSE_PAYMENT_OPTIONS,
	initialPaymentId,
	onPaymentMethodChangeEvent,
});

export const paymentMethodSelectionModalPropType = PropTypes.shape({
	doneCallback: PropTypes.func,
	enabledFields: PropTypes.object,
	initialPaymentId: PropTypes.string,
	onPaymentMethodChangeEvent: PropTypes.func,
});
