import React from 'react';

import Review from './Review';
import reviewData from './Reviews.data';
import './Reviews.css';

const Reviews = () => (
	<section
		id="reviews"
		className="instagram-quickflow-page-reviews padding"
	>
		<ul className="instagram-quickflow-page-reviews-list">
			{reviewData.map((review, index) => (
				<Review
					key={`${index}-${review.username}`}
					{...review}
				/>
			))}
		</ul>
	</section>
);

export default Reviews;
