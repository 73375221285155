export const findAllRegexMatches = (pattern, string) => {
	const regex = new RegExp(pattern, 'g');
	const matches = [];

	let match;
	do {
		match = regex.exec(string);
		if (match) matches.push(match[1]);
	} while (match);

	return matches;
}