import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SimilarProduct from './SimilarProduct/SimilarProduct';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import { DEVICE_WIDTH, mediaQueryBetween, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../const';
import normalizeMeasurement from '../../helpers/normalizeMeasurement';

const SimilarProductsContainer = styled(PageFlowContainer)`
	background-color: ${(props) => props.backgroundColor};
	padding-bottom: ${(props) => props.paddingBottom};
	padding-top: ${(props) => props.paddingTop};

	${mediaQueryUpTo(DEVICE_WIDTH.MOBILE_L)} {
		padding-left: 4vw;
		padding-right: 4vw;
	}
	${mediaQueryBetween(DEVICE_WIDTH.MOBILE_L, DEVICE_WIDTH.DESKTOP)} {
		padding-left: 12vw;
		padding-right: 12vw;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.DESKTOP)} {
		padding-left: 80px;
		padding-right: 80px;
	}
`;

const SimilarProductsTitleDiv = styled.div`
	color: ${(props) => props.color};
	font-size: 32px;
	font-weight: 700;
	letter-spacing: -0.5px;
	line-height: 41px;
	padding-bottom: ${SPACING.BIGGER};
	padding-top: ${SPACING.BIGGER};
	text-align: center;
`;

const ProductsDiv = styled.div`
	display: grid;
	column-gap: ${SPACING.MEDIUM};
	row-gap: ${SPACING.BIG};
	padding-bottom: ${SPACING.BIGGER};

	${mediaQueryUpTo(DEVICE_WIDTH.MOBILE_L)} {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	${mediaQueryBetween(DEVICE_WIDTH.MOBILE_L, DEVICE_WIDTH.TABLET)} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
`;

const SimilarProducts = ({
	backgroundColor,
	products,
	paddingBottom,
	paddingTop,
	titleColor,
	title,
}) => {
	if (!products || !products.length) return null;

	return (
		<SimilarProductsContainer
			backgroundColor={backgroundColor}
			color={titleColor}
			paddingBottom={normalizeMeasurement(paddingBottom)}
			paddingTop={normalizeMeasurement(paddingTop)}
		>
			<SimilarProductsTitleDiv
				color={titleColor}>
				{title}
			</SimilarProductsTitleDiv>
			<ProductsDiv>
				{products.map((product, index) => (
					<SimilarProduct
						key={index}
						product={product}
					/>
				))}
			</ProductsDiv>
		</SimilarProductsContainer>
	);
};

SimilarProducts.displayName = 'SimilarProducts';

SimilarProducts.propTypes = {
	backgroundColor: PropTypes.object,
	products: PropTypes.array,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	titleColor: PropTypes.string,
	title: PropTypes.string,
};

SimilarProducts.defaultProps = {
	isInset: false,
};

export default SimilarProducts;
