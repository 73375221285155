import React from 'react';
import PropTypes from 'prop-types';

export const PreContent = ({ preContent }) => {
	if (!preContent) return null;

	return <h3 className="pre-content-cap">{preContent}</h3>;
};

PreContent.propTypes = {
	preContent: PropTypes.string
};

export default PreContent;
