import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconHeart = (props) => (
	<Svg
		{...props}
		name="close"
		fill="none"
		hasStroke={true}
		width={props.size * 1.104761905}
		viewBox="0 0 116 105"
	>
		<path
			d="M52.1623 100.695L52.1623 100.695L52.1508 100.687C48.1799 98.1529 43.9574 95.1851 39.4845 91.7884C34.9717 88.3614 30.5552 84.5589 26.2344 80.3835C21.8836 76.1791 17.9389 71.691 14.4023 66.9194C10.8305 62.1004 7.95963 57.0344 5.79959 51.7226C3.60407 46.3235 2.5 40.7597 2.5 35.0472C2.5 28.8945 3.83539 23.313 6.56472 18.3672C9.27663 13.453 13.0153 9.56178 17.7598 6.73834C22.5408 3.89314 27.9712 2.5 33.9773 2.5C39.8017 2.5 45.0355 4.05367 49.5885 7.20474C52.8793 9.48229 55.6807 12.2258 57.9857 15.4254C60.2768 12.2198 63.0927 9.47398 66.4232 7.19672C71.016 4.05634 76.243 2.5 82.0227 2.5C88.0288 2.5 93.4592 3.89314 98.2402 6.73834C102.985 9.56178 106.723 13.453 109.435 18.3672C112.165 23.313 113.5 28.8945 113.5 35.0472C113.5 40.7597 112.396 46.3235 110.2 51.7226C108.04 57.0344 105.17 62.1004 101.598 66.9194C98.0611 71.691 94.1164 76.1791 89.7656 80.3835C85.4449 84.5589 81.0284 88.3614 76.5155 91.7884C72.0426 95.1851 67.8201 98.1529 63.8493 100.687L63.8493 100.687L63.8377 100.695C62.0109 101.847 60.0536 102.5 58 102.5C55.9464 102.5 53.9892 101.847 52.1623 100.695Z"
			fill="none"
			stroke="inherit"
			strokeWidth="5"
			strokeLinejoin="round"
		/>
	</Svg>
);

IconHeart.displayName = 'IconHeart';

IconHeart.propTypes = iconProps;

IconHeart.defaultProps = defaultIconProps;

export default IconHeart;
