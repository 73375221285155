import deep from 'deep-get-set';
import { schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const orderCalculatedShippingOptionFromJson = (json = {}) => ({
	id: String(deep(json, 'ID')),
	title: deep(json, 'Title'),
	trackingDescriptionKey: deep(json, 'TrackingDescriptionKey'),
});

export const ordersCalculatedShippingOptionsFromJsonArray = (jsonArray) => (jsonArray || []).map(orderCalculatedShippingOptionFromJson);

export const orderCalculatedShippingOptionSchema = new schema.Entity(ENTITIES.ORDER_CALCULATED_SHIPPING_OPTION);
export const orderCalculatedShippingOptionsSchema = new schema.Array(orderCalculatedShippingOptionSchema);

export const getTitle = (orderCalculatedShippingOption) => deep(orderCalculatedShippingOption, 'title');

export const getTrackingDescriptionKey = (orderCalculatedShippingOption) => deep(orderCalculatedShippingOption, 'trackingDescriptionKey');
