export const isVisible = (currentItem, containerWidth, scrollLeft) => {
	if (!currentItem) {
		return false;
	}

	const { offsetLeft, offsetWidth } = currentItem;
	const isInLeftView = offsetLeft >= scrollLeft;

	const edgeOfItem = offsetLeft + offsetWidth;
	const edgeOfView = containerWidth + scrollLeft;
	const isInRightView = edgeOfItem <= edgeOfView;

	return isInLeftView && isInRightView;
};
