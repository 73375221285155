import { queryStringFromJson } from '../../helpers/locationUtils';
import { apiPostRecieveData, apiPost, apiGetData } from './api.http.actions';

export const getDataSources = () => (dispatch) => {
	return dispatch(apiGetData('/dataSources'));
};

export const getAnonymousDataSource = (id, key) => (dispatch) => {
	return dispatch(apiGetData(`/dataSources/getAnon?${queryStringFromJson({ id, key })}`));
};

export const postAddInstagramGraphDatasource = (data) => (dispatch) => {
	return dispatch(apiPostRecieveData('/dataSources/addInstagramGraphApi', data));
};

export const postReconnectFacebookAnon = (id, key, token) => (dispatch) => {
	return dispatch(apiPost('/dataSources/reconnectFacebookAnon', { id, key, token }));
};

export const postReconnectInstagramAnon = (data) => (dispatch) => {
	return dispatch(apiPost('/dataSources/reconnectInstagramAnon', data));
};

export const postReconnectDataSource = (dataSourceId, token, instagramLongLivedToken) => (dispatch) => {
	const data = { id: dataSourceId };

	if (instagramLongLivedToken) data.instagramLongLivedToken = instagramLongLivedToken;
	if (token) data.token = token;

	return dispatch(apiPost('/dataSources/reconnect', data));
};