import React from 'react';
import PropTypes from 'prop-types';

import { convertActionUri } from '../DuploBlock.helpers';
import Image from './Image';
import Wrapper from '../Wrapper/Wrapper';
import MaybeLinkWrap from '../../MaybeLinkWrap/MaybeLinkWrap';
import './TwoImage.css';

const TwoImage = ({
	actionUri,
	analyticEvent,
	analyticObj,
	caption1,
	caption2,
	image1,
	image2,
}) => (
	<Wrapper className="duplo-block duplo-block-two-image">
		<MaybeLinkWrap
			analyticEvent={analyticEvent}
			analyticObj={analyticObj}
			path={actionUri && convertActionUri(actionUri)}
		>
			<Image
				caption={caption1}
				image={image1}
			/>
			<Image
				caption={caption2}
				image={image2}
			/>
		</MaybeLinkWrap>
	</Wrapper>
);

TwoImage.propTypes = {
	actionUri: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	caption1: PropTypes.string,
	caption2: PropTypes.string,
	image1: PropTypes.string,
	image2: PropTypes.string,
};

export default TwoImage;
