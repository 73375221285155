import { apiDelete, apiGetData, apiPostRecieveData, apiPutRecieveData } from './api.http.actions';
import { addressFromJson } from '../../model/address.model';

export const deleteAddress = (id) => (dispatch) => {
	return dispatch(apiDelete(`/addresses/${id}`))
};

export const getAddresses = () => (dispatch) => {
	return dispatch(apiGetData('/addresses'))
		.then((data) => data.addresses || [])
};


export const postAddress = ({
	name,
	street1,
	street2,
	city,
	state,
	postalCode,
	countryCode
}) => (dispatch) => {
	return dispatch(apiPostRecieveData('/addresses', {
		name,
		street1,
		street2,
		city,
		state,
		postalCode,
		countryCode
	}))
		.then((json) => addressFromJson(json.address));
};

export const putAddress = (id, {
	name,
	street1,
	street2,
	city,
	state,
	postalCode,
	countryCode
}) => (dispatch) => {
	return dispatch(apiPutRecieveData(`/addresses/${id}`, {
		name,
		street1,
		street2,
		city,
		state,
		postalCode,
		countryCode
	}))
		.then((json) => addressFromJson(json.address));
};
