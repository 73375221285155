import deep from 'deep-get-set';

import { roundToTwoDecimals } from '../numbers/numbers';

const delayInMs = 15;
const maxDurationInMs = 1000;
const minStepInPx = 100;
const headerHeight = 60
const loopBreakOutNum = 1000;

const getAppBodyElement = () => {
	const appBodyList = document.getElementsByClassName('app-body');

	return deep(appBodyList, `${0}`);
};

const getNumFrames = (maxDurationInMs, delayInMs) =>
	Math.ceil(maxDurationInMs / delayInMs);

const getLowestScroll = () =>
	window.scrollHeight - window.outerHeight;

const getTempScrollStepDistanceInPx = (scrollTop, numFrames) =>
	Math.ceil(scrollTop / numFrames);

const getIsScrollingUp = (targetOffsetTop) => {
	const appBody = getAppBodyElement();

	return appBody.scrollTop > targetOffsetTop;
};

export const scrollAppBodyTo = (targetOffsetTop) => {
	const appBody = getAppBodyElement();

	if (!!appBody) {
		let loopNum = 0;
		const numFrames = getNumFrames(maxDurationInMs, delayInMs);
		const tempScrollStepDistanceInPx = getTempScrollStepDistanceInPx(appBody.scrollTop, numFrames);
		let scrollStepDistanceInPx = (tempScrollStepDistanceInPx < minStepInPx) ? minStepInPx : tempScrollStepDistanceInPx;
		const isScrollingUp = getIsScrollingUp(targetOffsetTop);

		scrollStepDistanceInPx = (isScrollingUp) ? -scrollStepDistanceInPx : scrollStepDistanceInPx;

		const scrollInterval = setInterval(() => {
			const absDiff = Math.abs(appBody.scrollTop - targetOffsetTop);
			const absScrollStepDistanceInPx = Math.abs(scrollStepDistanceInPx);

			if (absDiff <= absScrollStepDistanceInPx) {
				appBody.scrollTop = targetOffsetTop;
				clearInterval(scrollInterval);
			} else {
				appBody.scrollTop += scrollStepDistanceInPx;
			}

			loopNum++;
			if (loopNum >= loopBreakOutNum) clearInterval(scrollInterval);
		}, delayInMs);
	}
};

export const scrollBodyToElement = (reviewId) => {
	const appBody = getAppBodyElement();
	const reviewsElement = document.getElementById(reviewId);

	if (!!appBody && !!reviewsElement) {
		let targetOffsetTop = reviewsElement.offsetTop - headerHeight;
		const isScrollingUp = getIsScrollingUp(targetOffsetTop);
		const lowestScroll = getLowestScroll();

		if (!isScrollingUp) {
			if (targetOffsetTop > lowestScroll) {
				targetOffsetTop = lowestScroll;
			}
		}

		scrollAppBodyTo(targetOffsetTop);
	}
};

export const scrollToTop = () => scrollAppBodyTo(0);

export const getAppBodyScrollPercent = () => {
	const appBody = getAppBodyElement();
	const scrollTop = appBody.scrollTop;
	const scrollHeight = appBody.scrollHeight;
	const clientHeight = appBody.clientHeight;
	const scrollableHeight = scrollHeight - clientHeight;
	const percent = scrollTop / scrollableHeight;

	return roundToTwoDecimals(percent);
};
