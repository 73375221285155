
const isActive = () => {
	if (typeof window !== 'undefined') {
		// eslint-disable-next-line no-prototype-builtins
		return window.hasOwnProperty('Intercom');
	}

	return false;
};

const boot = (options) => {
	const timeout = setTimeout(() => boot(options), 500);
	if (isActive()) {
		window.Intercom('boot', options);
		clearTimeout(timeout);
	}
};

// Used to clear user conversations on logout
const shutdown = () => {
	isActive() && window.Intercom('shutdown');
};

const hide = () => {
	isActive() && window.Intercom('hide');
};

const show = () => {
	isActive() && window.Intercom('show');
};

const update = (userData) => {
	isActive() && window.Intercom('update', userData);
};

const showSpace = (space) => {
	isActive() && window.Intercom('showSpace', space);
};

const showNewMessage = (message) => {
	isActive() && window.Intercom('showNewMessage', message);
};

export default {
	boot,
	shutdown,
	hide,
	show,
	setIdentity: update,
	showSpace,
	showNewMessage,
};
