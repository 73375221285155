import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import * as FacebookDS from './facebookDataSource.model';
import * as GoogleDS from './googleDataSource.model';
import * as InstagramDS from './instagramDataSource.model';
import * as InstagramGraphDS from './instagramGraphDataSource.model';
import * as LocalDS from './localDataSource.model';
import { DATA_SOURCE_TYPE } from '../../const/dataSource.const';
import { ENTITIES } from '../../const/entities/entities';
import { isDataSourceTypeOfInstagram, isDataSourceTypeOfInstagramGraph, isDataSourceTypeOfInstagramLegacy } from '../../helpers/dataSource/dataSource.helpers';

export const dataSourceFromJson = (json = {}) => {
	const model = {
		id: deep(json, 'ID'),
		isAuthenticated: deep(json, 'IsAuthenticated'),
		type: deep(json, 'Type'),
	};

	if (json.Facebook) model.facebook = FacebookDS.facebookDataSourceFromJson(json.Facebook);
	if (json.Google) model.google = GoogleDS.googleDataSourceFromJson(json.Google);
	if (json.Instagram) model.instagram = InstagramDS.instagramDataSourceFromJson(json.Instagram);
	if (json.InstagramGraphApi) model.instagramGraphApi = InstagramGraphDS.instagramGraphDataSourceFromJson(json.InstagramGraphApi);
	if (json.Local) model.local = LocalDS.localDataSourceFromJson(json.Local);

	return model;
};

export const dataSourceSchema = new schema.Entity(ENTITIES.DATA_SOURCE);
export const dataSourcesSchema = new schema.Array(dataSourceSchema);

export const normalizeDataSource = (dataSource) => normalize(dataSource, dataSourceSchema);
export const normalizeDataSources = (dataSources) => normalize(dataSources, dataSourcesSchema);

export const getIcon = (dataSource) => {
	const iconMap = {
		[DATA_SOURCE_TYPE.LOCAL]: '/assets/img/local_photos_logo.png',
		[DATA_SOURCE_TYPE.INSTAGRAM]: '/assets/img/source_instagram.svg',
		[DATA_SOURCE_TYPE.FACEBOOK]: '/assets/img/source_facebook.svg',
		[DATA_SOURCE_TYPE.GOOGLE_PHOTOS]: '/assets/img/source_google.svg',
		[DATA_SOURCE_TYPE.INSTAGRAM_GRAPH]: '/assets/img/source_instagram.svg',
	};

	return iconMap[deep(dataSource, 'type')];
};

export const getName = (dataSource) => {
	switch (deep(dataSource, 'type')) {
		case DATA_SOURCE_TYPE.FACEBOOK:
			return FacebookDS.getName(dataSource.facebook);
		case DATA_SOURCE_TYPE.GOOGLE_PHOTOS:
			return GoogleDS.getName(dataSource.google);
		case DATA_SOURCE_TYPE.INSTAGRAM:
			return InstagramDS.getName(dataSource.instagram);
		case DATA_SOURCE_TYPE.INSTAGRAM_GRAPH:
			return InstagramGraphDS.getName(dataSource.instagramGraphApi);
		case DATA_SOURCE_TYPE.LOCAL:
			return LocalDS.getName(dataSource.local);
		default:
	}
};

export const getUsername = (dataSource) => {
	switch (deep(dataSource, 'type')) {
		case DATA_SOURCE_TYPE.FACEBOOK:
			return FacebookDS.getUsername(dataSource.facebook);
		case DATA_SOURCE_TYPE.GOOGLE_PHOTOS:
			return GoogleDS.getUsername(dataSource.google);
		case DATA_SOURCE_TYPE.INSTAGRAM:
			return InstagramDS.getUsername(dataSource.instagram);
		case DATA_SOURCE_TYPE.INSTAGRAM_GRAPH:
			return InstagramGraphDS.getUsername(dataSource.instagramGraphApi);
		case DATA_SOURCE_TYPE.LOCAL:
			return LocalDS.getUsername(dataSource.local);
		default:
	}
};

export const isTypeOfFacebook = (dataSource) => deep(dataSource, 'type') === DATA_SOURCE_TYPE.FACEBOOK;

export const isTypeOfInstagram = (dataSource) => isDataSourceTypeOfInstagram(deep(dataSource, 'type'));

export const isTypeOfInstagramGraph = (dataSource) => isDataSourceTypeOfInstagramGraph(deep(dataSource, 'type'));

export const isTypeOfInstagramLegacy = (dataSource) => isDataSourceTypeOfInstagramLegacy(deep(dataSource, 'type'));
