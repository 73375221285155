import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BlogRecommendation from './BlogRecommendation/BlogRecommendation';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import withWindowSize from '../../components/WindowSize/withWindowSize';
import { DEVICE_WIDTH, mediaQueryBetween, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../const';
import normalizeMeasurement from '../../helpers/normalizeMeasurement';

const BlogRecommendationsContainer = styled(PageFlowContainer)`
	background-color: ${(props) => props.backgroundColor};
	color: ${(props) => props.color};
	padding-bottom: ${(props) => props.paddingBottom};
	padding-top: ${(props) => props.paddingTop};

	${mediaQueryUpTo(DEVICE_WIDTH.MOBILE_L)} {
		padding-left: 4vw;
		padding-right: 4vw;
	}
	${mediaQueryBetween(DEVICE_WIDTH.MOBILE_L, DEVICE_WIDTH.DESKTOP)} {
		padding-left: 6vw;
		padding-right: 6vw;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.DESKTOP)} {
		padding-left: ${SPACING.BIGGER};
		padding-right: ${SPACING.BIGGER};
	}
`;

const BlogRecommendationsTitleDiv = styled.div`
	font-size: 32px;
	font-weight: 700;
	line-height: 41px;
	letter-spacing: -0.5px;
	text-align: center;
	padding-top: ${SPACING.BIGGER};
`;

const BlogRecommendationsDescriptionDiv = styled.div`
	font-size: 20px;
	font-weight: 500;
	letter-spacing: -0.25px;
	line-height: 27px;
	margin: auto;
	max-width: 425px;
	padding-bottom: ${SPACING.BIGGER};
	padding-top: ${SPACING.MEDIUM};
	text-align: center;
`;

const BlogPostsDiv = styled.div`
	display: grid;
	column-gap: ${SPACING.MEDIUM};
	row-gap: ${SPACING.BIG};
	padding-bottom: ${SPACING.BIGGER};

	${mediaQueryUpTo(DEVICE_WIDTH.MOBILE_S)} {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	${mediaQueryBetween(DEVICE_WIDTH.MOBILE_L, DEVICE_WIDTH.TABLET)} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
`;

const BlogRecommendations = ({
	analyticEvent,
	backgroundColor,
	blogPosts,
	description,
	linkCallback,
	paddingBottom,
	paddingTop,
	textColor,
	title,
}) => {
	if (!blogPosts || !blogPosts.length) return null;

	return (
		<BlogRecommendationsContainer
			backgroundColor={backgroundColor}
			color={textColor}
			paddingBottom={normalizeMeasurement(paddingBottom)}
			paddingTop={normalizeMeasurement(paddingTop)}
		>
			<BlogRecommendationsTitleDiv>
				{title}
			</BlogRecommendationsTitleDiv>
			<BlogRecommendationsDescriptionDiv>
				{description}
			</BlogRecommendationsDescriptionDiv>
			<BlogPostsDiv>
				{blogPosts.map((blogPost, index) => (
					<BlogRecommendation
						key={index}
						blogPost={blogPost}
					/>
				))}
			</BlogPostsDiv>
		</BlogRecommendationsContainer>
	);
};

BlogRecommendations.displayName = 'BlogRecommendations';

BlogRecommendations.propTypes = {
	analyticEvent: PropTypes.func,
	backgroundColor: PropTypes.string,
	blogPosts: PropTypes.array,
	description: PropTypes.string,
	linkCallback: PropTypes.func,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	textColor: PropTypes.string,
	title: PropTypes.string,
};

BlogRecommendations.defaultProps = {
	isInset: false,
};

export default withWindowSize(BlogRecommendations);
