import React from 'react';

import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconExternalLink = (props) => {
	const { size } = props;
	const heightRatio = 16;
	const widthRatio = 14	;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="trash"
			width={width}
			height={height}
			viewBox="0 0 20 20"
		>
			<path
				d="M18.3232 1.41406C17.2773 0.368164 15.8008 0.204102 14.0474 0.204102H6.05957C4.33691 0.204102 2.86035 0.368164 1.81445 1.41406C0.768555 2.45996 0.614746 3.92627 0.614746 5.63867V13.5547C0.614746 15.3081 0.768555 16.7642 1.81445 17.8101C2.86035 18.856 4.33691 19.02 6.08008 19.02H14.0474C15.8008 19.02 17.2773 18.856 18.3232 17.8101C19.3691 16.7642 19.5229 15.3081 19.5229 13.5547V5.66943C19.5229 3.91602 19.3691 2.44971 18.3232 1.41406ZM17.8721 5.38232V13.8315C17.8721 14.8979 17.7388 15.9849 17.1133 16.6104C16.498 17.2256 15.3906 17.3691 14.3345 17.3691H5.80322C4.74707 17.3691 3.63965 17.2256 3.01416 16.6104C2.39893 15.9849 2.26562 14.8979 2.26562 13.8315V5.41309C2.26562 4.33643 2.39893 3.229 3.01416 2.61377C3.63965 1.98828 4.75732 1.85498 5.83398 1.85498H14.3345C15.3906 1.85498 16.498 1.99854 17.1133 2.61377C17.7388 3.23926 17.8721 4.32617 17.8721 5.38232ZM13.2886 12.1396C13.7397 12.1396 14.0371 11.791 14.0371 11.3193V6.47949C14.0371 5.87451 13.709 5.63867 13.186 5.63867H8.31543C7.8335 5.63867 7.52588 5.93604 7.52588 6.38721C7.52588 6.83838 7.84375 7.13574 8.33594 7.13574H10.2021L11.7197 6.97168L10.1201 8.4585L6.34668 12.2422C6.20312 12.3857 6.10059 12.5908 6.10059 12.7959C6.10059 13.2573 6.39795 13.5547 6.84912 13.5547C7.09521 13.5547 7.29004 13.4624 7.43359 13.3188L11.207 9.54541L12.6836 7.96631L12.5298 9.56592V11.3398C12.5298 11.8218 12.8271 12.1396 13.2886 12.1396Z"
				fill="inherit"
			/>
		</Svg>
	);
};

IconExternalLink.displayName = 'IconExternalLink';

IconExternalLink.propTypes = iconProps;

IconExternalLink.defaultProps = {
	...defaultIconProps,
	size: 16,
};

export default IconExternalLink;
