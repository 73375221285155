import React from 'react';
import PropTypes from 'prop-types';

import { convertImageUrl } from '../DuploBlock.helpers';
import './Image.css';

const Image = ({
	src,
}) => (
	<li>
		<img
			src={convertImageUrl(src)}
			alt=""
			className="gallery-image"
		/>
	</li>
);

Image.propTypes = {
	src: PropTypes.string,
};

export default Image;
