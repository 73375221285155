import deep from 'deep-get-set';

import * as ACTIONS from './actionTypes';
import * as awsActions from './aws.actions';
import * as branchActions from './branch.actions';
import {
	setGdprDisplayedCookie,
	setUuidCookie,
	setVisitedAppCookie,
	setVisitedBlogCookie,
	setVisitedMarketingSiteCookie,
} from './appCookie.actions';
import { EVENT_QUEUES } from '../const/events/eventQueues.const';
import { appCookie } from '../cookies';
import { getValueByKey } from '../helpers/analytics/Analytics.helpers';
// import AwsService from '../helpers/analytics/AWS/AwsService';
// import Blueshift from '../helpers/analytics/Blueshift/Blueshift';
import Branch from '../helpers/analytics/Branch/Branch';
import Kochava from '../helpers/analytics/Kochava/Kochava';
import Events from '../helpers/analytics/Events';
import MouseFlow from '../helpers/analytics/MouseFlow/MouseFlow';
import generateUUID from '../helpers/generateUUID';
import Intercom from '../helpers/intercom/Intercom';
import { queryStringToJson } from '../helpers/locationUtils';
import { selectQueuedUserEvents, selectQueuedCartEvents } from '../selectors/analytics.selectors';
import { selectUserHasVisitedApp, selectUserHasVisitedBlog, selectUserHasVisitedMarketing, selectUuidCookie } from '../selectors/appCookie.selectors';
import { selectUserIdString, selectUserLongId } from '../selectors/user.selectors';
import { selectCartId } from '../selectors/checkout.selectors';
import { selectEnvironmentConfig } from '../selectors/config.selectors';
import { selectCurrentBookId } from '../selectors/groups.selectors';
import { selectViewGroupId } from '../selectors/view.selectors';
import { sendPageView } from '../vendors/googleAnalytics';
import { Amplitude } from '../helpers/analytics/Amplitude/AmplitudeService';
import { Rudderstack } from '../helpers/analytics/Rudderstack/RudderstackService';

export const enqueueEvent = (event, queueName) => ({ type: ACTIONS.ANALYTICS_ENQUEUE_EVENT, data: { event, queueName } });
export const flushEventQueue = (queueName) => ({ type: ACTIONS.ANALYTICS_FLUSH_EVENT_QUEUE, data: { queueName } });
const analyticsEventTriggered = (event, attrs) => ({ type: ACTIONS.ANALYTICS_EVENT_TRIGGERED, data: { event, attrs } });

export const cartIdChanged = () => (dispatch, getState) => {
	const queuedEvents = selectQueuedCartEvents(getState());
	const shoppingcartid = selectCartId(getState());

	queuedEvents.forEach((event) => {
		const { name, customAttributes } = event;
		dispatch(triggerAnalyticsEvent(name, { ...customAttributes, shoppingcartid }));
	});

	dispatch(flushCartEvents());
};

export const clearAnalyticsUser = () => () => {
	Branch.logout();
	Amplitude.reset();
	Rudderstack.reset();
};

export const clickTracking = (name, customAttributes = {}) => (dispatch) => {
	dispatch(triggerAnalyticsEvent(name, customAttributes));
};

export const enqueueCartEvent = (event) => (dispatch) => dispatch(enqueueEvent(event, EVENT_QUEUES.CART));
export const enqueueUserEvent = (event) => (dispatch) => dispatch(enqueueEvent(event, EVENT_QUEUES.USER));

export const enteredApp = () => (dispatch, getState) => {
	if (!selectUserHasVisitedApp(getState())) {
		dispatch(setVisitedAppCookie());
	}
};

export const enteredBlog = () => (dispatch, getState) => {
	if (!selectUserHasVisitedBlog(getState())) {
		dispatch(setVisitedBlogCookie());
	}
};

export const enteredMarketingSite = () => (dispatch, getState) => {
	if (!selectUserHasVisitedMarketing(getState())) {
		dispatch(setVisitedMarketingSiteCookie());
	}
};

export const flushCartEvents = () => (dispatch) => dispatch(flushEventQueue(EVENT_QUEUES.CART));
export const flushUserEvents = () => (dispatch) => dispatch(flushEventQueue(EVENT_QUEUES.USER));

export const gdprAccepted = () => async (dispatch) => {
	const ipAddress = await fetch('https://api.ipify.org').then(res => res.text());

	dispatch(triggerAnalyticsEvent('CookiesAccepted', { attr1: ipAddress }));
	appCookie().set({ gdprAccepted: true });
};

export const gdprDisplayed = () => async (dispatch) => {
	const ipAddress = await fetch('https://api.ipify.org').then(res => res.text());

	dispatch(triggerAnalyticsEvent('CookiesDisplayed', { attr1: ipAddress }));
	dispatch(setGdprDisplayedCookie());
};

export const initAws = () => (dispatch) => {
	dispatch(awsActions.init());
};
export const initBranch = (history, location) => (dispatch) => {
	dispatch(branchActions.init(history, location));
};
export const initUuidCookie = () => (dispatch) => {
	dispatch(initializeUuidCookie());
};

const initializeUuidCookie = () => (dispatch, getState) => {
	const storedUuid = selectUuidCookie(getState());
	if (!storedUuid) dispatch(setUuidCookie(generateUUID()));
};

export const personIdChanged = () => (dispatch, getState) => {
	const state = getState();
	const queuedEvents = selectQueuedUserEvents(state);

	queuedEvents.forEach((event) => {
		const { name, customAttributes } = event;
		dispatch(triggerAnalyticsEvent(name, customAttributes));
	});

	dispatch(flushUserEvents());
};

export const setAnalyticsUser = (user) => () => {
	const userId = user.LongId;

	Intercom.setIdentity(user);

	// Amplitude.setIdentity(user);
	// MouseFlow.setIdentity(userId);
	// Blueshift.setIdentity(user);
	Branch.setIdentity(userId);
	Kochava.identify({
		longpersonid: user.LongId,
		personid: user.ID
	});
	
	if (user.IsApproved || user.IsFullUser) {
		Rudderstack.identify(user);
	}
	// _1Flow.identify(user);
};

export const tagBookSeriesInfoBookPressed = () => (dispatch, getState) => {
	const state = getState();
	const bookId = selectCurrentBookId(state);
	const groupId = selectViewGroupId(state);

	dispatch(triggerAnalyticsEvent('SeriesInfo_EditBook', {
		attr2: bookId,
		chatgroupid: groupId,
	}));
};

export const tagChangePaymentMethodCompleted = () => (dispatch) => {
	dispatch(triggerAnalyticsEvent('Change_Payment_Method_Completed'));
};

export const tagChangePaymentMethodOpened = () => (dispatch) => {
	dispatch(triggerAnalyticsEvent('Change_Payment_Method_Opened'));
};

export const tagCokeCopyLinkClick = () => (dispatch) => {
	dispatch(triggerAnalyticsEvent('coke_copy_link'));
};

export const tagCreateGiftCardPressed = () => (dispatch) => {
	dispatch(triggerAnalyticsEvent('Create_GiftCardPressed'));
};

export const tagCreatePage = () => (dispatch) => {
	dispatch(trackScreenByName('Create'));
};

export const tagGiftingCallToActionClick = () => (dispatch) => {
	dispatch(triggerAnalyticsEvent('Gifting_CTA_Clicked'));
};

export const tagLandingPageLoad = (url, params) => (dispatch) => {
	if (url === '/invite') dispatch(tagReferralLandingPageLoad(params));
};

export const tagReferralLandingPageLoad = (params) => (dispatch) => {
	dispatch(triggerOrQueueEventRequiringPersonId('ReferralLanding_Load', {
		attr1: params.link,
		attr2: params.friend,
	}));
};

export const tagReferralLandingPageAction = (link, friend) => (dispatch) => {
	dispatch(triggerOrQueueEventRequiringPersonId('ReferralLanding_Action', {
		attr1: link,
		attr2: friend,
	}));
};

export const tagStartOrder = (group) => (dispatch) => {
	if (group.isCustomPrints()) {
		dispatch(triggerAnalyticsEvent(Events.orderCustomPrintsClicked));
	}
};

export const triggerAnalyticsEvent = (event, customAttributes = {}) => (dispatch, getState) => {

	try {
		const state = getState();
		const userIdString = selectUserIdString(state);
		const userLongId = selectUserLongId(state);
		const configEnvironment = selectEnvironmentConfig(state);
		const uuid = selectUuidCookie(state);

		const attributes = {
			...customAttributes
		};
		let name;

		if (typeof event === 'string') {
			name = event;
			const eventValues = getValueByKey(event, ['screen', 'action']);
			Object.assign(attributes, eventValues);
		} else if (typeof event === 'object') {
			name = deep(event, 'name');
			attributes.screen = deep(event, 'screen') || '';
			attributes.action = deep(event, 'action') || '';
		}

		if (userLongId) {
			attributes.personid = userLongId;
		}
		if (userIdString) {
			attributes.personidstring = userIdString;
		}
		if (configEnvironment) {
			attributes.environment = configEnvironment;
		}
		if (uuid) {
			attributes.appInstallationID = uuid;
		}

		attributes.url_parameters = queryStringToJson(window.location.search);
		
		// Rudderstack migration is ongoing, here is how we will handle it:
		// Amplitude.trackEvent(name, attributes); // we will turn off after rudderstack migration is complete
		// AwsService.recordEvent(name, attributes); // we will turn off after rudderstack migration is complete
		// Blueshift.track(name, attributes); // we will turn off after rudderstack migration is complete
		Branch.track(name, attributes); // leave as-is
		Kochava.activity(name, attributes); // leave as-is
		Rudderstack.trackEvent(name, attributes);
		dispatch(analyticsEventTriggered(name, attributes));

		return Promise.resolve();

	} catch (err) {
		console.error(err);
	}
};

// These events also require a personId, but if you have a cartId, then you also have a personId
export const triggerOrQueueEventRequiringCartId = (eventName, customAttributes = {}) => (dispatch, getState) => {
	const shoppingcartid = selectCartId(getState());

	if (!!shoppingcartid) {
		return dispatch(triggerAnalyticsEvent(eventName, {
			...customAttributes,
			shoppingcartid
		}));
	} else {
		return dispatch(enqueueCartEvent({ name: eventName, customAttributes }));
	}
};

export const triggerOrQueueEventRequiringPersonId = (eventName, customAttributes = {}) => (dispatch, getState) => {
	const personId = selectUserLongId(getState());

	if (!!personId) {
		return dispatch(triggerAnalyticsEvent(eventName, customAttributes));
	} else {
		return dispatch(enqueueUserEvent({ name: eventName, customAttributes }));
	}
};

export const trackBookInfoScreen = () => (dispatch) => {
	dispatch(trackScreenByName('Book_Info'));
};

export const trackScreenByName = (screenName) => () => {
	Kochava.page(screenName);
};

export const trackCurrentPage = (page) => () => {
	MouseFlow.pageViewed(page);

	const delayForHelmetTitle = 1000;
	setTimeout(() => {
		const protocol = deep(window, 'location.protocol');
		const hostname = deep(window, 'location.hostname');
		const location = protocol + '//' + hostname;

		const title = deep(document, 'title');
		sendPageView({ location, page, title });
	}, delayForHelmetTitle);
};
