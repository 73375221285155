import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TornBookImage from './TornBookImage/TornBookImage';
import './PageError.css';

const PageError = ({ errorSubtext, errorTitle, primaryButtonProps, secondaryButtonProps }) => (
	<div className="PageError">
		<div className="inner">
			<TornBookImage className="image" />
			<div className="error">
				{ errorTitle && <h1 className="error-title">{errorTitle}</h1> }
				{ errorSubtext && <p className="error-subtext">{errorSubtext}</p> }
			</div>
			<div className="call-to-action">
				{ primaryButtonProps && <button className={classnames(['button', { 'alone': !secondaryButtonProps }])} {...primaryButtonProps} /> }
				{ secondaryButtonProps && <button className="button hollow" {...secondaryButtonProps} /> }
			</div>
		</div>
	</div>
);

PageError.displayName = 'PageError';

PageError.propTypes = {
	errorTitle: PropTypes.node,
	errorSubtext: PropTypes.node,
	primaryButtonProps: PropTypes.object,
	secondaryButtonProps: PropTypes.object,
};

export default PageError;