import React from 'react';
import PropTypes from 'prop-types';

import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';
import './IconFullCart.css';

const IconFullCart = (props) => (
	<span className="IconFullCart">
		<Svg
			className="full-cart-svg"
			{...props}
			hasStroke={false}
			height={props.size * 30 / 37}
			viewBox="0 0 37 30"
			name="fullCart"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H6.4L8.83922 20.1961C8.93271 20.6635 9.34312 21 9.8198 21H10.5H11H25H26H26.1529C26.6417 21 27.0589 20.6466 27.1393 20.1644L28.8086 10.1486C28.9088 9.54732 28.4451 9 27.8356 9C27.3534 9 26.9419 9.3486 26.8626 9.82423L25.3333 19H10.6L8.16078 6.80388C8.06729 6.33646 7.65688 6 7.1802 6H7H6H3ZM15 25.5C15 26.8807 13.8807 28 12.5 28C11.1193 28 10 26.8807 10 25.5C10 24.1193 11.1193 23 12.5 23C13.8807 23 15 24.1193 15 25.5ZM23.5 28C24.8807 28 26 26.8807 26 25.5C26 24.1193 24.8807 23 23.5 23C22.1193 23 21 24.1193 21 25.5C21 26.8807 22.1193 28 23.5 28Z"
				fill="inherit"
			/>
		</Svg>
		<span className="count">{props.count}</span>
	</span>
);

IconFullCart.displayName = 'IconFullCart';

IconFullCart.propTypes = {
	...iconProps,
	count: PropTypes.number
};

IconFullCart.defaultProps = {
	...defaultIconProps,
	count: 0
};

export default IconFullCart;
