import React from 'react';
import PropTypes from 'prop-types';

import './Title.css';

const Title = ({
	title,
}) => (
	<h1>{title}</h1>
);

Title.displayName = 'Title';

Title.propTypes = {
	title: PropTypes.string,
};

export default Title;
