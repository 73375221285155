import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ConfigurableLink from '../../ConfigurableLink/ConfigurableLink';
import './ButtonBannerButton.css';

export const ButtonBannerButton = ({
	action,
	analyticObj,
	analyticEvent,
	buttonStyle,
	image,
	linkElement,
	linkElementPathAttr,
	path,
	text
}) => {
	const content = image ? <img src={image} alt={text} /> : text;
	let className = 'ButtonBannerButton';
	if (!image) {
		className = classnames(className, 'button large', buttonStyle);
	}

	return (
		<ConfigurableLink
			action={action}
			analyticEvent={analyticEvent}
			analyticObj={analyticObj}
			className={className}
			linkElement={linkElement}
			linkElementPathAttr={linkElementPathAttr}
			path={path}
		>
			{content}
		</ConfigurableLink>
	);
};

ButtonBannerButton.displayName = 'ButtonBannerButton';

ButtonBannerButton.propTypes = {
	action: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	buttonStyle: PropTypes.string,
	image: PropTypes.string,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	path: PropTypes.string,
	text: PropTypes.any
};

export default ButtonBannerButton;
