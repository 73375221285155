import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ButtonBannerButton from './ButtonBannerButton/ButtonBannerButton';
import normalizeMeasurement from '../../helpers/normalizeMeasurement';
import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import { HEADER_POSITIONS } from '../../const';
import './ButtonBanner.css';

class ButtonBanner extends Component {
	getButtonContainer(buttons, position) {
		const buttonColumns = buttons;
		let containerClasses = this.getClasses(position);
		let gridClasses = ['grid-x', 'align-middle', 'banner-buttons'];
		if (position === HEADER_POSITIONS.RIGHT) {
			gridClasses = [...gridClasses, 'align-right'];
			containerClasses = [...containerClasses, 'medium-order-2', 'large-order-1'];
		} else if (position === HEADER_POSITIONS.BOTTOM) {
			containerClasses = [...containerClasses, 'small-order-1'];
		}
		if (this.isStacked(position)) {
			gridClasses = [...gridClasses, 'align-center'];
		}

		return (
			<div key="ButtonBannerButtons" className={containerClasses.join(' ')}>
				<div className={gridClasses.join(' ')}>{buttonColumns}</div>
			</div>
		);
	}

	getClasses(position) {
		const classes = ['cell', 'medium-12'];
		if (this.isStacked(position)) {
			classes.push('large-12');
		} else {
			classes.push('large-6');
		}

		return classes;
	}

	getHeader(title, position) {
		let classes = this.getClasses(position);
		if (position === HEADER_POSITIONS.RIGHT) {
			classes = [...classes, 'medium-order-1', 'large-order-2'];
		} else if (position === HEADER_POSITIONS.BOTTOM) {
			classes = [...classes, 'small-order-2'];
		}

		return (
			<div key="ButtonBannerHeader" className={classes.join(' ')}>
				<h4 className={`header-${position}`}>{title}</h4>
			</div>
		);
	}

	isStacked(position) {
		return position === HEADER_POSITIONS.TOP || position === HEADER_POSITIONS.BOTTOM;
	}

	render() {
		const {
			backgroundColor,
			children: childrenProp,
			color,
			headerPosition,
			isInset,
			paddingBottom,
			paddingTop,
			title
		} = this.props;
		const children = React.Children.toArray(childrenProp);
		const columns = children.length === 0 ? 'shrink' : `medium-shrink small-${Math.ceil(12 / children.length)}`;
		const buttons = children.map((child, index) => (
			<div key={`ButtonBannerButton-${index}`} className={`col ${columns}`}>
				{child}
			</div>
		));
		const style = {
			backgroundColor,
			color,
			paddingBottom: normalizeMeasurement(paddingBottom),
			paddingTop: normalizeMeasurement(paddingTop)
		};
		const header = this.getHeader(title, headerPosition);
		const buttonContainer = this.getButtonContainer(buttons, headerPosition);
		const className = classnames('ButtonBanner');

		return (
			<PageFlowContainer isInset={isInset}>
				<div className={className} style={style}>
					<div className="grid-container full">
						<div className="grid-x grid-margin-x align-middle">
							{header}
							{buttonContainer}
						</div>
					</div>
				</div>
			</PageFlowContainer>
		);
	}
}

ButtonBanner.displayName = 'ButtonBanner';

ButtonBanner.propTypes = {
	backgroundColor: PropTypes.string,
	children: (props, propName, componentName) => {
		const prop = props[propName];
		let error = null;
		React.Children.forEach(prop, (child) => {
			if (child.type !== ButtonBannerButton) {
				error = new Error(`'${componentName}' can only have children of type 'ButtonBannerButton'`);
			}
		});

		return error;
	},
	color: PropTypes.string,
	headerPosition: PropTypes.string,
	isInset: PropTypes.bool,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	title: PropTypes.string
};

ButtonBanner.defaultProps = {
	isInset: false
};

export default ButtonBanner;
