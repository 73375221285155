export const SSO_TYPE = {
	NONE: 0,
	GOOGLE: 1,
	FACEBOOK: 2,
	APPLE: 3,
};

export const SSO_NAMES = {
	[SSO_TYPE.GOOGLE]: 'Google',
	[SSO_TYPE.FACEBOOK]: 'Facebook',
	[SSO_TYPE.APPLE]: 'Apple',
};

export const ssoName = (ssoType) => {
	const name = SSO_NAMES[ssoType];

	return name || '';
};
