import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { SOCIAL_PLATFORMS } from '../../const/socialPlatforms';

const UsernameTwitter = ({ name, username, platform }) => {
	if (platform !== SOCIAL_PLATFORMS.TWITTER) return null;

	return (
		<Fragment>
			<span className="name">{name}</span>
			<br />
			<span className="username">{username}</span>
		</Fragment>
	);
};

UsernameTwitter.displayName = 'UsernameTwitter';

UsernameTwitter.propTypes = {
	name: PropTypes.string,
	username: PropTypes.string,
	platform: PropTypes.string
};

export default UsernameTwitter;
