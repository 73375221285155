import deep from 'deep-get-set';

export const addNumbers = (a, b) => {
	const coerceNumber = (value) => +value;
	const numA = coerceNumber(a) || 0;
	const numB = coerceNumber(b) || 0;

	return numA + numB;
};

export const convertNumberTwoDecimalsString = (num) => {
	// 9 to "09"
	return num < 10 ? `0${num}` : String(num);
};

/**
 * Get random number between a min and max
 * @param min Min val
 * @param max Max val
 * @returns {number}
 */
export const getRandomIntRange = (min, max) => Math.floor(Math.random() * (max - min) + min);

export const isNotANumber = (value) => !isNumber(value);

export const isNumber = (value) => {
	/*
		Refer to Unit Tests to see what values are numbers.

		isNaN allows anything that can be coerced into a number.

		Number.isNaN is supposed to avoid this, but is also a little misleading.

		This linting rule is suggested specifically for cases like this,
		but my testing of Number.isNaN shows that some undesirable values come through as numbers.
		So I'm disabling the rule and doing the self comparison as an isNaN check.
	*/

	// eslint-disable-next-line no-self-compare
	return typeof value === 'number' && value === value;
}

export const parseIntDec = (str) => parseInt(str, 10);

export const numToNumString = (num) => {
	const nums = {
		0: 'zero',
		1: 'one',
		2: 'two',
		3: 'three',
		4: 'four',
		5: 'five',
		6: 'six',
		7: 'seven',
		8: 'eight',
		9: 'nine',
		10: 'ten',
	};

	return deep(nums, `${num}`);
};

export const roundToTwoDecimals = (dec) => {
	const HUNDRED = 100;

	return Math.round(dec * HUNDRED) / HUNDRED;
};
