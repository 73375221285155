import React from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from './Title.helpers';
import { SOCIAL_PLATFORMS } from '../../const/socialPlatforms';

const Title = ({ date, platform, title, username }) => {
	if (platform !== SOCIAL_PLATFORMS.APP_STORE) return null;

	const dateStr = getFormattedDate(date);

	return (
		<p className="social-title">
			<strong>{title}</strong>
			<br />
			<span className="username">
				{username} / {dateStr}
			</span>
		</p>
	);
};

Title.displayName = 'Title';

Title.propTypes = {
	date: PropTypes.object,
	platform: PropTypes.string,
	title: PropTypes.string,
	username: PropTypes.string
};

export default Title;
