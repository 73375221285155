import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const personFromJson = (json = {}) => ({
	id: String(deep(json, 'ID')),
	name: deep(json, 'Name'),
	profileMediumUrl: deep(json, 'ProfileImage.MediumUrl'),
});

export const peopleFromJsonArray = (jsonArray) => (jsonArray || []).map(personFromJson);

export const maybeTransformPerson = (person) => deep(person, 'id') ? person : personFromJson(person);

export const personSchema = new schema.Entity(ENTITIES.PERSON);
export const peopleSchema = new schema.Array(personSchema);

export const normalizePerson = (person) => normalize(person, personSchema);
export const normalizePeople = (people) => normalize(people, peopleSchema);
