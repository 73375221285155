import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { REDUCERS } from '../const/reducers.const';

export const selectHappykitState = (state) => deep(state, `${REDUCERS.HAPPYKIT}`);

export const selectHappykitFlags = createSelector(
	selectHappykitState,
	(happykitState) => deep(happykitState, 'flags') || {}
);

export const selectHappykitIsLoading = createSelector(
	selectHappykitState,
	(happykitState) => deep(happykitState, 'isLoading')
);

export const selectHappykitIsError = createSelector(
	selectHappykitState,
	(happykitState) => deep(happykitState, 'isError')
);

export const selectHappykitIsInitialized = createSelector(
	selectHappykitIsError,
	selectHappykitIsLoading,
	(isError, isLoading) => !isError && !isLoading
);
