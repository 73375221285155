import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (config = defaultState[REDUCERS.CONFIG]) => (state = config, action) => {
	const { data, type } = action;

	switch (type) {
		case ACTIONS.UPDATE_CONFIG_BASE_API_URL:
			return { ...state, apiBaseUrl: data };

		default:
			return state;
	}
};
