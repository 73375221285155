import React from 'react';
import PropTypes from 'prop-types';

export const H4 = ({
	children,
	className,
}) => {
	if (!children) return null;

	return (
		<h4
			className={className}
		>
			{children}
		</h4>
	);
};

H4.displayName = 'H4';

H4.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default H4;
