import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconMakeCover = (props) => (
	<Svg
		{...props}
		name="make-cover"
		viewBox="0 0 16 16"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-1030.000000, -21.000000)">
				<g transform="translate(1030.000000, 21.000000)">
					<path
						d="M16,14.5 L16,16 L2,16 L2,14 L14,14 L14,2 L16,2 L16,14.5 Z M0,0 L13,0 L13,13 L0,13 L0,0 Z M2,2 L2,11 L11,11 L11,2 L2,2 Z M15.9887695,1.99951172 L13,1.98580685 L13,0 L15.9887695,1.99951172 Z M2,16 L0,13 L1.98629178,13 L2,16 Z"
						fill="inherit"
					/>
				</g>
			</g>
		</g>
	</Svg>
);

IconMakeCover.displayName = 'IconMakeCover';

IconMakeCover.propTypes = iconProps;

IconMakeCover.defaultProps = defaultIconProps;

export default IconMakeCover;
