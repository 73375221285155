import React from 'react';
import PropTypes from 'prop-types';

import './Accordian.css';

const Accordian = ({
	children,
}) => {
	if (!children) return null;

	return (
		<ul className="accordian">
			{children}
		</ul>
	);
};

Accordian.propTypes = {
	children: PropTypes.node,
};

Accordian.displayName = 'Accordian';

export default Accordian;
