import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const couponCodeFromJson = (json = {}) => {
	const id = deep(json, 'ID');

	return {
		code: deep(json, 'Code'),
		id: id && String(id),
		productFamily: {
			bookCreationModelType: deep(json, 'ProductFamily.BookCreationModelType'),
		},
	};
};

export const couponCodesFromJsonArray = (jsonArray = []) => jsonArray.map(couponCodeFromJson);

export const couponCodeSchema = new schema.Entity(ENTITIES.COUPON_CODE);

export const couponCodesSchema = new schema.Array(couponCodeSchema);

export const normalizeCouponCodes = (couponCodes) => normalize(couponCodes, couponCodesSchema);
