import classnames from 'classnames';

export const getBgProps = (backgroundColor, backgroundImage, noBox) => {
	let bgProps = { style: { backgroundImage: `url('${backgroundImage}')` } };
	if (noBox && backgroundColor) {
		bgProps = {
			...bgProps,
			style: {
				...bgProps.style,
				backgroundColor
			}
		};
	}

	return bgProps;
};

export const getBoxProps = (side, backgroundColor, textAlign, textColor) => {
	let boxProps = {
		className: 'box cell small-12 medium-6 large-4',
		style: {}
	};

	if (side.toLowerCase() === 'right') {
		boxProps = {
			...boxProps,
			className: classnames(boxProps.className, 'medium-offset-6 large-offset-7')
		};
	} else {
		boxProps = {
			...boxProps,
			className: classnames(boxProps.className, 'large-offset-1')
		};
	}

	if (backgroundColor) {
		boxProps = {
			...boxProps,
			style: { backgroundColor }
		};
	}

	if (textAlign) {
		boxProps = {
			...boxProps,
			style: {
				...boxProps.style,
				textAlign
			}
		};
	}

	if (textColor) {
		boxProps.style = {
			...boxProps.style,
			color: textColor
		};
	}

	return boxProps;
};
