import React from 'react';

import infoCardsData from './InfoCards.data';
import CtaButton from '../CtaButton/CtaButton';
import './InfoCards.css';

const InfoCards = () => {
	const midCardIndex = Math.floor(infoCardsData.length / 2) - 1;

	return (
		<div className="instagram-quickflow-page-info-cards padding-none">
			<ul className="instagram-quickflow-page-info-cards-list">
				{infoCardsData.map((item, index) => (
					<li
						key={index}
					>
						<img
							alt=""
							src={item.image}
						/>
						<div className="padding padding-bottom-big">
							<p className="instagram-quickflow-page-info-cards-title">{item.title}</p>
							<p className="instagram-quickflow-page-info-cards-text">{item.text}</p>

							{midCardIndex === index && (
								<CtaButton
									className="margin-top"
									trackingName="Info Cards 1"
								/>
							)}
						</div>
					</li>
				))}
			</ul>
			<CtaButton
				className="margin-bottom margin-left margin-right"
				trackingName="Info Cards 2"
			/>
		</div>
	);
};

export default InfoCards;
