import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

const duploValueAsObject = (value) => {
	try {
		return JSON.parse(value);
	} catch (e) {
		console.warn('Unable to parse duplo value.', value, e);

		return {};
	}
};

export const duploFromJson = (json = {}) => ({
	key: deep(json, 'Key'),
	version: deep(json, 'Version'),
	value: duploValueAsObject(deep(json, 'Value')),
});

export const normalizeDuplo = (duplo) => {
	return normalize(duplo, duploSchema)
}

const duploSchema = new schema.Entity(ENTITIES.DUPLO, {}, {
	idAttribute: 'key',
});
