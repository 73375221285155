import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { dataSourceMediasSchema } from './dataSourceMedia.model';
import { ENTITIES } from '../../../const/entities/entities';

export const dataSourceMediaPageModel = ({
	media = [],
	pageId,
	pagination = {},
	sourceType,
	token,
}) => ({
	media,
	pageId,
	pagination,
	sourceType,
	token,
});

const dataSourceMediaPageSchema = new schema.Entity(ENTITIES.DATA_SOURCE_MEDIA_PAGE, {
	media: dataSourceMediasSchema,
}, {
	idAttribute: 'pageId',
});

export const normalizeDataSourceMediaPage = (page) => normalize(page, dataSourceMediaPageSchema);

export const gatherPhotos = (currentPageId, mediaMap, mediaPageMap) => {
	const page = deep(mediaPageMap, `${currentPageId}`);

	return !page
		? []
		: [...page.media.map((photoId) => mediaMap[photoId]), ...gatherPhotos(page.pageId)];
};