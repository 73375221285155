import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { selectGroupsState } from './groups.selectors';
import { selectLoadingErrorMap, selectLoadingStatusFunc } from './loading.selectors';
import { LOADING_KEYS } from '../actions/loading.actions';
import { ENTITIES } from '../const/entities/entities';

export const selectDataSourceState = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.DATA_SOURCE}`)
);

export const selectDataSourceIds = createSelector(
	selectGroupsState,
	(groupsState) => deep(groupsState, `${ENTITIES.DATA_SOURCE_IDS}`) || []
);

export const selectDataSources = createSelector(
	selectDataSourceState,
	selectDataSourceIds,
	(dataSourceState, ids) => ids.map((id) => dataSourceState[id])
);

export const selectReconnectDataSourceLoadingStatus = createSelector(
	selectLoadingStatusFunc,
	(statusFunc) => statusFunc(LOADING_KEYS.RECONNECT_DATA_SOURCE),
);

export const selectReconnectDataSourceLoadingErrorReason = createSelector(
	selectLoadingErrorMap,
	(errorMap) => {
		if (errorMap[LOADING_KEYS.RECONNECT_DATA_SOURCE]) {
			return deep(errorMap, `${LOADING_KEYS.RECONNECT_DATA_SOURCE}.data.error`) || 'Failed to reconnect';
		}
	}
);