import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './FooterSections.css';

const FooterSections = ({ sectionArray }) => {
	if (!sectionArray.length) return null;

	const sections = sectionArray.map((child, index) => (
		<Fragment key={`CommonFooter-FooterSection-${index}`}>{child}</Fragment>
	));

	return <div className="footer-sections">{sections}</div>;
};

FooterSections.propTypes = {
	sectionArray: PropTypes.node
};

export default FooterSections;
