import React from 'react';
import classnames from 'classnames';

export const getImage = (imageUrl, imageText, classes, size) => {
	if (!imageUrl) return null;

	if (size === 'full') {
		return (
			<div className={classes}>
				<img src={imageUrl} alt={imageText} />
			</div>
		);
	}
	const className = classnames(classes, size === 'contain' ? 'contain' : 'cover');

	return <div className={className} style={{ backgroundImage: `url('${imageUrl}')` }} />;
};
