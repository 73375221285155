import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import withFormContext from '../withFormContext';

const getColumnStyles = (desktopWidth, tabletWidth, mobileWidth) => {
	const styles = {
		large: desktopWidth,
		medium: tabletWidth,
		small: mobileWidth
	};

	return Object.keys(styles)
		.filter((key) => !!styles[key])
		.map((key) => `${key}-${styles[key]}`);
};

const shouldShow = (showItem, submitted, success) => {
	if (showItem === 'before') {
		return !success;
	}
	if (showItem === 'after') {
		return submitted && success;
	}

	return true;
};

const FormItem = ({
	children,
	className,
	columnWidthDesktop,
	columnWidthMobile,
	columnWidthTablet,
	showItem,
	style,
	submitted,
	success
}) => {
	if (!shouldShow(showItem, submitted, success)) return null;

	const columnStyles = getColumnStyles(columnWidthDesktop, columnWidthTablet, columnWidthMobile);

	return (
		<div className={classnames('FormItem', 'cell', className, columnStyles)} style={style}>
			{children}
		</div>
	);
};

FormItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	columnWidthDesktop: PropTypes.number,
	columnWidthMobile: PropTypes.number,
	columnWidthTablet: PropTypes.number,
	showItem: PropTypes.string,
	style: PropTypes.any,
	submitted: PropTypes.bool,
	success: PropTypes.bool,
};

export default withFormContext(FormItem);
