import React from 'react';
import PropTypes from 'prop-types';

import { convertImageUrl } from '../DuploBlock.helpers';
import './Image.css';

const Image = ({
	caption,
	image,
}) => {
	if (!caption || !image) return null;

	return (
		<div className="duplo-block-two-image-item">
			<img
				src={convertImageUrl(image)}
				alt=""
			/>
			<p className="caption">{caption}</p>
		</div>
	);
};

Image.propTypes = {
	caption: PropTypes.string,
	image: PropTypes.string,
};

export default Image;
