import { apiGetData, apiPost } from './api.http.actions';
import { queryStringFromJson } from '../../helpers/locationUtils';
import requireParams from '../../helpers/requireParams';

export const getBooksExcludedMoments = (groupId, index, count, includeDateInfo = true, offsetMinutes) => (dispatch) => {
	requireParams({ groupId }, getBooksExcludedMoments.name);

	const query = {
		groupID: groupId,
		index,
		count,
	};

	if (includeDateInfo) query.includeDateInfo = true;
	if (offsetMinutes) query.offsetMinutes = offsetMinutes;

	return dispatch(apiGetData(`/books/ExcludedMoments?${queryStringFromJson(query)}`));
};

export const getBooksInGroup = (groupId, start, count, downloadState, sort) => (dispatch) => {
	requireParams({ groupId }, getBooksInGroup.name);

	const query = {
		groupId,
	};

	if (sort) query.sort = sort;
	if (count) query.count = count;
	if (start) query.index = start;

	return dispatch(apiGetData(`/books/skinny?${queryStringFromJson(query)}`));
};

export const postBooksDeleteAllExcluded = (groupId) => (dispatch) => {
	requireParams({ groupId }, postBooksDeleteAllExcluded.name);

	return dispatch(apiPost(`/books/deleteAllExcluded`, { groupId }));
};

export const postBooksSetCoverMedia = (bookId, mediaId) => (dispatch) => {
	requireParams({ bookId, mediaId }, postBooksSetCoverMedia.name);

	return dispatch(apiPost('/books/SetCoverMedia', { id: bookId, mediaID: mediaId }));
};