import React from 'react';
import PropTypes from 'prop-types';

import Box from '../../Box/Box';

const BoxMiddle = ({
	children,
	className,
}) => (
	<Box
		className={className}
		isBorderBottomVisible={false}
		isRadiusBottomLeftVisible={false}
		isRadiusBottomRightVisible={false}
		isRadiusTopLeftVisible={false}
		isRadiusTopRightVisible={false}
	>
		{children}
	</Box>
);

BoxMiddle.displayName = 'BoxMiddle';

BoxMiddle.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default BoxMiddle