import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.CARD_TEMPLATE], action) => {
	const { type } = action;

	switch (type) {
		case ACTIONS.SET_CARD_TEMPLATE_ID: {
			const id = deep(action, 'id');

			return {
				...state,
				cardTemplateId: String(id),
			};
		}

		case ACTIONS.SET_CARD_BACK_TEMPLATES: {
			const cardTemplateId = deep(state, 'cardTemplateId');
			const cardBackTemplateIds = deep(action, 'cardBackTemplateIds');
			const cardBackTemplates = deep(action, 'cardBackTemplates');

			return {
				...state,
				queriedBackTemplateIds: {
					...(deep(state, 'queriedBackTemplateIds') || {}),
					[cardTemplateId]: cardBackTemplateIds,
				},
				cardBackTemplates: {
					...(deep(state, 'cardBackTemplates') || {}),
					...cardBackTemplates
				},
			};
		}

		case ACTIONS.SET_CARD_TEMPLATE_CAROUSEL_SCREEN: {
			const carouselScreen = deep(action, 'carouselScreen');

			return {
				...state,
				currentCarouselScreen: carouselScreen,
			};
		}

		case ACTIONS.SET_CARD_TEMPLATE_ORIENTATION_SET: {
			const familyId = deep(action, 'familyId');
			const cardTemplateIds = deep(action, 'cardTemplateIds');

			const cardTemplateFamilyRelation = cardTemplateIds
				.reduce((total, cardTemplateId) => ({
					...total,
					[cardTemplateId]: familyId
				}), {});

			return {
				...state,
				cardTemplateFamilyRelation,
				cardTemplateFamily: {
					...deep(state, 'cardTemplateFamily'),
					[familyId]: deep(action, 'cardTemplateIds')
				},
			};
		}

		case ACTIONS.SET_CARD_TEMPLATE_EDGE_TYPE: {
			const num = deep(action, 'num');

			return {
				...state,
				currentCardEdgeType: String(num),
			};
		}

		case ACTIONS.SET_CARD_TEMPLATE_FOIL_OPTION: {
			const foilOption = deep(action, 'foilOption');

			return {
				...state,
				currentFoilOption: foilOption,
			};
		}

		case ACTIONS.SET_CARD_TEMPLATE_PREMIUM_TYPE: {
			const cardPremiumType = deep(action, 'cardPremiumType');

			return {
				...state,
				currentCardPremiumType: cardPremiumType,
			};
		}

		default:
			return state;
	}
};
