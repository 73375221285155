import { queryStringFromJson } from '../../helpers/locationUtils';
import { apiGetData } from './api.http.actions';

export const getCoverPhysicalCropInfo = (groupId, mediaId) => (dispatch) => {
	return dispatch(apiGetData(`/media/GetCoverPhysicalCropInfo?${queryStringFromJson({
		groupId,
		mediaId,
	})}`));
};

export const getMediaByMediaId = (mediaId) => (dispatch, getState) => {
	const url = `/media/get?id=${mediaId}`;

	return dispatch(apiGetData(url));
};

export const getCropRectForMedia = (mediaId, mediaType, bookCreationModelType) => (dispatch) => {
	const query = {
		id: mediaId,
		type: mediaType,
		bookCreationModelType
	};
	const url = `/media/cropRect?${queryStringFromJson(query)}`;

	return dispatch(apiGetData(url));
};
