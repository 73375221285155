import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import * as OrderCalculatedShippingOption from './orderCalculatedShippingOption.model';
import { ENTITIES } from '../const/entities/entities';
import { onlyCharacters } from '../helpers/strings/strings';

export const makeGroupOrderCalculatedShippingOptionId = (groupId, calculatedShippingOptionId) => `groupId(${groupId})-calculatedShippingOptionId(${calculatedShippingOptionId})`;

export const groupOrderCalculatedShippingOptionFromJson = (groupId, json = {}) => {

	const calculatedShippingOption = OrderCalculatedShippingOption.orderCalculatedShippingOptionFromJson(deep(json, 'ShippingOption'));

	const holidayArrivalMessage = deep(json, 'HolidayArrivalMessage');

	return {
		calculatedShippingOption,
		estimatedRange: deep(json, 'EstimatedRange'),
		groupId: String(groupId),
		holidayArrivalMessage: holidayArrivalMessage && onlyCharacters(holidayArrivalMessage),
		holidayArrivalStatus: deep(json, 'HolidayArrivalStatus'),
		id: makeGroupOrderCalculatedShippingOptionId(groupId, calculatedShippingOption.id),
		isAvailable: deep(json, 'IsAvailable'),
		isRecommended: deep(json, 'IsRecommended'),
		notAvailableReason: deep(json, 'NotAvailableReason'),
		price: deep(json, 'Price'),
		priceText: deep(json, 'PriceText'),
	};
};

export const groupOrderCalculatedShippingOptionsFromJsonArray = (groupId, jsonArray = []) => {
	return jsonArray.map((json) => groupOrderCalculatedShippingOptionFromJson(groupId, json));
};

export const groupOrderCalculatedShippingOptionSchema = new schema.Entity(
	ENTITIES.GROUP_ORDER_CALCULATED_SHIPPING_OPTION,
	{ calculatedShippingOption: OrderCalculatedShippingOption.orderCalculatedShippingOptionSchema },
);

export const groupOrderCalculatedShippingOptionsSchema = new schema.Array(groupOrderCalculatedShippingOptionSchema);

export const normalizeGroupOrderCalculatedShippingOption = (groupOrderCalculatedShippingOption) => {
	return normalize(groupOrderCalculatedShippingOption, groupOrderCalculatedShippingOptionSchema);
};


export const getCalculatedShippingOption = (groupOrderCalculatedShippingOption, orderCalculatedShippingOptionMap) => {
	return deep(orderCalculatedShippingOptionMap, `${deep(groupOrderCalculatedShippingOption, `calculatedShippingOption`)}`);
};

export const getCalculatedShippingOptionId = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'id');

export const getCalculatedShippingOptionTitle = (groupOrderCalculatedShippingOption, orderCalculatedShippingOptionMap) => {
	return OrderCalculatedShippingOption.getTitle(getCalculatedShippingOption(groupOrderCalculatedShippingOption, orderCalculatedShippingOptionMap))
};

export const getCalculatedShippingOptionTrackingDescriptionKey = (groupOrderCalculatedShippingOption, orderCalculatedShippingOptionMap) => {
	return OrderCalculatedShippingOption.getTrackingDescriptionKey(getCalculatedShippingOption(groupOrderCalculatedShippingOption, orderCalculatedShippingOptionMap))
};

export const getEstimatedRange = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'estimatedRange') || '';

export const getHolidayArrivalMessage = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'holidayArrivalMessage') || '';

export const getHolidayArrivalStatus = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'holidayArrivalStatus');

export const getIsAvailable = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'isAvailable') || false;

export const getIsRecommended = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'isRecommended') || false;

export const getNotAvailableReason = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'notAvailableReason') || '';

export const getPrice = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'price');

export const getPriceText = (groupOrderCalculatedShippingOption) => deep(groupOrderCalculatedShippingOption, 'priceText') || '';
