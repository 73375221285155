import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import withCarouselContext from '../withCarouselContext';
import Icon from '../../Icon/Icon';
import { SCROLL_DIRECTION } from '../../../const/scrollDirection';

import './CarouselButton.css';

const CarouselButton = ({
	className,
	color,
	context,
	direction,
	onGoToPage,
}) => {
	const handleClick = useCallback(() => {
		const { pageCount, pageNumber } = context;

		if (direction === SCROLL_DIRECTION.LEFT) {
			const newPageNumber = pageNumber <= 0 ? pageCount - 1 : pageNumber - 1;
			onGoToPage(newPageNumber, true, SCROLL_DIRECTION.LEFT);
		} else if (direction === SCROLL_DIRECTION.RIGHT) {
			const newPageNumber = (pageNumber + 1) % pageCount;
			onGoToPage(newPageNumber, true, SCROLL_DIRECTION.RIGHT);
		}
	}, [context, direction, onGoToPage]);

	return (
		<div
			className={classnames('CarouselButton flex-container flex-dir-column', direction, className)}
			style={{ color }}
			onClick={handleClick}>
			<Icon name={`chevron-${direction}`} />
		</div>
	);
}

CarouselButton.displayName = 'CarouselButton';

CarouselButton.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	context: PropTypes.object,
	direction: PropTypes.string,
	onGoToPage: PropTypes.func
};

export default withCarouselContext(CarouselButton);
