import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useDebouncedValue } from '../../hooks';

const Svg = styled.svg`
	@keyframes throbber-spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	animation: throbber-spin 1s linear infinite;
`;

const InlineSpinner = ({
	className,
	delay,
	isVisible,
	size
}) => {
	const _isVisible = useDebouncedValue(isVisible, delay);

	if (!_isVisible) return null;

	return (
		<Svg className={className} width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_b)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M25.2751 8.26593C18.7946 5.35248 11.1792 8.24418 8.26577 14.7247C5.35232 21.2053 8.24401 28.8206 14.7246 31.7341C21.2051 34.6475 28.8205 31.7558 31.7339 25.2753C34.6474 18.7947 31.7557 11.1794 25.2751 8.26593ZM26.2026 6.2028C18.5827 2.77709 9.62835 6.17722 6.20264 13.7972C2.77693 21.4172 6.17706 30.3715 13.797 33.7972C21.417 37.2229 30.3713 33.8228 33.797 26.2028C37.2228 18.5828 33.8226 9.62851 26.2026 6.2028Z"
					fill="#E2E8F0"
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.5899 33.5391C22.3671 32.7908 22.7931 32.0035 23.5414 31.7807C25.793 31.1102 27.8119 29.8252 29.3697 28.0716C30.9274 26.3179 31.9619 24.1658 32.3567 21.8589C32.4884 21.0893 33.2191 20.5722 33.9887 20.7039C34.7583 20.8356 35.2755 21.5663 35.1437 22.3359C34.6597 25.1643 33.3916 27.8015 31.4836 29.9494C29.5757 32.0972 27.104 33.6701 24.3483 34.4906C23.6 34.7134 22.8127 34.2874 22.5899 33.5391Z"
				fill="#357FE8"
			/>
			<defs>
				<filter id="filter0_b" x="-4" y="-4" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix"/>
					<feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
					<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
				</filter>
			</defs>
		</Svg>
	);
};

InlineSpinner.displayName = 'InlineSpinner';

InlineSpinner.propTypes = {
	className: PropTypes.string,
	delay: PropTypes.number,
	isVisible: PropTypes.bool,
	size: PropTypes.number,
};

InlineSpinner.defaultProps = {
	delay: 0,
	isVisible: false,
	size: 20,
};

export default InlineSpinner;
