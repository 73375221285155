import { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

class MobileCheckerWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isMobile: this.getIsMobile(props.breakpoint),
		};

		this.debouncedHandleResize = this.debouncedHandleResize.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.debouncedHandleResize)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.debouncedHandleResize)
	}

	debouncedHandleResize = debounce(this.handleResize, 300)

	getIsMobile(breakpoint) {
		return window.innerWidth < breakpoint;
	}

	handleResize() {
		const isMobile = this.getIsMobile(this.props.breakpoint);

		if (isMobile !== this.state.isMobile) {
			this.setState({ isMobile });
		}
	}

	render() {
		return this.props.render(this.state);
	}
};

MobileCheckerWrapper.displayName = 'MobileCheckerWrapper';

MobileCheckerWrapper.propTypes = {
	render: PropTypes.func,
	breakpoint: PropTypes.number,
};

MobileCheckerWrapper.defaultProps = {
	breakpoint: 768,
};

export default MobileCheckerWrapper;
