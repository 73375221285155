import { apiPost } from './api.http.actions';
import { loadingInit, LOADING_KEYS, loadingError, loadingSuccess } from '../loading.actions';

const giftCardsApiPost = (path, data) => (dispatch) => {
	return dispatch(apiPost(`/giftCodes${path}`, data));
};

export const postRedeemGiftCard = (code) => (dispatch) => {
	dispatch(loadingInit(LOADING_KEYS.REDEEM_GIFT_CARD));

	return dispatch(giftCardsApiPost('/redeem', { code }))
		.then(() => dispatch(loadingSuccess(LOADING_KEYS.REDEEM_GIFT_CARD)))
		.catch((err) => {
			dispatch(loadingError(LOADING_KEYS.REDEEM_GIFT_CARD, err));
			throw err;
		})
};