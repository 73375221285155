import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLayoutFullPage = (props) => (
	<Svg
		{...props}
		name="layout-full-page"
		viewBox="0 0 30 30"
	>
		<rect width="30" height="30" rx="4" fill="inherit" />
	</Svg>
);

IconLayoutFullPage.displayName = 'IconLayoutFullPage';

IconLayoutFullPage.propTypes = iconProps;

IconLayoutFullPage.defaultProps = defaultIconProps;

export default IconLayoutFullPage;
