import deep from 'deep-get-set';

import { httpGet, httpDelete, httpPut, httpPost } from '../http.actions';
import { openLoginPage } from '../user.actions';
import { selectApiUrl } from '../../selectors/config.selectors';

export const apiDelete = (path) => (dispatch, getState) => {
	const apiUrl = selectApiUrl(getState());
	const url = `${apiUrl}${path}`;

	return dispatch(httpDelete(url))
		.catch((err) => dispatch(maybeHandleUnauthorized(err)));
};

export const apiGet = (path) => (dispatch, getState) => {
	const apiUrl = selectApiUrl(getState());
	const url = `${apiUrl}${path}`;

	return dispatch(httpGet(url))
		.catch((err) => dispatch(maybeHandleUnauthorized(err)));
};

const deconstructData = ({ data }) => data;

export const apiGetData = (url) => (dispatch) => {
	return dispatch(apiGet(url))
		.then(deconstructData);
};

export const apiPost = (path, data, { headers } = {}) => (dispatch, getState) => {
	const apiUrl = selectApiUrl(getState());
	const url = `${apiUrl}${path}`;

	return dispatch(httpPost(url, data, { headers }))
		.catch((err) => dispatch(maybeHandleUnauthorized(err)));
};

export const apiPostRecieveData = (path, data, { headers } = {}) => (dispatch) => {
	return dispatch(apiPost(path, data, { headers }))
		.then(deconstructData);
};

export const apiPut = (path, data, { headers, validateStatus } = {}) => (dispatch, getState) => {
	const apiUrl = selectApiUrl(getState());
	const url = `${apiUrl}${path}`;

	return dispatch(httpPut(url, data, { headers, validateStatus }))
		.catch((err) => dispatch(maybeHandleUnauthorized(err)));
};

export const apiPutRecieveData = (path, data) => (dispatch) => {
	return dispatch(apiPut(path, data))
		.then(deconstructData);
};

const maybeHandleUnauthorized = (err) => (dispatch) => {
	if (deep(err, 'response.status') === 401) {
		dispatch(openLoginPage());
	}

	throw err;
};
