import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { COLOR, IconCheckmarkCircledTwoColors, IconError, IconInstagramLogo } from '../../chatponents';
import { getInstagramUser } from '../../services/instagram/instagram';
import './InstagramUser.css';

class InstagramUser extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.loadInstagramUser = this.loadInstagramUser.bind(this);
	}

	componentDidMount() {
		const { token } = this.props;

		if (token) this.loadInstagramUser();
	}

	componentDidUpdate(prevProps) {
		const { token } = this.props;
		const { token: prevToken } = prevProps;

		if (!prevToken && token) this.loadInstagramUser();
	}

	loadInstagramUser() {
		const { token } = this.props;

		getInstagramUser({ token })
			.then((user) => this.setState({ username: user.username }));
	}

	render() {
		const { username } = this.state;
		const { isFailure, isSuccess } = this.props;

		if (!username) return null;

		return (
			<div className="instagram-user">
				<div className="instagram-user-content">
					<IconInstagramLogo
						color={COLOR.BLACK}
						size="32"
					/>
					<p className="username">{username}</p>
					<p className="instructions">Chatbooks Instagram Series</p>
					{isSuccess && (
						<IconCheckmarkCircledTwoColors className="status-icon" color="#36ce67" color2={COLOR.BLACK} size="24" />
					)}
					{isFailure && (
						<IconError className="status-icon"  color="#FFD31F" size="24" />
					)}
				</div>
			</div>
		);
	}
}

InstagramUser.displayName = 'InstagramUser';

InstagramUser.propTypes = {
	isFailure: PropTypes.bool,
	isSuccess: PropTypes.bool,
	token: PropTypes.string,
};

InstagramUser.defaultProps = {
	isFailure: false,
	isSuccess: false,
};

export default InstagramUser;
