import React from 'react';

import Benefits from '../components/Benefits/Benefits';
import CtaButton from '../components/CtaButton/CtaButton';
import Faqs from '../components/Faqs/Faqs';
import HeroInfo from '../components/HeroInfo/HeroInfo';
import ProductSpecs from '../components/ProductSpecs/ProductSpecs';
import Reviews from '../components/Reviews/Reviews';
import InstagramBookSeriesSection from '../components/InstagramBookSeriesSection/InstagramBookSeriesSection';
import UserGeneratedImages from '../components/UserGeneratedImages/UserGeneratedImages';

import { Provider } from '../../../helpers/context/context';
import { getAppBodyScrollPercent } from '../../../helpers/scroll/scroll';
import './Landing.css';

export const LANDING_URL = '/landing';

const Landing = () => {
	return (
		<div className="instagram-quickflow-page instagram-landing-page">
			<Provider
				value={{
					ctaText: 'Connect My Instagram Account',
					onCtaClick: (trackingName) => {
						const scrollPercent = getAppBodyScrollPercent();

						this.props.analyticsActions.triggerAnalyticsEvent('IGSeriesLanding_Continue', {
							attr1: scrollPercent,
							attr2: trackingName,
						});

						this.props.instagramQuickFlowActions.authenticateAndPreview(this.props.history);
					},
				}}
			>
				<InstagramBookSeriesSection />
				<Benefits className="margin-bottom" />
				<CtaButton
					className="margin-left margin-right"
					trackingName="Benefits"
				/>
				<HeroInfo />
				<Faqs />
				<ProductSpecs />
				<Reviews />
				<UserGeneratedImages />
			</Provider>
		</div>
	);
};

export default Landing;
