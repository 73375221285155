import deep from 'deep-get-set';

import { formatPost } from './blog.reducer.helpers';
import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.BLOG], action) => {
	const { type } = action;

	switch (type) {
		case ACTIONS.FETCH_BLOG_POSTS: {
			if (!action.hasFilterChanged) return state;

			return {
				...state,
				posts: [],
			}
		}

		case ACTIONS.RECEIVE_BLOG_POSTS: {
			const search = deep(action, 'search');
			const category = deep(action, 'category');
			const page = deep(action, 'page') || 1;
			const limit = deep(action, 'limit') || 20;
			const queriedPosts = deep(action, 'posts') || [];
			const posts = queriedPosts.map((post) => formatPost(post));
			const existingPosts = deep(state, 'posts') || [];
			const hasFilterChanged = deep(action, 'hasFilterChanged') || false;
			const isAllPagesLoaded = hasFilterChanged ? false : state.isAllPagesLoaded || false;

			return {
				...state,
				currentCategory: category,
				currentPage: hasFilterChanged ? 1 : page,
				currentSearch: search,
				isAllPagesLoaded,
				limit,
				posts: hasFilterChanged ? posts : [...existingPosts, ...posts]
			};
		}

		case ACTIONS.RECEIVE_POPULAR_BLOG_POSTS: {
			return {
				...state,
				popularBlogPosts: action.posts,
			};
		}

		case ACTIONS.RECEIVE_BLOG_CATEGORIES: {
			const { categories } = action;

			return {
				...state,
				categories: [...categories]
			};
		}

		case ACTIONS.BLOG_LOADED_ALL_PAGES:
			return {
				...state,
				isAllPagesLoaded: true
			};

		default:
			return state;
	}
};
