import { getApiSettings } from '../api/api.actions';
import { receiveEntities } from '../entity.actions';
import { loadingInit, LOADING_KEYS, loadingSuccess, loadingError } from '../loading.actions';
import { normalizeApiSettings } from '../../model/apiSettings.model';

export const loadApiSettings = () => (dispatch) => {
	dispatch(loadingInit(LOADING_KEYS.LOAD_API_SETTINGS));

	return dispatch(getApiSettings())
		.then(normalizeApiSettings)
		.then((normalized) => dispatch(receiveEntities(normalized.entities, loadApiSettings.name)))
		.then(() => dispatch(loadingSuccess(LOADING_KEYS.LOAD_API_SETTINGS)))
		.catch((err) => dispatch(loadingError(LOADING_KEYS.LOAD_API_SETTINGS, err)));
};