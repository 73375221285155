import React from 'react';
import PropTypes from 'prop-types';
import defaultIconProps from '../_defaultIconProps';
import iconProps from '../_iconProps';
import Svg from '../Svg';

export const IconCardBackPortrait3Photos = (props) => {
	const { size } = props;
	const heightRatio = 140;
	const widthRatio = 100;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="IconCardBackPortrait3Photos"
			width={width}
			height={height}
			viewBox="0 0 100 140"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <rect fill={props.color} x="0" y="0" width="100" height="140" />
		        <rect fill={props.color2} x="11" y="11" width="78" height="53" />
		        <rect fill={props.color2} x="21" y="113" width="58" height="2" />
		        <rect fill={props.color2} x="21" y="125" width="58" height="2" />
		        <rect fill={props.color2} x="24" y="119" width="52" height="2" />
		        <rect fill={props.color2} x="11" y="66" width="38" height="38" />
		        <rect fill={props.color2} x="51" y="66" width="38" height="38" />
		    </g>
		</Svg>
	);
};

IconCardBackPortrait3Photos.displayName = 'IconCardBackPortrait3Photos';

IconCardBackPortrait3Photos.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconCardBackPortrait3Photos.defaultProps = {
	...defaultIconProps,
	color: '#ffffff',
	color2: '#d8d8d8',
};

export default IconCardBackPortrait3Photos;
