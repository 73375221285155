import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconStars = (props) => (
	<Svg
		{...props}
		name="icon-stars"
		height={props.size * .266666667}
		viewBox="0 0 97 17"
	>
		<path
			d="M3.61426 16.2852C3.91455 16.5195 4.29541 16.439 4.74951 16.1094L8.62402 13.2603L12.5059 16.1094C12.96 16.439 13.3335 16.5195 13.6411 16.2852C13.9414 16.0581 14.0073 15.6846 13.8242 15.1572L12.2935 10.5942L16.2046 7.78906C16.6587 7.4668 16.8418 7.12256 16.7246 6.76367C16.6074 6.40479 16.2632 6.229 15.6992 6.23633L10.9019 6.27295L9.44434 1.68799C9.26855 1.15332 9.00488 0.875 8.62402 0.875C8.25049 0.875 7.98682 1.15332 7.81104 1.68799L6.35352 6.27295L1.55615 6.23633C0.992188 6.229 0.647949 6.40479 0.530762 6.76367C0.40625 7.12256 0.59668 7.4668 1.05078 7.78906L4.96191 10.5942L3.43115 15.1572C3.24805 15.6846 3.31396 16.0581 3.61426 16.2852Z"
			fill="inherit"
		/>
		<path
			d="M23.6143 16.2852C23.9146 16.5195 24.2954 16.439 24.7495 16.1094L28.624 13.2603L32.5059 16.1094C32.96 16.439 33.3335 16.5195 33.6411 16.2852C33.9414 16.0581 34.0073 15.6846 33.8242 15.1572L32.2935 10.5942L36.2046 7.78906C36.6587 7.4668 36.8418 7.12256 36.7246 6.76367C36.6074 6.40479 36.2632 6.229 35.6992 6.23633L30.9019 6.27295L29.4443 1.68799C29.2686 1.15332 29.0049 0.875 28.624 0.875C28.2505 0.875 27.9868 1.15332 27.811 1.68799L26.3535 6.27295L21.5562 6.23633C20.9922 6.229 20.6479 6.40479 20.5308 6.76367C20.4062 7.12256 20.5967 7.4668 21.0508 7.78906L24.9619 10.5942L23.4312 15.1572C23.248 15.6846 23.314 16.0581 23.6143 16.2852Z"
			fill="inherit"
		/>
		<path
			d="M43.6143 16.2852C43.9146 16.5195 44.2954 16.439 44.7495 16.1094L48.624 13.2603L52.5059 16.1094C52.96 16.439 53.3335 16.5195 53.6411 16.2852C53.9414 16.0581 54.0073 15.6846 53.8242 15.1572L52.2935 10.5942L56.2046 7.78906C56.6587 7.4668 56.8418 7.12256 56.7246 6.76367C56.6074 6.40479 56.2632 6.229 55.6992 6.23633L50.9019 6.27295L49.4443 1.68799C49.2686 1.15332 49.0049 0.875 48.624 0.875C48.2505 0.875 47.9868 1.15332 47.811 1.68799L46.3535 6.27295L41.5562 6.23633C40.9922 6.229 40.6479 6.40479 40.5308 6.76367C40.4062 7.12256 40.5967 7.4668 41.0508 7.78906L44.9619 10.5942L43.4312 15.1572C43.248 15.6846 43.314 16.0581 43.6143 16.2852Z"
			fill="inherit"
		/>
		<path
			d="M63.6143 16.2852C63.9146 16.5195 64.2954 16.439 64.7495 16.1094L68.624 13.2603L72.5059 16.1094C72.96 16.439 73.3335 16.5195 73.6411 16.2852C73.9414 16.0581 74.0073 15.6846 73.8242 15.1572L72.2935 10.5942L76.2046 7.78906C76.6587 7.4668 76.8418 7.12256 76.7246 6.76367C76.6074 6.40479 76.2632 6.229 75.6992 6.23633L70.9019 6.27295L69.4443 1.68799C69.2686 1.15332 69.0049 0.875 68.624 0.875C68.2505 0.875 67.9868 1.15332 67.811 1.68799L66.3535 6.27295L61.5562 6.23633C60.9922 6.229 60.6479 6.40479 60.5308 6.76367C60.4062 7.12256 60.5967 7.4668 61.0508 7.78906L64.9619 10.5942L63.4312 15.1572C63.248 15.6846 63.314 16.0581 63.6143 16.2852Z"
			fill="inherit"
		/>
		<path
			d="M83.6143 16.2852C83.9146 16.5195 84.2954 16.439 84.7495 16.1094L88.624 13.2603L92.5059 16.1094C92.96 16.439 93.3335 16.5195 93.6411 16.2852C93.9414 16.0581 94.0073 15.6846 93.8242 15.1572L92.2935 10.5942L96.2046 7.78906C96.6587 7.4668 96.8418 7.12256 96.7246 6.76367C96.6074 6.40479 96.2632 6.229 95.6992 6.23633L90.9019 6.27295L89.4443 1.68799C89.2686 1.15332 89.0049 0.875 88.624 0.875C88.2505 0.875 87.9868 1.15332 87.811 1.68799L86.3535 6.27295L81.5562 6.23633C80.9922 6.229 80.6479 6.40479 80.5308 6.76367C80.4062 7.12256 80.5967 7.4668 81.0508 7.78906L84.9619 10.5942L83.4312 15.1572C83.248 15.6846 83.314 16.0581 83.6143 16.2852Z"
			fill="inherit"
		/>
	</Svg>
);

IconStars.displayName = 'IconStars';

IconStars.propTypes = iconProps;

IconStars.defaultProps = defaultIconProps;

export default IconStars;
