import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconPlusThin = (props) => (
	<Svg
		{...props}
		name="plus-thin"
		viewBox="0 0 15 16"
	>
		<path
			d="M7.59766 15.1426C8.15137 15.1426 8.59961 14.6855 8.59961 14.167V8.66504H13.9609C14.4971 8.66504 14.9541 8.2168 14.9541 7.66309C14.9541 7.11816 14.4971 6.66113 13.9609 6.66113H8.59961V1.15918C8.59961 0.631836 8.15137 0.183594 7.59766 0.183594C7.05273 0.183594 6.5957 0.631836 6.5957 1.15918V6.66113H1.23438C0.71582 6.66113 0.241211 7.11816 0.241211 7.66309C0.241211 8.2168 0.71582 8.66504 1.23438 8.66504H6.5957V14.167C6.5957 14.6855 7.05273 15.1426 7.59766 15.1426Z"
			fill="inherit"
		/>
	</Svg>
);

IconPlusThin.displayName = 'IconPlusThin';

IconPlusThin.propTypes = iconProps;

IconPlusThin.defaultProps = defaultIconProps;

export default IconPlusThin;
