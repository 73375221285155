import React from 'react';
import PropTypes from 'prop-types';

import { IconMinusThin, IconPlusThin } from '../../../../chatponents';
import './AccordianHeader.css';

const AccordianHeader = ({
	isOpen,
	onClick,
	title,
}) => (
	<div
		onClick={onClick}
		className="accordian-header"
	>
		<p className="accordian-title">{title}</p>

		{isOpen ? (
			<IconMinusThin size="18" />
		) : (
			<IconPlusThin size="18" />
		)}
	</div>
);

AccordianHeader.propTypes = {
	isOpen: PropTypes.bool,
	onClick: PropTypes.func,
	title: PropTypes.string,
};

AccordianHeader.displayName = 'AccordianHeader';

export default AccordianHeader;
