import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from '../Wrapper/Wrapper';
import './Separator.css';

const Separator = ({
	separator
}) => (
	<Wrapper className="duplo-block duplo-block-separator" />
);

Separator.propTypes = {
	separator: PropTypes.string,
};

export default Separator;
