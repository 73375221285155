import React from 'react';
import PropTypes from 'prop-types';

import Box from '../../Box/Box';

const BoxTop = ({
	children,
	className,
}) => (
	<Box
		className={className}
		isBorderBottomVisible={false}
		isRadiusBottomLeftVisible={false}
		isRadiusBottomRightVisible={false}
	>
		{children}
	</Box>
);

BoxTop.displayName = 'BoxTop';

BoxTop.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default BoxTop