import * as ACTIONS from './actionTypes';

export const decrementThrobberCount = (source) => ({ type: ACTIONS.DECREMENT_THROBBER_COUNT, source });
export const incrementThrobberCount = (source) => ({ type: ACTIONS.INCREMENT_THROBBER_COUNT, source });
export const hideThrobber = (source) => ({ type: ACTIONS.DECREMENT_THROBBER_COUNT, source });
export const setAngularLoaded = (source) => ({ type: ACTIONS.ANGULAR_LOADED, data: { source } });
export const setAngularLoading = (source) => ({ type: ACTIONS.ANGULAR_LOADING, data: { source } });
export const setBookCollageModalOpen = (isOpen) => ({ type: ACTIONS.SET_BOOK_COLLAGE_MODAL_OPEN, isOpen });
export const setContextVisible = (visible) => ({ type: ACTIONS.SET_CONTEXT_VISIBLE, data: visible });
export const setModalOpen = (isOpen) => ({ type: ACTIONS.SET_MODAL_OPEN, isOpen });
export const setScrolled = () => ({ type: ACTIONS.SET_SCROLLED });
export const setScrolledFromBottom = () => ({ type: ACTIONS.SET_SCROLLED_FROM_BOTTOM });
export const setScrolledToBottom = () => ({ type: ACTIONS.SET_SCROLLED_TO_BOTTOM });
export const setScrolledToTop = () => ({ type: ACTIONS.SET_SCROLLED_TO_TOP });
export const showThrobber = (source) => ({ type: ACTIONS.INCREMENT_THROBBER_COUNT, source });
export const touch = () => ({ type: ACTIONS.TOUCH_REDUCERS_TO_TRIGGER_SELECTORS });

export const logError = (err) => console.error(err);