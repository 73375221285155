import { omit } from 'lodash';
import { objectMap } from '@chtbks/helpers';

/**
 * Throw an error if any parameters are undefined.
 * @param param
 * @param functionName
 */
export default (params, functionName) => {
	const missingParams = omit(params, (param) => param !== undefined);
	const errorMessages = Object.values(objectMap(missingParams, (ignore, paramName) => {
		return `{${paramName}} is required by function api. {${functionName}()}.`;
	}));

	if (errorMessages.length) throw new Error(errorMessages.join(' '));
};
