import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import deep from 'deep-get-set';

import SourceDescriptionList from './SourceLanding.data';
import { returnSourceIdFromSourceString } from './SourceLanding.helpers';
import {
	QUICKFLOW_SOURCE_INSTAGRAM,
} from '../QuickFlow.const';
import CtaButton from '../../instagram-quick-flow/components/CtaButton/CtaButton';
import * as analyticsActions from '../../../actions/analytics.actions';
import * as instagramQuickFlowActions from '../../../actions/instagramQuickFlow.actions';
import './SourceLanding.css';

class SourceLanding extends Component {
	constructor(props) {
		super(props);

		this.getSource = this.getSource.bind(this);
		this.onCtaClick = this.onCtaClick.bind(this);
	}

	componentDidMount() {
		this.props.analyticsActions.triggerAnalyticsEvent('QuickSeriesLanding_Load', {
			attr1: this.getSource()
		});
	}

	getSource() {
		return deep(this.props.match, 'params.source');
	}

	onCtaClick() {
		const source = this.getSource();

		this.props.analyticsActions.triggerAnalyticsEvent('QuickSeriesLanding_Continue', {
			attr1: source,
		});

		if (source === QUICKFLOW_SOURCE_INSTAGRAM) {
			const shouldGoToReadyPage = true;

			this.props.instagramQuickFlowActions.authenticateAndPreview(this.props.history, shouldGoToReadyPage);
		} else {
			const sourceId = returnSourceIdFromSourceString(source);

			this.props.instagramQuickFlowActions.generateQuickFlowSourceBranchLink(sourceId)
				.then((branchLink) => {
					window.location.href = branchLink;
				});
		}
	};

	render() {
		const source = this.getSource();
		const list = deep(SourceDescriptionList, `${source}`) || [];
		const ctaText = (source === QUICKFLOW_SOURCE_INSTAGRAM) ? 'Connect Your Instagram' : 'Get the Chatbooks app';

		return (
			<div className="quickflow-source-landing">
				<h1>How It Works</h1>

				{list.map((item, index) => (
					<div
						key={index}
						className="quickflow-source-landing-detial"
					>
						<img
							src={item.img}
							alt={source}
						/>
						<p>{item.text}</p>
					</div>
				))}

				<CtaButton
					className=""
					ctaText={ctaText}
					onCtaClick={this.onCtaClick}
					trackingName=""
				/>
			</div>
		);
	};
};

SourceLanding.propTypes = {
	analyticsActions: PropTypes.object,
	history: PropTypes.object,
	instagramQuickFlowActions: PropTypes.object,
	match: PropTypes.object,
};

SourceLanding.displayName = 'SourceLanding';

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
	instagramQuickFlowActions: bindActionCreators(instagramQuickFlowActions, dispatch),
});

export default connect(
	null,
	mapDispatchToProps
)(withRouter(SourceLanding));
