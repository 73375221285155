import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCreditCard = (props) => (
	<Svg
		{...props}
		name="credit-card"
		viewBox="0 0 23 18"
	>
		<path
			d="M0 15.875C0 16.9102 0.832666 17.75 1.85897 17.75H20.4487C21.475 17.75 22.3077 16.9102 22.3077 15.875V9H0V15.875ZM7.4359 13.2188C7.4359 12.9609 7.64503 12.75 7.90064 12.75H13.1677C13.4233 12.75 13.6325 12.9609 13.6325 13.2188V14.7812C13.6325 15.0391 13.4233 15.25 13.1677 15.25H7.90064C7.64503 15.25 7.4359 15.0391 7.4359 14.7812V13.2188ZM2.47863 13.2188C2.47863 12.9609 2.68777 12.75 2.94338 12.75H5.73184C5.98745 12.75 6.19658 12.9609 6.19658 13.2188V14.7812C6.19658 15.0391 5.98745 15.25 5.73184 15.25H2.94338C2.68777 15.25 2.47863 15.0391 2.47863 14.7812V13.2188ZM22.3077 2.125V4H0V2.125C0 1.08984 0.832666 0.25 1.85897 0.25H20.4487C21.475 0.25 22.3077 1.08984 22.3077 2.125Z"
			fill="inherit"
			stroke="none"
		/>
	</Svg>
);

IconCreditCard.displayName = 'IconCreditCard';

IconCreditCard.propTypes = iconProps;

IconCreditCard.defaultProps = defaultIconProps;

export default IconCreditCard;
