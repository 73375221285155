import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { booksFromJsonArray, booksSchema } from './book.model';
import { groupFromJson, groupSchema } from './group.model';
import { personFromJson, personSchema } from './person.model';

export const groupsBySharableKeyResponseModel = (json = {}) => {
	const group = groupFromJson(deep(json, 'group'));

	return {
		groupId: group.id,
		group,
		person: personFromJson(deep(json, 'person')),
		skinnyBooks: booksFromJsonArray(deep(json, 'skinnyBooks')),
	};
};

export const groupsBySharableKeyResponseSchema = new schema.Entity('ignoreEntity', {
	group: groupSchema,
	person: personSchema,
	skinnyBooks: booksSchema
}, {
	idAttribute: 'groupId',
});

export const normalizeGroupsBySharableKeyResponse = (response) => {
	return normalize(response, groupsBySharableKeyResponseSchema);
};
