import deep from 'deep-get-set';
import { omit } from 'lodash';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { APP_STRING_KEYS } from '../const/appStringKeys.const';
import { REDUCERS } from '../const/reducers.const';
import arrayToKeyMap from '../helpers/arrays/arrayToKeyMap';

export default (state = defaultState[REDUCERS.APP_STRING], action) => {
	const { data, type } = action;

	switch (type) {
		case ACTIONS.APP_STRING_DISPLAYED: {
			const current = deep(state, ['appStringsOnDisplay', action.appStringKey]) || {
				displayCount: 0,
				id: action.appStringKey,
			};

			return {
				...state,
				appStringsOnDisplay: {
					...state.appStringsOnDisplay,
					[action.appStringKey]: {
						...current,
						displayCount: current.displayCount + 1,
						lastUsage: new Date(),
					}
				}
			};
		}
		case ACTIONS.APP_STRING_HIDDEN: {
			const current = deep(state, ['appStringsOnDisplay', action.appStringKey]) || {
				displayCount: 1,
				id: action.appStringKey,
			};

			return {
				...state,
				appStringsOnDisplay: {
					...state.appStringsOnDisplay,
					[action.appStringKey]: {
						...current,
						displayCount: current.displayCount - 1,
					}
				}
			};
		}
		case ACTIONS.FETCH_APP_STRINGS: {
			const namespace = deep(action, 'namespace');

			return {
				...state,
				loadingNamespaces: {
					...state.loadingNamespaces,
					[namespace]: new Date().toString(),
				},
			};
		}
		case ACTIONS.RECEIVE_APP_STRINGS: {
			const strings = deep(data, 'strings') || [];
			const newAppStringsByKeyMap = arrayToKeyMap(strings, 'Key');
			const namespace = deep(action, 'namespace');
			const isFeatureAppStringInitializedState = deep(state, 'isFeatureAppStringInitialized') || false;
			const isFeatureAppStringInitialized = namespace === APP_STRING_KEYS.FEATURES ? true : isFeatureAppStringInitializedState;

			return {
				...state,
				isFeatureAppStringInitialized,
				loadingNamespaces: omit(state.loadingNamespaces, namespace),
				loadedNamespaces: {
					...state.loadedNamespaces,
					[namespace]: new Date().toString(),
				},
				stringByKeyMap: {
					...state.stringByKeyMap,
					...newAppStringsByKeyMap
				}
			};
		}
		default:
			return state;
	}
};
