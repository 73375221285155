import deep from 'deep-get-set';

export const instagramGraphDataSourceFromJson = (json = {}) => ({
	id: deep(json, 'ID'),
	name: deep(json, 'Name'),
	token: deep(json, 'Token.Token'),
});

export const getName = () => 'Instagram';

export const getUsername = (dataSource) => deep(dataSource, 'name');
