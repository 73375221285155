import React from 'react';
import PropTypes from 'prop-types';

import './BodyText.css';

const BodyText = ({
	text,
}) => (
	<p className="instagram-body-text">{text}</p>
);

BodyText.displayName = 'BodyText';

BodyText.propTypes = {
	text: PropTypes.string,
};

export default BodyText;
