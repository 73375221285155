import * as ACTIONS from './actionTypes';
import * as api from './api/api.actions';
import { setAppStringOverrideCookieByKey, clearAppStringOverridesCookie } from './appCookie.actions';
import { openModalById } from './view.actions';
import { APP_STRING_KEYS } from '../const/appStringKeys.const';
import { MODAL_NAMES } from '../const/modalNames.const';
import { LANGUAGE_KEYS } from '../const/language.const';
import { modalModel } from '../model/modal.model';
import { selectDefaultLanguageNameSpace, selectLocalesConfig } from '../selectors/config.selectors';
import { selectCurrentLang } from '../selectors/lang.selectors';
import { selectIsAppStringTrackerEnabled, selectTranslateFunc, isAppStringNamespaceLoadedOrLoadingFunc } from '../selectors/appString.selectors';

const appStringDisplayed = (appStringKey) => ({ type: ACTIONS.APP_STRING_DISPLAYED, appStringKey });
const appStringHidden = (appStringKey) => ({ type: ACTIONS.APP_STRING_HIDDEN, appStringKey });
export const appStringTrackerToggled = (enabled) => ({ type: ACTIONS.APP_STRING_TRACKER_TOGGLED, enabled });
export const receiveAppStrings = (data, namespace) => ({ type: ACTIONS.RECEIVE_APP_STRINGS, data, namespace });
export const fetchAppStrings = (namespace, language) => ({ type: ACTIONS.FETCH_APP_STRINGS, namespace, language });

export const appStringMounted = (appStringKey) => (dispatch, getState) => {
	if (selectIsAppStringTrackerEnabled(getState())) {
		dispatch(appStringDisplayed(appStringKey));
	}
};

export const appStringUnmounted = (appStringKey) => (dispatch, getState) => {
	if (selectIsAppStringTrackerEnabled(getState())) {
		dispatch(appStringHidden(appStringKey));
	}
};

export const clearAllAppStringOverrides = () => (dispatch) => {
	dispatch(clearAppStringOverridesCookie());
};

export const clearAppStringOverride = (key) => (dispatch) => {
	dispatch(setAppStringOverrideCookieByKey(key, undefined));
};

export const initializeAppStrings = () => (dispatch, getState) => {
	const state = getState();
	const namespace = selectDefaultLanguageNameSpace(state);

	return dispatch(loadAppStrings(namespace));
};

export const loadAppStrings = (namespace) => (dispatch, getState) => {
	const language = selectCurrentLang(getState());
	const supportedLocales = selectLocalesConfig(getState());
	const languageToLoad = !!supportedLocales[language] ? language : LANGUAGE_KEYS.en_US;

	dispatch(fetchAppStrings(namespace, languageToLoad));

	return dispatch(api.getAppStrings(namespace, languageToLoad))
		.then((appStringsJson) => dispatch(receiveAppStrings(appStringsJson, namespace)));
};

export const maybeLoadAppStrings = (namespace) => (dispatch, getState) => {
	const isAlreadyLoadedOrLoadingFunc = isAppStringNamespaceLoadedOrLoadingFunc(getState());

	if (!isAlreadyLoadedOrLoadingFunc(namespace)) {
		return dispatch(loadAppStrings(namespace));
	}

	return Promise.resolve();
};

export const getAppStringFeatureFlags = () => (dispatch) => {
	return dispatch(loadAppStrings(APP_STRING_KEYS.FEATURES));
};

export const getCardsCategoryAppString = () => (dispatch) => {
	return dispatch(loadAppStrings(APP_STRING_KEYS.PHOTO_CARDS));
};

export const getProductsAppString = () => (dispatch) => {
	return dispatch(loadAppStrings(APP_STRING_KEYS.PRODUCTS));
};

export const getProductListsPageAppString = () => (dispatch) => {
	return dispatch(loadAppStrings(APP_STRING_KEYS.PLP));
};

export const getProductDetailsPageAppString = () => (dispatch) => {
	return dispatch(loadAppStrings(APP_STRING_KEYS.PDP));
};

export const openNewAppStringOverrideModal = () => (dispatch) => {
	return dispatch(openModalById(modalModel({
		id: MODAL_NAMES.ADD_APP_STRING_OVERRIDE,
	})));
};

export const overrideAppString = (key, value) => (dispatch) => {
	dispatch(setAppStringOverrideCookieByKey(key, value));
};

export const translateEagerly = (key, params) => (dispatch, getState) => {
	dispatch(appStringDisplayed(key));
	dispatch(appStringHidden(key));

	if (!key) return Promise.resolve('');

	return dispatch(maybeLoadAppStrings(key))
		.then(() => selectTranslateFunc(getState())(key, params));
};
