import { first, isFunction } from 'lodash';

export { default as arrayMove } from './arrayMove';
export { default as arrayToKeyMap } from './arrayToKeyMap';

/**
 * Gets the first item off of an array.
 *
 * (This is really just a testable wrapper for 'lodash.first')
 *
 * @param {array} array The array to grab the first item from.
 */
export const firstItemInArray = (array) => {
	return first(array);
};

/**
 * Checks to see if an item is in an array
 *
 * @param {array} array An array
 * @param {any} item The value in the array
 * @returns {int} index
 */
export const itemIndexInArray = (array, item) => {
	for (let i = 0; i < array.length; i++) {
		if (array[i] === item) {
			return i;
		}
	}

	return null;
};

/**
 * Checks to see if an item is in an array based on a predicate.
 *
 * @param {array} array An array
 * @param {function(any):bool} predicate A function to evaluate each item in the array
 */
export const isInArray = (array = [], predicate) => {
	if (!array || !predicate || !Array.isArray(array) || !isFunction(predicate)) return false;

	return array.findIndex(predicate) !== -1;
}

/**
 * Checks to see if an item is in an array
 *
 * @param {array} array An array
 * @param {any} item The value in the array
 * @returns {bool}
 */
export const isItemInArray = (array, item) => isInArray(array, (currentItem) => currentItem === item);

/**
 * Removes a value from an array
 *
 * @param {array} array An array
 * @param {any} item The value in the array
 * @returns {array}
 */
export const removeItemFromArray = (array, item) => array.filter((arrayItem) => arrayItem !== item);

/**
 * Checks to see if the array is defined and not empty
 *
 * @param {array} array An array
 * @returns {boolean}
 */
export const hasLength = (array) => !!array && array.length > 0;
