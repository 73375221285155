import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconInclude = (props) => (
	<Svg
		{...props}
		name="include"
		viewBox="0 0 14 14"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-1.000000, -1.000000)">
				<path
					d="M9,8.52431191 L11.9165039,10.0506592 L4.99707031,10.8249512 L6.0090332,4.11413574 L7.51297869,7 L8,7 L8,6 L9,6 L9,5 L10,5 L10,4 L11,4 L11,3 L12,3 L12,2 L13,2 L13,1 L15,1 L15,3 L14,3 L14,4 L13,4 L13,5 L12,5 L12,6 L11,6 L11,7 L10,7 L10,8 L9,8 L9,8.52431191 Z M7,3 L3,3 L3,13 L13,13 L13,9 L15,9 L15,1 L7,1 L7,3 Z M1,1 L15,1 L15,15 L1,15 L1,1 Z"
					fill="inherit"
				/>
			</g>
		</g>
	</Svg>
);

IconInclude.displayName = 'IconInclude';

IconInclude.propTypes = iconProps;

IconInclude.defaultProps = defaultIconProps;

export default IconInclude;
