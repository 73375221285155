import deep from 'deep-get-set';
import { first } from 'lodash';
import { displayDateFromJson } from '../model/displayDate.model';

import * as ACTIONS from './actionTypes';
import * as api from './api/api.actions';
import { uploadSocialMoment } from './api/api.uploader.actions';
import * as appActions from './app.actions';
import { receiveEntities } from './entity.actions';
import * as groupActions from './groups.actions';
import * as uploaderActions from './uploader.actions';
import * as uploaderHelpers from '../components/AngularApp/web-app/src/common/services/uploader/uploader.helpers';
import { BOOK_SIZES } from '../const/bookSizes.const';
import { isLayoutCollage } from '../helpers/groups/groups';
import { normalizeMoment, momentFromJson } from '../model/moment.model';
import { collageLayoutsByBookSizeFromJson, normalizeCollageLayoutsByBookSize } from '../model/collageLayoutsByBookSize.model';
import * as groupsSelectors from '../selectors/groups.selectors';

export const momentsDeleted = (momentIds, bookId) => ({ type: ACTIONS.MOMENTS_DELETE, momentIds, bookId });

export const getCollageLayouts = (bookSize = BOOK_SIZES.SIX_BY_SIX) => (dispatch, getState) => {
	const selectedCollageLayouts = groupsSelectors.selectCollageLayouts(getState());
	const isQueried = deep(selectedCollageLayouts, `bookSize(${bookSize})`);

	if (isQueried) return Promise.resolve();

	return dispatch(api.getCollageLayouts(bookSize))
		.then((data) => {
			const collageLayouts = deep(data, 'collageLayouts');
			const formatted = collageLayoutsByBookSizeFromJson(bookSize, collageLayouts);
			const normalized = normalizeCollageLayoutsByBookSize(formatted);

			dispatch(receiveEntities(normalized.entities, getCollageLayouts.name));
		});
};

export const loadCaptionCanUnderflow = (bookSizeStr, pageLayoutType, text) => (dispatch) => {
	return dispatch(api.postMomentsCaptionSize(bookSizeStr, pageLayoutType, text))
		.then(({ captionSizeModel }) => captionSizeModel.Pages.length < 2 && !captionSizeModel.CaptionTruncated);
};

export const loadMomentById = (momentId, groupId) => (dispatch) => {
	return dispatch(api.getMomentById(momentId, groupId))
		.then(({ moment }) => {
			const normalized = normalizeMoment(momentFromJson(moment));
			dispatch(receiveEntities(normalized.entities, loadMomentById.name));

			return moment; // Maintain legacy untransformed data
		});
};

export const loadMomentDisplayDate = (momentId) => (dispatch) => {
	return dispatch(api.getMomentsDisplayDate(momentId))
		.then(({ date }) => displayDateFromJson(date));
};

export const loadMomentLocation = (momentId) => (dispatch) => {
	return dispatch(api.getMomentsLocationName(momentId))
		.then(({ locationName }) => locationName);
};

export const loadMomentMedia = (moment) => (dispatch) => {
	if (!moment || !moment.id) return null;

	if (isLayoutCollage(moment.pageLayoutType)) {
		dispatch(groupActions.getCollageMedia(moment.id))
			.then(({ collageMedia }) => {
				const mediaId = first(collageMedia.map((cm) => cm.MediaId));
				if (mediaId) {
					dispatch(groupActions.getMediaByMediaId(mediaId));
				}
			});
	} else if (moment.mediaId) {
		dispatch(groupActions.getMediaByMediaId(moment.mediaId));
	}
};

export const loadMomentTextCharLimit = (momentId, text) => (dispatch) => {
	return dispatch(api.postMomentsLayoutCaption(momentId, text))
		.then(({ captionSizeModel }) => {
			const textIsTruncated = captionSizeModel.CaptionTruncated || captionSizeModel.Pages.length > 1;
			if (!textIsTruncated) {
				return text.length;
			} else {
				const userWhitespaceChars = [...text.matchAll(/\s/g)];
				const firstPageTextWithoutWhitespace = captionSizeModel.Pages[0].FormattedText.replace(/\s/g, '');
				const charLimit = userWhitespaceChars.reduce((limit, match) => {
					if (match.index < limit) {
						return limit + 1;
					}

					return limit;
				}, firstPageTextWithoutWhitespace.length);

				return charLimit;
			}
		});
};

export const moveContinuationToCaption = (momentId) => (dispatch) => {
	return dispatch(api.postMomentsMoveContinuationToCaption(momentId))
		.then(({ moment }) => {
			const normalized = normalizeMoment(momentFromJson(moment));
			dispatch(receiveEntities(normalized.entities, moveContinuationToCaption.name));
			dispatch(momentsDeleted([momentId]));

			return moment;
		});
};

export const overflowCaptionToNextPage = (momentId, groupId, caption) => (dispatch) => {
	// Once we refactor/rip out momentDetail.js, move all animation and redux state update
	// logic into this function.
	return dispatch(saveMomentCaption(momentId, groupId, caption, true));
};

export const removeMoment = (momentId, bookId) => (dispatch) => {
	return dispatch(api.postMomentsDelete(momentId)).then((res) => {
		dispatch(momentsDeleted([momentId], bookId));

		return res;
	});
};

export const saveMedia = ({ groupId, mediaUploadType, photos }) => (dispatch) => {
	dispatch(appActions.showThrobber(saveMedia.name));

	const addMediaPhotoModel = (media = {}) => ({
		id: media.id,
		created_time: media.date ? Math.floor(new Date(media.date).getTime() / 1000) : null,
		caption: {
			text: media.caption,
		},
		tags: media.tags,
		type: 'image',
		images: {
			standard_resolution: {
				url: media.standardResUrl,
			},
			thumbnail: {
				url: media.thumbnailUrl,
			},
		},
	});

	const addMediaPhotosModel = (photos) => Array.isArray(photos) ? photos.map(addMediaPhotoModel) : [];

	const addMediaRequestModel = ({ groupId, mediaUploadType, photos }) => ({
		groupId,
		mediaUploadType,
		suppressContinuations: false,
		returnFirstMoment: false,
		medias: addMediaPhotosModel(photos),
	});

	return dispatch(api.postMomentsAddMedia(addMediaRequestModel({
		groupId,
		mediaUploadType,
		photos,
	})))
		.finally(() => dispatch(appActions.hideThrobber(saveMedia.name)));
};

export const saveMomentCaption = (momentId, groupId, caption, allowOverflow, forceCaption) => (dispatch) => {
	return dispatch(api.postMomentsUpdateCaption(momentId, caption, allowOverflow, forceCaption));
};

export const saveMomentDate = (momentId, groupId, shortDate) => (dispatch) => {
	dispatch(appActions.showThrobber(saveMomentDate.name));

	return dispatch(api.postMomentsDisplayDate(momentId, shortDate))
		.then(() => dispatch(loadMomentById(momentId, groupId)))
		.finally(() => dispatch(appActions.hideThrobber(saveMomentDate.name)));
};

export const saveMomentLocation = (momentId, groupId, locationName) => (dispatch) => {
	dispatch(appActions.showThrobber(saveMomentLocation.name));

	return dispatch(api.postMomentsSetLocationName(momentId, locationName))
		.then(() => dispatch(loadMomentById(momentId, groupId)))
		.finally(() => dispatch(appActions.hideThrobber(saveMomentLocation.name)));
};

export const saveMomentText = (momentId, groupId, text) => (dispatch) => {
	return dispatch(api.putMomentsUpdate(momentId, { Text: text }))
		.then(() => dispatch(loadMomentById(momentId, groupId)));
};

export const uploadFile = (file, groupId) => (dispatch) => {
	let imageUrl = file._imageUrl;
	if (imageUrl && /^http[s]?:\/\/lh3.googleusercontent.com/i.test(imageUrl)) {
		// If it's a Google photo, make sure the scale is large enough for our resolution warnings
		imageUrl = imageUrl.replace(/\/s300\//, '/s3150/');
	}

	// Make sure it's a supported format
	if (file.type && !uploaderHelpers.isValidFile(file)) {
		const error = new Error(`Invalid file: ${file.name} ("${file.type}" not supported)`);
		error.name = 'UnsupportedFileError';

		return Promise.reject(error);
	}

	return imageUrl
		? dispatch(uploadSocialMoment(groupId, imageUrl))
		: dispatch(uploaderActions.uploadSingleFile(file))
			.then((medium) => dispatch(api.postMomentsCreate(groupId, null, medium.Medium.MediaID)));
};
