import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '../Box/Box';
import { COLOR } from '../../const';

const StyledBox = styled(Box)`
	background-color: ${(props) => props.backgroundColor};
`;

const BoxWithBackground = ({
	backgroundColor,
	children,
	className,
}) => (
	<StyledBox
		className={className}
		isBorderBottomVisible={false}
		isBorderLeftVisible={false}
		isBorderRightVisible={false}
		isBorderTopVisible={false}
		backgroundColor={backgroundColor}
	>
		{children}
	</StyledBox>
);

BoxWithBackground.displayName = 'BoxWithBackground';

BoxWithBackground.propTypes = {
	backgroundColor: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
};

BoxWithBackground.defaultProps = {
	backgroundColor: COLOR.GRAY_200
}

export default BoxWithBackground