const get = (props) => {
	const config = loadConfig();
	const parts = props.split('.');

	return parts.reduce((obj, prop) => {
		return obj && obj[prop];
	}, config);
};

const isProduction = () => get('config') === 'production';

const loadConfig = () => {
	/**
	 * If we're in the browser, read config object from the window
	 **/

	if (window.config) {
		return window.config;
	}

	/**
	 * If we're on the server, read in the appropriate config file
	 **/

	// eslint-disable-next-line no-undef
	const _exports = typeof exports !== 'undefined' ? exports : null;
	// eslint-disable-next-line no-undef
	const _require = typeof __non_webpack_require__ !== 'undefined' ? __non_webpack_require__ : null;
	if (_exports && _require) {
		const path = _require('path');
		const env = process.env.NODE_ENV;
		// eslint-disable-next-line no-undef
		const configPath = path.resolve(__dirname, '..', '..', 'build', 'config', '', `${env}.js`);
		const config = _require(configPath);

		return config;
	}

	// Not sure what environment we're in, so just return an empty object ¯\_(ツ)_/¯
	return {};
};

export default { get, isProduction, loadConfig };
