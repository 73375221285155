import { normalize, schema } from 'normalizr';

import { excludedMomentsDateInfoSchema, excludedMomentsDateInfoFromJson } from './excludedMomentsDateInfo.model';
import { excludedMomentsPageSchema, excludedMomentsPageFromJson } from './excludedMomentsPage.model';
import { groupExcludedMomentsPageSchema, groupExcludedMomentsPageFromJson, makeGroupExcludedMomentsPageId } from './groupExcludedMomentsPage.model';
import { ENTITIES } from '../const/entities/entities';

export const excludedMomentsResponseFromJson = (groupId, index, pageSize, json = {}) => {
	const model = {
		groupId,
		index,
		groupExcludedMomentsPage: groupExcludedMomentsPageFromJson(groupId, index),
	};

	if (json.dateInfo) {
		model.dateInfo = excludedMomentsDateInfoFromJson(groupId, json.dateInfo);
	}

	if (json.moments && json.moments.length) {
		model.excludedMomentsPage = excludedMomentsPageFromJson(groupId, index, pageSize, json.moments);
	}

	return model;
}

const excludedMomentsResponseSchema = new schema.Entity(ENTITIES.EXCLUDED_MOMENTS_RESPONSE, {
	dateInfo: excludedMomentsDateInfoSchema,
	groupExcludedMomentsPage: groupExcludedMomentsPageSchema,
	excludedMomentsPage: excludedMomentsPageSchema,
}, {
	idAttribute: (model) => makeGroupExcludedMomentsPageId(model.groupId, model.index),
});

export const normalizeExcludedMomentsResponse = (excludedMomentsResponse) => normalize(excludedMomentsResponse, excludedMomentsResponseSchema);
