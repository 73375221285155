import React, { Fragment } from 'react';

import Translate from './Translate';
import { isAppString, maybeAppStringPropType } from '../../model/appString.model';

/**
 * MaybeTranslate either translates, or just returns a string value.
 *
 * @param {props.value} value Either a string or an appString.
 */
const MaybeTranslate = ({ value }) => {
	if (value === undefined) {
		return null;
	} else if (Array.isArray(value)) {
		return value.map((maybeAppString, index) => (
			<Fragment key={index}>
				<MaybeTranslate value={maybeAppString} />
				{' '}
			</Fragment>
		));
	}
	else if (isAppString(value)) {
		return <Translate id={value.id} params={value.params} />
	}

	return value;
}

MaybeTranslate.displayName = 'MaybeTranslate';

MaybeTranslate.propTypes = {
	value: maybeAppStringPropType,
};

export default MaybeTranslate;
