import deep from 'deep-get-set';

import * as ACTIONS from './actionTypes';
import { MODAL_NAMES } from '../const/modalNames.const';
import { appStringModel } from '../model/appString.model';
import { confirmModalModel } from '../model/confirmModal.model';
import { modalModel } from '../model/modal.model';
import { selectOpenModals } from '../selectors/view.selectors';

export const groupSelected = (id) => ({ type: ACTIONS.GROUP_SELECTED, id });
export const closeModalById = (id) => ({ type: ACTIONS.MODAL_POPPED_FROM_MODAL_STACK, id });
export const modalOpened = (modal) => ({ type: ACTIONS.MODAL_PUSHED_ONTO_MODAL_STACK, modal });
export const momentSelected = (id) => ({ type: ACTIONS.MOMENT_SELECTED, id });
export const momentUnselected = (id) => ({ type: ACTIONS.MOMENT_UNSELECTED, id });
export const momentSelectedClear = () => ({ type: ACTIONS.MOMENT_SELECTED_CLEAR });
export const setActiveMoment = (momentId) => ({ type: ACTIONS.MOMENT_ACTIVATED, momentId });
export const setActiveCollageMediaIndex = (mediaIndex) => ({ type: ACTIONS.COLLAGE_MEDIA_SELECTED_INDEX, mediaIndex });

export const openModalById = (modalData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		return dispatch(modalOpened(modalModel({
			...modalData,
			data: {
				...modalData.data,
				confirmCallback: deep(modalData, 'data.confirmCallback') || resolve,
				cancelCallback: deep(modalData, 'data.cancelCallback') || reject,
			},
		})));
	});
};

export const openConfirmModal = (modalData) => (dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(openModalById(modalModel({
			id: MODAL_NAMES.CONFIRM,
			data: confirmModalModel({
				...modalData,
				confirmCallback: deep(modalData, 'confirmCallback') || resolve,
				cancelCallback: deep(modalData, 'cancelCallback') || reject,
			}),
		})));
	});
};

export const openDialogModal = (modalData) => (dispatch) => {
	const dialogModal = modalModel({
		id: MODAL_NAMES.DIALOG,
		data: modalData,
	});

	return dispatch(openModalById(dialogModal));
};

export const openErrorModal = (confirmModal) => (dispatch) => {
	return dispatch(openConfirmModal(confirmModalModel({
		...confirmModal,
		title: deep(confirmModal, 'title') || appStringModel({ id: 'web.error-modal.title' }),
		body: deep(confirmModal, 'body') || appStringModel({ id: 'web.error-modal.body' }),
	})));
};

export const openToastModal = (modalData) => (dispatch) => {
	const toastModal = modalModel({
		id: MODAL_NAMES.TOAST,
		data: modalData,
	});

	return dispatch(openModalById(toastModal));
};

/**
 * Opens a modal on the modal stack, but only allows one to be open.
 * @param {object} modal The modalModel to open.
 */
export const openModalSingleton = (modal) => (dispatch, getState) => {
	const openModals = selectOpenModals(getState());

	return (!openModals[modal.id]) ? dispatch(openModalById(modal)) : Promise.resolve();
};
