import { asyncComponent } from 'react-async-component';
import { withRouter } from 'react-router-dom';

export default withRouter(
	asyncComponent({
	name: 'AsyncApp',
	env: process.env.SERVER ? 'node' : 'browser',
		resolve: () => import('./App')
	})
);
