import { findLastIndex } from 'lodash';

/**
 * Removes the last instance of an item in an array with a specified id.
 *
 * It only removes the last instance. If a previous duplicate exists, it's left in place.
 *
 * @param {array} stack An array of objects with an id attribute. [ { id: string } ]
 * @param {string} id The id of the item being removed.
 */
const pop = (stack, id) => {
	const foundIndex = findLastIndex(stack, (item) => item.id === id);

	if (foundIndex === -1) {
		return stack;
	} else {
		return [...stack.slice(0, foundIndex), ...stack.slice(foundIndex + 1)];
	}
};

/**
 * Pushes an item onto an array stack.
 *
 * Duplicate ids are okay.
 *
 * @param {array} stack An array of objects with an id attribute. [ { id: string } ]
 * @param {object} item An item with an id attribute. { id: string }
 */
const push = (stack, item) => {
	if (!item || !item.id) {
		return stack;
	} else {
		return [...stack, item];
	}
};

export const stackWithIds = {
	pop,
	push,
}
