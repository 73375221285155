import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HomePagePillButton from '../../components/Button/HomePagePillButton/HomePagePillButton';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import withWindowSize from '../../components/WindowSize/withWindowSize';
import { DEVICE_WIDTH, mediaQueryBetween, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../const';

const STACKED_BREAKPOINT = DEVICE_WIDTH.TABLET;
const SQUISHED_BREAKPOINT = DEVICE_WIDTH.LAPTOP;

const twoColorBackground = (colorChangePoint, topColor, bottomColor) => `linear-gradient(to top, ${bottomColor}, ${bottomColor} ${colorChangePoint}, ${topColor} ${colorChangePoint}, ${topColor})`;

const heroHeightValues = ({ topHeight, bottomHeight }) => {
	return {
		bottomHeightPx: `${bottomHeight}px`,
		topHeightPx: `${topHeight}px`,
		heightPx: `${topHeight + bottomHeight}px`,
	};
};

const MOBILE_HEIGHTS = heroHeightValues({
	topHeight: 491,
	bottomHeight: 116,
});

const DESKTOP_HEIGHTS = heroHeightValues({
	topHeight: 408-50,
	bottomHeight: 272-50,
});

const Container = styled.div`
	${mediaQueryUpTo(STACKED_BREAKPOINT)} {
		background: ${(props) => twoColorBackground(MOBILE_HEIGHTS.bottomHeightPx, props.topBackgroundColor, props.bottomBackgroundColor)};
	}
	${mediaQueryStartingFrom(STACKED_BREAKPOINT)} {
		background: ${(props) => twoColorBackground(DESKTOP_HEIGHTS.bottomHeightPx, props.topBackgroundColor, props.bottomBackgroundColor)};
	}
`;

const HeroGridDiv = styled(PageFlowContainer)`
	display: grid;

	${mediaQueryUpTo(STACKED_BREAKPOINT)} {
		grid-template-columns: 1fr;
		grid-template-areas:
			'headline'
			'cta'
			'image';
		height: ${MOBILE_HEIGHTS.heightPx};
		row-gap: ${SPACING.MEDIUM};
	}
	${mediaQueryStartingFrom(STACKED_BREAKPOINT)} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-rows: ${DESKTOP_HEIGHTS.topHeightPx} ${DESKTOP_HEIGHTS.bottomHeightPx};
		grid-template-areas:
			'headline image'
			'cta image';
		height: ${DESKTOP_HEIGHTS.heightPx};
	}
`;
const PADDING_LEFT_DESKTOP = '20%';
const PADDING_X_MOBILE = '10%';

const HeadlineAreaDiv = styled.div`
	align-self: center;
	color: #333333;
	grid-area: headline;

	${mediaQueryUpTo(STACKED_BREAKPOINT)} {
		margin-top: ${SPACING.BIG};
		text-align: center;
		padding-left: ${PADDING_X_MOBILE};
		padding-right: ${PADDING_X_MOBILE};
	}
	${mediaQueryStartingFrom(STACKED_BREAKPOINT)} {
		padding-left: ${PADDING_LEFT_DESKTOP};
	}
`;

const HeadlineDiv = styled.div`
	${mediaQueryUpTo(STACKED_BREAKPOINT)} {
		font-size: 30px;
		line-height: 35px;
		letter-spacing: 0.0125em;
		margin-bottom: ${SPACING.BIG};
	}
	${mediaQueryStartingFrom(STACKED_BREAKPOINT)} {
		font-size: 44px;
		line-height: 56.25px;
		margin-bottom: ${SPACING.MEDIUM};
	}
	${mediaQueryBetween(STACKED_BREAKPOINT, SQUISHED_BREAKPOINT)} {
		font-size: 38px;
		line-height: 45.6px;
	}
`;

const HeadlinePrefix = styled.span`
	font-family: Circular-Pro-Medium;
`;

const MemorySpan = styled.span`
	color: ${(props) => props.color};
	font-family: Circular-Pro-Bold;
`;

const SubcopyDiv = styled.div`
	font-family: Circular-Pro-Medium;

	${mediaQueryUpTo(STACKED_BREAKPOINT)} {
		font-size: 16px;
		letter-spacing: 0.0125em;
		line-height: 23px;
		margin: auto;
		text-align: center;
		max-width: 260px;
	}
	${mediaQueryStartingFrom(STACKED_BREAKPOINT)} {
		font-size: 25px;
		line-height: 35px;
		max-width: 601px;
	}
	${mediaQueryBetween(STACKED_BREAKPOINT, SQUISHED_BREAKPOINT)} {
		font-size: 22px;
		line-height: 36px;
	}
`;

const CtaContainerDiv = styled.div`
	align-self: center;
	display: grid;
	grid-area: cta;

	${mediaQueryUpTo(STACKED_BREAKPOINT)} {
		justify-content: center;
	}
	${mediaQueryStartingFrom(STACKED_BREAKPOINT)} {
		justify-content: left;
		padding-left: ${PADDING_LEFT_DESKTOP};
	}
`;

const Cta = styled(HomePagePillButton)`
	align-self: center;
`;

const Image = styled.img`
	align-self: center;
	grid-area: image;
	justify-self: center;

	${mediaQueryUpTo(STACKED_BREAKPOINT)} {
		max-width: 300px;
		margin-bottom: ${SPACING.BIG};
	}
	${mediaQueryBetween(STACKED_BREAKPOINT, DEVICE_WIDTH.LAPTOP_L)} {
		max-width: 43vw;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.LAPTOP_L)} {
		max-width: 660px;
	}
`;

const useMemoryTypewriter = (memories) => {
	const [memoryIndex, setMemoryIndex] = useState(0);

	useEffect(() => {
		if (memories && memories.length) {
			const timer = setTimeout(() => setMemoryIndex((memoryIndex + 1) % memories.length), 1500);

			return () => clearTimeout(timer);
		}
	}, [memories, memoryIndex]);

	if (memories && memories.length) return memories[memoryIndex];
};

const HeroTypewriter = ({
	bottomBackgroundColor,
	ctaBackgroundColor,
	ctaBackgroundColorMobile,
	ctaBackgroundHoverColor,
	ctaBackgroundHoverColorMobile,
	ctaTextColor,
	ctaTextColorMobile,
	ctaTextDesktop,
	ctaTextHoverColor,
	ctaTextHoverColorMobile,
	ctaTextMobile,
	ctaUrlDesktop,
	ctaUrlMobile,
	headlineAndSubcopyColor,
	headlineMemoryColor,
	heroHeadline,
	imageAlt,
	imageUrl,
	linkCallback,
	memories,
	subcopy,
	topBackgroundColor,
	windowSize,
}) => {
	const isMobile = windowSize.width < STACKED_BREAKPOINT;

	const memory = useMemoryTypewriter(memories);

	return (
		<Container
			topBackgroundColor={topBackgroundColor}
			bottomBackgroundColor={bottomBackgroundColor}>
			<HeroGridDiv>
					<HeadlineAreaDiv
						color={headlineAndSubcopyColor}>
						<HeadlineDiv>
							<HeadlinePrefix>{heroHeadline}</HeadlinePrefix>
							{memory && (
								<>
									{isMobile ? (<br />) : (' ')}
									<MemorySpan
										color={headlineMemoryColor}>
										{memory}
									</MemorySpan>
								</>
							)}
						</HeadlineDiv>
						<SubcopyDiv>
							{subcopy}
						</SubcopyDiv>
					</HeadlineAreaDiv>
					<CtaContainerDiv>
						<Cta
							borderColor={isMobile ? ctaBackgroundColorMobile : ctaBackgroundColor}
							backgroundColor={isMobile ? ctaBackgroundColorMobile : ctaBackgroundColor}
							hoverBackgroundColor={isMobile ? ctaBackgroundHoverColorMobile : ctaBackgroundHoverColor}
							hoverTextColor={isMobile ? ctaTextHoverColorMobile : ctaTextHoverColor}
							color={isMobile ? ctaTextColorMobile : ctaTextColor}
							onClick={() => linkCallback(isMobile ? ctaUrlMobile : ctaUrlDesktop)}
						>
							{isMobile ? ctaTextMobile : ctaTextDesktop}
						</Cta>
					</CtaContainerDiv>
					<Image
						alt={imageAlt}
						src={imageUrl}
					/>
			</HeroGridDiv>
		</Container>
	);
};

HeroTypewriter.displayName = 'HeroTypewriter';

HeroTypewriter.propTypes = {
	bottomBackgroundColor: PropTypes.string,
	ctaBackgroundColor: PropTypes.string,
	ctaBackgroundColorMobile: PropTypes.string,
	ctaBackgroundHoverColor: PropTypes.string,
	ctaBackgroundHoverColorMobile: PropTypes.string,
	ctaTextColor: PropTypes.string,
	ctaTextColorMobile: PropTypes.string,
	ctaTextDesktop: PropTypes.string,
	ctaTextHoverColor: PropTypes.string,
	ctaTextHoverColorMobile: PropTypes.string,
	ctaTextMobile: PropTypes.string,
	ctaUrlDesktop: PropTypes.string,
	ctaUrlMobile: PropTypes.string,
	headlineAndSubcopyColor: PropTypes.string,
	headlineMemoryColor: PropTypes.string,
	heroHeadline: PropTypes.string,
	imageAlt: PropTypes.string,
	imageUrl: PropTypes.string,
	linkCallback: PropTypes.func,
	memories: PropTypes.arrayOf(PropTypes.string),
	subcopy: PropTypes.string,
	topBackgroundColor: PropTypes.string,
	windowSize: PropTypes.object,
};

export default withWindowSize(HeroTypewriter);
