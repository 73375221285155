import { useState } from 'react';

export const useIsHover = () => {
	const [ isHover, setIsHover ] = useState(false);

	return {
		hoverProps: {
			onMouseOver: () => setIsHover(true),
			onMouseLeave: () => setIsHover(false),
		},
		isHover,
	};
};

export default useIsHover;