import deep from 'deep-get-set';

import { hideThrobber } from './app.actions';
import * as branchActions from './branch.actions';
import * as groupActions from './groups.actions';
import * as instagramActions from './instagram.actions';
import { openErrorModal } from './view.actions';
import { CHATBOOKS_URIS } from '../const/actionUri.const';
import { BOOK_CREATION_MODEL_TYPES } from '../const/bookCreationModelTypes.const';
import { BOOK_SIZES } from '../const/bookSizes.const';
import { BRANCH_PARAMS, DEEP_VIEWS, BRANCH_CAMPAIGNS } from '../const/branch.const';
import { DATA_SOURCE_NAMES } from '../const/dataSource.const';
import { makeInstagramQuickflowActionUri } from '../helpers/actionUri/actionUri.helpers';
import { queryStringFromJson } from '../helpers/locationUtils';
import { SERIES_PREVIEW_URL } from '../routes/instagram-quick-flow/series-preview/SeriesPreview';
import { INSTAGRAM_QUICK_FLOW_URL } from '../routes/instagram-quick-flow/InstagramQuickFlow';
import { QUICK_FLOW_BOOKS_ARE_READY_URL } from '../routes/quick-flow/BooksAreReady/BooksAreReady';
import { QUICK_FLOW_URL } from '../routes/quick-flow/QuickFlow';
import { selectAccessToken } from '../selectors/user.selectors';
import { getInstagramUser } from '../services/instagram/instagram';


export const authenticateAndPreview = (history, shouldGoToReadyPage) => (dispatch) => {
	return dispatch(instagramActions.authenticate(makeInstagramQuickflowActionUri(shouldGoToReadyPage)))
		// The normal desktop flow will continue here, but IG In-App browser will continue in Instagram auth window.
		.then((token) => dispatch(goToInstagramSeriesPreview(token, history, shouldGoToReadyPage)))
		.catch((error) => console.error('Failed to authenticate Instagram', error));
};

export const generateIGMergeBranchLink = (history, groupId, photos, instagramToken, shouldGoToReadyPage) => (dispatch, getState) => {
	const token = selectAccessToken(getState());

	return getInstagramUser({ token: instagramToken })
		.then((instagramUser) => {
			const instagramHandle = deep(instagramUser, 'username');

			const deepViewLink = shouldGoToReadyPage ? DEEP_VIEWS.QUICKFLOW_DEEP_VIEW : DEEP_VIEWS.IG_QUICKFLOW_DEEP_VIEW;

			return dispatch(branchActions.generateBranchUrl({
				ActionUri: `${CHATBOOKS_URIS.IG_MERGE}?${queryStringFromJson({ groupid: groupId, handle: instagramHandle, token })}`,
				OpenScreen: 'ActionUri',
				[BRANCH_PARAMS.CAMPAIGN]: BRANCH_CAMPAIGNS.INSTAGRAM_QUICK_FLOW,
				instagramHandle,
				photo1: deep(photos, `0.thumbnailUrl`),
				photo2: deep(photos, `1.thumbnailUrl`),
				photo3: deep(photos, `2.thumbnailUrl`),
				photo4: deep(photos, `3.thumbnailUrl`),
				photo5: deep(photos, `4.thumbnailUrl`),
				[BRANCH_PARAMS.DEEP_VIEW_OVERRIDE.ANDROID]: deepViewLink,
				[BRANCH_PARAMS.DEEP_VIEW_OVERRIDE.IOS]: deepViewLink,
			}))
				.then((branchLink) => ({
					branchLink,
					instagramHandle,
				}));
		})
		.then(({ branchLink, instagramHandle }) => {
			const queryObj = { groupId, token: instagramToken, branchLink, instagramHandle };
			const url = getReadyPageUrl(shouldGoToReadyPage, queryObj);

			return history.replace(url);
		})
		.catch((err) => {
			console.error('Unable to generate branch link', err);
			dispatch(hideThrobber(generateIGMergeBranchLink.name));
			dispatch(openErrorModal());
		});
};

export const generateQuickFlowSourceBranchLink = (sourceId) => (dispatch, getState) => {
	const deepViewLink = DEEP_VIEWS.QUICKFLOW_DEEP_VIEW;

	return dispatch(branchActions.generateBranchUrl({
		ActionUri: `${CHATBOOKS_URIS.QUICK_FLOW}?${queryStringFromJson({ source: sourceId })}`,
		OpenScreen: 'ActionUri',
		[BRANCH_PARAMS.DEEP_VIEW_OVERRIDE.ANDROID]: deepViewLink,
		[BRANCH_PARAMS.DEEP_VIEW_OVERRIDE.IOS]: deepViewLink,
	}))
		.catch((err) => {
			console.error('Unable to generate branch link', err);
			dispatch(hideThrobber(generateIGMergeBranchLink.name));
			dispatch(openErrorModal());
		});
};

const getReadyPageUrl = (shouldGoToReadyPage, queryObj) => {
	const url = (shouldGoToReadyPage) ? `${QUICK_FLOW_URL}${QUICK_FLOW_BOOKS_ARE_READY_URL}` : `${INSTAGRAM_QUICK_FLOW_URL}${SERIES_PREVIEW_URL}`;

	return `${url}?${queryStringFromJson(queryObj)}`;
};

export const goToInstagramSeriesPreview = (token, history, shouldGoToReadyPage) => () => {
	const queryObj = { token };
	const url = getReadyPageUrl(shouldGoToReadyPage, queryObj);

	history.push(url);

	return Promise.resolve();
};

export const loadInstagramSeriesPreviewPhotos = (token) => (dispatch) => {
	const numberOfPhotosDesiredForPreview = 5;

	return dispatch(instagramActions.loadPhotosForPhotoPicker({ count: numberOfPhotosDesiredForPreview, token }));
};

export const saveSeries = (history, token, shouldGoToReadyPage) => (dispatch) => {
	const newIGSeries = {
		source: DATA_SOURCE_NAMES.INSTAGRAM,
		isHardcover: false,
		bookCreationModelType: BOOK_CREATION_MODEL_TYPES.SIXTY_PAGE_SERIES,
		bookSize: BOOK_SIZES.SIX_BY_SIX,
	};

	return dispatch(groupActions.createGroupTitle(newIGSeries.bookCreationModelType, newIGSeries.source))
		.then((title) => dispatch(groupActions.createGroup({ ...newIGSeries, title })))
		.then((groupId) => {
			return dispatch(instagramActions.saveInstagramGraphDatasourceToGroup({ groupId, longLivedToken: token }))
				.then(() => groupId);
		})
		.then((groupId) => {
			const queryObj = { groupId, token };
			const url = getReadyPageUrl(shouldGoToReadyPage, queryObj);

			return history.replace(url);
		})
		.catch((err) => {
			console.error('Unable to save series', err);
			dispatch(hideThrobber(saveSeries.name));
			dispatch(openErrorModal());
		});
};
