import { isObject } from '../objects/objects';

export const receiveEntityObjects = (oldState, newState, key) => {
	return !newState || !newState[key]
		? {}
		: { [key]: produceNewObject(oldState[key], newState[key]) };
};

const produceNewObject = (oldState = {}, newState = {}) => {
	return (!newState || !oldState || !isObject(oldState))
		? newState || oldState
		: Object.keys(newState).reduce((oldState, key) => {
			if (newState[key] === undefined) return oldState;

			return {
				...oldState,
				[key]: isObject(newState[key])
					? produceNewObject(oldState[key], newState[key])
					: newState[key],
			};
		}, oldState);
};

export const receiveEntityArrayReplacement = (state, data, key) => {
	if (!data) return state;

	return {
		[key]: data[key] ? data[key] : state[key] || [],
	};
};