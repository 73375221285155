/**
 * Order the referrals array based on status. Unfortunately the correct status order is 1,3,2 - so
 * we have to order it.
 *
 * (This actually sorts 2,3,1... which may be the correct sort!) TODO - investigate order and correct comments.
 * @param arr Referrals array
 */
const sortFunction = (a, b) => {
	if ((a.Status === 3 && b.Status === 2) || (a.Status === 1 && b.Status !== 1)) {
		return 1;
	}
	if ((a.Status === 2 && b.Status === 1) || (a.Status === 3 && b.Status === 1) || (a.Status === 2 && b.Status !== 2)) {
		return -1;
	}

	return 0;
};

export default (referrals) => (referrals || []).slice().sort(sortFunction);
