import React from 'react';

import ReviewLine from './ReviewLine';
import CtaButton from '../CtaButton/CtaButton';
import './HeroInfo.css';

const HeroInfo = () => (
	<section className="instagram-quickflow-page-hero-info padding">
		<h1>Instagram Book Series</h1>
		<ReviewLine />
		<p className="pricing">$10 per book + Free Shipping</p>
		<p className="subtext">That&apos;s 2x the pictures as our Standard Photo Books</p>
		<CtaButton
			className="margin-bottom-small"
			trackingName="Hero Info"
		/>
	</section>
);

export default HeroInfo;
