import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLogoPageTile = (props) => (
	<Svg
		{...props}
		name="logo-page-tile"
		viewBox="0 0 56 56"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g fill="inherit">
				<path
					d="M46,0 L10,0 C4.477,0 0,4.477 0,10 L0,46 C0,51.523 4.477,56 10,56 L46,56 C51.523,56 56,51.523 56,46 L56,10 C56,4.477 51.523,0 46,0 L46,0 Z M54,46 C54,50.411 50.411,54 46,54 L10,54 C5.589,54 2,50.411 2,46 L2,10 C2,5.589 5.589,2 10,2 L46,2 C50.411,2 54,5.589 54,10 L54,46 L54,46 Z"
				/>
				<path
					d="M20,12 L9,12 C8,12 7,13 7,14 L7,29 C7,30 8,31 9,31 L21,31 C24,31 27,35 27,35 L27,17 C27,14 24.069,12 20,12 L20,12 Z"
				/>
				<path
					d="M36,21 L47,21 C48,21 49,22 49,23 L49,38 C49,39 48,40 47,40 L35,40 C32,40 29,44 29,44 L29,26 C29,23 31.932,21 36,21 L36,21 Z"
				/>
			</g>
		</g>
	</Svg>
);

IconLogoPageTile.displayName = 'IconLogoPageTile';

IconLogoPageTile.propTypes = iconProps;

IconLogoPageTile.defaultProps = defaultIconProps;

export default IconLogoPageTile;
