import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CarouselItem = ({
	children,
	gutter,
	className: classNameProp
}) => (
	<div className={classnames(
		'CarouselItem',
		{
			gutter,
		},
		classNameProp
	)}>{children}</div>
);

CarouselItem.displayName = 'CarouselItem';

CarouselItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	gutter: PropTypes.bool,
};

CarouselItem.defaultProps = {
	gutter: true,
};

export default CarouselItem;
