import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { productsFromJsonArray, productsSchema } from './product.model';
import { ENTITIES } from '../const/entities/entities';

export const groupSubscriptionsResponseFromJson = (groupId, json = {}) => ({
	groupId: String(groupId),
	products: productsFromJsonArray(deep(json, 'products')),
	subscriptionOptionsText: deep(json, 'subscriptionOptionsText'),
	bottomText: deep(json, 'bottomText'),
});

const groupSubscriptionsResponseSchema = new schema.Entity(ENTITIES.GROUP_SUBSCRIPTIONS_RESPONSE, {
	products: productsSchema,
}, {
	idAttribute: 'groupId',
});

export const normalizeGroupSubscriptionsResponse = (groupSubscriptionsResponse) => normalize(groupSubscriptionsResponse, groupSubscriptionsResponseSchema);
