import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Wrapper.css';

const Wrapper = ({
	children,
	className: propClassName,
}) => (
	<div
		className={classnames('duplo-block', 'duplo-block-wrapper', propClassName)}
	>{children}</div>
);

Wrapper.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Wrapper;
