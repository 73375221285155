import * as ACTIONS from './actionTypes';
import { postLocalFileMediaCreateBatch, postLocalFileMediaUpload } from './api/api.localFileMedia.actions';
import * as apiUploaderAction from './api/api.uploader.actions';
import { receiveEntities } from './entity.actions';
import { isValidFile } from '../components/AngularApp/web-app/src/common/services/uploader/uploader.helpers';
import { localMediaFilesFromJson, normalizeLocalMediaFiles } from '../model/localMediaFile.model';

export const groupFileUploaded = (fileId) => ({ type: ACTIONS.GROUP_FILE_UPLOADED, fileId });

export const createBatch = (groupID, files) => (dispatch) => {
	return dispatch(postLocalFileMediaCreateBatch(groupID, files))
		.then((uploads) => {
			const localFiles = uploads.map((upload) => upload.LocalFileMedia);
			const normalized = normalizeLocalMediaFiles(localMediaFilesFromJson(localFiles));
			dispatch(receiveEntities(normalized.entities, createBatch.name));

			return uploads;
		});
};

export const uploadImage = (file) => (dispatch) => {
	return dispatch(postLocalFileMediaUpload(file.ID, file));
};

/**
 * Upload a single file, and get the Media Object back
 * @param file File to upload
 * @returns {promise}
 */
export const uploadSingleFile = (file) => (dispatch) => {
	if (!file) {
		const error = new Error('File required');

		return Promise.reject(error);
	}
	if (!isValidFile(file)) {
		const error = new Error(`Invalid file: ${file.name} ("${file.type}" not supported)`);
		error.name = 'UnsupportedFileError';

		return Promise.reject(error);
	}

	return dispatch(apiUploaderAction.uploadMedia(file));
};
