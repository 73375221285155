import * as ACTIONS from './actionTypes';
import * as api from './api/api.actions';
import { pricingFromJson } from '../model/pricing.model';
import { selectIsPricingInitialized } from '../selectors/pricing.selectors';

const loadUpgradePricingError = (err) => ({ type: ACTIONS.LOAD_PRICING_ERROR, data: err });
const loadUpgradePricingInit = () => ({ type: ACTIONS.LOAD_PRICING_INIT });
const loadUpgradePricingSuccess = (pricing) => ({ type: ACTIONS.LOAD_PRICING_SUCCESS, data: { pricing } });

export const loadUpgradePricing = () => (dispatch, getState) => {
	if (selectIsPricingInitialized(getState())) return Promise.resolve();

	dispatch(loadUpgradePricingInit());

	return dispatch(api.getUpgradePrices())
		.then((data) => {
			dispatch(loadUpgradePricingSuccess(pricingFromJson(data.upgradePriceModel)));

			return data.upgradePriceModel;
		})
		.catch((err) => loadUpgradePricingError(err));
};
