import { ENTITIES } from '../const/entities/entities';
import { LANGUAGE_KEYS } from '../const/language.const';
import { REDUCERS } from '../const/reducers.const';
import configHelper from '../helpers/config';
import { COOKIES } from '../helpers/cookies';

const config = configHelper.loadConfig() || {};

const defaultState = {
	[REDUCERS.ADDRESS]: {},
	[REDUCERS.ANALYTICS]: {
		queues: {},
	},
	[REDUCERS.API_SETTINGS]: {},
	[REDUCERS.APP]: {
		contextVisible: false,
		isAngularLoaded: false,
		isAngularLoading: false,
		isScrolled: false,
		isScrolledToBottom: false,
		isScrolledToTop: true,
		throbberVisible: 0,
	},
	[REDUCERS.APP_COOKIE]: {
		[COOKIES.APP.GDPR_DISPLAYED]: false,
		[COOKIES.APP.APP_VISITED]: false,
		[COOKIES.APP.MARKETING_VISITED]: false,
		[COOKIES.APP.VISITING_USER_LANGUAGE]: LANGUAGE_KEYS.en_US
	},
	[REDUCERS.APP_STRING]: {
		isFeatureAppStringInitialized: false
	},
	[REDUCERS.USER]: {
		authenticating: false,
		error: null,
		invalid: false,
		isRegisteredUser: false,
		passwordReset: false
	},
	[REDUCERS.BOOK]: {
		[ENTITIES.BOOK]: {}
	},
	[REDUCERS.BLOG]: {
		posts: [],
		categories: []
	},
	[REDUCERS.BLOG_POST]: null,
	[REDUCERS.BRANCH]: {},
	[REDUCERS.CARDS]: {},
	[REDUCERS.CARD_TEMPLATE]: {},
	[REDUCERS.CHECKOUT]: {},
	[REDUCERS.COMMON_HEADER]: {
		expanded: false,
		openDropdowns: {},
		shadowVisible: true,
	},
	[REDUCERS.CONFIG]: config,
	[REDUCERS.FORM]: {},
	[REDUCERS.GROUPS]: {},
	[REDUCERS.HAPPYKIT]: {},
	[REDUCERS.HOME_PAGE]: null,
	[REDUCERS.LANDING_PAGE]: null,
	[REDUCERS.LOADING]: {},
	[REDUCERS.LOCATION]: {},
	[REDUCERS.MOMENT]: {
		[ENTITIES.MOMENT]: {},
	},
	[REDUCERS.PAYMENT_METHOD]: {},
	[REDUCERS.PRICING]: {},
	[REDUCERS.PDP]: null,
	[REDUCERS.VIEW]: {}
};

export default defaultState;
