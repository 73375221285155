import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Tile from '../Tile/Tile';
import PageFlowContainer from '../../PageFlowContainer/PageFlowContainer';
import normalizeMeasurement from '../../../helpers/normalizeMeasurement';

import './TileContainer.css';

export const TileContainer = ({ children: childrenProp, isInset, paddingBottom, paddingTop }) => {
	const children = React.Children.toArray(childrenProp);
	let primary = children.filter((child) => child.props.isPrimary).shift();
	let secondary = children.filter((child) => !child.props.isPrimary).slice(0, 2);
	if (!primary) {
		primary = React.cloneElement(children.slice(0, 1).shift(), { isPrimary: true });
		secondary = children.slice(1, 3);
	}
	const style = {
		paddingBottom: normalizeMeasurement(paddingBottom),
		paddingTop: normalizeMeasurement(paddingTop)
	};
	const className = classnames('TileContainer');

	return (
		<PageFlowContainer isInset={isInset}>
			<div className={className} style={style}>
				<div className="grid-container fluid">
					<div className="grid-x grid-margin-x">
						<div className="cell medium-7 small-12 primary-tile">{primary}</div>
						<div className="cell medium-5 small-12">
							<div className="flex-container flex-dir-column secondary-tiles">{secondary}</div>
						</div>
					</div>
				</div>
			</div>
		</PageFlowContainer>
	);
};

TileContainer.displayName = 'TileContainer';

TileContainer.propTypes = {
	children: (props, propsName, componentName) => {
		const prop = props[propsName];
		if (React.Children.count(prop) > 3) {
			return new Error(`'${componentName}' cannot have more than 3 children`);
		}
		let error = null;
		React.Children.forEach(prop, (child) => {
			if (child.type !== Tile) {
				error = new Error(`'${componentName}' children should be of type 'Tile'. Found child of type '${child.type}'.`);
			}
		});

		return error;
	},
	isInset: PropTypes.bool,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string
};

TileContainer.defaultProps = {
	isInset: false
};

export default TileContainer;
