import * as ACTIONS from './actionTypes';

export const LOADING_KEYS = {
	CHECKOUT_SHOPPING_CART: 'CHECKOUT_SHOPPING_CART',
	DUPLOS: 'DUPLOS',
	LANDING_PAGE_DATA_: 'LANDING_PAGE_DATA_',
	LOAD_API_SETTINGS: 'LOAD_API_SETTINGS',
	LOAD_COMMITTED_CART: 'LOAD_COMMITTED_CART',
	LOAD_GROUP_SERIES_PRICING: 'LOAD_GROUP_SERIES_PRICING',
	LOAD_ORDER_DESCRIPTORS: 'LOAD_ORDER_DESCRIPTORS',
	LOAD_ORDER_OPTIONS: 'LOAD_ORDER_OPTIONS',
	LOAD_PAYMENT_METHODS: 'LOAD_PAYMENT_METHODS',
	LOAD_SHIPPING_ADDRESSES: 'LOAD_SHIPPING_ADDRESSES',
	LOAD_SHIPPING_METHODS: 'LOAD_SHIPPING_METHODS',
	LOAD_SHOPPING_CART: 'LOAD_SHOPPING_CART',
	RECONNECT_DATA_SOURCE: 'RECONNECT_DATA_SOURCE',
	REDEEM_GIFT_CARD: 'REDEEM_GIFT_CARD',
	REMOVE_SHIPPING_ADDRESS: 'REMOVE_SHIPPING_ADDRESS',
	SAVE_ADDRESS: 'SAVE_ADDRESS',
	SAVE_BRAINTREE_PAYMENT_METHOD: 'SAVE_BRAINTREE_PAYMENT_METHOD',
	SAVE_PAYPAL_PAYMENT_METHOD: 'SAVE_PAYPAL_PAYMENT_METHOD',
	SAVE_SHIPPING_METHOD: 'SAVE_SHIPPING_METHOD',
	SUBMIT_NEW_USER_ADDRESS_FORM: 'SUBMIT_NEW_USER_ADDRESS_FORM',
};

export const landingPageLoadingKey = (landingPage) => `${LOADING_KEYS.LANDING_PAGE_DATA_}${landingPage}`;

export const loadingClearErrors = () => ({ type: ACTIONS.LOADING_CLEAR_ERRORS });
export const loadingError = (key, err) => ({ type: ACTIONS.LOADING_ERROR, err, key });
export const loadingInit = (key) => ({ type: ACTIONS.LOADING, key });
export const loadingSuccess = (key) => ({ type: ACTIONS.LOADING_SUCCESS, key });