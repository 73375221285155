import {
	IconCamera,
	IconClock,
	IconPencil2,
	IconPrinter,
	IconRibbon,
	IconSandwich,
	IconShipmentTruck2,
	IconThumbsUpKids,
} from '../../../../chatponents';

export default [
	{
		icon: IconShipmentTruck2,
	},
	{
		icon: IconPrinter,
	},
	{
		icon: IconClock,
	},
	{
		icon: IconPencil2,
	},
	{
		icon: IconCamera,
	},
	{
		icon: IconThumbsUpKids,
	},
	{
		icon: IconRibbon,
	},
	{
		icon: IconSandwich,
	},
];
