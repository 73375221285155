import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { CarouselContext } from './CarouselContext';
import normalizeMeasurement from '../../helpers/normalizeMeasurement';
import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import { SCROLL_DIRECTION } from '../../const';

import './CarouselContainer.css';

class CarouselContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			context: {
				autoScroll: true,
				pageCount: 0,
				pageNumber: 0,
				pageSize: 0,
				prevPageNumber: 0,
				scrollDirection: SCROLL_DIRECTION.RIGHT,
				scrollIncrement: 0
			},
			goToPage: this.goToPage.bind(this),
			startTimer: this.startTimer.bind(this),
			updateContext: this.updateContext.bind(this)
		};
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	goToPage(page, forceChange = false, direction) {
		clearTimeout(this.timer);

		const { context } = this.state;
		const { pageCount, pageNumber } = context;
		if (page >= pageCount) {
			console.warn(`Cannot go to page ${page}. There are only ${pageCount - 1} pages.`);

			return;
		}

		if (page === pageNumber) {
			return;
		}

		this.updateContext((prevContext) => {
			const { pageNumber: prevPageNumber } = prevContext;
			const scrollDirection =
				direction ||
				(prevPageNumber < page || (page === 0 && !forceChange) ? SCROLL_DIRECTION.RIGHT : SCROLL_DIRECTION.LEFT);

			return {
				autoScroll: !forceChange,
				pageNumber: page,
				prevPageNumber: prevContext.pageNumber,
				scrollDirection
			};
		});
	}

	startTimer(callback, interval) {
		this.timer = setTimeout(callback, interval);
	}

	updateContext(context, callback) {
		return this.setState((prevState) => {
			const newContext = typeof context === 'function' ? context(prevState.context) : context;

			return {
				context: {
					...prevState.context,
					...newContext
				}
			};
		}, callback);
	}

	render() {
		const { children, className: classNameProp, paddingBottom, paddingTop } = this.props;
		const className = classnames('CarouselContainer max-width-container', classNameProp);
		const style = {
			paddingBottom: normalizeMeasurement(paddingBottom),
			paddingTop: normalizeMeasurement(paddingTop)
		};

		return (
			<PageFlowContainer isInset={this.props.isInset}>
				<CarouselContext.Provider value={this.state}>
					<div className={className} style={style}>
						{children}
					</div>
				</CarouselContext.Provider>
			</PageFlowContainer>
		);
	}
}

CarouselContainer.displayName = 'CarouselContainer';

CarouselContainer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	isInset: PropTypes.bool,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string
};

CarouselContainer.defaultProps = {
	isInset: false
};

export default CarouselContainer;
