import { apiPostRecieveData } from './api.http.actions';
import { MEDIA_UPLOAD_TYPE } from '../../const/mediaUploadType.const';
import requireParams from '../../helpers/requireParams';
import { queryStringFromJson } from '../../helpers/locationUtils';

export const postLocalFileMediaCreateBatch = (groupID, files) => (dispatch) => {
	requireParams({ groupID, files }, postLocalFileMediaCreateBatch.name);

	return dispatch(apiPostRecieveData(
		'/localFileMedia/createBatch',
		{
			groupID,
			checkForDuplicates: false,
			mediaUploadType: MEDIA_UPLOAD_TYPE.MANUAL,
			inputs: files
				.sort((a, b) => a.lastModified - b.lastModified)
				.map((file) => {
					const obj = {
						fileName: file.newName,
						localTime: new Date(),
						datePhotoTaken:
							file.metadata && file.metadata.datePhotoTaken ? file.metadata.datePhotoTaken : file.lastModifiedDate,
						path: file.newName
					};

					if (file.metadata) {
						if (file.metadata.width && file.metadata.height) {
							obj.width = file.metadata.width;
							obj.height = file.metadata.height;
						}

						if (file.metadata.latitude && file.metadata.longitude) {
							obj.latitude = file.metadata.latitude;
							obj.longitude = file.metadata.longitude;
						}
					}

					return obj;
				}),
		}
	)).then((data) => data.results);
};


export const postLocalFileMediaUpload = (mediaId, image) => (dispatch) => {
	requireParams({ mediaId, image }, postLocalFileMediaUpload.name);

	const headers = {
		'Content-Type': 'application/octet-stream'
	};

	const qs = queryStringFromJson({ id: mediaId });

	return dispatch(apiPostRecieveData(
		`/localFileMedia/upload?${qs}`,
		image,
		{ headers }
	));
};