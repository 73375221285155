import React from 'react';
import PropTypes from 'prop-types';

import InstagramStepsIcons from './InstagramStepsIcons.data';
import './InstagramSteps.css';

const InstagramSteps = ({
	className,
	renderStep1,
	renderStep2,
	renderStep3,
}) => (
	<ul className={`instagram-quickflow-page-instagram-steps ${className}`}>
		{InstagramStepsIcons.map((item, index) => {
			const isLast = index === InstagramStepsIcons.length - 1;

			return (
				<li
					key={index}
					className="instagram-quickflow-page-instagram-step"
				>
					<img
						alt="Instagram Step"
						className="instagram-steps-image"
						src={item}
					/>
					{!isLast && (
						<div className="instagram-steps-line" />
					)}
					<div className="instagram-steps-content">
						{index === 0 && renderStep1()}
						{index === 1 && renderStep2()}
						{index === 2 && renderStep3()}
					</div>
				</li>
			)
		})}
	</ul>
);

InstagramSteps.propTypes = {
	className: PropTypes.string,
	renderStep1: PropTypes.func,
	renderStep2: PropTypes.func,
	renderStep3: PropTypes.func,
};

export default InstagramSteps;
