import deep from 'deep-get-set';

import * as analyticsActions from './analytics.actions';
import * as appActions from './app.actions';
import { callbacksPost } from './api/api.actions';
import * as userActions from './user.actions';
import { createOrRecalculateCart } from './checkout/checkout.actions';
import { loadDuplosDialog } from './duplos.actions';
import * as headerActions from './header.actions';
import * as instagramQuickflowActions from './instagramQuickFlow.actions';
import * as instagramQuickReauthActions from './instagramQuickReauth.actions';
import { openHelp } from './intercom.actions';
import * as viewActions from './view.actions';
import { CHATBOOKS_ACTIONS } from '../const/actionUri.const';
import {
	isActionUri,
	queryStringToJson,
	startsWithHttps,
	actionUriToParts,
	serverCallbackValuesFromQueryParams
} from '../helpers/locationUtils';
import { dialogModalModel } from '../model/dialogModal.model';
import {
	selectDuploValueByKeyFunc,
	selectSherpa,
} from '../selectors';

const handleAcceptInviteLinkAction = (location) => (dispatch) => {
	const queryParams = queryStringToJson(location.search);

	dispatch(analyticsActions.tagReferralLandingPageAction(queryParams.link, queryParams.friend));

	const redirectLink = queryParams.link;
	if (redirectLink) window.location.href = redirectLink;

	return Promise.resolve();
};

const handleConfetti = () => () => {
	// Not implemented.
	return Promise.resolve();
};

const handleCreateAction = () => (_, getState) => {
	const sherpa = selectSherpa(getState());
	window.location.href = sherpa.landing.products();

	return Promise.resolve();
};

const handleReloadCart = () => (dispatch) => dispatch(createOrRecalculateCart());

export const handleLiveChatLinkAction = () => (dispatch) => {
	dispatch(headerActions.requestCollapse());
	dispatch(openHelp());

	return Promise.resolve();
};

const handleInstagramMerge = (params, history) => (dispatch) => {
	dispatch(appActions.showThrobber(handleInstagramMerge.name));
	const { groupid, token } = params;

	return dispatch(userActions.accountMerge(token))
		.then(() => history.push(`/app/groups/${groupid}/books`))
		.finally(() => dispatch(appActions.hideThrobber(handleInstagramMerge.name)));
};

const handleServerCallbackAction = (queryParams, history, location) => (dispatch, getState) => {
	if (!queryParams) return;

	const callbackValues = serverCallbackValuesFromQueryParams(queryParams);

	return dispatch(callbacksPost(callbackValues.name, callbackValues.params))
		.then((response) => {
			if (!response) return Promise.resolve();

			const data = response.data;

			const maybeDisplayMessage = !data.message
				? Promise.resolve()
				: dispatch(viewActions.openToastModal({ message: data.message }));

			return maybeDisplayMessage.then(() =>
				!data.actions ? Promise.resolve() : dispatch(handleActionUris(data.actions, history, location))
			);
		})
		.catch((err) => {
			if (deep(err, 'response.status') === 401) {
				// Don't change this to use `dispatch(openLoginPage())` yet, we need to handle actionUris appropriately across sites first.
				return dispatch(userActions.maybeOpenLogInModal()).then(() =>
					dispatch(handleServerCallbackAction(queryParams))
				);
			}
		});
};

const handleDuplosDialogueAction = (queryParams, history, location) => (dispatch, getState) => {
	return dispatch(loadDuplosDialog(queryParams.key)).then(() => {
		return new Promise((resolve, reject) => {
			const duplosData = selectDuploValueByKeyFunc(getState())(queryParams.key);

			if (!duplosData) {
				console.error(`Duplos data for {${queryParams.key}} failed to load.`);

				return reject();
			}

			dispatch(
				viewActions.openDialogModal(
					dialogModalModel({
						altImageUrl: duplosData.altImageUrl,
						analyticsEvent: duplosData.analyticsEvent,
						body: duplosData.body,
						imageUrl: duplosData.imageUrl,
						isDismissible: duplosData.isDismissible,
						primaryCta: duplosData.primaryCta,
						primaryCtaCallback: () => {
							dispatch(handleActionUri(duplosData.primaryCtaUri, history, location))
								.then(resolve)
								.catch(reject);
						},
						secondaryCta: duplosData.secondaryCta,
						secondaryCtaCallback: () => {
							dispatch(handleActionUri(duplosData.secondaryCtaUri, history, location))
								.then(reject) // no matter the response of the secondary callback, reject the promise
								.catch(reject);
						},
						title: duplosData.title
					})
				)
			);
		});
	});
};

const handleDuplosExplainerAction = (queryParams, history) => () => {
	const { product } = queryParams;

	switch (product) {
		case 'book':
			history.push('/app/create?page=web_custom_explainer');
			break;
		case 'layflat':
			history.push('/app/create?page=web_layflat_explainer');
			break;
		case 'gallerywalltile':
			history.push('/gallery-wall-tiles');
			break;
		case 'instagramseries':
			history.push('/app/create?page=web_series_explainer');
			break;
		case 'minis':
			history.push('/monthly-minis');
			break;
		case 'wallprint':
			history.push('/app/groups');
			break;
		case 'series':
			history.push('/app/create?page=web_series_explainer');
			break;
		case 'cards':
			history.push('/photo-cards');
			break;
		case 'prints':
			history.push(
				'/app/extras?actionUri=chatbooks:%2F%2Fcreateexplainer%3Fproduct%3D6&_branch_match_id=693547291324450709&modal=product_explainer_custom_prints'
			);
			break;
		default:
		// Stay put
	}

	const giveSomeTimeForPageToLoadPromise = new Promise((resolve) => setTimeout(() => resolve(), 1000));

	return giveSomeTimeForPageToLoadPromise;
};

const handleInstagramAuthQuickflow = (queryParams, history) => (dispatch) => {
	const shouldGoToReadyPage = deep(queryParams, 'shouldGoToReadyPage') || false;
	const token = deep(queryParams, 'token');

	return dispatch(instagramQuickflowActions.goToInstagramSeriesPreview(token, history, shouldGoToReadyPage));
};

const handleGoToOrderHistoryAction = () => (_, getState) => {
	const sherpa = selectSherpa(getState());
	window.location.href = sherpa.account.orders();

	return Promise.resolve();
};

const handleGoToProfileAction = () => (_, getState) => {
	const sherpa = selectSherpa(getState());
	window.location.href = sherpa.account.profile();

	return Promise.resolve();
};

const handleSignInLinkAction = () => (dispatch) => {
	return dispatch(userActions.openLoginPage(typeof window !== 'undefined' ? window.location.href : null));
};

const handleSignOutLinkAction = (history) => (dispatch, getState) => {
	const sherpa = selectSherpa(getState());

	return dispatch(userActions.logout())
		.then(() => Promise.resolve(history.push(sherpa.webInfinite.loggingOut())))
		.then(() => {
			const enoughTimeForReduxPersistToWriteAccessTokenCookie = 500;
			setTimeout(() => {
				dispatch(userActions.openLoginPage());
			}, enoughTimeForReduxPersistToWriteAccessTokenCookie);
		})
		.then(() => dispatch(userActions.logInTempUser()))
		.then(() => dispatch(userActions.openLoginPage()))
		.then(() => dispatch(headerActions.requestCollapse()));
};

export const handleActionUri = (actionUri, history, location) => (dispatch) => {
	if (actionUri === '' || typeof actionUri !== 'string') return Promise.reject();

	const action = actionUriToParts(actionUri);

	switch (action.name) {
		case CHATBOOKS_ACTIONS.ACCEPT_INVITE:
			return dispatch(handleAcceptInviteLinkAction(location));
		case CHATBOOKS_ACTIONS.CALLBACK:
			return dispatch(handleServerCallbackAction(action.queryParams, history, location));
		case CHATBOOKS_ACTIONS.CONFETTI:
			return dispatch(handleConfetti());
		case CHATBOOKS_ACTIONS.CREATE:
			return dispatch(handleCreateAction(history));
		case CHATBOOKS_ACTIONS.DUPLO_DIALOG:
			return dispatch(handleDuplosDialogueAction(action.queryParams, history, location));
		case CHATBOOKS_ACTIONS.DUPLOS_EXPLAINER:
			return dispatch(handleDuplosExplainerAction(action.queryParams, history));
		case CHATBOOKS_ACTIONS.HELP:
			return dispatch(handleLiveChatLinkAction());
		case CHATBOOKS_ACTIONS.IG_MERGE:
			return dispatch(handleInstagramMerge(action.queryParams, history));
		case CHATBOOKS_ACTIONS.IG_AUTH_QUICKFLOW:
			return dispatch(handleInstagramAuthQuickflow(action.queryParams, history));
		case CHATBOOKS_ACTIONS.IG_REAUTH:
			return dispatch(instagramQuickReauthActions.goToInstagramReauthWithToken(action.queryParams.token, history));
		case CHATBOOKS_ACTIONS.LIVE_CHAT:
			return dispatch(handleLiveChatLinkAction());
		case CHATBOOKS_ACTIONS.ORDERS:
			return dispatch(handleGoToOrderHistoryAction());
		case CHATBOOKS_ACTIONS.PROFILE:
			return dispatch(handleGoToProfileAction());
		case CHATBOOKS_ACTIONS.RELOAD_CART:
			return dispatch(handleReloadCart());
		case CHATBOOKS_ACTIONS.SIGN_IN:
			return dispatch(handleSignInLinkAction());
		case CHATBOOKS_ACTIONS.SIGN_OUT:
			return dispatch(handleSignOutLinkAction(history));
		default:
			console.warn(`Action {${action.name}} not yet implemented`, action);

			return Promise.resolve();
	}
};

export const handleActionUris = (actionUris, history, location) => (dispatch) => {
	if (!Array.isArray(actionUris) && !actionUris.length) return Promise.resolve();

	const [actionUri, ...rest] = actionUris;

	const actionPromise = dispatch(handleActionUri(actionUri, history, location));

	return actionPromise.then
		? actionPromise.then(() => dispatch(handleActionUris(rest, history, location)))
		: dispatch(handleActionUris(rest, history, location));
};

export const handleLinkCallback = (path, history, location) => (dispatch) => {
	if (isActionUri(path)) {
		dispatch(handleActionUri(path, history, location)).catch((err) => console.error(err));
	} else if (startsWithHttps(path)) {
		window.location = path;
	} else {
		path && history.push(path);
	}
};
