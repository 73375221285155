import { useCookies } from 'react-cookie';

import { defaultCookieOptions } from './defaultCookieOptions';
import { triggerCookieUpdatedEvent } from './triggerCookieUpdatedEvent';

export function useCookiesApi() {
  const [cookies, setCookie, removeCookie] = useCookies();

  function get(name) {
    return cookies[name];
  }

  function set(name, value, options) {
    setCookie(name, value, {
      ...defaultCookieOptions(),
      ...options,
    });
    triggerCookieUpdatedEvent(name, value);
  }


  function remove(name, options) {
    removeCookie(name, options);
    triggerCookieUpdatedEvent(name);
  }

  return {
    get,
    set,
    remove,
  };
}
