import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = ({
	onClose,
	isVisible,
}) => {
	if (!isVisible) return null;

	return (
		<button
			className="close-button"
			aria-label="Close modal"
			type="button"
			onClick={onClose}
		>
			<span
				aria-hidden="true"
			>&times;</span>
		</button>
	);
};

CloseButton.displayName = 'CloseButton';

CloseButton.defaultProps = {
	isVisible: false,
};

CloseButton.propTypes = {
	isVisible: PropTypes.bool,
	onClose: PropTypes.func,
};

export default CloseButton;
