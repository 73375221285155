import React from 'react';
import PropTypes from 'prop-types';

export const H2 = ({
	children,
	className,
}) => {
	if (!children) return null;

	return (
		<h2
			className={className}
		>
			{children}
		</h2>
	);
};

H2.displayName = 'H2';

H2.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default H2;
