import deep from 'deep-get-set';

import { isNullOrUndefined } from '../helpers/bool/bool';

export const promoFromJson = (json = {}) => {
	const promoId = deep(json, 'PromoId');

	return {
		label: deep(json, 'Label'),
		promoId: !isNullOrUndefined(promoId) && String(promoId),
	};
};

export const promosFromJsonArray = (jsonArray) => (jsonArray || []).map(promoFromJson);


export const getPromoLabel = (promo) => deep(promo, 'label');
