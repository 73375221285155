import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { priceFromJson } from './price.model';
import { ENTITIES } from '../const/entities/entities';

export const committedCartFromJson = (json = {}) => {
	return {
		id: String(deep(json, 'Id')),
		masterOrderId: String(deep(json, 'MasterOrderId')),
		price: priceFromJson(deep(json, 'Price')),
	};
};

export const normalizeCommittedCart = (cart) => {
	return normalize(cart, committedCartSchema)
};

const committedCartSchema = new schema.Entity(ENTITIES.COMMITTED_CART);
