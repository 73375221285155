import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconStar = (props) => (
	<Svg
		{...props}
		name="star"
		viewBox="0 0 28 28"
	>
		<path
			fill="inherit"
			d="M14,5.981l1.994,4.888l0.468,1.149l1.238,0.09l5.264,0.385L18.932,15.9l-0.947,0.8l0.296,1.205l1.26,5.125
			l-4.485-2.782L14,19.594l-1.054,0.654L8.461,23.03l1.26-5.125l0.296-1.205l-0.948-0.801l-4.032-3.405l5.264-0.385l1.237-0.09
			l0.469-1.149L14,5.981 M14,0.686l-3.846,9.429L0,10.857l7.778,6.57l-2.43,9.887L14,21.947l8.652,5.367l-2.43-9.887L28,10.857
			l-10.154-0.743L14,0.686L14,0.686z"
		/>
		<path
			fill="inherit"
			d="M44.654,5.981l1.994,4.888l0.467,1.149l1.238,0.09l5.264,0.385L49.586,15.9l-0.947,0.8l0.295,1.205
			l1.26,5.125l-4.484-2.782l-1.055-0.654L43.6,20.248l-4.484,2.782l1.26-5.125L40.67,16.7l-0.947-0.801l-4.031-3.405l5.264-0.385
			l1.236-0.09l0.469-1.149L44.654,5.981 M44.654,0.686l-3.846,9.429l-10.154,0.743l7.777,6.57l-2.43,9.887l8.652-5.367l8.652,5.367
			l-2.43-9.887l7.777-6.57L48.5,10.114L44.654,0.686L44.654,0.686z"
		/>
	</Svg>
);

IconStar.displayName = 'IconStar';

IconStar.propTypes = iconProps;

IconStar.defaultProps = defaultIconProps;

export default IconStar;
