import React from 'react';
import PropTypes from 'prop-types';

import { getYouTubeEmbedId, getYouTubeEmbedUrl } from './EmbeddedVideo.helpers';

const YouTubePlayer = ({ allowFullScreen, url }) => {
	const src = getYouTubeEmbedUrl(url);
	const id = getYouTubeEmbedId(url);

	return (
		<div className="responsive-embed widescreen">
			<iframe
				title={`embeddedVideo-${id}`}
				src={src}
				frameBorder="0"
				allow="autoplay; encrypted-media"
				allowFullScreen={allowFullScreen}
			/>
		</div>
	);
};

YouTubePlayer.propTypes = {
	allowFullScreen: PropTypes.bool,
	url: PropTypes.string
};

YouTubePlayer.defaultProps = {
	allowFullScreen: true
};

export default YouTubePlayer;
