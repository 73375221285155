import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import YouTubePlayer from './YouTubePlayer';

const EmbeddedVideo = ({ allowFullScreen, isInset, url }) => {
	const className = classnames('EmbeddedVideo');

	return (
		<PageFlowContainer className={className} isInset={isInset}>
			<YouTubePlayer allowFullScreen={allowFullScreen} url={url} />
		</PageFlowContainer>
	);
};

EmbeddedVideo.displayName = 'EmbeddedVideo';

EmbeddedVideo.propTypes = {
	allowFullScreen: PropTypes.bool,
	isInset: PropTypes.bool,
	url: PropTypes.string
};

EmbeddedVideo.defaultProps = {
	allowFullScreen: true,
	isInset: false
};

export default EmbeddedVideo;
