import React from 'react';
import PropTypes from 'prop-types';

import addLineBreaks from '../../helpers/addLineBreaks';
import Column from './Column/Column';
import ExpandableColumn from './ExpandableColumn/ExpandableColumn';
import MultiColumnFooter from './MultiColumnFooter/MultiColumnFooter';
import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';

import './MultiColumnContainer.css';

const getFooter = (children) => children.filter((child) => child.type === MultiColumnFooter).shift();

const getColumns = (children) => {
	const columnChildren = children.filter((child) => child.type === Column || child.type === ExpandableColumn);

	return columnChildren.map((child, index) => {
		const {
			key,
			props: { ratio }
		} = child;
		const cellSize = Math.floor(12 / columnChildren.length);
		const columnClasses = ratio ? `ratioed-column column-ratio-${ratio}` : `cell small-12 medium-${cellSize}`;

		return (
			<div key={`${key || `column-${index}`}-${index}`} className={columnClasses}>
				{child}
			</div>
		);
	});
};

const MultiColumnContainer = ({
	backgroundColor,
	color,
	children: childrenProp,
	isInset,
	paddingTop,
	paddingBottom,
	subHeadCallout,
	shortHeader,
	title
}) => {
	const children = React.Children.toArray(childrenProp) || [];
	const columns = getColumns(children);
	const footer = getFooter(children);
	const heading = title ? <h2>{addLineBreaks(title)}</h2> : null;
	const subHead = subHeadCallout ? <h3 className="sub-head-callout">{addLineBreaks(subHeadCallout)}</h3> : null;
	const header =
		heading || subHead ? (
			<header className={shortHeader ? 'short-margin' : null}>
				{heading}
				{subHead}
			</header>
		) : null;
	const style = {
		backgroundColor,
		color
	};

	return (
		<PageFlowContainer
			className="MultiColumnContainer"
			isInset={isInset}
			paddingTop={paddingTop}
			paddingBottom={paddingBottom}
		>
			<div style={style}>
				<div className={`text-center grid-container fluid columns-${columns.length}-container`}>
					{header}
					<div className={`grid-x grid-margin-x columns columns-${columns.length}`}>{columns}</div>
					{footer}
				</div>
			</div>
		</PageFlowContainer>
	);
};

MultiColumnContainer.displayName = 'MultiColumnContainer';

MultiColumnContainer.propTypes = {
	backgroundColor: PropTypes.string,
	children: (props, propsName, componentName) => {
		const prop = props[propsName];
		let error = null;
		React.Children.forEach(prop, (child) => {
			if (child.type !== Column && child.type !== ExpandableColumn && child.type !== MultiColumnFooter) {
				error = new Error(
					`'${componentName}' children should be of type 'Column', 'ExpandableColumn', or 'MultiColumnFooter`
				);
			}
		});

		return error;
	},
	color: PropTypes.string,
	isInset: PropTypes.bool,
	paddingTop: PropTypes.string,
	paddingBottom: PropTypes.string,
	subHeadCallout: PropTypes.string,
	shortHeader: PropTypes.bool,
	title: PropTypes.string
};

MultiColumnContainer.defaultProps = {
	isInset: false,
	shortHeader: false
};

export default MultiColumnContainer;
