/* This site is converting visitors into subscribers and customers with OptinMonster - https://optinmonster.com */

import config from '../helpers/config';
import { containsAny } from '../helpers/strings/strings';
import { INSTAGRAM_QUICK_FLOW_URL } from '../routes/instagram-quick-flow/InstagramQuickFlow';

const optinMonsterBlacklist = [
	`${INSTAGRAM_QUICK_FLOW_URL}`,
	'resetpassword',
];

if (!process.env.SERVER && config.get('analytics.optinMonster.enabled')) {
	if (!containsAny(window.location.pathname, optinMonsterBlacklist) && !containsAny(window.location.search, optinMonsterBlacklist)) {
		(function() {
			const om = document.createElement('script');
			om.type = 'text/javascript';
			om.async = true;
			om.src = 'https://a.opmnstr.com/app/js/api.min.js';
			om.dataset.account = '27023';
			om.dataset.user = '35810';
			document.getElementsByTagName('head')[0].appendChild(om);
		})();
	}
}
