import { makeSherpaLinkResolver } from './makeSherpaLinkResolver';
import { HAPPYKIT_FLAG_KEYS } from '../const';

export function sherpa({
	infiniteOrigin,
	makeItRainOrigin,
	site,
	flags,
}) {

	const currentSite = { // Site
		name: site,
		origin: typeof window === 'undefined' ? '' : window && window.location && window.location.origin,
	};

	const makeItRain = { // Site
		name: 'makeItRain',
		origin: makeItRainOrigin,
	};

	const webInfinite = { // Site
		name: 'webInfinite',
		origin: infiniteOrigin,
	};

	const products = makeSherpaLinkResolver('/products/all', makeItRain, currentSite);

	return { // Sherpa
		aboutUs: makeSherpaLinkResolver('/about-us', makeItRain, currentSite),
		account: {
			orders: makeSherpaLinkResolver('/account/orders', makeItRain, currentSite),
			profile: makeSherpaLinkResolver('/account/profile', makeItRain, currentSite)
		},
		accessories: makeSherpaLinkResolver('/accessories', makeItRain, currentSite),
		blog: makeSherpaLinkResolver('/blog', makeItRain, currentSite),
		careers: makeSherpaLinkResolver('/careers', makeItRain, currentSite),
		cart: makeSherpaLinkResolver('/cart', makeItRain, currentSite),
		create: {
			book: makeSherpaLinkResolver('/app/create?page=web_custom_explainer', webInfinite, currentSite),
			layflat: makeSherpaLinkResolver('/app/create?page=web_layflat_explainer', webInfinite, currentSite),
			/**
			 * Prints are no longer a supported product. So we'll route the user back to the products page.
			 * This is where it used to go link to. makeSherpaLinkResolver('/app/extras?actionUri=chatbooks:%2F%2Fcreateexplainer%3Fproduct%3D6&modal=product_explainer_custom_prints', webInfinite, currentSite),
			 */
			prints: products,
			series: makeSherpaLinkResolver('/app/create?page=web_series_explainer', webInfinite, currentSite),
			giftCard: makeSherpaLinkResolver('/new/giftcard', makeItRain, currentSite),
		},
		faq: makeSherpaLinkResolver('/faq', makeItRain, currentSite),
		forgotPassword: makeSherpaLinkResolver('/forgot-password', makeItRain, currentSite),
		groupsHome: flags[HAPPYKIT_FLAG_KEYS.NX_APP_HOME_PAGE]
			? makeSherpaLinkResolver('/groups', makeItRain, currentSite)
			: makeSherpaLinkResolver('/app/groups', webInfinite, currentSite),
		landing: {
			aboutUs: makeSherpaLinkResolver('/about-us', makeItRain, currentSite),
			accessories: makeSherpaLinkResolver('/accessories', makeItRain, currentSite),
			affiliates: makeSherpaLinkResolver('/affiliates', makeItRain, currentSite),
			careers: makeSherpaLinkResolver('/careers', makeItRain, currentSite),
			photoBooks: makeSherpaLinkResolver('/photo-books', makeItRain, currentSite),
			cards: makeSherpaLinkResolver('/photo-cards', webInfinite, currentSite),
			faq: makeSherpaLinkResolver('/faq', makeItRain, currentSite),
			galleryWallTiles: makeSherpaLinkResolver('/gallery-wall-tiles', makeItRain, currentSite),
			monthlyMinis: makeSherpaLinkResolver('/monthly-minis', makeItRain, currentSite),
			products,
			press: makeSherpaLinkResolver('/press', makeItRain, currentSite),
			pricing: {
				us: makeSherpaLinkResolver('/pricing/unitedstates', makeItRain, currentSite),
				canada: makeSherpaLinkResolver('/pricing/canada', makeItRain, currentSite),
				australia: makeSherpaLinkResolver('/pricing/australia', makeItRain, currentSite),
				newzealand: makeSherpaLinkResolver('/pricing/newzealand', makeItRain, currentSite),
				intl: makeSherpaLinkResolver('/pricing/international', makeItRain, currentSite),
			},
			privacyPolicy: makeSherpaLinkResolver('/privacy-policy', makeItRain, currentSite),
			podcast: makeSherpaLinkResolver('/podcast', makeItRain, currentSite),
			realMomVideos: makeSherpaLinkResolver('/realmomvideos', makeItRain, currentSite),
			returnPolicy: makeSherpaLinkResolver('/return-policy', makeItRain, currentSite),
			termsOfService: makeSherpaLinkResolver('/terms-of-service', makeItRain, currentSite),
			toddlerGuarantee: makeSherpaLinkResolver('/chatbooks-toddler-guarantee', makeItRain, currentSite),
		},
		login: makeSherpaLinkResolver('/login', makeItRain, currentSite),
		podcast: makeSherpaLinkResolver('/podcast', makeItRain, currentSite),
		press: makeSherpaLinkResolver('/press', makeItRain, currentSite),
		pricing: makeSherpaLinkResolver('/pricing', makeItRain, currentSite),
		products: {
			all: makeSherpaLinkResolver('/products/all', makeItRain, currentSite),
			babyPhotoBooks: makeSherpaLinkResolver('/products/baby-photo-books', makeItRain, currentSite),
			birthdayPhotoBooks: makeSherpaLinkResolver('/products/birthday-photo-books', makeItRain, currentSite),
			cards: makeSherpaLinkResolver('/products/cards', makeItRain, currentSite),
			classicPhotoBooks: makeSherpaLinkResolver('/products/photo-books', makeItRain, currentSite),
			facebookPhotoBooks: makeSherpaLinkResolver('/products/facebook-photo-books', makeItRain, currentSite),
			familyPhotoBooks: makeSherpaLinkResolver('/products/family-photo-books', makeItRain, currentSite),
			giftSub: makeSherpaLinkResolver('/products/gift-a-subscription', makeItRain, currentSite),
			hardcoverMonthbooks: makeSherpaLinkResolver('/products/hardcover-monthbooks', makeItRain, currentSite),
			holidayPhotoBooks: makeSherpaLinkResolver('/products/holiday-photo-books', makeItRain, currentSite),
			instagramPhotoBooks: makeSherpaLinkResolver('/products/instagram-photo-books', makeItRain, currentSite),
			layflat: makeSherpaLinkResolver('/products/layflat', makeItRain, currentSite),
			monthBooks: makeSherpaLinkResolver('/products/monthbooks', makeItRain, currentSite),
			monthlyMinis: makeSherpaLinkResolver('/products/monthly-minis', makeItRain, currentSite),
			offerMatrix: makeSherpaLinkResolver('/products/comparison', makeItRain, currentSite),
			photoBooks: makeSherpaLinkResolver('/products/photo-books', makeItRain, currentSite),
			photoGifts: makeSherpaLinkResolver('/products/photo-gifts', makeItRain, currentSite),
			softcoverMonthbooks: makeSherpaLinkResolver('/products/softcover-monthbooks', makeItRain, currentSite),
			travelPhotoBooks: makeSherpaLinkResolver('/products/travel-photo-books', makeItRain, currentSite),
			wallTiles: makeSherpaLinkResolver('/products/gallery-wall-tiles', makeItRain, currentSite),
			weddingPhotoBooks: makeSherpaLinkResolver('/products/wedding-photo-books', makeItRain, currentSite),
			yearBook: makeSherpaLinkResolver('/products/yearbook', makeItRain, currentSite)
		},
		marketingHome: makeSherpaLinkResolver('/', makeItRain, currentSite),
		privacyPolicy: makeSherpaLinkResolver('/privacy-policy', makeItRain, currentSite),
		realmomvideos: makeSherpaLinkResolver('/realmomvideos', makeItRain, currentSite),
		resetPassword: makeSherpaLinkResolver('/resetpassword', makeItRain, currentSite),
		resetPasswordLegacy: makeSherpaLinkResolver('/resetpassword/', webInfinite, currentSite),
		returnPolicy: makeSherpaLinkResolver('/return-policy', makeItRain, currentSite),
		signup: makeSherpaLinkResolver('/signup', makeItRain, currentSite),
		termsOfService: makeSherpaLinkResolver('/terms-of-service', makeItRain, currentSite),
		webInfinite: {
			loggingOut: makeSherpaLinkResolver('/loggingOut', webInfinite, currentSite),
		},
	};
}