import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ConfigurableLink from '../../components/ConfigurableLink/ConfigurableLink';

export const Button = ({
	action,
	analyticEvent,
	buttonStyle,
	buttonText,
	linkElement,
	linkElementPathAttr,
	onClick,
	path
}) => {
	if (!buttonText) return null;

	const buttonClasses = classnames('button large expanded', buttonStyle);

	return (
		<ConfigurableLink
			className={buttonClasses}
			action={action}
			analyticEvent={analyticEvent}
			linkElement={linkElement}
			linkElementPathAttr={linkElementPathAttr}
			path={path}
			clickHandler={onClick}
		>
			{buttonText}
		</ConfigurableLink>
	);
};

Button.propTypes = {
	action: PropTypes.string,
	analyticEvent: PropTypes.func,
	buttonStyle: PropTypes.string,
	buttonText: PropTypes.string,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	onClick: PropTypes.func,
	path: PropTypes.string
};

export default Button;
