import { omit } from 'lodash';

import { isServer } from '@chtbks/helpers';

import { BOOK_CREATION_MODEL_TYPES } from '../../../const/bookCreationModelTypes.const';
import { clog } from '../../logging';

/**
 * Rudderstack is initialized in web-app/src/vendors/rudderstack.js
 */
const isLoaded = () => {
  return !isServer() && window.rudderanalytics;
};


export const Rudderstack = {
  identify: (user) => {
    if (!isLoaded() || !user) {
      return;
    }

    const userId = user.ID;
    const personId = user.LongId.toString();

    const traits = {
      id: userId,
      email: user.Email,
      firstName: user.FirstName,
      lastName: user.LastName,
      personId
    };

    window.rudderanalytics.identify(userId, traits);
  },
  reset: () => {
    if (!isLoaded()) {
      return;
    }

    clog("Reset");
    window.rudderanalytics.reset();
  },
  trackEvent: (eventName, eventProperties) => {
    if (!isLoaded() || !eventName || !eventProperties) {
      return;
    }

    const _eventProperties = {
      ...eventProperties,
      app_installation_id: eventProperties.appInstallationID,
      chatgroup_id: eventProperties.chatgroupid,
      person_id_string: eventProperties.personidstring,
      person_id: eventProperties.personid,
      shopping_cart_id: eventProperties.shoppingcartid,
    };
    
    delete _eventProperties['appInstallationID'];
    delete _eventProperties['chatgroupid'];
    delete _eventProperties['personid'];
    delete _eventProperties['personidstring'];
    delete _eventProperties['shoppingcartid'];

    if (eventName === 'error:occurred') {
      window.rudderanalytics.track(
        'error:occurred',
        {
          ..._eventProperties,
          action: 'occurred',
        },
      );
    } else if (eventName === 'product-options:selected') {
      window.rudderanalytics.track(
        'product-options:selected',
        {
          ..._eventProperties,
          action: 'selected',
        },
      );
    } else if (eventName === 'product-options:viewed') {
      window.rudderanalytics.track(
        'product-options:viewed',
        {
          ..._eventProperties,
          action: 'viewed',
        },
      );
    } else if (eventName === 'EditBook_EditCover') {
      window.rudderanalytics.track(
        'book:edited-cover',
        {
          ..._eventProperties,
          action: 'edit-cover',
        },
      );
    } else if (eventName === 'EditPage_ChangePageLayout') {
      window.rudderanalytics.track(
        'book:edited-page',
        {
          ...(omit(_eventProperties, ['attr1', 'attr2'])),
          action: 'edited-page',
          book_id: _eventProperties.attr2,
          selected: _eventProperties.attr1,
        },
      );
    } else if (eventName === 'EditPage_EditCaption') {
      window.rudderanalytics.track(
        'book:edited-page',
        {
          ...(omit(_eventProperties, ['attr1', 'attr2'])),
          action: 'edited-page',
          book_id: _eventProperties.attr2,
        },
      );
    } else if (eventName === 'CustomEditBook_Preview' || eventName === 'EditBook_Preview') {
      window.rudderanalytics.track(
        'book:previewed',
        {
          ..._eventProperties,
          action: 'previewed',
        },
      );
    } else if (eventName === 'CustomEditBook_Rearrange' || eventName === 'EditBook_Rearrange') {
      window.rudderanalytics.track(
        'book:rearranged-page',
        {
          ...(omit(_eventProperties, ['metric1'])),
          num_pages: _eventProperties.metric1,
          action: 'rearranged-page',
        },
      );
    } else if (eventName === 'CustomEditBook_Add' || eventName === 'EditBook_AddPhotos') {
      window.rudderanalytics.track(
        'book:started-adding-page',
        {
          ..._eventProperties,
          action: 'started-adding-page',
        },
      );
    } else if (eventName === 'SeriesInfo_EditBook') {
      window.rudderanalytics.track(
        'book:viewed',
        {
          ...(omit(_eventProperties, ['attr2'])),
          action: 'viewed',
          book_id: _eventProperties.attr2,
          product_category: 'series',
        },
      );
    } else if (eventName === 'CustomEditBook_ViewPage' || eventName === 'EditBook_ViewPage') {
      window.rudderanalytics.track(
        'bookpage:viewed',
        {
          ..._eventProperties,
          action: 'viewed',
        },
      );
    } else if (eventName === 'SeriesInfo_Subscribe') {
      window.rudderanalytics.track(
        'checkout:started',
        {
          ..._eventProperties,
          action: 'started',
          product_category: 'series',
        },
      );
    } else if (eventName === 'CookiesAccepted') {
      window.rudderanalytics.track(
        'cookies:accepted',
        {
          ...(omit(_eventProperties, ['attr1'])),
          action: 'accepted',
          ip_address: _eventProperties.attr1,
        },
      );
    } else if (eventName === 'CookiesDisplayed') {
      window.rudderanalytics.track(
        'cookies:displayed',
        {
          ...(omit(_eventProperties, ['attr1'])),
          action: 'displayed',
          ip_address: _eventProperties.attr1,
        },
      );
    } else if (eventName === 'MarketingSite_FooterSelect') {
      window.rudderanalytics.track(
        'footer:clicked',
        {
          ...(omit(_eventProperties, ['attr1', 'attr2', 'attr3'])),
          action: 'clicked',
          button_action: _eventProperties.attr2,
          destination_url: _eventProperties.attr3,
          url: _eventProperties.attr1,
        },
      );
    } else if (eventName === 'MarketingSite_HeaderSelect') {
      window.rudderanalytics.track(
        'navbar:clicked',
        {
          ...(omit(_eventProperties, ['attr1', 'attr2', 'attr3'])),
          action: 'clicked',
          button_action: _eventProperties.attr2,
          destination_url: _eventProperties.attr3,
          url: _eventProperties.attr1,
        },
      );
    } else if (eventName === 'Referrals_Page') {
      window.rudderanalytics.track(
        'page:viewed',
        {
          ..._eventProperties,
          action: 'viewed',
          page: 'referrals',
        },
      );
    } else if (eventName === 'PaymentMethodOverlay_ChooseMethod') {
      window.rudderanalytics.track(
        'payment-method:selected',
        {
          ...(omit(_eventProperties, ['attr1', 'attr2'])),
          action: 'selected',
          method: _eventProperties.attr1,
          method_id: _eventProperties.attr2,
        },
      );
    } else if (eventName === 'CustomEditBook_AddToCart') {
      window.rudderanalytics.track(
        'product:added-to-cart',
        {
          ..._eventProperties,
          action: 'add-to-cart',
        },
      );
    } else if (eventName === 'CreatedChatgroup') {
      window.rudderanalytics.track(
        'product:created',
        {
          ...(omit(_eventProperties, ['attr1', 'attr2'])),
          action: 'created',
          additional_data: _eventProperties.attr1,
          product_category: productCategoryFromBookCreationModelType(_eventProperties.attr2),
        },
      );
    } else if (eventName === 'ShippingAddressOverlay_AddNewAddress') {
      window.rudderanalytics.track(
        'shipping-address:added',
        {
          ...(omit(_eventProperties, ['attr1'])),
          action: 'added',
          method_id: _eventProperties.attr1,
        },
      );
    } else {
      console.log('### RudderstackService.js - eventName', eventName);
      console.log('### RudderstackService.js - _eventProperties', _eventProperties);
    }
  }
};

// This only implements layflat and custom book.. I think that's all it needs.
export const productCategoryFromBookCreationModelType = (bookCreationModelType) => {
  switch (bookCreationModelType) {
    case BOOK_CREATION_MODEL_TYPES.CUSTOM_BOOK: return ProductCategory.CustomBook;
    case BOOK_CREATION_MODEL_TYPES.LAYFLAT: return ProductCategory.Layflat;
    default: return '';
  }
};

export const ProductCategory = {
  Accessories: 'accessories',
  Cards: 'cards',
  ChatbooksSubscription: 'chatbooks subscription', // For Subs4All
  CustomBook: 'custom book',
  GiftCard: 'gift card',
  GiftedSubscription: 'gifted subscription',
  Layflat: 'layflat',
  Monthbooks: 'monthbooks',
  MonthlyMinis: 'monthly minis',
  Other: 'other',
  Series: 'series',
  WallDecor: 'wall decor',
  None: '',
};