import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconConnected = (props) => {
	const { size } = props;
	const heightRatio = 12;
	const widthRatio = 44;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			height={height}
			width={width}
			viewBox="0 0 44 12"
			fill="none"
			hasStroke={false}
			name="connected"
		>
			<path
				d="M44 6.00056C44.0004 5.42194 43.5362 4.95308 42.9633 4.95345L31.7882 4.95308C31.5797 3.74895 31.0167 2.63934 30.1432 1.75708C29.0214 0.624019 27.5301 0.00037332 25.9429 0.00037332L17.6629 0C16.0765 0 14.5844 0.624393 13.4626 1.75745C12.5894 2.63934 12.0265 3.74895 11.8176 4.95197L1.03672 4.95234C0.464202 4.95234 0 5.42119 0 5.99944C0 6.28894 0.11605 6.55053 0.303573 6.73993C0.491095 6.92933 0.750458 7.04692 1.03672 7.04655L11.818 7.04654C12.0269 8.2503 12.5891 9.35992 13.4626 10.2422C14.5844 11.3752 16.0754 11.9993 17.6617 12L25.9429 11.9993C28.8637 11.9985 31.2938 9.85593 31.7864 7.04692L42.9633 7.04766C43.5365 7.04841 44.0007 6.57955 44 6.00056ZM17.6614 9.90542C16.6302 9.90505 15.6594 9.49834 14.9292 8.76083C14.199 8.02332 13.7964 7.04282 13.7964 5.99944C13.796 4.95643 14.1987 3.97594 14.9285 3.2388C15.6587 2.50129 16.6298 2.09495 17.6625 2.09458L20.7656 2.09495L20.766 9.90431L17.6614 9.90542ZM25.9429 9.90505L22.8398 9.90468L22.8402 2.09458L25.9433 2.09495C26.976 2.09532 27.9475 2.50129 28.6769 3.23805C29.4071 3.97556 29.8094 4.95643 29.8094 5.99981C29.8083 8.15244 28.0746 9.90356 25.9429 9.90505Z"
				fill="inherit"
			/>
		</Svg>
	);
};

IconConnected.displayName = 'IconConnected';

IconConnected.propTypes = iconProps;

IconConnected.defaultProps = defaultIconProps;

export default IconConnected;
