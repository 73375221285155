import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SingleLargeImage = styled.img`
	margin: 0 auto;
`;

const SingleProductImage = ({
	horizontalImageAlt,
	horizontalImageUrl,
}) => {
	if (!horizontalImageUrl) return null;

	return (
		<SingleLargeImage src={horizontalImageUrl} alt={horizontalImageAlt} />
	);
};

SingleProductImage.displayName = 'SingleProductImage';

SingleProductImage.propTypes = {
	horizontalImageAlt: PropTypes.string,
	horizontalImageUrl: PropTypes.string,
};

export default SingleProductImage;