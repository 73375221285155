import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconSandwich = (props) => (
	<Svg
		{...props}
		name="sandwich"
		viewBox="0 0 120 120"
	>
		<path
			d="M98.58,55.56H21.31a.85.85,0,0,1-.83-.67.84.84,0,0,1,.46-1L58.25,36.37a.84.84,0,0,1,.71,0l40,17.57a.85.85,0,0,1-.34,1.63Zm-73.47-1.7H94.53L58.63,38.07Z"
		/>
		<path
			d="M93.57,62.29H26.31a5.86,5.86,0,0,1-5.85-5.86V54.71a.85.85,0,0,1,.85-.85H98.58a.85.85,0,0,1,.85.85v1.72A5.87,5.87,0,0,1,93.57,62.29ZM22.16,55.56v.87a4.16,4.16,0,0,0,4.15,4.16H93.57a4.17,4.17,0,0,0,4.16-4.16v-.87Z"
		/>
		<path
			d="M74.48,69.35H48.91a.85.85,0,0,1,0-1.7H74.48a.85.85,0,1,1,0,1.7Z"
		/>
		<path
			d="M95.19,69.35h-7a.85.85,0,1,1,0-1.7h7c.73,0,1.38-1,1.38-2.15V64.43c0-1.16-.63-2.14-1.38-2.14H25.35c-.75,0-1.38,1-1.38,2.14V65.5c0,1.16.63,2.15,1.38,2.15H41.73a.85.85,0,0,1,0,1.7H25.35c-1.7,0-3.08-1.73-3.08-3.85V64.43c0-2.15,1.35-3.84,3.08-3.84H95.19c1.73,0,3.08,1.69,3.08,3.84V65.5C98.27,67.62,96.89,69.35,95.19,69.35Z"
		/>
		<path
			d="M98.21,74.91H85.72a.85.85,0,1,1,0-1.7H98.21a1.07,1.07,0,0,0,.78-.47,2.5,2.5,0,0,0,.44-1.46c0-1-.57-1.93-1.22-1.93H88.14a.85.85,0,1,1,0-1.7H98.21c1.61,0,2.92,1.63,2.92,3.63a4.14,4.14,0,0,1-.77,2.47A2.7,2.7,0,0,1,98.21,74.91Z"
		/>
		<path
			d="M81.31,74.91H21.79c-1.64,0-2.92-1.6-2.92-3.63a4.18,4.18,0,0,1,.77-2.48,2.68,2.68,0,0,1,2.15-1.15H41.73a.85.85,0,0,1,0,1.7H21.79a1,1,0,0,0-.78.47,2.46,2.46,0,0,0-.44,1.46c0,1,.57,1.93,1.22,1.93H81.31a.85.85,0,1,1,0,1.7Z"
		/>
		<path
			d="M74.48,69.35H48.91a.85.85,0,0,1,0-1.7H74.48a.85.85,0,1,1,0,1.7Z"
		/>
		<path
			d="M91.43,83.71h-63a6.19,6.19,0,0,1-.93-.07c-2.89-.49-5.36-3.29-6.47-7.3l-.56-2.06a.84.84,0,0,1,.82-1.07h60a.85.85,0,1,1,0,1.7H22.42l.27,1c.91,3.32,2.91,5.7,5.09,6.07a3.92,3.92,0,0,0,.67.05h63a4,4,0,0,0,1-.12c2.06-.52,3.88-2.81,4.76-6l.27-1H85.72a.85.85,0,1,1,0-1.7H98.57a.85.85,0,0,1,.68.33.89.89,0,0,1,.15.74l-.57,2.08c-1,3.82-3.29,6.5-6,7.18A5.52,5.52,0,0,1,91.43,83.71Z"
		/>
		<path
			d="M45.09,72.13a.87.87,0,0,1-.55-.2L32.68,62.09a.84.84,0,0,1-.11-1.19.86.86,0,0,1,1.2-.12L45.12,70.2l13-9.45a.85.85,0,0,1,1,1.38L45.59,72A.85.85,0,0,1,45.09,72.13Z"
		/>
		<path
			d="M84.56,77.56a.8.8,0,0,1-.53-.19L65.29,62.1a.86.86,0,0,1-.13-1.2.87.87,0,0,1,1.2-.12L84.23,75.35,90.44,61.1a.85.85,0,1,1,1.55.68L85.34,77.05a.82.82,0,0,1-.58.49Z"
		/>
	</Svg>
);

IconSandwich.displayName = 'IconSandwich';

IconSandwich.propTypes = iconProps;

IconSandwich.defaultProps = defaultIconProps;

export default IconSandwich;
