import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.HOME_PAGE], action) => {
	const { homePage, type } = action;

	switch (type) {
		case ACTIONS.RECEIVE_HOME_PAGE:
			return {
				...homePage
			};

		default:
			return state;
	}
};
