import { apiPostRecieveData } from './api.http.actions';
import requireParam from '../../helpers/requireParam';
import requireParams from '../../helpers/requireParams';

/**
 * Create a new moment from a social URL
 *
 * @param {number} groupId
 * @param {string} url
 * @param {string?} text
 * @param {string?} personId
 * @param {number?} captionTextMomentId
 * @param {string?} localTime
 * @param {number?} latitude
 * @param {number?} height
 * @param {number?} width
 * @param {number?} longitude
 * @param {Date?} datePhotoTaken
 * @param {number?} posterMediaId
 * @param {boolean?} isPoster
 * @param {string?} filename
 * @param {string?} deviceToken
 * @param {Date?} localDatePhotoTaken
 * @returns {Promise}
 */
export const uploadSocialMoment = (
	groupId,
	url,
	text,
	personId,
	captionTextMomentId,
	localTime,
	latitude,
	height,
	width,
	longitude,
	datePhotoTaken,
	posterMediaId,
	isPoster,
	filename,
	deviceToken,
	localDatePhotoTaken
) => (dispatch) => {
	requireParam(groupId, 'groupId', 'uploadSocialMoment');
	requireParam(url, 'url', 'uploadSocialMoment');

	const data = {
		groupId,
		url,
		text,
		personId,
		captionTextMomentId,
		localTime,
		latitude,
		height,
		width,
		longitude,
		datePhotoTaken,
		posterMediaId,
		isPoster,
		filename,
		deviceToken,
		localDatePhotoTaken
	};
	const apiUrl = '/moments/uploadsocial';

	return dispatch(apiPostRecieveData(apiUrl, data))
		.then((result) => result.moment)
};

/**
 * Upload a media file.
 *
 * @param {object|Blob} imageBlob File blob to upload.
 * @param {object} [params={}]
 *
 * @returns {promise}
 */
export const uploadMedia = (imageBlob, params) => (dispatch) => {
	requireParams({ imageBlob }, uploadMedia.name);

	params = typeof params === 'object' ? params : {};

	return dispatch(apiPostRecieveData('/media/uploadstream', imageBlob, {
		headers: {
			'Content-Type': 'application/octet-stream',
		}
	}));
};
