import React from 'react';
import PropTypes from 'prop-types';
import { useAppString } from '@chtbks/api';

/**
 * DangerouslyTranslate should be used any time we know an AppString value contains HTML we wish to display.
 *
 * It will use dangeriouslySetInnerHTML to render the dynamic content.
 */
const DangerouslyTranslate = ({ id, params }) => {
	const appString = useAppString({ id, params });

	// eslint-disable-next-line react/no-danger
	return <span dangerouslySetInnerHTML={{ __html: appString }} />
}

DangerouslyTranslate.displayName = 'DangerouslyTranslate';

DangerouslyTranslate.propTypes = {
	id: PropTypes.string,
	params: PropTypes.object,
}

export default DangerouslyTranslate;
