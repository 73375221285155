import { Cookies } from 'react-cookie';

import { defaultCookieOptions } from './defaultCookieOptions';
import { triggerCookieUpdatedEvent } from './triggerCookieUpdatedEvent';

export function reactCookies(cookies) {
	const reactCookies = new Cookies(cookies);

	function reactCookiesGet(name, options) {
		return reactCookies.get(name, options);
	}

	function reactCookiesSet(name, value, options) {
		reactCookies.set(name, value, {
			...defaultCookieOptions(),
			options,
		});
		triggerCookieUpdatedEvent(name, value);
	}

	function reactCookiesRemove(name, options) {
		reactCookies.remove(name, options);
		triggerCookieUpdatedEvent(name);
	}

	return {
		remove: reactCookiesRemove,
		get: reactCookiesGet,
		set: reactCookiesSet,
	};
}
