import { apiDelete, apiGetData, apiPost } from './api.http.actions'

export const delete_shippingHolidayArrivals_stub = () => (dispatch) => {
	return dispatch(apiDelete('/shippingHolidayArrivals/stub'));
};

export const get_shippingHolidayArrivals_stub = () => (dispatch) => {
	return dispatch(apiGetData('/shippingHolidayArrivals/stub'));
};

export const post_shippingHolidayArrivals_stub = ({
	emoji,
	expirationSeconds,
	holidayName,
	status,
}) => (dispatch) => {
	return dispatch(apiPost('/shippingHolidayArrivals/stub', {
		status,
		holidayName,
		emoji,
		expirationSeconds,
	}));
};
