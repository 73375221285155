import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	margin: auto;
	display: flex;
	width: 167px;
	height: 167px;
	background: rgba(17, 46, 90, 0.7);
	backdrop-filter: blur(1px);
	border-radius: 21px;
`;

const Svg = styled.svg`
	@keyframes throbber-spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	margin: auto;
	animation: throbber-spin 1s linear infinite;
`;

export const ThrobberBox = () => (
	<Container>
		<Svg width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_b)">
				<circle cx="70.7322" cy="70.7327" r="49.5" transform="rotate(-155.793 70.7322 70.7327)" stroke="white" strokeWidth="8"/>
			</g>
			<path d="M84.6843 117.189C93.5389 114.552 101.48 109.499 107.608 102.599C113.737 95.7004 117.808 87.2314 119.362 78.1505" stroke="#357FE8" strokeWidth="10" strokeLinecap="round"/>
			<defs>
				<filter id="filter0_b" x="-4.00049" y="-4.00003" width="149.465" height="149.465" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix"/>
					<feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
					<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
				</filter>
			</defs>
		</Svg>
	</Container>
);

ThrobberBox.displayName = 'ThrobberBox';

export default ThrobberBox;
