import React from 'react';
import PropTypes from 'prop-types';

export const Title = ({ title }) => {
	if (!title) return null;

	return <h2>{title}</h2>;
};

Title.propTypes = {
	title: PropTypes.string
};

export default Title;
