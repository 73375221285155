import deep from 'deep-get-set';

const productPricingFromJson = (json = {}) => ({
	basePrice: deep(json, 'BasePrice'),
	bonusPrintsUpgradePrice: deep(json, 'BonusPrintsUpgradePrice'),
	eightByEightUpgradePrice: deep(json, 'EightByEightUpgradePrice'),
	hardcover6X6UpgradePrice: deep(json, 'Hardcover6X6UpgradePrice'),
	hardcover8X8UpgradePrice: deep(json, 'Hardcover8X8UpgradePrice'),
	hardcover10X10UpgradePrice: deep(json, 'Hardcover10X10UpgradePrice'),
	maxPages: deep(json, 'MaxPages'),
	pageBatch6X6Cost: deep(json, 'PageBatch6X6Cost'),
	pageBatch8X8Cost: deep(json, 'PageBatch8X8Cost'),
	pageBatch10X10Cost: deep(json, 'PageBatch10X10Cost'),
	pageBatchSize: deep(json, 'PageBatchSize'),
	pagesIncludedInBasePrice: deep(json, 'PagesIncludedInBasePrice'),
	premiumCoverUpgradeStartPrice: deep(json, 'PremiumCoverUpgradeStartPrice'),
	tenByTenUpgradePrice: deep(json, 'TenByTenUpgradePrice'),
});

export const pricingFromJson = (json = {}) => ({
	custom: productPricingFromJson(deep(json, 'Custom')),
	customPrints: productPricingFromJson(deep(json, 'CustomPrints')),
	layflat: productPricingFromJson(deep(json, 'Layflat')),
	series: productPricingFromJson(deep(json, 'Series')),
});
