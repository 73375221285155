import config from '../helpers/config';
import loadScript from '../helpers/loadScript';

if (!process.env.SERVER && config.isProduction()) {
	/* eslint-disable */
	/* <![CDATA[ */

	window.goog_snippet_vars = function(amount) {
		var w = window;
		w.google_conversion_id = 945062865;
		w.google_conversion_label = 'keAiCKmWh2AQ0YfSwgM';
		w.google_conversion_value = amount;
		w.google_conversion_currency = 'USD';
		w.google_remarketing_only = false;
	};

	// DO NOT CHANGE THE CODE BELOW.
	window.goog_report_conversion = function(amount, url) {
		window.goog_snippet_vars(amount);
		window.google_conversion_format = '3';
		window.google_is_call = true;
		var opt = new Object();
		opt.onload_callback = function() {
			if (typeof url != 'undefined') {
				window.location = url;
			}
		};

		var conv_handler = window['google_trackConversion'];

		if (typeof conv_handler == 'function') {
			conv_handler(opt);
		}
	};
	/* ]]> */

	loadScript('//www.googleadservices.com/pagead/conversion_async.js');
	/* eslint-enable */
}
