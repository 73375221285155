import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TaperedLine from './TaperedLine';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import { DEVICE_WIDTH, mediaQueryBetween, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../const';

const BetterInTheAppContainer = styled.div`
	background-color: ${(props) => props.backgroundColor};
`;

const BetterInTheAppContentContainer = styled(PageFlowContainer)`
	padding-left: ${SPACING.MEDIUM};
	padding-right: ${SPACING.MEDIUM};

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		padding-top: ${SPACING.BIGGER};
		padding-bottom: 108px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		padding-top: 100px;
		padding-bottom: 200px;
	}
`;

const BetterInTheAppGrid = styled.div`
	display: grid;
	margin: auto;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		grid-template-columns: 1fr;
		grid-template-areas:
			"text"
			"demo";
		row-gap: ${SPACING.MEDIUM};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		max-width: 1200px;

		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-areas:
			"demo text";
		column-gap: ${SPACING.MEDIUM};
	}
`;

const ContentDiv = styled.div`
	grid-area: text;
	align-self: center;
`;

const AppDemoImg = styled.img`
	grid-area: demo;
	justify-self: center;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		max-width: 225px;
	}
`;

const TitleDiv = styled.div`
	font-family: Circular-Pro-Medium;
	font-size: 40px;
	line-height: 50px;
	color: #333333;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 21px;
		line-height: 28px;
		margin: auto;
		max-width: 221px;
		text-align: center;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 40px;
		line-height: 50px;
	}
`;

const DescriptionDiv = styled.div`
	color: #333333;
	font-family: Inter;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		max-width: 285px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 16px;
		margin-bottom: ${SPACING.BIG};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 23px;
		line-height: 33px;
		margin-bottom: 50px;
		margin-top: ${SPACING.BIGGER};
		max-width: 506px;
	}
`;

const AppLinksDiv = styled.div`
	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		margin-left: auto;
		margin-right: auto;
		max-width: 320px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		margin-left: -${SPACING.SMALL};
		margin-right: -${SPACING.SMALL};
	}
`;

const AppDownloadLink = styled.a`
	margin-top: ${SPACING.MEDIUM};

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		margin-left: ${SPACING.SMALL};
		margin-right: ${SPACING.SMALL};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		margin-left: ${SPACING.SMALL};
		margin-right: ${SPACING.SMALL};
	}
`;

const AppDownloadImg = styled.img`
	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		max-width: 140px;
	}
	${mediaQueryBetween(DEVICE_WIDTH.TABLET, DEVICE_WIDTH.LAPTOP)} {
		max-width: 150px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.LAPTOP)} {
		max-width: 195px;
	}
`;

const StyledTaperedLine = styled(TaperedLine)`
	margin: auto;
	max-width: 1250px;
`;

const BetterInTheApp = ({
	betterInTheAppBackgroundColor,
	betterInTheAppDescription,
	betterInTheAppTitle,
	demoImageAlt,
	demoImageUrl,
}) => {
	return (
		<BetterInTheAppContainer
			backgroundColor={betterInTheAppBackgroundColor}>
			<BetterInTheAppContentContainer>
				<BetterInTheAppGrid>
					<ContentDiv>
						<TitleDiv>{betterInTheAppTitle}</TitleDiv>
						<DescriptionDiv>{betterInTheAppDescription}</DescriptionDiv>
						<AppLinksDiv>
							<AppDownloadLink href="//itunes.apple.com/us/app/chatbooks/id734887606?mt=8" target="_blank">
								<AppDownloadImg src="/assets/img/badge-app-store.png" alt="Download on the App Store" />
							</AppDownloadLink>
							<AppDownloadLink href="//play.google.com/store/apps/details?id=com.chatbooks" target="_blank">
								<AppDownloadImg src="/assets/img/badge-google-play.png" alt="Get it on Google Play" />
							</AppDownloadLink>
						</AppLinksDiv>
					</ContentDiv>
					<AppDemoImg src={demoImageUrl} alt={demoImageAlt} />
				</BetterInTheAppGrid>
				<StyledTaperedLine />
			</BetterInTheAppContentContainer>
		</BetterInTheAppContainer>
	);
};

BetterInTheApp.displayName = 'BetterInTheApp';

BetterInTheApp.propTypes = {
	betterInTheAppBackgroundColor: PropTypes.string,
	betterInTheAppDescription: PropTypes.string,
	betterInTheAppTitle: PropTypes.string,
	demoImageAlt: PropTypes.string,
	demoImageUrl: PropTypes.string,
};

export default BetterInTheApp;
