/*
	Kochava
	Kochava provides holistic, unbiased measurement for precise, real-time visualization of app performance through the funnel.
	www.kochava.com/‎

	Setup in vendors/kochava.js
*/

const isActive = () => window.hasOwnProperty('kochava');

const activity = (eventName, metadataObj) => isActive() && window.kochava.activity(eventName, metadataObj);

const identify = (userObj, callback) => isActive() && window.kochava.identify(userObj, callback);

const page = (pageName, metadataObj, callback) => isActive() && window.kochava.page(pageName, metadataObj, callback);

export default {
	activity,
	identify,
	page
};
