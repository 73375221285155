import { apiGetData, apiPost, apiDelete } from './api.http.actions';

export const delete_singleSignOn__id = (id) => (dispatch) => {
	return dispatch(apiDelete(`/singleSignOn/${id}`));
};

export const get_singleSignOn_getAll = () => (dispatch) => {
	return dispatch(apiGetData('/singleSignOn/getAll'))
		.then(({ connections }) => connections);
};

export const post_singleSignOn_connectNew = (email, singleSignOnToken, singleSignOnType, personId) => (dispatch) => {
	return dispatch(apiPost('/singleSignOn/connectNew', {
		email,
		singleSignOnToken,
		singleSignOnType,
		personId,
	}));
};
