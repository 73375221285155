import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const fixedRatioContainer = (yRatio) => {
	return `
		height: 0;
		overflow: hidden;
		padding-top: ${yRatio}%;
		position: relative;
		width: 100%;
	`;
};

const fixedRatioContent = () => {
	return `
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	`;
};

const SQUARE_RATIO = 1 / 1 * 100/*%*/;

const SquareRatioContainerDiv = styled('div')`
	${fixedRatioContainer(SQUARE_RATIO)}
`;

const SquareImg = styled('img')`
	${fixedRatioContent()}
`;

const SquareImage = ({
	alt,
	src,
	style,
}) => (
	<SquareRatioContainerDiv style={style}>
		<SquareImg src={src} alt={alt} />
	</SquareRatioContainerDiv>
);

SquareImage.displayName = 'SquareImage';

SquareImage.propTypes = {
	alt: PropTypes.string,
	src: PropTypes.string,
	style: PropTypes.object,
};

export default SquareImage;