import React from 'react';
import PropTypes from 'prop-types';
import defaultIconProps from '../_defaultIconProps';
import iconProps from '../_iconProps';
import Svg from '../Svg';

export const IconCardBackLandscapeTextOnly = (props) => {
	const { size } = props;
	const heightRatio = 100;
	const widthRatio = 140;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="IconCardBackLandscapeTextOnly"
			width={width}
			height={height}
			viewBox="0 0 140 100"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <rect fill={props.color} x="0" y="0" width="140" height="100" />
		        <polygon fill={props.color2} points="40 38 100 38 100 40 40 40" />
		        <polygon fill={props.color2} points="40 51 100 51 100 53 40 53" />
		        <polygon fill={props.color2} points="55 57 85 57 85 59 55 59" />
		        <rect fill={props.color2} x="45" y="44" width="50" height="2" />
		    </g>
		</Svg>
	);
};

IconCardBackLandscapeTextOnly.displayName = 'IconCardBackLandscapeTextOnly';

IconCardBackLandscapeTextOnly.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconCardBackLandscapeTextOnly.defaultProps = {
	...defaultIconProps,
	color: '#ffffff',
	color2: '#d8d8d8',
};

export default IconCardBackLandscapeTextOnly;
