import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import deep from 'deep-get-set';

import close from '../../../img/icon_close.svg';
import connectContext from '../../../helpers/connectContext';
import { Consumer } from '../../../helpers/context';
import hamburger from '../../../img/icon_hamburger.svg';
import './ExpandToggle.css';

export const ExpandToggle = ({ expanded, hasLinks, hasProducts, onToggleExpanded }) => {
	const classes = classnames('toggle-links', 'hide-for-large');

	if (!hasProducts && !hasLinks) return null;

	return (
		<li className={classes}>
			<button
				onClick={() => onToggleExpanded && onToggleExpanded()}
				className="button-toggle-link"
			>
				<img
					src={expanded ? close : hamburger}
					alt={`Toggle Menu`}
				/>
			</button>
		</li>
	);
};

ExpandToggle.propTypes = {
	expanded: PropTypes.bool,
	hasLinks: PropTypes.bool,
	hasProducts: PropTypes.bool,
	onToggleExpanded: PropTypes.func
};

ExpandToggle.defaultProps = {
	hasLinks: false,
	hasProducts: false
};

const mapContextToProps = (context) => {
	const expanded = deep(context, 'expanded');
	const onToggleExpanded = deep(context, 'onToggleExpanded');
	const products = deep(context, 'products');
	const rightHandLinks = deep(context, 'rightHandLinks');

	const hasProducts = products ? products.length > 0 : false;
	const hasLinks = rightHandLinks ? rightHandLinks.length > 0 : false;

	return {
		expanded,
		hasLinks,
		hasProducts,
		onToggleExpanded
	};
};

export default connectContext(Consumer, mapContextToProps)(ExpandToggle);
