import React from 'react';
import PropTypes from 'prop-types';

export const H3 = ({
	children,
	className,
}) => {
	if (!children) return null;

	return (
		<h3
			className={className}
		>
			{children}
		</h3>
	);
};

H3.displayName = 'H3';

H3.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default H3;
