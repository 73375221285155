import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import deep from 'deep-get-set';

import connectContext from '../../../helpers/connectContext';
import { Consumer } from '../../../helpers/context';
import ExpandToggle from '../ExpandToggle/ExpandToggle';
import Logo from '../Logo/Logo';
import ProductsLink from '../ProductsLink/ProductsLink';
import RightHandLinks from '../RightHandLinks/RightHandLinks';
import './Menu.css';

export const Menu = ({ cart, expanded }) => (
	<div className="menu-wrapper">
		<div className="constrained-content">
			<ul className="menu top-menu">
				<ExpandToggle />
				<Logo />
				<li className={classnames('links', { 'links-visible': expanded })}>
					<ul className="menu embeded-menu">
						<ProductsLink />
						<RightHandLinks />
					</ul>
				</li>
				{!!cart && (
					<li className="cart-link">
						{cart}
					</li>
				)}
			</ul>
		</div>
	</div>
);

Menu.propTypes = {
	cart: PropTypes.node,
	expanded: PropTypes.bool
};

Menu.defaultProps = {
	expanded: false
};

const mapContextToProps = (context) => {
	const cart = deep(context, 'cart');
	const expanded = deep(context, 'expanded');

	return {
		cart,
		expanded
	};
};

export default connectContext(Consumer, mapContextToProps)(Menu);
