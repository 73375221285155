import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconClock = (props) => (
	<Svg
		{...props}
		name="close"
		viewBox="0 0 120 120"
	>
		<path
			d="M60,95A29.66,29.66,0,1,1,89.66,65.39,29.69,29.69,0,0,1,60,95Zm0-56.93A27.28,27.28,0,1,0,87.28,65.39,27.31,27.31,0,0,0,60,38.11Z"
		/>
		<path
			d="M60,88.31A22.92,22.92,0,1,1,82.92,65.39,22.94,22.94,0,0,1,60,88.31Zm0-43.47A20.55,20.55,0,1,0,80.55,65.39,20.57,20.57,0,0,0,60,44.84Z"
		/>
		<path
			d="M72.68,66.57H59.21A1.18,1.18,0,0,1,58,65.39V51.92a1.19,1.19,0,1,1,2.38,0V64.2H72.68a1.19,1.19,0,1,1,0,2.37Z"
		/>
		<path
			d="M40.89,97.15a1.19,1.19,0,0,1-.84-2l5.54-5.54a1.19,1.19,0,1,1,1.68,1.68L41.73,96.8A1.2,1.2,0,0,1,40.89,97.15Z"
		/>
		<path
			d="M79.18,97.15a1.2,1.2,0,0,1-.84-.35L72.8,91.26a1.19,1.19,0,0,1,1.68-1.68L80,95.12a1.19,1.19,0,0,1-.84,2Z"
		/>
		<path
			d="M60,38.11a1.2,1.2,0,0,1-1.19-1.19V32.17a1.19,1.19,0,0,1,2.38,0v4.75A1.2,1.2,0,0,1,60,38.11Z"
		/>
		<path
			d="M62.37,32.57H57.63a1.19,1.19,0,1,1,0-2.37h4.74a1.19,1.19,0,1,1,0,2.37Z"
		/>
		<path
			d="M45.29,42.13a1.17,1.17,0,0,1-1-.57l-1.92-3.18a1.19,1.19,0,0,1,2-1.23l1.92,3.18a1.18,1.18,0,0,1-1,1.8Z"
		/>
		<path
			d="M33.87,44.64a1.18,1.18,0,0,1-1-.58,12.25,12.25,0,0,1,21-12.67,1.19,1.19,0,0,1-.4,1.63l-19,11.45A1.22,1.22,0,0,1,33.87,44.64Zm9.46-16.8a9.75,9.75,0,0,0-5.09,1.43h0a9.88,9.88,0,0,0-3.91,12.51L51.14,31.64a9.84,9.84,0,0,0-5.42-3.51A10.21,10.21,0,0,0,43.33,27.84Zm-5.7.41h0Z"
		/>
		<path
			d="M36.28,29.18A3.16,3.16,0,1,1,39,24.38h0a3.16,3.16,0,0,1-1.08,4.34A3.12,3.12,0,0,1,36.28,29.18Zm0-3.95a.77.77,0,0,0-.41.11.8.8,0,0,0-.27,1.09A.79.79,0,0,0,37,25.61h0A.78.78,0,0,0,36.28,25.23Z"
		/>
		<path
			d="M74.59,42.13A1.13,1.13,0,0,1,74,42a1.19,1.19,0,0,1-.4-1.63l1.92-3.18a1.17,1.17,0,0,1,1.62-.4,1.2,1.2,0,0,1,.41,1.63l-1.92,3.18A1.2,1.2,0,0,1,74.59,42.13Z"
		/>
		<path
			d="M86,44.64a1.22,1.22,0,0,1-.61-.17L66.44,33a1.13,1.13,0,0,1-.53-.73,1.18,1.18,0,0,1,.13-.9A12.26,12.26,0,0,1,87,44.06a1.17,1.17,0,0,1-.73.54A1.45,1.45,0,0,1,86,44.64Zm-17.26-13,16.8,10.14a9.88,9.88,0,0,0-3.91-12.51h0a9.86,9.86,0,0,0-12.89,2.37Z"
		/>
		<path
			d="M83.6,29.18A3.07,3.07,0,0,1,82,28.72a3.17,3.17,0,0,1-1.07-4.34h0A3.12,3.12,0,0,1,82.85,23a3.08,3.08,0,0,1,2.39.36,3.17,3.17,0,0,1-.88,5.78A3.29,3.29,0,0,1,83.6,29.18Zm-.67-3.57a.79.79,0,0,0,.87,1.17.77.77,0,0,0,.48-.35A.78.78,0,0,0,84,25.34a.77.77,0,0,0-.59-.09.78.78,0,0,0-.49.36Z"
		/>
	</Svg>
);

IconClock.displayName = 'IconClock';

IconClock.propTypes = iconProps;

IconClock.defaultProps = defaultIconProps;

export default IconClock;
