import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as intercomActions from '../../actions/intercom.actions';
import { PageError } from '../../chatponents';
import ServerRouteStatus from '../../components/ServerRouteStatus/ServerRouteStatus';
import Translate from '../../components/Translate/Translate';
import './NotFound.css';

const NotFound = ({ intercomActions, history }) => (
	<ServerRouteStatus httpStatusCode={404}>
		<div className="NotFound constrain-width mobile-padded">
			<PageError
				errorTitle={<Translate id="web.404.title" />}
				errorSubtext={<Translate id="web.404.subtext" />}
				primaryButtonProps={{
					children: <Translate id="web.404.primary-cta" />,
					onClick: () => history.push('/'),
				}}
				secondaryButtonProps={{
					children: <Translate id="web.404.secondary-cta" />,
					onClick: () => intercomActions.openHelp(),
				}}
			/>
		</div>
	</ServerRouteStatus>
);

NotFound.displayName = 'NotFound';

NotFound.propTypes = {
	intercomActions: PropTypes.object,
	history: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
	intercomActions: bindActionCreators(intercomActions, dispatch)
});

export default connect(
	null,
	mapDispatchToProps,
)(withRouter(NotFound));
