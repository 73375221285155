export const COLOR = {
	// Neutral
	BLACK: '#000000',
	BLACK_QUATERNARY: 'rgba(68, 94, 126, 0.18)',
	BLACK_SECONDARY: 'rgba(0, 0, 0, 0.45)',
	BLACK_TERTIARY: 'rgba(38, 60, 89, 0.3)',
	WHITE: '#FFFFFF',
	WHITE_QUATERNARY: 'rgba(235, 235, 245, 0.18)',
	WHITE_SECONDARY: 'rgba(245, 245, 245, 0.6)',
	WHITE_TERTIARY: 'rgba(235, 235, 245, 0.3)',

	// Grays
	GRAY_50: '#FBFDFE',
	GRAY_100: '#F1F5F9',
	GRAY_200: '#E2E8F0',
	GRAY_300: '#CFD8E3',
	GRAY_400: '#97A6BA',
	GRAY_500: '#7A899F',
	GRAY_600: '#52627A',
	GRAY_700: '#3D495C',
	GRAY_800: '#27303F',
	GRAY_900: '#1A202E',

	// Chatty Green
	CHATTY_GREEN_50: '#F0FFFB',
	CHATTY_GREEN_100: '#E1FCF7',
	CHATTY_GREEN_200: '#B0F0E2',
	CHATTY_GREEN_300: '#80E0CE',
	CHATTY_GREEN_400: '#00CFB4',
	CHATTY_GREEN_500: '#00A896',
	CHATTY_GREEN_600: '#008177',
	CHATTY_GREEN_700: '#006961',
	CHATTY_GREEN_800: '#00504A',
	CHATTY_GREEN_900: '#002825',

	// Blue
	BLUE_50: '#F7FBFF',
	BLUE_100: '#EBF8FF',
	BLUE_200: '#C0E6FA',
	BLUE_300: '#9CCEFF',
	BLUE_400: '#69A1F0',
	BLUE_500: '#357FE8',
	BLUE_600: '#255DAF',
	BLUE_700: '#1B4685',
	BLUE_800: '#112E5A',
	BLUE_900: '#09172D',

	// Pink
	PINK_50: '#FFF5F5',
	PINK_100: '#FCEDEE',
	PINK_200: '#FCCEDD',
	PINK_300: '#FCB4D0',
	PINK_400: '#EA73B4',
	PINK_500: '#CA3975',
	PINK_600: '#991658',
	PINK_700: '#7A1447',
	PINK_800: '#5A1135',
	PINK_900: '#2D091B',

	// Red
	RED_50: '#FFF4EE',
	RED_100: '#FFE1D4',
	RED_200: '#FFB69C',
	RED_300: '#FF9473',
	RED_400: '#FF534A',
	RED_500: '#E02222',
	RED_600: '#AD0B0B',
	RED_700: '#840E0E',
	RED_800: '#5A1111',

	// Yellow
	YELLOW_50: '#FCFAF3',
	YELLOW_100: '#FFF9DE',
	YELLOW_200: '#FFEEA3',
	YELLOW_300: '#FFE67B',
	YELLOW_400: '#F9D22D',
	YELLOW_500: '#E2B330',
	YELLOW_600: '#C78516',
	YELLOW_700: '#8C5C0B',
	YELLOW_800: '#503300',
	YELLOW_900: '#281A00',
};
