export default (measurement) => {
	if (!measurement) {
		return measurement === 0 ? '0' : undefined;
	}

	if (/[0-9]$/.test(measurement) && measurement !== '0') {
		return `${measurement}px`;
	}

	return measurement;
};
