import React from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu/Menu';
import Overlay from './Overlay/Overlay';
import { Provider } from '../../helpers/context';
import './CommonHeader.css';

export const CommonHeader = ({
	analyticEvent,
	cart,
	clickHandler,
	expanded,
	logo,
	linkElement,
	linkElementPathAttr,
	onRequestCollapse,
	onToggleDropdown,
	onToggleExpanded,
	openDropdowns,
	productsHeader,
	rightHandLinks
}) => (
	<Provider
		value={{
			analyticEvent,
			cart,
			clickHandler,
			expanded,
			logo,
			linkElement,
			linkElementPathAttr,
			onRequestCollapse,
			onToggleDropdown,
			onToggleExpanded,
			openDropdowns,
			productsHeader,
			rightHandLinks
		}}
	>
		<header className="CommonHeader">
			<Menu />
			<Overlay />
		</header>
	</Provider>
);

CommonHeader.displayName = 'CommonHeader';

CommonHeader.propTypes = {
	analyticEvent: PropTypes.func,
	cart: PropTypes.object,
	clickHandler: PropTypes.func,
	expanded: PropTypes.bool,
	logo: PropTypes.shape({
		url: PropTypes.string,
		alt: PropTypes.string
	}),
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	onRequestCollapse: PropTypes.func,
	onToggleDropdown: PropTypes.func,
	onToggleExpanded: PropTypes.func,
	openDropdowns: PropTypes.object,
	productsHeader: PropTypes.string,
	rightHandLinks: PropTypes.array
};

CommonHeader.defaultProps = {
	expanded: false,
};

export default CommonHeader;
