import styled from 'styled-components';

import PillButton from '../PillButton/PillButton';
import { DEVICE_WIDTH, mediaQueryStartingFrom, mediaQueryUpTo } from '../../../const';

const HomePagePillButton = styled(PillButton)`
	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		border-radius: 65px;
		font-size: 16px;
		line-height: 23px;
		min-height: 48px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		border-radius: 80px;
		font-size: 20px;
		line-height: 29px;
		min-height: 55px;
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

HomePagePillButton.displayName = 'HomePagePillButton';

export default HomePagePillButton;