import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { selectInstagramDataSourceMedia } from '../../../../selectors/dataSourceMedia.selectors';
import './PreviewImages.css';

const PreviewImages = ({ className, photos }) => {
	const maxImageCount = 5;
	const photosFiltered = photos.slice(0, maxImageCount);

	if (photos.length === 0) return null;

	return (
		<div className={classnames(['margin-top', 'PreviewImages', className])}>
			<ul>
				{photosFiltered.map((photo, index) => (
					<li
						key={index}
					>
						<img
							src={photo.thumbnailUrl}
							alt={`series preview ${index + 1}`}
						/>
					</li>
				))}
			</ul>
		</div>
	);
};

PreviewImages.propTypes = {
	className: PropTypes.string,
	photos: PropTypes.array,
};

const mapStateToProps = (state) => ({
	photos: selectInstagramDataSourceMedia(state),
});

export default connect(
	mapStateToProps,
)(PreviewImages);
