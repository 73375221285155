import React from 'react';
import PropTypes from 'prop-types';
import deep from 'deep-get-set';
import classnames from 'classnames';

import ConfigurableLink from '../../ConfigurableLink/ConfigurableLink';
import connectContext from '../../../helpers/connectContext';
import { Consumer } from '../../../helpers/context';
import SubMenu from './SubMenu';
import './MenuLink.css';

export const MenuLink = ({
	action,
	analyticObj,
	analyticEvent,
	className,
	children,
	clickHandler,
	dropdownCallback,
	dropdownKey,
	dropdownOpen,
	fullLabel,
	items,
	label,
	linkElement,
	linkElementPathAttr,
	onMouseEnter,
	onMouseLeave,
	path
}) => {
	const pathname = deep(window, 'location.pathname');
	const isHomePage = path === '/';
	const isActive = isHomePage ? false : path === pathname;
	const classes = classnames(
		'CommonHeaderMenuLink',
		'flex-dir-column',
		{
			'has-children': (items && items.length) || children,
			relative: items && items.length,
			open: dropdownOpen,
		},
		className,
		{
			active: isActive
		}
	);

	return (
		<li
			className={classes}
			onMouseEnter={() => onMouseEnter && onMouseEnter()}
			onMouseLeave={() => onMouseLeave && onMouseLeave()}
		>
			<ConfigurableLink
				action={action}
				analyticEvent={analyticEvent}
				analyticObj={analyticObj}
				className="link small-flex-dir-column"
				clickHandler={() => {
					clickHandler && clickHandler(action, path);
					dropdownCallback && dropdownCallback(dropdownKey);
				}}
				label={label}
				linkElement={linkElement}
				linkElementPathAttr={linkElementPathAttr}
				path={path}
			>
				{fullLabel && fullLabel}
				{!fullLabel && <span>{label}</span>}
			</ConfigurableLink>
			<SubMenu items={items} />
			{children}
		</li>
	);
};

MenuLink.propTypes = {
	action: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	className: PropTypes.any,
	children: PropTypes.node,
	clickHandler: PropTypes.func,
	dropdownCallback: PropTypes.func,
	dropdownKey: PropTypes.any,
	dropdownOpen: PropTypes.bool,
	fullLabel: PropTypes.any,
	items: PropTypes.array,
	label: PropTypes.any,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	path: PropTypes.string,
};

MenuLink.defaultProps = {
	dropdownOpen: false
};

const mapContextToProps = (context) => {
	const analyticEvent = deep(context, 'analyticEvent');
	const clickHandler = deep(context, 'clickHandler');
	const linkElement = deep(context, 'linkElement');
	const linkElementPathAttr = deep(context, 'linkElementPathAttr');

	return {
		analyticEvent,
		clickHandler,
		linkElement,
		linkElementPathAttr
	};
};

export default connectContext(Consumer, mapContextToProps)(MenuLink);
