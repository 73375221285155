import React, { Fragment } from 'react';

import Stars from '../Stars/Stars';
import { scrollBodyToElement } from '../../../../helpers/scroll/scroll';
import './ReviewLine.css';

const ReviewLine = () => (
	<Fragment>
		<div
			className="review-line"
			onClick={() => scrollBodyToElement('reviews')}
		>
			<Stars />
			<p className="review-link">
				4.8 <span className="underline">(104K Review)</span>
			</p>
		</div>
		<p className="review-text">Why spend your time scrapbooking when you can be Chatbooking? Connect your Instagram and receive a photo book for every 60 pictures you post</p>
	</Fragment>
);

export default ReviewLine;
