import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconDiscount = (props) => (
	<Svg
		{...props}
		name="discount"
		viewBox="0 0 48 48"
	>
		<g>
			<path
				fill="inherit"
				d="M19.102,2c0.78,0.001,1.732,0.498,2.547,1.328l1.378,1.388l0.013-0.012l14.978,14.977l0.081,0.082
				l0.09,0.071l0.114,0.088l0.017,0.013l7.387,7.387c0.391,0.39,0.391,1.025,0.001,1.415L28.733,45.709
				C28.48,45.962,28.184,46,28.029,46c-0.155,0-0.453-0.038-0.708-0.293l-3.094-3.094l-1.409-1.361L4.748,23.18l0.02-0.019
				L3.28,21.699c-0.814-0.8-1.263-1.683-1.264-2.485L2,2.018L19.102,2 M19.103,0L1.93,0.018c-0.001,0-0.003,0-0.004,0
				c-0.608,0-1.065,0.217-1.402,0.554C0.186,0.909,0,1.4,0,2.01l0.016,17.206c0.001,1.311,0.633,2.701,1.862,3.909l-0.007,0.007
				l20.918,20.919l0.024-0.023l3.094,3.094C26.493,47.707,27.262,48,28.029,48c0.769,0,1.536-0.293,2.12-0.879L47.121,30.15
				c1.172-1.172,1.172-3.07,0-4.243l-7.424-7.424c-0.082-0.083-0.176-0.146-0.266-0.217L23.084,1.919l-0.008,0.007
				C21.851,0.679,20.417,0.001,19.103,0L19.103,0z"
			/>
		</g>
		<circle
			fill="none"
			stroke={props.color}
			strokeMiterlimit="10"
			cx="12.75"
			cy="12.749"
			r="3.75"
		/>
	</Svg>
);

IconDiscount.displayName = 'IconDiscount';

IconDiscount.propTypes = iconProps;

IconDiscount.defaultProps = defaultIconProps;

export default IconDiscount;
