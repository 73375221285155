import requireParams from '../helpers/requireParams';
import * as ACTIONS from './actionTypes';
import * as api from './api/api.actions';
import { apiGetData } from './api/api.http.actions';
import * as appActions from './app.actions';
import * as groupActions from './groups.actions';
import { receiveEntities } from './entity.actions';
import { openConfirmModal } from './view.actions';
import { queryStringFromJson } from '../helpers/locationUtils';
import { appStringModel } from '../model/appString.model';
import { bookFromJson, normalizeBooks } from '../model/book.model';
import { confirmModalModel } from '../model/confirmModal.model';
import { normalizeExcludedMomentsResponse, excludedMomentsResponseFromJson } from '../model/excludedMomentsResponse.model';

export const setBookCoverMedia = (bookId, mediaId) => ({ type: ACTIONS.SET_BOOK_COVER_MEDIA, bookId, mediaId });

const SORT_DIRECTIONS = {
	ASCENDING: 'asc',
	DESCENDING: 'desc'
};

export const createCustomBookWithMoments = (title, momentIds, bookSize) => (dispatch) => {
	return dispatch(groupActions.createCustomBookWithMoments(title, momentIds, bookSize));
};

export const deleteAllExcluded = (groupId) => (dispatch) => {
	dispatch(appActions.showThrobber(deleteAllExcluded.name));

	return dispatch(api.postBooksDeleteAllExcluded(groupId))
		.finally(() => dispatch(appActions.hideThrobber(deleteAllExcluded.name)));
};

/**
 * Sends an API request to retrieve a book, given its ID.
 *
 * @param {string} groupId The Group ID.
 * @param {string} volume The volume of the book. (defaults to 1)
 * @param {string} count Number of volumes to retrieve. (defaults to 1)
 * @param {string} sort 'asc' (default) or 'desc'
 */
export const loadBooksSkinny = (groupId, volume = 1, count = 1, sort = SORT_DIRECTIONS.ASCENDING) => (dispatch) => {
	const queryString = queryStringFromJson({ groupID: groupId, index: volume - 1, count, sort });

	return dispatch(apiGetData(`/books/skinny?${queryString}`))
		.then((booksJson) => booksJson.books.map(bookFromJson))
		.then((books) => {
			const normalized = normalizeBooks(books);
			dispatch(receiveEntities(normalized.entities, loadBooksSkinny.name));

			return books;
		});
};

/**
 * In order to load excluded moments paging/date data, we call the excluded moments endpoint
 * with includeDateInfo=true.
 *
 * We'll also request 0 excluded moments.
 *
 * @param {string} groupId The group to load excluded moments date info.
 */
export const loadExcludedMomentsDateInfo = (groupId) => (dispatch) => {
	return dispatch(loadExcludedMoments(groupId, 0, 0, true));
};

export const loadExcludedMoments = (groupId, index, count, includeDataInfo, offsetMinutes) => (dispatch) => {
	return dispatch(api.getBooksExcludedMoments(groupId, index, count, includeDataInfo, offsetMinutes))
		.then((data) => {
			const response = excludedMomentsResponseFromJson(groupId, index, count, data);
			const normalized = normalizeExcludedMomentsResponse(response);
			dispatch(receiveEntities(normalized.entities, loadExcludedMoments.name));

			return data; // Maintain legacy untransformed data
		});
};

export const promptDeleteAllExcluded = (groupId) => (dispatch) => {
	return dispatch(openConfirmModal(confirmModalModel({
		title: appStringModel({ id: 'web.Delete All Excluded' }),
		body: appStringModel({ id: 'web.delete_excluded_confirm' }),
		cancelText: appStringModel({ id: 'web.Cancel' }),
		confirmType: 'delete',
		confirmText: appStringModel({ id: 'web.Delete' }),
	})))
		.then(() => {
			return dispatch(deleteAllExcluded(groupId))
				.catch((err) => {
					console.error(err);
					throw err;
				});
		});
};

export const setCoverMedia = (bookId, mediaId) => (dispatch) => {
	requireParams({ bookId, mediaId }, setCoverMedia.name);

	return dispatch(api.postBooksSetCoverMedia(bookId, mediaId))
		.then(() => dispatch(setBookCoverMedia(bookId, mediaId)));
};
