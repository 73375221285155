import React, { Component } from 'react';
import { FormContext } from './FormContext';

export default (WrappedComponent) =>
	class extends Component {
		static displayName = `WithFormContext${WrappedComponent.displayName || WrappedComponent.name || 'Component'}`;

		render() {
			const props = this.props;

			return (
				<FormContext.Consumer>
					{({ formFields, submitted, success, updateFormField }) => (
						<WrappedComponent
							{...props}
							formFields={formFields}
							submitted={submitted}
							success={success}
							onUpdateFormField={updateFormField}
						/>
					)}
				</FormContext.Consumer>
			);
		}
	};
