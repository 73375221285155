import React from 'react';
import { hydrate, render } from 'react-dom';
import Cookies from 'cookies-js';

import asyncBootstrapper from './asyncBootstrapper';
import { unregister } from './registerServiceWorker';
import Root from './components/App/Root';
import { initHttpInterceptors } from './helpers/http';
import { create as createStore } from './store';
import { Modal } from './chatponents';
import './vendors';

initHttpInterceptors();

const ssr = !!window.__SSR__;
const reduxStore = createStore(window.__REDUX_STATE__, Cookies);
const rehydrateState = window.__ASYNC_COMPONENT_STATE__;
const app = <Root reduxStore={reduxStore} rehydrateState={rehydrateState} />;
const root = document.getElementById('root');

if (window.Cypress) {
	window.store = reduxStore;
}

asyncBootstrapper(app, { componentWillUnmount: true })
	.then(() => {
		(ssr ? hydrate : render)(app, root);
		delete window.__ASYNC_COMPONENT_STATE__;
		delete window.__REDUX_STATE__;
		delete window.__SSR__;
	})
	.catch((err) => console.error(err));

unregister();
Modal.setAppElement('#root');
