import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { stripeCardFromJson } from './stripeCard.model';
import { ENTITIES } from '../const/entities/entities';
import { PAYMENT_TYPE } from '../const/payment.const';

export const paymentMethodFromJson = (json = {}) => ({
	active: deep(json, 'Active'),
	cardId: deep(json, 'CardId'),
	cardType: deep(json, 'CardType'),
	email: deep(json, 'Email'),
	expirationDate: new Date(deep(json, 'ExpirationDate.Iso8601')),
	id: String(deep(json, 'ID')),
	last4: deep(json, 'Last4'),
	stripeCard: stripeCardFromJson(deep(json, 'StripeCard')),
	type: deep(json, 'Type'),
});

export const paymentMethodSchema = new schema.Entity(ENTITIES.PAYMENT_METHOD);
const paymentMethodsSchema = new schema.Array(paymentMethodSchema);

export const normalizePaymentMethod = (paymentMethod) => {
	return normalize(paymentMethod, paymentMethodSchema);
};

export const normalizePaymentMethods = (paymentMethods) => {
	return normalize(paymentMethods, paymentMethodsSchema);
};


export const isPayPal = (paymentMethod) => [PAYMENT_TYPE.PAYPAL_BRAINTREE, PAYMENT_TYPE.PAYPAL].includes(getType(paymentMethod));

export const getType = (paymentMethod) => deep(paymentMethod, 'type');