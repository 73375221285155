export const ENTITIES = {
	ADDRESS_IDS: 'addressIds',
	ADDRESS: 'address',
	BOOK_COVER_COLOR_IDS: 'bookCoverColorIds',
	BOOK_COVER_COLOR: 'bookCoverColors',
	BOOK: 'bookMap',
	CART_ITEM: 'cartItem',
	CART: 'cart',
	COLLAGE_LAYOUT_IDS_BY_BOOK_SIZE: 'collageLayoutIdsByBookSize',
	COLLAGE_LAYOUT: 'collageLayouts',
	COLLAGE_MEDIA: 'collageMedia',
	COLLAGE: 'collage',
	COMMITTED_CART: 'committedCart',
	COUNTRY: 'country',
	COUPON_CODE_IDS: 'couponCodeIds',
	COUPON_CODE: 'couponCode',
	COVER_BUNDLE_IDS: 'coverBundleIds',
	COVER_BUNDLE: 'coverBundles',
	COVER_CROP: 'coverCrop',
	COVER_TEMPLATE: 'coverTemplates',
	CROP_RECT: 'cropRect',
	CURRENCY_MARKET: 'currency_market',
	CURRENCY: 'currency',
	DATA_SOURCE_IDS: 'dataSourceIds',
	DATA_SOURCE_MEDIA_PAGE: 'dataSourceMediaPage',
	DATA_SOURCE_MEDIA: 'dataSourceMedia',
	DATA_SOURCE: 'dataSource',
	DISPLAY_DATE: 'displayDate',
	DUPLO: 'duplo',
	EXCLUDED_MOMENTS_DATE_INFO: 'excludedMomentsDateInfo',
	EXCLUDED_MOMENTS_PAGE: 'excludedMomentsPage',
	EXCLUDED_MOMENTS_RESPONSE: 'excludedMomentsResponse',
	GIFT_CODE: 'giftCode',
	GROUP_COVER_BUNDLE: 'groupCoverBundles',
	GROUP_EXCLUDED_MOMENTS_PAGE: 'groupExcludedMomentsPage',
	GROUP_IDS: 'groupIds',
	GROUP_INVITE_CODE: 'groupInviteCode',
	GROUP_ORDER_CALCULATED_SHIPPING_OPTION: 'groupOrderCalculatedShippingOption',
	GROUP_ORDER_CALCULATED_SHIPPING_OPTIONS_RESPONSE: 'groupOrderCalculatedShippingOptionResponse',
	GROUP_SERIES_PRICE: 'groupSeriesPrice',
	GROUP_SUBSCRIPTION_ORDERS: 'groupSubscriptionOrders',
	GROUP_SUBSCRIPTIONS_RESPONSE: 'groupSubscriptionsResponse',
	GROUP: 'groups',
	LOCAL_MEDIA_FILE: 'localMediaFile',
	MASTER_ORDER: 'masterOrder',
	MEDIA: 'media',
	MOMENT: 'momentMap',
	ORDER_CALCULATED_SHIPPING_OPTION: 'orderCalculatedShippingOption',
	ORDER_DESCRIPTORS: 'orderDescriptors',
	ORDER_OPTION: 'orderOptions',
	ORDER_SHIPPING_OPTION: 'orderShippingOption',
	ORDER: 'order',
	PAYMENT_METHOD_IDS: 'paymentMethodIds',
	PAYMENT_METHOD: 'paymentMethod',
	PERSON: 'person',
	PRODUCT_PRICE_TIER: 'productPriceTier',
	PRODUCT: 'product',
	SHIPPING_GRADE: 'shippingGrade',
	SHIPPING_MESSAGE: 'shippingMessage',
	SHIPPING_OPTION_VIEW_RESPONSE: 'shippingOptionViewResponse',
	SHIPPING_OPTION: 'shippingOption',
};
