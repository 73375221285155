import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import sourcesData from './PhotoSources.data';
import { QUICK_FLOW_URL } from '../QuickFlow';
import { ConfigurableLink } from '../../../chatponents';
import * as analyticsActions from '../../../actions/analytics.actions';
import './PhotoSources.css';

export const PhotoSources = ({
	analyticsActions,
	history,
}) => (
	<div className="quickflow-landing-photo-sources">
		<p className="margin pick-a-photo-source">Pick a photo source to get started</p>

		<div className="row">
			{sourcesData.map((source, index) => (
				<div
					className="col-xs-6"
					key={index}
				>
					<ConfigurableLink
						className="source-box"
						path={`${QUICK_FLOW_URL}/${source.key}`}
						linkCallback={(path) => {
							analyticsActions.triggerAnalyticsEvent('QuickFlowLanding_ChooseSource', {
								attr1: source.key,
							});
							history.push(path);
						}}
					>
						<img
							src={source.img}
							alt={source.name}
						/>
						<p>{source.name}</p>
					</ConfigurableLink>
				</div>
			))}
		</div>
	</div>
);

PhotoSources.propTypes = {
	analyticsActions: PropTypes.object,
	history: PropTypes.object,
};

PhotoSources.displayName = 'PhotoSources';

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
});

export default connect(
	null,
	mapDispatchToProps
)(withRouter(PhotoSources));
