import React, { Component } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CloseButton from './CloseButton';
import './Modal.css';

class Modal extends Component {
	constructor(props) {
		super(props);

		this.modalContentRef = React.createRef();
		this.handleClick = this.handleClick.bind(this);
		this.handleClickClose = this.handleClickClose.bind(this);
		this.setContentRef = this.setContentRef.bind(this);
	}

	static setAppElement(el) {
		ReactModal.setAppElement(el);
	}

	handleClick(e) {
		const { onRequestClose, shouldCloseOnOverlayClick } = this.props;

		if (shouldCloseOnOverlayClick && onRequestClose && e.target === this.contentRef) {
			onRequestClose();
		}
	}

	handleClickClose(e) {
		const { onRequestClose } = this.props;

		onRequestClose && onRequestClose();
	}

	setContentRef(node) {
		const { contentRef } = this.props;

		this.contentRef = node;
		this.contentRef && this.contentRef.addEventListener('click', this.handleClick);
		contentRef && contentRef(this.modalContentRef);
	}

	render() {
		return (
			<ReactModal
				{...this.props}
				className="ModalDialogWrapper"
				contentRef={this.setContentRef}
				overlayClassName={classnames('ModalOverlay', this.props.overlayClassName)}
				portalClassName={classnames('Modal', this.props.portalClassName)}
			>
				<div
					ref={this.modalContentRef}
					className={classnames('ModalDialog', this.props.className)}
				>
					<CloseButton
						isVisible={this.props.showCloseButton}
						onClose={this.handleClickClose}
					/>
					{this.props.children}
				</div>
			</ReactModal>
		);
	}
}

Modal.displayName = 'Modal';

Modal.defaultProps = {
	ariaHideApp: false,
	isOpen: false,
	shouldCloseOnEsc: true,
	shouldCloseOnOverlayClick: true,
	shouldFocusAfterRender: false,
	shouldReturnFocusAfterClose: false,
	showCloseButton: true
};

Modal.propTypes = {
	aria: PropTypes.object,
	ariaHideApp: PropTypes.bool,
	bodyOpenClassName: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	closeTimeoutMS: PropTypes.number,
	contentLabel: PropTypes.string,
	contentRef: PropTypes.func,
	htmlOpenClassName: PropTypes.string,
	isOpen: PropTypes.bool,
	onAfterClose: PropTypes.func,
	onAfterOpen: PropTypes.func,
	onRequestClose: PropTypes.func,
	overlayClassName: PropTypes.string,
	overlayRef: PropTypes.func,
	parentSelector: PropTypes.func,
	portalClassName: PropTypes.string,
	role: PropTypes.string,
	shouldCloseOnEsc: PropTypes.bool,
	shouldCloseOnOverlayClick: PropTypes.bool,
	shouldFocusAfterRender: PropTypes.bool,
	shouldReturnFocusAfterClose: PropTypes.bool,
	showCloseButton: PropTypes.bool,
	style: PropTypes.object
};

export default Modal;
