import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCheckmark = (props) => {
	const { size } = props;
	const heightRatio = 23;
	const widthRatio = 20;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			height={height}
			width={width}
			viewBox="0 0 20 23"
			fill="none"
			hasStroke={false}
			name="checkmark"
		>
			<path
				d="M19.8751 1.39355C19.7418 1.00206 19.4936 0.65986 19.1628 0.41163C18.832 0.163399 18.434 0.0206712 18.0209 0.00207963C17.6078 -0.016512 17.1986 0.0898968 16.8469 0.307414C16.4951 0.524931 16.2171 0.84345 16.0492 1.22138L8.49301 17.3859L3.50975 12.4026C3.12411 12.0171 2.60117 11.8006 2.0559 11.8006C1.51063 11.8006 0.987681 12.0171 0.602047 12.4026C0.216555 12.7883 0 13.3112 0 13.8565C0 14.4018 0.216555 14.9247 0.602047 15.3103L7.65609 22.3692C8.03605 22.7515 8.55178 22.9681 9.09081 22.9717C9.20936 22.9719 9.32773 22.9623 9.44471 22.943C9.77019 22.8852 10.0771 22.7504 10.34 22.5499C10.6029 22.3494 10.814 22.0891 10.9559 21.7905L19.7986 2.96696C19.9149 2.72283 19.9815 2.45803 19.9947 2.18793C20.0078 1.91783 19.9672 1.64781 19.8751 1.39355Z"
				fill="inherit"
			/>
		</Svg>
	);
};

IconCheckmark.displayName = 'IconCheckmark';

IconCheckmark.propTypes = iconProps;

IconCheckmark.defaultProps = defaultIconProps;

export default IconCheckmark;
