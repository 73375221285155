import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.HAPPYKIT], action) => {
	const { type } = action;

	switch (type) {
		case ACTIONS.FETCH_HAPPYKIT:
			return {
				...state,
				isLoading: true,
				isError: false,
			};

		case ACTIONS.RECEIVE_HAPPYKIT:
			return {
				...state,
				flags: action.flags,
				isLoading: false,
			};

		case ACTIONS.ERROR_HAPPYKIT:
			return {
				isError: true,
			};

		default:
			return state;
	}
};
