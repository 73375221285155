import React from 'react';
import PropTypes from 'prop-types';

import TwoProductImagesSideBySide from './TwoProductImagesSideBySide';
import ProductImagesCarousel from './ProductImagesCarousel';
import withWindowSize from '../../../components/WindowSize/withWindowSize';
import { DEVICE_WIDTH } from '../../../const';

const MultipleProductImages = ({
	verticalImage2Alt,
	verticalImage2Url,
	verticalImageAlt,
	verticalImageUrl,
	windowSize,
}) => {
	if (!verticalImageUrl || !verticalImage2Url) return null;

	return windowSize.width <= DEVICE_WIDTH.LAPTOP
		? (
			<ProductImagesCarousel
				productImages={[
					{
						url: verticalImageUrl,
						alt: verticalImageAlt,
					},
					{
						url: verticalImage2Url,
						alt: verticalImage2Alt,
					},
				]}
			/>
		)
		: (
			<TwoProductImagesSideBySide
				verticalImage2Alt={verticalImage2Alt}
				verticalImage2Url={verticalImage2Url}
				verticalImageAlt={verticalImageAlt}
				verticalImageUrl={verticalImageUrl}
			/>
		);
};

MultipleProductImages.displayName = 'MultipleProductImages';

MultipleProductImages.propTypes = {
	verticalImage2Alt: PropTypes.string,
	verticalImage2Url: PropTypes.string,
	verticalImageAlt: PropTypes.string,
	verticalImageUrl: PropTypes.string,
	windowSize: PropTypes.object,
};

export default withWindowSize(MultipleProductImages);