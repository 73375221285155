/**
 * Creates a logInModel data object.
 *
 * @param {object} logInModel The logInModel object.
 * @param {string} logInModel.email An email address
 * @param {string} logInModel.password An optional user password (for email users)
 * @param {string} logInModel.ssoToken An optional single sign on access token
 * @param {string} logInModel.ssoType An optional single sign on type (for SSO users)
 * @param {string} logInModel.tempUserToken An optional temp user token for account merging
 * @param {string} logInModel.username An optional user name (for SSO users)
 */
export const logInModel = ({
	email,
	password,
	ssoToken,
	ssoType,
	tempUserToken,
	username,
}) => ({
	email,
	password,
	ssoToken,
	ssoType,
	tempUserToken,
	username,
});
