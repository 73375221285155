import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconChat = (props) => (
	<Svg
		{...props}
		name="chat"
		viewBox="0 0 28 28"
	 >
		<path
			fill="inherit"
			d="M14.137,0c-7.732,0-14,6.268-14,14c0,2.543,0.689,4.922,1.875,6.978c0.139,0.911,0.197,2.811-0.832,3.56
			L0,25.5l1.313,0.736c0.438,0.224,1.111,0.419,1.893,0.419c0.926,0,2.007-0.275,3.036-1.097C8.49,27.097,11.207,28,14.137,28
			c7.731,0,14-6.269,14-14C28.137,6.268,21.868,0,14.137,0z M14.137,26c-3.177,0-5.963-1.105-8.112-3.128l0,0
			c-0.523,0.704-1.005,1.123-1.403,1.377c0.001,0.001,0.002,0.002,0.003,0.003c-0.02,0.012-0.038,0.016-0.058,0.027
			c-0.599,0.367-0.991,0.355-0.991,0.355c0.698-1.759,0.522-3.456,0.327-4.399C2.791,18.416,2.137,16.286,2.137,14
			c0-6.617,5.383-12,12-12s12,5.383,12,12S20.753,26,14.137,26z"
		/>
	</Svg>
);

IconChat.displayName = 'IconChat';

IconChat.propTypes = iconProps;

IconChat.defaultProps = defaultIconProps;

export default IconChat;
