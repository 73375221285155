import React from 'react';
import PropTypes from 'prop-types';

import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

const IconLogoTileTwoTone = (props) => (
	<Svg
		{...props}
		name="logo-tile-two-tone"
		viewBox="0 0 40 40"
	>
		<path
			d="M20.0019 0C14.5702 0 13.8885 0.0237502 11.7551 0.120835C9.62593 0.218336 8.17258 0.555422 6.90091 1.05001C5.58547 1.56085 4.46963 2.24419 3.35795 3.35628C2.24544 4.46796 1.5621 5.58381 1.04959 6.89882C0.553756 8.17092 0.216252 9.62468 0.120418 11.753C0.0250003 13.8864 0 14.5685 0 20.0002C0 25.4319 0.0241669 26.1115 0.120835 28.2449C0.218752 30.3741 0.555839 31.8274 1.05001 33.0991C1.56127 34.4145 2.24461 35.5304 3.3567 36.642C4.46796 37.7546 5.58381 38.4396 6.89841 38.9504C8.17092 39.445 9.62468 39.7821 11.7535 39.8796C13.8868 39.9767 14.5681 40.0004 19.9994 40.0004C25.4315 40.0004 26.1111 39.9767 28.2445 39.8796C30.3736 39.7821 31.8287 39.445 33.1012 38.9504C34.4162 38.4396 35.5304 37.7546 36.6416 36.642C37.7541 35.5304 38.4375 34.4145 38.95 33.0995C39.4417 31.8274 39.7792 30.3736 39.8792 28.2453C39.975 26.1119 40 25.4319 40 20.0002C40 14.5685 39.975 13.8868 39.8792 11.7535C39.7792 9.62427 39.4417 8.17092 38.95 6.89924C38.4375 5.58381 37.7541 4.46796 36.6416 3.35628C35.5291 2.24377 34.4166 1.56043 33.0999 1.05001C31.8249 0.555422 30.3707 0.218336 28.2415 0.120835C26.1082 0.0237502 25.429 0 19.9956 0H20.0019Z"
			fill={props.color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.0876 13H9.15275C8.51608 13 8 13.5161 8 14.1527V22.0594C8 22.6959 8.51608 23.2121 9.15275 23.2121H9.2941V23.226H9.30602C9.66678 23.226 13.2038 23.2267 15.8151 23.2262C16.9608 23.226 18.0595 23.6804 18.8702 24.4897L18.9695 24.589V15.8819C18.9695 14.2903 17.6793 13 16.0876 13Z"
			fill={props.color2}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M23.5865 16.3678H30.8472C31.4838 16.3678 32 16.8838 32 17.5205V25.7689C32 26.4055 31.4838 26.9217 30.8472 26.9217H30.7059V26.9355H30.694C30.3332 26.9355 26.4703 26.9363 23.859 26.9358C22.7134 26.9355 21.6147 27.39 20.8039 28.1992L20.7047 28.2984V19.2497C20.7047 17.658 21.9949 16.3678 23.5865 16.3678Z"
			fill={props.color2}
		/>
	</Svg>
);

IconLogoTileTwoTone.displayName = 'IconLogoTileTwoTone';

IconLogoTileTwoTone.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconLogoTileTwoTone.defaultProps = {
	...defaultIconProps,
	color2: 'white',
};

export default IconLogoTileTwoTone;