import React from 'react';
import PropTypes from 'prop-types';
import deep from 'deep-get-set';

import MaybeTranslate from '../../../../components/Translate/MaybeTranslate';
import connectContext from '../../../../helpers/context/connectContext';
import { Consumer } from '../../../../helpers/context/context';
import { maybeAppStringPropType } from '../../../../model/appString.model';
import './CtaButton.css';

const CtaButton = ({
	className,
	ctaText,
	onCtaClick,
	trackingName
}) => (
	<div className={`${className} instagram-quickflow-page-cta-container`}>
		<button
			className="button instagram-quickflow-page-cta"
			onClick={() => onCtaClick(trackingName)}
		>
			<MaybeTranslate value={ctaText} />
		</button>
	</div>
);

CtaButton.displayName = 'CtaButton';

CtaButton.propTypes = {
	className: PropTypes.string,
	ctaText: maybeAppStringPropType,
	onCtaClick: PropTypes.func,
	trackingName: PropTypes.string,
};

CtaButton.defaultProps = {
	className: '',
	trackingName: '',
};

const mapContextToProps = (context) => ({
	ctaText: deep(context, 'ctaText'),
	onCtaClick: deep(context, 'onCtaClick'),
});

export default connectContext(Consumer, mapContextToProps)(CtaButton);
