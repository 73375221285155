import React from 'react';
import PropTypes from 'prop-types';

import './PressItem.css';

export const PressItem = ({ children, logo, publisher }) => (
	<div className="PressItem flex-container flex-dir-column">
		<div className="flex-child-grow">{children}</div>
		<div className="press-logo show-for-small-only flex-child-shrink">
			<img src={logo} alt={publisher} />
		</div>
	</div>
);

PressItem.displayName = 'PressItem';

PressItem.propTypes = {
	children: PropTypes.node,
	logo: PropTypes.string,
	publisher: PropTypes.string
};

export default PressItem;
