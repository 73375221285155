import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.LOCATION], action) => {
	const { type } = action;

	switch (type) {
		case ACTIONS.SET_LOCATION_PROPS: {
			const queryParams = deep(action, 'queryParams');

			return {
				...state,
				queryParams,
			}
		}
		default:
			return state;
	}
};
