import PropTypes from 'prop-types';

import { appStringModel, maybeAppStringPropType } from './appString.model';

/**
 * Creates a dialogModal data object.
 *
 * @param {object} dialogModal The dialogModal object.
 * @param {string} dialogModal.altImageUrl A dark mode header image
 * @param {string} dialogModal.analyticsEvent An analytics key
 * @param {string} dialogModal.body An app string or literal text
 * @param {object|string} dialogModal.secondaryCta An app string or literal text (Optional)
 * @param {func} dialogModal.secondaryCtaCallback A cancel callback (Optional)
 * @param {object|string} dialogModal.primaryCta An app string or literal text (Optional)
 * @param {func} dialogModal.primaryCtaCallback A confirm callback
 * @param {string} dialogModal.imageUrl A header image
 * @param {bool} dialogModal.isDismissible Can be dismissed by clicking outside modal
 * @param {string} dialogModal.title An app string or literal text (Optional)
 */
export const dialogModalModel = ({
	altImageUrl,
	analyticsEvent,
	body,
	secondaryCta,
	secondaryCtaCallback,
	primaryCta,
	primaryCtaCallback,
	imageUrl,
	isDismissible,
	title,
}) => ({
	altImageUrl,
	analyticsEvent,
	body,
	secondaryCta,
	secondaryCtaCallback,
	primaryCta: primaryCta || appStringModel({ id: 'web.OK' }),
	primaryCtaCallback,
	imageUrl,
	isDismissible,
	title,
});

export const dialogModalPropType = PropTypes.shape({
	altImageUrl: PropTypes.string,
	analyticsEvent: PropTypes.string,
	body: maybeAppStringPropType,
	imageUrl: PropTypes.string,
	isDismissible: PropTypes.bool,
	primaryCta: maybeAppStringPropType,
	primaryCtaCallback: PropTypes.func,
	secondaryCta: maybeAppStringPropType,
	secondaryCtaCallback: PropTypes.func,
	title: maybeAppStringPropType,
});
