import deep from 'deep-get-set';

import { getAddresses, postAddress, deleteAddress, putAddress } from './api/api.actions';
import { receiveEntities } from './entity.actions';
import { LOADING_KEYS, loadingInit, loadingSuccess, loadingError } from './loading.actions';
import { openModalById } from './view.actions';
import { ENTITIES } from '../const/entities/entities';
import { FORM_FIELDS } from '../const/formFields.const';
import { MODAL_NAMES } from '../const/modalNames.const';
import { normalizeAddresses, addressFromJson } from '../model/address.model';
import { modalModel } from '../model/modal.model';

export const loadShippingAddresses = () => (dispatch) => {
	dispatch(loadingInit(LOADING_KEYS.LOAD_SHIPPING_ADDRESSES));

	return dispatch(getAddresses())
		.then((addressesJson) => {
			const normalized = normalizeAddresses(addressesJson.map(addressFromJson));
			const entities = {
				...normalized.entities,
				[ENTITIES.ADDRESS_IDS]: normalized.result,
			};
			dispatch(receiveEntities(entities, loadShippingAddresses.name));
			dispatch(loadingSuccess(LOADING_KEYS.LOAD_SHIPPING_ADDRESSES));

			return addressesJson; // Maintain legacy untransformed data
		})
		.catch((err) => dispatch(loadingError(LOADING_KEYS.LOAD_SHIPPING_ADDRESSES, err)));
};

export const openAddressSelectionModal = (addressSelectionModalData) => (dispatch) => {
	return dispatch(openModalById(modalModel({
		id: MODAL_NAMES.ADDRESS_SELECTION,
		data: addressSelectionModalData,
	})));
};

const openAddressModal = (addressModalModel) => (dispatch) => {
	return dispatch(openModalById(modalModel({
		id: MODAL_NAMES.ADDRESS_EDIT,
		data: addressModalModel,
	})));
};

export const openEditAddressModal = (addressModalModel) => (dispatch) => {
	return dispatch(openAddressModal(addressModalModel));
};

export const openNewAddressModel = (addressModalModel) => (dispatch) => {
	return dispatch(openAddressModal(addressModalModel));
};

export const removeAddress = (id) => (dispatch) => {
	return dispatch(deleteAddress(id))
		.then(() => dispatch(loadShippingAddresses()))
		.catch((err) => {
			dispatch(loadingError(LOADING_KEYS.REMOVE_SHIPPING_ADDRESS, err));
			throw err;
		});
};

export const saveAddress = (form, id) => (dispatch) => {
	dispatch(loadingInit(LOADING_KEYS.SAVE_ADDRESS));

	const address = {
		name: deep(form, `${FORM_FIELDS.ADDRESS_NAME}`),
		street1: deep(form, `${FORM_FIELDS.ADDRESS_STREET_1}`),
		street2: deep(form, `${FORM_FIELDS.ADDRESS_STREET_2}`),
		city: deep(form, `${FORM_FIELDS.ADDRESS_CITY}`),
		state: deep(form, `${FORM_FIELDS.ADDRESS_REGION}`),
		postalCode: deep(form, `${FORM_FIELDS.ADDRESS_POSTAL_CODE}`),
		countryCode: deep(form, `${FORM_FIELDS.ADDRESS_COUNTRY_CODE}`),
	};

	const savePromise = id ? dispatch(putAddress(id, address)) : dispatch(postAddress(address));

	return savePromise
		.then((address) => {
			return dispatch(loadShippingAddresses())
				.then(() => address);
		})
		.catch((err) => {
			dispatch(loadingError(LOADING_KEYS.SAVE_ADDRESS, err));
			throw err;
		});
};
