import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconDisconnected = (props) => {
	const { size } = props;
	const heightRatio = 12;
	const widthRatio = 44;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			height={height}
			width={width}
			viewBox="0 0 44 12"
			fill="none"
			hasStroke={false}
			name="disconnected"
		>
			<path
				d="M44 6.00037C43.9996 5.42173 43.5358 4.95324 42.9629 4.95286L36.1677 4.95324C35.9588 3.74944 35.3958 2.63979 34.5224 1.7575C33.4002 0.624039 31.9085 1.58451e-06 30.3221 0.000745942L26.1812 9.36612e-07C25.906 -0.00037117 25.6426 0.110147 25.4485 0.306252C25.2543 0.502357 25.1442 0.769165 25.1445 1.04714L25.1442 10.9517C25.1445 11.2416 25.2606 11.5025 25.4485 11.6923C25.636 11.8817 25.895 11.9996 26.1812 11.9993L30.3221 12C33.2425 11.9989 35.6718 9.85624 36.1647 7.04751L42.9633 7.04751C43.5362 7.04788 44.0004 6.57901 44 6.00037ZM30.3225 9.90536L27.2187 9.90424L27.2187 2.09427L30.3229 2.09576C31.3552 2.09501 32.3263 2.50136 33.0561 3.23853C33.7863 3.97606 34.1882 4.95733 34.1886 6.00037C34.1871 8.15343 32.4537 9.90424 30.3225 9.90536Z"
				fill="inherit"
			/>
			<path
				d="M21.6259 7.28045L18.4193 7.28045L18.4189 4.71992L21.6248 4.71992C22.1976 4.72029 22.6618 4.25142 22.6615 3.67278C22.6615 3.09451 22.1976 2.62602 21.6248 2.62565L18.4189 2.62565V1.04788C18.4189 0.469608 17.9547 0.000741807 17.3822 0.00074184L13.242 0.000741456C10.3209 0.00111371 7.89128 2.14339 7.39872 4.95324L1.0367 4.95286C0.464194 4.95286 3.24619e-08 5.42173 0 6C-1.62518e-08 6.2895 0.11568 6.55147 0.303199 6.74088C0.491087 6.93066 0.750446 7.0475 1.03707 7.0475L7.39688 7.04787C7.6054 8.2513 8.16796 9.36058 9.04145 10.2429C10.1633 11.376 11.6546 11.9996 13.242 12L17.3822 12C17.9547 12 18.4189 11.5311 18.4189 10.9529L18.4193 9.3762H21.6259C22.1984 9.3762 22.6626 8.90734 22.6626 8.32907C22.6626 7.7508 22.1987 7.28008 21.6259 7.28045ZM13.242 9.90424C12.2098 9.90349 11.2379 9.49789 10.5081 8.76072C9.77827 8.02356 9.37633 7.04229 9.37597 5.99925C9.37597 3.84619 11.1101 2.09464 13.242 2.09427L16.3448 2.09427L16.3448 9.90424L13.242 9.90424Z"
				fill="inherit"
			/>
		</Svg>
	);
};

IconDisconnected.displayName = 'IconDisconnected';

IconDisconnected.propTypes = iconProps;

IconDisconnected.defaultProps = defaultIconProps;

export default IconDisconnected;
