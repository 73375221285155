import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Input.css';

class Input extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: ''
		};

		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
	}

	handleBlur(e) {
		const { onBlur } = this.props;
		onBlur && onBlur(e);
	}

	handleChange(e) {
		const { onChange } = this.props;
		onChange && onChange(e);
	}

	handleFocus(e) {
		const { onFocus } = this.props;
		onFocus && onFocus(e);
	}

	render() {
		return (
			<input
				className={`input-chatponent ${this.props.className}`}
				name={this.props.name}
				type={this.props.type}
				value={this.props.value}
				autoComplete={this.props.autoComplete}
				placeholder={this.props.placeholder}
				onBlur={this.handleBlur}
				onChange={this.handleChange}
				onFocus={this.handleFocus}
			/>
		);
	}
};

Input.displayName = 'Input';

Input.propTypes = {
	autoComplete: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.string,
};

Input.defaultProps = {
	autoComplete: 'on',
	className: '',
	type: 'text',
};

export default Input;
