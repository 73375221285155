import config from '../helpers/config';

const kochavaConfig = config.get('analytics.kochava');

if (!process.env.SERVER && kochavaConfig.enabled) {
	// Add Kochava tracking snippet to head element // details: https://support.kochava.com/sdk-integration/web-sdk-integration

	const script = document.createElement('script');
	script.type = 'text/javascript';
	const versionControl = 'v1';
	const autoPage = false;
	const cache = true;
	const verbose = kochavaConfig.verbose;
	script.innerText =
		'!function(a,b,c,d,e){var f=window.kochava=window.kochava||[];if(f.loaded)return void(window.console&&console.error&&console.error("Kochava snippet already included"));f.loaded=!0,f.methods=["page","identify","activity","conversion","init"],stub=function(a){return function(){var b=Array.prototype.slice.call(arguments);return b.unshift(a),f.push(b),f}};for(var g=0;g<f.methods.length;g++){var h=f.methods[g];f[h]=stub(h)}f.init((new Date).getTime(),a,e),function(){var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=("https:"===document.location.protocol?"https://":"http://")+"assets.kochava.com/kochava.js/"+b+"/kochava.min.js",d||(a.src=a.src+"?c="+Math.random());var c=document.getElementsByTagName("script")[0];c.parentNode.insertBefore(a,c)}(),c&&f.page()}("' +
		kochavaConfig.appGuid +
		'","' +
		versionControl +
		'",' +
		autoPage +
		',' +
		cache +
		',' +
		verbose +
		');';
	document.head.appendChild(script);
}
