import deep from 'deep-get-set';

export const checkoutErrorFromJson = (json = {}) => ({
	cartId: deep(json, 'CartId'),
	message: deep(json, 'Message'),
	detailedMessage: deep(json, 'DetailedMessage'),
	errorFlags: deep(json, 'ErrorFlags'),
	itemErrors: deep(json, 'ItemErrors'),
	presentation: {
		cartId: deep(json, 'Presentation.CartId'),
		flags: deep(json, 'Presentation.Flags'),
		errors: (deep(json, 'Presentation.Errors') || []).map((error) => ({
			message: deep(error, 'Message'),
			callToAction: {
				displayText: deep(error, 'CallToAction.DisplayText'),
				actionUri: deep(error, 'CallToAction.ActionUri'),
			},
		})),
		itemized: (deep(json, 'Presentation.Itemized') || []).map((itemized) => ({
			itemId: deep(itemized, 'ItemId'),
			flags: deep(itemized, 'Flags'),
			errors: (deep(itemized, 'Errors') || []).map((error) => ({
				message: deep(error, 'Message'),
				callToAction: {
					displayText: deep(error, 'CallToAction.DisplayText'),
					actionUri: deep(error, 'CallToAction.ActionUri'),
				},
			})),
		})),
	},
});
