import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AccordianItem from '../Accordian/AccordianItem';
import * as analyticsActions from '../../../../actions/analytics.actions';

const Faq = ({
	analyticsActions,
	text,
	title,
}) => (
	<AccordianItem
		title={title}
		renderContent={() => text}
		onOpen={() => analyticsActions.triggerAnalyticsEvent('IGSeriesLanding_Expand', {
			attr1: title
		})}
	/>
);

Faq.propTypes = {
	analyticsActions: PropTypes.object,
	text: PropTypes.string,
	title: PropTypes.string,
};

Faq.displayName = 'Faq';

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
});

export default connect(
	null,
	mapDispatchToProps,
)(Faq);
