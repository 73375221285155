import deep from 'deep-get-set';
import { schema } from 'normalizr';

import { priceFromJson } from './price.model';
import { BOOK_CREATION_MODEL_TYPES } from '../const/bookCreationModelTypes.const';
import { ENTITIES } from '../const/entities/entities';
import { objectSafeParse } from '../helpers/objects/objects';

export const productFromJson = (json = {}) => {
	const id = deep(json, 'ID');
	const attributes = objectSafeParse(deep(json, 'Attributes'));
	const pricing = deep(json, 'Pricing');

	return {
		attributes,
		bookCreationModelType: deep(json, 'BookCreationModelType'),
		description: deep(json, 'Description'),
		id: id && String(id),
		isDefault: deep(json, 'IsDefault') || false,
		isSubscription: deep(json, 'IsSubscription') || false,
		isShippable: deep(json, 'IsShippable') || false,
		name: deep(json, 'Name'),
		pricing: pricing && priceFromJson(pricing),
		sku: deep(json, 'SKU'),
		supportsGiftNotes: deep(json, 'SupportsGiftNotes'),
		variableShippingDescription: deep(json, 'VariableShippingDescription'),
	};
};

export const productsFromJsonArray = (jsonArray) => (jsonArray || []).map(productFromJson);

export const productSchema = new schema.Entity(ENTITIES.PRODUCT);

export const productsSchema = new schema.Array(productSchema);


const getBookCreationType = (product) => deep(product, 'bookCreationModelType');

export const getCardsPerPack = (product) => deep(product, `attributes.cardsPerPack`) || 20;

export const getCategory = (product) => {
	const modelType = getBookCreationType(product);

	const PHOTO_BOOKS = 'photo_books';
	const WALL_DECOR = 'wall_decor';
	const PHOTO_CARDS = 'photo_cards';
	const SUBSCRIPTION_PHOTO_BOOK = 'subscription_photo_books';
	const OTHER = 'other';

	const bookCreationModelTypeToCategoryMap = {
		[BOOK_CREATION_MODEL_TYPES.SIXTY_PAGE_SERIES]: SUBSCRIPTION_PHOTO_BOOK,
		[BOOK_CREATION_MODEL_TYPES.CUSTOM_BOOK]: PHOTO_BOOKS,
		[BOOK_CREATION_MODEL_TYPES.CUSTOM_PRINTS]: OTHER,
		[BOOK_CREATION_MODEL_TYPES.GIFT_CARD]: OTHER,
		[BOOK_CREATION_MODEL_TYPES.CARDS]: PHOTO_CARDS,
		[BOOK_CREATION_MODEL_TYPES.BOOK_SUBSCRIPTION_GROUP]: SUBSCRIPTION_PHOTO_BOOK, // Monthly mini sub
		[BOOK_CREATION_MODEL_TYPES.SUBSCRIPTION_BOOK]: PHOTO_BOOKS, // Monthly mini individual book
		[BOOK_CREATION_MODEL_TYPES.ACCESSORY]: OTHER,
		[BOOK_CREATION_MODEL_TYPES.WALL_PRINT]: WALL_DECOR,
		[BOOK_CREATION_MODEL_TYPES.LAYFLAT]: PHOTO_BOOKS,
		[BOOK_CREATION_MODEL_TYPES.GALLERY_WALL_TILE]: WALL_DECOR,
	}

	return bookCreationModelTypeToCategoryMap[modelType] || OTHER;
};

export const getName = (product) => deep(product, 'name') || '';

export const getSku = (product) => deep(product, 'sku');

export const getVariableShippingDescription = (product) => deep(product, 'variableShippingDescription') || '';
