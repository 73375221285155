import { popupWindowOptions } from '../../helpers/window/window.helper';

export const O_AUTH_2_SUCCESS_CALLBACK = 'O_AUTH_2_SUCCESS_CALLBACK';
export const O_AUTH_2_FAILURE_CALLBACK = 'O_AUTH_2_FAILURE_CALLBACK';

/**
 * Opens an authentication window.
 * Sets up callback functions on this window so the child window can communicate back.
 *
 * @param {string} url The authorization url to use.
 */
export const authenticate = (
	url,
	{ windowOptions = popupWindowOptions() } = {}
) => {
	return new Promise((resolve, reject) => {

		const authWindow = window.open(url, 'Authentication', windowOptions);

		if (!authWindow) {
			return reject({
				error: 'fail',
				error_reason: 'window_blocked',
				error_description: 'Could not open the Instagram authentication window.',
			});
		}

		let receivedMessage = false;

		const cleanupAuthWindowCallbacks = setupAuthWindowCallbacks({
			resolve,
			reject,
			receivedCallback: () => receivedMessage = true
		});

		const windowClosedWatcher = setInterval(() => {
			if (!authWindow || authWindow.closed) {
				clearInterval(windowClosedWatcher);

				if (!receivedMessage) {
					cleanupAuthWindowCallbacks();
					reject({
						error: 'fail',
						error_reason: 'user_action',
						error_description: 'The auth window was closed.'
					});
				}
			}
		}, 500);
	});
};

/**
 * Sets up some callback functions on the window so the child authorization window can call it
 * when a response has come back from Instagram.
 *
 * @param {object} namedParams The named parameters for this function.
 * @param {funcion} resolve A promise resolve to be called upon successful auth.
 * @param {function} reject A promise reject to be called upon auth failure.
 * @param {function} receivedCallback A callback when an answer has been received.
 */
export const setupAuthWindowCallbacks = ({ resolve, reject, receivedCallback }) => {
	const cleanupAuthWindowCallbacks = () => {
		delete window[O_AUTH_2_SUCCESS_CALLBACK];
		delete window[O_AUTH_2_FAILURE_CALLBACK];
	};

	const handleAuthCallback = (resolveOrReject, result) => {
		receivedCallback();
		cleanupAuthWindowCallbacks();
		resolveOrReject(result);
	};

	window[O_AUTH_2_SUCCESS_CALLBACK] = (result) => handleAuthCallback(resolve, result);
	window[O_AUTH_2_FAILURE_CALLBACK] = (errorParms, caughtError) => {
		if (caughtError) console.error('Failed to authenticate', caughtError);
		handleAuthCallback(reject, errorParms);
	}

	return cleanupAuthWindowCallbacks;
};
