import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProductImages from './ProductImages/ProductImages';
import PillButton from '../../components/Button/PillButton/PillButton';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import withWindowSize from '../../components/WindowSize/withWindowSize';
import { COLOR, DEVICE_WIDTH, mediaQueryBetween, mediaQueryStartingFrom, mediaQueryUpTo, SPACING, WIDTH_CONSTRAINT } from '../../const';

const ProductDescriptionDiv = styled(PageFlowContainer)`
	display: grid;
	gap: ${SPACING.MEDIUM};
	padding-bottom: ${SPACING.BIGGER};

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		grid-template-columns: 1fr;
	}
	${mediaQueryBetween(DEVICE_WIDTH.TABLET, DEVICE_WIDTH.LAPTOP)} {
		grid-template-columns: minmax(0, 2fr) minmax(0, 2fr);
		padding-top: ${SPACING.BIGGER};
		gap: ${SPACING.SMALLER};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.LAPTOP)} {
		grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
		padding-top: ${SPACING.BIGGER};
	}
`;

const ProductTextContainer = styled.div`
	${mediaQueryUpTo(WIDTH_CONSTRAINT)} {
		padding-left: ${SPACING.BIGGER};
		padding-right: ${SPACING.BIGGER};
	}
`;

const ProductNameH1 = styled.h1`
	color: #333333;
	font-size: 32px;
	font-weight: 700;
	letter-spacing: -0.5px;
	line-height: 41px;
	max-width: 350px;
	text-align: left;
`;

const ProductPricing = styled.div`
	color: ${COLOR.RED_300};
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.005em;
	line-height: 20px;
	padding-bottom: 10px;
	text-align: left;
`;

const ProductDescriptionTextDiv = styled.div`
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0.25px;
	max-width: 300px;
	text-align: left;
	color: #5C5C5C;
	padding-bottom: 10px;
`;

const ProductDetailListUl = styled.ul`
	color: #5C5C5C;
	font-size: 14px;
	font-weight: 450;
	letter-spacing: 0.005em;
	line-height: 20px;
	max-width: 380px;
	padding-bottom: 10px;
	text-align: left;
`;

const ProductDescription = ({
	linkCallback,
	productCta,
	productCtaColor,
	productCtaHoverColor,
	productDescription,
	productDetails,
	horizontalProductImageAlt,
	horizontalProductImageUrl,
	verticalProductImage2Alt,
	verticalProductImage2Url,
	verticalProductImageAlt,
	verticalProductImageUrl,
	productLink,
	productName,
	productPricing,
}) => (
	<ProductDescriptionDiv
		isInset={false}>
		<ProductImages
			horizontalImageAlt={horizontalProductImageAlt}
			horizontalImageUrl={horizontalProductImageUrl}
			verticalImage2Alt={verticalProductImage2Alt}
			verticalImage2Url={verticalProductImage2Url}
			verticalImageAlt={verticalProductImageAlt}
			verticalImageUrl={verticalProductImageUrl}/>
		<ProductTextContainer>
			<ProductNameH1>
				{productName}
			</ProductNameH1>
			<ProductPricing>
				{productPricing}
			</ProductPricing>
			<ProductDescriptionTextDiv>
				{productDescription}
			</ProductDescriptionTextDiv>
			<ProductDetailListUl>
				{productDetails.map((detail, index) => (
					<li key={index}>{detail.detail}</li>
				))}
			</ProductDetailListUl>
			<PillButton
				marginBottom={SPACING.MEDIUM}
				borderColor={productCtaColor}
				backgroundColor={productCtaColor}
				hoverBackgroundColor={productCtaHoverColor}
				onClick={() => linkCallback(productLink)}>
				{productCta}
			</PillButton>
		</ProductTextContainer>
	</ProductDescriptionDiv>
);

ProductDescription.displayName = 'ProductDescription';

ProductDescription.propTypes = {
	linkCallback: PropTypes.func,
	productCta: PropTypes.string,
	productCtaColor: PropTypes.string,
	productCtaHoverColor: PropTypes.string,
	productDescription: PropTypes.string,
	productDetails: PropTypes.array,
	horizontalProductImageAlt: PropTypes.string,
	horizontalProductImageUrl: PropTypes.string,
	verticalProductImage2Alt: PropTypes.string,
	verticalProductImage2Url: PropTypes.string,
	verticalProductImageAlt: PropTypes.string,
	verticalProductImageUrl: PropTypes.string,
	productLink: PropTypes.string,
	productName: PropTypes.string,
	productPricing: PropTypes.string,
};

export default withWindowSize(ProductDescription);
