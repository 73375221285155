import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconInfo = (props) => (
	<Svg
		{...props}
		name="info"
		viewBox="0 0 17 17"
	>
		<path
			d="M8.5 16.3281C12.8594 16.3281 16.4688 12.7109 16.4688 8.35938C16.4688 4 12.8516 0.390625 8.49219 0.390625C4.14062 0.390625 0.53125 4 0.53125 8.35938C0.53125 12.7109 4.14844 16.3281 8.5 16.3281ZM8.42188 5.50781C7.83594 5.50781 7.36719 5.03906 7.36719 4.44531C7.36719 3.85156 7.83594 3.38281 8.42188 3.38281C9.00781 3.38281 9.46875 3.85156 9.46875 4.44531C9.46875 5.03906 9.00781 5.50781 8.42188 5.50781ZM7.08594 12.8047C6.75 12.8047 6.49219 12.5625 6.49219 12.2266C6.49219 11.9141 6.75 11.6562 7.08594 11.6562H8.07812V7.98438H7.21875C6.89062 7.98438 6.63281 7.74219 6.63281 7.40625C6.63281 7.09375 6.89062 6.83594 7.21875 6.83594H8.73438C9.14844 6.83594 9.36719 7.13281 9.36719 7.57031V11.6562H10.3594C10.6953 11.6562 10.9531 11.9141 10.9531 12.2266C10.9531 12.5625 10.6953 12.8047 10.3594 12.8047H7.08594Z"
			fill="inherit"
		/>
	</Svg>
);

IconInfo.displayName = 'IconInfo';

IconInfo.propTypes = iconProps;

IconInfo.defaultProps = defaultIconProps;

export default IconInfo;
