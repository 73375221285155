import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLock = (props) => (
	<Svg
		{...props}
		name="lock"
		viewBox="0 0 32 32"
	>
		<path
			fill="inherit"
			d="M27,15v-4.091C27,4.884,22.025,0,16,0S5,4.884,5,10.909V15H2v17h28V15H27z M11,10.909 c0-2.807,2.193-5.091,5-5.091s5,2.284,5,5.091V15H11V10.909z"
		/>
		<rect x="47.105" y="15.413" fill="inherit" width="19" height="12.392" />
		<g>
			<path fill="inherit" d="M56.569,9.232c1.93,0,3.5,1.57,3.5,3.5v5.205c0,1.93-1.57,3.5-3.5,3.5s-3.5-1.57-3.5-3.5v-5.205
				C53.069,10.803,54.64,9.232,56.569,9.232 M56.569,5.232c-4.142,0-7.5,3.358-7.5,7.5v5.205c0,4.143,3.358,7.5,7.5,7.5
				c4.143,0,7.5-3.357,7.5-7.5v-5.205C64.069,8.59,60.712,5.232,56.569,5.232L56.569,5.232z" />
		</g>
	</Svg>
);

IconLock.displayName = 'IconLock';

IconLock.propTypes = iconProps;

IconLock.defaultProps = defaultIconProps;

export default IconLock;
