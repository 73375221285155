import { apiGetData, apiGet, apiPostRecieveData } from './api.http.actions';
import requireParams from '../../helpers/requireParams';
import { productPriceTiersFromJson } from '../../model/productPriceTier.model';
import { queryStringFromJson } from '../../helpers/locationUtils';

export const getProductsGiftCard = (giftCardAmount) => (dispatch) => {
	return dispatch(apiGet(`/products/giftcodes?${queryStringFromJson({ giftCardAmount })}`));
};

export const postProductPrice = (
	productId,
	couponCode,
	groupId,
	addressId,
	quantity,
	shippingOptionId,
	sharableId,
	sharableKey,
) => (dispatch) => {
	requireParams({ productId, quantity }, postProductPrice.name);

	return dispatch(apiPostRecieveData('/products/price', {
		id: productId,
		couponCode,
		groupId,
		addressId,
		quantity,
		shippingOptionId,
		sharableId,
		sharableKey,
	}))
		.then(({ error, price, success }) => {
			if (!success) throw new Error(error);

			return price;
		});
};

export const getProductPriceTiers = (productId) => (dispatch) => {
	return dispatch(apiGetData(`/products/${productId}/tieredprices`))
		.then(({ tieredPrices = [] }) => productPriceTiersFromJson(tieredPrices));
};

export const getProductSingles = ({ bookId, filter, sharableId, sharableKey } = {}) => (dispatch) => {
	return dispatch(apiGet(`/products/singles?${queryStringFromJson({ bookId, filter, sharableId, sharableKey })}`));
};

export const getProductsSubscriptions = ({ groupId, filter, sharableId, sharableKey }) => (dispatch) => {
	return dispatch(apiGetData(`/products/subscriptions?${queryStringFromJson({ groupId, filter, sharableId, sharableKey })}`));
};

export const getUpgradePrices = () => (dispatch) => {
	return dispatch(apiGetData('/products/upgradePrices'));
};