import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../../Icon/Icon';
import './PasswordField.css';

class PasswordField extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			value: ''
		};

		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.inputRef = props.inputRef || React.createRef();
	}

	handleBlur(e) {
		const { onBlur } = this.props;
		onBlur && onBlur(e);
	}

	handleChange(e) {
		const { onChange } = this.props;
		const { value } = e.target;
		this.setState({ value });
		onChange && onChange(e);
	}

	handleClick(e) {
		this.setState((prevState, props) => {
			const show = !prevState.show;
			const { onShow } = props;
			onShow && onShow(show);

			return { show };
		});
	}

	handleFocus(e) {
		const { onFocus } = this.props;
		onFocus && onFocus(e);
	}

	render() {
		const { autoComplete, className, id, inputClassName, name, placeholder, showClassName } = this.props;
		const { show, value } = this.state;
		const type = show ? 'text' : 'password';
		const button = value ? (
			<button
				className={classnames('show-password-button button large clear icon', showClassName)}
				aria-label={show ? 'Hide password' : 'Show password'}
				type="button"
				onClick={this.handleClick}
			>
				<Icon name={show ? 'eye' : 'eye-slash'} />
			</button>
		) : null;
		const ariaProps = ['aria-describedby', 'aria-errormessage', 'aria-invalid'].reduce((obj, key) => {
			return this.props.hasOwnProperty(key)
				? {
					...obj,
					[key]: this.props[key]
				}
				: obj;
		}, {});

		return (
			<div className={classnames('PasswordField', show ? 'visible' : 'masked', className)}>
				<input
					id={id}
					ref={this.inputRef}
					className={inputClassName}
					name={name}
					type={type}
					value={value}
					autoComplete={autoComplete}
					placeholder={placeholder}
					onBlur={this.handleBlur}
					onChange={this.handleChange}
					onFocus={this.handleFocus}
					{...ariaProps}
				/>
				{button}
			</div>
		);
	}
}

PasswordField.displayName = 'PasswordField';

PasswordField.propTypes = {
	autoComplete: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,
	inputClassName: PropTypes.string,
	inputRef: PropTypes.object,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onShow: PropTypes.func,
	placeholder: PropTypes.string,
	showClassName: PropTypes.string
};

export default PasswordField;
