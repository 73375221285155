import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { addressFromJson, addressSchema } from './address.model';
import { cartItemFromJson, cartItemSchema } from './cartItem.model';
import { couponCodeFromJson } from './couponCode.model';
import { paymentMethodFromJson, paymentMethodSchema } from './paymentMethod.model';
import { priceFromJson } from './price.model';
import { shippingGradeFromJson, shippingGradeSchema } from './shippingGrade.model';
import { ENTITIES } from '../const/entities/entities';

export const masterOrderFromJson = (json = {}) => {
	const paymentMethod = deep(json, 'PaymentMethod');
	const shippingAddress = deep(json, 'ShippingAddress');

	return {
		couponCode: couponCodeFromJson(deep(json, 'CouponCode')),
		id: String(deep(json, 'Id')),
		isFirstOrder: deep(json, 'IsFirstOrder'),
		items: (deep(json, 'Items') || []).map(cartItemFromJson),
		giftNote: deep(json, 'GiftNote'),
		paymentMethod: paymentMethod && paymentMethodFromJson(paymentMethod),
		price: priceFromJson(deep(json, 'Price')),
		shippingAddress: shippingAddress && addressFromJson((shippingAddress)),
		shippingGrade: shippingGradeFromJson(deep(json, 'ShippingGrade')),
	};
};

export const normalizeMasterOrder = (cart) => {
	return normalize(cart, masterOrderSchema)
}

const masterOrderSchema = new schema.Entity(ENTITIES.MASTER_ORDER, {
	items: [cartItemSchema],
	paymentMethod: paymentMethodSchema,
	shippingAddress: addressSchema,
	shippingGrade: shippingGradeSchema,
});
