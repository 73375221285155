import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { groupFromJson, groupSchema } from './group.model';

export const groupsSkinnyResponseModel = (json = {}) => {
	const group = groupFromJson(deep(json, 'group'));

	return {
		groupId: group.id,
		group,
	};
};

export const groupsSkinnyResponseSchema = new schema.Entity('ignoreEntity', {
	group: groupSchema,
}, {
	idAttribute: 'groupId',
});

export const normalizeGroupSkinnyResponse = (response) => {
	return normalize(response, groupsSkinnyResponseSchema);
};
