import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { isReceiveEntitiesAction } from '../actions/entity.actions';
import { ENTITIES } from '../const/entities/entities';
import { REDUCERS } from '../const/reducers.const';
import { receiveEntityObjects } from '../helpers/entity/entity.helpers';
import { momentFromJson } from '../model/moment.model';

export default (state = defaultState[REDUCERS.BOOK], action) => {
	const { type } = action;

	if (isReceiveEntitiesAction(type)) {
		const { data } = action;

		return {
			...state,
			...receiveEntityObjects(state, data, ENTITIES.BOOK),
		};
	}

	switch (type) {
		case ACTIONS.MOMENTS_DELETE: {
			const toDeleteIds = deep(action, 'momentIds') || [];
			const bookId = deep(action, 'bookId');
			let bookMap = deep(state, `${ENTITIES.BOOK}`) || {};

			const removeMomentIds = (books, bookId, momentIds) => {
				const book = deep(books, `${bookId}`) || {};
				const originalMomentIds = deep(book, 'momentIds') || [];
				const updatedMomentIds = originalMomentIds.filter((mId) => !momentIds.includes(mId));

				return {
					...books,
					[bookId]: {
						...book,
						momentIds: updatedMomentIds,
					}
				};
			};

			if (!bookId) {
				bookMap = Object.keys(bookMap).reduce((books, id) => {
					return removeMomentIds(books, id, toDeleteIds);
				}, bookMap);
			} else {
				bookMap = removeMomentIds(bookMap, bookId, toDeleteIds);
			}

			return {
				...state,
				[ENTITIES.BOOK]: bookMap
			};
		}

		case ACTIONS.MOMENTS_RECEIVE: {
			const receivedMoments = deep(action, 'moments') || [];
			const bookId = deep(action, 'bookId');
			const momentIds = receivedMoments
				.map(momentFromJson)
				.map((moment) => moment.id);

			return {
				...state,
				[ENTITIES.BOOK]: {
					[bookId]: {
						...state[ENTITIES.BOOK][bookId],
						momentIds
					}
				}
			}
		}

		case ACTIONS.SET_BOOK_COVER_MEDIA: {
			const bookId = deep(action, 'bookId');
			const coverMediaId = deep(action, 'coverMediaId');

			return {
				...state,
				[ENTITIES.BOOK]: {
					...state[ENTITIES.BOOK],
					[bookId]: {
						...state[ENTITIES.BOOK][bookId],
						coverMediaId,
					}
				}
			};
		}

		default:
			return state;
	}
}