import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLocation = (props) => (
	<Svg
		{...props}
		name="location"
		viewBox="0 0 21 30"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-272.000000, -578.000000)">
				<g transform="translate(264.000000, 575.000000)">
					<path
						d="M18.5,3 C12.695,3 8,7.695 8,13.5 C8,21.375 18.5,33 18.5,33 C18.5,33 29,21.375 29,13.5 C29,7.695 24.305,3 18.5,3 Z M18.5,17.25 C16.43,17.25 14.75,15.57 14.75,13.5 C14.75,11.43 16.43,9.75 18.5,9.75 C20.57,9.75 22.25,11.43 22.25,13.5 C22.25,15.57 20.57,17.25 18.5,17.25 Z"
						fill="inherit"
						fillRule="nonzero"
					/>
				</g>
			</g>
		</g>
	</Svg>
);

IconLocation.displayName = 'IconLocation';

IconLocation.propTypes = iconProps;

IconLocation.defaultProps = defaultIconProps;

export default IconLocation;
