import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';
import { momentFromJson, momentSchema } from './moment.model';

export const localMediaFileFromJson = (json = {}) => {
	const momentId = deep(json, 'MomentId');
	const groupId = deep(json, 'ChatgroupId');

	return {
		id: deep(json, 'ID'),
		isUploaded: deep(json, 'IsUploaded'),
		momentId: momentId && String(momentId),
		moment: momentFromJson(deep(json, 'Moment')),
		groupId: groupId && String(groupId),
	};
};

export const localMediaFilesFromJson = (jsonArray = []) => jsonArray.map(localMediaFileFromJson);

export const localMediaFileSchema = new schema.Entity(ENTITIES.LOCAL_MEDIA_FILE, { moment: momentSchema });
export const localMediaFilesSchema = new schema.Array(localMediaFileSchema);

export const normalizeLocalMediaFiles = (localMediaFiles) => normalize(localMediaFiles, localMediaFilesSchema);
