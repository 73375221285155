import deep from 'deep-get-set';

import { currencyMarketModel } from './currencyMarket.model';
import { priceSummaryFromJson } from './priceSummary.model';
import { getPromoLabel, promosFromJsonArray } from './promo.model';
import { getPromoDiscountSubtotal, promoDiscountFromJson } from './promoDiscount.model';

export const priceFromJson = (json = {}) => ({
	calculatedShippingOptions: {
		estimatedRange: deep(json, 'CalculatedShippingOption.EstimatedRange'),
		price: deep(json, 'CalculatedShippingOption.Price'),
		shippingText: deep(json, 'CalculatedShippingOption.PriceText'),
	},
	creditApplied: deep(json, 'CreditApplied'),
	couponCode: deep(json, 'CouponCode'),
	couponDiscount: deep(json, 'CouponDiscount'),
	coverUpgradePrice: deep(json, 'CoverUpgradePrice'),
	market: currencyMarketModel(deep(json, 'Market')),
	promoDiscount: promoDiscountFromJson(deep(json, 'PromoDiscount')),
	promos: promosFromJsonArray(deep(json, 'Promos')),
	retailDeliveryFee: deep(json, 'RetailDeliveryFee'),
	shipping: deep(json, 'Shipping'),
	subtotal: deep(json, 'SubTotal'),
	summary: priceSummaryFromJson(deep(json, 'Summary')),
	tax: deep(json, 'Tax'),
	total: deep(json, 'Total'),
	totalDiscount: {
		shipping: deep(json, 'TotalDiscount.Shipping') || 0,
		subtotal: deep(json, 'TotalDiscount.SubTotal') || 0,
	},
});

export const getPriceDiscountSubtotal = (price) => deep(price, 'totalDiscount.subtotal');

export const getPricePromoDiscount = (price) => getPromoDiscountSubtotal(deep(price, 'promoDiscount'));

const getPricePromos = (price) => deep(price, 'promos') || [];

export const getPricePromoLabels = (price) => {
	return getPricePromos(price).map((promo) => getPromoLabel(promo));
};
