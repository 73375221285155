import React from 'react';
import PropTypes from 'prop-types';

import bullet1 from './bullet-1.png';
import bullet2 from './bullet-2.png';
import bullet3 from './bullet-3.png';
import './Bullet.css';

const bulletArray = [bullet1, bullet2, bullet3];

const Bullet = ({
	index,
	title,
	text,
}) => (
	<li className="bullet">
		{index < bulletArray.length &&
			<img
				alt={`bullet-${index}`}
				className="bullet-image"
				src={bulletArray[index]}
			/>
		}
		<div className="bullet-content">
			<p className="title">{title}</p>
			<p className="text">{text}</p>
		</div>
	</li>
);

Bullet.propTypes = {
	index: PropTypes.number,
	title: PropTypes.string,
	text: PropTypes.string,
};

export default Bullet;
