import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCircleEmpty = (props) => (
	<Svg
		{...props}
		name="circle-empty"
		viewBox="0 0 25 25"
	>
		<path
			d="M12.4883 24.668C19.1328 24.668 24.6172 19.1836 24.6172 12.5508C24.6172 5.90625 19.1211 0.421875 12.4766 0.421875C5.84375 0.421875 0.371094 5.90625 0.371094 12.5508C0.371094 19.1836 5.85547 24.668 12.4883 24.668ZM12.4883 22.2422C7.10938 22.2422 2.80859 17.918 2.80859 12.5508C2.80859 7.17188 7.10938 2.84766 12.4766 2.84766C17.8555 2.84766 22.1797 7.17188 22.1914 12.5508C22.2031 17.918 17.8672 22.2422 12.4883 22.2422Z"
			fill="inherit"
		/>
	</Svg>
);

IconCircleEmpty.displayName = 'IconCircleEmpty';

IconCircleEmpty.propTypes = iconProps;

IconCircleEmpty.defaultProps = defaultIconProps;

export default IconCircleEmpty;
