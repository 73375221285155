import * as appCookieActions from './appCookie.actions';
import * as dataSourceActions from './dataSource/dataSource.actions';
import * as instagramActions from './instagram.actions';
import { loadingClearErrors } from './loading.actions';
import * as viewActions from './view.actions';
import { MODAL_NAMES } from '../const/modalNames.const';
import { reauthReminderSnoozedCookie } from '../helpers/instagram/instagram.helpers';
import * as DataSource from '../model/dataSource/dataSource.model';
import { modalModel } from '../model/modal.model';
import { selectNextInstagramLegacyDataSourceToReauth } from '../selectors/instagramLegacy.selectors';

export const closeReauthModal = () => (dispatch) => {
	dispatch(viewActions.closeModalById(MODAL_NAMES.INSTAGRAM_LEGACY_REAUTH));
};

export const maybeOpenReauthModal = () => (dispatch, getState) => {
	return dispatch(dataSourceActions.loadDataSources())
		.then(() => {
			dispatch(loadingClearErrors());
			const legacyInstagramDataSource = selectNextInstagramLegacyDataSourceToReauth(getState());

			if (legacyInstagramDataSource) {
				dispatch(viewActions.openModalSingleton(modalModel({
					id: MODAL_NAMES.INSTAGRAM_LEGACY_REAUTH,
					data: { dataSource: legacyInstagramDataSource },
				})));
			}
		});
};

export const reconnectLegacyInstagram = (instagramLegacyDataSource) => (dispatch) => {
	return dispatch(instagramActions.reconnectInstagram(instagramLegacyDataSource));
};

export const snoozeReauth = (dataSource) => (dispatch) => {
	dispatch(appCookieActions.setAppCookie(reauthReminderSnoozedCookie(DataSource.getUsername(dataSource)), new Date().toString()));
	dispatch(closeReauthModal());
};
