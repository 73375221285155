import React from 'react';
import PropTypes from 'prop-types';

export const H6 = ({
	children,
	className,
}) => {
	if (!children) return null;

	return (
		<h6
			className={className}
		>
			{children}
		</h6>
	);
};

H6.displayName = 'H6';

H6.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default H6;
