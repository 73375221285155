import React from 'react';
import PropTypes from 'prop-types';

import Image from './Image';
import Wrapper from '../Wrapper/Wrapper';
import './ImageGallery.css';

const ImageGallery = ({
	galleryImages,
}) => {
	if (!galleryImages || galleryImages.length === 0) return null;

	return (
		<Wrapper className="duplo-block duplo-block-image-gallery">
			<ul>
				{galleryImages.map((image, index) => (
					<Image
						key={index}
						src={image}
					/>
				))}
			</ul>
		</Wrapper>
	);
};

ImageGallery.propTypes = {
	galleryImages: PropTypes.arrayOf(PropTypes.string),
};

ImageGallery.defaultProps = {
	galleryImages: [],
};

export default ImageGallery;
