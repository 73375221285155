import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ imageUrl }) => {
	if (!imageUrl) return null;

	return (
		<div className="social-image">
			<img src={imageUrl} alt="Social" />
		</div>
	);
};

Image.displayName = 'Image';

Image.propTypes = {
	imageUrl: PropTypes.string
};

export default Image;
