import React from 'react';

import { COLOR, IconStars } from '../../../../chatponents';

const Stars = () => (
	<IconStars
		color={COLOR.BLUE_800}
		size="100"
	/>
);

export default Stars;
