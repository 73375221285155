import deep from 'deep-get-set';

import defaultState from './defaultState';
import { isReceiveEntitiesAction } from '../actions/entity.actions';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.API_SETTINGS], action) => {
	const { data, type } = action;

	if (isReceiveEntitiesAction(type)) {
		return {
			...state,
			apiSettings: {
				...state.apiSettings,
				...deep(data, 'apiSettings.apiSettings'),
			}
		};
	}

	switch (type) {
		default:
			return state;
	}
};

