import config from '../helpers/config';

if (!process.env.SERVER && config.get('analytics.mouseFlow.enabled')) {
	window._mfq = window._mfq || [];
	(function() {
		const mf = document.createElement('script');
		mf.type = 'text/javascript';
		mf.async = true;
		mf.src = '//cdn.mouseflow.com/projects/99947ff3-11c3-42ed-9ce3-2085b6e6f356.js';
		document.getElementsByTagName('head')[0].appendChild(mf);
	})();
}
