import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

const IconGoogleLogo = (props) => (
	<Svg
		{...props}
		name="google-logo"
		viewBox="0 0 19 18"
	>
		<g clipPath="url(#clipGoogleLogo)">
			<path fillRule="evenodd" clipRule="evenodd" d="M18.14 9.20468C18.14 8.5665 18.0827 7.95286 17.9764 7.36377H9.5V10.8451H14.3436C14.135 11.9701 13.5009 12.9233 12.5477 13.5615V15.8197H15.4564C17.1582 14.2529 18.14 11.9456 18.14 9.20468Z" fill="#4285F4"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M9.5 18C11.93 18 13.9673 17.1941 15.4564 15.8195L12.5477 13.5613C11.7418 14.1013 10.7109 14.4204 9.5 14.4204C7.15591 14.4204 5.17182 12.8372 4.46409 10.71H1.45728V13.0418C2.93818 15.9831 5.98182 18 9.5 18Z" fill="#34A853"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M4.46409 10.7098C4.28409 10.1698 4.18182 9.59301 4.18182 8.99983C4.18182 8.40664 4.28409 7.82983 4.46409 7.28983V4.95801H1.45727C0.847727 6.17301 0.5 7.54755 0.5 8.99983C0.5 10.4521 0.847727 11.8266 1.45727 13.0416L4.46409 10.7098Z" fill="#FBBC05"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M9.5 3.57955C10.8214 3.57955 12.0077 4.03364 12.9405 4.92545L15.5218 2.34409C13.9632 0.891818 11.9259 0 9.5 0C5.98182 0 2.93818 2.01682 1.45728 4.95818L4.46409 7.29C5.17182 5.16273 7.15591 3.57955 9.5 3.57955Z" fill="#EA4335"/>
		</g>
		<defs>
			<clipPath id="clipGoogleLogo">
				<rect width="18" height="18" fill="white" transform="translate(0.5)"/>
			</clipPath>
		</defs>
	</Svg>
);

IconGoogleLogo.displayName = 'IconGoogleLogo';

IconGoogleLogo.propTypes = iconProps;

IconGoogleLogo.defaultProps = defaultIconProps;

export default IconGoogleLogo;
