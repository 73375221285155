import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MaybeLinkWrap from '../MaybeLinkWrap/MaybeLinkWrap';
import connectContext from '../../helpers/connectContext';
import { Consumer } from '../../helpers/context';
import { CONFIGURABLE_LINK_TYPES } from '../../const';
import { isVisible } from './CarouselItem.helpers';
import './CarouselItem.css';

class CarouselItem extends Component {
	itemRef;

	constructor(props) {
		super(props);

		this.itemRef = React.createRef();
	}

	render() {
		const { caption, imageUrl, title } = this.props;

		const visible = isVisible(this.itemRef.current, this.props.width, this.props.scrollLeft);

		return (
			<div
				ref={this.itemRef}
				className={classnames(
					'CarouselItem',
					{
						visible,
						'gutter': this.props.hasGutter,
					}
				)}
			>
				<MaybeLinkWrap
					analyticEvent={this.props.analyticEvent}
					analyticObj={this.props.analyticObj}
					linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK}
					path={this.props.linkUrl}
				>
					<div className="card">
						<div className="card-section flex-child-shrink">
							<div className="carousel-item-image" style={{ backgroundImage: `url('${imageUrl}')` }} />
						</div>
						<div className="card-section carousel-item-content">
							<strong className="card-title">{title}</strong>
							<div>{caption}</div>
						</div>
					</div>
				</MaybeLinkWrap>
			</div>
		);
	}
}

const mapContextToProps = (context) => {
	const { scrollLeft, width } = context;

	return {
		scrollLeft,
		width
	};
};

CarouselItem.displayName = 'CarouselItem';

CarouselItem.propTypes = {
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	caption: PropTypes.node,
	hasGutter: PropTypes.bool,
	imageUrl: PropTypes.string,
	linkUrl: PropTypes.string,
	scrollLeft: PropTypes.number,
	title: PropTypes.string,
	width: PropTypes.number
};

CarouselItem.defaultProps = {
	hasGutter: true,
};

export default connectContext(Consumer, mapContextToProps)(CarouselItem);
