export const REDUCERS = {
	ADDRESS: 'address',
	ANALYTICS: 'analytics',
	API_SETTINGS: 'apiSettings',
	APP_COOKIE: 'appCookie',
	APP_STRING: 'appString',
	APP: 'app',
	BOOK: 'bookReducer',
	BLOG_POST: 'blogPost',
	BLOG: 'blog',
	BRANCH: 'branch',
	CARD_TEMPLATE: 'cardTemplate',
	CARDS: 'cards',
	CHECKOUT: 'checkout',
	COMMON_HEADER: 'commonHeader',
	CONFIG: 'config',
	FORM: 'form',
	GROUPS: 'groups',
	HAPPYKIT: 'happykit',
	HOME_PAGE: 'homePage',
	LANDING_PAGE: 'landingPage',
	LOADING: 'loading',
	LOCATION: 'location',
	MOMENT: 'momentReducer',
	PAYMENT_METHOD: 'paymentMethod',
	PRICING: 'pricing',
	USER: 'user',
	VIEW: 'view',
};
