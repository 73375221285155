import deep from 'deep-get-set';

import { couponCodeFromJson } from './couponCode.model';
import { giftCardFromJson } from './giftCard.model';

export const codeFromJson = (json = {}) => {
	const couponCode = deep(json, 'CouponCode');
	const giftCard = deep(json, 'GiftCard');

	const model = {
		type: deep(json, 'Type'),
	};

	if  (couponCode) model.couponCode = couponCodeFromJson(couponCode);
	if  (giftCard) model.giftCard = giftCardFromJson(giftCard);

	return model;
};
