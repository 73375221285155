import React, { Component } from 'react';
import { CarouselContext } from './CarouselContext';

export default (WrappedComponent) =>
	class extends Component {
		static displayName = `WithCarouselContext(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
		render() {
			const props = this.props;

			return (
				<CarouselContext.Consumer>
					{({ context, goToPage, startTimer, updateContext }) => (
						<WrappedComponent
							{...props}
							context={context}
							onGoToPage={goToPage}
							onStartTimer={startTimer}
							onUpdateContext={updateContext}
						/>
					)}
				</CarouselContext.Consumer>
			);
		}
	};
