import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './CarouselPaginationButton.css';

const CarouselPaginationButton = ({
	active,
	className,
	color,
	onClick,
}) => (
	<button
		className={classnames(
			'CarouselPaginationButton',
			className,
			{
				active,
			},
		)}
		style={{
			backgroundColor: color,
		}}
		onClick={onClick}
	/>
);

CarouselPaginationButton.displayName = 'CarouselPaginationButton';

CarouselPaginationButton.propTypes = {
	active: PropTypes.bool,
	className: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func
};

export default CarouselPaginationButton;
