import deep from 'deep-get-set';
import { schema } from 'normalizr';

import { currencyModel, currencySchema } from './currency.model';
import { ENTITIES } from '../const/entities/entities';
import { isNullOrUndefined } from '../helpers/bool/bool';

export const currencyMarketModel = (json = {}) => {
	const id = deep(json, 'Id');

	return {
		id: !isNullOrUndefined(id) && String(id),
		name: deep(json, 'Name'),
		flagEmoji: deep(json, 'FlagEmoji'),
		culture: deep(json, 'Culture'),
		currency: currencyModel(deep(json, 'Currency')),
	};
};

export const currencyMarketSchema = new schema.Entity(ENTITIES.CURRENCY_MARKET, {
	currency: currencySchema,
});

export const currencyMarketsSchema = new schema.Array(currencyMarketSchema);
