import { selectIsAwsEnabled, selectAwsAppId, selectAwsPoolId, selectAwsRegion } from '../selectors/config.selectors';
import AwsService from '../helpers/analytics/AWS/AwsService';

export const init = () => (dispatch, getState) => {
	const state = getState();
	const isAwsEnabled = selectIsAwsEnabled(state);
	const awsAppId = selectAwsAppId(state);
	const awsPoolId = selectAwsPoolId(state);
	const awsRegion = selectAwsRegion(state);

	if (isAwsEnabled) AwsService.init(awsAppId, awsPoolId, awsRegion);
};
