import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.COMMON_HEADER], action) => {
	const { data, type } = action;

	switch (type) {
		case ACTIONS.HEADER_REQUEST_COLLAPSE:
			return {
				...state,
				expanded: false,
				openDropdowns: {}
			};

		case ACTIONS.HEADER_TOGGLE_DROPDOWN:
			const isOpen = deep(state, `openDropdowns.${data}`) || false;

			return {
				...state,
				openDropdowns: {
					[data]: !isOpen
				}
			};

		case ACTIONS.HEADER_TOGGLE_EXPANDED: {
			const expanded = !state.expanded;
			const openDropdowns = expanded ? { ...state.openDropdowns } : {};

			return {
				...state,
				expanded,
				openDropdowns
			};
		}
		case ACTIONS.HEADER_SHADOW_HIDE:
			return {
				...state,
				shadowVisible: false
			};
		case ACTIONS.HEADER_SHADOW_SHOW:
			return {
				...state,
				shadowVisible: true
			};
		default:
			return state;
	}
};
