import React from 'react';
import PropTypes from 'prop-types';

export const H1 = ({
	children,
	className,
}) => {
	if (!children) return null;

	return (
		<h1
			className={className}
		>
			{children}
		</h1>
	);
};

H1.displayName = 'H1';

H1.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default H1;
