import React from 'react';

export default (text) =>
	text
		.split('\n')
		.reduce(
			(parts, part, index) => [
				...parts,
				<React.Fragment key={`LineBreaksPart-${index}-1`}>{part}</React.Fragment>,
				<br key={`LineBreaksPart-${index}-2`} />
			],
			[]
		)
		.slice(0, -1);
