import {
	QUICKFLOW_SOURCE_CAMERA_ROLL,
	QUICKFLOW_SOURCE_FACEBOOK,
	QUICKFLOW_SOURCE_FAVORITES,
	QUICKFLOW_SOURCE_INSTAGRAM,
} from '../QuickFlow.const';

export default {
	[QUICKFLOW_SOURCE_CAMERA_ROLL]: [
		{
			text: 'Connect your Camera Roll',
			img: 'https://cdn.chatbooks.com/mk/2020-07-camera-roll-icon.png',
		},
		{
			text: 'Upload 60 photos to create a new photo book in your collection',
			img: 'https://cdn.chatbooks.com/mk/2020-07-book-icon.png',
		},
		{
			text: 'You always have the option to edit, or send to print as is',
			img: 'https://cdn.chatbooks.com/mk/2020-07-mailbox-icon.png',
		},
	],
	[QUICKFLOW_SOURCE_FACEBOOK]: [
		{
			text: 'Connect your Facebook account',
			img: '/assets/img/source_facebook.svg',
		},
		{
			text: 'Your pictures are automatically pulled into 60 page photo books',
			img: 'https://cdn.chatbooks.com/mk/2020-07-book-icon.png',
		},
		{
			text: 'You always have the option to edit, or send to print as is',
			img: 'https://cdn.chatbooks.com/mk/2020-07-mailbox-icon.png',
		},
	],
	[QUICKFLOW_SOURCE_FAVORITES]: [
		{
			text: 'Connect your Camera Roll',
			img: 'https://cdn.chatbooks.com/mk/2020-07-camera-roll-icon.png',
		},
		{
			text: 'Your \'favorited\' photos are automatically pulled into 60 page photo books',
			img: 'https://cdn.chatbooks.com/mk/2020-07-favorites-to-book-icon.png',
		},
		{
			text: 'You always have the option to edit, or send to print as is',
			img: 'https://cdn.chatbooks.com/mk/2020-07-mailbox-icon.png',
		},
	],
	[QUICKFLOW_SOURCE_INSTAGRAM]: [
		{
			text: 'Connect your Instagram account',
			img: 'https://cdn.chatbooks.com/mk/2020-07-IG-icon.png',
		},
		{
			text: 'Your posts are automatically pulled into 60 page photo books',
			img: 'https://cdn.chatbooks.com/mk/2020-07-IG-book.png',
		},
		{
			text: 'You always have the option to edit, or send to print as is',
			img: 'https://cdn.chatbooks.com/mk/2020-07-IG-mailbox.png',
		},
	],
};
