import { combineReducers } from 'redux';

import addressReducer from './address.reducer';
import analyticsReducer from './analytics.reducer';
import apiSettingsReducer from './apiSettings.reducer';
import appCookiesReducer from './appCookie.reducer';
import appStringReducer from './appString.reducer';
import appReducer from './app.reducer';
import userReducer from './user.reducer';
import blogReducer from './blog.reducer';
import blogPostReducer from './blogPost.reducer';
import bookReducer from './book.reducer';
import branchReducer from './branch.reducer';
import cardsReducer from './cards.reducer';
import cardTemplateReducer from './cardTemplate.reducer';
import checkoutReducer from './checkout.reducer';
import commonHeaderReducer from './commonHeader.reducer';
import configReducer from './config.reducer';
import formReducer from './form.reducer';
import groupsReducer from './groups.reducer';
import happykitReducer from './happykit.reducer';
import homePageReducer from './homePage.reducer';
import landingPageReducer from './landingPage.reducer';
import loadingReducer from './loading.reducer';
import locationReducer from './location.reducer';
import momentReducer from './moment.reducer';
import paymentMethodReducer from './paymentMethod.reducer';
import { withCookies, withLocalStorage } from './persist.reducer';
import pricingReducer from './pricing.reducer';
import viewReducer from './view.reducer';
import { REDUCERS } from '../const/reducers.const';

const reducers = (cookieJar) =>
	combineReducers({
		[REDUCERS.ADDRESS]: addressReducer,
		[REDUCERS.ANALYTICS]: analyticsReducer,
		[REDUCERS.API_SETTINGS]: apiSettingsReducer,
		[REDUCERS.APP_COOKIE]: withCookies(cookieJar)('appCookie', null, null)(appCookiesReducer),
		[REDUCERS.APP_STRING]: appStringReducer,
		[REDUCERS.APP]: appReducer,
		[REDUCERS.BLOG_POST]: blogPostReducer,
		[REDUCERS.BLOG]: blogReducer,
		[REDUCERS.BOOK]: bookReducer,
		[REDUCERS.BRANCH]: branchReducer,
		[REDUCERS.CARD_TEMPLATE]: cardTemplateReducer,
		[REDUCERS.CARDS]: cardsReducer,
		[REDUCERS.CHECKOUT]: checkoutReducer,
		[REDUCERS.COMMON_HEADER]: commonHeaderReducer,
		[REDUCERS.CONFIG]: withLocalStorage('config', null, ['apiBaseUrl'])(configReducer()),
		[REDUCERS.FORM]: formReducer,
		[REDUCERS.GROUPS]: groupsReducer,
		[REDUCERS.HAPPYKIT]: happykitReducer,
		[REDUCERS.HOME_PAGE]: homePageReducer,
		[REDUCERS.LANDING_PAGE]: landingPageReducer,
		[REDUCERS.LOADING]: loadingReducer,
		[REDUCERS.LOCATION]: locationReducer,
		[REDUCERS.MOMENT]: momentReducer,
		[REDUCERS.PAYMENT_METHOD]: paymentMethodReducer,
		[REDUCERS.PRICING]: pricingReducer,
		[REDUCERS.USER]: withCookies(cookieJar)('user', null, ['accessToken'])(userReducer),
		[REDUCERS.VIEW]: viewReducer,
	});

export default reducers;
