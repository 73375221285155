import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { formatNewline, formatRemoveFirstLine } from '../helpers/strings/strings';

export const addressFromJson = (json = {}) => {
	const id = deep(json, 'ID');

	return {
		city: deep(json, 'City'),
		countryCode: deep(json, 'CountryCode'),
		formatted: deep(json, 'Formatted'),
		htmlFormatted: formatNewline(deep(json, 'Formatted')),
		htmlFormattedNoName: formatNewline(formatRemoveFirstLine(deep(json, 'Formatted'))),
		id: id && String(id),
		isDefault: deep(json, 'IsDefault'),
		isVisible: deep(json, 'IsVisible'),
		name: deep(json, 'Name'),
		personId: deep(json, 'PersonId'),
		postalCode: deep(json, 'PostalCode'),
		state: deep(json, 'State'),
		street1: deep(json, 'Street1'),
		street2: deep(json, 'Street2'),
	};
};

export const addressSchema = new schema.Entity('address');
const addressesSchema = new schema.Array(addressSchema);

export const normalizeAddress = (address) => {
	return normalize(address, addressSchema);
};

export const normalizeAddresses = (addresses) => {
	return normalize(addresses, addressesSchema);
};
