import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';
import { ENTITIES } from '../const/entities/entities';

export const groupCoverBundleFromJson = (json = {}) => ({
	id: deep(json, 'CoverBundleId'),
	endVolume: deep(json, 'EndVolume'),
	groupId: deep(json, 'GroupId'),
	startVolume: deep(json, 'StartVolume'),
});

export const groupCoverBundleSchema = new schema.Entity(ENTITIES.GROUP_COVER_BUNDLE, {});

export const normalizeGroupCoverBundle = (coverBundle) => normalize(coverBundle, groupCoverBundleSchema);
