import axios from 'axios';

import { selectAccessToken } from '../selectors/user.selectors';
import { perfHooks } from '../helpers/logging';

const { performance } = perfHooks;

const getDefaultHeaders = (state) => {
	const headers = {};

	const accessToken = selectAccessToken(state);
	if (accessToken) headers['Access-Token'] = accessToken;
	headers['Content-Type'] = 'application/json';

	return headers;
};

export const httpDelete = (url) => (dispatch, getState) => {
	return axios({
		method: 'delete',
		url,
		withCredentials: true,
		headers: getDefaultHeaders(getState())
	});
};

export const httpGet = (url) => (dispatch, getState) => {
	performance.mark(`httpGet:${url}:start`);

	return axios({
		method: 'get',
		url,
		withCredentials: true,
		headers: getDefaultHeaders(getState())
	}).finally(() => {
		performance.mark(`httpGet:${url}:end`);
		performance.measure(`httpGet:${url}`, `httpGet:${url}:start`, `httpGet:${url}:end`);
	});
};

export const httpGetData = (url) => (dispatch) => {
	return dispatch(httpGet(url))
		.then(({ data }) => data);
};

export const httpPost = (url, data, { headers } = {}) => (dispatch, getState) => {
	performance.mark(`httpPost:${url}:start`);

	return axios({
		method: 'post',
		url,
		data,
		headers: {
			...getDefaultHeaders(getState()),
			...headers,
		},
	}).finally(() => {
		performance.mark(`httpPost:${url}:end`);
		performance.measure(`httpPost:${url}`, `httpGet:${url}:start`, `httpGet:${url}:end`);
	});
};

export const httpPut = (url, data, { headers = {}, validateStatus } = {}) => (dispatch, getState) => {
	performance.mark(`httpPut:${url}:start`);

	return axios({
		method: 'put',
		url,
		data,
		headers: {
			...getDefaultHeaders(getState()),
			...headers,
		},
		validateStatus,
	}).finally(() => {
		performance.mark(`httpPut:${url}:end`);
		performance.measure(`httpPut:${url}`, `httpGet:${url}:start`, `httpGet:${url}:end`);
	});
};
