import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { selectLoadingErrorMap, selectIsStateInitializingFunc, selectIsStateLoadingFunc } from './loading.selectors';
import { LOADING_KEYS } from '../actions/loading.actions';
import { REDUCERS } from '../const/reducers.const';
import { firstItemInArray } from '../helpers/arrays/arrays';

export const selectAddressState = (state) => deep(state, `${REDUCERS.ADDRESS}`);

export const selectAddressMap = createSelector(
	selectAddressState,
	(addressState) => deep(addressState, 'address') || {}
);

const selectAddressIds = createSelector(
	selectAddressState,
	(addressState) => deep(addressState, 'addressIds') || []
);

export const selectAddressArray = createSelector(
	selectAddressMap,
	selectAddressIds,
	(addressMap, ids) => ids.map((id) => addressMap[id])
);

export const selectAddressCount = createSelector(
	selectAddressArray,
	(addresses) => addresses.length
);

export const selectHasAddress = createSelector(
	selectAddressCount,
	(count) => count > 0
);

export const selectCanDeleteAnAddress = createSelector(
	selectAddressCount,
	(count) => count > 1
);

export const selectSaveAddressLoadingErrorReason = createSelector(
	selectLoadingErrorMap,
	(errorMap) => deep(errorMap, `${LOADING_KEYS.SAVE_ADDRESS}.data.error`)
);

export const selectRemoveAddressErrorReason = createSelector(
	selectLoadingErrorMap,
	(errorMap) => deep(errorMap, `${LOADING_KEYS.REMOVE_SHIPPING_ADDRESS}.data.error`)
);

export const selectIsAddressLoading = createSelector(
	selectIsStateLoadingFunc,
	(isStateLoadingFunc) => isStateLoadingFunc(LOADING_KEYS.LOAD_SHIPPING_ADDRESSES)
);

export const selectIsAddressInitializing = createSelector(
	selectIsStateInitializingFunc,
	(isStateInitalizingFunc) => isStateInitalizingFunc(LOADING_KEYS.LOAD_SHIPPING_ADDRESSES)
);

export const selectDefaultAddress = createSelector(
	selectAddressArray,
	(addresses) => firstItemInArray(addresses)
);

export const selectDefaultAddressId = createSelector(
	selectDefaultAddress,
	(address) => deep(address, 'id')
);