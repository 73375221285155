import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCaretLeft = (props) => (
	<Svg
		{...props}
		name="caret-left"
		viewBox="0 0 24 24"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.4098 18.1585C14.1164 18.4516 13.641 18.4516 13.3476 18.1585L7.71419 12.5319C7.42122 12.2395 7.42073 11.765 7.71308 11.4721C7.71345 11.4717 7.71382 11.4713 7.71419 11.471L13.3476 5.84431C13.6406 5.55101 14.1158 5.55072 14.4091 5.84367C14.7024 6.13663 14.7027 6.61188 14.4098 6.90519L9.30737 12.0014L14.4098 17.0977C14.7027 17.39 14.7032 17.8644 14.411 18.1573C14.4106 18.1577 14.4102 18.1581 14.4098 18.1585Z"
			fill="inherit"
		/>
	</Svg>
);

IconCaretLeft.displayName = 'IconCaretLeft';

IconCaretLeft.propTypes = iconProps;

IconCaretLeft.defaultProps = defaultIconProps;

export default IconCaretLeft;
