import React from 'react';
import PropTypes from 'prop-types';

import { convertImageUrl } from '../DuploBlock.helpers';
import './Badge.css';

const Badge = ({
	className,
	title,
	imageURL,
}) => (
	<li className={`duplo-badge ${(!!className) ? className : ''}`}>
		<div className="square">
			<img
				src={convertImageUrl(imageURL)}
				alt=""
			/>
		</div>
		<p className="title">{title}</p>
	</li>
);

Badge.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	imageURL: PropTypes.string,
};

export default Badge;
