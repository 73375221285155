import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HomePagePillButton from '../../components/Button/HomePagePillButton/HomePagePillButton';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import { DEVICE_WIDTH, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../const';

const FounderMomentContainer = styled(PageFlowContainer)`
	display: grid;
	padding-left: ${SPACING.BIGGER};
	padding-right: ${SPACING.BIGGER};

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		padding-bottom: ${SPACING.BIGGEST};
		padding-top: ${SPACING.BIGGEST};
		grid-template-columns: 1fr;
		grid-template-areas:
			"image"
			"story"
			"cta";
		row-gap: ${SPACING.BIG};
		justify-items: center;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		padding-bottom: 100px;
		padding-top: 100px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-areas:
			"image story"
			"image cta";
		row-gap: ${SPACING.BIG};
		column-gap: ${SPACING.BIG};
	}
`;

const FounderImage = styled.img`
	grid-area: image;
	object-fit: contain;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		align-self: center;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		justify-self: center;
	}
`;

const StoryContainer = styled.div`
	grid-area: story;
`;

const Title = styled.div`
	color: #333333;
	font-family: Circular-Pro-Medium;
	align-self: end;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 30px;
		line-height: 40px;
		text-align: center;
		font-size: 21px;
		line-height: 28px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 40px;
		line-height: 50px;
	}
`;

const Story = styled.div`
	color: #333333;
	font-family: Inter;
	font-style: normal;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 14px;
		line-height: 20px;
		max-width: 340px;
		margin-top: 16px;
		text-align: center;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 16px;
		line-height: 26px;
		margin-top: ${SPACING.BIGGER};
		max-width: 525px;
	}
`;

const StoryAttribution = styled(Story)`
	font-style: italic;
`;

const CtaContainer = styled.div`
	grid-area: cta;
`;

const FounderMoment = ({
	ctaBackgroundColor,
	ctaBackgroundHoverColor,
	ctaText,
	ctaTextColor,
	ctaUrl,
	founderMomentSubcopy,
	founderMomentTitle,
	founderQuoteAttribution,
	imageAlt,
	imageUrl,
	linkCallback,
}) => (
	<FounderMomentContainer>
		<FounderImage src={imageUrl} alt={imageAlt} />
		<StoryContainer>
			<Title>{founderMomentTitle}</Title>
			<Story>
				{founderMomentSubcopy}
				{founderQuoteAttribution && (
					<>
						<br />
						<StoryAttribution>{founderQuoteAttribution}</StoryAttribution>
					</>
				)}
			</Story>
		</StoryContainer>
		<CtaContainer>
			<HomePagePillButton
				color={ctaTextColor}
				backgroundColor={ctaBackgroundColor}
				hoverBackgroundColor={ctaBackgroundHoverColor}
				onClick={() => linkCallback(ctaUrl)}>
				{ctaText}
			</HomePagePillButton>
		</CtaContainer>
	</FounderMomentContainer>
);

FounderMoment.displayName = 'FounderMoment';

FounderMoment.propTypes = {
	ctaBackgroundColor: PropTypes.string,
	ctaBackgroundHoverColor: PropTypes.string,
	ctaText: PropTypes.string,
	ctaTextColor: PropTypes.string,
	ctaUrl: PropTypes.string,
	founderMomentSubcopy: PropTypes.string,
	founderMomentTitle: PropTypes.string,
	founderQuoteAttribution: PropTypes.string,
	linkCallback: PropTypes.func,
	imageAlt: PropTypes.string,
	imageUrl: PropTypes.string,
};

export default FounderMoment;
