import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

export const useDebouncedCallback = (fn, deps = [], delay = 250) => {
	const memoizedFn = useCallback(fn, deps);
	const debouncedFn = useRef(debounce(memoizedFn, delay));

	useEffect(() => {
		debouncedFn.current = debounce(memoizedFn, delay);

		return debouncedFn.current.cancel;
	}, [debouncedFn, memoizedFn, delay]);

	return debouncedFn.current;
};

export default useDebouncedCallback;
