import axios from 'axios';

const handleHttpResponse = (response) => {
	const { data } = response;
	if (data.success === false) {
		return Promise.reject(response);
	}

	return response;
};

export const initHttpInterceptors = () => {
	axios.interceptors.response.use(handleHttpResponse);
};
