import { omit } from 'lodash';

export const PAYMENT_TYPE = {
	BRAINTREE_CREDIT_CARD: 'BraintreeCreditCard',
	CYBER_SOURCE: 'CyberSourcePaymentMethod',
	PAYPAL: 'PaypalPaymentMethod',
	PAYPAL_BRAINTREE: 'BraintreePayPalAccount',
	STRIPE: 'StripePaymentMethod',
};

export const NEW_PAYMENT_OPTION = {
	NEW_CREDIT_CARD: 'newCreditCard',
	NEW_PAYPAL: 'newPaypal',
	REDEEM_GIFT_CARD: 'redeemGiftCard',
};

export const CHOOSE_PAYMENT_OPTIONS = {
	...NEW_PAYMENT_OPTION,
	EXISTING_PAYMENT_METHOD: 'existingPaymentMethod',
	USER_CREDIT: 'userCredit',
};

export const CHOOSE_PAYMENT_OPTIONS_ONLY_NEW_PAYMENT_METHODS = omit(CHOOSE_PAYMENT_OPTIONS, (option) => {
	return option === CHOOSE_PAYMENT_OPTIONS.EXISTING_PAYMENT_METHOD
		|| option === CHOOSE_PAYMENT_OPTIONS.REDEEM_GIFT_CARD
		|| option === CHOOSE_PAYMENT_OPTIONS.USER_CREDIT;
});

export const CHOOSE_PAYMENT_OPTIONS_WITHOUT_GIFT_CARD_OR_CHATBOOKS_CREDIT = omit(CHOOSE_PAYMENT_OPTIONS, (option) => {
	return option === CHOOSE_PAYMENT_OPTIONS.REDEEM_GIFT_CARD
		|| option === CHOOSE_PAYMENT_OPTIONS.USER_CREDIT;
});

export const PAYMENT_ANALYTICS_TYPE = {
	CREDIT_CARD: 'CreditCard',
	PAY_PAL: 'Paypal',
	GIFT_CARD: 'giftCard',
};

export const NEW_PAYMENT_OPTION_TO_ANALYTICS_TYPE_MAP = {
	[NEW_PAYMENT_OPTION.NEW_CREDIT_CARD]: PAYMENT_ANALYTICS_TYPE.CREDIT_CARD,
	[NEW_PAYMENT_OPTION.NEW_PAYPAL]: PAYMENT_ANALYTICS_TYPE.PAY_PAL,
	[NEW_PAYMENT_OPTION.REDEEM_GIFT_CARD]: PAYMENT_ANALYTICS_TYPE.GIFT_CARD,
};

export const PAYMENT_TYPE_TO_ANALYTICS_TYPE_MAP = {
	[PAYMENT_TYPE.BRAINTREE_CREDIT_CARD]: PAYMENT_ANALYTICS_TYPE.CREDIT_CARD,
	[PAYMENT_TYPE.CYBER_SOURCE]: PAYMENT_ANALYTICS_TYPE.CREDIT_CARD,
	[PAYMENT_TYPE.STRIPE]: PAYMENT_ANALYTICS_TYPE.CREDIT_CARD,
	[PAYMENT_TYPE.PAYPAL]: PAYMENT_ANALYTICS_TYPE.PAY_PAL,
	[PAYMENT_TYPE.PAYPAL_BRAINTREE]: PAYMENT_ANALYTICS_TYPE.PAY_PAL,
};

export const NEW_PAYPAL_PAYMENT_METHOD_ID = 'newPayPalPaymentMethodId';

export const PAYMENT_FORMS = {
	MODAL: 'MODAL',
	PAYMENT_STEP: 'PAYMENT_STEP',
};

export const SUBMIT_PAYMENT_METHOD_ANALYTICS_EVENT_MAP = {
	[PAYMENT_FORMS.MODAL]: 'PaymentMethodOverlay_Done',
	[PAYMENT_FORMS.PAYMENT_STEP]: 'CheckoutPaymentMethod_Continue',
};
