import deep from 'deep-get-set';

export const ssoConnectionFromJson = (json = {}) => {
	const id = deep(json, 'Id');

	return {
		id: id && String(id),
		type: deep(json, 'Type'),
	};
};

export const ssoConnectionsFromJsonArray = (jsonArray) => (jsonArray || []).map(ssoConnectionFromJson);
