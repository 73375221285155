import React from 'react';
import PropTypes from 'prop-types';

export const H5 = ({
	children,
	className,
}) => {
	if (!children) return null;

	return (
		<h5
			className={className}
		>
			{children}
		</h5>
	);
};

H5.displayName = 'H5';

H5.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default H5;
