import { apiPut } from './api.http.actions';

const SECONDS_PER_MINUTE = 60;

export const put_reflections_my_location = (location, expirationSeconds = SECONDS_PER_MINUTE) => (dispatch) => {
	const Location = {
		GeoNameId: location.geoNameId,
		City: location.city,
		State: location.state,
		Zip: location.zip,
		Country: location.country,
		Latitude: location.latitude,
		Longitude: location.longitude,
	};

	return dispatch(apiPut('/reflections/my/location', {
		Location,
		expirationSeconds,
	}));
};
