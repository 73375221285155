import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';

import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import { mediaQueryStartingFrom, mediaQueryUpTo, DEVICE_WIDTH, SPACING } from '../../const';
import normalizeMeasurement from '../../helpers/normalizeMeasurement';

const ReviewsContainer = styled('div')`
	margin: 0 auto;
	max-width: 1000px;
	padding-bottom: ${SPACING.BIGGEST};
	padding-top: ${SPACING.BIGGEST};
`;

const ReviewsTitleDiv = styled('div')`
	color: ${(props) => props.color};
	font-size: 32px;
	font-weight: 700;
	line-height: 41px;
	letter-spacing: -0.5px;
	text-align: center;
`;

const ReviewEntriesDiv = styled('div')`
	margin-top: 45px;
	display: grid;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		grid-template-columns: 1fr;
		row-gap: ${SPACING.SMALL};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		grid-template-columns: 140px 1fr;
		row-gap: ${SPACING.BIG};
	}
`;

const ReviewerNameDiv = styled('div')`
	color: ${(props) => props.color};
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.005em;
	text-align: left;
`;

const ReviewDiv = styled('div')`
	font-size: 20px;
	font-weight: 450;
	line-height: 27px;
	letter-spacing: 0px;
	text-align: left;
	color: #333333;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		margin-bottom: ${(props) => !props.isLast && SPACING.BIG};
	}
`;

const Reviews = ({
	backgroundColor,
	paddingBottom,
	paddingTop,
	reviews,
	title,
	titleAndReviewerColor,
}) => {
	if (!reviews || !reviews.length) return null;

	return (
		<PageFlowContainer
			className={classnames('Reviews')}
			isInset={true}
			style={{
				backgroundColor,
				paddingBottom: normalizeMeasurement(paddingBottom),
				paddingTop: normalizeMeasurement(paddingTop),
			}}>
			<ReviewsContainer>
				<ReviewsTitleDiv
					color={titleAndReviewerColor}>
					{title}
				</ReviewsTitleDiv>
				<ReviewEntriesDiv>
					{reviews.map((review, index) => (
						<Fragment key={index}>
							<ReviewerNameDiv
								color={titleAndReviewerColor}>
								{review.reviewerName}
							</ReviewerNameDiv>
							<ReviewDiv
								isLast={index >= reviews.length - 1}>
								{review.review}
							</ReviewDiv>
						</Fragment>
					))}
				</ReviewEntriesDiv>
			</ReviewsContainer>
		</PageFlowContainer>
	);
};

Reviews.displayName = 'Reviews';

Reviews.propTypes = {
	backgroundColor: PropTypes.string,
	isInset: PropTypes.bool,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	reviews: PropTypes.array,
	title: PropTypes.string,
	titleAndReviewerColor: PropTypes.string,
};

export default Reviews;