import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const datePhotoTakenFromJson = (json = {}) => ({
	shortDate: deep(json, 'ShortDate'),
	iso8601: deep(json, 'Iso8601'),
});

export const mediaPhotoFromJson = (json = {}) => ({
	collectionLarge: deep(json, 'CollectionLarge'),
});

export const mediaFromJson = (json = {}) => ({
	caption: deep(json, 'Caption'),
	datePhotoTaken: datePhotoTakenFromJson(deep(json, 'DatePhotoTaken')),
	location: deep(json, 'Location'),
	mediaId: deep(json, 'MediaID'),
	mediaType: deep(json, 'MediaType'),
	photo: mediaPhotoFromJson(deep(json, 'Photo')),
});

export const mediaObjectsFromJson = (jsonArray = []) => jsonArray.map(mediaFromJson);

export const mediaSchema = new schema.Entity(ENTITIES.MEDIA, {}, { idAttribute: 'mediaId' });
export const mediaObjectsSchema = new schema.Array(mediaSchema);

export const normalizeMedia = (media) => normalize(media, mediaSchema);
