import { createSelector } from 'reselect';

import { selectAppCookiesState } from './appCookie.selectors';
import { selectTranslateFunc } from './appString.selectors';
import { selectDataSources, selectDataSourceState, selectReconnectDataSourceLoadingErrorReason, selectReconnectDataSourceLoadingStatus } from './dataSource.selectors';
import { LOAD_STATUS } from '../const/loadStatus.const';
import { firstItemInArray } from '../helpers/arrays/arrays';
import { isDate, isDateOlderThanXHours } from '../helpers/date/date';
import { reauthReminderSnoozedCookie } from '../helpers/instagram/instagram.helpers';
import { parseIntDec } from '../helpers/numbers/numbers';
import * as DataSource from '../model/dataSource/dataSource.model';

export const selectNextInstagramLegacyDataSourceToReauth = createSelector(
	selectDataSources,
	selectAppCookiesState,
	selectTranslateFunc,
	(dataSources, cookies, translate) => firstItemInArray(dataSources.filter((dataSource) => {
		const snoozeDate = new Date(cookies[reauthReminderSnoozedCookie(DataSource.getUsername(dataSource))]);
		const snoozeHours = parseIntDec(translate('web.ig.reauth.popup.snoozeHours')) || 1;
		const isSnoozed = isDate(snoozeDate) && !isNaN(snoozeHours) && snoozeHours > 0 && !isDateOlderThanXHours(snoozeDate, snoozeHours);
		const isInstagramLegacy = DataSource.isTypeOfInstagramLegacy(dataSource);

		return isInstagramLegacy && !isSnoozed;
	}))
);

export const selectInstagramLegacyDatasourceReconnectStatusFunc = createSelector(
	selectDataSourceState,
	selectReconnectDataSourceLoadingErrorReason,
	selectReconnectDataSourceLoadingStatus,
	(dataSourceState, error, status) => (dataSourceId) => {
		const dataSourceInStore = dataSourceState[dataSourceId];
		const isInstagramGraph = DataSource.isTypeOfInstagramGraph(dataSourceInStore);

		const statusReturn = { status, error };

		if (status === LOAD_STATUS.SUCCESS && !isInstagramGraph) {
			statusReturn.status = LOAD_STATUS.ERROR;
			statusReturn.error = 'Unable to reconnect';
		}

		return statusReturn;
	}
);
