import * as analyticsActions from '../analytics.actions';
import * as paymentMethodActions from '../paymentMethod.actions';
import { SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP } from '../../const/shippingMethod.const';

export const triggerShoppingCartEvent = (eventName, attrs = {}) => (dispatch) => {
	return dispatch(analyticsActions.triggerOrQueueEventRequiringCartId(eventName, attrs));
};

export const tagShoppingCartLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_Load'));
};

export const tagShoppingCartCheckout = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShoppingCart_Checkout'));
};

export const tagCheckoutShippingAddressLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_Load'));
};

export const tagCheckoutShippingAddressChangeCountry = (country) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_ChangeCountry', { attr1: country }));
};

export const tagCheckoutShippingAddressEditExisting = (addressId, result) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_EditExisting', { attr1: addressId, attr2: result }));
};

export const tagCheckoutShippingAddressContinue = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingAddress_Continue'));
};


export const tagCheckoutShippingMethodLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingMethod_Load'));
};

export const tagBreadcrumbClick = (eventName, breadcrumbName) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent(eventName, { attr1: breadcrumbName }));
};

export const tagCheckoutShippingMethodContinue = (shippingMethod) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutShippingMethod_Continue', { attr1: SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP[shippingMethod] }));
};


export const tagCheckoutPaymentMethodLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('CheckoutPaymentMethod_Load'));
};

const tagPaymentMethodChooseMethod = (paymentMethodSelection, eventName) => (dispatch) => {
	const attrs = dispatch(paymentMethodActions.buildAttributesForPaymentMethodSelectionEvent(paymentMethodSelection));

	return dispatch(triggerShoppingCartEvent(eventName, attrs));
};

export const tagCheckoutPaymentMethodChooseMethod = (paymentMethodSelection) => (dispatch) => {
	return dispatch(tagPaymentMethodChooseMethod(paymentMethodSelection, 'CheckoutPaymentMethod_ChooseMethod'));
};


export const tagOrderReviewLoad = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('OrderReview_Load'));
};

export const tagOrderReviewPlaceOrder = (masterOrderId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('OrderReview_PlaceOrder', { attr1: masterOrderId }));
};

export const tagShippingAddressOverlayAddNewAddress = (addressId) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingAddressOverlay_AddNewAddress', { attr1: addressId }));
};

export const tagShippingAddressOverlayEditAddress = (addressId, result) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingAddressOverlay_EditAddress', { attr1: addressId, attr2: result }));
};

export const tagShippingAddressOverlayCancel = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingAddressOverlay_Cancel'));
};

export const tagShippingMethodOverlayChooseMethod = (shippingMethod) => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingMethodOverlay_ChooseMethod', { attr1: SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP[shippingMethod] }));
};

export const tagShippingMethodOverlayCancel = () => (dispatch) => {
	return dispatch(triggerShoppingCartEvent('ShippingMethodOverlay_Cancel'));
};


export const tagPaymentMethodOverlayChooseMethod = (paymentMethodSelection) => (dispatch) => {
	return dispatch(tagPaymentMethodChooseMethod(paymentMethodSelection, 'PaymentMethodOverlay_ChooseMethod'));
};
