import React from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

import Breadcrumb from './Breadcrumb';
import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import './Breadcrumbs.css';

export const Breadcrumbs = ({
	analyticEvent,
	className: classNameProps,
	isInset,
	links,
	paddingBottom,
	paddingTop,
	style
}) => {
	if (links.length === 0) return null;

	const breadcrumbs = some(links, (link) => link.isBold)
		? links
		: links.map((link, index) => ({
			...link,
			isBold: index === links.length - 1,
		}))

	return (
		<PageFlowContainer
			className={classNameProps}
			isInset={isInset}
			paddingTop={paddingTop}
			paddingBottom={paddingBottom}
			style={style}
		>
			<div className="breadcrumbs">
				<ul className="breadcrumbs-list">
					{breadcrumbs.map((breadcrumb, index) => (
						<Breadcrumb
							key={`${index}-${breadcrumb.to}`}
							analyticEvent={analyticEvent}
							index={index}
							length={links.length}
							{...breadcrumb}
						/>
					))}
				</ul>
			</div>
		</PageFlowContainer>
	);
};

Breadcrumbs.displayName = 'Breadcrumbs';

Breadcrumbs.propTypes = {
	analyticEvent: PropTypes.func,
	className: PropTypes.string,
	links: PropTypes.array,
	isInset: PropTypes.bool,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	style: PropTypes.object
};

Breadcrumbs.defaultProps = {
	isInset: false,
	links: [],
};

export default Breadcrumbs;
