import { isString } from '../strings/strings';

export const getBookIdAsMap = (bookId = '') => {
	if (!isString(bookId)) return {};

	const matches = /^book:volume\((\d+)\):group\((\d+)\):person\((\d+)\)/.exec(bookId);

	if (matches && matches.length) {
		return {
			volume: Number(matches[1]),
			group: Number(matches[2]),
			person: Number(matches[3]),
		};
	} else {
		return {};
	}

}

export const getVolumeFromBookId = (bookId = '') => {
	const bookIdAsMap = getBookIdAsMap(bookId);

	return bookIdAsMap.volume;
}