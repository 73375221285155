import { getNumberWithin } from '../math/math';

export const popupWindowOptions = ({
	minHeight = 400,
	minWidth = 300,
	maxWidth = 450,
	maxHeight = 600,
} = {}) => {
	const width = getNumberWithin(Math.floor(window.outerWidth * .5), minWidth, maxWidth);
	const height = getNumberWithin(Math.floor(window.outerHeight * .8), minHeight, maxHeight);
	const left = window.screenX + (window.outerWidth - width) / 2;
	const top = window.screenY + (window.outerHeight - height) / 8;

	const windowOptions = 'toolbar=0,' +
		'scrollbars=1,' +
		'status=1,' +
		'resizable=1,' +
		'location=1,' +
		'menuBar=0,' +
		`width=${width},` +
		`height=${height},` +
		`left=${left},` +
		`top=${top}`;

	return windowOptions;
};
