import React from 'react';
import PropTypes from 'prop-types';
import defaultIconProps from '../_defaultIconProps';
import iconProps from '../_iconProps';
import Svg from '../Svg';

export const IconCardBackPortraitTextOnly = (props) => {
	const { size } = props;
	const heightRatio = 140;
	const widthRatio = 100;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="IconCardBackPortraitTextOnly"
			width={width}
			height={height}
			viewBox="0 0 100 140"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <rect fill={props.color} x="0" y="0" width="100" height="140" />
		        <polygon fill={props.color2} points="20 59 80 59 80 61 20 61" />
		        <rect fill={props.color2} x="21" y="71" width="58" height="2" />
		        <rect fill={props.color2} x="24" y="65" width="52" height="2" />
		        <rect fill={props.color2} x="24" y="77" width="52" height="2" />
		    </g>
		</Svg>
	);
};

IconCardBackPortraitTextOnly.displayName = 'IconCardBackPortraitTextOnly';

IconCardBackPortraitTextOnly.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconCardBackPortraitTextOnly.defaultProps = {
	...defaultIconProps,
	color: '#ffffff',
	color2: '#d8d8d8',
};

export default IconCardBackPortraitTextOnly;
