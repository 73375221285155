/**
 * Converts an array of objects to a keyMap by a provided key.
 *
 * @param {array} array An array of objects.
 * @param {string} key The key to use in the map.
 * @returns {object}
 */
export default (array, key) => {
	return array.reduce((acc, current) => {
		acc[current[key]] = current;

		return acc;
	}, {});
}