import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import PasswordField from '../../PasswordField/PasswordField';
import withFormContext from '../withFormContext';
import './FormField.css';

class FormField extends Component {
	constructor(props) {
		super(props);

		this.state = { dirty: false, focused: false, invalid: false, value: '' };

		this.handleBlur = this.handleBlur.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.setDirty = this.setDirty.bind(this);

		props.onUpdateFormField && props.onUpdateFormField(props.name, { ...this.state, setDirty: this.setDirty });
	}

	afterSetState(state) {
		const { onUpdateFormField, name } = this.props;
		onUpdateFormField && onUpdateFormField(name, state);
	}

	getInput({ type, name, placeholder, selectOptions, value, invalid }) {
		const commonProps = {
			className: classnames({ 'is-invalid-input': invalid }),
			id: name,
			name,
			placeholder,
			type,
			value,
			'aria-describedby': `${name}-error`,
			onBlur: this.handleBlur,
			onChange: this.handleInputChange,
			onFocus: this.handleFocus
		};
		if (type === 'password') {
			return <PasswordField {...commonProps} />;
		}
		if (type === 'select') {
			const defaultOpt = placeholder ? (
				<option key="option-default" value="" disabled hidden>
					{placeholder}
				</option>
			) : null;
			const opts = selectOptions.map((opt, index) => (
				<option key={`option-${index}`} value={opt.key}>
					{opt.value}
				</option>
			));

			return (
				<select
					id={name}
					name={name}
					value={value}
					className={classnames({ placeholder: !value, 'is-invalid-input': invalid })}
					onBlur={this.handleBlur}
					onChange={this.handleSelectChange}
					onFocus={this.handleFocus}
					aria-describedby={`${name}-error`}
				>
					{defaultOpt}
					{opts}
				</select>
			);
		}

		return <input {...commonProps} />;
	}

	handleBlur() {
		const { required, validationRegex } = this.props;
		const { value } = this.state;
		const invalid = !this.isValid(value, validationRegex, required);
		this.setState({ focused: false, invalid }, () => this.afterSetState(this.state));
	}

	handleFocus() {
		this.setState({ focused: true }, () => this.afterSetState(this.state));
	}

	handleInputChange(e) {
		const {
			target: { value }
		} = e;
		this.setState({ dirty: true, value }, () => this.afterSetState(this.state));
	}

	handleSelectChange(e) {
		const {
			target: { value }
		} = e;
		this.setState({ dirty: true, value }, () => this.afterSetState(this.state));
	}

	isValid(value, regex, required) {
		if (required === true && !value) {
			return false;
		}
		if (regex) {
			return regex.test(value);
		}

		return true;
	}

	setDirty(callback) {
		const { required, validationRegex } = this.props;
		const { value } = this.state;
		const invalid = !this.isValid(value, validationRegex, required);
		this.setState({ dirty: true, invalid }, () => {
			this.afterSetState(this.state);
			callback && callback();
		});
	}

	render() {
		const { label, name, selectOptions, type, validationMessage } = this.props;
		const { dirty, focused, invalid: invalidState, value } = this.state;
		const invalid = !focused && invalidState && dirty;
		const input = this.getInput({ name, placeholder: label, type, selectOptions, value, invalid });
		const error =
			invalid && validationMessage ? (
				<span id={`${name}-error`} className="form-error is-visible">
					{validationMessage}
				</span>
			) : null;

		return (
			<label className={classnames('FormField', { 'is-invalid-label': invalid })}>
				{error}
				<span className="show-for-sr">{label}</span>
				{input}
			</label>
		);
	}
}

FormField.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	onUpdateFormField: PropTypes.func,
	required: PropTypes.bool,
	selectOptions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })),
	type: PropTypes.string,
	validationMessage: PropTypes.node,
	validationRegex: PropTypes.instanceOf(RegExp)
};

export default withFormContext(FormField);
