import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconPencil2 = (props) => (
	<Svg
		{...props}
		name="pencil-2"
		viewBox="0 0 120 120"
	>
		<path
			d="M71.12,88.85a1.2,1.2,0,0,1-.84-.35L25.41,43.63a1.2,1.2,0,0,1,0-1.68L41.87,25.5a1.18,1.18,0,0,1,1.67,0L88.41,70.37a1.2,1.2,0,0,1,0,1.68L72,88.5A1.2,1.2,0,0,1,71.12,88.85ZM27.93,42.79,71.12,86,85.89,71.21,42.7,28Z"
		/>
		<path
			d="M32.34,50.07a1.19,1.19,0,0,1-.83-2L48,31.59a1.18,1.18,0,1,1,1.67,1.68L33.18,49.72A1.18,1.18,0,0,1,32.34,50.07Z"
		/>
		<path
			d="M40,57.72a1.19,1.19,0,0,1-.84-2L55.6,39.24a1.19,1.19,0,0,1,1.68,1.68L40.83,57.37A1.2,1.2,0,0,1,40,57.72Z"
		/>
		<path
			d="M93.75,94.85a1.65,1.65,0,0,1-.31,0l-22.62-6a1.19,1.19,0,0,1,.6-2.3L92.06,92,86.43,71.52a1.19,1.19,0,1,1,2.29-.63l6.17,22.46a1.18,1.18,0,0,1-.3,1.14A1.19,1.19,0,0,1,93.75,94.85Z"
		/>
		<path
			d="M84.32,92.35a1.22,1.22,0,0,1-.84-.35,1.2,1.2,0,0,1,0-1.68l6.86-6.85A1.18,1.18,0,1,1,92,85.15L85.16,92A1.18,1.18,0,0,1,84.32,92.35Z"
		/>
	</Svg>
);

IconPencil2.displayName = 'IconPencil2';

IconPencil2.propTypes = iconProps;

IconPencil2.defaultProps = defaultIconProps;

export default IconPencil2;
