import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCrop = (props) => (
	<Svg
		{...props}
		name="crop"
		viewBox="0 0 16 16"
	>
		<g fillRule="evenodd">
			<rect fill="inherit" x="2" y="0" width="2" height="13" />
			<rect fill="inherit" x="12" y="2" width="2" height="9" />
			<rect fill="inherit" x="12" y="12" width="2" height="4" />
			<rect fill="inherit" x="2" y="12" width="14" height="2" />
			<rect fill="inherit" x="0" y="2" width="4" height="2" />
			<rect fill="inherit" x="5" y="2" width="9" height="2" />
		</g>
	</Svg>
);

IconCrop.displayName = 'IconCrop';

IconCrop.propTypes = iconProps;

IconCrop.defaultProps = defaultIconProps;

export default IconCrop;
