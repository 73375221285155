export const LAYOUT_TYPES = {
	STANDARD: 0,
	FULL_BLEED: 1,
	FIT: 2,
	SQUARE: 3,
	COLLAGE_FOUR_PHOTO: 4,
	COLLAGE_TWO_LANDSCAPE: 5,
	COLLAGE_TWO_PORTRAIT: 6,
	COLLAGE_THREE_LANDSCAPE: 7,
	COLLAGE_THREE_PORTRAIT: 8,
	COLLAGE_SIX_SQUARE: 9,
	CONTINUATION: 10,
	TEXT_PAGE: 11,
	STANDARD_OR_FIT: 101, // this is the default for new groups, and it fit unless there is a caption
	FIT_WITHOUT_CONTINUATION: 102 // this is fit and hides all captions (no continuations either)
};
