import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useIsHover } from '../../hooks';

const Container = styled.div`
	position: relative;
`;

const ImageWithHiddenHoverImage = ({
	isHovered: controlledIsHover,
	render,
}) => {
	const { hoverProps, isHover } = useIsHover();

	if (!render) return null;

	return (
		<Container
			{...hoverProps}>
			{render({
				getFrontProps: () => ({
					style: {
						opacity: (isHover || controlledIsHover) ? 0 : 1,
						top: 0,
						left: 0,
					},
				}),
				getBackProps: () => ({
					style: {
						position: 'absolute',
						opacity: (isHover || controlledIsHover) ? 1 : 0,
						top: 0,
						left: 0,
					},
				}),
			})}
		</Container>
	);
};

ImageWithHiddenHoverImage.displayName = 'ImageWithHiddenHoverImage';

ImageWithHiddenHoverImage.propTypes = {
	isHovered: PropTypes.bool,
	render: PropTypes.func,
};

export default ImageWithHiddenHoverImage;