const events = {};
const values = {};

const EVENTS = {
    CAN_ORDER_CARD: 'canOrderCard',
    CARD_FILTER_SYNC: 'cardFilterSync',
    CARD_INCOMPLETE: 'cardIncomplete',
    CARD_TEMPLATE_WIDTH: 'cardTemplateWidth',
    CARDS_SCREEN: 'cardsScreen',
    CARDS_SCREEN_CHANGED: 'cardsScreenChanged',
    CARDS_UPDATE_PHOTO_GROUPS: 'cardsUpdatePhotoGroups',
    FILE_FINISHED_UPLOADING: 'fileFinishedUploading',
    OPEN_CHANGE_TITLE: 'openChangeTitle'
};

/**
 * Register an event listener.
 *
 * @param {string} name Name of event.
 * @param {func} callback The callback function
 * @param {bool} catchup Send any previous values if there are any.
 */
const listen = (name, callback, catchup) => {
    if (!events[name]) {
        events[name] = [];
    }
    events[name].push(callback);

    if (catchup && typeof values[name] !== 'undefined') {
        callback(values[name]);
    }
};

/**
 * De-register a listener.
 *
 * @param {string} name Name of event.
 * @param {func} callback The callback function.
 */
const squelch = (name, callback) => {
    if (events[name]) {
        const index = events[name].indexOf(callback);

        if (index > -1) {
            events[name].splice(index, 1);
        }
    }
};

/**
 * Deliver message to event listeners.
 *
 * @param {string} name Name of event.
 * @param {any} data The data to send to listener.
 */
const deliver = (name, data) => {
    values[name] = data;

    if (events[name]) {
        events[name].forEach((cb) => cb(data));
    }
};

export default {
    EVENTS,
    deliver,
    listen,
    squelch
};
