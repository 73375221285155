import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ClearableField.css';

class ClearableField extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inputRef: props.inputRef || React.createRef(),
			refValue: props.initialValue || ''
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClearClick = this.handleClearClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.initialValue !== prevProps.initialValue) {
			this.setState({
				refValue: this.props.initialValue
			});
		}
	}

	handleClearClick() {
		const { inputRef } = this.state;
		const { postClearCallback } = this.props;

		inputRef.current.value = '';
		inputRef.current.focus();

		this.setState({ refValue: '' });

		postClearCallback && postClearCallback();
	}

	handleChange(event) {
		const { handleChange } = this.props;

		this.setState({ refValue: event.target.value });
		handleChange && handleChange(event);
	}

	renderClearBtn() {
		const { refValue } = this.state;
		const { clearClassName } = this.props;
		const clearClasses = classnames('x', 'fa', 'fa-times', 'pointer', clearClassName);

		return refValue ? <i className={clearClasses} onClick={this.handleClearClick} /> : null;
	}

	render() {
		const { inputRef, refValue } = this.state;
		const { children, className, inputClassName, name, placeholder } = this.props;

		const classes = classnames('ClearableField', className);
		const inputClasses = classnames('i', inputClassName);
		const inputProps = {};

		if (this.props.maxLength) inputProps.maxLength = this.props.maxLength;

		return (
			<div className={classes}>
				<input
					className={inputClasses}
					type="text"
					ref={inputRef}
					name={name}
					onChange={this.handleChange}
					placeholder={placeholder}
					value={refValue}
					{...inputProps}
				/>
				{this.renderClearBtn()}
				{children}
			</div>
		);
	}
}

ClearableField.displayName = 'ClearableField';

ClearableField.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	clearClassName: PropTypes.string,
	handleChange: PropTypes.func,
	initialValue: PropTypes.string,
	inputClassName: PropTypes.string,
	inputRef: PropTypes.object,
	maxLength: PropTypes.number,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	postClearCallback: PropTypes.func
};

export default ClearableField;
