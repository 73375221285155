import { Link } from 'react-router-dom';

export const CONFIGURABLE_LINK_TYPES = {
	ROUTER_LINK: {
		element: Link,
		elementPathAttr: 'to'
	},
	A: {
		element: 'a',
		elementPathAttr: 'href'
	}
};
