/*
	Amazon Web Services
	https://aws.amazon.com/
*/

import AWS from 'aws-sdk';

let service;

const init = (awsAppId, awsPoolId, awsRegion) => {
	const version = '1.0';

	if (AWS && window.AMA) {
		AWS.config.region = awsRegion;

		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: awsPoolId
		});

		const options = {
			appId: awsAppId,
			appVersionName: version
		};

		service = new window.AMA.Manager(options);
	}
};

const recordEvent = (name, attributes) => {
	if (service) {
		service.recordEvent(name, attributes);
	}
};

export default {
	init,
	recordEvent
};
