import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { isReceiveEntitiesAction } from '../actions/entity.actions';
import { ENTITIES } from '../const/entities/entities';
import { REDUCERS } from '../const/reducers.const';
import { receiveEntityObjects, receiveEntityArrayReplacement } from '../helpers/entity/entity.helpers';

export default (state = defaultState[REDUCERS.ADDRESS], action) => {
	const { data, type } = action;

	if (isReceiveEntitiesAction(type)) {
		return {
			...state,
			...receiveEntityObjects(state, data, ENTITIES.ADDRESS),
			...receiveEntityArrayReplacement(state, data, ENTITIES.ADDRESS_IDS),
		};
	}

	switch (type) {
		case ACTIONS.RECEIVE_ACCOUNT_LOGOFF: {
			return {
				...state,
				[ENTITIES.ADDRESS]: {},
				[ENTITIES.ADDRESS_IDS]: [],
			}
		}
		default:
			return state;
	}
};
