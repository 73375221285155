import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconRedo = (props) => (
	<Svg
		{...props}
		name="redo"
		viewBox="0 0 14 12"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.1419 5.41443L13.693 5.41443C13.8652 5.41697 14.0026 5.55261 14 5.71739C13.999 5.77781 13.9789 5.83652 13.9423 5.88576L11.8155 8.78333C11.72 8.91509 11.531 8.94781 11.3933 8.85642C11.3635 8.83663 11.3376 8.81187 11.317 8.78333L9.19014 5.88576C9.09034 5.75147 9.12318 5.56516 9.26349 5.46964C9.31494 5.43461 9.37629 5.41537 9.43941 5.41444L10.9129 5.41444C10.574 2.86221 8.13756 1.05616 5.47095 1.38052C2.80435 1.70488 0.91738 4.03682 1.25627 6.58905C1.56535 8.91673 3.63499 10.6611 6.08656 10.6601C6.4227 10.6601 6.69519 10.9209 6.69519 11.2427C6.69519 11.5644 6.4227 11.8252 6.08656 11.8252L6.08636 11.8252C2.72496 11.8252 -4.12975e-07 9.21711 -2.66043e-07 5.99988C-1.19112e-07 2.78264 2.72496 0.17456 6.08636 0.17456C9.21098 0.17456 11.828 2.43915 12.1419 5.41461L12.1419 5.41443Z"
			fill="inherit"
		/>
	</Svg>
);

IconRedo.displayName = 'IconRedo';

IconRedo.propTypes = iconProps;

IconRedo.defaultProps = defaultIconProps;

export default IconRedo;
