import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const orderDescriptorFromJson = (json = {}) => ({
	bookCreationModelType: deep(json, 'BookCreationModelType'),
	id: String(deep(json, 'Id')),
	masterOrderId: String(deep(json, 'MasterOrderId')),
	recentTransactionId: String(deep(json, 'RecentTransactionId')),
	subscriptionType: deep(json, 'SubscriptionType'),
});

export const orderDescriptorsFromJson = (jsonArray = []) => jsonArray.map((json) => orderDescriptorFromJson(json));

export const normalizeOrderDescriptors = (transactions) => {
	return normalize(transactions, orderDescriptorsSchema);
};

const orderDescriptorSchema = new schema.Entity(ENTITIES.ORDER_DESCRIPTORS, {});
const orderDescriptorsSchema = new schema.Array(orderDescriptorSchema);
