import { createSelector } from 'reselect';

import { selectAngularUrl, selectSiteUrl, selectUseHttps } from './config.selectors';
import { selectHappykitFlags } from './happykit.selectors';
import { sherpa } from '../sherpa';

const selectMakeItRainOrigin = selectSiteUrl;
const selectWebInfiniteOrigin = createSelector(
	selectUseHttps,
	selectAngularUrl,
	(useHttps, angularUrl) => `http${useHttps ? 's' : ''}://${angularUrl}`
);

export const selectMakeWebInfiniteLinkFn = createSelector(
	selectWebInfiniteOrigin,
	(webInfiniteOrigin) => (search) => `${webInfiniteOrigin}${search}`
);

export const selectSherpa = createSelector(
	selectMakeItRainOrigin,
	selectWebInfiniteOrigin,
	selectHappykitFlags,
	(makeItRainOrigin, infiniteOrigin, flags) => {
		return sherpa({
			infiniteOrigin,
			makeItRainOrigin,
			site: 'webInfinite',
			flags,
		});
	},
);
