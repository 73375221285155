import React from 'react';
import PropTypes from 'prop-types';
import MenuLink from './MenuLink';

export const SubMenu = ({ items }) => {
	if (items.length === 0) return null;

	return (
		<ul className="menu vertical dropdown">
			{items.map((link, i) => (
				<MenuLink
					key={i}
					analyticObj={{
						attr2: link.label
					}}
					{...link}
				/>
			))}
		</ul>
	);
};

SubMenu.propTypes = {
	items: PropTypes.array
};

SubMenu.defaultProps = {
	items: []
};

export default SubMenu;
