import React from 'react';
import PropTypes from 'prop-types';

export const Content = ({ content }) => {
	if (!content) return null;

	return <div className="content">{content}</div>;
};

Content.propTypes = {
	content: PropTypes.node
};

export default Content;
