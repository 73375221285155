import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { LOAD_STATUS } from '../const/loadStatus.const';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.PRICING], action) => {
	const { data, type } = action;

	switch (type) {
		case ACTIONS.LOAD_PRICING_INIT: {
			return {
				...state,
				loadPricingStatus: LOAD_STATUS.INITIALIZING
			};
		}
		case ACTIONS.LOAD_PRICING_SUCCESS: {
			const { pricing } = data;

			return {
				...state,
				loadPricingStatus: LOAD_STATUS.SUCCESS,
				pricing
			};
		}
		case ACTIONS.LOAD_PRICING_ERROR: {
			return {
				...state,
				loadPricingStatus: LOAD_STATUS.ERROR
			};
		}

		default:
			return state;
		}
};
