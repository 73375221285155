import styled from 'styled-components';

import PillButton from '../PillButton/PillButton';
import { COLOR } from '../../../const';

const DEFAULT_COLOR = COLOR.BLUE_500;

const HollowPillButton = styled(PillButton)`
	border: solid;
	background-color: ${COLOR.WHITE};
	border-width: 2px;
	color: ${(props) => props.color || DEFAULT_COLOR};
	border-color: ${(props) => props.borderColor || DEFAULT_COLOR};
	margin-left: ${(props) => props.marginLeft};

	&:hover {
		background-color: ${(props) => props.color || DEFAULT_COLOR};
		color: ${COLOR.WHITE};
	}
`;

HollowPillButton.displayName = 'HollowPillButton';

export default HollowPillButton;
