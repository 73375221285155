import PropTypes from 'prop-types';
import { useAppString } from '@chtbks/api';

const Translate = ({ id, params }) => {
	const appString = useAppString({ id, params });

	return appString;
};

Translate.displayName = 'Translate';

Translate.propTypes = {
	id: PropTypes.string,
	params: PropTypes.object,
};

export default Translate;
