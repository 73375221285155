import React from 'react';
import PropTypes from 'prop-types';

import { IconCircleMenuFilled, IconConnected, IconDisconnected, IconInstagramLogo, IconLogoTileTwoTone } from '../../chatponents';
import TwoGradientBackground from '../../components/Modals/InstagramLegacyReauthModal/TwoGradientBackground/TwoGradientBackground';
import './Banner.css';

const Banner = ({
	isFailure,
	isSuccess,
}) => (
	<TwoGradientBackground className="banner">
		<div className="icon-holder">
			<IconInstagramLogo
				color="#ffffff"
				size="40"
			/>

			{isFailure && (
				<IconCircleMenuFilled
					color="#ffffff"
					size="44"
				/>
			)}

			{isSuccess && (
				<IconConnected
					color="#ffffff"
					size="44"
				/>
			)}

			{!isFailure && !isSuccess && (
				<IconDisconnected
					color="#ffffff"
					size="44"
				/>
			)}

			<IconLogoTileTwoTone
				color="#00cfb4"
				size="40"
			/>
		</div>
	</TwoGradientBackground>
);

Banner.propTypes = {
	isFailure: PropTypes.bool,
	isSuccess: PropTypes.bool,
};

Banner.defaultProps = {
	isFailure: false,
	isSuccess: false,
};

Banner.displayName = 'Banner';

export default Banner;
