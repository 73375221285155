export const BOOK_CREATION_MODEL_TYPES = {
	MONTH_BOOK: 0,
	SIXTY_PAGE_SERIES: 1,
	SIXTY_PAGE_SERIES_GRANDFATHER: 2,
	CUSTOM_BOOK: 3,
	THIRTY_PAGE_HIGHLIGHT: 4,
	SERIES_PRINTS: 5,
	CUSTOM_PRINTS: 6,
	GIFT_CARD: 7,
	HIGHLIGHTS_GIFT_BOOK: 8,
	CARDS: 10,
	BOOK_SUBSCRIPTION_GROUP: 11, // Monthly mini sub
	SUBSCRIPTION_BOOK: 12, // Monthly mini individual book
	ACCESSORY: 13,
	WALL_PRINT: 14,
	LAYFLAT: 15,
	GALLERY_WALL_TILE: 16,
};
