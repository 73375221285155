import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { REDUCERS } from '../const/reducers.const';

export const selectLocationState = (state) => deep(state, `${REDUCERS.LOCATION}`);

export const selectLocationQueryParams = createSelector(
	selectLocationState,
	(locationState) => deep(locationState, 'queryParams') || {}
);
