import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CarouselPaginationButton from './CarouselPaginationButton/CarouselPaginationButton';
import withCarouselContext from '../withCarouselContext';

import './CarouselPagination.css';

const CarouselPagination = ({
	className,
	color,
	context,
	onGoToPage,
}) => {
	const { pageCount, pageNumber } = context;

	const arraySizeOfPages = new Array(pageCount).fill(true);

	return <div className={classnames('CarouselPagination', className)}>
		{arraySizeOfPages.map((_, index) => (
			<CarouselPaginationButton
				key={`CarouselPaginationButton-${index}`}
				active={pageNumber === index}
				color={color}
				onClick={() => onGoToPage(index, true)}
			/>
		))}
	</div>;
};

CarouselPagination.displayName = 'CarouselPagination';

CarouselPagination.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	context: PropTypes.object,
	onGoToPage: PropTypes.func
};

export default withCarouselContext(CarouselPagination);
