import deep from 'deep-get-set';
import { first, range } from 'lodash';

import { normalize, schema } from 'normalizr';
import { ENTITIES } from '../const/entities/entities';

export const productPriceTiersFromJson = (json = []) => {

	const mapped = json.map((item) => ({
		productId: String(deep(item, 'ProductId')),
		minQuantity: deep(item, 'MinQuantity'),
		price: deep(item, 'Price'),
	}));

	const sortedAsc = mapped.sort((a, b) => a.minQuantity - b.minQuantity);

	const maxPrice = first(sortedAsc).price;

	const inclusiveOne = 1;
	const quantityOptions = range(1, 99 + inclusiveOne)
		.map((quantity) => ({
			price: sortedAsc.reduce((matchedPrice, tier) => (tier.minQuantity <= quantity) ? tier.price : matchedPrice, Number.MAX_SAFE_INTEGER),
			quantity,
		}));

	return {
		maxPrice,
		productId: first(sortedAsc).productId,
		tiers: quantityOptions,
	};
};

const productPriceTiersSchema = new schema.Entity(ENTITIES.PRODUCT_PRICE_TIER, {}, { idAttribute: 'productId' });

export const normalizeProductPriceTiers = (productPriceTiers) => {
	return normalize(productPriceTiers, productPriceTiersSchema);
};