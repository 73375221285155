export default [
	{
		title: 'Simply tap below to connect your Instagram account',
		image: 'https://cdn.chatbooks.com/mk/2002-IG-series-PDP-21.png',
	},
	{
		title: 'Your posts are automatically pulled into 60 page photo books',
		image: 'https://cdn.chatbooks.com/mk/2002-IG-series-PDP-22.png',
	},
	{
		title: 'It\'s that easy! Books are sent to you with free shipping',
		image: 'https://cdn.chatbooks.com/mk/2002-IG-series-PDP-23.png',
	},
];
