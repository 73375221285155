import { firstItemInArray } from '../arrays/arrays';

/**
 * Converts an array of step names
 *
 * ['step1', 'step2', 'step3', 'step4']
 *
 * to a map of one step to the next
 *
 * {
 * 	'step1': 'step2',
 * 	'step2': 'step3',
 * 	'step3': 'step4',
 * 	'step4': undefined,
 * }
 * @param {array} steps An array of step names/ids
 */
export const convertToNextStepMap = (steps) => {
	return steps.reduce((acc, step, index) => ({
		...acc,
		[step]: steps[index + 1]
	}), {});
};

/**
 * Converts a limited set of steps to a map of all steps pointing to
 * The limited set of available steps
 *
 * Converts an array of available steps
 * ['step2', 'step3', 'step5']
 * ['step1', 'step2', 'step3', 'step4', 'step5']
 *
 * {
 * 	'step1': 'step2', // step1 isn't available, but will point to first available step2
 * 	'step2': 'step3',
 * 	'step3': 'step5', // skips step4, since it's not available
 * 	'step4': 'step5',
 * 	'step5': undefined,
 * }
 *
 * @param {array} availableSteps A set of available steps in order
 * @param {array} allSteps A set of all steps in order
 */
export const convertToLimitedNextStepMap = (availableSteps, allSteps) => {
	const nextAvailableStepMap = convertToNextStepMap(availableSteps);

	const reduced = allSteps.reduce(({ nextSteps, currentNextStep }, step) => {
		const nextNextStep = step === availableSteps[availableSteps.length - 1] ? undefined
			: nextAvailableStepMap[step] ? nextAvailableStepMap[step]
			: currentNextStep;

		return {
			currentNextStep: nextNextStep,
			nextSteps: {
				...nextSteps,
				[step]: nextNextStep,
			},
		}
	}, {
		currentNextStep: firstItemInArray(availableSteps),
		nextSteps: {},
	});

	return reduced.nextSteps;
};

/**
 * Given a step, step to next step map, and step to continue to app string.
 *
 * This will get the Continue to {step} app string value for the current step.
 *
 * @param {string} step The step being displayed
 * @param {object} nextStepMap A map of on step to the next step
 * @param {object} continueTextMap A map of the next step to an app string
 */
export const checkoutContinueButtonAppString = (step, nextStepMap, continueTextMap) => {
	return continueTextMap[nextStepMap[step]];
};