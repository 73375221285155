import React from 'react';
import PropTypes from 'prop-types';

import { convertActionUri, convertImageUrl } from '../DuploBlock.helpers';
import MaybeLinkWrap from '../../MaybeLinkWrap/MaybeLinkWrap';
import './Tile.css';

const Tile = ({
	actionUri,
	analyticEvent,
	analyticObj,
	description,
	imageURLs,
	linkCallback,
	primary,
	tagline,
}) => {
	if (imageURLs.length === 0) return null;

	return (
		<div className="duplo-block duplo-block-tile">
			<MaybeLinkWrap
				analyticEvent={analyticEvent}
				analyticObj={analyticObj}
				linkCallback={linkCallback}
				path={actionUri && convertActionUri(actionUri)}
			>
				<img
					alt=""
					className="tile-image"
					src={convertImageUrl(imageURLs[0])}
				/>
				<div className="tile-content">
					<p className="primary">{primary}</p>
					<p className="tagline">{tagline}</p>
					<p className="description">{description}</p>
				</div>
			</MaybeLinkWrap>
		</div>
	);
};

Tile.propTypes = {
	actionUri: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	description: PropTypes.string,
	imageURLs: PropTypes.arrayOf(PropTypes.string),
	linkCallback: PropTypes.func,
	primary: PropTypes.string,
	tagline: PropTypes.string,
};

Tile.defaultProps = {
	imageURLs: []
};

export default Tile;
