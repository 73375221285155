import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLayoutCollage = (props) => (
	<Svg
		{...props}
		name="layout-collage"
		hasStroke={true}
		viewBox="0 0 30 30"
	>
		<rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="inherit" fill="none" />
		<rect x="4" y="4" width="10" height="10" rx="2" fill="inherit" />
		<rect x="16" y="4" width="10" height="10" rx="2" fill="inherit" />
		<rect x="4" y="16" width="10" height="10" rx="2" fill="inherit" />
		<rect x="16" y="16" width="10" height="10" rx="2" fill="inherit" />
	</Svg>
);

IconLayoutCollage.displayName = 'IconLayoutCollage';

IconLayoutCollage.propTypes = iconProps;

IconLayoutCollage.defaultProps = defaultIconProps;

export default IconLayoutCollage;
