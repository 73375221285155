import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCaretDown = (props) => (
	<Svg
		{...props}
		name="caret-down"
		viewBox="0 0 24 24"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.5278 16.2862C12.2352 16.5792 11.7605 16.5795 11.4676 16.2869C11.4678 16.2871 11.4674 16.2867 11.4676 16.2869L5.84025 10.6567C5.5433 10.3676 5.5369 9.89256 5.82594 9.59561C6.11499 9.29866 6.59004 9.29226 6.88699 9.58131C6.89175 9.58594 6.89645 9.59064 6.90109 9.5954L11.9973 14.6942L17.0936 9.5954C17.3824 9.29824 17.8575 9.29149 18.1547 9.58035C18.4518 9.8692 18.4586 10.3443 18.1697 10.6414C18.1647 10.6466 18.1597 10.6517 18.1545 10.6567L12.5278 16.2862Z"
			fill="inherit"
		/>
	</Svg>
);

IconCaretDown.displayName = 'IconCaretDown';

IconCaretDown.propTypes = iconProps;

IconCaretDown.defaultProps = defaultIconProps;

export default IconCaretDown;
