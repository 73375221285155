import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';
import { ENTITIES } from '../const/entities/entities';

export const coverBundleFromJson = (json = {}) => ({
	id: deep(json, 'Id'),
	name: deep(json, 'Name'),
	available: deep(json, 'Available'),
	surcharge: deep(json, 'Surcharge'),
	supportedBookSizes: deep(json, 'SupportedBookSizes'),
});

export const coverBundleSchema = new schema.Entity(ENTITIES.COVER_BUNDLE);

export const coverBundlesSchema = new schema.Array(coverBundleSchema);

export const normalizeCoverBundle = (coverBundle) => normalize(coverBundle, coverBundlesSchema);

export const isVolumeUsingCoverBundle = (coverBundle, volume) => {
	const { endVolume, startVolume } = coverBundle;

	const isWithinEndVolume = endVolume === null || (Number.isInteger(endVolume) && volume <= endVolume);
	const isWithinStartVolume = startVolume !== null && Number.isInteger(startVolume) && volume >= startVolume;

	return isWithinStartVolume && isWithinEndVolume;
}
