export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);

	return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
};

export function getNumberWithin(num, min, max) {
	const MIN = min || 1;
	const MAX = max || 20;
	const parsed = parseInt(num)

	return Math.min(Math.max(parsed, MIN), MAX)
};
