import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';
import dateFormatter from '../helpers/cms/dateFormatter';

export default (state = defaultState[REDUCERS.BLOG_POST], action) => {
	const { type } = action;

	switch (type) {
		case ACTIONS.FETCH_BLOG_POST:
			return defaultState.blogPost;

		case ACTIONS.RECEIVE_BLOG_POST: {
			const { blogPost } = action;
			const date = (blogPost.data && blogPost.data.posted_date) || blogPost.first_publication_date;

			return {
				...blogPost,
				formattedDate: date && dateFormatter(date)
			};
		}

		default:
			return state;
	}
};
