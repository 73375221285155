export const DEVICE_WIDTH = {
	MOBILE_S: 320,
	MOBILE_M: 375,
	MOBILE_L: 425,
	TABLET: 768,
	LAPTOP: 1024,
	LAPTOP_L: 1440,
	DESKTOP: 2560,
};

export const WIDTH_CONSTRAINT = 1500;
export const WIDTH_CONSTRAINT_PADDED = 1560;

const mediaQueryRange = (width1, width2) => {
	if (!width2) return `(min-width: ${width1}px)`;

	return `(min-width: ${width1}px) and (max-width: ${width2 - 1}px)`;
};

export const mediaQueryUpTo = (width) => {
	return `@media ${mediaQueryRange(0, width)}`;
};

export const mediaQueryStartingFrom = (width) => {
	return `@media ${mediaQueryRange(width)}`;
};

export const mediaQueryBetween = (width1, width2) => {
	return `@media ${mediaQueryRange(width1, width2)}`;
};

