import PropTypes from 'prop-types';
import { objectHasEveryProperty } from '@chtbks/helpers';

/**
 * Creates an AppString object.
 *
 * @param {object} appString The AppString object.
 * @param {string} appString.id The id of the AppString.
 * @param {object} appString.params The params for the AppString. (Optional)
 */
export const appStringModel = ({id, params}) => ({ id, params });

export const isAppString = (possibleAppString) => objectHasEveryProperty(possibleAppString, ['id']);

export const appStringPropType = PropTypes.oneOfType([
	PropTypes.shape({
		id: PropTypes.string,
		params: PropTypes.object
	}),
	PropTypes.string
]);

export const maybeAppStringPropType = PropTypes.oneOfType([
	PropTypes.arrayOf(() => maybeAppStringPropType),
	appStringPropType,
	PropTypes.string
]);
