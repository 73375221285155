import React from 'react';
import PropTypes from 'prop-types';

import './DuploBlock.css';
import Badges from './Badges/Badges';
import Bullets from './Bullets/Bullets';
import FullWidthImage from './FullWidthImage/FullWidthImage';
import Image from './Image/Image';
import ImageGallery from './ImageGallery/ImageGallery';
import ImageWithCaption from './ImageWithCaption/ImageWithCaption';
import LargeBullets from './LargeBullets/LargeBullets';
import Tile from './Tile/Tile';
import Quote from './Quote/Quote';
import Space from './Space/Space';
import Separator from './Separator/Separator';
import Text from './Text/Text';
import TwoImage from './TwoImage/TwoImage';

const DuploBlock = (data) => {
	if ('badges' in data) return (<Badges {...data} />);
	if ('bullets' in data) return (<Bullets {...data} />);
	if ('fullWidthImage' in data) return (<FullWidthImage {...data} />);
	if ('imageCaption' in data) return (<ImageWithCaption {...data} />);
	if ('galleryImages' in data) return (<ImageGallery {...data} />);
	if ('largeBullets' in data) return (<LargeBullets {...data} />);
	if ('quote' in data) return (<Quote {...data} />);
	if ('space' in data) return (<Space {...data} />);
	if ('separator' in data) return (<Separator {...data} />);
	if ('text' in data) return (<Text {...data} />);
	if ('caption1' in data) return (<TwoImage {...data} />);
	if ('image' in data) return (<Image {...data} />);
	if ('type' in data && data.type === 'tile') return (<Tile {...data} />);

	return null;
};

DuploBlock.displayName = 'DuploBlock';

DuploBlock.propTypes = {
	data: PropTypes.object,
};

export default DuploBlock;
