import React from 'react';
import PropTypes from 'prop-types';

import ConfigurableLink from '../ConfigurableLink/ConfigurableLink';
import { CONFIGURABLE_LINK_TYPES } from '../../const';

export const MaybeLinkWrap = ({
	analyticEvent,
	analyticObj,
	className,
	href,
	linkCallback,
	linkType,
	path: pathProp,
	target,
	children,
}) => {
	const path = href || pathProp;

	if (!path) {
		return <span className={className}>{children}</span>;
	}

	return (
		<ConfigurableLink
			analyticEvent={analyticEvent}
			analyticObj={analyticObj}
			className={className}
			linkCallback={linkCallback}
			linkElement={linkType.element}
			linkElementPathAttr={linkType.elementPathAttr}
			path={path}
			target={target}
		>
			{children}
		</ConfigurableLink>
	);
};

MaybeLinkWrap.displayName = 'MaybeLinkWrap';

MaybeLinkWrap.propTypes = {
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	children: PropTypes.node,
	className: PropTypes.string,
	linkCallback: PropTypes.func,
	linkType: PropTypes.shape({
		element: PropTypes.any,
		elementPathAttr: PropTypes.string
	}),
	path: PropTypes.string,
	href: PropTypes.string,
	target: PropTypes.string,
};

MaybeLinkWrap.defaultProps = {
	linkType: CONFIGURABLE_LINK_TYPES.A
};

export default MaybeLinkWrap;
