import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Banner from './Banner';
import PhotoSources from './PhotoSources';
import * as analyticsActions from '../../../actions/analytics.actions';
import './QuickFlow.css';

class QuickFlowLanding extends Component {
	componentDidMount() {
		this.props.analyticsActions.triggerAnalyticsEvent('QuickFlowLanding_Load');
	}

	render() {
		return (
			<div className="quickflow-landing">
				<Banner />
				<PhotoSources />
			</div>
		);
	}
};

QuickFlowLanding.propTypes = {
	analyticsActions: PropTypes.object,
};

QuickFlowLanding.displayName = 'QuickFlowLanding';

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
});

export default connect(
	null,
	mapDispatchToProps
)(QuickFlowLanding);
