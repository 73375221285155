import React from 'react';
import PropTypes from 'prop-types';
import defaultIconProps from '../_defaultIconProps';
import iconProps from '../_iconProps';
import Svg from '../Svg';

export const IconCardBackLandscape2Photos = (props) => {
	const { size } = props;
	const heightRatio = 100;
	const widthRatio = 140;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="IconCardBackLandscape2Photos"
			width={width}
			height={height}
			viewBox="0 0 140 100"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <rect fill={props.color} x="0" y="0" width="140" height="100" />
		        <rect fill={props.color2} x="12" y="16" width="37" height="68" />
		        <rect fill={props.color2} x="54" y="16" width="37" height="68" />
		        <rect fill={props.color2} x="100" y="36" width="26" height="2" />
		        <rect fill={props.color2} x="100" y="55" width="26" height="2" />
		        <rect fill={props.color2} x="100" y="48" width="26" height="2" />
		        <polygon fill={props.color2} points="100 42 123 42 123 44 100 44" />
		        <polygon fill={props.color2} points="100 61 123 61 123 63 100 63" />
		    </g>
		</Svg>
	);
};

IconCardBackLandscape2Photos.displayName = 'IconCardBackLandscape2Photos';

IconCardBackLandscape2Photos.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconCardBackLandscape2Photos.defaultProps = {
	...defaultIconProps,
	color: '#ffffff',
	color2: '#d8d8d8',
};

export default IconCardBackLandscape2Photos;
