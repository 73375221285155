import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconBook = (props) => (
	<Svg
		{...props}
		name="book"
		viewBox="0 0 120 120"
	>
		<path
			d="M91.66,101.31H28.34a1.88,1.88,0,0,1-1.88-1.88V36.12a1.88,1.88,0,0,1,1.88-1.88H91.66a1.88,1.88,0,0,1,1.88,1.88V99.43A1.88,1.88,0,0,1,91.66,101.31ZM30.23,97.55H89.77V38H30.23Z"
		/>
		<path
			d="M36.92,101.31A1.88,1.88,0,0,1,35,99.43V36.12a1.88,1.88,0,0,1,3.76,0V99.43A1.88,1.88,0,0,1,36.92,101.31Z"
		/>
		<path
			d="M28.35,38a1.88,1.88,0,0,1-.54-3.69L79.94,18.76a1.89,1.89,0,0,1,1.66.3,1.86,1.86,0,0,1,.76,1.51v8.25a1.88,1.88,0,1,1-3.76,0V23.1L28.88,37.92A2,2,0,0,1,28.35,38Z"
		/>
		<path
			d="M28.34,38a1.88,1.88,0,0,1-.26-3.75l57.59-8.06a1.9,1.9,0,0,1,1.5.45,1.87,1.87,0,0,1,.64,1.42v8.06a1.88,1.88,0,1,1-3.76,0v-5.9L28.61,38A1.23,1.23,0,0,1,28.34,38Z"
		/>
	</Svg>
);

IconBook.displayName = 'IconBook';

IconBook.propTypes = iconProps;

IconBook.defaultProps = defaultIconProps;

export default IconBook;
