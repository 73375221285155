import deep from 'deep-get-set';

import * as analyticsActions from './analytics.actions';
import * as api from './api/api.actions';
import * as appActions from './app.actions';
import * as entityActions from './entity.actions';
import * as loadingActions from './loading.actions';
import * as viewActions from './view.actions';
import { ENTITIES } from '../const/entities/entities';
import { FORM_FIELDS } from '../const/formFields.const';
import { MODAL_NAMES } from '../const/modalNames.const';
import { CHOOSE_PAYMENT_OPTIONS, NEW_PAYMENT_OPTION, NEW_PAYPAL_PAYMENT_METHOD_ID, CHOOSE_PAYMENT_OPTIONS_ONLY_NEW_PAYMENT_METHODS, CHOOSE_PAYMENT_OPTIONS_WITHOUT_GIFT_CARD_OR_CHATBOOKS_CREDIT, PAYMENT_TYPE_TO_ANALYTICS_TYPE_MAP, NEW_PAYMENT_OPTION_TO_ANALYTICS_TYPE_MAP } from '../const/payment.const';
import { isString } from '../helpers/strings/strings';
import { modalModel } from '../model/modal.model';
import { normalizePaymentMethods } from '../model/paymentMethod.model';
import { paymentMethodSelectionModalModel } from '../model/paymentMethodSelectionModal.model';
import { queryClient } from '../react-query/queryClient';
import { selectChatbooksApi } from '../selectors/chatbooksApi.selectors';
import * as paymentMethodState from '../selectors/paymentMethod.selectors';

export const buildAttributesForPaymentMethodSelectionEvent = (paymentMethodSelection, maybePaymentMethodId) => (dispatch, getState) => {
	const attrs = {};
	const paymentMethods = paymentMethodState.selectPaymentMethodMap(getState());

	if (paymentMethods[paymentMethodSelection]) {
		const paymentMethod = paymentMethods[paymentMethodSelection];
		attrs.attr1 = PAYMENT_TYPE_TO_ANALYTICS_TYPE_MAP[paymentMethod.type];
		attrs.attr2 = paymentMethod.id;
	} else {
		attrs.attr1 = NEW_PAYMENT_OPTION_TO_ANALYTICS_TYPE_MAP[paymentMethodSelection];
		attrs.attr2 = maybePaymentMethodId;
	}

	return attrs;
};

export const createCreditCard = (form) => (dispatch) => {
	const creditCardField = deep(form, `${FORM_FIELDS.CREDIT_CARD}`);

	return dispatch(saveBraintreeCreditCard(creditCardField));
};

export const loadPaymentMethods = () => (dispatch, getState) => {
	dispatch(loadingActions.loadingInit(loadingActions.LOADING_KEYS.LOAD_PAYMENT_METHODS));

	const api = selectChatbooksApi(getState());

	return api.paymentMethods.getPaymentMethods()
		.then((paymentMethods) => {
			const normalized = normalizePaymentMethods(paymentMethods);

			const entities = {
				...normalized.entities,
				[ENTITIES.PAYMENT_METHOD_IDS]: normalized.result,
			};

			dispatch(entityActions.receiveEntities(entities, loadPaymentMethods.name));
			dispatch(loadingActions.loadingSuccess(loadingActions.LOADING_KEYS.LOAD_PAYMENT_METHODS));
		})
		.catch((err) => dispatch(loadingActions.loadingError(loadingActions.LOADING_KEYS.LOAD_PAYMENT_METHODS, err)));
};

export const maybeSaveChosenPaymentMethod = (form) => (dispatch) => {
	dispatch(loadingActions.loadingClearErrors());

	const { NEW_CREDIT_CARD, NEW_PAYPAL, REDEEM_GIFT_CARD } = NEW_PAYMENT_OPTION;
	const { USER_CREDIT } = CHOOSE_PAYMENT_OPTIONS;
	const paymentSelection = deep(form, `${FORM_FIELDS.PAYMENT_SELECTION}`);

	if (paymentSelection === USER_CREDIT) return Promise.resolve();
	if (paymentSelection === NEW_CREDIT_CARD) return dispatch(createCreditCard(form));
	if (paymentSelection === REDEEM_GIFT_CARD) return dispatch(redeemGiftCard(deep(form, `${FORM_FIELDS.GIFT_CARD_CODE}`)));
	if (paymentSelection === NEW_PAYPAL) return Promise.resolve(deep(form, `${NEW_PAYPAL_PAYMENT_METHOD_ID}`));
	if (isString(paymentSelection)) return Promise.resolve(paymentSelection);

	return Promise.resolve();
};

export const openPaymentMethodSelectionModal = (paymentMethodSelectionModal) => (dispatch) => {
	const data = paymentMethodSelectionModal || paymentMethodSelectionModalModel();

	return dispatch(viewActions.openModalById(modalModel({
		id: MODAL_NAMES.PAYMENT_METHOD_SELECTION,
		data,
	})));
};

export const openPaymentMethodSelectionOnlyNewOptionsModal = (initialPaymentMethodId) => (dispatch) => {
	return new Promise((resolve) => {
		return dispatch(openPaymentMethodSelectionModal(paymentMethodSelectionModalModel({
			initialPaymentId: initialPaymentMethodId,
			doneCallback: resolve,
			enabledFields: CHOOSE_PAYMENT_OPTIONS_ONLY_NEW_PAYMENT_METHODS,
		})));
	});
};

export const openPaymentMethodSelectionWithoutGiftCardModal = (initialPaymentMethodId) => (dispatch) => {
	return new Promise((resolve) => {
		return dispatch(openPaymentMethodSelectionModal(paymentMethodSelectionModalModel({
			initialPaymentId: initialPaymentMethodId,
			doneCallback: resolve,
			enabledFields: CHOOSE_PAYMENT_OPTIONS_WITHOUT_GIFT_CARD_OR_CHATBOOKS_CREDIT,
		})));
	});
};

const redeemGiftCard = (code) => (dispatch, getState) => {
	return dispatch(api.postRedeemGiftCard(code))
		.then(() => {
			const api = selectChatbooksApi(getState());
			queryClient.invalidateQueries(api.credits.getCreditBalanceLocalized.queryKey());
		});
};

export const saveBraintreeCreditCard = (creditCardField) => (dispatch) => {
	const braintree = deep(creditCardField, `${FORM_FIELDS.BRAINTREE}`) || (() => Promise.reject('braintree not set'));

	return braintree.getToken()
		.then(({ nonce }) => dispatch(saveBraintreePaymentMethod(nonce, loadingActions.LOADING_KEYS.SAVE_BRAINTREE_PAYMENT_METHOD)));
};

export const saveBraintreePaymentMethod = (paymentMethodNonce, loadingKey) => (dispatch, getState) => {
	dispatch(loadingActions.loadingInit(loadingKey));

	const api = selectChatbooksApi(getState());

	return api.paymentMethods.addBraintreePaymentMethod(paymentMethodNonce)
		.then((paymentMethod) => {
			return dispatch(loadPaymentMethods())
				.then(() => {
					dispatch(loadingActions.loadingSuccess(loadingKey));

					return paymentMethod.id;
				});
		})
		.catch((err) => {
			dispatch(loadingActions.loadingError(loadingKey, err));
			throw err;
		});
};

export const submitPaymentMethodModalForm = (form) => (dispatch) => {
	dispatch(appActions.showThrobber(submitPaymentMethodModalForm.name));

	return dispatch(maybeSaveChosenPaymentMethod(form))
		.finally(() => dispatch(appActions.hideThrobber(submitPaymentMethodModalForm.name)));
};

export const tagPaymentMethodChooseMethod = (paymentMethodSelection, eventName, attrs) => (dispatch) => {
	return dispatch(analyticsActions.triggerAnalyticsEvent(eventName, {
		...dispatch(buildAttributesForPaymentMethodSelectionEvent(paymentMethodSelection)),
		...attrs,
	}));
};
