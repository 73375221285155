import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ConfigurableLink from '../../ConfigurableLink/ConfigurableLink';
import addLineBreaks from '../../../helpers/addLineBreaks';
import { getImage } from '../multiColumnHelper';
import EmbeddedVideo from '../../EmbeddedVideo/EmbeddedVideo';

import './Column.css';

class Column extends Component {
	getImageElem(imageUrl, imageText, isImageOnly, imageSize) {
		const className = classnames('column-header-image', isImageOnly ? 'image-only' : null);
		if (/^http[s]?:\/\/(?:youtu\.be|www\.youtube\.com)/.test(imageUrl)) {
			return (
				<div className={className}>
					<EmbeddedVideo url={imageUrl} />
				</div>
			);
		}

		return getImage(imageUrl, imageText, className, imageSize);
	}

	render() {
		const {
			action,
			buttonMargin,
			buttonStyle,
			buttonText,
			buttonUrl,
			color,
			children,
			imageSize,
			imageText,
			imageUrl,
			linkElement,
			linkElementPathAttr,
			onButtonClick,
			preContent,
			preContentElement: PreContentElement = 'h5',
			textAlign,
			title,
			titleElement: TitleElement = 'h4',
			verticalAlign
		} = this.props;
		const preContentCap = preContent ? (
			<PreContentElement className="pre-content-cap">{preContent}</PreContentElement>
		) : null;
		const titleElem = title ? <TitleElement className="title">{addLineBreaks(title)}</TitleElement> : null;
		const buttonContent = buttonText ? (
			<div className="column-button" style={{ marginTop: buttonMargin }}>
				<ConfigurableLink
					action={action}
					clickHandler={onButtonClick}
					linkElement={linkElement}
					linkElementPathAttr={linkElementPathAttr}
					path={buttonUrl}
					className={classnames('button large', buttonStyle)}
				>
					{buttonText}
				</ConfigurableLink>
			</div>
		) : null;
		const childrenContent = React.Children.count(children) ? <div>{children}</div> : null;
		const image = this.getImageElem(imageUrl, imageText, !preContentCap && !titleElem && !childrenContent, imageSize);
		const classes = ['Column'];
		if (textAlign) {
			classes.push(`text-${textAlign}`);
		}
		if (verticalAlign) {
			classes.push(`vertical-align-${verticalAlign}`);
		}

		return (
			<div className={classes.join(' ')} style={{ color }}>
				{image}
				{preContentCap}
				{titleElem}
				{childrenContent}
				{buttonContent}
			</div>
		);
	}
}

Column.displayName = 'Column';

Column.propTypes = {
	action: PropTypes.string,
	buttonMargin: PropTypes.string,
	buttonStyle: PropTypes.string,
	buttonText: PropTypes.any,
	buttonUrl: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.string,
	imageSize: PropTypes.string,
	imageText: PropTypes.string,
	imageUrl: PropTypes.string,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	onButtonClick: PropTypes.func,
	onClick: PropTypes.func,
	path: PropTypes.string,
	preContent: PropTypes.string,
	preContentElement: PropTypes.any,
	ratio: PropTypes.number,
	textAlign: PropTypes.string,
	title: PropTypes.string,
	titleElement: PropTypes.any,
	verticalAlign: PropTypes.string
};

export default Column;
