import React from 'react';
import PropTypes from 'prop-types';
import defaultIconProps from '../_defaultIconProps';
import iconProps from '../_iconProps';
import Svg from '../Svg';

export const IconCardBackLandscape3Photos = (props) => {
	const { size } = props;
	const heightRatio = 100;
	const widthRatio = 140;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="IconCardBackLandscape3Photos"
			width={width}
			height={height}
			viewBox="0 0 140 100"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <rect fill={props.color} x="0" y="0" width="140" height="100" />
		        <rect fill={props.color2} x="7" y="7" width="41" height="50" />
		        <rect fill={props.color2} x="50" y="7" width="40" height="50" />
		        <rect fill={props.color2} x="92" y="7" width="41" height="50" />
		        <polygon fill={props.color2} points="40 68 100 68 100 70 40 70" />
		        <polygon fill={props.color2} points="40 81 100 81 100 83 40 83" />
		        <rect fill={props.color2} x="45" y="74" width="50" height="2" />
		    </g>
		</Svg>
	);
};

IconCardBackLandscape3Photos.displayName = 'IconCardBackLandscape3Photos';

IconCardBackLandscape3Photos.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconCardBackLandscape3Photos.defaultProps = {
	...defaultIconProps,
	color: '#ffffff',
	color2: '#d8d8d8',
};

export default IconCardBackLandscape3Photos;
