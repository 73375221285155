import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HollowPillButton from '../../../components/Button/HollowPillButton/HollowPillButton';
import { DEVICE_WIDTH, mediaQueryBetween, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../../const';

const CtaHalfDiv = styled('div')`
	grid-area: ${(props) => props.gridArea || 'r'};
	text-align: ${(props) => props.textAlign};
	display: grid;
	grid-template-columns: 1fr;
	align-content: center;
	justify-content: ${(props) => props.textAlign};
	justify-items: ${(props) => props.textAlign};
	justify-self: ${(props) => props.textAlign};

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		padding: ${SPACING.BIG} 4vw;
		row-gap: ${SPACING.SMALLER};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		padding: 0;
		padding-left: 4vw;
		padding-right: 4vw;
		row-gap: ${SPACING.MEDIUM};
	}
`;

const TitleDiv = styled('div')`
	color: ${(props) => props.color};
	font-size: 28px;
	font-weight: 700;
	line-height: 41px;
	letter-spacing: -0.5px;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 28px;
	}
	${mediaQueryBetween(DEVICE_WIDTH.TABLET, DEVICE_WIDTH.LAPTOP_L)} {
		font-size: 30px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.LAPTOP_L)} {
		font-size: 32px;
	}
`;

const DescriptionDiv = styled('div')`
	color: rgba(51, 51, 51, .8);
	font-weight: 450;
	line-height: 27px;
	letter-spacing: 0px;
	max-width: ${((props) => props.textAlign !== 'left' ? '420px' : '500px')};

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 16px;
	}
	${mediaQueryBetween(DEVICE_WIDTH.TABLET, DEVICE_WIDTH.LAPTOP_L)} {
		font-size: 17px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.LAPTOP_L)} {
		font-size: 18px;
	}
`;

const CtaButton = styled(HollowPillButton)`
	margin-bottom: 0;
	margin-top: ${SPACING.SMALL};
	margin-left: ${(props) => props.marginLeft};
	margin-right: ${(props) => props.marginRight};
`;

const CtaHalf = ({
	ctaText,
	ctaUrl,
	description,
	gridArea,
	isMobile,
	linkCallback,
	textAlign,
	textColor,
	title,
}) => (
	<CtaHalfDiv
		gridArea={gridArea || 'r'}
		textAlign={isMobile ? 'left' : textAlign}>
		<TitleDiv
			color={textColor}
			textAlign={textAlign}>
			{title}
		</TitleDiv>
		<DescriptionDiv>
			{description}
		</DescriptionDiv>
		<CtaButton
			color={textColor}
			borderColor={textColor}
			marginLeft={(textAlign === 'left' || isMobile) ? 0 : 'auto'}
			marginRight="auto"
			onClick={() => linkCallback(ctaUrl)}>
			{ctaText}
		</CtaButton>
	</CtaHalfDiv>
);

CtaHalf.displayName = 'CtaHalf';

CtaHalf.propTypes = {
	ctaText: PropTypes.string,
	ctaUrl: PropTypes.string,
	description: PropTypes.string,
	gridArea: PropTypes.string,
	isMobile: PropTypes.bool,
	linkCallback: PropTypes.func,
	textAlign: PropTypes.string,
	textColor: PropTypes.string,
	title: PropTypes.string,
};

export default CtaHalf;