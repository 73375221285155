import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconPreview = (props) => (
	<Svg
		{...props}
		name="preview"
		viewBox="0 0 18 14"
	>
		<path
			d="M9 14c-2.44 0-4.515-.96-6.227-2.611A12.621 12.621 0 0 1 .168 7.751a1.763 1.763 0 0 1 0-1.5 12.622 12.622 0 0 1 2.605-3.64C4.485.961 6.56 0 9 0c2.44 0 4.515.96 6.227 2.611a12.62 12.62 0 0 1 2.605 3.638 1.763 1.763 0 0 1 0 1.5 12.622 12.622 0 0 1-2.605 3.64C13.515 13.039 11.44 14 9 14zm0-3a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
			fill="inherit"
			fillRule="evenodd"
		/>
	</Svg>
);

IconPreview.displayName = 'IconPreview';

IconPreview.propTypes = iconProps;

IconPreview.defaultProps = defaultIconProps;

export default IconPreview;
