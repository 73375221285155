import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.ANALYTICS], action) => {
	const { data, type } = action;
	switch (type) {
		case ACTIONS.ANALYTICS_ENQUEUE_EVENT: {
			const { event, queueName } = data;

			const currentQueue = deep(state, `queues.${queueName}`) || [];
			const updatedQueue = [...currentQueue, event];

			return {
				...state,
				queues: {
					...state.queues,
					[queueName]: updatedQueue,
				},
			};
		}
		case ACTIONS.ANALYTICS_FLUSH_EVENT_QUEUE: {
			const { queueName } = data;

			return {
				...state,
				queues: {
					...state.queues,
					[queueName]: [],
				}
			};
		}
		default:
			return state;
	}
};
