import deep from 'deep-get-set';

import * as actionUriActions from './actionUri.actions';
import { apiGetData } from './api/api.http.actions';
import * as api from './api/api.actions';
import * as appActions from './app.actions';
import * as dataSourceActions from './dataSource/dataSource.actions';
import * as entityActions from './entity.actions';
import * as viewActions from './view.actions';
import { DATA_SOURCE_NAMES } from '../const/dataSource.const';
import { actionUriWithAddedQueryParams, queryStringFromJson } from '../helpers/locationUtils';
import { appStringModel } from '../model/appString.model';
import { confirmModalModel } from '../model/confirmModal.model';
import * as DataSource from '../model/dataSource/dataSource.model';
import { instagramMediaItemResponseToMediaModel } from '../model/dataSource/media/instagram/instagramMediaItemResponse.model';
import { instagramPaginationModel } from '../model/dataSource/media/instagram/instagramPagination.model';
import { dataSourceMediaPageModel, normalizeDataSourceMediaPage } from '../model/dataSource/media/dataSourceMediaPage.model';
import { selectInstagramRedirectUri, selectInstagramUrlFunc } from '../selectors/instagram.selectors';
import * as InstagramService from '../services/instagram/instagram';

export const INSTAGRAM_PAGINATION_FIRST_PAGE_ID = 'firstInstagramPage';

export const authenticate = (intent) => (dispatch, getState) => {
	const buildInstagramAuthUrl = selectInstagramUrlFunc(getState());

	return InstagramService.authenticate(buildInstagramAuthUrl(intent));
};

export const authenticateAndLoadPhotos = ({ count, intent } = {}) => (dispatch) => {
	return dispatch(authenticate(intent))
		.then((token) => dispatch(loadPhotosForPhotoPicker({ count, token })))
		.catch((error) => console.error('Failed to authenticate Instagram', error));
};

export const exchangeAuthCodeForAccessToken = (authCode) => (dispatch, getState) => {
	const redirectUri = selectInstagramRedirectUri(getState());

	return dispatch(apiGetData(`/instagram/longLivedAccessToken?${queryStringFromJson({
		authCode,
		redirectUri
	})}`))
		.then((data) => deep(data, 'LongLivedAccessToken.Token'))
		.then((token) => {
			if (!token) throw new Error('Failed to exchange code for token.');

			return token;
		});
};

export const handleAuthIntentActionUri = (intentActionUri, token, history, location) => (dispatch) => {
	const intentWithToken = actionUriWithAddedQueryParams(intentActionUri, { token });

	return dispatch(actionUriActions.handleActionUri(intentWithToken, history, location))
		.catch((err) => console.error(err));
};

export const loadPhotosForPhotoPicker = ({ token, pagination, count = 40 }) => (dispatch) => {
	dispatch(appActions.showThrobber(loadPhotosForPhotoPicker.name));

	return InstagramService.getPhotos({ token, pagination, count })
		.then((instagramData) => {
			const page = dataSourceMediaPageModel({
				media: instagramData.media.map(instagramMediaItemResponseToMediaModel),
				pageId: deep(pagination, 'next') || INSTAGRAM_PAGINATION_FIRST_PAGE_ID,
				pagination: instagramPaginationModel({
					next: instagramData.paging.cursors.after,
				}),
				sourceType: DATA_SOURCE_NAMES.INSTAGRAM,
				token,
			});

			const normalized = normalizeDataSourceMediaPage(page);
			dispatch(entityActions.receiveEntities(normalized.entities, loadPhotosForPhotoPicker.name));

			return page;
		})
		.finally(() => dispatch(appActions.hideThrobber(loadPhotosForPhotoPicker.name)));
};

export const maybePromptInstagramHandleOverwrite = (token, dataSource) => (dispatch) => {
	return InstagramService.getInstagramUser({ token })
		.then((instagramUser) => {
			const oldHandle = DataSource.getUsername(dataSource);
			const newHandle = instagramUser.username;

			return (newHandle === oldHandle)
				? Promise.resolve()
				: dispatch(viewActions.openConfirmModal(confirmModalModel({
					body: appStringModel({ id: 'web.ig.reauth.popup.mismatchModal.body', params: { oldHandle, newHandle } }),
					title: appStringModel({ id: 'web.ig.reauth.popup.mismatchModal.title' }),
					cancelText: appStringModel({ id: 'web.ig.reauth.popup.mismatchModal.cancle' }),
					confirmText: appStringModel({ id: 'web.ig.reauth.popup.mismatchModal.confirm' }),
					confirmType: 'delete',
				})));
		});
};

export const reconnectInstagramAnon = (id, key, longLivedToken) => (dispatch) => {
	dispatch(appActions.showThrobber(reconnectInstagramAnon.name));

	return dispatch(api.postReconnectInstagramAnon({ id, key, longLivedToken }))
		.finally(() => dispatch(appActions.hideThrobber(reconnectInstagramAnon.name)));
};

export const reconnectInstagram = (instagramDataSource) => (dispatch) => {
	return dispatch(authenticate())
		.then((token) => {
			return dispatch(maybePromptInstagramHandleOverwrite(token, instagramDataSource))
				.then(() => dispatch(dataSourceActions.reconnectDataSource(instagramDataSource.id, null, token)))
				.then(() => dispatch(dataSourceActions.loadDataSources()));
		});
};

export const saveInstagramGraphDatasourceToGroup = ({ groupId, longLivedToken, preferredStartDate, tags }) => (dispatch) => {
	dispatch(appActions.showThrobber(saveInstagramGraphDatasourceToGroup.name));

	return dispatch(api.postAddInstagramGraphDatasource({ groupId, longLivedToken, preferredStartDate, tags }))
		.finally(() => dispatch(appActions.hideThrobber(saveInstagramGraphDatasourceToGroup.name)));
};
