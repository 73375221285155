import { apiDelete, apiGetData, apiPost, apiPut, apiPostRecieveData } from './api.http.actions';
import { checkoutErrorFromJson } from '../../model/checkoutError.model';
import { committedCartFromJson } from '../../model/committedCart.model';
import { shippingOptionsViewResponseFromJson } from '../../model/shippingOptionsViewResponse.model';
import { selectCartId } from '../../selectors/checkout.selectors';
import { queryStringFromJson } from '../../helpers/locationUtils';

const cartApiDelete = (path) => (dispatch, getState) => {
	const cartId = selectCartId(getState());

	return dispatch(apiDelete(`/shoppingCarts/${cartId}${path}`));
};

const cartApiGetData = (path, cartIdOverride) => (dispatch, getState) => {
	const cartId = cartIdOverride || selectCartId(getState());

	return dispatch(apiGetData(`/shoppingCarts/${cartId}${path}`));
};

const cartApiPost = (path, data) => (dispatch, getState) => {
	const cartId = selectCartId(getState());

	return dispatch(apiPost(`/shoppingCarts/${cartId}${path}`, data));
};

const cartApiPut = (path, data, { validateStatus } = {}) => (dispatch, getState) => {
	const cartId = selectCartId(getState());

	return dispatch(apiPut(`/shoppingCarts/${cartId}${path}`, data, validateStatus));
};

const cartsApiGetData = (path) => (dispatch) => {
	return dispatch(apiGetData(`/shoppingCarts${path}`));
};

const cartsApiPostReceiveData = (path) => (dispatch) => {
	return dispatch(apiPostRecieveData(`/shoppingCarts${path}`));
};

export const deleteCartCoupon = () => (dispatch) => {
	return dispatch(cartApiDelete('/coupon'));
};

export const deleteCartGiftNote = () => (dispatch) => {
	return dispatch(cartApiDelete('/giftNote'));
};

export const deleteCartItem = (itemId) => (dispatch) => {
	return dispatch(cartApiDelete(`/items/${itemId}`));
};

/**
 * Adds 'enriched' to the query object. This enables more in depth information about items in the cart.
 *
 * @param {object} queryObject
 */
const queryWithGroups = (queryObject = {}) => ({ ...queryObject, enriched: true });

export const getActiveCart = () => (dispatch) => {
	const query = queryWithGroups();

	return dispatch(cartsApiGetData(`/active?${queryStringFromJson(query)}`));
};

export const getCheckoutError = () => (dispatch) => {
	return dispatch(cartApiGetData('/checkout/error')).then(checkoutErrorFromJson);
};

export const getCheckoutInfo = (cartId) => (dispatch) => {
	return dispatch(cartApiGetData('/checkout', cartId)).then(committedCartFromJson);
};

export const get_shoppingCarts_cartId_shippingOptions_view = () => (dispatch) => {
	return dispatch(cartApiGetData('/shippingOptions/view'))
		.then(shippingOptionsViewResponseFromJson);
};

export const getPromoSuggestions = (limit) => (dispatch) => {
	return dispatch(cartApiGetData(`/promoSuggestions?limit=${limit}`));
};

export const postCart = () => (dispatch) => {
	const query = queryWithGroups();

	return dispatch(cartsApiPostReceiveData(`?${queryStringFromJson(query)}`));
};

export const postCartItem = (item) => (dispatch) => {
	return dispatch(cartApiPost('/items', item));
};

export const putCartCheckout = () => (dispatch) => {
	const allowErrorStatusesToReturnData = () => true;

	return dispatch(cartApiPut('/checkout', undefined, { validateStatus: allowErrorStatusesToReturnData }));
};

export const putCartCouponCode = (couponId) => (dispatch) => {
	return dispatch(cartApiPut('/coupon', { couponId }));
};

export const putCartGiftNote = (giftNote) => (dispatch) => {
	const wrappedInQuotesForApi = `"${giftNote}"`;

	return dispatch(cartApiPut('/giftNote', wrappedInQuotesForApi));
};

export const putCartItemProductId = (itemId, productId) => (dispatch) => {
	return dispatch(cartApiPut(`/items/${itemId}/product`, productId));
};

export const putCartItemQuantity = (itemId, quantity) => (dispatch) => {
	return dispatch(cartApiPut(`/items/${itemId}/quantity`, quantity));
};

export const putCartPaymentMethod = (methodId) => (dispatch) => {
	return dispatch(cartApiPut(`/paymentMethod`, { methodId }));
};

export const putCartShippingAddress = (addressId) => (dispatch) => {
	return dispatch(cartApiPut(`/shippingAddress`, { addressId }));
};

export const putCartShippingGrade = (shippingGrade) => (dispatch) => {
	const notWrappedInObjectForApiShippingGrade = shippingGrade;

	return dispatch(cartApiPut(`/shippingGrade`, notWrappedInObjectForApiShippingGrade));
};

export const putPromoSuggestions = (promoId) => (dispatch) => {
	return dispatch(cartApiPut(`/promoSuggestions`, { promoId }));
};
