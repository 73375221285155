import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Box from '../Box/Box';
import RadioInput from '../RadioInput/RadioInput';
import './RadioInputSeparatedBox.css';

const RadioInputSeparatedBox = ({
	checked,
	children,
	className,
	disabled,
	id,
	label,
	name,
	onBlur,
	onChange,
	value,
}) => (
	<Box className={classnames('RadioInputSeparatedBox', { checked, disabled })}>
		<RadioInput
			className={className}
			checked={checked}
			disabled={disabled}
			id={id}
			name={name}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			label={label}
			children={children}
		/>
	</Box>
);

RadioInputSeparatedBox.displayName = 'RadioInputSeparatedBox';

RadioInputSeparatedBox.propTypes = {
	checked: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.node,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RadioInputSeparatedBox