import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CtaHalf from './CtaHalf/CtaHalf';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import withWindowSize from '../../components/WindowSize/withWindowSize';
import { DEVICE_WIDTH, mediaQueryStartingFrom, mediaQueryUpTo } from '../../const';

const HalfImageHalfCtaDiv = styled(PageFlowContainer)`
	display: grid;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		grid-template-areas:
			"l"
			"r";
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-template-areas: 'l r';
	}
`;

const ImageHalf = styled('img')`
	grid-area: ${(props) => props.gridArea || 'l'};
`;

const HalfImageHalfCta = ({
	analyticEvent,
	ctaText,
	ctaUrl,
	description,
	imageSide,
	imageAlt,
	imageUrl,
	linkCallback,
	textAlign,
	textColor,
	title,
	windowSize,
}) => {
	if (!imageUrl) return null;

	const MOBILE_SIZE_WIDTH = DEVICE_WIDTH.TABLET - 1;
	const isMobile = windowSize.width <= MOBILE_SIZE_WIDTH;
	const isImageLeft = imageSide === 'left' || isMobile;

	return (
		<HalfImageHalfCtaDiv
			isInset={false}>
			<ImageHalf
				gridArea={isImageLeft ? 'l' : 'r'}
				alt={imageAlt}
				src={imageUrl}
			/>
			<CtaHalf
				ctaText={ctaText}
				ctaUrl={ctaUrl}
				description={description}
				gridArea={isImageLeft ? 'r' : 'l'}
				isMobile={isMobile}
				linkCallback={linkCallback}
				textAlign={isMobile ? 'left' : textAlign}
				textColor={textColor}
				title={title}
			/>
		</HalfImageHalfCtaDiv>
	);
};

HalfImageHalfCta.displayName = 'HalfImageHalfCta';

HalfImageHalfCta.propTypes = {
	analyticEvent: PropTypes.func,
	ctaText: PropTypes.string,
	ctaUrl: PropTypes.string,
	description: PropTypes.string,
	imageAlt: PropTypes.string,
	imageSide: PropTypes.string,
	imageUrl: PropTypes.string,
	linkCallback: PropTypes.func,
	textAlign: PropTypes.string,
	textColor: PropTypes.string,
	title: PropTypes.string,
	windowSize: PropTypes.object,
};

export default withWindowSize(HalfImageHalfCta);
