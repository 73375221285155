import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const bookCoverColorFromJson = (json = {}) => ({
	id: deep(json, 'BackgroundColor.ID'),
	name: deep(json, 'BackgroundColor.Name'),
	hex: deep(json, 'BackgroundColor.Hex'),
	isSoftcoverDefault: deep(json, 'IsSoftcoverDefault'),
	isHardcoverDefault: deep(json, 'IsHardcoverDefault'),
});

export const bookCoverColorsSchema = new schema.Entity(ENTITIES.BOOK_COVER_COLOR, {});

export const bookCoverColorsSchemas = new schema.Array(bookCoverColorsSchema);

export const normalizebookCoverColors = (bookCoverColors) => normalize(bookCoverColors, bookCoverColorsSchemas);
