import { apiPostRecieveData, apiPost, apiGetData, apiPut } from './api.http.actions';
import requireParams from '../../helpers/requireParams';
import { queryStringFromJson } from '../../helpers/locationUtils';


export const getCollageLayouts = (bookSize) => (dispatch) => {
	return dispatch(apiGetData(`/moments/GetCollageLayouts?${queryStringFromJson({ bookSize })}`));
};

export const getCollageMediaByMomentId = (momentId) => (dispatch) => {
	requireParams({ momentId }, getCollageMediaByMomentId.name);

	const url = `/moments/collagemedia?id=${momentId}`;

	return dispatch(apiGetData(url));
};

export const getMomentById = (momentId, groupId) => (dispatch) => {
	requireParams({ momentId, groupId }, getMomentById.name);

	return dispatch(apiGetData(`/moments/${momentId}?${queryStringFromJson({ groupId })}`));
};

export const getMomentsDisplayDate = (momentId) => (dispatch) => {
	requireParams({ momentId }, getMomentsDisplayDate.name);

	return dispatch(apiGetData(`/moments/${momentId}/DisplayDate`));
};

export const getMomentsLocationName = (momentId) => (dispatch) => {
	requireParams({ momentId }, getMomentsLocationName.name);

	return dispatch(apiGetData(`/moments/LocationName/${momentId}`));
};

export const moveCollageMedia = (momentId, fromIndex, toIndex) => (dispatch) => {
	const data = {
		id: momentId,
		fromIndex,
		toIndex,
	};
	const url = '/moments/movecollagemedia';

	return dispatch(apiPostRecieveData(url, data));
};

export const postMomentsAddMedia = (requestData) => (dispatch) => {
	return dispatch(apiPost('/moments/addMedia', requestData));
};

export const postMomentsCaptionSize = (bookSizeStr, pageLayoutType, text) => (dispatch) => {
	const data = {
		bookSize: bookSizeStr,
		pageLayoutType,
		text
	};

	return dispatch(apiPostRecieveData('/moments/captionSize', data));
};

export const postMomentsCreate = (groupId, caption, mediaId) => (dispatch) => {
	requireParams({ groupId }, postMomentsCreate.name);

	return dispatch(apiPostRecieveData('/moments/create', {
		groupId,
		text: caption,
		mediaId
	}))
		.then((result) => result.moment);
};

export const postMomentsDelete = (momentId) => (dispatch) => {
	requireParams({ momentId }, postMomentsDelete.name);

	return dispatch(apiPostRecieveData('/moments/delete', {
		id: momentId,
	}));
};

export const postMomentsLayoutCaption = (momentId, text) => (dispatch) => {
	requireParams({ momentId, text }, postMomentsLayoutCaption.name);

	return dispatch(apiPostRecieveData('/moments/LayoutCaption', {
		id: momentId,
		text,
	}));
};

export const postMomentsMoveContinuationToCaption = (momentId) => (dispatch) => {
	requireParams({ momentId }, postMomentsMoveContinuationToCaption.name);

	return dispatch(apiPostRecieveData('/moments/MoveContinuationToCaption', {
		id: momentId
	}));
};

export const postMomentsDisplayDate = (momentId, date) => (dispatch) => {
	requireParams({ momentId, date }, postMomentsDisplayDate.name);

	return dispatch(apiPostRecieveData(`/moments/${momentId}/DisplayDate`, { date }));
};

export const postMomentsSetLocationName = (momentId, location) => (dispatch) => {
	requireParams({ momentId, location }, postMomentsSetLocationName.name);

	return dispatch(apiPostRecieveData('/moments/setLocationName', {
		id: momentId,
		name: location
	}));
};

export const postMomentsUpdateCaption = (momentId, caption, allowOverflow, forceCaption) => (dispatch) => {
	requireParams({ momentId, caption }, postMomentsUpdateCaption.name);

	return dispatch(apiPostRecieveData('/moments/updateCaption', {
		id: momentId,
		caption,
		allowOverflow: !!allowOverflow,
		forceTextPage: !!forceCaption
	}));
};

export const postSetMultipleCollageMedia = (id, mediaIds) => (dispatch) => {
	requireParams({ id, mediaIds }, postSetMultipleCollageMedia.name);

	return dispatch(apiPostRecieveData('/moments/SetMultipleCollageMedia', {
		id,
		mediaIds,
	}));
};

export const putMomentsUpdate = (momentId, data) => (dispatch) => {
	requireParams({ momentId }, putMomentsUpdate.name);

	return dispatch(apiPut(`/Moments/${momentId}`, data));
};
