import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconHome = (props) => (
	<Svg
		{...props}
		name="home"
		viewBox="0 0 22 21"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path
				d="M8.51435638,20.6137821 L3.51435638,20.6137821 C2.96207163,20.6137821 2.51435638,20.1660669 2.51435638,19.6137821 L2.51435638,11.6137821 L1,11.6137821 C0.44771525,11.6137821 8.19344592e-14,11.1660669 8.21565038e-14,10.6137821 C8.21565038e-14,10.3414875 0.111038278,10.0809675 0.307468172,9.8923948 L10.3218246,0.278612679 C10.7087866,-0.092870893 11.3199262,-0.092870893 11.7068882,0.278612679 L21.7212446,9.8923948 C22.1196558,10.2748696 22.1325748,10.9079027 21.7501001,11.306314 C21.5615274,11.5027438 21.3010074,11.6137821 21.0287128,11.6137821 L19.5143564,11.6137821 L19.5143564,19.6137821 C19.5143564,20.1660669 19.0666411,20.6137821 18.5143564,20.6137821 L13.5143564,20.6137821 L13.5143564,12.6137821 L8.51435638,12.6137821 L8.51435638,20.6137821 Z"
				fill="inherit"
			/>
		</g>
	</Svg>
);

IconHome.displayName = 'IconHome';

IconHome.propTypes = iconProps;

IconHome.defaultProps = defaultIconProps;

export default IconHome;
