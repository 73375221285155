import React from 'react';
import PropTypes from 'prop-types';

import HeadingWrapper from './HeadingWrapper';
import Wrapper from '../Wrapper/Wrapper';
import './Text.css';

const Text = ({
	text,
	textStyle
}) => (
	<Wrapper className={`duplo-block-text ${textStyle}`}>
		<HeadingWrapper
			textStyle={textStyle}
		>
			{text}
		</HeadingWrapper>
	</Wrapper>
);

Text.propTypes = {
	text: PropTypes.string,
	textStyle: PropTypes.string,
};

export default Text;
