import React from 'react';

import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';
import { COLOR } from '../../const';

export const IconGiftCard = (props) => {
	const { size } = props;
	const heightRatio = 102;
	const widthRatio = 130;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="giftCard"
			viewBox="0 0 130 102"
			height={height}
			width={width}
		>
			<rect y="20.404" width="117.502" height="82.2516" rx="7" transform="rotate(-10 0 20.404)" fill={COLOR.CHATTY_GREEN_400}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M22.0896 58.5921C21.3595 58.996 20.6142 59.4088 19.8755 59.818C19.8465 59.7692 19.8222 59.7256 19.7954 59.6835C19.4167 59.0886 18.9585 58.58 18.2284 58.498C16.835 58.342 15.5459 59.1388 15.3787 60.7338C15.2953 61.5289 15.4463 62.2841 15.8601 62.9675C16.3418 63.7627 17.056 64.1805 17.971 64.1774C18.469 64.1761 18.9468 64.0523 19.3728 63.7647C19.9071 63.4035 20.2168 62.8978 20.3333 62.2595C20.3444 62.1992 20.3545 62.1386 20.3683 62.0581C21.1828 62.184 21.9946 62.3098 22.8278 62.4389C22.8112 62.5744 22.8015 62.7005 22.7791 62.8242C22.5331 64.171 21.8256 65.1904 20.6965 65.9169C20.0587 66.3274 19.3587 66.5387 18.6173 66.6429C17.8586 66.7496 17.1112 66.7017 16.3789 66.4659C14.8421 65.9714 13.7487 64.9569 13.1316 63.4433C12.5542 62.0268 12.4913 60.5751 13.0524 59.1456C13.615 57.7133 14.6466 56.7466 16.0736 56.2522C17.2606 55.8406 18.4653 55.8144 19.6627 56.2719C20.7648 56.6935 21.5455 57.4699 22.0613 58.5387C22.0673 58.551 22.0673 58.551 22.0896 58.5921Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M52.0943 46.974L54.6765 46.5187L55.3984 50.613C55.6229 50.1987 55.9398 49.8849 56.279 49.589C56.6722 49.2462 57.1333 49.0347 57.6251 48.9012C58.3509 48.704 59.0928 48.6233 59.8385 48.7822C61.2219 49.0771 62.22 49.904 62.8817 51.1646C63.3363 52.0306 63.5839 52.9625 63.6531 53.9375C63.7373 55.1237 63.5446 56.2526 62.92 57.2712C62.3529 58.1959 61.5486 58.8155 60.5253 59.1337C59.8503 59.3437 59.1624 59.4361 58.4575 59.3013C57.8464 59.1838 57.3067 58.9168 56.8518 58.4767C56.8247 58.4508 56.7974 58.4249 56.7696 58.3991C56.7676 58.3975 56.7619 58.3997 56.7427 58.4011L56.9458 59.5529L54.3917 60.0033L52.0943 46.974ZM60.9162 53.699C60.898 53.5959 60.8866 53.4908 60.8604 53.3894C60.7466 52.9491 60.5943 52.5244 60.309 52.165C59.6936 51.3896 58.6975 51.0842 57.7619 51.3575C56.8089 51.6356 56.2402 52.2743 56.0546 53.2558C55.8988 54.0807 56.029 54.8771 56.4225 55.6202C57.0474 56.8006 58.1906 57.2631 59.4124 56.8515C60.2981 56.553 60.9353 55.728 60.9783 54.721C60.9928 54.3809 60.929 53.6987 60.9162 53.699Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M25.5537 55.6078C25.8562 55.1468 26.2442 54.8035 26.7137 54.5536C27.2985 54.2427 27.9223 54.0791 28.5825 54.0303C29.2784 53.9791 29.9295 54.1313 30.5352 54.4696C31.1714 54.8255 31.6128 55.3742 31.9171 56.0404C32.1116 56.4661 32.2532 56.9089 32.3343 57.3715C32.6966 59.4334 33.0608 61.4945 33.4243 63.5563L33.4484 63.693L30.8139 64.1575C30.8032 64.0969 30.7928 64.0376 30.7824 63.9785C30.452 62.1047 30.1233 60.2307 29.79 58.3572C29.707 57.8908 29.5359 57.4657 29.2143 57.1064C28.9023 56.758 28.5091 56.6184 28.0708 56.6404C27.7768 56.6555 27.4708 56.7012 27.1981 56.8068C26.5115 57.0724 26.2081 57.6461 26.1386 58.3613C26.1173 58.5806 26.1301 58.8103 26.1677 59.028C26.49 60.8936 26.8213 62.7574 27.15 64.6215L27.1812 64.7985L24.5441 65.2635L22.2475 52.2387L24.8779 51.7749L25.5537 55.6078Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M74.9679 51.2463C75.2146 52.7075 74.9861 54.0712 74.1633 55.3005C73.626 56.1034 72.9056 56.692 72.0235 57.0591C70.5973 57.6526 69.1518 57.7225 67.7169 57.0773C66.3811 56.4765 65.4822 55.438 64.9841 54.0441C64.5855 52.9276 64.5019 51.787 64.7825 50.6324C65.0757 49.4258 65.7262 48.4679 66.719 47.7526C67.5043 47.1864 68.3846 46.8904 69.3385 46.7898C70.4485 46.6731 71.4941 46.8833 72.4632 47.4532C73.4308 48.0221 74.136 48.8426 74.5638 49.8946C74.6712 50.159 74.7575 50.4326 74.8415 50.7069C74.8953 50.8824 74.9804 51.2417 74.9679 51.2463ZM67.3433 52.573C67.4124 52.8101 67.4674 53.0523 67.5528 53.2829C67.7836 53.9053 68.1654 54.4144 68.7459 54.7282C69.3058 55.031 69.9062 55.0991 70.5228 54.9337C71.5263 54.6656 72.1273 54.0042 72.3287 52.973C72.4739 52.2283 72.3622 51.5044 72.0558 50.8134C71.7994 50.235 71.4076 49.7731 70.8378 49.5014C70.2328 49.2126 69.6009 49.1793 68.9727 49.3968C68.4262 49.5854 67.9754 49.9114 67.6763 50.4332C67.2937 51.102 67.2283 51.8177 67.3433 52.573Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M86.4167 49.2276C86.6632 50.6888 86.4351 52.0525 85.6121 53.2818C85.0746 54.0847 84.3544 54.6733 83.4721 55.0404C82.0459 55.6339 80.6004 55.7038 79.1655 55.0586C77.8299 54.4578 76.931 53.4193 76.4329 52.0254C76.0343 50.9088 75.9507 49.7682 76.2311 48.6137C76.5243 47.4071 77.1748 46.4492 78.1678 45.7339C78.9531 45.1676 79.8334 44.8717 80.7872 44.7711C81.8971 44.6544 82.9428 44.8646 83.912 45.4345C84.8798 46.0034 85.585 46.8238 86.0126 47.8759C86.12 48.1402 86.2063 48.4138 86.2905 48.6882C86.3441 48.8637 86.429 49.223 86.4167 49.2276ZM78.792 50.5543C78.861 50.7914 78.9164 51.0335 79.0016 51.2642C79.2323 51.8866 79.6142 52.3957 80.1947 52.7095C80.7546 53.0122 81.3549 53.0804 81.9718 52.915C82.975 52.6468 83.5761 51.9855 83.7773 50.9543C83.9229 50.2095 83.8112 49.4857 83.5048 48.7946C83.2482 48.2163 82.8564 47.7544 82.2866 47.4827C81.6816 47.1938 81.0496 47.1606 80.4213 47.3781C79.8752 47.5666 79.424 47.8927 79.1251 48.4145C78.7425 49.0833 78.677 49.7991 78.792 50.5543Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M104.476 43.544C103.77 43.8432 103.077 44.137 102.373 44.4359C102.33 44.3463 102.291 44.2619 102.249 44.1789C101.947 43.5845 101.382 43.2763 100.733 43.3559C100.406 43.3962 100.086 43.4631 99.8289 43.691C99.5493 43.9393 99.3628 44.2295 99.4509 44.6386C99.5118 44.9212 99.6896 45.0949 99.9334 45.2123C100.213 45.3478 100.515 45.3268 100.812 45.3466C101.485 45.3915 102.166 45.3874 102.829 45.4982C103.893 45.6757 104.737 46.233 105.174 47.2813C105.613 48.3324 105.507 49.3507 104.886 50.2954C104.479 50.9134 103.903 51.3264 103.234 51.5953C102.369 51.9433 101.46 52.0955 100.535 51.9544C99.2753 51.762 98.2636 51.1624 97.701 49.928C97.6446 49.8043 97.6014 49.6741 97.5477 49.5365C98.2804 49.2313 98.9958 48.9328 99.7208 48.6306C99.8001 48.9331 99.9531 49.1729 100.153 49.3878C100.446 49.7037 100.803 49.8644 101.224 49.8714C101.667 49.8787 102.104 49.8291 102.481 49.5697C102.793 49.3555 102.952 49.0453 102.902 48.6545C102.851 48.2638 102.616 48.0019 102.248 47.919C101.919 47.8445 101.576 47.8328 101.239 47.8005C100.622 47.741 99.9973 47.7365 99.3922 47.6189C98.3392 47.4141 97.5688 46.8019 97.1979 45.7466C96.8321 44.7056 97.0009 43.734 97.6339 42.8578C98.165 42.1225 98.8836 41.6591 99.7521 41.4296C100.381 41.2636 101.019 41.1836 101.663 41.2784C102.933 41.4662 103.899 42.098 104.411 43.3479C104.435 43.4046 104.45 43.4644 104.476 43.544Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M45.8949 48.0683L48.5083 47.6075L49.0462 50.6585L51.0047 50.3131L51.4534 52.8579L49.5155 53.1996C49.5154 53.2419 49.5111 53.2696 49.5159 53.2957C49.7697 54.7275 49.997 56.0068 50.2526 57.4389C50.3384 57.9189 50.7126 58.2845 51.2747 58.2091C51.6376 58.1605 51.9973 58.0845 52.3686 58.019C52.369 58.0211 52.3798 58.0619 52.3872 58.1028C52.5025 58.7556 52.615 59.4089 52.7353 60.0604C52.7552 60.169 52.7282 60.2189 52.6342 60.2701C52.1288 60.5436 51.5829 60.669 51.0204 60.7387C50.592 60.7914 50.1692 60.7755 49.746 60.6654C48.8189 60.4241 48.213 59.8259 47.8776 58.9302C47.7848 58.6822 47.7189 58.4205 47.6721 58.1593C47.4016 56.6625 47.1677 55.3163 46.9034 53.8184C46.896 53.7767 46.8851 53.7358 46.8713 53.6735L45.1176 53.9827L44.6652 51.417C44.8911 51.3772 46.2107 51.1561 46.4305 51.104L45.8949 48.0683Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M90.7067 50.8909L91.5898 49.5237L94.8768 52.8755C95.8589 52.698 96.942 52.5082 97.9251 52.3344C97.9686 52.3268 98.0114 52.3153 98.0749 52.3008C96.3989 50.6009 94.7375 48.9158 93.0732 47.228C94.1269 45.6024 95.1725 43.9888 96.2397 42.3419L96.0575 42.374C95.0976 42.5433 93.7943 42.7774 92.7986 42.9455L90.0583 47.2536L88.8661 40.4925L86.246 40.9545L88.543 53.9819L91.1701 53.5187L90.7067 50.8909Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M44.9224 61.673L42.3681 62.1234L42.1649 60.9712C42.1464 60.9764 42.1403 60.9763 42.1392 60.9789C42.1218 61.0122 42.1046 61.0459 42.0883 61.0799C41.8116 61.649 41.3958 62.0849 40.8614 62.4036C40.3752 62.6944 39.8501 62.8481 39.3031 62.9164C38.2976 63.0418 36.8093 62.6769 36.0021 62.0449C35.0497 61.2985 34.4746 60.2954 34.1447 59.1407C33.8764 58.2007 33.7904 57.2404 33.9214 56.2711C34.1118 54.8602 34.7668 53.7415 35.9662 52.9916C36.6126 52.5874 37.3373 52.4093 38.0866 52.3464C38.5946 52.3036 39.0999 52.3446 39.587 52.5322C40.0067 52.6943 40.4118 52.8808 40.7646 53.1933L40.5896 52.2009L43.1719 51.7456L44.9224 61.673ZM36.9262 58.9616C37.3111 59.8936 38.192 60.4509 39.1265 60.4285C40.4151 60.397 41.3313 59.5718 41.5147 58.2489C41.6303 57.416 41.4803 56.623 41.0519 55.9012C40.5419 55.0424 39.7886 54.6367 38.7981 54.7013C37.8257 54.7644 36.9939 55.3922 36.6809 56.3312C36.5359 56.7665 36.5378 57.2181 36.5814 57.6704C36.5915 57.7746 36.617 57.8773 36.6351 57.9804C36.623 57.9845 36.7964 58.6473 36.9262 58.9616Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M100.173 30.7902L94.1223 31.8572C93.5666 31.9552 93.1973 32.495 93.2975 33.063L94.4605 39.659C94.5606 40.2266 95.0923 40.608 95.648 40.51L95.7713 40.4883L95.7735 40.5005L95.7839 40.4986C96.0985 40.4431 99.1852 39.8997 101.464 39.4973C102.463 39.3209 103.494 39.5571 104.329 40.1544L104.43 40.2277L103.142 32.9177C102.891 31.4978 101.562 30.5453 100.173 30.7902Z" fill="white"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M107.359 32.6211L113.694 31.5039C114.25 31.406 114.782 31.787 114.882 32.355L116.179 39.713C116.279 40.2808 115.91 40.8209 115.354 40.9189L115.231 40.9406L115.233 40.953L115.223 40.9548C114.908 41.0103 111.538 41.6051 109.259 42.0065C108.259 42.1828 107.372 42.7569 106.792 43.6037L106.721 43.7073L105.297 35.6352C105.047 34.2155 105.97 32.866 107.359 32.6211Z" fill="white"/>
		</Svg>
	);
};

IconGiftCard.displayName = 'IconGiftCard';

IconGiftCard.propTypes = iconProps;

IconGiftCard.defaultProps = defaultIconProps;

export default IconGiftCard;
