import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TwoGradientBackground.css';

const TwoGradientBackground = ({ children, className, color1, color2 }) => (
	<div
		className={classnames(['TwoGradientBackground', className])}
		style={{
			background: `linear-gradient(220deg, ${color1}, ${color2})`,
			backgroundSize: '125% 125%',
		}}
	>
		{children}
	</div>
);


TwoGradientBackground.displayName = 'TwoGradientBackground';

TwoGradientBackground.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color1: PropTypes.string,
	color2: PropTypes.string,
};

TwoGradientBackground.defaultProps = {
	color1: '#EF3E5C',
	color2: '#5E67E3',
};

export default TwoGradientBackground;