import deep from 'deep-get-set';

import { dataSourceMediaModel } from '../dataSourceMedia.model';
import { instagramCaptionToTagsArray } from '../../../../helpers/instagram/instagram.helpers';

export const instagramMediaItemResponseFromJson = (json = {}) => ({
	caption: deep(json, 'caption'),
	children: (deep(json, 'children.data') || []).map(instagramMediaItemResponseFromJson),
	id: deep(json, 'id'),
	mediaType: deep(json, 'media_type'),
	mediaUrl: deep(json, 'media_url'),
	timestamp: deep(json, 'timestamp'),
});

export const instagramMediaItemsResponseFromJson = (itemsJson = []) => itemsJson.map(instagramMediaItemResponseFromJson);

export const imageOnlyFilter = (instagramMedia) => isImage(instagramMedia);

export const instagramMediaItemResponseToMediaModel = (instagramMedia) => {
	const caption = deep(instagramMedia, 'caption') || '';

	return dataSourceMediaModel({
		caption,
		children: deep(instagramMedia, 'children'),
		id: deep(instagramMedia, 'id'),
		standardResUrl: deep(instagramMedia, 'mediaUrl'),
		tags: deep(instagramMedia, 'tags') || instagramCaptionToTagsArray(caption),
		thumbnailUrl: deep(instagramMedia, 'mediaUrl'),
		date: deep(instagramMedia, 'timestamp'),
	});
};

export const isCarousel = (instagramMedia) => deep(instagramMedia, 'mediaType') === 'CAROUSEL_ALBUM';

export const isImage = (instagramMedia) => deep(instagramMedia, 'mediaType') === 'IMAGE';

export const isVideo = (instagramMedia) => deep(instagramMedia, 'mediaType') === 'VIDEO';

