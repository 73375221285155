import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCircleMenuFilled = (props) => (
	<Svg
		{...props}
		name="circle-menu-filled"
		viewBox="0 0 56 56"
	>
		<g>
			<path
				fill="inherit"
				d="M11,23c2.757,0,5,2.243,5,5s-2.243,5-5,5c-2.757,0-5-2.243-5-5S8.243,23,11,23 M11,21c-3.866,0-7,3.134-7,7
				s3.134,7,7,7c3.865,0,7-3.134,7-7S14.866,21,11,21L11,21z"
			/>
		</g>
		<g>
			<path
				fill="inherit"
				d="M28,23c2.756,0,5,2.243,5,5s-2.243,5-5,5c-2.757,0-5-2.243-5-5S25.243,23,28,23 M28,21
				c-3.866,0-7,3.134-7,7s3.134,7,7,7c3.865,0,7-3.134,7-7S31.865,21,28,21L28,21z"
			/>
		</g>
		<g>
			<path
				fill="inherit"
				d="M45,23c2.757,0,5,2.243,5,5s-2.243,5-5,5s-5-2.243-5-5S42.243,23,45,23 M45,21c-3.865,0-7,3.134-7,7
				s3.135,7,7,7s7-3.134,7-7S48.865,21,45,21L45,21z"
			/>
		</g>
	</Svg>
);

IconCircleMenuFilled.displayName = 'IconCircleMenuFilled';

IconCircleMenuFilled.propTypes = iconProps;

IconCircleMenuFilled.defaultProps = defaultIconProps;

export default IconCircleMenuFilled;
