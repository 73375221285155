import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconShipmentTruck2 = (props) => (
	<Svg
		{...props}
		name="shipment-truck-2"
		viewBox="0 0 120 120"
	>
		<path
			d="M82.81,70.44H15.43a1,1,0,0,1-1-1V29.72a1.9,1.9,0,0,1,1.9-1.9H82a1.9,1.9,0,0,1,1.9,1.9V69.4A1,1,0,0,1,82.81,70.44ZM16.47,68.35h65.3V30.22a.31.31,0,0,0-.32-.31H16.79a.31.31,0,0,0-.32.31Z"
		/>
		<path
			d="M82.82,84.53H42a1,1,0,1,1,0-2.09H81.77v-12H16.47V82a.45.45,0,0,0,.45.45h9.76a1,1,0,1,1,0,2.09H16.42a2,2,0,0,1-2-2V69.4a1,1,0,0,1,1-1H82.82a1,1,0,0,1,1,1V83.49A1,1,0,0,1,82.82,84.53Z"
		/>
		<path
			d="M34.33,92.18A8.69,8.69,0,1,1,43,83.49,8.7,8.7,0,0,1,34.33,92.18Zm0-15.3a6.61,6.61,0,1,0,6.6,6.61A6.62,6.62,0,0,0,34.33,76.88Z"
		/>
		<path
			d="M34.33,87.63a4.14,4.14,0,1,1,4.14-4.14A4.15,4.15,0,0,1,34.33,87.63Zm0-6.19a2.05,2.05,0,1,0,2.05,2A2.05,2.05,0,0,0,34.33,81.44Z"
		/>
		<path
			d="M105.79,92.18a8.69,8.69,0,1,1,8.69-8.69A8.7,8.7,0,0,1,105.79,92.18Zm0-15.3a6.61,6.61,0,1,0,6.61,6.61A6.62,6.62,0,0,0,105.79,76.88Z"
		/>
		<path
			d="M105.79,87.63a4.14,4.14,0,1,1,4.14-4.14A4.15,4.15,0,0,1,105.79,87.63Zm0-6.19a2.05,2.05,0,1,0,2,2A2.05,2.05,0,0,0,105.79,81.44Z"
			/>
		<path
			d="M118.27,84.53h-4.83a1,1,0,0,1,0-2.09h3.78V67.92a9.16,9.16,0,0,0-9.15-9.16h-2.93a1,1,0,0,1-1-.88,24.36,24.36,0,0,0-3.84-9.43c-2.12-3.11-4.67-5.14-7.18-5.74a14.62,14.62,0,0,0-3.36-.39H83.86V82.44H98.14a1,1,0,1,1,0,2.09H82.82a1,1,0,0,1-1-1V41.27a1,1,0,0,1,1-1h6.91a16.52,16.52,0,0,1,3.84.45c3,.71,6,3.06,8.43,6.59a26.72,26.72,0,0,1,4,9.4h2.06a11.26,11.26,0,0,1,11.24,11.25V83.49A1,1,0,0,1,118.27,84.53Z"
		/>
		<path
			d="M100.54,62.37h-13a1,1,0,0,1-1.05-1V47.68a1,1,0,0,1,1.05-1h3A10.12,10.12,0,0,1,97,49.29c1.94,1.78,2.73,4.31,3.24,6.55,0,.18.16.65.32,1.25l0,.12c.3,1.18.7,2.77,1,3.88a1,1,0,0,1-.2.88A1,1,0,0,1,100.54,62.37Zm-12-2.09H99.22c-.22-.88-.46-1.84-.66-2.62s-.3-1.15-.34-1.35c-.44-1.93-1.1-4.09-2.62-5.48a8.06,8.06,0,0,0-5.15-2.1h-1.9Z"
		/>
		<path
			d="M91.3,67.15H87.51a1,1,0,1,1,0-2.08H91.3a1,1,0,0,1,0,2.08Z"
		/>
		<path
			d="M19.88,40.91H1.73a1,1,0,0,1,0-2.09H19.88a1,1,0,1,1,0,2.09Z"
		/>
		<path
			d="M19.88,45.81H5.38a1,1,0,0,1,0-2.09h14.5a1,1,0,1,1,0,2.09Z"
		/>
		<path
			d="M19.88,49.88H9a1,1,0,0,1,0-2.09H19.88a1,1,0,1,1,0,2.09Z"
		/>
	</Svg>
);

IconShipmentTruck2.displayName = 'IconShipmentTruck2';

IconShipmentTruck2.propTypes = iconProps;

IconShipmentTruck2.defaultProps = defaultIconProps;

export default IconShipmentTruck2;
