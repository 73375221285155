import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import deep from 'deep-get-set';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Cta from './Cta';
import Banner from './Banner';
import BodyText from './BodyText';
import InstagramUser from './InstagramUser';
import Link from './Link';
import Messaging from './Messaging';
import Title from './Title';
import * as analyticsActions from '../../actions/analytics.actions';
import * as instagramReauthActions from '../../actions/instagramQuickReauth.actions';
import Events from '../../helpers/analytics/Events';
import { isTruthyString, isFalsyString } from '../../helpers/bool/bool';
import { queryStringToJson } from '../../helpers/locationUtils';
import './InstagramQuickReauth.css';

export const INSTAGRAM_QUICK_REAUTH_URL = '/ig-reauth';

const titleSuccess = 'You\'re connected with Instagram';
const messagingSuccess = '🙌 Success 🙌';
const infoSuccess = 'Your Chatbooks account is reconnected, and your Instagram Series is back on track.';

const titleWarn = 'Reconnect Instagram to continue to print your book series';
const messagingWarn = '🚨 Reconnect Now 🚨';
const infoWarn = 'Reconnect your Chatbooks account today to keep printing your Instagram photos.';

const titleFailure = 'There was an issue reconnecting with Instagram.';
const messagingFailure = 'Oops, something went wrong';

class InstagramQuickReauth extends Component {
	componentDidMount() {
		const { history, instagramReauthActions, location } = this.props;
		const { success, token } = queryStringToJson(location.search);

		if (!success && token) {
			instagramReauthActions.reconnectDatasourcesWithInstagramToken(token, history);
		}

		const queryString = deep(this.props, 'location.search');
		const queryJson = queryStringToJson(queryString);
		const isSuccess = isTruthyString(deep(queryJson, 'success'));
		const isFailure = isFalsyString(deep(queryJson, 'success'));

		if (isSuccess) {
			this.props.analyticsActions.triggerAnalyticsEvent(Events.QuickReauthStatus_Load, { attr1: 'success' });
		} else if (isFailure) {
			this.props.analyticsActions.triggerAnalyticsEvent(Events.QuickReauthStatus_Load, { attr1: 'error' });
		} else {
			this.props.analyticsActions.triggerAnalyticsEvent(Events.QuickReauthLanding_Load);
		}
	}

	componentDidUpdate(prevProps) {
		const { location: prevLocation } = prevProps;
		const { success: prevSuccess, token: prevToken } = queryStringToJson(prevLocation.search);

		const { history, instagramReauthActions, location } = this.props;
		const { success, token } = queryStringToJson(location.search);

		if ((!success && !prevToken && token) || (prevSuccess && !success && token)) {
			instagramReauthActions.reconnectDatasourcesWithInstagramToken(token, history);
		}
	}

	render() {
		const queryString = deep(this.props, 'location.search');
		const queryJson = queryStringToJson(queryString);
		const token = deep(queryJson, 'token');
		const isSuccess = isTruthyString(deep(queryJson, 'success'));
		const isFailure = isFalsyString(deep(queryJson, 'success'));

		return (
			<Fragment>
				<Helmet>
					<title>Reauthorize Instagram</title>
					<meta
						name="description"
						content="Reauthenticate Instagram"
					/>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="instagram-reauth">
					<Banner
						isFailure={isFailure}
						isSuccess={isSuccess}
					/>
					<Messaging
						isVisible={!isSuccess}
						text={isSuccess ? messagingSuccess : isFailure ? messagingFailure : messagingWarn}
						type={isSuccess ? 'success' : isFailure ? 'failure' : 'warn'}
					/>
					<div className="margin">
						<Title
							title={isSuccess ? titleSuccess : isFailure ? titleFailure : titleWarn}
						/>
						<Cta
							isFailure={isFailure}
							isVisible={!isSuccess}
						/>
						<InstagramUser
							isFailure={isFailure}
							isSuccess={isSuccess}
							token={token}
						/>
						<BodyText
							text={isSuccess ? infoSuccess : infoWarn}
						/>
						<hr />
						<Link
							isFailure={isFailure}
							isSuccess={isSuccess}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}

InstagramQuickReauth.displayName = 'InstagramQuickReauth';

InstagramQuickReauth.propTypes = {
	analyticsActions: PropTypes.object,
	history: PropTypes.object,
	location: PropTypes.object,
	instagramReauthActions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
	instagramReauthActions: bindActionCreators(instagramReauthActions, dispatch),
});

export default connect(
	null,
	mapDispatchToProps,
)(withRouter(InstagramQuickReauth));
