import deep from 'deep-get-set';
import { find } from 'lodash';

import * as ACTIONS from './actionTypes';
import { triggerAnalyticsEvent } from './analytics.actions';
import * as api from './api/api.actions';
import { apiGetData, apiPostRecieveData } from './api/api.http.actions';
import * as appActions from './app.actions';
import { translateEagerly } from './appString.actions';
import { receiveEntities } from './entity.actions';
import { momentsDeleted, removeMoment } from './moments.actions';
import * as viewActions from './view.actions';
import { setBookCollageModalOpen } from '../actions/app.actions';
import { decrementThrobberCount, incrementThrobberCount } from '../actions/user.actions';
import { ENTITIES } from '../const/entities/entities';
import { MODAL_NAMES } from '../const/modalNames.const';
import Events from '../helpers/analytics/Events';
import * as Groups from '../helpers/groups/groups';
import hermes from '../helpers/hermes/hermes';
import { queryStringFromJson } from '../helpers/locationUtils';
import requireParam from '../helpers/requireParam';
import requireParams from '../helpers/requireParams';
import { collageFromJson } from '../model/collage.model';
import { coverBundleFromJson, normalizeCoverBundle } from '../model/coverBundle.model';
import { groupCoverBundleFromJson, normalizeGroupCoverBundle } from '../model/groupCoverBundle.model';
import { bookCoverColorFromJson, normalizebookCoverColors } from '../model/bookCoverColor.model';
import { coverCropFromJson, normalizeCoverCrop } from '../model/coverCrop.model';
import { coverTemplateFromJson, normalizeCoverTemplateFilter } from '../model/coverTemplate.model';
import { groupFromJson, normalizeGroup } from '../model/group.model';
import { groupsBySharableKeyResponseModel, normalizeGroupsBySharableKeyResponse } from '../model/groupsBySharableKeyResponse.model';
import { normalizeGroupInviteCode, groupInviteCodeModel } from '../model/groupInviteLink.model';
import { groupsSkinnyResponseModel, normalizeGroupSkinnyResponse } from '../model/groupSkinnyResponse.model';
import { mediaFromJson, normalizeMedia } from '../model/media.model';
import { mediaRectFromJson } from '../model/mediaCropRect.model';
import { modalModel } from '../model/modal.model';
import { selectUserIdString } from '../selectors/user.selectors';
import {
	selectActiveCollageMedia,
	selectBookCoverColorIds,
	selectCoverBundleIds,
	selectCurrentBookId,
	selectCurrentMoments,
	selectGroupIds
} from '../selectors/groups.selectors';
import * as groupsSelectors from '../selectors/groups.selectors';
import { selectActiveCollageMediaIndex, selectActiveMomentId, selectHasActiveMomentId, selectViewGroupId } from '../selectors/view.selectors';

export const collageReceiveMedia = (collageId, media) => ({ type: ACTIONS.COLLAGE_RECEIVE_MEDIA, collageId, media });
export const groupCoverBundleUpdate = (groupId, activeCoverBundleId) => ({ type: ACTIONS.GROUP_COVER_BUNDLE_UPDATE, groupId, activeCoverBundleId });
export const receiveAddReplacementCollageImage = (activeMomentId, activeCollageMedia, collageMedia) => ({ type: ACTIONS.COLLAGE_ADD_REPLACEMENT_IMAGE, activeMomentId, activeCollageMedia, collageMedia });
export const receiveBookLayoutOptions = (id, object) => ({ type: ACTIONS.BOOK_LAYOUT_OPTIONS_RECEIVE, id, object });
export const receiveMoments = (bookId, moments) => ({ type: ACTIONS.MOMENTS_RECEIVE, bookId, moments });
export const updateBookCoverLayoutColor = (groupId, coverColorId) => ({ type: ACTIONS.UPDATE_BOOK_COVER_COLOR_ID, groupId, coverColorId });
export const updateBookCoverLayoutVersion = (groupId, coverColorId) => ({ type: ACTIONS.UPDATE_BOOK_COVER_LAYOUT_VERSION, groupId, coverColorId });
export const updateGroupBookSize = (groupId, bookSize) => ({ type: ACTIONS.UPDATE_GROUP_BOOK_SIZE, groupId, bookSize });
export const updateGroupCustomCoverTemplate = (groupId, customCoverTemplateId) => ({ type: ACTIONS.UPDATE_GROUP_CUSTOM_COVER_TEMPLATE, groupId, customCoverTemplateId });
export const updateGroupIsHardcover = (groupId, isHardcover) => ({ type: ACTIONS.UPDATE_GROUP_IS_HARDCOVER, groupId, isHardcover });
export const updateGroupIsDisplayCaptions = (groupId, isOn) => ({ type: ACTIONS.UPDATE_GROUP_IS_DISPLAY_CAPTIONS, groupId, isOn });
export const updateGroupIsDisplayDate = (groupId, isOn) => ({ type: ACTIONS.UPDATE_GROUP_IS_DISPLAY_DATE, groupId, isOn });
export const updateGroupIsDisplayFrontTitle = (groupId, isOn) => ({ type: ACTIONS.UPDATE_GROUP_IS_DISPLAY_FRONT_TITLE, groupId, isOn });
export const updateGroupIsDisplayLocation = (groupId, isOn) => ({ type: ACTIONS.UPDATE_GROUP_IS_DISPLAY_LOCATION, groupId, isOn });
export const updateGroupIsDisplayVolumeNumber = (groupId, isOn) => ({ type: ACTIONS.UPDATE_GROUP_IS_DISPLAY_VOLUME_NUMBER, groupId, isOn });
export const updateGroupRemovePerson = (groupId, personId) => ({ type: ACTIONS.UPDATE_GROUP_REMOVE_PERSON, groupId, personId });
export const updateGroupTitle = (groupId, title) => ({ type: ACTIONS.UPDATE_GROUP_TITLE, groupId, title });
export const groupCoverBundleClear = (groupId) => ({ type: ACTIONS.GROUP_COVER_BUNDLE_REMOVED, groupId });
export const updateDeleteCollageMedia = (momentId, id) => ({ type: ACTIONS.COLLAGE_DELETE_MEDIA, momentId, id });

export const clearCoverBundle = (groupId) => (dispatch) => {
	return dispatch(api.clearCoverBundle(groupId))
		.then((data) => {
			const action = dispatch(groupCoverBundleUpdate(groupId, null));

			dispatch(action);
			hermes.deliver(action.type, action);
			dispatch(groupCoverBundleClear(groupId));

			return data; // Maintain legacy untransformed data
		});
};

export const createCollage = (momentIds, pageLayoutType) => (dispatch, getState) => {
	const state = getState();
	const hasActiveMomentId = selectHasActiveMomentId(state);
	const selectedMomentsIds = momentIds.map((momentId) => momentId.toString());
	const mediaIds = selectCurrentMoments(state)
		.filter((moment) => selectedMomentsIds.includes(moment.id.toString()))
		.map((moment) => moment.mediaId)
		.filter((mediaId) => !!mediaId);
	const groupId = selectViewGroupId(state);

	if (selectedMomentsIds.length > 0 && mediaIds.length === selectedMomentsIds.length) {
		let collageMomentId;

		dispatch(incrementThrobberCount(createCollage.name));
		dispatch(createEmptyCollagePage(selectedMomentsIds, pageLayoutType))
			.then((emptyCollagePageData) => {
				collageMomentId = deep(emptyCollagePageData, 'moment.ID');
			})
			.then(() => dispatch(mergePagesIntoCollage(collageMomentId, selectedMomentsIds)))
			.then(() => {
				if (hasActiveMomentId) {
					dispatch(incrementThrobberCount(createCollage.name));
					dispatch(viewActions.setActiveMoment(collageMomentId));
					dispatch(getCollageMedia())
						.then(() => dispatch(getMomentById(collageMomentId, groupId)))
						.then((momentbyIdData) => {
							const newMoment = deep(momentbyIdData, 'moment');
							if (newMoment) {
								hermes.deliver('momentDetailModalAddOverflowMoment', { moment: newMoment, goTo: true });
								hermes.deliver('refreshMomentsInVolume'); // TODO: update the store instead of just refreshing
								hermes.deliver('selectedMomentsClear');
								dispatch(setBookCollageModalOpen(false));
							}
						})
						.finally(() => dispatch(decrementThrobberCount(createCollage.name)));
				} else {
					hermes.deliver('refreshMomentsInVolume');
					hermes.deliver('selectedMomentsClear');
					dispatch(setBookCollageModalOpen(false));
				}
			})
			.finally(() => dispatch(decrementThrobberCount(createCollage.name)));
	}
};

export const createEmptyCollagePage = (momentToMergeIds, pageLayoutType) => (dispatch, getState) => {
	const currentMoments = selectCurrentMoments(getState());

	return dispatch(apiPostRecieveData(`/moments/AddCollagePage`, {
		groupId: selectViewGroupId(getState()),
		insertAtIndex: currentMoments.findIndex((moment) => {
			const result = moment.id.toString() === momentToMergeIds[0].toString();
			return result;
		}),
		pageLayoutType,
	}));
};

export const createGroup = ({
	bookCreationModelType,
	bookSize,
	isHardcover = false,
	isPushOn = true,
	people = [],
	source,
	title = '',
} = {}) => (dispatch) => {
	dispatch(appActions.showThrobber(createGroup.name));

	return dispatch(apiPostRecieveData('/groups', { bookCreationModelType, bookSize, isPushOn, people, title }))
		.then((resp) => {
			const group = dispatch(receiveCreatedGroupResponse(resp));

			return dispatch(toggleHardCover(group.id, isHardcover))
				.then(() => dispatch(triggerAnalyticsEvent(Events.createdChatgroup, {
					chatgroupid: group.id,
					attr1: source || '',
					attr2: group.bookCreationModelType
				})))
				.then(() => group.id);
		})
		.finally(() => dispatch(appActions.hideThrobber(createGroup.name)));
};

export const createCustomBookWithMoments = (title, momentIds, bookSize) => (dispatch) => {
	return dispatch(api.postGroupsCreateCustomWithMoments(title, momentIds, bookSize))
		.then((resp) => dispatch(receiveCreatedGroupResponse(resp)));
};

const receiveCreatedGroupResponse = (resp) => (dispatch, getState) => {
	const groupResponseFromJson = (json = {}) => ({
		success: deep(json, 'success'),
		group: groupFromJson(deep(json, 'group')),
	});

	const group = groupResponseFromJson(resp).group;
	const normalized = normalizeGroup(group);

	const entities = {
		...normalized.entities,
		[ENTITIES.GROUP_IDS]: [...selectGroupIds(getState()), group.id],
	};

	const action = receiveEntities(entities, receiveCreatedGroupResponse.name);
	dispatch(action);
	hermes.deliver(CREATE_GROUP_SUCCESS_HERMES, resp.group);

	return group;
};

export const CREATE_GROUP_SUCCESS_HERMES = `${receiveCreatedGroupResponse.name}_Success`;

export const createGroupTitle = (bookCreationModelType, source) => (dispatch) => {
	const appStringKey = Groups.getNewGroupTitleAppStringKey(bookCreationModelType, source);

	if (!appStringKey) return Promise.resolve('');

	return dispatch(translateEagerly(appStringKey));
};

export const deleteCollageMedia = () => (dispatch, getState) => {
	const state = getState();
	const activeMomentId = selectActiveMomentId(state);
	const mediaIndex = selectActiveCollageMediaIndex(state) || 0;
	const data = {
		id: activeMomentId,
		index: mediaIndex
	};
	const url = '/moments/Removecollagemedia';

	return dispatch(apiPostRecieveData(url, data))
		.then(() => {
			const activeCollageMedia = selectActiveCollageMedia(getState());
			const mediaToDelete = find(activeCollageMedia, { index: mediaIndex });

			if (!!mediaToDelete) {
				dispatch(updateDeleteCollageMedia(activeMomentId, mediaToDelete.id));
			}
		});
};

export const moveCollageMedia = (fromIndex, toIndex) => (dispatch, getState) => {
	const state = getState();
	const activeMomentId = selectActiveMomentId(state);

	return dispatch(api.moveCollageMedia(activeMomentId, fromIndex, toIndex));
};

export const getBookCoverColors = (groupId) => (dispatch, getState) => {
	if (selectBookCoverColorIds(getState()).length > 0) return Promise.resolve();

	return dispatch(api.apiBookCoverColorsGetBookCoverColors(groupId))
		.then((data) => {
			if (selectBookCoverColorIds(getState()).length === 0) {
				const bookLayoutOptions = deep(data, 'colorPairs') || [];
				const bookCoverColors = bookLayoutOptions.map(bookCoverColorFromJson);
				const normalized = normalizebookCoverColors(bookCoverColors);

				const entities = {
					...normalized.entities,
					[ENTITIES.BOOK_COVER_COLOR_IDS]: normalized.result
				};

				dispatch(receiveEntities(entities, getBookCoverColors.name));
			}
		});
};

export const getBookLayoutOptions = (groupId) => (dispatch) => {
	return dispatch(api.apiGroupsGetBookLayoutOptions(groupId))
		.then((data) => {
			const id = deep(data, 'bookLayoutOptions.ChatgroupId');
			const object = {
				id,
				coverLayoutVersion: deep(data, 'bookLayoutOptions.CoverLayoutVersion'),
				coverColorId: deep(data, 'bookLayoutOptions.CoverColorId'),
			};

			dispatch(receiveBookLayoutOptions(id, object));
		});
};

export const getCollageMedia = () => (dispatch, getState) => {
	const activeMomentId = selectActiveMomentId(getState());

	return dispatch(api.getCollageMediaByMomentId(activeMomentId))
		.then((data) => {
			const media = deep(data, 'collageMedia') || [];

			const transformedMedia = media.map(collageFromJson);

			dispatch(collageReceiveMedia(activeMomentId, transformedMedia));

			return data; // Maintain legacy untransformed data
		});
};

export const getCropRectForMedia = (mediaId, id, mediaType, bookCreationModelType) => (dispatch, getState) => {
	return dispatch(api.getCropRectForMedia(mediaId, mediaType, bookCreationModelType))
		.then((data) => {
			const cropRect = deep(data, 'cropRect');

			if (!!cropRect) {
				const transformedMediaRect = mediaRectFromJson(cropRect);

				const entities = {
					[ENTITIES.CROP_RECT]: {
						...groupsSelectors.selectCropRect(getState()),
						[mediaId]: transformedMediaRect,
					},
				};

				dispatch(receiveEntities(entities, getCropRectForMedia.name));
			}

			return data; // Maintain legacy untransformed data
		});
};

export const getCustomCoverTemplate = (customCoverTemplateId) => (dispatch) => {
	return dispatch(api.coverTemplatesGetCustomCoverTemplate(customCoverTemplateId))
		.then((data) => {
			const templateMetadata = deep(data, 'templateMetadata');
			const coverTemplate = coverTemplateFromJson(templateMetadata);
			const coverTemplateNormalized = normalizeCoverTemplateFilter(coverTemplate);

			dispatch(receiveEntities(coverTemplateNormalized.entities, getCustomCoverTemplate.name));

			return templateMetadata; // Maintain legacy untransformed data
		});
};

export const recieveCollageMedia = (collageMedia) => (dispatch, getState) => {
	const state = getState();
	const entities = {
		[ENTITIES.COLLAGE_MEDIA]: {
			...state.groups[ENTITIES.COLLAGE_MEDIA],
			...collageMedia,
		},
	};

	dispatch(receiveEntities(entities, recieveCollageMedia.name));
};

export const getMediaByMediaId = (mediaId) => (dispatch, getState) => {
	return dispatch(api.getMediaByMediaId(mediaId))
		.then((resp) => {
			const mediaModel = deep(resp, 'mediaModel');
			const normalizedMedia = normalizeMedia(mediaFromJson(mediaModel));
			dispatch(receiveEntities(normalizedMedia.entities, getMediaByMediaId.name));

			return mediaModel; // Maintain legacy untransformed data
		});
};

export const getMomentById = (momentId, groupId) => (dispatch) => {
	requireParam(momentId, 'volume', 'momentById');
	requireParam(groupId, 'groupId', 'momentById');

	const query = {
		groupId,
	};

	const url = `/moments/${momentId}?${queryStringFromJson(query)}`;

	return dispatch(apiGetData(url));
};

export const getMomentsForVolume = (groupId, volume) => (dispatch) => {
	requireParam(groupId, 'groupId', 'momentsForVolume');
	requireParam(volume, 'volume', 'momentsForVolume');

	const query = {
		groupId,
		volume,
	};

	const url = `/books/momentsForVolume?${queryStringFromJson(query)}`;

	return dispatch(apiGetData(url))
		.then((data) => {
			const moments = deep(data, 'moments');
			const bookId = deep(data, 'bookId');
			dispatch(receiveMoments(bookId, moments));

			return { data };
		});
};

export const loadGroupCoverBundle = (groupId) => (dispatch) => {
	dispatch(apiGetData(`/groups/getcurrentcoverbundle?${queryStringFromJson({ groupId })}`))
		.then((data) => {
			const currentCoverBundle = deep(data, 'currentCoverBundle');

			if (currentCoverBundle) {
				const coverBundle = groupCoverBundleFromJson(currentCoverBundle);
				const normalized = normalizeGroupCoverBundle(coverBundle);

				dispatch(receiveEntities(normalized.entities, loadGroupCoverBundle.name));
			}
		});
};

export const loadGroupInviteCodeLink = (groupId) => (dispatch) => {
	dispatch(appActions.showThrobber(loadGroupInviteCodeLink.name));

	return dispatch(api.getInviteCodeLinkForGroup(groupId))
		.then(({ link }) => {
			const normalized = normalizeGroupInviteCode(groupInviteCodeModel({
				groupId,
				link,
			}));

			dispatch(receiveEntities(normalized.entities), loadGroupInviteCodeLink.name);
		})
		.finally(() => dispatch(appActions.hideThrobber(loadGroupInviteCodeLink.name)));
};

export const loadGroupsBySharableKey = ({ sharableId, sharableKey }) => (dispatch) => {
	return dispatch(api.getGroupsBySharableKey({ sharableId, sharableKey }))
		.then((response) => {
			const normalize = normalizeGroupsBySharableKeyResponse(groupsBySharableKeyResponseModel(response));
			dispatch(receiveEntities(normalize.entities, loadGroupsBySharableKey.name));

			return response; // Maintain legacy untransformed data
		});
};

export const loadGroupSkinnyByGroupId = (groupId) => (dispatch) => {
	return dispatch(api.getGroupSkinnyByGroupId(groupId))
		.then((resp) => {
			const normalize = normalizeGroupSkinnyResponse(groupsSkinnyResponseModel(resp));
			dispatch(receiveEntities(normalize.entities, loadGroupSkinnyByGroupId.name));

			hermes.deliver(LOAD_GROUP_SKINNY_SUCCESS_HERMES, resp.group);

			return resp; // Maintain legacy untransformed data
		});
};
export const LOAD_GROUP_SKINNY_SUCCESS_HERMES = `${loadGroupSkinnyByGroupId.name}_Success`;

/**
 * Get all cover bundles that are available
 */
export const getCoverBundles = (groupId) => (dispatch, getState) => {
	if (selectCoverBundleIds(getState()).length > 0) return Promise.resolve();

	return dispatch(api.getCoverbundles(groupId))
		.then((data) => {
			if (selectCoverBundleIds(getState()).length === 0) {
				const coverBundles = deep(data, 'coverBundles');

				if (coverBundles) {
					const formattedCoverBundles = coverBundles.map(coverBundleFromJson);
					const normalized = normalizeCoverBundle(formattedCoverBundles);

					const entities = {
						...normalized.entities,
						[ENTITIES.COVER_BUNDLE_IDS]: normalized.result,
					};

					dispatch(receiveEntities(entities, getCoverBundles.name));
				}
			}

			return data.coverBundles; // Maintain legacy untransformed data
		});
};

export const mergePagesIntoCollage = (collageMomentId, momentToMergeIds) => (dispatch, getState) => {
	const state = getState();
	const url = `/books/MergePagesIntoCollage`;
	const data = {
		collageMomentId,
		groupId: selectViewGroupId(state),
		momentToMergeIds,
		smartcrop: false,
	};

	return dispatch(apiPostRecieveData(url, data))
		.then((data) => {
			const bookId = selectCurrentBookId();
			dispatch(viewActions.momentSelectedClear());
			dispatch(momentsDeleted(momentToMergeIds, bookId));

			return data;
		});
};

export const mergeSinglePageIntoCollage = (momentId, mediaIndex) => (dispatch, getState) => {
	const state = getState();
	const activeMomentId = selectActiveMomentId(state);
	const groupId = selectViewGroupId(state);
	const url = '/books/MergePageIntoCollage';
	const data = {
		collageMomentId: activeMomentId,
		groupId,
		index: mediaIndex,
		momentToMergeId: momentId,
		smartcrop: false,
	};

	return dispatch(apiPostRecieveData(url, data));
};

export const momentConvertToIndividualPages = (collageMomentId, continuationMomentId) => (dispatch, getState) => {
	const state = getState();
	const url = `/books/BreakUpCollagePage`;
	const data = {
		collageMomentId,
		groupId: selectViewGroupId(state),
	};

	return dispatch(apiPostRecieveData(url, data))
		.then((data) => {
			const bookId = selectCurrentBookId(state);
			dispatch(viewActions.momentSelectedClear());
			dispatch(momentsDeleted([collageMomentId], bookId));
			if (continuationMomentId) {
				dispatch(removeMoment(continuationMomentId, bookId));
			}

			return data;
		});
};

export const openGroupIncompleteSyncingErrorModal = () => (dispatch) => {
	const syncErrorModal = modalModel({ id: MODAL_NAMES.GROUP_INCOMPLETE_SYNCING_ERROR_MODAL });
	dispatch(viewActions.openModalById(syncErrorModal));
};

export const removePerson = (groupId, personId) => (dispatch) => {
	return dispatch(api.postGroupRemovePerson(groupId, personId))
		.then(() => {
			const action = updateGroupRemovePerson(groupId, personId);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const setBookSize = (groupId, bookSize) => (dispatch) => {
	return dispatch(api.apiGroupsSetBookSize(groupId, bookSize))
		.then(() => {
			const action = updateGroupBookSize(groupId, bookSize);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const setCoverBundle = (groupId, coverBundleId, startAtVolume) => (dispatch) => {
	return dispatch(api.setCoverBundle(groupId, coverBundleId, startAtVolume))
		.then((data) => {
			dispatch(groupCoverBundleClear(groupId));
			dispatch(groupCoverBundleUpdate(groupId, coverBundleId));
			dispatch(loadGroupCoverBundle(groupId));

			return data; // Maintain legacy untransformed data
		});
};

export const setCoverColor = (groupId, coverColorId) => (dispatch) => {
	return dispatch(api.apiGroupsSetCoverColor(groupId, coverColorId))
		.then(() => dispatch(updateBookCoverLayoutColor(groupId, coverColorId)));
};

export const setCustomCoverTemplate = (groupId, templateId) => (dispatch) => {
	return dispatch(api.postGroupsSetCustomCoverTemplate(groupId, templateId))
		.then(() => {
			const action = updateGroupCustomCoverTemplate(groupId, templateId);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const setTitle = (groupId, title) => (dispatch) => {
	return dispatch(api.postGroupsSetTitle(groupId, title))
		.then(() => {
			const action = updateGroupTitle(groupId, title);
			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const toggleDisplayCaptions = (groupId, isOn) => (dispatch) => {
	return dispatch(api.postGroupToggleCaptions(groupId, isOn))
		.then(() => {
			const action = updateGroupIsDisplayCaptions(groupId, isOn);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const toggleDisplayDate = (groupId, isOn) => (dispatch) => {
	return dispatch(api.postGroupToggleDate(groupId, isOn))
		.then(() => {
			const action = updateGroupIsDisplayDate(groupId, isOn);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const toggleDisplayFrontTitle = (groupId, isOn) => (dispatch) => {
	return dispatch(api.postGroupToggleFrontTitle(groupId, isOn))
		.then(() => {
			const action = updateGroupIsDisplayFrontTitle(groupId, isOn);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const toggleDisplayLocation = (groupId, isOn) => (dispatch) => {
	return dispatch(api.postGroupToggleLocation(groupId, isOn))
		.then(() => {
			const action = updateGroupIsDisplayLocation(groupId, isOn);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const toggleDisplayVolumeNumber = (groupId, isOn) => (dispatch, getState) => {
	const userId = selectUserIdString(getState());

	return dispatch(api.postGroupToggleDisplayVolumeNumber(groupId, isOn, userId))
		.then(() => {
			const action = updateGroupIsDisplayVolumeNumber(groupId, isOn);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const toggleHardCover = (groupId, isHardcover) => (dispatch, getState) => {
	const userId = selectUserIdString(getState());

	return dispatch(api.postGroupsToggleHardCover(groupId, isHardcover, userId))
		.then(() => {
			const action = updateGroupIsHardcover(groupId, isHardcover);

			dispatch(action);
			hermes.deliver(action.type, action);
		});
};

export const upgradeBookLayout = (groupId, coverColorId) => (dispatch) => {
	return dispatch(api.apiGroupsUpgradeBookLayout(groupId, coverColorId))
		.then(() => dispatch(updateBookCoverLayoutVersion(groupId, coverColorId)));
};

const addReplacementCollageImage = () => (dispatch, getState) => {
	const activeMomentId = selectActiveMomentId(getState());

	return dispatch(api.getCollageMediaByMomentId(activeMomentId))
		.then((data) => {
			const collageMedia = (deep(data, 'collageMedia') || []).map(collageFromJson);
			const activeCollageMedia = selectActiveCollageMedia(getState());

			dispatch(receiveAddReplacementCollageImage(activeMomentId, activeCollageMedia, collageMedia));
		});
};

export const updateCollagePhoto = (momentId) => (dispatch, getState) => {
	const mediaIndex = selectActiveCollageMediaIndex(getState()) || 0;

	return dispatch(mergeSinglePageIntoCollage(momentId, mediaIndex))
		.then(() => {
			const activeCollageMedia = selectActiveCollageMedia(getState());
			const mediaToDelete = find(activeCollageMedia, { index: mediaIndex });

			if (!!mediaToDelete) {
				dispatch(updateDeleteCollageMedia(mediaToDelete.momentId, mediaToDelete.id));
			}

			dispatch(addReplacementCollageImage());
		});
};

/**
 * Get all of the books belonging to a group.
 *
 * @param {int} groupId ID of the group to get the groups for.
 * @param start Index to start at
 * @param count # of books to load
 * @param downloadState State of download
 * @param sort ascending by default, use 'desc' for descending order
 *
 * @returns {promise}
 */
export const getBooksInGroup = (groupId, start, count, downloadState, sort) => (dispatch) => {
	return dispatch(api.getBooksInGroup(groupId, start, count, downloadState, sort))
		.then((data) => {
			const books = deep(data, 'books');

			return books; // Maintain legacy untransformed data
		});
};

export const getCoverPhysicalCropInfo = (groupId, mediaId) => (dispatch) => {
	return dispatch(api.getCoverPhysicalCropInfo(groupId, mediaId))
		.then((resp) => {
			const { physicalCropInfo } = resp;
			const coverCrop = coverCropFromJson(groupId, mediaId, physicalCropInfo);
			const normalized = normalizeCoverCrop(coverCrop);

			dispatch(receiveEntities(normalized.entities, getCoverPhysicalCropInfo.name));
		});
};

/**
 * Get a list of available cover previews for a given group
 * @param groupId Group ID
 * @param hardcover Hardcover null|true|false
 * @param filter
 * @returns {*}
 */
export const getAvailableCoverPreviews = (groupId, hardcover = false, filter) => (dispatch) => {
	requireParams({ groupId }, getAvailableCoverPreviews.name);

	dispatch(appActions.showThrobber(getAvailableCoverPreviews.name));

	return dispatch(api.getAvailableCoverPreviews(groupId, hardcover, filter))
		.then((resp) => {
			const coverPreviewUrls = deep(resp, 'coverPreviewUrls');

			return coverPreviewUrls;
		})
		.finally(() => dispatch(appActions.hideThrobber(getAvailableCoverPreviews.name)));
};

export const setMultipleCollageMedia = (mediaIds, collageMomentId = null) => (dispatch, getState) => {
	const state = getState();
	const momentId = collageMomentId || selectActiveMomentId(state);

	return dispatch(api.postSetMultipleCollageMedia(momentId, mediaIds));
};
