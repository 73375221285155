import deep from 'deep-get-set';
import { schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';
import { isNullOrUndefined } from '../helpers/bool/bool';

export const currencyModel = (json = {}) => {
	const id = deep(json, 'Id');

	return {
		id: !isNullOrUndefined(id) && String(id),
		code: deep(json, 'Code'),
		name: deep(json, 'Name'),
	};
};

export const currencySchema = new schema.Entity(ENTITIES.CURRENCY);
