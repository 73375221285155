import React from 'react';
import PropTypes from 'prop-types';

import ConfigurableLink from '../ConfigurableLink/ConfigurableLink';
import './FooterSocialSection.css';

const FooterSocialSection = ({ analyticEvent, facebook, instagram, pinterest, title, twitter, youtube }) => {
	const links = [
		{ name: 'instagram', icon: 'instagram', url: instagram },
		{ name: 'facebook', icon: 'facebook-f', url: facebook },
		{ name: 'twitter', icon: 'twitter', url: twitter },
		{ name: 'youtube', icon: 'youtube-play', url: youtube },
		{ name: 'pinterest', icon: 'pinterest-p', url: pinterest }
	]
		.filter((link) => !!link.url)
		.map((link) => (
			<li key={link.icon}>
				<ConfigurableLink
					analyticObj={{
						attr2: 'social',
						attr3: link.name
					}}
					analyticEvent={analyticEvent}
					target="_blank"
					path={link.url}
					ariaLabel={link.name}
				>
					<i className={`fa fa-${link.icon}`} />
				</ConfigurableLink>
			</li>
		));

	return (
		<div className="FooterSection FooterSocialSection">
			<h4>{title}</h4>
			<ul>{links}</ul>
		</div>
	);
};

FooterSocialSection.propTypes = {
	analyticEvent: PropTypes.func,
	facebook: PropTypes.any,
	instagram: PropTypes.any,
	pinterest: PropTypes.any,
	title: PropTypes.string,
	twitter: PropTypes.any,
	youtube: PropTypes.any
};

export default FooterSocialSection;
