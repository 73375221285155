import { difference, omit } from 'lodash';
import deep from 'deep-get-set';
import { matchPath } from 'react-router';

import { parseIntDec } from './numbers/numbers';
import { isString } from './strings/strings';
import { CHATBOOKS_URIS, CHATBOOKS_ACTION_URI_PROTOCOL } from '../const/actionUri.const';
import { ENVIRONMENTS } from '../const/environments.const';
import { SERVER_CALLBACK_PARAM, SERVER_CALLBACK_FUNCTION_NAME_PARAM } from '../const/serverCallback.const';

/**
 * Compares two URLs using optional patterns to exclude certain values in the comparison.
 *
 * @param {string} location1 - First URL to compare
 * @param {string} location2 - Second URL to compare
 * @param {?Object} excludePatterns - Object with keys corresponding to URL parts, whose values consist
 * of a regular expression or array of regular expressions of patterns to exclude in the comparison.
 * @returns {boolean}
 */
export function urlsAreEqual(location1, location2, excludePatterns) {
	if (location1 === location2) {
		return true;
	}
	if ((location1 && !location2) || (!location1 && location2)) {
		return false;
	}

	const parts1 = urlToParts(location1),
		parts2 = urlToParts(location2);

	for (const key in parts1) {
		// Skip `queryValues` object, since it should have similar data to the `query` array
		if (key === 'queryValues') {
			continue;
		}

		let patterns = (excludePatterns && excludePatterns[key]) || [],
			value1 = parts1[key],
			value2 = parts2[key];

		if (!Array.isArray(patterns)) {
			patterns = [patterns];
		}

		// Handle arrays, like `query` property
		if (Array.isArray(value1) || Array.isArray(value2)) {
			value1 = filterQueries(value1, patterns);
			value2 = filterQueries(value2, patterns);

			// Get array of values that aren't common between the two arrays.
			// If the length is greater than zero, the URLs aren't equal.
			const diff = difference(value1, value2);
			if (diff && diff.length) {
				return false; // Short-circuit `for` loop
			}
		} else {
			// Handle simple string values
			const reducedValues = patterns.reduce(
				function (values, pattern) {
					return values.map(function (value) {
						return value.replace(pattern, '');
					});
				},
				[parts1[key] || '', parts2[key] || '']
			);
			if (reducedValues[0] !== reducedValues[1]) {
				return false; // Short-circuit `for` loop
			}
		}
	}

	// Didn't find any differences, so default to `true`
	return true;
}

/**
 * Forces a location string to use HTTPS
 *
 * @param {string} location A URL to convert.
 */
export function convertToHttps(location) {
	return location.replace(/http[s]?/i, 'https');
}

/**
 * @private
 * Removes values from `queries` array matching any of the provided regular expressions.
 *
 * @param {string[]} queries - Array of key-value pairs to filter
 * @param {RegExp[]} patterns - Array of regular expressions for excluding values from queries array
 * @returns {string[]} - Array of key-value pairs that did not match any of the provided expressions
 */
function filterQueries(queries, patterns) {
	return patterns.reduce(function (values, pattern) {
		return values.filter(function (value) {
			return !pattern.test(value);
		});
	}, queries || []);
}

/**
 * Returns a URL string constructed from the specified URL parts object.
 *
 * @param {Object} parts - URL parts object
 * @param {boolean} excludeBase - Exclude scheme, domain, and port from returned value
 * @returns {string}
 */
export function urlFromParts(parts, excludeBase) {
	const strings = [];
	if (parts.scheme && !excludeBase) {
		strings.push(parts.scheme + '://');
	}
	if (parts.domain && !excludeBase) {
		strings.push(parts.domain);
	}
	if (parts.port && !excludeBase) {
		strings.push(':' + parts.port);
	}
	if (parts.path) {
		strings.push(parts.path);
	}
	if (parts.query && parts.query.length) {
		strings.push('?' + parts.query.join('&'));
	}
	if (parts.fragment) {
		strings.push('#' + parts.fragment);
	}

	return strings.join('');
}

/**
 * Parses a URL into an object representing the different parts of a URL.
 * For example, http://example.com:80/path?query=string#fragment would return:
 * {
 * 		scheme: 'http',
 * 		domain: 'example.com',
 * 		port: 80,
 * 		path: '/path',
 * 		query: ['query=string'],
 * 		fragment: 'fragment'
 * }
 *
 * @param {string} url - URL to parse
 * @returns {Object} - Object representing URL parts
 */
export function urlToParts(url) {
	if (!url) {
		throw new Error('No URL specified');
	}
	// Break up URL into parts:
	// http://app.chatbooks.com:80/path?search=value#anchor
	// 1111111222222222222222223334444455555555555556666666
	const matches = url.match(/(http[s]?:\/\/)?([^:/?#]*)?(:[0-9]+)?([^?#]*)?(\?[^#]*)?(#.*)?/i);
	if (!matches) {
		throw new Error('Invalid URL: ' + url);
	}

	const scheme = matches[1] ? matches[1].replace(/:\/\/$/, '') : undefined;
	const domain = matches[2];
	const port = matches[3] ? parseIntDec(matches[3].replace(/^:/, '')) : undefined; // Remove leading ':' and parse value
	const path = (matches[4] || '').replace(/\/$/, '') || '/'; // Remove trailing '/' and make sure path at least consists of '/'
	let query = (matches[5] || '').replace(/^\?/, ''); // Remove leading '?'. We'll split it into an array later.
	const fragment = matches[6] ? matches[6].replace(/^#/, '') || undefined : undefined; // Remove leading '#'

	// Create an object with key-value pairs for quick lookup
	const queryValues = queryStringToJson(query);

	// Convert query string into array of key-value pairs
	query = Object.keys(queryValues).map((k) => {
		const value = queryValues[k];

		return value !== null && typeof value !== 'undefined' ? `${k}=${value}` : k;
	});

	return {
		scheme,
		domain,
		port,
		path,
		query,
		queryValues,
		fragment
	};
};

export const actionUriToParts = (url) => {
	if (!url) {
		throw new Error('No URL specified');
	}

	// chatbooks://actionPath?search=value
	const matches = url.match(/(chatbooks:\/\/)?([^:/?#]*)?(\?[^#]*)?/i);

	// const chatbooksProtocol = matches[1];
	const name = matches[2];
	const queryString = matches[3] || '';

	return {
		name,
		queryParams: queryStringToJson(queryString),
	};
};

export const actionUriFromParts = (parts) => {
	if (!parts.name) throw new Error('No action name');

	return `${CHATBOOKS_ACTION_URI_PROTOCOL}${parts.name}?${queryStringFromJson(parts.queryParams)}`;
};

export const actionUriWithAddedQueryParams = (actionUri, newQueryParams) => {
	const actionUriParts = actionUriToParts(actionUri);

	return actionUriFromParts({
		...actionUriParts,
		queryParams: {
			...actionUriParts.queryParams,
			...newQueryParams,
		}
	});
};

/**
 * Determines if a path is part of the "Web App" portion of the website.
 * @param {string} path URI path
 */
export const isAppRoute = (path) => {
	return path && /\/app/.test(path);
};

/**
 * Determines if a path is a Chatbooks action - 'chatbooks://{action_id}.
 * @param {string} path URI path
 */
export const isActionUri = (path) => {
	return path && /^chatbooks:\/\//.test(path);
};

/**
 * Determines if a path is part of the chatbooks blog portion of the website
 * @param {string} path URI path
 */
export const isBlogRoute = (path) => {
	return path && /\/blog/.test(path);
};

export const isDevelopmentEnvironment = (path) => {
	return path && /.*dev\.chatbooks\.com.*/.test(path);
};

/**
 * Determines if a path is part of the "Marketing" portion of the website.
 * @param {string} path URI path
 */
export const isMarketingRoute = (path) => {
	return path && !isAppRoute(path) && !isBlogRoute(path);
};

export const isStagingEnvironment = (path) => {
	return path && /.*staging\.chatbooks\.com.*/.test(path);
};

export const isProductionEnvironment = (path) => {
	return path && /.*api-prod\.chatbooks\.com.*/.test(path);
};

export const getEnvironmentFromUrl = (path) => {
	if (isProductionEnvironment(path)) return ENVIRONMENTS.PROD;
	if (isStagingEnvironment(path)) return ENVIRONMENTS.STAGING;
	if (isDevelopmentEnvironment(path)) return ENVIRONMENTS.DEV;

	return ENVIRONMENTS.LOCAL;
};

export const parseHash = (hash) => {
	const obj = {};
	const splitOnHash = hash.split('#');
	const hashFragment = splitOnHash[splitOnHash.length - 1];
	const params = hashFragment.split('&');

	params.forEach((param) => {
		if (param === '') return;

		const keyValue = param.split('=');
		if (keyValue.length > 1) {
			obj[keyValue[0]] = keyValue[1];
		} else {
			obj[keyValue[0]] = null;
		}
	});

	return obj;
};

export function queryStringPartFromJson(obj) {
	const query = queryStringFromJson(obj);

	return !!query ? `?${query}` : '';
};

/**
 * Build a query string based on object
 * @param obj
 * @returns {string}
 */
export function queryStringFromJson(obj) {
	if (!obj) return '';

	return Object.keys(obj).reduce((total, key) => {
		if (!obj.hasOwnProperty(key)) return total;

		const queryParamKey = encodeURIComponent(key);
		const value = obj[key];

		const isConvertableToString = typeof value === 'boolean' || typeof value === 'string' || typeof value === 'number';

		if (isConvertableToString) return [...total, `${queryParamKey}=${encodeURIComponent(value)}`];
		if (Array.isArray(value)) return [...total, `${queryStringFromArray(value, queryParamKey)}`];

		return total;
	}, []).join('&');
}

export function queryStringFromArray(array = [], name) {
	if (!Array.isArray(array) || !name) return '';

	return array.reduce((total, value) => {
		const isConvertableToString = typeof value === 'boolean' || typeof value === 'string' || typeof value === 'number';

		return !isConvertableToString ? total : [
			...total,
			`${encodeURIComponent(name)}=${encodeURIComponent(value)}`
		];
	}, []).join('&');
};


/**
 * Update a query with a new object
 * @param str
 * @param newParamsObj
 * @returns {*}
 */
export function queryStringMergeJson(str, newParamsObj) {
	const match = /\?(.*)$/.exec(str);

	if (match && match[1]) {
		const queryParams = queryStringToJson(match[1]);
		const updatedQueryParams = {
			...queryParams,
			...newParamsObj
		};
		str = str.replace(match[1], queryStringFromJson(updatedQueryParams));
	}

	return str;
}

/**
 * Pulls query params, decodes them, and puts them in a map.
 * @param {string} url A URL path
 */
export const queryStringToJson = (url) => {
	if (!isString(url)) return {};

	const parts = url.replace(/^.*\?/, '').split('&');
	const result = parts.reduce((obj, str) => {
		const pair = str.split('=');
		if (pair.length && pair[0]) {
			const key = pair[0];
			const value = pair.length > 1 ? pair[1] : undefined;

			return {
				...obj,
				[key]: decodeURIComponent(value)
			};
		}

		return obj;
	}, {});

	try {
		return JSON.parse(JSON.stringify(result));
	} catch (err) {
		return {};
	}
};

/**
 * Checks if a URL starts with http or https.
 *
 * @param {string} location A location URL to check.
 */
export function startsWithHttps(location) {
	return /^http[s]?:\/\//i.test(location);
}

/**
 * Convert query params to an object
 *
 * @param {string} url
 */
export const queryStringToObject = (str) => {
	if (!str || typeof str !== 'string') return null;

	return str
		.split('&')
		.reduce((total, param) => {
			const paramArray = param.split('=');
			const key = paramArray[0];
			const val = paramArray[1];
			const currentVal = deep(total, `${key}`) || [];

			return {
				...total,
				[key]: [
					...currentVal,
					val
				]
			};
		}, {});
};

/**
 * Convert query params object to a strings
 *
 * @param {object}
 * @param {array} array of keys
 */
export const objectToQueryString = (obj, keys) => {
	const urlQueryParams = keys
		.filter((key) => !!obj[key] && obj[key].length > 0)
		.map((key) => {
			const vals = obj[key];

			return vals.map((val) => `${key}=${val}`).join('&');
		}).join('&');

	return (!!urlQueryParams) ? `?${urlQueryParams}` : '';
};

/**
 * Get route value from the url by key
 *
 * @param {string} url
 * @param {string} routePath
 * @param {string} key
 */
export const getRouteValueFromUrlByKey = (url, routePath, key) => {
	const { path } = urlToParts(url);
	const match = matchPath(path, routePath) || {};

	return deep(match, `params.${key}`);
};

/**
 * Get Url Query String
 * This will simply return everything after the "?" and before a "#"
 *
 * @param {string} url
 */
export const getUrlQueryStringPart = (url) => {
	if (!url || typeof url !== 'string' || (url.indexOf('?') === -1)) return null;

	const urlAfterPath = url.split('?')[1];

	return (urlAfterPath.indexOf('#') > -1) ? urlAfterPath.split('#')[0] : urlAfterPath;
};

export const locationExtractQueryParamObject = (location) => {
	if (!location) return null;

	const search = deep(location, 'search');

	if (!search) return null;

	const queryString = getUrlQueryStringPart(search);

	return queryStringToObject(queryString) || {};
};

export const serverCallbackParamSplit = (search) => {
	const removeCbCallbackParam = new RegExp(`${SERVER_CALLBACK_PARAM}=[^&#]*`);
	const searchWithCallbackParamOnly = search.replace(removeCbCallbackParam, SERVER_CALLBACK_PARAM);
	const queryParamSplitter = '&';
	const queryParamStarter = '?';

	if (new RegExp(`${queryParamSplitter}${SERVER_CALLBACK_PARAM}`).test(searchWithCallbackParamOnly)) {
		return searchWithCallbackParamOnly.split(`${queryParamSplitter}${SERVER_CALLBACK_PARAM}${queryParamSplitter}`);
	} else {
		return searchWithCallbackParamOnly.split(`${queryParamStarter}${SERVER_CALLBACK_PARAM}${queryParamSplitter}`);
	}
};

export const serverCallbackActionUriFromLocation = (search) => `${CHATBOOKS_URIS.CALLBACK}?${search}`;

export const serverCallbackValuesFromQueryParams = (queryParams) => ({
	name: deep(queryParams, SERVER_CALLBACK_FUNCTION_NAME_PARAM),
	params: omit(queryParams, SERVER_CALLBACK_FUNCTION_NAME_PARAM),
});