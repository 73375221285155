import {
	QUICKFLOW_SOURCE_CAMERA_ROLL,
	QUICKFLOW_SOURCE_FACEBOOK,
	QUICKFLOW_SOURCE_FAVORITES,
	QUICKFLOW_SOURCE_INSTAGRAM,
} from '../QuickFlow.const';

export default [
	{
		name: 'Camera Roll',
		key: QUICKFLOW_SOURCE_CAMERA_ROLL,
		img: 'https://cdn.chatbooks.com/mk/2020-07-camera-roll-icon.png',
	},
	{
		name: 'Instagram',
		key: QUICKFLOW_SOURCE_INSTAGRAM,
		img: '/assets/img/source_instagram.svg',
	},
	{
		name: 'Favorites',
		key: QUICKFLOW_SOURCE_FAVORITES,
		img: 'https://cdn.chatbooks.com/mk/2020-07-favorite-heart-icon.png',
	},
	{
		name: 'Facebook',
		key: QUICKFLOW_SOURCE_FACEBOOK,
		img: '/assets/img/source_facebook.svg',
	},
];
