import deep from 'deep-get-set';
import { schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const shippingMessage = (json = {}, index) => ({
	index,
	iconUrl: deep(json, 'IconUrl'),
	message: deep(json, 'Message'),
	severity: deep(json, 'Severity'),
});

export const shippingMessagesFromJson = (jsonArray = []) => {
	return jsonArray.map((json, index) => shippingMessage(json, index));
};

export const shippingMessageSchema = new schema.Entity(ENTITIES.SHIPPING_MESSAGE, {}, {
	idAttribute: 'index',
});

export const shippingMessagesSchema = new schema.Array(shippingMessageSchema);

