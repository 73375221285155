export function wrapCookie(cookiesApi, key) {
  return {
    /**
     * this value will be stale if not used in hooks
     */
    value: cookiesApi.get(key),
    get: () => cookiesApi.get(key),
    set: (value) => cookiesApi.set(key, value),
    remove: () => cookiesApi.remove(key),
  };
}
