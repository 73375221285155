import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import Polaroid from '../Polaroid/Polaroid';
import './Polaroids.css';

const Polaroids = ({ isInset, paddingTop, paddingBottom, polaroids: polaroidsProps }) => {
	const polaroids = polaroidsProps.map((polaroid, index) => <Polaroid key={`Polaroid-${index}`} {...polaroid} />);
	const className = classnames('Polaroids-Container');

	return (
		<PageFlowContainer className={className} isInset={isInset} paddingTop={paddingTop} paddingBottom={paddingBottom}>
			{polaroids}
		</PageFlowContainer>
	);
};

Polaroids.displayName = 'Polaroids';

Polaroids.propTypes = {
	isInset: PropTypes.bool,
	paddingTop: PropTypes.string,
	paddingBottom: PropTypes.string,
	polaroids: PropTypes.arrayOf(PropTypes.object)
};

Polaroids.defaultProps = {
	isInset: false,
	polaroids: []
};

export default Polaroids;
