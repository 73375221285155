import deep from 'deep-get-set';
import { find, filter, first, some, takeWhile } from 'lodash';
import { createSelector } from 'reselect';

import { selectHasAddress, selectDefaultAddressId, selectAddressMap } from './address.selectors';
import { selectCheckoutSourceCookie, selectAppCookiesState } from './appCookie.selectors';
import { selectGroupMap, selectCurrentGroupSubscriptionsProductsIds, selectGroupOrderCalculatedShippingOptionMap, selectCurrentGroupBookCreationModelType, selectCurrentGroupOrderCalculatedShippingOptions, selectOrderCalculatedShippingOptionMap } from './groups.selectors';
import {
	selectIsLoadSuccessfulFunc,
	selectIsStateInitializingFunc,
	selectIsStateLoadingFunc,
	selectLoadingErrorMap,
} from './loading.selectors';
import {
	selectFirstPaymentMethod,
	selectHasPaymentMethod,
	selectPaymentMethodMap,
} from './paymentMethod.selectors';
import { LOADING_KEYS } from '../actions/loading.actions';
import {
	BREADCRUMBABLE_CHECKOUT_STEPS,
	CART_CHECKOUT_CONTINUE_TEXT_MAP,
	CART_CHECKOUT_STEPS,
	CHECKOUT_SHIPPING_STEPS,
	CHECKOUT_STEP_APP_STRING_MAP,
	CHECKOUT_STEP_LOCATION_MAP,
	CHECKOUT_STEPS,
	SELECTION_CHECKOUT_STEPS,
} from '../const/checkoutSteps.const';
import { COUNTRY_CODES } from '../const/countries.const';
import { REDUCERS } from '../const/reducers.const';
import { CHECKOUT_TYPES } from '../const/checkoutTypes.const';
import { ENTITIES } from '../const/entities/entities';
import { LOAD_STATUS } from '../const/loadStatus.const';
import { CHOOSE_PAYMENT_OPTIONS, NEW_PAYMENT_OPTION } from '../const/payment.const';
import { ORDER_FORM_SECTIONS, ORDER_FORM_SECTION_STATES } from '../const/seriesCheckout.const';
import { firstItemInArray } from '../helpers/arrays/arrays';
import {
	checkoutContinueButtonAppString,
	convertToLimitedNextStepMap,
} from '../helpers/checkout/checkout.helpers';
import { isNumber } from '../helpers/numbers/numbers';
import { getCartItemTitle, getCartItemRemoveAppString } from '../model/cartItem.model';
import * as ShippingOption from '../model/groupOrderCalculatedShippingOption.model';
import * as Product from '../model/product.model';

// ----- Root -----
export const selectCheckoutState = (state) => deep(state, `${REDUCERS.CHECKOUT}`);

// ----- checkout -----
export const selectCarts = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.CART}`)
);

export const selectCartId = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, 'cartId')
);

export const selectCouponCodeMap = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.COUPON_CODE}`) || {}
);

export const selectCouponCodeIds = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.COUPON_CODE_IDS}`) || []
);

export const selectShippingOptionsResponse = createSelector(
	selectCheckoutState,
	(checkout) => {
		const shippingOptionResponseLocation = `${ENTITIES.SHIPPING_OPTION_VIEW_RESPONSE}.${ENTITIES.SHIPPING_OPTION_VIEW_RESPONSE}`;

		return deep(checkout, shippingOptionResponseLocation);
	}
);

export const selectCurrentSeriesOrder = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, 'order') || {}
);

export const selectCurrentSeriesOrderAddressId = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, 'addressId')
);

export const selectCurrentSeriesOrderAddress = createSelector(
	selectCurrentSeriesOrderAddressId,
	selectAddressMap,
	(addressId, addressMap) => deep(addressMap, `${addressId}`)
);

export const selectCurrentSeriesOrderAddressName = createSelector(
	selectCurrentSeriesOrderAddress,
	(address) => deep(address, 'name')
);

export const selectCurrentSeriesOrderAddressHtmlFormattedNoName = createSelector(
	selectCurrentSeriesOrderAddress,
	(address) => deep(address, 'htmlFormattedNoName')
);

export const selectCurrentSeriesOrderCouponCodeId = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, 'couponCodeId')
);

export const selectCurrentSeriesOrderPaymentMethodId = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, 'paymentMethodId')
);

export const selectCurrentSeriesOrderFormAddressState = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, `${ORDER_FORM_SECTIONS.ADDRESS}`)
);

export const selectCurrentSeriesOrderFormCodeState = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, `${ORDER_FORM_SECTIONS.CODE}`)
);

export const selectCurrentSeriesOrderFormPaymentMethodState = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, `${ORDER_FORM_SECTIONS.PAYMENT_METHOD}`)
);

export const selectCurrentSeriesOrderFormShippingMethodState = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, `${ORDER_FORM_SECTIONS.SHIPPING_METHOD}`)
);

export const selectCurrentSeriesOrderFormSubscriptionState = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, `${ORDER_FORM_SECTIONS.SUBSCRIPTION}`)
);

export const selectCurrentSeriesOrderPaymentMethod = createSelector(
	selectCurrentSeriesOrderPaymentMethodId,
	selectPaymentMethodMap,
	(paymentMethodId, paymentMethodMap) => deep(paymentMethodMap, `${paymentMethodId}`)
);

export const selectCurrentSeriesOrderPaymentMethodIsExpired = createSelector(
	selectCurrentSeriesOrderPaymentMethod,
	(paymentMethod) => {
		const expirationDate = new Date(deep(paymentMethod, 'expirationDate'));

		return new Date().getTime() > expirationDate.getTime();
	}
);

export const selectCurrentSeriesOrderPreviousShippingTitle = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, 'previousShippingTitle')
);

export const selectCurrentSeriesOrderProductId = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, 'productId')
);

export const selectCurrentSeriesOrderShippingOptionId = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, 'shippingOptionId')
);

export const selectCurrentSeriesOrderShippingOptionTitle = createSelector(
	selectGroupOrderCalculatedShippingOptionMap,
	selectOrderCalculatedShippingOptionMap,
	selectCurrentSeriesOrderShippingOptionId,
	(shippingOptionMap, orderCalculatedShippingOptionMap, orderShippingOptionId) => {
		const title = ShippingOption.getCalculatedShippingOptionTitle(
			deep(shippingOptionMap, `${orderShippingOptionId}`),
			orderCalculatedShippingOptionMap
		);
		const price = ShippingOption.getPriceText(deep(shippingOptionMap, `${orderShippingOptionId}`));

		return `${title} - ${price}`;
	}
);

export const selectCurrentSeriesOrderShippingOptionTrackingDescriptionKey = createSelector(
	selectGroupOrderCalculatedShippingOptionMap,
	selectOrderCalculatedShippingOptionMap,
	selectCurrentSeriesOrderShippingOptionId,
	(shippingOptionMap, orderCalculatedShippingOptionMap, orderShippingOptionId) => {
		return ShippingOption.getCalculatedShippingOptionTrackingDescriptionKey(deep(shippingOptionMap, `${orderShippingOptionId}`), orderCalculatedShippingOptionMap);
	}
);

export const selectCurrentSeriesOrderFormIsTryingToSubmit = createSelector(
	selectCurrentSeriesOrder,
	(seriesOrder) => deep(seriesOrder, 'isTryingToSubmit') || false
);

const selectCurrentSeriesOrderFormIsAddressErrored = createSelector(
	selectCurrentSeriesOrderFormAddressState,
	(addressState) => addressState === ORDER_FORM_SECTION_STATES.ADD
);

const selectCurrentSeriesOrderFormIsShippingOptionErrored = createSelector(
	selectCurrentSeriesOrderShippingOptionId,
	(shippingOptionId) => !shippingOptionId
);

const selectCurrentSeriesOrderFormIsPaymentMethodErrored = createSelector(
	selectCurrentSeriesOrderFormPaymentMethodState,
	selectCurrentSeriesOrderPaymentMethodIsExpired,
	(paymentMethodState, cardIsExpired) => paymentMethodState === ORDER_FORM_SECTION_STATES.ADD || cardIsExpired
);

export const selectCurrentSeriesOrderErrors = createSelector(
	selectCurrentSeriesOrderFormIsAddressErrored,
	selectCurrentSeriesOrderFormIsShippingOptionErrored,
	selectCurrentSeriesOrderFormIsPaymentMethodErrored,
	selectCurrentSeriesOrderFormIsTryingToSubmit,
	(isAddressError, isShippingError, isPaymentMethodError, isTryingToSubmit) => {
		if (!isTryingToSubmit) return {};

		return {
			address: isAddressError,
			paymentMethod: isPaymentMethodError,
			shippingMethod: isShippingError,
		};
	}
);

export const selectCurrentSereisOrderErrorScrollSelector = createSelector(
	selectCurrentSeriesOrderErrors,
	(errors) => {
		if (deep(errors, 'paymentMethod')) return 'methods-grouping';
		if (deep(errors, 'shippingMethod')) return 'shipping-grouping';
		if (deep(errors, 'address')) return 'addresses-grouping';

		return undefined;
	}
);

export const selectSeriesOrderCouponIsError = createSelector(
	selectLoadingErrorMap,
	(errorMap) => (deep(errorMap, `${LOADING_KEYS.LOAD_GROUP_SERIES_PRICING}.data.error`) || '').indexOf('code') !== -1
);

export const selectCurrentSeriesOrderCouponCodeString = createSelector(
	selectCouponCodeMap,
	selectCurrentSeriesOrderCouponCodeId,
	(couponCodeMap, orderCouponCodeId) => deep(couponCodeMap, `${orderCouponCodeId}.code`)
);

export const selectCurrentSeriesOrderShippingOptionCalculatedShippingOptionId = createSelector(
	selectGroupOrderCalculatedShippingOptionMap,
	selectCurrentSeriesOrderShippingOptionId,
	(groupOrderCalculatedShippingOptionMap, orderShippingOptionId) => deep(groupOrderCalculatedShippingOptionMap, `${orderShippingOptionId}.calculatedShippingOption`)
);

export const selectCurrentSeriesOrderIsInternational = createSelector(
	selectAddressMap,
	selectCurrentSeriesOrderAddressId,
	(addressMap, addressId) => deep(addressMap, `${addressId}.countryCode`) !== COUNTRY_CODES.US
);

export const selectCouponCodes = createSelector(
	selectCouponCodeMap,
	selectCouponCodeIds,
	(codeMap, codeIds) => codeIds.map((codeId) => deep(codeMap, `${codeId}`))
);

export const selectCurrentSeriesOrderValidCouponCodes = createSelector(
	selectCurrentGroupBookCreationModelType,
	selectCouponCodes,
	(currentGroupType, couponCodes) => couponCodes.filter((couponCode) => {
		const bookCreationModelType = deep(couponCode, `productFamily.bookCreationModelType`);
		const isInvalid = !!bookCreationModelType && currentGroupType !== bookCreationModelType;

		return !isInvalid;
	})
);

export const selectCart = createSelector(
	selectCarts,
	selectCartId,
	(carts, cartId) => deep(carts, `${cartId}`) || {}
);

export const selectCartItemMap = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.CART_ITEM}`) || {}
);

export const selectCartItemIdToRemove = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, 'cartItemToRemoveId')
);

export const selectGiftNoteLayout = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, 'giftNoteLayout')
);

export const selectGiftNoteFormatted = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, 'giftNoteFormatted')
);

export const selectCountryMap = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.COUNTRY}`) || {}
);

export const selectCouponTestStatus = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, 'couponTestStatus')
);

const selectOrderOptionsMap = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.ORDER_OPTION}`) || {}
);

export const selectProductMap = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.PRODUCT}`) || {}
);

export const selectCurrentSeriesOrderProduct = createSelector(
	selectCurrentSeriesOrderProductId,
	selectProductMap,
	(productId, productMap) => deep(productMap, `${productId}`)
);

export const selectCurrentSeriesOrderProductName = createSelector(
	selectCurrentSeriesOrderProduct,
	(product) => Product.getName(product)
);

export const selectCurrentSeriesOrderProductVariableShippingDescription = createSelector(
	selectCurrentSeriesOrderProduct,
	(product) => Product.getVariableShippingDescription(product)
);

export const selectCurrentGroupSubscriptionsProducts = createSelector(
	selectCurrentGroupSubscriptionsProductsIds,
	selectProductMap,
	(productIds, productMap) => (productIds || []).map((productId) => deep(productMap, `${productId}`))
);

export const selectCurrentGroupSubscriptionsDefaultProduct = createSelector(
	selectCurrentGroupSubscriptionsProducts,
	(products) => products.length === 1
		? products[0]
		: find(products, (product) => product.isDefault)
);

export const selectCurrentGroupSubscriptionsDefaultProductId = createSelector(
	selectCurrentGroupSubscriptionsDefaultProduct,
	(product) => deep(product, 'id')
);

export const selectCurrentSeriesOrderBestMatchShippingId = createSelector(
	selectCurrentGroupOrderCalculatedShippingOptions,
	selectOrderCalculatedShippingOptionMap,
	selectCurrentSeriesOrderPreviousShippingTitle,
	(options, shippingOptionMap, previousShippingTitle) => {
		const bestMatchShippingMethod = find(options, (option) => ShippingOption.getCalculatedShippingOptionTitle(option, shippingOptionMap) === previousShippingTitle);
		const recommendedShippingMethod = find(options, (option) => ShippingOption.getIsRecommended(option));
		const firstShippingMethod = first(options);

		const newShippingMethod = bestMatchShippingMethod || recommendedShippingMethod || firstShippingMethod;

		return deep(newShippingMethod, 'id');
	}
);

export const selectCurrentGroupSubscriptionsBestMatchProductId = createSelector(
	selectCurrentGroupSubscriptionsProducts,
	selectCurrentSeriesOrderProduct,
	selectCurrentGroupSubscriptionsDefaultProductId,
	(products, currentProduct, defaultProductId) => {
		const bestMatch = find(products, (product) => product.variableShippingDescription === currentProduct.variableShippingDescription);

		return deep(bestMatch, 'id') || defaultProductId;
	}
);

const selectShippingGradeMap = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.SHIPPING_GRADE}`) || {}
);

const selectShippingOptionMap = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.SHIPPING_OPTION}`) || {}
);

const selectShippingOptionIds = createSelector(
	selectShippingOptionsResponse,
	(shippingOptionsResponse) => deep(shippingOptionsResponse, `options`) || []
);

export const selectShippingOptionsCallToAction = createSelector(
	selectShippingOptionsResponse,
	(shippingOptionsResponse) => deep(shippingOptionsResponse, 'callToAction')
);

const selectShippingMessageMap = createSelector(
	selectCheckoutState,
	(checkout) => deep(checkout, `${ENTITIES.SHIPPING_MESSAGE}`) || {}
);

const selectShippingMessageIds = createSelector(
	selectShippingOptionsResponse,
	(shippingOptionsResponse) => deep(shippingOptionsResponse, `messages`) || []
);

export const selectShippingMessages = createSelector(
	selectShippingMessageIds,
	selectShippingMessageMap,
	(shippingMessageIds, shippingMessageMap) => shippingMessageIds.map((id) => deep(shippingMessageMap, `${id}`))
);

// ----- checkout.cart -----
export const selectIsCartLoaded = createSelector(
	selectCartId,
	(cartId) => !!cartId
);

export const selectCartCoupon = createSelector(
	selectCart,
	(cart) => deep(cart, 'couponCode') || {}
);

export const selectCartGiftNote = createSelector(
	selectCart,
	(cart) => deep(cart, 'giftNote')
);

export const selectCartItemIds = createSelector(
	selectCart,
	(cart) => deep(cart, 'items') || []
);

export const selectCartItems = createSelector(
	selectCartItemIds,
	selectCartItemMap,
	(itemIds, cartItemsById) => itemIds.map((itemId) => deep(cartItemsById, `${itemId}`))
);

export const selectIsGroupIdInCartFunc = createSelector(
	selectCartItems,
	(cartItems) => (groupId) => cartItems.findIndex((item) => String(groupId) === String(item.groupId)) !== -1
);

const selectCartItemProducts = createSelector(
	selectCartItems,
	selectProductMap,
	(cartItems, productMap) => cartItems.map((cartItem) => productMap[cartItem.product])
);

export const selectCartItemsAreShippable = createSelector(
	selectCartItemProducts,
	(cartItemProducts) => some(cartItemProducts, (cartItemProduct) => cartItemProduct.isShippable) || false
);

export const selectCartItemsSupportGiftNotes = createSelector(
	selectCartItemProducts,
	(cartItemProducts) => some(cartItemProducts, (cartItemProduct) => cartItemProduct.supportsGiftNotes) || false
);

export const selectCartItemsArePurchasableWithCredit = createSelector(
	selectCartItems,
	(cartItems) => some(cartItems, (cartItem) => !cartItem.gift)
);

export const selectCartPrice = createSelector(
	selectCart,
	(cart) => deep(cart, 'price') || {}
);

const selectCartShippingAddressId = createSelector(
	selectCart,
	(cart) => deep(cart, 'shippingAddress')
);

export const selectCartHasShippingAddress = createSelector(
	selectCartShippingAddressId,
	(addressId) => !!addressId
);

const selectCartPaymentMethodId = createSelector(
	selectCart,
	(cart) => deep(cart, 'paymentMethod')
);

const selectCartHasPaymentMethod = createSelector(
	selectCartPaymentMethodId,
	(paymentMethodId) => !!paymentMethodId
);

export const selectCartShippingGradeId = createSelector(
	selectCart,
	(cart) => deep(cart, 'shippingGrade')
);

const selectCartHasShippingGrade = createSelector(
	selectCartShippingGradeId,
	(shippingGradeId) => !!shippingGradeId
);

// ----- checkout.cart.couponCode -----
export const selectCartCouponCodeName = createSelector(
	selectCartCoupon,
	(couponCode) => couponCode.code
);

export const selectCartHasActiveCoupon = createSelector(
	selectCartCoupon,
	(couponCode) => !!couponCode.id
);

// ----- checkout.cart.items -----
export const selectCartItemCount = createSelector(
	selectCartItems,
	(items) => items.length || 0
);

// ----- checkout.cart.price -----
const selectCartTotalDiscount = createSelector(
	selectCartPrice,
	(price) => deep(price, 'totalDiscount')
);

export const selectCartSubtotal = createSelector(
	selectCartPrice,
	(price) => deep(price, 'subtotal')
);

export const selectCartTotal = createSelector(
	selectCartPrice,
	(price) => deep(price, 'total')
);

export const selectCartCreditApplied = createSelector(
	selectCartPrice,
	(price) => deep(price, 'creditApplied')
);

export const selectCartMarket = createSelector(
	selectCartPrice,
	(price) => deep(price, 'market')
);

export const selectCartHasCreditApplied = createSelector(
	selectCartCreditApplied,
	(creditApplied) => isNumber(creditApplied) && creditApplied < 0
);

export const selectCartCalculatedShippingOptions = createSelector(
	selectCartPrice,
	(price) => deep(price, 'calculatedShippingOptions')
);

export const selectCartShippingSubtotal = createSelector(
	selectCartPrice,
	(price) => deep(price, 'shipping')
);

export const selectCartTax = createSelector(
	selectCartPrice,
	(price) => deep(price, 'tax')
);

export const selectCartRetailDeliveryFee = createSelector(
	selectCartPrice,
	(price) => deep(price, 'retailDeliveryFee')
);

// ----- checkout.cart.price.market

export const selectCartCurrency = createSelector(
	selectCartMarket,
	(market) => deep(market, 'currency')
);

export const selectCartCurrencyCode = createSelector(
	selectCartCurrency,
	(currency) => deep(currency, 'code')
);

// ----- checkout.cart.price.calculatedShippingOptions
export const selectCartCalculatedShippingPrice = createSelector(
	selectCartCalculatedShippingOptions,
	(calculatedShippingOptions) => deep(calculatedShippingOptions, 'price')
);

export const selectCartCalculatedShippingPriceText = createSelector(
	selectCartCalculatedShippingOptions,
	(calculatedShippingOptions) => deep(calculatedShippingOptions, 'shippingText')
);


// ----- checkout.cart.price.totalDiscount
export const selectCartTotalDiscountShipping = createSelector(
	selectCartTotalDiscount,
	(totalDiscount) => deep(totalDiscount, 'shipping')
);

export const selectCartTotalDiscountSubtotal = createSelector(
	selectCartTotalDiscount,
	(totalDiscount) => deep(totalDiscount, 'subtotal')
);

export const selectCartHasTotalDiscountSubtotal = createSelector(
	selectCartTotalDiscountSubtotal,
	(totalDiscountSubtotal) => isNumber(totalDiscountSubtotal) && totalDiscountSubtotal < 0
);

// ----- shipping price -----
export const selectCartShippingTotal = createSelector(
	selectCartShippingSubtotal,
	selectCartTotalDiscountShipping,
	(subtotal, discount) => !!discount ? subtotal + discount : subtotal
);

// ----- checkout.couponTestStatus -----
export const selectIsCouponTestErrored = createSelector(
	selectCouponTestStatus,
	(status) => status === LOAD_STATUS.ERROR
);

// ----- checkout.giftNoteFormatted -----
export const selectGiftNoteFormattedLines = createSelector(
	selectGiftNoteFormatted,
	(formatted) => deep(formatted, 'lines') || []
);

export const selectGiftNoteLineCount = createSelector(
	selectGiftNoteFormattedLines,
	(lines) => lines ? lines.length : 0
);

// ----- checkout.giftNoteLayout -----
export const selectGiftNoteMaxLineCount = createSelector(
	selectGiftNoteLayout,
	(layout) => deep(layout, 'maxLines') || 0
);

// ----- checkout.orderOption -----
const selectOrderOptions = createSelector(
	selectOrderOptionsMap,
	(selectOrderOptionsMap) => deep(selectOrderOptionsMap, 'orderOptions') || {}
);

export const selectOrderOptionsIsDefaultHardCover = createSelector(
	selectOrderOptions,
	(orderOptions) => deep(orderOptions, 'defaultHardcover') || false
);

const selectOrderOptionsOrderedCountryIds = createSelector(
	selectOrderOptions,
	(orderOptions) => deep(orderOptions, 'countries') || []
);

// ----- appCookie.checkoutSource
export const selectCheckoutSourceType = createSelector(
	selectCheckoutSourceCookie,
	(checkoutSourceCookie) => deep(checkoutSourceCookie, 'checkoutSource')
);

// ----- Composite -----
export const selectCartHeaderIconData = createSelector(
	selectCartItemCount,
	(itemCount) => ({
		itemCount,
	})
);

export const selectCartItemToRemove = createSelector(
	selectCartItemIdToRemove,
	selectCartItemMap,
	(itemId, cartItemsById) => deep(cartItemsById, `${itemId}`)
);

export const selectCartItemToRemoveTitle = createSelector(
	selectCartItemToRemove,
	selectGroupMap,
	(cartItemToRemove, groups) => getCartItemTitle(cartItemToRemove, groups)
);

export const selectCartItemToRemoveDeleteAppString = createSelector(
	selectCartItemToRemove,
	selectGroupMap,
	(cartItemToRemove, groups) => getCartItemRemoveAppString(cartItemToRemove, groups)
);

export const selectCountries = createSelector(
	selectCountryMap,
	selectOrderOptionsOrderedCountryIds,
	(countryMap, countryIdsInOrder) => countryIdsInOrder.map((countryId) => countryMap[countryId])
);

export const selectHasCartItemToRemove = createSelector(
	selectCartItemToRemove,
	(cartItemToRemove) => !!cartItemToRemove
);

const selectCartTotalIsZero = createSelector(
	selectCartTotal,
	(cartTotal) => cartTotal === 0
);

export const selectHasRequiredPayment = createSelector(
	selectHasPaymentMethod,
	selectCartTotalIsZero,
	(hasPaymentMethod, cartTotalIsZero) => hasPaymentMethod || cartTotalIsZero
);

export const selectHasRequiredCheckoutInfo = createSelector(
	selectHasAddress,
	selectCartItemsAreShippable,
	selectHasRequiredPayment,
	(hasAddress, cartItemsAreShippable, hasRequiredPayment) => (hasAddress || !cartItemsAreShippable) && hasRequiredPayment
);

export const selectIsCartInitializing = createSelector(
	selectIsStateInitializingFunc,
	(isStateInitalizingFunc) => isStateInitalizingFunc(LOADING_KEYS.LOAD_SHOPPING_CART)
);

export const selectIsCartCheckoutAccessible = createSelector(
	selectCartItemCount,
	selectIsCartLoaded,
	selectIsCartInitializing,
	(cartItemCount, isCartLoaded, isCartInitializing) => isCartInitializing || (isCartLoaded && cartItemCount > 0)
);

export const selectIsCheckoutAccessible = createSelector(
	selectCheckoutSourceType,
	selectIsCartCheckoutAccessible,
	(checkoutType, isCartCheckoutAccessible) => {
		if (checkoutType === CHECKOUT_TYPES.CART) return isCartCheckoutAccessible;

		return false;
	}
);

export const selectCurrentCheckoutSteps = createSelector(
	selectCheckoutSourceType,
	selectCartItemsAreShippable,
	(checkoutType, cartItemsAreShippable) => {
		const steps = (checkoutType === CHECKOUT_TYPES.CART) ? CART_CHECKOUT_STEPS : [];
		const maybeFilterShippingSteps = (step) => cartItemsAreShippable || !CHECKOUT_SHIPPING_STEPS.has(step);

		return steps.filter(maybeFilterShippingSteps);
	}
);

export const selectCurrentCheckoutSelectionSteps = createSelector(
	selectCurrentCheckoutSteps,
	(steps) => filter(steps, (step) => SELECTION_CHECKOUT_STEPS.has(step))
);

const selectCurrentBreadcrumbableSteps = createSelector(
	selectCurrentCheckoutSteps,
	(steps) => filter(steps, (step) => BREADCRUMBABLE_CHECKOUT_STEPS.has(step))
);

const selectCheckoutHasShippingAddress = createSelector(
	selectCheckoutSourceType,
	selectCartHasShippingAddress,
	(checkoutType, cartHasShippingaddress) => (checkoutType === CHECKOUT_TYPES.CART) ? cartHasShippingaddress : false
);

const selectCheckoutHasShippingMethod = createSelector(
	selectCheckoutSourceType,
	selectCartHasShippingGrade,
	(checkoutType, cartHasShippingGrade) => (checkoutType === CHECKOUT_TYPES.CART) ? cartHasShippingGrade : false
);

const selectCheckoutHasPaymentMethod = createSelector(
	selectCheckoutSourceType,
	selectCartHasPaymentMethod,
	(checkoutType, cartHasPaymentMethod) => (checkoutType === CHECKOUT_TYPES.CART) ? cartHasPaymentMethod : false
);

const selectCheckoutHasSelectionMap = createSelector(
	selectCheckoutHasShippingAddress,
	selectCheckoutHasShippingMethod,
	selectCheckoutHasPaymentMethod,
	(hasAddress, hasShipping, hasPayment) => ({
		[CHECKOUT_STEPS.SHIPPING_ADDRESS]: hasAddress,
		[CHECKOUT_STEPS.SHIPPING_METHOD]: hasShipping,
		[CHECKOUT_STEPS.PAYMENT_METHOD]: hasPayment,
	})
);

export const selectCheckoutBreadcrumbs = createSelector(
	selectCurrentBreadcrumbableSteps,
	selectCheckoutHasSelectionMap,
	(breadcrumbSteps, hasSelectionMap) => {

		const reduced = breadcrumbSteps.reduce((soFar, step) => ({
			breadcrumbs: [
				...soFar.breadcrumbs,
				{
					appString: CHECKOUT_STEP_APP_STRING_MAP[step],
					hasSelection: (hasSelectionMap[step] || !hasSelectionMap.hasOwnProperty(step)) && soFar.previousSteps
						.filter((step) => SELECTION_CHECKOUT_STEPS.has(step))
						.reduce((hasSelection, previousStep) => {
							return hasSelection && hasSelectionMap[previousStep];
						}, true),
					location: CHECKOUT_STEP_LOCATION_MAP[step],
					step,
				}
			],
			previousSteps: [...soFar.previousSteps, step],
		}), {
			breadcrumbs: [],
			previousSteps: [],
		});

		return reduced.breadcrumbs;
	}
);

// ----- Previous Selection Steps -----
const selectPreviousSelectionStepsFunc = createSelector(
	selectCurrentCheckoutSelectionSteps,
	(steps) => (currentStep) => takeWhile(steps, (step) => step !== currentStep)
);

export const selectShippingMethodPreviousSelectionSteps = createSelector(
	selectPreviousSelectionStepsFunc,
	(previousStepsFunc) => previousStepsFunc(CHECKOUT_STEPS.SHIPPING_METHOD)
);

export const selectPaymentMethodPreviousSelectionSteps = createSelector(
	selectPreviousSelectionStepsFunc,
	(previousStepsFunc) => previousStepsFunc(CHECKOUT_STEPS.PAYMENT_METHOD)
);

export const selectReviewPreviousSelectionSteps = createSelector(
	selectPreviousSelectionStepsFunc,
	(previousStepsFunc) => previousStepsFunc(CHECKOUT_STEPS.REVIEW)
);
// ----- Previous Steps -----

// ----- Next Step -----
const selectNextCheckoutStepMap = createSelector(
	selectCurrentCheckoutSteps,
	(checkoutSteps) => convertToLimitedNextStepMap(checkoutSteps, CART_CHECKOUT_STEPS)
);

const selectCurrentCheckoutNextStepMap = createSelector(
	selectCheckoutSourceType,
	selectNextCheckoutStepMap,
	(checkoutType, nextStepMap) => (checkoutType === CHECKOUT_TYPES.CART) ? nextStepMap : {}
);

const selectNextCheckoutStepLocationFunc = createSelector(
	selectCurrentCheckoutNextStepMap,
	(nextStepMap) => (currentStep) => deep(CHECKOUT_STEP_LOCATION_MAP, deep(nextStepMap, currentStep))
);

export const selectCartNextStepLocation = createSelector(
	selectNextCheckoutStepLocationFunc,
	(nextStepLocationFunc) => nextStepLocationFunc(CHECKOUT_STEPS.CART)
);

export const selectShippingAddressNextStepLocation = createSelector(
	selectNextCheckoutStepLocationFunc,
	(nextStepLocationFunc) => nextStepLocationFunc(CHECKOUT_STEPS.SHIPPING_ADDRESS)
);

export const selectShippingMethodNextStepLocation = createSelector(
	selectNextCheckoutStepLocationFunc,
	(nextStepLocationFunc) => nextStepLocationFunc(CHECKOUT_STEPS.SHIPPING_METHOD)
);

export const selectPaymentMethodNextStepLocation = createSelector(
	selectNextCheckoutStepLocationFunc,
	(nextStepLocationFunc) => nextStepLocationFunc(CHECKOUT_STEPS.PAYMENT_METHOD)
);

export const selectOrderReviewNextStepLocation = createSelector(
	selectNextCheckoutStepLocationFunc,
	(nextStepLocationFunc) => nextStepLocationFunc(CHECKOUT_STEPS.REVIEW)
);
// ----- Next Step -----

export const selectDefaultCheckoutAddressId = createSelector(
	selectDefaultAddressId,
	selectCartShippingAddressId,
	(userDefaultId, cartId) => cartId || userDefaultId
);

export const selectCartPaymentMethodIdOrFirstPaymentMethodId = createSelector(
	selectCartPaymentMethodId,
	selectFirstPaymentMethod,
	(cartPaymentMethodId, firstPaymentMethod) => cartPaymentMethodId || deep(firstPaymentMethod, 'id')
);

export const selectInitialCheckoutPaymentMethodId = createSelector(
	selectCartPaymentMethodIdOrFirstPaymentMethodId,
	selectCartTotalIsZero,
	(cartOrFirstPaymentMethodId, cartTotalIsZero) => {
		if (cartTotalIsZero) return CHOOSE_PAYMENT_OPTIONS.USER_CREDIT;

		return cartOrFirstPaymentMethodId || NEW_PAYMENT_OPTION.NEW_CREDIT_CARD;
	}
);

export const selectIsNewUserAddressFormBeingHandled = createSelector(
	selectIsStateLoadingFunc,
	selectIsLoadSuccessfulFunc,
	(isStateLoading, isLoadSuccessful) => isStateLoading(LOADING_KEYS.SUBMIT_NEW_USER_ADDRESS_FORM) || isLoadSuccessful(LOADING_KEYS.SUBMIT_NEW_USER_ADDRESS_FORM)
);

export const selectCartShippingAddress = createSelector(
	selectCartShippingAddressId,
	selectAddressMap,
	(addressId, addressMap) => addressMap[addressId]
);

export const selectCartShippingAddressIsInternational = createSelector(
	selectCartShippingAddress,
	(address) => deep(address, 'countryCode') !== COUNTRY_CODES.US
);

const selectShippingMethodMap = createSelector(
	selectShippingGradeMap,
	selectShippingOptionMap,
	(gradeMap, optionMap) => {
		// TODO - remove unused selectors

		return optionMap;
	}
);

export const selectShippingMethods = createSelector(
	selectShippingMethodMap,
	selectShippingOptionIds,
	(methodMap, optionIds) => optionIds.map((optionId) => ({ ...methodMap[optionId] }))
);

export const selectNextStepAppStringFunc = createSelector(
	selectCheckoutSourceType,
	selectNextCheckoutStepMap,
	(checkoutType, nextStepMap) => {
		if (checkoutType === CHECKOUT_TYPES.CART) {
			return (step) => checkoutContinueButtonAppString(step, nextStepMap, CART_CHECKOUT_CONTINUE_TEXT_MAP);
		}

		return () => { };
	}
);

export const selectCartShippingGrade = createSelector(
	selectCartShippingGradeId,
	selectShippingMethodMap,
	(cartShippingGradeId, shippingMethodMap) => shippingMethodMap[cartShippingGradeId]
);

export const selectCartPaymentMethod = createSelector(
	selectCartPaymentMethodId,
	selectPaymentMethodMap,
	(cartPaymentMethodId, paymentMethodMap) => paymentMethodMap[cartPaymentMethodId]
);

export const selectRedeemGiftCodeErrorReason = createSelector(
	selectLoadingErrorMap,
	(errorMap) => deep(errorMap, `${LOADING_KEYS.REDEEM_GIFT_CARD}.data.error`)
);

export const selectCheckoutErrorMessages = createSelector(
	selectLoadingErrorMap,
	(errorMap) => deep(errorMap, `${LOADING_KEYS.CHECKOUT_SHOPPING_CART}.presentation.errors`)
);

export const selectCheckoutErrorsByCartItemId = createSelector(
	selectLoadingErrorMap,
	(errorMap) => {
		return (deep(errorMap, `${LOADING_KEYS.CHECKOUT_SHOPPING_CART}.presentation.itemized`) || [])
			.reduce((cartItemErrorsByCartItemId, cartItemErrors) => ({
				...cartItemErrorsByCartItemId,
				[cartItemErrors.itemId]: cartItemErrors,
			}), {});
	}
);

export const selectCartPriceAfterShippingMethodChangeCookieKey = createSelector(
	selectCartId,
	(cartId) => `${cartId}-cartPriceAfterShippingMethodSaved`
);

export const selectIsCartPriceDifferentSinceShippingMethodChange = createSelector(
	selectCartPriceAfterShippingMethodChangeCookieKey,
	selectAppCookiesState,
	selectCartSubtotal,
	(cookieKey, cookieState, cartSubtotal) => cookieState[cookieKey] !== cartSubtotal
);

export const selectIsShippingMethodSaving = createSelector(
	selectIsStateLoadingFunc,
	(isStateLoading) => isStateLoading(LOADING_KEYS.SAVE_SHIPPING_METHOD)
);

export const selectMaybeFasterCheaperGrade = createSelector(
	selectShippingMethods,
	selectIsCartPriceDifferentSinceShippingMethodChange,
	selectCartShippingGradeId,
	selectIsShippingMethodSaving,
	selectCartHasActiveCoupon,
	(methods, cartTotalDifferentSinceShippingMethodChange, cartShippingGrade, isSaving, cartHasCoupon) => {
		const cheapest = (a, b) => a.cost - b.cost;
		const fastest = (a, b) => b.estimatedArrivalDate - a.estimatedArrivalDate;

		const validMethods = methods.filter((method) => !method.disabled);
		const sortedMethods = validMethods.sort(fastest).sort(cheapest);

		const cheapestFastestMethod = firstItemInArray(sortedMethods);
		const cheapestFastestGradeId = deep(cheapestFastestMethod, 'shippingGrade');

		const firstMethod = firstItemInArray(validMethods);
		const firstGradeId = deep(firstMethod, 'shippingGrade');

		const defaultGradeId = sortedMethods.find((shippingOption) => deep(shippingOption, 'shippingGrade') === cartShippingGrade)
			? cartShippingGrade
			: firstGradeId;

		return (cartTotalDifferentSinceShippingMethodChange && !isSaving && cartHasCoupon)
			? cheapestFastestGradeId
			: defaultGradeId;
	}
);
