import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { countryFromJson, countrySchema } from './country.model';
import { ENTITIES } from '../const/entities/entities';

export const orderOptionsFromJson = (json = {}) => ({
	countries: (deep(json, 'Countries') || []).map((countryJson) => countryFromJson(countryJson)),
	defaultHardcover: deep(json, 'DefaultHardcover'),
});

export const normalizeOrderOptions = (orderOptions) => {
	return normalize(orderOptions, orderOptionsSchema);
};

const orderOptionsSchema = new schema.Entity(ENTITIES.ORDER_OPTION, {
	countries: [countrySchema]
}, {
	idAttribute: () => 'orderOptions'
});