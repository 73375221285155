import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { callToActionFromJson } from './callToAction.model';
import { shippingMessagesFromJson, shippingMessagesSchema } from './shippingMessage.model';
import { shippingOptionsPresentationFromJson, shippingOptionsPresentationSchema } from './shippingOptionPresentation.model';
import { ENTITIES } from '../const/entities/entities';

export const shippingOptionsViewResponseFromJson = (json = {}) => ({
	id: ENTITIES.SHIPPING_OPTION_VIEW_RESPONSE,
	callToAction: callToActionFromJson(deep(json, 'CallToAction')),
	messages: shippingMessagesFromJson(deep(json, 'Messages')),
	options: shippingOptionsPresentationFromJson(deep(json, 'Options')),
});

const shippingOptionsViewResponseSchema = new schema.Entity(ENTITIES.SHIPPING_OPTION_VIEW_RESPONSE, {
	messages: shippingMessagesSchema,
	options: shippingOptionsPresentationSchema,
});

export const normalizeShippingOptionsViewResponse = (shippingOptionsViewResponse) => normalize(shippingOptionsViewResponse, shippingOptionsViewResponseSchema);

export const transformAndNormalizeShippingOptionsViewResponse = (json) => {
	return normalizeShippingOptionsViewResponse(shippingOptionsViewResponseFromJson(json));
};