import { apiGetData } from './api.http.actions'
import { apiSettingsFromJson } from '../../model/apiSettings.model';

export const getApiSettings = () => (dispatch) => {
	return dispatch(apiGetData('/settings/api'))
		.then(apiSettingsFromJson);
};

export const getOrderOptions = () => (dispatch) => {
	return dispatch(apiGetData('/settings/orderOptions'))
};