import React from 'react';
import PropTypes from 'prop-types';

import './Titles.css';

const Titles = ({
	description,
	title,
}) => (
	<div className="instagram-quickflow-page-titles">
		<p className="instagram-quickflow-page-titles-description">{description}</p>
		<h1>{title}</h1>
	</div>
);

Titles.propTypes = {
	description: PropTypes.string,
	title: PropTypes.string,
};

export default Titles;
