import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconPlus = (props) => (
	<Svg
		{...props}
		name="plus"
		viewBox="0 0 8 8"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-915.000000, -625.000000)" fill="inherit">
				<path d="M918,628 L918,625 L920,625 L920,628 L923,628 L923,630 L920,630 L920,633 L918,633 L918,630 L915,630 L915,628 L918,628 Z" />
			</g>
		</g>
	</Svg>
);

IconPlus.displayName = 'IconPlus';

IconPlus.propTypes = iconProps;

IconPlus.defaultProps = defaultIconProps;

export default IconPlus;
