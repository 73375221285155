import React from 'react';
import PropTypes from 'prop-types';
import defaultIconProps from '../_defaultIconProps';
import iconProps from '../_iconProps';
import Svg from '../Svg';

export const IconCardBackLandscapeBlank = (props) => {
	const { size } = props;
	const heightRatio = 100;
	const widthRatio = 140;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="IconCardBackLandscapeBlank"
			width={width}
			height={height}
			viewBox="0 0 140 100"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
		        <rect fill={props.color} x="0" y="0" width="140" height="100" />
		    </g>
		</Svg>
	);
};

IconCardBackLandscapeBlank.displayName = 'IconCardBackLandscapeBlank';

IconCardBackLandscapeBlank.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconCardBackLandscapeBlank.defaultProps = {
	...defaultIconProps,
	color: '#ffffff',
	color2: '#d8d8d8',
};

export default IconCardBackLandscapeBlank;
