import config from '../helpers/config';

if (!process.env.SERVER && config.get('analytics.blueshift.enabled')) {
	window._blueshiftid = '578d93e56f5aabc0a3a7fb50f86badd7';
	window.blueshift = window.blueshift || [];
	if (window.blueshift.constructor === Array) {
		window.blueshift.load = function() {
			var d = function(a) {
					return function() {
						window.blueshift.push([a].concat(Array.prototype.slice.call(arguments, 0)));
					};
				},
				e = ['identify', 'track', 'click', 'pageload', 'capture', 'retarget'];
			for (var f = 0; f < e.length; f++) window.blueshift[e[f]] = d(e[f]);
		};
	}
	window.blueshift.load();
	window.blueshift.pageload();
	if (window.blueshift.constructor === Array) {
		/* eslint-disable */
		(function() {
			var b = document.createElement('script');
			(b.type = 'text/javascript'),
				(b.async = !0),
				(b.src =
					('https:' === document.location.protocol ? 'https:' : 'http:') + '//cdn.getblueshift.com/blueshift.js');
			var c = document.getElementsByTagName('script')[0];
			c.parentNode.insertBefore(b, c);
		})();
		/* eslint-enable */
	}
}
