import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';
import { ENTITIES } from '../const/entities/entities';

export const coverCropFromJson = (groupId, mediaId, json = {}) => ({
	id: `group(${groupId}):media(${mediaId})`,
	lowDpiWarning: deep(json, 'LowDpiWarning'),
});

export const coverCropSchema = new schema.Entity(ENTITIES.COVER_CROP);

export const normalizeCoverCrop = (coverCrop) => normalize(coverCrop, coverCropSchema);
