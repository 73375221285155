import { useMemo } from 'react';
import deep from 'deep-get-set';

import { reactCookies } from './reactCookies';
import { useCookiesApi } from './useCookiesApi';
import { wrapCookie } from './wrapCookie';

// export interface AppCookies {
//   amplitudeDeviceId?: string; // AMPLITUDE DEVICE ID TO ALLOW CROSS DOMAIN TRACKING
//   appStringOverride?: { [key: string]: string; }; // APP_STRING_OVERRIDE
//   appVisited?: boolean; // APP_VISITED
//   blogVisited?: boolean; // BLOG_VISITED
//   bookUpgradeModalDismissed?: boolean; // BOOK_UPGRADE_MODAL_DISMISSED
//   cartPriceAfterShippingMethodSaved?: number;
//   cbUUID?: string; // UUID
//   checkoutSource?: string; // CHECKOUT_SOURCE
//   devToolsOverlay?: boolean; // DEV_TOOL_OVERLAY // DEPRECATED
//   env?: string;
//   gdprAccepted?: boolean; // GDPR_ACCEPTED
//   gdprDisplayed?: boolean; // GDPR_DISPLAYED
//   infiniteOrigin?: string;
//   initialized?: boolean;
//   lcid?: string; // VISITING_USER_LANGUAGE
//   madeToSuffer?: boolean; // MADE_TO_SUFFER
//   makeItRainOrigin?: string;
//   marketingVisited?: boolean; // MARKETING_VISITED
//   printedBooks?: boolean; // PRINTED_BOOKS
//   sas?: boolean; // Has ShareASale (sas) already triggered for user?
//   shipOfTheseusOrigin?: string;
//   tempCard?: { id: string; dirty: boolean; }; // TEMP_CARD
// }

export function appCookie(cookiesApi = reactCookies()) {
  const appCookiesWrapper = wrapCookie(cookiesApi, '__cb_app');

  return {
    ...appCookiesWrapper,
    set: (updatedAppCookieValues) => {
      appCookiesWrapper.set({
        ...appCookiesWrapper.get(),
        ...updatedAppCookieValues,
      });
    },
  };
}

export function useAppCookie() {
  const cookiesApi = useCookiesApi();

  return appCookie(cookiesApi);
}

/**
 * Returns the amplitudeDeviceId from the app cookie and a function to set it.
 *
 * @returns {object} { value, set }
 */
export function useAmplitudeDeviceIdCookie() {
  const appCookie = useAppCookie();

  return useMemo(() => ({
    value: deep(appCookie, 'value.amplitudeDeviceId'),
    set: (amplitudeDeviceId) => {
      appCookie.set({
        ...appCookie.get(),
        amplitudeDeviceId
      });
    },
  }), [appCookie]);
}
