import React from 'react';
import PropTypes from 'prop-types';

import './AccordianIcon.css';

const AccordianIcon = ({ open }) => <i className={`accordion-icon fa ${open ? 'fa-minus' : 'fa-plus'}`} />;

AccordianIcon.propTypes = {
	open: PropTypes.bool
};

AccordianIcon.defaultProps = {
	open: false
};

export default AccordianIcon;
