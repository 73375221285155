import PropTypes from 'prop-types';

/**
 * Creates a addressSelectionModalModel data object.
 *
 * @param {object} addressSelectionModalModel The addressSelectionModalModel object.
 * @param {number} addressSelectionModalModel.addressId The ID of the address to edit if not new (Optional)
 * @param {func} addressSelectionModalModel.doneCallback A callback when an address has been selected
 * @param {func} addressSelectionModalModel.cancelCallback A callback when no address has been selected
 * @param {func} addressSelectionModalModel.onAddressChangeEvent A callback to trigger an event
 * @param {func} addressSelectionModalModel.onAddressEditEvent A callback to trigger an event
 * @param {func} addressSelectionModalModel.onAddressNewEvent A callback to trigger an event
 * @param {func} addressSelectionModalModel.onAddressSubmitEvent A callback to trigger an event
 */
export const addressSelectionModalModel = ({
	addressId,
	cancelCallback,
	doneCallback,
	onAddressChangeEvent,
	onAddressEditEvent,
	onAddressNewEvent,
	onAddressSubmitEvent,
}) => ({
	addressId,
	cancelCallback,
	doneCallback,
	onAddressChangeEvent,
	onAddressEditEvent,
	onAddressNewEvent,
	onAddressSubmitEvent,
});

export const addressSelectiongModalPropType = PropTypes.shape({
	addressId: PropTypes.string,
	cancelCallback: PropTypes.func,
	doneCallback: PropTypes.func.isRequired,
	onAddressChangeEvent: PropTypes.func,
	onAddressEditEvent: PropTypes.func,
	onAddressNewEvent: PropTypes.func,
	onAddressSubmitEvent: PropTypes.func,
});
