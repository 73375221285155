import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const groupInviteCodeModel = ({ groupId, link }) => ({
	groupId,
	link,
});

export const groupInviteCodeSchema = new schema.Entity(ENTITIES.GROUP_INVITE_CODE, {}, {
	idAttribute: 'groupId'
});

export const normalizeGroupInviteCode = (group) => normalize(group, groupInviteCodeSchema);
