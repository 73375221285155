import { schema } from 'normalizr';

import { makeGroupExcludedMomentsPageId } from './groupExcludedMomentsPage.model';
import { momentsFromJson, momentsSchema } from './moment.model';
import { ENTITIES } from '../const/entities/entities';

export const excludedMomentsPageFromJson = (groupId, index, pageSize, moments) => ({
	groupId,
	index,
	moments: momentsFromJson(moments),
	pageSize,
});

export const excludedMomentsPageSchema = new schema.Entity(ENTITIES.EXCLUDED_MOMENTS_PAGE, {
	moments: momentsSchema,
}, {
	idAttribute: (model) => makeGroupExcludedMomentsPageId(model.groupId, model.index),
});
