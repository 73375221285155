import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { REDUCERS } from '../const/reducers.const';
import { VIEW } from '../const/view.const';
import { arrayToKeyMap } from '../helpers/arrays/arrays';

const selectViewState = (state) => deep(state, `${REDUCERS.VIEW}`);

export const selectViewGroupId = createSelector(
	selectViewState,
	(view) => deep(view, 'groupId')
);

export const selectModalStack = createSelector(
	selectViewState,
	(view) => deep(view, 'modalStack') || []
);

export const selectOpenModals = createSelector(
	selectModalStack,
	(modalStack) => arrayToKeyMap(modalStack, 'id')
);

export const selectIsModalOpenFunc = createSelector(
	selectOpenModals,
	(openModals) => (modalId) => !!openModals[modalId]
);

export const selectViewSelectedMomentIds = createSelector(
	selectViewState,
	(view) => deep(view, 'momentIds') || []
);

export const selectActiveMomentId = createSelector(
	selectViewState,
	(view) => deep(view, `${VIEW.ACTIVE_MOMENT_ID}`)
);

export const selectHasActiveMomentId = createSelector(
	selectActiveMomentId,
	(activeMomentId) => !!activeMomentId
);

export const selectActiveCollageMediaId = createSelector(
	selectViewState,
	(view) => deep(view, 'activeCollageMediaId')
);

export const selectActiveCollageMediaIndex = createSelector(
	selectViewState,
	(view) => deep(view, 'activeCollageMediaIndex')
);
