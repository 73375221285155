import { SCROLL_DIRECTION } from '../../const';

/**
 * We need all children to be rendered so our container maintains a consistent height.
 * This logic places cards at the appropriate place at the beginning of the collection
 * for the animation to work as expected, then puts the remaining cards at the end.
 *
 * @param {object} children Elements to be sorted
 * @param {number} prevPage Page to scroll from
 * @param {number} currentPage Page to scroll to
 * @param {number} pageSize Number of elements per page/scroll
 * @param {string} direction Direction to scroll
 * @returns {object[]}
 */
export const sortPages = (children, prevPage, currentPage, pageSize, direction) => {
	const childArr = [...children];
	let page1 = [];
	let page2 = [];
	if (currentPage === prevPage) {
		page1 = childArr.splice(currentPage * pageSize, pageSize);
	} else if (currentPage > prevPage) {
		page1 = childArr.splice(prevPage * pageSize, pageSize);
		page2 = childArr.splice((currentPage - 1) * pageSize, pageSize);
		if (direction === SCROLL_DIRECTION.LEFT) {
			const tmp = page1;
			page1 = page2;
			page2 = tmp;
		}
	} else if (currentPage < prevPage) {
		page1 = childArr.splice(currentPage * pageSize, pageSize);
		page2 = childArr.splice((prevPage - 1) * pageSize, pageSize);
		if (direction === SCROLL_DIRECTION.RIGHT) {
			const tmp = page1;
			page1 = page2;
			page2 = tmp;
		}
	}

	return [...page1, ...page2, ...childArr];
};
