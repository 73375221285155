import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SocialCard from '../SocialCard/SocialCard';
import { Carousel, CarouselContainer, CarouselItem, CarouselPagination } from '../../components/Carousel';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import './SocialCarousel.css';

const SocialCarousel = ({
	duration,
	interval,
	isInset,
	paddingBottom,
	paddingTop,
	title: titleText,
	children: childrenProp
}) => {
	const children = React.Children.map(childrenProp, (child, index) => (
		<CarouselItem key={`SocialCarousel-CarouselItem-${index}`}>{child}</CarouselItem>
	));
	const title = titleText ? <h2>{titleText}</h2> : null;
	const className = classnames('SocialCarousel');

	return (
		<PageFlowContainer isInset={isInset}>
			<CarouselContainer className={className} paddingBottom={paddingBottom} paddingTop={paddingTop}>
				{title}
				<Carousel duration={duration} interval={interval} title={title}>
					{children}
				</Carousel>
				<CarouselPagination className="text-center" />
			</CarouselContainer>
		</PageFlowContainer>
	);
};

SocialCarousel.displayName = 'SocialCarousel';

SocialCarousel.propTypes = {
	children: (props, propName, componentName) => {
		const prop = props[propName];
		let error = null;
		React.Children.forEach(prop, (child) => {
			if (child.type !== SocialCard) {
				error = new Error(`'${componentName}' children should be of type 'SocialCard'`);
			}
		});

		return error;
	},
	duration: PropTypes.number,
	interval: PropTypes.number,
	isInset: PropTypes.bool,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	title: PropTypes.string
};

SocialCarousel.defaultProps = {
	duration: 500,
	interval: 8000,
	isInset: false
};

export default SocialCarousel;
