import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.BRANCH], action) => {
	const { data, type } = action;

	switch (type) {
		case ACTIONS.RECEIVE_BRANCH_DATA: {
			const { branchData, branchErr } = data;

			return {
				data: {
					...branchData
				},
				err: {
					...branchErr
				}
			}
		}
		default:
			return state;
	}
}
