export const getScrollIncrements = (containerWidth, scrollableContentWidth, numberOfItems) => {
	if (!containerWidth || !scrollableContentWidth || scrollableContentWidth <= containerWidth) {
		return [0];
	}

	const itemWidth = scrollableContentWidth / numberOfItems;
	const itemsPerView = Math.floor(containerWidth / itemWidth);
	const margin = (containerWidth - itemsPerView * itemWidth) / 2;
	const increments = [0];
	let increment = 0;
	while (true) {
		increment += increment === 0 ? itemsPerView * itemWidth - margin : itemsPerView * itemWidth;
		if (scrollableContentWidth - increment <= containerWidth) {
			increment = scrollableContentWidth - containerWidth;
			increments.push(increment);
			break;
		}
		increments.push(increment);
	}

	return increments;
};
