import React from 'react';
import PropTypes from 'prop-types';

import './Messaging.css';

const Messaging = ({
	text,
	type,
}) => (
	<div className={`messaging ${type}`}>
		<p>{text}</p>
	</div>
);

Messaging.displayName = 'Messaging';

Messaging.propTypes = {
	text: PropTypes.string,
	type: PropTypes.string,
};

export default Messaging;
