import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconPrints = (props) => (
	<Svg
		{...props}
		name="prints"
		viewBox="0 0 17 16"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-657.000000, -219.000000)" fill="inherit">
				<path
					d="M657.166667,218.9998 L657.166667,228.9998 L659.166667,228.9998 L659.166667,220.9998 L667.166667,220.9998 L667.166667,218.9998 L657.166667,218.9998 Z M662.166667,224 L670.166667,224 L670.166667,222 L660.166667,222 L660.166667,232 L662.166667,232 L662.166667,224 Z M663.166667,235 L673.166667,235 L673.166667,225 L663.166667,225 L663.166667,235 Z"
				/>
			</g>
		</g>
	</Svg>
);

IconPrints.displayName = 'IconPrints';

IconPrints.propTypes = iconProps;

IconPrints.defaultProps = defaultIconProps;

export default IconPrints;
