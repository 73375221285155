export const MODAL_NAMES = {
	ADD_APP_STRING_OVERRIDE: 'ADD_APP_STRING_OVERRIDE',
	ADD_PASSWORD_TO_SSO_USER_MODAL: 'ADD_PASSWORD_TO_SSO_USER_MODAL',
	ADDRESS_EDIT: 'ADDRESS_EDIT',
	ADDRESS_SELECTION: 'ADDRESS_SELECTION',
	APP_STRING_TRACKER_MODAL: 'APP_STRING_TRACKER_MODAL',
	BOOK_CHANGE_DEFAULT_TITLE_MODAL: 'BOOK_CHANGE_DEFAULT_TITLE_MODAL',
	CARD_ISSUES_MODAL: 'CARD_ISSUES_MODAL',
	CHANGE_CURRENCY: 'CHANGE_CURRENCY',
	CHANGE_CURRENCY_PROMPT: 'CHANGE_CURRENCY_PROMPT',
	CHANGE_TITLE_MODAL: 'CHANGE_TITLE_MODAL',
	CONFIRM_ORDER_COST: 'CONFIRM_ORDER_COST',
	CONFIRM: 'CONFIRM',
	DIALOG: 'DIALOG',
	EASTER_EGG: 'EASTER_EGG',
	GROUP_INCOMPLETE_SYNCING_ERROR_MODAL: 'GROUP_INCOMPLETE_SYNCING_ERROR_MODAL',
	INSTAGRAM_LEGACY_REAUTH: 'INSTAGRAM_LEGACY_REAUTH',
	LOGIN_MODAL: 'LOGIN_MODAL',
	NEW_CREDIT_CARD: 'NEW_CREDIT_CARD',
	NEW_SSO_USER_EXISTING_USER_CONFLICT_MODAL: 'NEW_SSO_USER_EXISTING_USER_CONFLICT_MODAL',
	NEW_USER_EXISTING_SSO_USER_CONFLICT_MODAL: 'NEW_USER_EXISTING_SSO_USER_CONFLICT_MODAL',
	PAYMENT_METHOD_SELECTION: 'PAYMENT_METHOD_SELECTION',
	SERVER_ERROR_MODAL: 'SERVER_ERROR_MODAL',
	SHARE_BOOK_MODAL: 'SHARE_BOOK_MODAL',
	SHIPPING_METHOD_SELECTION: 'SHIPPING_METHOD_SELECTION',
	TOAST: 'TOAST',
	UPGRADE_BOOK_INFO_MODAL: 'UPGRADE_BOOK_INFO_MODAL',
	UPGRADE_BOOK_MODAL: 'UPGRADE_BOOK_MODAL',
	UPGRADE_BOOK_NOW_MODAL: 'UPGRADE_BOOK_NOW_MODAL',
	UPGRADE_BOOK_SUCCESS_MODAL: 'UPGRADE_BOOK_SUCCESS_MODAL',
};
