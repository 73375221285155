import React from 'react';
import PropTypes from 'prop-types';
import { first } from 'lodash';
import styled from 'styled-components';

const CenteredImage = styled.img`
	margin: 0 auto;
`;

const ProductImagesCarousel = ({
	productImages,
}) => {
	const image = first(productImages);

	return (
		<CenteredImage src={image.url} alt={image.alt} />
	);
};

ProductImagesCarousel.displayName = 'ProductImagesCarousel';

ProductImagesCarousel.propTypes = {
	productImages: PropTypes.array,
};

export default ProductImagesCarousel;