import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { EVENT_QUEUES } from '../const/events/eventQueues.const';
import { REDUCERS } from '../const/reducers.const';

export const selectAnalyticsState = (state) => deep(state, `${REDUCERS.ANALYTICS}`);

export const selectQueuedCartEvents = createSelector(
	selectAnalyticsState,
	(analyticsState) => deep(analyticsState, `queues.${EVENT_QUEUES.CART}`) || []
);

export const selectQueuedUserEvents = createSelector(
	selectAnalyticsState,
	(analyticsState) => deep(analyticsState, `queues.${EVENT_QUEUES.USER}`) || []
);
