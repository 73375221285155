import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import MaybeLinkWrap from '../MaybeLinkWrap/MaybeLinkWrap';
import { CONFIGURABLE_LINK_TYPES } from '../../const';

const PressQuoteLink = styled.div`
	background-color: ${(props) => props.backgroundColor};
	display: grid;
	justify-items: center;
	padding: 50px 0;
	row-gap: 6px;
`;

const QuotationMarkSpan = styled.span`
	color: ${(props) => props.color};
	font-size: 50px;
	font-weight: 700;
	line-height: 63px;
	text-align: center;
`;

const QuotationLink = styled(MaybeLinkWrap)`
	color: ${(props) => props.color};
	font-size: 24px;
	font-weight: 600;
	letter-spacing: -0.25px;
	line-height: 32px;
	max-width: 750px;
	padding: 0 20px;
	text-align: center;

	text-decoration: none;
	text-transform: none;
	text-decoration-color: none;

	${PressQuoteLink}:hover & {
		color: ${(props) => props.color};
		cursor: ${(props) => props.path ? 'pointer' : undefined};
		text-transform: none;
	}
`;

const QuotationLogoImg = styled.img`
	margin: 20px 0;
	max-width: 150px;
`;

const PressQuote = ({
	backgroundColor,
	isInset,
	logoUrl,
	quotation,
	quotationColor,
	quotationMarkColor,
	quotationUrl,
}) => {
	return (
		<PageFlowContainer isInset={isInset}>
			<PressQuoteLink
				backgroundColor={backgroundColor}>
				<QuotationMarkSpan
					color={quotationMarkColor}>
					“
				</QuotationMarkSpan>
				<QuotationLink
					path={quotationUrl}
					linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK}
					color={quotationColor}>
					{quotation}
				</QuotationLink>
				{logoUrl && (
					<QuotationLogoImg
						src={logoUrl}
						alt="quotation attribution logo"
					/>
				)}
			</PressQuoteLink>
		</PageFlowContainer>
	);
};

PressQuote.displayName = 'PressQuote';

PressQuote.propTypes = {
	backgroundColor: PropTypes.string,
	logoUrl: PropTypes.string,
	isInset: PropTypes.bool,
	quotation: PropTypes.object,
	quotationColor: PropTypes.string,
	quotationMarkColor: PropTypes.string,
	quotationUrl: PropTypes.string,
};

PressQuote.defaultProps = {
	duration: 500,
	interval: 8000,
	isInset: false
};

export default PressQuote;
