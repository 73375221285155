
export const BRANCH_CHATBOOKS_SUBDOMAIN = 'https://chatbooks.app.link';

export const BRANCH_PARAMS = {
	CAMPAIGN: 'campaign',
	DEEP_VIEW_OVERRIDE: {
		ANDROID: '$android_deepview',
		IOS: '$ios_deepview',
	},
	FALLBACK_URL: '$fallback_url',
	LINK_TYPE: 'type',
	/**
	 * Small window of time that the branch link will work.
	 *
	 * This is important for security. If we don't have a small window,
	 * this increases the chances that users opening their app with similari
	 * Branch matching heuristics, same phone/model/ip address/os version/etc,
	 * then the matching phone will receive the branch data instead of the intended user.
	 */
	MATCH_DURATION: '$match_duration',
	URI_REDIRECT_MODE: '$uri_redirect_mode',
};

export const BRANCH_CAMPAIGNS = {
	INSTAGRAM_QUICK_FLOW: 'Instagram Quickflow',
};

/**
 * Branch has 3 redirect modes.
 *
 * https://docs.branch.io/links/integrate/#forced-redirections
 */
const BRANCH_REDIRECT_MODES = {
	STANDARD: 0,
	SMART: 1,
	FORCEFUL: 2, // Don't use this unless you absolutely know the Chatbooks app is installed.
};

const BRANCH_LINK_TYPES = {
	SINGLE_USE: 0,
	SAVE_AS_QUICK_LINK: 1, // Don't use this unless you want this to appear in Branch under Quick Links.
};

const SECONDS_IN_A_MINUTE = 60;

/**
 * Number of seconds for a branch link to match with a device that opens the Chatbooks app.
 *
 * Enough time to download the app should be given.
 */
const DEFAULT_MATCH_DURATION_IN_SECONDS = 5 * SECONDS_IN_A_MINUTE;

export const DEEP_VIEWS = {
	DEFAULT_DEEP_VIEW: 'branch_passive_default',
	IG_QUICKFLOW_DEEP_VIEW: 'chatbooks_deepview_ig_quickflow',
	IG_QUICKFLOW_DEEP_VIEW_2: 'chatbooks_deepview_ig_quickflow_2',
	QUICKFLOW_DEEP_VIEW: 'chatbooks_deepview_quickflow',
};

export const DEFAULT_BRANCH_LINK_DATA = {
	[BRANCH_PARAMS.DEEP_VIEW_OVERRIDE.ANDROID]: DEEP_VIEWS.DEFAULT_DEEP_VIEW,
	[BRANCH_PARAMS.DEEP_VIEW_OVERRIDE.IOS]: DEEP_VIEWS.DEFAULT_DEEP_VIEW,
	[BRANCH_PARAMS.LINK_TYPE]: BRANCH_LINK_TYPES.SINGLE_USE,
	[BRANCH_PARAMS.MATCH_DURATION]: DEFAULT_MATCH_DURATION_IN_SECONDS,
	[BRANCH_PARAMS.URI_REDIRECT_MODE]: BRANCH_REDIRECT_MODES.SMART,
};
