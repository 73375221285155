import deep from 'deep-get-set';
import jwtDecode from 'jwt-decode';

export const loginForSso = () => {
	return (window.AppleID && window.AppleID.auth && window.AppleID.auth.signIn)
		? window.AppleID.auth.signIn().then(loginForSso_parseLoginResponse)
		: Promise.reject();
};
const loginForSso_parseLoginResponse = (data) => {
	const accessToken = data.authorization.id_token;
	const jwtDecoded = jwtDecode(accessToken);
	const user = data.user;
	const email = (user && user.email) || deep(jwtDecoded, 'email');
	const firstName = user && user.name.firstName;
	const lastName = user && user.name.lastName;
	const fullName = !!lastName ? `${firstName} ${lastName}` : firstName;
	const name = !!fullName || 'Apple User';

	return {
		accessToken,
		email,
		name,
	};
}