import { getDuplosByKeyAndVersion } from './api/api.duplos.actions';
import { receiveEntities } from './entity.actions';
import { loadingInit, LOADING_KEYS, loadingError } from './loading.actions';
import { normalizeDuplo, duploFromJson } from '../model/duplo.model';

export const loadDuplos = (key, version = 'v1') => (dispatch) => {
	dispatch(loadingInit(LOADING_KEYS.DUPLOS));

	return dispatch(getDuplosByKeyAndVersion(key, version))
		.then((duploJson) => {
			const normalized = normalizeDuplo(duploFromJson(duploJson));
			dispatch(receiveEntities(normalized.entities));
		})
		.catch((err) => dispatch(loadingError(LOADING_KEYS.DUPLOS, err)));
};

export const loadDuplosDialog = (key) => (dispatch) => {
	const duplosDialogSchemaVersion = 'v1';

	return dispatch(loadDuplos(key, duplosDialogSchemaVersion));
};
