import { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

class WindowSize extends Component {
	constructor(props) {
		super(props);

		this.state = {
			height: this.props.assumedHeight,
			width: this.props.assumedWidth,
		};

		this.handleResize = debounce(this.handleResize, 300).bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		this.update();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize() {
		this.update();
	}

	update() {
		this.setState({
			height: window.innerHeight,
			width: window.innerWidth,
		});
	}

	render() {
		return this.props.render(this.state);
	}
};

WindowSize.displayName = 'WindowSize';

WindowSize.propTypes = {
	assumedHeight: PropTypes.number,
	assumedWidth: PropTypes.number,
	render: PropTypes.func,
};

WindowSize.defaultProps = {
	assumedHeight: 500,
	assumedWidth: 400,
};

export default WindowSize;
