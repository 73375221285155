import { ORDER_FORM_SECTION_STATES, TOGGLE_TYPES } from '../../const/seriesCheckout.const';

export const toggleAdd = (currentState) => {
	if (currentState === ORDER_FORM_SECTION_STATES.CHOOSE) return ORDER_FORM_SECTION_STATES.ADD;
	if (currentState === ORDER_FORM_SECTION_STATES.ADD) return ORDER_FORM_SECTION_STATES.CHOOSE;

	return ORDER_FORM_SECTION_STATES.CHOSEN;
};

export const toggleChoose = (currentState) => {
	if (currentState === ORDER_FORM_SECTION_STATES.CHOSEN) return ORDER_FORM_SECTION_STATES.CHOOSE;

	return ORDER_FORM_SECTION_STATES.CHOSEN;
};

export const toggleFormState = (currentState, toggleType) => {
	if (toggleType === TOGGLE_TYPES.ADD) return toggleAdd(currentState);
	if (toggleType === TOGGLE_TYPES.CHOOSE) return toggleChoose(currentState);

	return ORDER_FORM_SECTION_STATES.CHOSEN;
};