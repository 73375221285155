import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ASPECT_RATIOS, IMAGE_RATIO_CLASSES } from './AspectRatios';
import { CONFIGURABLE_LINK_TYPES } from '../../const';
import MaybeLinkWrap from '../../components/MaybeLinkWrap/MaybeLinkWrap';
import './Polaroid.css';

const Polaroid = ({
	analyticEvent,
	analyticObj,
	aspectRatio,
	backgroundColor,
	caption,
	fontColor,
	imageAltText,
	imageUrl,
	link,
	showSubtext,
	subtext
}) => {
	if (!imageUrl) return null;

	const style = {
		backgroundColor
	};
	const textStyle = {
		color: fontColor
	};

	return (
		<MaybeLinkWrap analyticEvent={analyticEvent} analyticObj={analyticObj} linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK} path={link}>
			<div className="Polaroid" style={style}>
				<div className={classnames(IMAGE_RATIO_CLASSES[aspectRatio])}>
					<img className="polaroid-image" alt={imageAltText} src={imageUrl} />
				</div>
				<div className={showSubtext ? 'caption-with-subtext-container' : 'caption-container'}>
					<div className="caption" style={textStyle}>
						<svg viewBox="0 0 100 100">
							<foreignObject width="100%" height="100%">
								<div className="caption-text-container">
									<div className={classnames('centered-text', 'caption-text')}>{caption}</div>
									{showSubtext && <div className={classnames('centered-text', 'subtext')}>{subtext}</div>}
								</div>
							</foreignObject>
						</svg>
					</div>
				</div>
			</div>
		</MaybeLinkWrap>
	);
};

Polaroid.displayName = 'Polaroid';

Polaroid.propTypes = {
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	aspectRatio: PropTypes.string,
	backgroundColor: PropTypes.string,
	caption: PropTypes.string,
	fontColor: PropTypes.string,
	imageAltText: PropTypes.string,
	imageUrl: PropTypes.string,
	link: PropTypes.string,
	showSubtext: PropTypes.bool,
	subtext: PropTypes.string
};

Polaroid.defaultProps = {
	aspectRatio: ASPECT_RATIOS._16x9,
	showSubtext: false
};

export default Polaroid;
