import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { bookFromJson, bookSchema } from './book.model';
import { peopleFromJsonArray, peopleSchema, personFromJson, personSchema } from './person.model';
import { COVER_TYPES } from '../const/coverType.const';
import { ENTITIES } from '../const/entities/entities';

export const groupFromJson = (json = {}) => ({
	activeCoverBundleId: deep(json, 'ActiveCoverBundleId'),
	bookCreationModelType: deep(json, 'BookCreationModelType'),
	bookSize: deep(json, 'BookSize'),
	book: deep(json, 'Book') && bookFromJson(deep(json, 'Book')),
	customCoverTemplateId: deep(json, 'CustomCoverTemplateId'),
	coverType: deep(json, 'IsHardcoverPreviewOn') ? COVER_TYPES.HARD : COVER_TYPES.SOFT,
	hasPrintPack: deep(json, 'HasPrintPack'),
	id: String(deep(json, 'ID')),
	isDisplayCaptionOn: deep(json, 'IsDisplayCaptionOn'),
	isDisplayDateOn: deep(json, 'IsDisplayDateOn'),
	isDisplayFrontTitleOn: deep(json, 'IsDisplayFrontTitleOn'),
	isDisplayLocationOn: deep(json, 'IsDisplayLocationOn'),
	isDisplayVolumeNumberOn: deep(json, 'IsDisplayVolumeNumberOn'),
	isErrorPhotos: deep(json, 'IsErrorPhotos'),
	isHardcover: deep(json, 'IsHardcoverPreviewOn'),
	isSingleOrdered: deep(json, 'IsSingleOrdered'),
	isSubscribed: deep(json, 'IsSubscribed'),
	isSyncing: deep(json, 'IsSyncing'),
	owner: deep(json, 'Owner') && personFromJson(deep(json, 'Owner')),
	people: peopleFromJsonArray(deep(json, 'People')),
	properties: deep(json, 'Properties'),
	skewyUrl: deep(json, 'SkewyUrl'),
	title: deep(json, 'Title'),
	totalPageCount: deep(json, 'TotalPageCount'),
	volumeCoverUrls: deep(json, 'VolumeCoverUrls'),
});

export const groupsFromJsonArray = (json) => {
	if (Array.isArray(json)) {
		return json.map(groupFromJson);
	}

	return json;
};

export const groupSchema = new schema.Entity(ENTITIES.GROUP, {
	book: bookSchema,
	owner: personSchema,
	people: peopleSchema,
});
export const groupsSchema = new schema.Array(groupSchema);

export const maybeTransformGroup = (group) => deep(group, 'id') ? group : groupFromJson(group);

export const normalizeGroup = (group) => normalize(group, groupSchema);
export const normalizeGroups = (groups) => normalize(groups, groupsSchema);
