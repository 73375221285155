import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import clickHandler from '../../helpers/clickHandler';

export const Icon = ({ className: classNameProp, name, onClick }) => (
	<i
		aria-hidden="true"
		className={classnames(classNameProp, 'Icon fa', `fa-${name}`)}
		onClick={(e) => clickHandler(onClick, e)}
	/>
);

Icon.displayName = 'Icon';

Icon.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func
};

export default Icon;
