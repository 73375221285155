import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Landing, { LANDING_URL } from './landing/Landing';
import SeriesPreview, { SERIES_PREVIEW_URL } from './series-preview/SeriesPreview';
import NotFound from '../not-found/NotFound';

export const INSTAGRAM_QUICK_FLOW_URL = '/ig-quick-flow';

export const InstagramQuickFlow = ({ match }) => (
	<Fragment>
		<Helmet>
			<title>Instagram Series</title>
			<meta
				name="description"
				content="Instagram Series Quick Flow"
			/>
			<meta name="robots" content="noindex" />
		</Helmet>
		<Switch>
			<Route path={`${match.path}${LANDING_URL}`} component={Landing} />
			<Route path={`${match.path}${SERIES_PREVIEW_URL}`} component={SeriesPreview} />
			<Route component={NotFound} />
		</Switch>
	</Fragment>
);

InstagramQuickFlow.propTypes = {
	match: PropTypes.object,
};

InstagramQuickFlow.displayName = 'InstagramQuickFlow';

export default withRouter(InstagramQuickFlow);
