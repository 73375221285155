import deep from 'deep-get-set';
import { schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const shippingGradeFromJson = (json = {}) => {
	const id = deep(json, 'Id');

	return {
		id: id !== undefined ? String(id) : undefined,
		name: deep(json, 'Name'),
		localizedName: deep(json, 'LocalizedName'),
	};
};

export const shippingGradeSchema = new schema.Entity(ENTITIES.SHIPPING_GRADE);

