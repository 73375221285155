import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { isReceiveEntitiesAction } from '../actions/entity.actions';
import { ENTITIES } from '../const/entities/entities';
import { LOAD_STATUS } from '../const/loadStatus.const';
import { REDUCERS } from '../const/reducers.const';
import { receiveEntityObjects, receiveEntityArrayReplacement } from '../helpers/entity/entity.helpers';
import { toggleFormState } from '../helpers/seriesCheckout/seriesCheckout.helpers';

export default (state = defaultState[REDUCERS.CHECKOUT], action) => {
	const { data, err, type } = action;

	if (isReceiveEntitiesAction(type)) {
		return {
			...state,
			...receiveEntityObjects(state, data, ENTITIES.CART_ITEM),
			...receiveEntityObjects(state, data, ENTITIES.CART),
			...receiveEntityObjects(state, data, ENTITIES.COMMITTED_CART),
			...receiveEntityObjects(state, data, ENTITIES.COUNTRY),
			...receiveEntityObjects(state, data, ENTITIES.COUPON_CODE),
			...receiveEntityObjects(state, data, ENTITIES.GIFT_CODE),
			...receiveEntityObjects(state, data, ENTITIES.MASTER_ORDER),
			...receiveEntityObjects(state, data, ENTITIES.ORDER_DESCRIPTORS),
			...receiveEntityObjects(state, data, ENTITIES.ORDER_OPTION),
			...receiveEntityObjects(state, data, ENTITIES.PRODUCT_PRICE_TIER),
			...receiveEntityObjects(state, data, ENTITIES.PRODUCT),
			...receiveEntityObjects(state, data, ENTITIES.SHIPPING_GRADE),
			...receiveEntityObjects(state, data, ENTITIES.SHIPPING_MESSAGE),
			...receiveEntityObjects(state, data, ENTITIES.SHIPPING_OPTION),
			...receiveEntityObjects(state, data, ENTITIES.SHIPPING_OPTION_VIEW_RESPONSE),
			...receiveEntityArrayReplacement(state, data, ENTITIES.COUPON_CODE_IDS),
		};
	}

	switch (type) {
		case ACTIONS.APPLY_COUPON_CODE_ERROR: {
			return {
				...state,
				couponTestStatus: LOAD_STATUS.ERROR
			}
		}
		case ACTIONS.APPLY_COUPON_CODE_INIT: {
			return {
				...state,
				couponTestStatus: LOAD_STATUS.INITIALIZING
			}
		}
		case ACTIONS.APPLY_COUPON_CODE_SUCCESS: {
			return {
				...state,
				couponTestStatus: LOAD_STATUS.SUCCESS
			}
		}
		case ACTIONS.SET_CART_ID: {
			const { cartId } = data;

			return {
				...state,
				cartId
			}
		}
		case ACTIONS.CLEAR_COUPON_CODE_ERROR: {
			return {
				...state,
				couponClearStatus: LOAD_STATUS.ERROR
			}
		}
		case ACTIONS.CLEAR_COUPON_CODE_INIT: {
			return {
				...state,
				couponClearStatus: LOAD_STATUS.INITIALIZING
			}
		}
		case ACTIONS.CLEAR_COUPON_CODE_SUCCESS: {
			return {
				...state,
				couponClearStatus: LOAD_STATUS.SUCCESS,
				cart: {
					...state.cart,
					[action.cartId]: {
						...state.cart[action.cartId],
						couponCode: undefined,
					},
				},
			};
		}
		case ACTIONS.LOAD_SHOPPING_CART_ERROR: {
			return {
				...state,
				err
			}
		}
		case ACTIONS.LOAD_GIFT_NOTE_LAYOUT_SUCCESS: {
			const { giftNoteLayout } = data;

			return {
				...state,
				giftNoteLayout
			}
		}
		case ACTIONS.LOAD_GIFT_NOTE_WRAPPED_TEXT_SUCCESS: {
			const { giftNoteFormatted } = data;

			return {
				...state,
				giftNoteFormatted
			}
		}
		case ACTIONS.RECEIVE_ACCOUNT_LOGOFF: {
			return {
				...state,
				cart: {},
				cartItems: {},
			}
		}
		case ACTIONS.SET_CART_ITEM_FOR_REMOVAL: {
			return {
				...state,
				cartItemToRemoveId: data.itemId
			}
		}
		case ACTIONS.UNSET_CART_ITEM_FOR_REMOVAL: {
			return {
				...state,
				cartItemToRemoveId: undefined
			}
		}
		case ACTIONS.SET_ORDER_ADDRESS_ID: {
			return {
				...state,
				order: {
					...state.order,
					addressId: action.addressId,
				}
			}
		}
		case ACTIONS.SET_ORDER_COUPON_CODE_ID: {
			return {
				...state,
				order: {
					...state.order,
					couponCodeId: action.couponCodeId,
				}
			}
		}
		case ACTIONS.SET_ORDER_FORM_SECTION: {
			const { section, sectionState } = action;

			return {
				...state,
				order: {
					...state.order,
					[section]: sectionState,
				}
			};
		}
		case ACTIONS.SET_ORDER_IS_TRYING_TO_SUBMIT: {
			const { isTryingToSubmit } = action;

			return {
				...state,
				order: {
					...state.order,
					isTryingToSubmit,
				}
			}
		}
		case ACTIONS.SET_ORDER_PAYMENT_METHOD_ID: {
			return {
				...state,
				order: {
					...state.order,
					paymentMethodId: action.paymentMethodId,
				}
			}
		}
		case ACTIONS.SET_ORDER_PREVIOUS_SHIPPING_TITLE: {
			return {
				...state,
				order: {
					...state.order,
					previousShippingTitle: action.title,
				}
			}
		}
		case ACTIONS.SET_ORDER_PRODUCT_ID: {
			return {
				...state,
				order: {
					...state.order,
					productId: action.productId,
				}
			}
		}
		case ACTIONS.SET_ORDER_SHIPPING_OPTION_ID: {
			return {
				...state,
				order: {
					...state.order,
					shippingOptionId: action.shippingOptionId,
				}
			}
		}
		case ACTIONS.TOGGLE_ORDER_FORM_SECTION: {
			const { section, toggleType } = action;
			const sectionState = deep(state, `order.${section}`);

			return {
				...state,
				order: {
					...state.order,
					[section]: toggleFormState(sectionState, toggleType),
				}
			};
		}
		default:
			return state;
	}
};
