export const ENVIRONMENTS = {
	LOCAL: 'Local',
	DEV: 'Development',
	STAGING: 'Staging',
	PROD: 'Production',
};

export const ENVIRONMENT_URLS = {
	[ENVIRONMENTS.LOCAL]: 'localhost:5301',
	[ENVIRONMENTS.DEV]: 'dev.chatbooks.com',
	[ENVIRONMENTS.STAGING]: 'staging.chatbooks.com',
	[ENVIRONMENTS.PROD]: 'api-prod.chatbooks.com',
};