import { useEffect } from 'react';

import loadScript from '../helpers/loadScript';
import { usePrevious } from './usePrevious';

export function useScript(
	source,
	options = {},
) {
	const {
		callback,
		enabled = true,
	} = options;

	const prevEnabled = usePrevious(enabled);

	useEffect(() => {
		if (enabled && !prevEnabled) {
			loadScript(source, callback);
		}

	}, [callback, enabled, prevEnabled, source]);
}
