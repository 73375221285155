import React from 'react';
import PropTypes from 'prop-types';
import deep from 'deep-get-set';
import connectContext from '../../../helpers/connectContext';
import { Consumer } from '../../../helpers/context';
import MenuLink from '../MenuLink/MenuLink';

export const Logo = ({ logo }) => (
	<MenuLink
		action={'Link'}
		label={logo ? <img className="logo" src={logo.url} alt={logo.alt || 'Chatbooks'} /> : null}
		analyticObj={{
			attr2: 'logo'
		}}
		className="logo-wrap"
		path="https://chatbooks.com"
	/>
);

Logo.propTypes = {
	logo: PropTypes.shape({
		url: PropTypes.string,
		alt: PropTypes.string
	})
};

const mapContextToProps = (context) => ({
	logo: deep(context, 'logo'),
});

export default connectContext(Consumer, mapContextToProps)(Logo);
