export const FORM_FIELDS = {
	ADDRESS: 'address',
	ADDRESS_ID: 'addressId',
	ADDRESS_CITY: 'city',
	ADDRESS_COUNTRY_CODE: 'countryCode',
	ADDRESS_NAME: 'name',
	ADDRESS_POSTAL_CODE: 'postalCode',
	ADDRESS_REGION: 'state',
	ADDRESS_STREET_1: 'street1',
	ADDRESS_STREET_2: 'street2',
	BRAINTREE: 'braintree',
	CART_ITEM_QUANTITY: 'cartItemQuantity',
	CREDIT_CARD: 'creditCard',
	CREDIT_CARD_CVV: 'creditCardCvv',
	CREDIT_CARD_EXPIRATION: 'creditCardExpiration',
	CREDIT_CARD_NUMBER: 'creditCardNumber',
	CURRENCY: 'currency',
	EMAIL: 'email',
	FULL_NAME: 'fullName',
	GIFT_CARD_AMOUNT: 'giftCardAmount',
	GIFT_CARD_AMOUNT_OPTIONS: 'giftCardAmountOptions',
	GIFT_CARD_AMOUNT_OTHER: 'giftCardOtherAmount',
	GIFT_CARD_DELIVERY_EMAIL: 'giftCardDeliveryEmail',
	GIFT_CARD_DELIVERY_METHOD: 'giftCardDeliveryMethod',
	GIFT_CARD_DELIVERY_OPTIONS: 'giftCardDeliveryOptions',
	GIFT_CARD_CODE: 'giftCardCode',
	HOW_DID_YOU_HEAR: 'howDidYouHearAboutUs',
	HOW_DID_YOU_HEAR_OTHER: 'howDidYouHearOther',
	LOGIN_EXISTING_USER_FORM: 'loginExistingUserForm',
	LOGIN_FORGOT_PASSWORD_FORM: 'loginForgotPasswordForm',
	LOGIN_MODAL_FORM_NAME: 'loginFormName',
	LOGIN_MODAL: 'loginModal',
	LOGIN_NEW_USER_FORM: 'loginNewUserForm',
	LOGIN_RESET_PASSWORD_FORM: 'loginResetPasswordForm',
	NEW_USER: 'newUser',
	PASSWORD: 'password',
	PASSWORD_CONFIRM: 'passwordConfirm',
	PASSWORD_NEW: 'newPassword',
	PAYMENT_SELECTION: 'payment',
	SHIPPING_METHOD: 'shippingMethod',
	SIGN_IN: 'signIn',
};