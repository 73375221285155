import { createSelector } from 'reselect';

import { selectDataSourceMediaMap, selectDataSourceMediaPageMap } from './groups.selectors';
import { INSTAGRAM_PAGINATION_FIRST_PAGE_ID } from '../actions/instagram.actions';
import { gatherPhotos } from '../model/dataSource/media/dataSourceMediaPage.model';

export const selectInstagramDataSourceMedia = createSelector(
	selectDataSourceMediaMap,
	selectDataSourceMediaPageMap,
	(mediaMap, mediaPageMap) => gatherPhotos(INSTAGRAM_PAGINATION_FIRST_PAGE_ID, mediaMap, mediaPageMap)
);
