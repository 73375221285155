import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconLogo = (props) => (
	<Svg
		{...props}
		height={props.size * .229813665}
		name="logo"
		viewBox="0 0 161 37"
	>
		<defs>
			<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-1">
				<feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"
				/>
				<feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"
				/>
				<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.189254982 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"
				/>
				<feMerge>
					<feMergeNode in="shadowMatrixOuter1"
					/>
					<feMergeNode in="SourceGraphic"
					/>
				</feMerge>
			</filter>
		</defs>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-640.000000, -804.000000)" fill="inherit">
				<g filter="url(#filter-1)" transform="translate(643.000000, 805.000000)">
					<path d="M14.9738552,20.1656278 C13.8280471,20.5477055 12.6583165,20.9382434 11.4987598,21.3255064 C11.4696128,21.2507284 11.4459652,21.1841377 11.418193,21.1191845 C11.026633,20.2005607 10.4997868,19.3823686 9.47634119,19.0898063 C7.52321549,18.5322459 5.47907407,19.3228737 4.83783951,21.4960768 C4.51804714,22.5795399 4.54361953,23.6649134 4.96295174,24.7148082 C5.45130191,25.9366379 6.36723345,26.6915142 7.67637486,26.9112089 C8.38882716,27.0312904 9.10320426,26.976435 9.78485971,26.6814165 C10.6400224,26.3108011 11.2103143,25.6844667 11.5378058,24.8269753 C11.5688777,24.7459202 11.5985746,24.6643193 11.6387205,24.555973 C12.7716049,24.9301362 13.9006397,25.3034807 15.0596465,25.68665 C15.0016274,25.8705931 14.955982,26.0433468 14.8927385,26.2095506 C14.201459,28.0189612 12.9327385,29.2609865 11.1349719,29.993211 C10.1195006,30.4069465 9.06525814,30.5289385 7.978844,30.4920953 C6.86713244,30.4544333 5.81041526,30.2049912 4.82271605,29.6984654 C2.74997755,28.6357437 1.442211,26.9597873 0.941487093,24.7074395 C0.472934905,22.5997354 0.749006734,20.5689927 1.91186308,18.7219201 C3.07746914,16.8712997 4.79631874,15.7818325 6.96144781,15.4447855 C8.76251403,15.1639584 10.4918126,15.4224066 12.0885746,16.3505824 C13.5582997,17.2056176 14.4789057,18.4746613 14.946908,20.0845728 C14.9524074,20.1031308 14.9524074,20.1031308 14.9738552,20.1656278"
					/>
					<path d="M60.8078283,11.3816625 L64.6150898,11.3816625 L64.6150898,17.2421879 C65.0404714,16.7220165 65.5728171,16.3639551 66.132385,16.0361871 C66.7810438,15.6565657 67.493771,15.4758975 68.2306958,15.4109443 C69.3182099,15.3148791 70.3993996,15.3844718 71.4255948,15.7875637 C73.3295006,16.5356172 74.5481762,17.9277444 75.1764871,19.8397702 C75.608193,21.1532986 75.7272559,22.507491 75.5804209,23.8780581 C75.4016891,25.5452813 74.8415713,27.0654045 73.6916386,28.3265337 C72.6475701,29.4714021 71.3411785,30.1345797 69.7977609,30.3258915 C68.7795398,30.45225 67.7725926,30.4121319 66.798642,30.052433 C65.9544781,29.7396752 65.25,29.2369702 64.7105051,28.5145705 C64.6783333,28.4719961 64.6458866,28.4294217 64.612615,28.3868474 C64.6101403,28.3841182 64.6013412,28.3857557 64.573569,28.3830266 L64.573569,30.0316917 L60.8078283,30.0316917 L60.8078283,11.3816625 L60.8078283,11.3816625 Z M71.7269641,22.8770146 C71.7269641,22.7293689 71.737138,22.5806315 71.7253143,22.4335316 C71.6736195,21.7943703 71.5628058,21.16749 71.2454882,20.5987402 C70.5610831,19.371725 69.2137205,18.7039079 67.8069641,18.8542827 C66.3740853,19.0071138 65.3998597,19.7546214 64.887037,21.0716977 C64.456156,22.1786313 64.4415825,23.316131 64.8169192,24.4440789 C65.4127834,26.2357502 66.9309035,27.157649 68.7817396,26.8852822 C70.1236027,26.6876934 71.2427385,25.6983852 71.5581313,24.3108975 C71.6645455,23.8423066 71.7453872,22.8797438 71.7269641,22.8770146 L71.7269641,22.8770146 Z"
					/>
					<path d="M20.679798,16.8707539 C21.228642,16.3047331 21.8698765,15.9232013 22.6043266,15.6912256 C23.5188833,15.4027569 24.452138,15.3282518 25.4084905,15.4221337 C26.4165376,15.5214739 27.3090965,15.8920892 28.0900168,16.5099634 C28.9099832,17.1597683 29.4027329,18.0295408 29.6700056,19.0289468 C29.8407632,19.6675623 29.9315039,20.3168214 29.930954,20.9789074 C29.9290292,23.9299115 29.9301291,26.8803698 29.9301291,29.8316467 L29.9301291,30.0273251 L26.0458754,30.0273251 L26.0458754,29.7710601 C26.0458754,27.088875 26.0483502,24.4069627 26.0442256,21.7245047 C26.0431257,21.0566875 25.9056397,20.4246219 25.5363524,19.8471388 C25.178064,19.2871221 24.6509428,18.997016 24.0187823,18.9203276 C23.5945006,18.8692929 23.1454714,18.8578306 22.7288889,18.937794 C21.6801459,19.1383848 21.1016049,19.8605115 20.8219585,20.836447 C20.7361672,21.1355593 20.6965713,21.4575961 20.6954714,21.7689894 C20.6858474,24.4378019 20.689697,27.1063414 20.689697,29.774608 L20.689697,30.0278709 L16.8015937,30.0278709 L16.8015937,11.3843916 L20.679798,11.3843916 L20.679798,16.8707539"
					/>
					<path d="M92.4384007,22.9116745 C92.4227273,25.0005477 91.7520707,26.8377954 90.2655724,28.3429084 C89.2949214,29.3259397 88.1163917,29.9667385 86.7624299,30.2603925 C84.5733782,30.7352604 82.4888159,30.4784496 80.5997587,29.2315119 C78.8410382,28.0705417 77.8175926,26.4087768 77.4568294,24.3518344 C77.1683838,22.704261 77.3363917,21.1003535 78.028771,19.5663117 C78.7522222,17.9629501 79.9238777,16.7924279 81.5239394,16.0424641 C82.7896352,15.4486062 84.1229742,15.2532008 85.5124074,15.3470828 C87.1289675,15.4567936 88.5711953,16.0045292 89.8132435,17.032864 C91.053367,18.0595612 91.8549102,19.3711792 92.2013749,20.9363331 C92.288266,21.3296001 92.3427104,21.7305088 92.3935802,22.1319632 C92.4263019,22.388774 92.4573737,22.9083996 92.4384007,22.9116745 M81.2011223,22.8871124 C81.2401684,23.2331656 81.2577666,23.5827667 81.321835,23.9239074 C81.4947924,24.8444417 81.9124747,25.6446214 82.6634231,26.2223774 C83.3876992,26.779665 84.2291134,27.0211927 85.1524691,26.9425938 C86.6549158,26.8159624 87.6811111,26.0449842 88.2291302,24.6626819 C88.6245398,23.6643675 88.6473625,22.632212 88.3833895,21.5978732 C88.162587,20.7321944 87.7187823,19.9950575 86.9725084,19.4784339 C86.1803143,18.9293337 85.2850056,18.72847 84.3319529,18.8766616 C83.5029125,19.0046576 82.7761616,19.34689 82.2168687,19.9980595 C81.5011167,20.8328992 81.2272447,21.8104722 81.2011223,22.8871124"
					/>
					<path d="M109.318378,22.9116745 C109.30243,25.0005477 108.632323,26.8377954 107.14555,28.3429084 C106.174624,29.3259397 104.996369,29.9667385 103.642132,30.2603925 C101.453081,30.7352604 99.3685185,30.4784496 97.4794613,29.2315119 C95.7210157,28.0705417 94.6975701,26.4087768 94.336807,24.3518344 C94.0483614,22.704261 94.2163692,21.1003535 94.9084736,19.5663117 C95.6319248,17.9629501 96.8035802,16.7924279 98.4039169,16.0424641 C99.6696128,15.4486062 101.002952,15.2532008 102.39211,15.3470828 C104.00867,15.4567936 105.450898,16.0045292 106.693221,17.032864 C107.93362,18.0595612 108.735163,19.3711792 109.081352,20.9363331 C109.168244,21.3296001 109.222688,21.7305088 109.273833,22.1319632 C109.306279,22.388774 109.337076,22.9083996 109.318378,22.9116745 M98.0810999,22.8871124 C98.1198709,23.2331656 98.1380191,23.5827667 98.2018126,23.9239074 C98.3747699,24.8444417 98.7924523,25.6446214 99.5434007,26.2223774 C100.267677,26.779665 101.108816,27.0211927 102.032722,26.9425938 C103.534893,26.8159624 104.561089,26.0449842 105.108833,24.6626819 C105.504792,23.6643675 105.527615,22.632212 105.263642,21.5978732 C105.042565,20.7321944 104.59876,19.9950575 103.852486,19.4784339 C103.060292,18.9293337 102.164983,18.72847 101.211655,18.8766616 C100.383165,19.0046576 99.6558642,19.34689 99.0968462,19.9980595 C98.3810943,20.8328992 98.1069473,21.8104722 98.0810999,22.8871124"
					/>
					<path d="M136.574972,19.4421365 C135.490483,19.6847558 134.425516,19.9230085 133.343227,20.1656278 C133.303631,20.0305361 133.268984,19.9039046 133.230213,19.7783648 C132.948917,18.8793907 132.217492,18.313097 131.269663,18.2647915 C130.792312,18.2407752 130.31771,18.2552396 129.892879,18.5087754 C129.430376,18.7849629 129.090511,19.1422055 129.113333,19.7316968 C129.129282,20.1388824 129.339635,20.4235303 129.658603,20.6462269 C130.024315,20.9027648 130.461521,20.9475225 130.880578,21.0476814 C131.831706,21.2747447 132.806481,21.4357631 133.726538,21.7517959 C135.203137,22.2585947 136.269753,23.2388968 136.631066,24.8013215 C136.993204,26.3678399 136.585146,27.7556005 135.458311,28.9149333 C134.721661,29.6733574 133.793356,30.105651 132.76936,30.3152479 C131.443996,30.586523 130.106257,30.5753336 128.819113,30.1531378 C127.066167,29.5775651 125.770774,28.4976499 125.277475,26.6462107 C125.22798,26.4606302 125.199108,26.2693184 125.157037,26.0651798 C126.281672,25.820923 127.37991,25.5815786 128.492722,25.3395051 C128.529843,25.7788944 128.688227,26.1492369 128.919478,26.4963817 C129.259068,27.0067283 129.72927,27.3173028 130.329534,27.4300158 C130.961695,27.5487327 131.59798,27.5866676 132.202918,27.3189403 C132.702817,27.0978811 133.009136,26.7062515 133.035258,26.1514202 C133.06138,25.5965888 132.791083,25.1754847 132.28596,24.9702544 C131.833906,24.7863113 131.34638,24.6861523 130.872329,24.5587022 C130.006442,24.3253619 129.113608,24.1659809 128.277969,23.8545876 C126.823917,23.3125832 125.876639,22.2741507 125.612391,20.7182759 C125.351717,19.1836883 125.838143,17.8761639 126.964153,16.8148068 C127.908956,15.92402 129.053389,15.4565207 130.353182,15.3506306 C131.293861,15.2739422 132.227391,15.3192457 133.124349,15.608533 C134.892419,16.180012 136.114119,17.2934955 136.532076,19.1542137 C136.550774,19.2385437 136.557649,19.3253299 136.574972,19.4421365"
					/>
					<path d="M51.667211,11.3833 L55.5203928,11.3833 L55.5203928,15.7504476 L58.4078732,15.7504476 L58.4078732,19.3930122 L55.5506397,19.3930122 C55.5399158,19.4516884 55.5267172,19.4890775 55.5269921,19.5264665 C55.5289169,21.5763131 55.5313917,23.4078297 55.5357912,25.458495 C55.5374411,26.1459619 55.9804209,26.7450051 56.8031369,26.7780275 C57.3343827,26.7993147 57.8678283,26.7818483 58.4152974,26.7818483 C58.4152974,26.7848503 58.4205219,26.8440724 58.4207969,26.9027486 C58.4210718,27.8372014 58.4172222,28.7716542 58.4249214,29.7055611 C58.4260213,29.8611213 58.3748765,29.9238912 58.2274916,29.9719238 C57.4358474,30.2279159 56.6235802,30.2683069 55.8016891,30.22737 C55.175853,30.1957122 54.5753143,30.0701724 53.9978732,29.8136345 C52.7330022,29.2517074 52.0175253,28.2730428 51.7637262,26.9475063 C51.6936083,26.5804388 51.6652862,26.2010903 51.6641863,25.8269271 C51.6548373,23.6829256 51.6597868,21.7567084 51.6595118,19.6127069 C51.6595118,19.552939 51.6542873,19.4934441 51.6501627,19.4036558 L49.0646016,19.4036558 L49.0646016,15.7310708 C49.3975926,15.7310708 51.3402694,15.7471726 51.6677609,15.7286146 L51.667211,11.3833"
					/>
					<path d="M115.033395,26.2709559 L116.640881,24.5889955 L120.495988,30.0467019 C121.94509,30.0406978 123.541577,30.0423353 124.991229,30.0417895 C125.055297,30.0417895 125.119366,30.0363312 125.213956,30.0316917 C123.245982,27.2616287 121.295056,24.515582 119.340831,21.7651687 C121.257385,19.7663567 123.159366,17.782282 125.100668,15.7572704 L124.83202,15.7572704 C123.41674,15.7572704 121.494136,15.7632745 120.027985,15.7529038 L115.023221,21.0626916 L115.023221,11.3849374 L111.16014,11.3849374 L111.16014,30.0322375 L115.033395,30.0322375 L115.033395,26.2709559 L116.640881,24.5889955 L115.033395,26.2709559"
					/>
					<path d="M46.8464029,30.0316917 L43.0803872,30.0316917 L43.0803872,28.3824808 C43.052615,28.3852099 43.0438159,28.3835724 43.0416162,28.3868474 C43.0083446,28.4288759 42.9753479,28.4714503 42.9434512,28.5145705 C42.4042312,29.2369702 41.6997531,29.740221 40.8553143,30.0518872 C40.0867677,30.3365351 39.2970483,30.4214109 38.4977048,30.3823844 C37.0282548,30.3103355 34.9920875,29.4394713 33.9972391,28.3644685 C32.8236588,27.095152 32.2541919,25.5619289 32.0735354,23.8780581 C31.9269753,22.507491 32.0460382,21.1532986 32.4777441,19.8397702 C33.10578,17.9277444 34.3244557,16.5350714 36.2286364,15.7875637 C37.2548316,15.3847447 38.3360213,15.3148791 39.4232604,15.4109443 C40.1604602,15.4758975 40.8726375,15.6565657 41.5218462,16.0361871 C42.0811392,16.3639551 42.6134848,16.7220165 43.0391414,17.2421879 L43.0391414,15.8216778 L46.8464029,15.8216778 L46.8464029,30.0316917 L46.8464029,30.0316917 Z M36.0960999,24.3103517 C36.4114927,25.6983852 37.5306285,26.6876934 38.8724916,26.8852822 C40.7230527,27.1571031 42.2411728,26.2357502 42.837037,24.4440789 C43.2123737,23.316131 43.1978002,22.1786313 42.7671942,21.0716977 C42.2543715,19.7546214 41.279596,19.0071138 39.8469921,18.8542827 C38.4405107,18.7039079 37.0928732,19.371725 36.408468,20.5987402 C36.0914254,21.16749 35.9803367,21.7949161 35.928642,22.4335316 C35.9168182,22.5806315 35.9272671,22.7293689 35.9272671,22.8770146 C35.908844,22.8797438 35.9896857,23.8423066 36.0960999,24.3103517 L36.0960999,24.3103517 Z"
					/>
					<path d="M133.638272,0.683761005 L124.716532,0.683761005 C123.897116,0.683761005 123.233058,1.34284496 123.233058,2.15585164 L123.233058,11.5972635 C123.233058,12.4097243 123.897116,13.0693541 124.716532,13.0693541 L124.898288,13.0693541 L124.898288,13.0868205 L124.913687,13.0868205 C125.377565,13.0868205 129.92835,13.0879121 133.288232,13.0870934 C134.761807,13.0868205 136.175438,13.6670327 137.218681,14.7005528 L137.345993,14.8271842 L137.345993,4.36371467 C137.345993,2.33133445 135.685988,0.683761005 133.638272,0.683761005"
					/>
					<path d="M143.451195,4.98431795 L152.792267,4.98431795 C153.611409,4.98431795 154.275466,5.64340191 154.275466,6.45640858 L154.275466,16.9886521 C154.275466,17.8013859 153.611409,18.4607427 152.792267,18.4607427 L152.610511,18.4607427 L152.610511,18.4784821 L152.595112,18.4784821 C152.13096,18.4784821 147.161392,18.4790279 143.80151,18.4784821 C142.32766,18.4784821 140.914304,19.0584214 139.871061,20.0919414 L139.743474,20.2183 L139.743474,8.6639987 C139.743474,6.63189139 141.403479,4.98431795 143.451195,4.98431795"
					/>
				</g>
			</g>
		</g>
	</Svg>
);

IconLogo.displayName = 'IconLogo';

IconLogo.propTypes = iconProps;

IconLogo.defaultProps = {
	...defaultIconProps,
	size: defaultIconProps.size * 4
};

export default IconLogo;
