import deep from 'deep-get-set';

import { apiGetData } from './api.http.actions';
import * as appStringSelectors from '../../selectors/appString.selectors';
import * as cardsSelectors from '../../selectors/cards.selectors';
import { queryStringFromJson } from '../../helpers/locationUtils';

const filterInValidValues = (selectedValues, templateFilter) => {
	const optionNames = templateFilter.optionKeys
		.map((optionKey) => templateFilter.options[optionKey])
		.map((option) => option.slug);
	const optionNamesSet = new Set(optionNames);

	const validValues = selectedValues
		.filter((filter) => optionNamesSet.has(filter));

	const areAllSelected = validValues.length === optionNames.length;

	return (areAllSelected) ? [] : validValues;
};

export const getCardTemplatesByTheme = (queryParams) => (dispatch, getState) => {
	const state = getState();
	const cardsTemplateFilterKeys = cardsSelectors.selectCardsTemplateFilterKeys(state);
	const filters = cardsSelectors.selectCardsTemplateFilters(state);
	const validatedQueryParams = cardsTemplateFilterKeys
		.reduce((total, key) => {
			const value = deep(queryParams, `${key}`);
			const resp = (!!value)
				? filterInValidValues(value, filters[key])
				: [];

			return {
				...total,
				[key]: resp
			};
		}, {});

	const queryParamsString = cardsTemplateFilterKeys
		.reduce((total, key) => {
			const filter = deep(filters, `${key}`);
			const filterOptionKeys = deep(filter, 'optionKeys');
			const filterOptions = deep(filter, 'options');
			const slugMap = filterOptionKeys.reduce((totalSlug, key) => {
				const filter = deep(filterOptions, `${key}`);

				return {
					...totalSlug,
					[filter.slug]: filter
				};
			}, {});
			const parameterName = deep(filter, 'name');
			const values = deep(validatedQueryParams, `${key}`);
			const str = values.reduce((t, value) => {
				const id = deep(slugMap, `${value}.id`);

				return `${t}&${parameterName}=${id}`;
			}, '');

			return `${total}${str}`;
		}, '');

	const data = {
		side: 'Front',
	};

	if (appStringSelectors.selectIsPlpQueryTemplatesWithCombineTemplateSetsParamEnabled(state)) {
		data.combineTemplateSets = 'true';
	}

	const cat = cardsSelectors.selectSubCatOrCat(state);
	const path = `/cardshop/categories/${cat}/templates`;

	const url = `${path}?${queryStringFromJson(data)}${queryParamsString}`;

	return dispatch(apiGetData(url));
};
