import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import deep from 'deep-get-set';

import CtaButton from '../../instagram-quick-flow/components/CtaButton/CtaButton';
import * as analyticsActions from '../../../actions/analytics.actions';
import * as appActions from '../../../actions/app.actions';
import * as instagramQuickFlowActions from '../../../actions/instagramQuickFlow.actions';
import { queryStringToJson } from '../../../helpers/locationUtils';
import { selectUserIdString } from '../../../selectors/user.selectors';
import { selectInstagramDataSourceMedia } from '../../../selectors/dataSourceMedia.selectors';
import './BooksAreReady.css';

export const QUICK_FLOW_BOOKS_ARE_READY_URL = '/ready';

class BooksAreReady extends Component {
	constructor(props) {
		super(props);

		this.generateBranchLink = this.generateBranchLink.bind(this);
		this.onCtaClick = this.onCtaClick.bind(this);
		this.saveSeries = this.saveSeries.bind(this);
		this.triggerLoadEvent = this.triggerLoadEvent.bind(this);
	}

	componentDidMount() {
		const { appActions, instagramQuickFlowActions, location, personId, photos } = this.props;
		const { branchLink, groupId, token } = queryStringToJson(location.search);

		if (groupId && personId) {
			this.triggerLoadEvent();
		}

		if (token) {
			instagramQuickFlowActions.loadInstagramSeriesPreviewPhotos(token);
		}

		if (personId && token) {
			this.saveSeries();
		}

		if (groupId && photos && photos.length && !branchLink) {
			this.generateBranchLink();
		}

		if (!branchLink) appActions.showThrobber(this.componentDidMount.name);
	}

	componentDidUpdate(prevProps) {
		const { location: prevLocation, personId: prevPersonId, photos: prevPhotos } = prevProps;
		const { branchLink: prevBranchLink, groupId: prevGroupId, token: prevToken } = queryStringToJson(prevLocation.search);

		const { appActions, instagramQuickFlowActions, location, personId, photos } = this.props;
		const { branchLink, groupId, token } = queryStringToJson(location.search);

		if (!(prevGroupId && prevPersonId) && (groupId && personId)) {
			this.triggerLoadEvent();
		}

		if (!prevToken && token) {
			instagramQuickFlowActions.loadInstagramSeriesPreviewPhotos(token);
		}

		if (!(prevPersonId && prevToken) && (personId && token)) {
			this.saveSeries();
		}

		if (!(prevPhotos && prevPhotos.length && prevGroupId) && (groupId && photos && photos.length) && !branchLink) {
			this.generateBranchLink();
		}

		if (!prevBranchLink && branchLink) appActions.hideThrobber(this.componentDidUpdate.name);
	}

	triggerLoadEvent() {
		const { analyticsActions, location, personId } = this.props;
		const { groupId } = queryStringToJson(location.search);

		analyticsActions.triggerAnalyticsEvent('IGSeriesPreview_Load', {
			chatgroupid: groupId,
			personid: personId,
		});
	}

	saveSeries() {

		const shouldGoToReadyPage = true;
		const { history, instagramQuickFlowActions, location } = this.props;
		const { groupId, token } = queryStringToJson(location.search);

		if (!groupId) {
			instagramQuickFlowActions.saveSeries(history, token, shouldGoToReadyPage);
		}
	}

	generateBranchLink() {
		const shouldGoToReadyPage = true;
		const { history, instagramQuickFlowActions, location, photos } = this.props;
		const { groupId, token } = queryStringToJson(location.search);

		instagramQuickFlowActions.generateIGMergeBranchLink(history, groupId, photos, token, shouldGoToReadyPage);
	}

	onCtaClick() {
		const { analyticsActions, location, personId } = this.props;
		const { branchLink, groupId } = queryStringToJson(location.search);

		analyticsActions.triggerAnalyticsEvent('IGSeriesPreview_Continue', {
			chatgroupid: groupId,
			personid: personId,
		});

		if (branchLink) {
			window.location.href = branchLink;
		}
	}

	render() {
		const coverImage = deep(this.props, 'photos.0.thumbnailUrl');

		return (
			<div className="quickflow-books-are-ready">
				<h1>Your books are ready!</h1>
				<h2>Check them out in the Chatbooks Mobile App</h2>

				{coverImage && (
					<Fragment>
						<p className="album-title">My Instagram Photos</p>
						<div className="album-cover">
							<div
								className="album-cover-image"
								style={{ backgroundImage: `url(${coverImage})` }}
							/>
							<div className="album-cover-shadow" />
						</div>
					</Fragment>
				)}

				<CtaButton
					ctaText="Get the Chatbooks app"
					onCtaClick={this.onCtaClick}
				/>
			</div>
		);
	}
};

BooksAreReady.displayName = 'BooksAreReady';

BooksAreReady.propTypes = {
	analyticsActions: PropTypes.object,
	appActions: PropTypes.object,
	history: PropTypes.object,
	instagramQuickFlowActions: PropTypes.object,
	location: PropTypes.object,
	personId: PropTypes.string,
	photos: PropTypes.array,
};

const mapStateToProps = (state) => ({
	personId: selectUserIdString(state),
	photos: selectInstagramDataSourceMedia(state),
});

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
	appActions: bindActionCreators(appActions, dispatch),
	instagramQuickFlowActions: bindActionCreators(instagramQuickFlowActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(BooksAreReady));
