import React from 'react';

import { SCROLL_DIRECTION } from '../../const';

export const CarouselContext = React.createContext({
	context: {
		autoScroll: true,
		pageCount: 0,
		pageNumber: 0,
		pageSize: 0,
		prevPageNumber: 0,
		scrollDirection: SCROLL_DIRECTION.RIGHT,
		scrollIncrement: 0
	},
	goToPage: (page, forcedUpdate, direction) => {},
	startTimer: (callback, interval) => {},
	updateContext: (context) => {}
});
