import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconShare = (props) => (
	<Svg
		{...props}
		name="share"
		viewBox="0 0 56 56"
	>
		<polygon
			fill="inherit"
			points="28.106,2.272 16.879,13.5 18.086,13.5 20.914,13.5 21.121,13.5 26.5,8.121 26.5,36.5 29.5,36.5
			29.5,7.908 35.092,13.5 35.299,13.5 38.127,13.5 39.334,13.5 "
		/>
		<polygon
			fill="inherit"
			points="33.5,16.5 33.5,18.5 45.5,18.5 45.5,52.5 10.5,52.5 10.5,18.5 22.5,18.5 22.5,16.5 8.5,16.5
			8.5,54.5 47.5,54.5 47.5,16.5 "
		/>
	</Svg>
);

IconShare.displayName = 'IconShare';

IconShare.propTypes = iconProps;

IconShare.defaultProps = defaultIconProps;

export default IconShare;
