export function loadScript(source, callback) {
	return new Promise((resolve, reject) => {
		const _resolve = () => {
			if (callback) callback();
			resolve();
		};

		const isLoaded = Boolean(document.getElementById(source));
		if (isLoaded) return _resolve();

		const script = document.createElement('script');
		script.src = source;
		script.id = source;
		script.onload = _resolve;
		script.onerror = reject;
		script.async = true;

		document.body.appendChild(script);
	});
};

export default loadScript;
