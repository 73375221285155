import React from 'react';
import PropTypes from 'prop-types';

import DangerouslyTranslate from '../../../../components/Translate/DangerouslyTranslate';

const Benefit = ({
	number,
	item,
}) => (
	<li>
		<item.icon
			size={90}
		/>
		<p>
			<DangerouslyTranslate
				id={`web.ig.quickflow.landing.benefits.${number}`}
			/>
		</p>
	</li>
);

Benefit.propTypes = {
	number: PropTypes.number,
	item: PropTypes.shape({
		icon: PropTypes.func,
		text: PropTypes.string,
	}),
};

export default Benefit;
