import { BOOK_CREATION_MODEL_TYPES } from '../../const/bookCreationModelTypes.const';

export const isTypeBook = (type) => !isTypePrints(type) && !isTypeCards(type);

export const isTypeCards = (type) => type === BOOK_CREATION_MODEL_TYPES.CARDS;

export const isTypeCustom = (type) => isTypeCustomBook(type) || isTypeCustomPrints(type);

export const isTypeCustomBook = (type) => type === BOOK_CREATION_MODEL_TYPES.CUSTOM_BOOK;

export const isTypeCustomPrints = (type) => type === BOOK_CREATION_MODEL_TYPES.CUSTOM_PRINTS;

export const isTypeHighlightsBook = (type) => type === BOOK_CREATION_MODEL_TYPES.THIRTY_PAGE_HIGHLIGHT;

export const isTypeHighlightsGiftBook = (type) => type === BOOK_CREATION_MODEL_TYPES.HIGHLIGHTS_GIFT_BOOK;

export const isTypePrints = (type) => isTypeCustomPrints(type) || isTypeSeriesPrints(type);

export const isTypeSeriesBook = (type) => type === BOOK_CREATION_MODEL_TYPES.SIXTY_PAGE_SERIES_GRANDFATHER || type === BOOK_CREATION_MODEL_TYPES.SIXTY_PAGE_SERIES;

export const isTypeSeriesPrints = (type) => type === BOOK_CREATION_MODEL_TYPES.SERIES_PRINTS;

export const isTypeSeries = (type) => isTypeSeriesPrints(type) || isTypeSeriesBook(type);

export const isTypeLayflat = (type) => type === BOOK_CREATION_MODEL_TYPES.LAYFLAT;

export const isTypeSubscriptionBook = (type) => type === BOOK_CREATION_MODEL_TYPES.SUBSCRIPTION_BOOK;

export const isTypeWallTile = (type) => type === BOOK_CREATION_MODEL_TYPES.GALLERY_WALL_TILE;

export const isTypeCustomBookOrLayflat = (type) => isTypeLayflat(type) || isTypeCustomBook(type);
