import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import TaperedLine from './TaperedLine';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import withWindowSize from '../../components/WindowSize/withWindowSize';
import { DEVICE_WIDTH, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../const';

const LoveItGuaranteeContainer = styled.div`
	background-color: ${(props) => props.backgroundColor};
`;

const LoveItGuaranteeContentContainer = styled(PageFlowContainer)`
	position: relative;
`;

const SEAL_DIAMETER = 309;
const SEAL_OUTER_DIAMETER = SEAL_DIAMETER + 4;

const SEAL_DIAMETER_SMALL = 115;
const SEAL_OUTER_DIAMETER_SMALL = SEAL_DIAMETER_SMALL + 10;

const LoveItGuaranteeSealDivBackground = styled.div`
	position: absolute;

	background-color: ${(props) => props.backgroundColor};
	border-radius: 100%;
	display: flex;
	left: 50%;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		top: -${(SEAL_OUTER_DIAMETER_SMALL / 2) + 20}px;
		height: ${SEAL_OUTER_DIAMETER_SMALL}px;
		width: ${SEAL_OUTER_DIAMETER_SMALL}px;
		margin-left: -${SEAL_OUTER_DIAMETER_SMALL / 2}px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		top: -${(SEAL_OUTER_DIAMETER / 2) + 20}px;
		height: ${SEAL_OUTER_DIAMETER}px;
		width: ${SEAL_OUTER_DIAMETER}px;
		margin-left: -${SEAL_OUTER_DIAMETER / 2}px;
	}
`;

const LoveItGuaranteeSealImg = styled.img`
	margin: auto;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		height: ${SEAL_DIAMETER_SMALL}px;
		width: ${SEAL_DIAMETER_SMALL}px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		height: ${SEAL_DIAMETER}px;
		width: ${SEAL_DIAMETER}px;
	}
`;

const GuaranteeDiv = styled.div`
	font-family: Circular-Pro-Medium;
	text-align: center;
	color: #333333;
	padding-left: ${SPACING.MEDIUM};
	padding-right: ${SPACING.MEDIUM};
	margin-left: auto;
	margin-right: auto;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 21px;
		line-height: 28px;
		max-width: 320px;
		padding-bottom: ${SPACING.BIGGER};
		padding-top: 82px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 36px;
		line-height: 48px;
		max-width: 988px;
		padding-bottom: 138px;
		padding-top: 233px;
	}
`;

// const StyledTaperedLine = styled(TaperedLine)`
// 	margin: auto;
// 	max-width: 1250px;
// `;

// const BottomTaperedLine = styled(StyledTaperedLine)`
// 	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
// 		margin-bottom: ${SPACING.BIGGEST};
// 	}
// `;

const LoveItGuarantee = ({
	betterInTheAppBackgroundColor,
	loveItGuarantee,
	loveItGuaranteeBackgroundColor,
	sealImageAlt,
	sealImageUrl,
	windowSize,
}) => {
	// const isMobile = windowSize.width < DEVICE_WIDTH.TABLET;

	return (
		<LoveItGuaranteeContainer
			backgroundColor={loveItGuaranteeBackgroundColor}>
			<LoveItGuaranteeContentContainer>
				<LoveItGuaranteeSealDivBackground
					backgroundColor={betterInTheAppBackgroundColor}>
					<LoveItGuaranteeSealImg src={sealImageUrl} alt={sealImageAlt} />
				</LoveItGuaranteeSealDivBackground>
				<GuaranteeDiv>{loveItGuarantee}</GuaranteeDiv>
				{/* {!isMobile && <BottomTaperedLine />} */}{/* TODO - bring this back when blog posts section is introduced */}
			</LoveItGuaranteeContentContainer>
		</LoveItGuaranteeContainer>
	);
};

LoveItGuarantee.displayName = 'LoveItGuarantee';

LoveItGuarantee.propTypes = {
	betterInTheAppBackgroundColor: PropTypes.string,
	loveItGuarantee: PropTypes.string,
	loveItGuaranteeBackgroundColor: PropTypes.string,
	sealImageAlt: PropTypes.string,
	sealImageUrl: PropTypes.string,
	windowSize: PropTypes.object,
};

export default withWindowSize(LoveItGuarantee);
