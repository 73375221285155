import { apiGetData, apiPost } from './api.http.actions';
import requireParams from '../../helpers/requireParams';
import { orderDescriptorsFromJson } from '../../model/orderDescriptor.model';
import { queryStringFromJson } from '../../helpers/locationUtils';

export const getOrderDescriptors = () => (dispatch) => {
	return dispatch(apiGetData('/orders/descriptors'))
		.then((orderDescriptorsJson) => orderDescriptorsFromJson(orderDescriptorsJson));
};

export const getOrderSubscriptionsByGroupId = (groupId) => (dispatch) => {
	requireParams({ groupId }, getOrderSubscriptionsByGroupId.name);

	return dispatch(apiGetData(`/orders/subscriptions?${queryStringFromJson({ groupId })}`))
		.then(({ orders }) => orders);
};

export const postOrdersUpdateShippingAddress = (orderId, addressId) => (dispatch) => {
	requireParams({ addressId, orderId }, postOrdersUpdateShippingAddress.name);

	return dispatch(apiPost('/orders/updateShippingAddress', { id: orderId, addressId }));
};
