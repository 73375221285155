import deep from 'deep-get-set';

import { dataSourceResponseFromJson } from './dataSourceResponse.model';
import * as api from '../api/api.actions';
import * as appActions from '../app.actions';
import * as entityActions from '../entity.actions';
import { LOADING_KEYS, loadingError, loadingInit, loadingSuccess } from '../loading.actions';
import { ENTITIES } from '../../const/entities/entities';
import { dataSourceFromJson, normalizeDataSources } from '../../model/dataSource/dataSource.model';

export const loadAnonymousDataSource = (id, key) => (dispatch) => {
	dispatch(appActions.showThrobber(loadAnonymousDataSource.name));

	return dispatch(api.getAnonymousDataSource(id, key))
		.then((dataSourceResponseFromJson))
		.finally(() => dispatch(appActions.hideThrobber(loadAnonymousDataSource.name)));
};

export const loadDataSources = () => (dispatch) => {
	return dispatch(api.getDataSources())
		.then((data) => {
			return (deep(data, 'dataSources') || []).map(dataSourceFromJson);
		})
		.then((dataSources) => {
			const normalized = normalizeDataSources(dataSources);

			const entities = {
				...normalized.entities,
				[ENTITIES.DATA_SOURCE_IDS]: normalized.result,
			};

			dispatch(entityActions.receiveEntities(entities), loadDataSources.name);
		});
};

export const reconnectDataSource = (dataSourceId, token, instagramLongLivedToken) => (dispatch) => {
	dispatch(appActions.showThrobber(reconnectDataSource.name));
	dispatch(loadingInit(LOADING_KEYS.RECONNECT_DATA_SOURCE));

	return dispatch(api.postReconnectDataSource(dataSourceId, token, instagramLongLivedToken))
		.then(dataSourceResponseFromJson)
		.then((data) => {
			dispatch(loadingSuccess(LOADING_KEYS.RECONNECT_DATA_SOURCE));

			return data;
		})
		.catch((err) => {
			dispatch(loadingError(LOADING_KEYS.RECONNECT_DATA_SOURCE, err));
			throw err;
		})
		.finally(() => dispatch(appActions.hideThrobber(reconnectDataSource.name)));
};
