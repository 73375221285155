import React from 'react';
import PropTypes from 'prop-types';

import { SOCIAL_PLATFORMS } from '../../const/socialPlatforms';

const PlatformIcon = ({ platform, stars }) => {
	if (!platform) return null;

	switch (platform) {
		case SOCIAL_PLATFORMS.APP_STORE:
			const icons = new Array(Math.floor(stars))
				.fill(<i className="fa fa-star" />)
				.map((icon, index) => React.cloneElement(icon, { key: `SocialCard-Star-${index}` }));
			if (!Number.isInteger(stars)) {
				icons.push(<i key="SocialCard-HalfStar" className="fa fa-star-half" />);
			}

			return icons;
		case SOCIAL_PLATFORMS.INSTAGRAM:
		case SOCIAL_PLATFORMS.TWITTER:
			return <i className={`fa fa-${platform}`} />;
		default:
			return null;
	}
};

PlatformIcon.displayName = 'PlatformIcon';

PlatformIcon.propTypes = {
	platform: PropTypes.string,
	stars: PropTypes.number
};

PlatformIcon.defaultProps = {
	stars: 0
};

export default PlatformIcon;
