import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'

import './Box.css';

const Box = ({
	children,
	className,
	isBorderBottomVisible,
	isBorderLeftVisible,
	isBorderRightVisible,
	isBorderTopVisible,
	isRadiusBottomLeftVisible,
	isRadiusBottomRightVisible,
	isRadiusTopLeftVisible,
	isRadiusTopRightVisible,
}) => (
	<div className={classnames([
		'Box',
		className,
		{
			'border-bottom': isBorderBottomVisible,
			'border-left': isBorderLeftVisible,
			'border-right': isBorderRightVisible,
			'border-top': isBorderTopVisible,
			'radius-bottom-left': isRadiusBottomLeftVisible,
			'radius-bottom-right': isRadiusBottomRightVisible,
			'radius-top-left': isRadiusTopLeftVisible,
			'radius-top-right': isRadiusTopRightVisible,
		},
	])}>
		{children}
	</div>
);

Box.displayName = 'Box';

Box.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	isBorderBottomVisible: PropTypes.bool,
	isBorderLeftVisible: PropTypes.bool,
	isBorderRightVisible: PropTypes.bool,
	isBorderTopVisible: PropTypes.bool,
	isRadiusBottomLeftVisible: PropTypes.bool,
	isRadiusBottomRightVisible: PropTypes.bool,
	isRadiusTopLeftVisible: PropTypes.bool,
	isRadiusTopRightVisible: PropTypes.bool,
};

Box.defaultProps = {
	isBorderBottomVisible: true,
	isBorderLeftVisible: true,
	isBorderRightVisible: true,
	isBorderTopVisible: true,
	isRadiusBottomLeftVisible: true,
	isRadiusBottomRightVisible: true,
	isRadiusTopLeftVisible: true,
	isRadiusTopRightVisible: true,
};


export default Box
