import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconExclude = (props) => (
	<Svg
		{...props}
		name="exclude"
		viewBox="0 0 14 14"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(-1.000000, -1.000000)">
			<path
				d="M10,7 L10,8 L8,8 L8,6 L9,6 L9,5 L10,5 L10,4 L11,4 L11,3 L12,3 L12,2 L13,2 L13,1 L15,1 L15,3 L14,3 L14,4 L13,4 L13,5 L12,5 L12,6 L11,6 L11,7 L10,7 Z M14,6 L15,6 L15,8 L13,8 L13,7 L12,7 L12,5 L14,5 L14,6 Z M10,2 L11,2 L11,4 L9,4 L9,3 L8,3 L8,1 L10,1 L10,2 Z M6,1 L6,3 L3,3 L3,13 L13,13 L13,10 L15,10 L15,15 L1,15 L1,1 L6,1 Z"
				fill="inherit"
			/>
		</g>
	</Svg>
);

IconExclude.displayName = 'IconExclude';

IconExclude.propTypes = iconProps;

IconExclude.defaultProps = defaultIconProps;

export default IconExclude;
