export const getFormattedDate = (date) => {
	if (!date) {
		return '';
	}

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const month = months[date.getMonth()];
	const day = date.getDate();

	return `${month} ${day}`;
};
