import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconRemove = (props) => (
	<Svg
		{...props}
		name="remove"
		viewBox="0 0 14 14"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-952.000000, -96.000000)" fill="inherit" fillRule="nonzero">
				<g transform="translate(0.000000, 73.000000)">
					<path
						d="M959,37 C955.134007,37 952,33.8659932 952,30 C952,26.1340068 955.134007,23 959,23 C962.865993,23 966,26.1340068 966,30 C966,33.8659932 962.865993,37 959,37 Z M955,29 L955,31 L963,31 L963,29 L955,29 Z"
					/>
				</g>
			</g>
		</g>
	</Svg>
);

IconRemove.displayName = 'IconRemove';

IconRemove.propTypes = iconProps;

IconRemove.defaultProps = defaultIconProps;

export default IconRemove;
