/*
	Branch
	We use branch to route users to the Chatbooks mobile apps.
	https://docs.branch.io/web/integrate/

	Setup in vendors/branch.js
*/

const init = (branchKey, callback) => {
	if (!window.branch) return;

	window.branch.init(branchKey, callback);
}

const isActive = () => window.hasOwnProperty('branch');

const logout = () => isActive() && window.branch.logout();

const sendSMS = (phone, linkData, options, callback) =>
	isActive() && window.branch.sendSMS(phone, linkData, options, callback);

const setIdentity = (userId) => isActive() && window.branch.setIdentity(userId);

const track = (name, attributes) => isActive() && window.branch.track(name, attributes);

export default {
	init,
	logout,
	sendSMS,
	setIdentity,
	track
};
