
import ssrPrepass from 'react-ssr-prepass'

const defaultContext = {
	reactAsyncBootstrapperRunning: true,
}

export default function asyncBootstrapper(app, options, context = {}) {
	const visitor = (element, instance) => {
		if (
			instance &&
			(typeof instance.asyncBootstrap === 'function' ||
				typeof instance.bootstrap === 'function')
		) {
			return typeof instance.bootstrap === 'function'
				? instance.bootstrap()
				: instance.asyncBootstrap()
		}

		return undefined;
	}

	return ssrPrepass(
		app,
		visitor,
		Object.assign({}, context, defaultContext),
		options,
	)
}