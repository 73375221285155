import deep from 'deep-get-set';
import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';
import { isItemInArray, removeItemFromArray } from '../helpers/arrays/arrays';
import { stackWithIds } from '../helpers/stack/stackWithIds';

export default (state = defaultState[REDUCERS.VIEW], action) => {
	const { type } = action;

	switch (type) {
		case ACTIONS.COLLAGE_MEDIA_SELECTED_INDEX: {
			return {
				...state,
				activeCollageMediaIndex: deep(action, 'mediaIndex')
			};
		}

		case ACTIONS.GROUP_SELECTED:
			return {
				...state,
				groupId: deep(action, 'id')
			};

		case ACTIONS.MODAL_PUSHED_ONTO_MODAL_STACK: {
			const modal = deep(action, 'modal');

			const currentModals = state.modalStack || [];
			const updatedModals = stackWithIds.push(currentModals, modal);

			return !modal.id ? state : {
				...state,
				modalStack: updatedModals
			};
		}

		case ACTIONS.MODAL_POPPED_FROM_MODAL_STACK: {
			const id = deep(action, 'id');

			const currentModals = state.modalStack || [];
			const updatedModals = stackWithIds.pop(currentModals, id);

			return {
				...state,
				modalStack: updatedModals
			};
		}

		case ACTIONS.MOMENTS_DELETE: {
			const momentIds = deep(state, 'momentIds') || [];
			const deletedMomentIds = deep(action, 'momentIds');
			const deleteObj = {};

			deletedMomentIds.forEach((momentId) => {
				deleteObj[momentId] = momentId;
			});

			const updatedMomentIds = momentIds.filter((momentId) => !deep(deleteObj, `${momentId}`));

			return {
				...state,
				momentIds: updatedMomentIds
			};
		}

		case ACTIONS.MOMENT_ACTIVATED: {
			const momentId = deep(action, 'momentId');

			return {
				...state,
				activeMomentId: momentId ? momentId : undefined
			};
		}

		case ACTIONS.MOMENT_SELECTED: {
			const id = deep(action, 'id')
			const momentIds = deep(state, 'momentIds') || [];
			let updatedMomentIds = [];

			if (!isItemInArray(momentIds, id)) {
				updatedMomentIds = [...momentIds, id];
			}

			return {
				...state,
				momentIds: updatedMomentIds
			};
		}

		case ACTIONS.MOMENT_SELECTED_CLEAR:
			return {
				...state,
				momentIds: []
			};

		case ACTIONS.MOMENT_UNSELECTED: {
			const id = deep(action, 'id')
			let momentIds = deep(state, 'momentIds') || [];

			momentIds = removeItemFromArray(momentIds, id);

			return {
				...state,
				momentIds
			};
		}
		default:
			return state;
	}
};
