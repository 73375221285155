import React from 'react';
import PropTypes from 'prop-types';

import Image from './Image';
import PlatformIcon from './PlatformIcon';
import Title from './Title';
import User from './User';
import ConfigurableLink from '../../components/ConfigurableLink/ConfigurableLink';
import './SocialCard.css';

const SocialCard = ({
	analyticEvent,
	avatar,
	children,
	date,
	image: imageUrl,
	name,
	platform,
	stars,
	title: titleProp,
	url,
	username
}) => {
	const cardProps = url ? { path: url, target: '_blank' } : {};

	return (
		<ConfigurableLink
			className={`SocialCard card ${platform}`}
			analyticObj={{
				attr1: url
			}}
			analyticEvent={analyticEvent}
			{...cardProps}
		>
			<div className="grid-x">
				<div className="cell shrink">
					<User name={name} username={username} avatar={avatar} platform={platform} />
				</div>
				<div className="cell auto text-right platform-icon">
					<PlatformIcon platform={platform} stars={stars} />
				</div>
			</div>
			<div className="flex-container flex-dir-row">
				<Image imageUrl={imageUrl} />
				<div className="content flex-grow">
					<Title date={date} platform={platform} title={titleProp} username={username} />
					{children}
				</div>
			</div>
		</ConfigurableLink>
	);
};

SocialCard.displayName = 'SocialCard';

SocialCard.propTypes = {
	analyticEvent: PropTypes.func,
	avatar: PropTypes.string,
	children: PropTypes.node,
	date: (props, propName, componentName) => {
		const prop = props[propName];
		if (prop && (!prop.getMonth || !prop.getDate)) {
			return new Error(`'${componentName}.${propName}' must be a 'Date' object`);
		}
	},
	image: PropTypes.string,
	name: PropTypes.string,
	platform: PropTypes.string,
	stars: PropTypes.number,
	title: PropTypes.string,
	url: PropTypes.string,
	username: PropTypes.string
};

export default SocialCard;
