import PropTypes from 'prop-types';

import { appStringModel, appStringPropType } from './appString.model';

/**
 * Creates a confirmModal data object.
 *
 * @param {object} confirmModal The confirmModal object.
 * @param {string} confirmModal.body An app string or literal text
 * @param {object|string} confirmModal.cancelText An app string or literal text (Optional)
 * @param {string} confirmModal.cancelType The type of button variation (Optional)
 * @param {func} confirmModal.cancelCallback A cancel callback (Optional)
 * @param {object|string} confirmModal.confirmText An app string or literal text (Optional)
 * @param {string} confirmModal.confirmType The type of button variation (Optional)
 * @param {func} confirmModal.confirmCallback A confirm callback
 * @param {string} confirmModal.mobileSize A mobile size class to apply
 * @param {string} confirmModal.size A modal size class to apply
 * @param {string} confirmModal.title An app string or literal text (Optional)
 */
export const confirmModalModel = ({
	body,
	cancelText,
	cancelType,
	cancelCallback,
	confirmText,
	confirmType,
	confirmCallback,
	mobileSize,
	size,
	title,
}) => ({
	body,
	cancelText,
	cancelType,
	cancelCallback,
	confirmText: confirmText || appStringModel({ id: 'web.OK' }),
	confirmType,
	confirmCallback,
	mobileSize,
	size,
	title: title || appStringModel({ id: '' }),
});

export const confirmModalPropType = PropTypes.shape({
	body: appStringPropType,
	cancelText: appStringPropType,
	cancelType: PropTypes.string,
	cancelCallback: PropTypes.func,
	confirmText: appStringPropType,
	confirmType: PropTypes.string,
	confirmCallback: PropTypes.func,
	mobileSize: PropTypes.string,
	size: PropTypes.string,
	title: appStringPropType,
})
