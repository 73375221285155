
export const newUserModel = ({
	email,
	name,
	password,
	personId,
	singleSignOnType,
	token,
}) => ({
	email,
	name,
	password,
	personId,
	singleSignOnType,
	token,
});

export const withPersonId = (newUser, personId) => ({
	...newUser,
	personId,
});