import deep from 'deep-get-set';
import { createSelector } from 'reselect';
import { first, omit } from 'lodash';

import { selectLoadingErrorMap } from './loading.selectors';
import { LOADING_KEYS } from '../actions/loading.actions';
import { ENTITIES } from '../const/entities/entities';
import { REDUCERS } from '../const/reducers.const';
import { isNotNullOrUndefined } from '../helpers/bool/bool';

export const selectPaymentMethodState = (state) => deep(state, `${REDUCERS.PAYMENT_METHOD}`);

export const selectPaymentMethodMap = createSelector(
	selectPaymentMethodState,
	(paymentMethodState) => deep(paymentMethodState, `${ENTITIES.PAYMENT_METHOD}`) || {}
);

export const selectPaymentMethodIds = createSelector(
	selectPaymentMethodState,
	(paymentMethodState) => deep(paymentMethodState, `${ENTITIES.PAYMENT_METHOD_IDS}`) || []
);

export const selectActivePaymentMethodMap = createSelector(
	selectPaymentMethodMap,
	(paymentMethodMap) => omit(paymentMethodMap, (paymentMethod) => !paymentMethod.active)
);

export const selectPaymentMethodArray = createSelector(
	selectPaymentMethodMap,
	selectPaymentMethodIds,
	(paymentMethodMap, paymentMethodIds) => (paymentMethodIds || [])
		.map((paymentMethodId) => paymentMethodMap[paymentMethodId])
		.filter(isNotNullOrUndefined)
);

export const selectPaymentMethodCount = createSelector(
	selectPaymentMethodArray,
	(paymentMethods) => paymentMethods.length
);

export const selectHasPaymentMethod = createSelector(
	selectPaymentMethodCount,
	(count) => count > 0
);

export const selectFirstPaymentMethod = createSelector(
	selectPaymentMethodArray,
	(paymentMethods) => first(paymentMethods)
);

export const selectFirstPaymentMethodId = createSelector(
	selectFirstPaymentMethod,
	(paymentMethod) => deep(paymentMethod, 'id')
);

export const selectSaveCreditCardErrorReason = createSelector(
	selectLoadingErrorMap,
	(errorMap) => deep(errorMap, `${LOADING_KEYS.SAVE_BRAINTREE_PAYMENT_METHOD}`)
);

export const selectSavePayPalErrorReason = createSelector(
	selectLoadingErrorMap,
	(errorMap) => deep(errorMap, `${LOADING_KEYS.SAVE_PAYPAL_PAYMENT_METHOD}`)
);