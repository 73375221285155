import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconGift = (props) => (
	<Svg
		{...props}
		name="gift"
		viewBox="0 0 23 20"
	>
		<path
			d="M20.625 5H18.3566C18.5996 4.52891 18.75 4.00313 18.75 3.4375C18.75 1.54219 17.2078 0 15.3125 0C13.6875 0 12.6363 0.833594 11.2875 2.66914C9.93906 0.833203 8.88789 0 7.26289 0C5.36758 0 3.82539 1.54219 3.82539 3.4375C3.82539 4.00313 3.97578 4.52891 4.21875 5H1.875C0.839453 5 0 5.83945 0 6.875V18.125C0 19.1605 0.839453 20 1.875 20H20.625C21.6605 20 22.5 19.1605 22.5 18.125V6.875C22.5 5.83945 21.6605 5 20.625 5ZM15.3125 1.875C16.1742 1.875 16.875 2.57578 16.875 3.4375C16.875 4.29883 16.1742 5 15.3125 5H11.9504C13.9566 2.01289 14.5176 1.875 15.3125 1.875ZM5.70039 3.4375C5.70039 2.57578 6.40117 1.875 7.26289 1.875C8.0418 1.875 8.61367 2.00273 10.625 5H7.26289C6.40117 5 5.70039 4.29883 5.70039 3.4375ZM8.04922 7.5L6.65391 8.89531C6.40977 9.13945 6.40977 9.53516 6.65391 9.7793L7.0957 10.2211C7.33984 10.4652 7.73555 10.4652 7.97969 10.2211L10.7008 7.5H11.7996L14.5203 10.2207C14.7645 10.4648 15.1602 10.4648 15.4043 10.2207L15.8461 9.77891C16.0902 9.53477 16.0902 9.13906 15.8461 8.89492L14.4508 7.5H20V12.5H2.5V7.5H8.04922ZM2.5 17.5V15H20V17.5H2.5Z"
			fill="inherit"
			stroke="none"
		/>
	</Svg>
);

IconGift.displayName = 'IconGift';

IconGift.propTypes = iconProps;

IconGift.defaultProps = defaultIconProps;

export default IconGift;
