import React from 'react';
import PropTypes from 'prop-types';
import deep from 'deep-get-set';
import { withRouter } from 'react-router-dom';

import PreviewImages from '../PreviewImages/PreviewImages';
import { IconCheckmarkCircled, IconCircleEmpty } from '../../../../chatponents';
import CtaButton from '../../components/CtaButton/CtaButton';
import Titles from '../../components/Titles/Titles';
import InstagramSteps from '../../components/InstagramSteps/InstagramSteps';
import Translate from '../../../../components/Translate/Translate';
import { queryStringToJson } from '../../../../helpers/locationUtils';
import './Instructions.css';

const Instructions = ({
	location,
}) => {
	const locationJson = queryStringToJson(location.search);
	const instagramHandle = deep(locationJson, 'instagramHandle');

	return (
		<section className="padding ig-quick-flow-instructions">
			<Titles
				description="Get excited..."
				title="Your books are ready!"
			/>
			<InstagramSteps
				className="margin-top"
				renderStep1={() => (
					<div className="margin-bottom-small">
						<div className="ig-quick-flow-instructions-bucket-header">
							<IconCheckmarkCircled size="24" />
							<p>
								{instagramHandle && (
									<Translate
										id="web.ig.quickflow.preview.whatsNext.1"
										params={{ username: instagramHandle }}
									/>
								)}
							</p>
						</div>
						<PreviewImages />
					</div>
				)}
				renderStep2={() => (
					<div className="margin-bottom-bigger">
						<div className="ig-quick-flow-instructions-bucket-header">
							<IconCheckmarkCircled size="24" />
							<p>
								<Translate id="web.ig.quickflow.preview.whatsNext.2" />
							</p>
						</div>
					</div>
				)}
				renderStep3={() => (
					<div className="ig-quick-flow-instructions-bucket-header">
						<IconCircleEmpty size="24" color="#88B9FE" />
						<p>
							<Translate id="web.ig.quickflow.preview.whatsNext.3" />
						</p>
					</div>
				)}
			/>
			<CtaButton className="margin-top-big" />
		</section>
	);
};

Instructions.propTypes = {
	location: PropTypes.object,
};

export default withRouter(Instructions);
