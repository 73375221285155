import { apiGetData, apiPost, apiPostRecieveData } from './api.http.actions';
import { BOOK_SIZES } from '../../const/bookSizes.const';
import requireParam from '../../helpers/requireParam';
import requireParams from '../../helpers/requireParams';
import { queryStringFromJson } from '../../helpers/locationUtils';

export const apiGroupsUpgradeBookLayout = (groupId, coverColorId) => (dispatch) => {
	const data = {
		id: groupId,
		coverColorId
	};
	const url = '/groups/UpgradeBookLayout';

	return dispatch(apiPost(url, data));
};

export const postGroupsCreateCustomWithMoments = (title, momentIds, bookSize) => (dispatch) => {
	requireParams({ title, momentIds }, postGroupsCreateCustomWithMoments.name);

	return dispatch(apiPostRecieveData('/groups/createCustomWithMoments', {
		bookSize: bookSize || BOOK_SIZES.SIX_BY_SIX,
		momentIds,
		title,
	}));
};

export const postGroupsToggleHardCover = (groupId, isHardcover, personId) => (dispatch) => {
	requireParam(groupId, 'groupId', 'toggleHardCover');
	requireParam(isHardcover, 'isHardcover', 'toggleHardCover');
	requireParam(personId, 'personId', 'toggleHardCover');

	const data = {
		id: groupId,
		isOn: isHardcover,
		personId,
	};
	const url = '/groups/toggleHardcoverPreview';

	return dispatch(apiPost(url, data));
};

export const postGroupsSetCustomCoverTemplate = (groupId, templateId) => (dispatch) => {
	requireParams({ groupId, templateId }, postGroupsSetCustomCoverTemplate.name);

	return dispatch(apiPost('/groups/setCustomCoverTemplate', {
		groupId,
		templateId,
	}));
};

export const postGroupsSetTitle = (groupId, title) => (dispatch) => {
	return dispatch(apiPost('/groups/SetTitle', {
		id: groupId,
		title,
	}));
};

export const postGroupRemovePerson = (groupId, userId) => (dispatch) => {
	return dispatch(apiPost('/groups/RemovePerson', {
		id: groupId,
		personID: userId,
	}));
};

export const postGroupToggleCaptions = (groupId, isOn) => (dispatch) => {
	return dispatch(apiPost('/groups/ToggleCaptions', {
		id: groupId,
		isOn,
	}));
};

export const postGroupToggleDate = (groupId, isOn) => (dispatch) => {
	return dispatch(apiPost('/groups/ToggleDate', {
		id: groupId,
		isOn,
	}));
};

export const postGroupToggleFrontTitle = (groupId, isOn) => (dispatch) => {
	return dispatch(apiPost('/groups/ToggleFrontTitle', {
		id: groupId,
		isOn,
	}));
};

export const postGroupToggleDisplayVolumeNumber = (groupId, isOn, personId) => (dispatch) => {
	return dispatch(apiPost('/groups/ToggleDisplayVolumeNumber', {
		id: groupId,
		isOn,
		personId,
	}));
};

export const postGroupToggleLocation = (groupId, isOn) => (dispatch) => {
	return dispatch(apiPost('/groups/ToggleLocation', {
		id: groupId,
		isOn,
	}));
};

export const apiGroupsSetCoverColor = (groupId, coverColorId) => (dispatch) => {
	const data = {
		id: groupId,
		coverColorId,
	};
	const url = '/groups/SetCoverColor';

	return dispatch(apiPost(url, data));
};

export const apiGroupsSetBookSize = (groupId, bookSize) => (dispatch) => {
	const data = {
		groupId,
		bookSize,
	};
	const url = '/groups/SetBookSize';

	return dispatch(apiPost(url, data));
};

export const apiGroupsGetBookLayoutOptions = (groupId) => (dispatch) => {
	const query = {
		id: groupId,
	};
	const url = `/groups/BookLayoutOptions?${queryStringFromJson(query)}`;

	return dispatch(apiGetData(url));
};

export const getGroupsBySharableKey = ({ sharableId, sharableKey }) => (dispatch) => {
	return dispatch(apiGetData(`/groups/bySharableKey/${sharableId}/${sharableKey}`));
};

export const getGroupSkinnyByGroupId = (groupId) => (dispatch) => {
	return dispatch(apiGetData(`/groups/skinny/${groupId}`));
};

/**
 * Set cover bundle for a series
 * @param groupId
 * @param coverBundleId
 * @param startAtVolume
 */
export const setCoverBundle = (groupId, coverBundleId, startAtVolume) => (dispatch) => {
	requireParam(groupId, 'groupId', 'setCoverBundle');
	requireParam(coverBundleId, 'coverBundleId', 'setCoverBundle');

	const data = {
		groupId,
		coverBundleId,
		startAtVolume: startAtVolume || 1
	};
	const url = '/groups/SetCoverBundle';

	return dispatch(apiPostRecieveData(url, data));
};

/**
 * Clear cover bundle from a group
 * @param groupId Group ID
 * @returns {*}
 */
export const clearCoverBundle = (groupId) => (dispatch) => {
	requireParam(groupId, 'groupId', 'clearCoverBundle');

	const data = {
		groupId,
	};
	const url = '/groups/ClearCoverBundle';

	return dispatch(apiPostRecieveData(url, data));
};

/**
 * Get a list of available cover previews for a given group
 * @param groupId Group ID
 * @param hardcover Hardcover null|true|false
 * @param filter
 * @returns {*}
 */
export const getAvailableCoverPreviews = (groupId, hardcover, filter) => (dispatch) => {
	requireParam(groupId, 'groupId', 'getAvailableCoverPreviews');

	const query = {
		groupId,
		hardcover,
		filter
	};
	const url = `/groups/GetAvailableCoverPreviews?${queryStringFromJson(query)}`;

	return dispatch(apiGetData(url));
};
