import deep from 'deep-get-set';

import { appStringModel } from '../appString.model';

export const localDataSourceFromJson = (json = {}) => ({
	id: deep(json, 'ID'),
	name: deep(json, 'Name'),
});

export const getName = () => appStringModel({ id: 'web.Local' });

export const getUsername = () => '';
