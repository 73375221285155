import { showThrobber, hideThrobber } from './app.actions';
import { reconnectDataSource } from './dataSource/dataSource.actions';
import * as instagramActions from './instagram.actions';
import { makeInstagramReauthenticateActionUri } from '../helpers/actionUri/actionUri.helpers';
import { queryStringFromJson } from '../helpers/locationUtils';
import { INSTAGRAM_QUICK_REAUTH_URL } from '../routes/instagram-reauth/InstagramQuickReauth';

export const authenticateAndReconnectInstagramDatasources = (history, location) => (dispatch) => {
	const intentActionUri = makeInstagramReauthenticateActionUri();

	return dispatch(instagramActions.authenticate(intentActionUri))
		// The normal desktop flow will continue here, but IG In-App browser will continue in Instagram auth window.
		.then((token) => dispatch(instagramActions.handleAuthIntentActionUri(intentActionUri, token, history, location)))
		.catch((err) => console.error('Failed to authenticate Instagram', err));
};

export const goToInstagramReauthWithToken = (token, history) => () => {
	history.push(`${INSTAGRAM_QUICK_REAUTH_URL}?${queryStringFromJson({ token })}`);

	return Promise.resolve();
};

export const reconnectDatasourcesWithInstagramToken = (token, history) => (dispatch) => {
	dispatch(showThrobber(reconnectDatasourcesWithInstagramToken.name));

	return dispatch(reconnectDataSource(0, undefined, token))
		.then(() => history.push(`${INSTAGRAM_QUICK_REAUTH_URL}?${queryStringFromJson({ success: true, token })}`))
		.catch((err) => {
			history.push(`${INSTAGRAM_QUICK_REAUTH_URL}?${queryStringFromJson({ success: false, token })}`);
			console.error('Failed to authorize Instagram Reconnect', err);
		})
		.finally(() => dispatch(hideThrobber(reconnectDatasourcesWithInstagramToken.name)));
};
