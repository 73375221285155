import React from 'react';
import PropTypes from 'prop-types';

import Stars from '../Stars/Stars';

const Review = ({
	text,
	title,
	username
}) => (
	<li className="instagram-quickflow-page-review-item">
		<p className="review-username">{username}</p>
		<Stars />
		<p className="review-title">{title}</p>
		<p className="review-text">{text}</p>
	</li>
);

Review.propTypes = {
	text: PropTypes.string,
	title: PropTypes.string,
	username: PropTypes.string,
};

Review.displayName = 'Review';

export default Review;
