import Box from '../Box/Box';
import BoxBottom from './BoxBottom/BoxBottom';
import BoxMiddle from './BoxMiddle/BoxMiddle';
import BoxTop from './BoxTop/BoxTop';

export const getStackedBoxComponent = (length, index) => {
	if (length === 1) return Box;
	if (index === 0) return BoxTop;
	if (index === length - 1) return BoxBottom;

	return BoxMiddle;
};