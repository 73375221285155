import React from 'react';

import WindowSize from './WindowSize';

const getDisplayName = (WrappedComponent) => WrappedComponent.displayName || WrappedComponent.name || 'Component';

const withWindowSize = (WrappedComponent) => (props) => {
	withWindowSize.displayName = getDisplayName(WrappedComponent);

	return <WindowSize render={({ height, width }) => <WrappedComponent {...props} windowSize={{ height, width }} />} />;
};

export default withWindowSize;
