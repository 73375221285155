import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCamera = (props) => (
	<Svg
		{...props}
		name="camera"
		viewBox="0 0 120 120"
	>
		<path
			d="M97.5,88.86H28.7a1.18,1.18,0,0,1-1.19-1.18V41.81a1.18,1.18,0,0,1,1.19-1.18H97.5a1.18,1.18,0,0,1,1.18,1.18V87.68A1.18,1.18,0,0,1,97.5,88.86ZM29.88,86.49H96.31V43H29.88Z"
		/>
		<path
			d="M79.51,43H46.69a1.2,1.2,0,0,1-1.08-.69,1.19,1.19,0,0,1,.17-1.26l7.9-9.49a1.19,1.19,0,0,1,.92-.42h17a1.19,1.19,0,0,1,.91.42l7.91,9.49a1.19,1.19,0,0,1-.91,2ZM49.22,40.63H77L71,33.51H55.15Z"
		/>
		<path
			d="M63.1,82.14a17.4,17.4,0,1,1,17.39-17.4A17.41,17.41,0,0,1,63.1,82.14Zm0-32.42a15,15,0,1,0,15,15A15,15,0,0,0,63.1,49.72Z"
		/>
		<path
			d="M63.1,77.53A12.79,12.79,0,1,1,75.88,64.74,12.81,12.81,0,0,1,63.1,77.53Zm0-23.2A10.42,10.42,0,1,0,73.51,64.74,10.43,10.43,0,0,0,63.1,54.33Z"
		/>
		<path
			d="M89,53a3.27,3.27,0,1,1,3.26-3.27A3.28,3.28,0,0,1,89,53Zm0-4.5a1.24,1.24,0,1,0,1.23,1.23A1.24,1.24,0,0,0,89,48.54Z"
		/>
		<path
			d="M39.9,49.32H22.5a1.19,1.19,0,0,1,0-2.37H39.9a1.19,1.19,0,1,1,0,2.37Z"
		/>
		<path
			d="M39.9,53.28H25.67a1.19,1.19,0,1,1,0-2.37H39.9a1.19,1.19,0,1,1,0,2.37Z"
		/>
	</Svg>
);

IconCamera.displayName = 'IconCamera';

IconCamera.propTypes = iconProps;

IconCamera.defaultProps = defaultIconProps;

export default IconCamera;
