import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCaretUp = (props) => (
	<Svg
		{...props}
		name="caret-up"
		viewBox="0 0 24 24"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.1068 14.4204L12.0106 9.32163L6.91433 14.4204C6.61738 14.7095 6.14233 14.7031 5.85329 14.4061C5.56966 14.1147 5.56975 13.6504 5.85349 13.3592L11.4801 7.72967C11.7727 7.4367 12.2475 7.43643 12.5404 7.72907C12.5402 7.72886 12.5407 7.72927 12.5404 7.72907L18.1677 13.3592C18.4578 13.6552 18.453 14.1302 18.157 14.4203C17.8653 14.7061 17.3986 14.7062 17.1068 14.4204Z"
			fill="inherit"
		/>
	</Svg>
);

IconCaretUp.displayName = 'IconCaretUp';

IconCaretUp.propTypes = iconProps;

IconCaretUp.defaultProps = defaultIconProps;

export default IconCaretUp;
