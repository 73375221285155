import hermes from '../helpers/hermes/hermes';

export function triggerCookieUpdatedEvent(cookieName, value) {
  /**
   * This event is needed for 2 reasons.
   *
   * 1. To be received by web-infinite so it can trigger an action
   *    and ensure anything in its selectors get the latest state.
   * 2. `react-cookies` works great. But it provides 2 methods of setting cookies.
   *    For some reason, editing cookies in one API does not trigger the hooks to rerender
   *    with updated values.
   *
   * We can remove when there are no selectors that read cookie state AND when we're
   * ONLY using `react-cookie` hooks.
  */
  hermes.deliver(hermes.EVENTS.COOKIE_UPDATED, {
    key: cookieName,
    value,
  });
}
