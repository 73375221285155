import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconMenu = (props) => (
	<Svg
		{...props}
		name="menu"
		viewBox="0 0 20 20"
	 >
		<rect fill="inherit" width="20" height="4" />
		<rect y="8" fill="inherit" width="20" height="4" />
		<rect y="16" fill="inherit" width="20" height="4" />
	</Svg>
);

IconMenu.displayName = 'IconMenu';

IconMenu.propTypes = iconProps;

IconMenu.defaultProps = defaultIconProps;

export default IconMenu;
