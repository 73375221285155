import React from 'react';
import PropTypes from 'prop-types';

export const Caption = ({ caption }) => {
	if (!caption) return null;

	return <small>{caption}</small>;
};

Caption.propTypes = {
	caption: PropTypes.node
};

export default Caption;
