import deep from 'deep-get-set';

export const facebookDataSourceFromJson = (json = {}) => ({
	albumName: deep(json, 'AlbumName'),
	id: deep(json, 'ID'),
	name: deep(json, 'Name'),
});

export const getName = (dataSource) => {
	const albumName = deep(dataSource, 'albumName');
	const albumNamePart = albumName ? ` / ${albumName}` : '';

	return `Facebook${albumNamePart}`;
};

export const getUsername = (dataSource) => deep(dataSource, 'name');
