import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconThumbsUpKids = (props) => (
	<Svg
		{...props}
		name="thumbs-up-kids"
		viewBox="0 0 120 120"
	>
		<path
			d="M65.43,92.8A37.72,37.72,0,0,1,48,88.41a1.19,1.19,0,1,1,1.15-2.08c7.46,4.12,19.52,6,27.15,1.5,5.06-3,9.81-11.34,11-19.36.83-5.41-.09-9.93-2.58-12.73-2-2.2-5.11-2.77-7.93-3-.62-.06-5.69-.38-8.36-.3a2.28,2.28,0,0,1-2.24-3,43.42,43.42,0,0,0,2-13.19V34a4.22,4.22,0,0,0-3.91-4.45H63.26a4,4,0,0,0-3.75,3.2l-.68,2.63A43,43,0,0,1,52.1,49.85a6.51,6.51,0,0,1-5.29,2.81H41.59a1.19,1.19,0,0,1,0-2.37h5.22a4.17,4.17,0,0,0,3.36-1.83,40.57,40.57,0,0,0,6.36-13.65l.69-2.64a6.4,6.4,0,0,1,6-5h1.08A6.58,6.58,0,0,1,70.62,34v2.23A46,46,0,0,1,68.52,50c2.85-.08,7.87.26,8.52.32,3.28.3,7,1,9.48,3.81,3,3.34,4.09,8.55,3.15,14.67-1.33,8.65-6.56,17.7-12.17,21A23.63,23.63,0,0,1,65.43,92.8Z"
		/>
		<path
			d="M48.55,91.15H31.19A1.19,1.19,0,0,1,30,90V51.48a1.19,1.19,0,0,1,1.19-1.19H48.55a1.19,1.19,0,0,1,1.19,1.19V90A1.19,1.19,0,0,1,48.55,91.15ZM32.38,88.78h15V52.66h-15Z"
		/>
		<path
			d="M88.21,64.39H75.58a1.19,1.19,0,1,1,0-2.37H88.21a1.19,1.19,0,1,1,0,2.37Z"
		/>
		<path
			d="M87.72,73H76.89a1.19,1.19,0,0,1,0-2.37H87.72a1.19,1.19,0,1,1,0,2.37Z"
		/>
		<path
			d="M83.5,81.53H72.07a1.19,1.19,0,1,1,0-2.37H83.5a1.19,1.19,0,1,1,0,2.37Z"
		/>
	</Svg>
);

IconThumbsUpKids.displayName = 'IconThumbsUpKids';

IconThumbsUpKids.propTypes = iconProps;

IconThumbsUpKids.defaultProps = defaultIconProps;

export default IconThumbsUpKids;
