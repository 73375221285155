import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.USER], action) => {
	const { data, type } = action;

	switch (type) {
		case ACTIONS.TOUCH_REDUCERS_TO_TRIGGER_SELECTORS:
			return {
				...state,
			};
		case ACTIONS.AUTH_PASSTHROUGH_INIT:
			return {
				...data
			};
		case ACTIONS.ERROR_ACCOUNT_CREATE: {
			const { status, data: payload } = data;
			const invalid = status === 200 && payload.emailInUse;
			const error = !invalid ? data : null;

			return {
				...state,
				authenticating: false,
				error,
				invalid
			};
		}
		case ACTIONS.ERROR_ACCOUNT_LOGON: {
			const status = deep(data, 'status');
			const userExists = !!deep(data, 'data.userExists');
			const success = !!deep(data, 'data.success');
			const invalid = status === 200;
			const error = (!status || status >= 400 || userExists || !success) ? data : null;

			return {
				...state,
				authenticating: false,
				error,
				invalid
			};
		}
		case ACTIONS.ERROR_REFERRAL_INFO: {
			const currentUser = state.user || {};

			return {
				...state,
				user: {
					...currentUser,
					referrals: null,
					referralsError: data,
					referralsLoading: false
				}
			};
		}
		case ACTIONS.ERROR_REQUEST_RESET_PASSWORD: {
			const status = deep(data, 'status');
			const passwordReset = status === 200;
			const error = !status || status >= 400 ? data : null;

			return {
				...state,
				error,
				passwordReset
			};
		}
		case ACTIONS.ERROR_RESET_PASSWORD:
			return {
				...state,
				error: data,
			};
		case ACTIONS.FETCH_ACCOUNT_CREATE:
		case ACTIONS.FETCH_ACCOUNT_LOGOFF:
		case ACTIONS.FETCH_ACCOUNT_LOGON:
		case ACTIONS.FETCH_TEMPUSER_LOGON:
		case ACTIONS.FETCH_USER_LOGGEDIN:
			return {
				...state,
				authenticating: true,
				error: null,
				invalid: false
			};
		case ACTIONS.FETCH_REQUEST_RESET_PASSWORD:
		case ACTIONS.FETCH_RESET_PASSWORD:
			return {
				...state,
				error: null,
				passwordReset: false
			};
		case ACTIONS.FETCH_REFERRAL_INFO: {
			const currentUser = state.user || {};

			return {
				...state,
				user: {
					...currentUser,
					referrals: null,
					referralsError: null,
					referralsLoading: true
				}
			};
		}
		case ACTIONS.INVALIDATE_TOKEN: {
			return {
				...state,
				accessToken: 'eeeeeeee-eeee-eeee-eeee-eeeeeeeeeeee',
			};
		}
		case ACTIONS.RECEIVE_ACCOUNT_LOGOFF:
			return {
				...state,
				accessToken: null,
				authenticating: false,
				isRegisteredUser: false,
				lang: defaultState.lang,
				user: null
			};
		case ACTIONS.RECEIVE_ACCOUNT_CREATE: {
			const { User: user, access_token: accessToken } = data;
			const isRegisteredUser = !!(user && user.IsFullUser);

			return {
				...state,
				accessToken,
				authenticating: false,
				invalid: false,
				isRegisteredUser,
				user
			};
		}
		case ACTIONS.RECEIVE_PASSWORD_CHANGED: {
			const accessToken = deep(action, 'accessToken');

			return {
				...state,
				accessToken,
			}
		}
		case ACTIONS.RECEIVE_ACCOUNT_LOGON:
		case ACTIONS.RECEIVE_TEMPUSER_LOGON: {
			const { Person: user, DefaultLanguage: lang, access_token: accessToken } = data;
			const isRegisteredUser = !!(user && user.IsFullUser);

			return {
				...state,
				accessToken,
				authenticating: false,
				invalid: false,
				isRegisteredUser,
				lang,
				user
			};
		}
		case ACTIONS.RECEIVE_REFERRAL_INFO: {
			const referrals = deep(data, 'info');
			const currentUser = state.user || {};

			return {
				...state,
				user: {
					...currentUser,
					referrals,
					referralsError: null,
					referralsLoading: false
				}
			};
		}
		case ACTIONS.RECEIVE_REQUEST_RESET_PASSWORD:
		case ACTIONS.RECEIVE_RESET_PASSWORD:
			return {
				...state,
				// The current UI does not show the user logged in, so we don't need to accept the access token.. but we could.
				// accessToken: data.access_token ? data.access_token : state.accessToken,
				passwordReset: true,
			};
		case ACTIONS.RECEIVE_USER_LOGGEDIN: {
			const { User: user, DefaultLanguage: lang } = data;
			const isRegisteredUser = !!(user && user.IsFullUser);

			return {
				...state,
				authenticating: false,
				isRegisteredUser,
				lang,
				user
			};
		}
		case ACTIONS.RESET_LOGIN_STATE: {
			const { authenticating, invalid, passwordReset } = defaultState.user;

			return {
				...state,
				authenticating,
				error: null,
				invalid,
				passwordReset
			};
		}
		case ACTIONS.SET_ACCESS_TOKEN_UNSETTER:
			return {
				...state,
				accessTokenUnsetter: data
			};
		default:
			return state;
	}
};
