import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const coverTemplateSurchargesFromJson = (json = {}) => ({
	custom: deep(json, 'Custom'),
});

export const coverTemplateAttributesFromJson = (json = {}) => ({
	surcharges: coverTemplateSurchargesFromJson(deep(json, 'Surcharges')),
});

export const coverTemplateFromJson = (json = {}) => ({
	id: deep(json, 'Id'),
	attributes: coverTemplateAttributesFromJson(deep(json, 'Attributes')),
	includesPhoto: deep(json, 'IncludesPhoto'),
	isStandardTemplate: deep(json, 'IsStandardTemplate'),
});

export const coverTemplateSchema = new schema.Entity(ENTITIES.COVER_TEMPLATE);

export const normalizeCoverTemplateFilter = (coverTemplates) => normalize(coverTemplates, coverTemplateSchema);
