import { persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import { defaultCookieOptions } from '../cookies';

export const getCookieStorageConfig = (key, blacklist, whitelist, cookieJar) => {

	const options = defaultCookieOptions();

	const storage = new CookieStorage(cookieJar, {
		expiration: {
			default: options.expires,
		},
		setCookieOptions: {
			path: options.path,
			secure: options.secure,
			domain: options.domain,
		},
	});

	return {
		key,
		storage,
		blacklist,
		whitelist
	};
}

export const getLocalStorageConfig = (key, blacklist, whitelist) => ({
	key,
	storage,
	blacklist,
	whitelist
});

export const getSessionStorageConfig = (key, blacklist, whitelist) => ({
	key,
	storage: sessionStorage,
	blacklist,
	whitelist
});

export const withCookies = (cookieJar) => (key, blacklist, whitelist) => (reducer) => {
	const config = getCookieStorageConfig(key, blacklist, whitelist, cookieJar);

	return persistReducer(config, reducer);
};

export const withLocalStorage = (key, blacklist, whitelist) => (reducer) => {
	const config = getLocalStorageConfig(key, blacklist, whitelist);

	return persistReducer(config, reducer);
};

export const withSessionStorage = (key, blacklist, whitelist) => (reducer) => {
	const config = getSessionStorageConfig(key, blacklist, whitelist);

	return persistReducer(config, reducer);
};
