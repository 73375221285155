import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconHelp = (props) => (
	<Svg
		{...props}
		name="help"
		viewBox="0 0 14 14"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-1022.000000, -39.000000)" fill="inherit">
				<path
					d="M1029,53 C1025.13401,53 1022,49.8659932 1022,46 C1022,42.1340068 1025.13401,39 1029,39 C1032.86599,39 1036,42.1340068 1036,46 C1036,49.8659932 1032.86599,53 1029,53 Z M1027.93,48.46 L1027.93,50 L1029.5,50 L1029.5,48.46 L1027.93,48.46 Z M1026.32,45.16 L1027.79,45.16 C1027.79,44.9799991 1027.81,44.8116675 1027.85,44.655 C1027.89,44.4983326 1027.95167,44.3616673 1028.035,44.245 C1028.11833,44.1283328 1028.225,44.0350004 1028.355,43.965 C1028.485,43.8949997 1028.64,43.86 1028.82,43.86 C1029.08667,43.86 1029.295,43.9333326 1029.445,44.08 C1029.595,44.2266674 1029.67,44.4533318 1029.67,44.76 C1029.67667,44.9400009 1029.645,45.0899994 1029.575,45.21 C1029.505,45.3300006 1029.41333,45.4399995 1029.3,45.54 C1029.18667,45.6400005 1029.06333,45.7399995 1028.93,45.84 C1028.79667,45.9400005 1028.67,46.0583327 1028.55,46.195 C1028.43,46.3316674 1028.325,46.4966657 1028.235,46.69 C1028.145,46.8833343 1028.09,47.1233319 1028.07,47.41 L1028.07,47.86 L1029.42,47.86 L1029.42,47.48 C1029.44667,47.279999 1029.51167,47.113334 1029.615,46.98 C1029.71833,46.846666 1029.83667,46.7283339 1029.97,46.625 C1030.10333,46.5216662 1030.245,46.4183339 1030.395,46.315 C1030.545,46.2116662 1030.68167,46.0866674 1030.805,45.94 C1030.92833,45.7933326 1031.03167,45.6166677 1031.115,45.41 C1031.19833,45.2033323 1031.24,44.9400016 1031.24,44.62 C1031.24,44.4266657 1031.19833,44.2183345 1031.115,43.995 C1031.03167,43.7716656 1030.89333,43.5633343 1030.7,43.37 C1030.50667,43.1766657 1030.25167,43.0150007 1029.935,42.885 C1029.61833,42.7549994 1029.22334,42.69 1028.75,42.69 C1028.38333,42.69 1028.05167,42.7516661 1027.755,42.875 C1027.45833,42.998334 1027.205,43.1699989 1026.995,43.39 C1026.785,43.6100011 1026.62167,43.8699985 1026.505,44.17 C1026.38833,44.4700015 1026.32667,44.7999982 1026.32,45.16 Z"
				/>
			</g>
		</g>
	</Svg>
);

IconHelp.displayName = 'IconHelp';

IconHelp.propTypes = iconProps;

IconHelp.defaultProps = defaultIconProps;

export default IconHelp;
