export const MEDIA_UPLOAD_TYPE = {
	OTHER: 0,
	MANUAL: 1,
	FAVORITE: 2,
	PHOTO_EXTENSION: 3,
	CHINDER: 4,
	AUTO_ADD_ANDROID: 5,
	APPROVE_ADD_ANDROID: 6,
	FACEBOOK: 7,
	INSTAGRAM: 8,
	GOOGLE: 9,
	DROPBOX: 10,
	FACEBOOK_ROXIE: 11,
	GOOGLE_ROXIE: 12,
	FLICKR: 13,
	SMUG_MUG: 14,
	WESTERN_DIGITAL: 15,
	SUGGESTED_HAT: 16,
	TINYBEANS: 17,
};
