import React from 'react';
import PropTypes from 'prop-types';

import MaybeLinkWrap from '../../MaybeLinkWrap/MaybeLinkWrap';
import { convertActionUri, convertImageUrl } from '../DuploBlock.helpers';

const FullWidthImage = ({
	actionUri,
	analyticEvent,
	analyticObj,
	fullWidthImage,
}) => (
	<div className="duplo-block duplo-block-full-width-image">
		<MaybeLinkWrap
			analyticEvent={analyticEvent}
			analyticObj={analyticObj}
			path={actionUri && convertActionUri(actionUri)}
		>
			<img
				src={convertImageUrl(fullWidthImage)}
				alt=""
				className="gallery-image"
			/>
		</MaybeLinkWrap>
	</div>
);

FullWidthImage.propTypes = {
	actionUri: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	fullWidthImage: PropTypes.string,
};

export default FullWidthImage;
