import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { LOAD_STATUS } from '../const/loadStatus.const';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.LOADING], action) => {
	const { key, type } = action;

	switch (type) {
		case ACTIONS.LOADING_ERROR: {
			const { err } = action;

			return {
				...state,
				error: {
					...state.error,
					[key]: err
				},
				status: {
					...state.status,
					[key]: LOAD_STATUS.ERROR,
				},
			};
		}
		case ACTIONS.LOADING_CLEAR_ERRORS: {
			return {
				...state,
				error: {},
			}
		}
		case ACTIONS.LOADING: {

			const newStatus = deep(state, `status.${key}`) ? LOAD_STATUS.LOADING : LOAD_STATUS.INITIALIZING;

			return {
				...state,
				status: {
					...state.status,
					[key]: newStatus,
				},
			}
		}
		case ACTIONS.LOADING_SUCCESS: {
			return {
				...state,
				status: {
					...state.status,
					[key]: LOAD_STATUS.SUCCESS,
				},
			}
		}
		default:
			return state;
	}
};
