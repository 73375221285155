import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

const IconEmptyCart = (props) => (
	<span className="IconEmptyCart">
		<Svg
			{...props}
			hasStroke={false}
			viewBox="0 0 36 36"
			name="emptyCart"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 7C3 6.44772 3.44772 6 4 6H7H8H8.1802C8.65688 6 9.06729 6.33646 9.16078 6.80388L11.6 19H26.3333L27.6667 11H15C14.4477 11 14 10.5523 14 10C14 9.44772 14.4477 9 15 9H28.8356H29V9.01355C29.5258 9.10129 29.8996 9.6027 29.8086 10.1486L28.1393 20.1644C28.0589 20.6466 27.6417 21 27.1529 21H27H26H12H11.5H10.8198C10.3431 21 9.93271 20.6635 9.83922 20.1961L7.4 8H4C3.44772 8 3 7.55228 3 7ZM16 25.5C16 26.8807 14.8807 28 13.5 28C12.1193 28 11 26.8807 11 25.5C11 24.1193 12.1193 23 13.5 23C14.8807 23 16 24.1193 16 25.5ZM24.5 28C25.8807 28 27 26.8807 27 25.5C27 24.1193 25.8807 23 24.5 23C23.1193 23 22 24.1193 22 25.5C22 26.8807 23.1193 28 24.5 28Z"
				fill="inherit"
			/>
		</Svg>
	</span>
);

IconEmptyCart.displayName = 'IconEmptyCart';

IconEmptyCart.propTypes = iconProps;

IconEmptyCart.defaultProps = defaultIconProps;

export default IconEmptyCart;
