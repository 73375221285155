import PropTypes from 'prop-types';

export default {
	className: PropTypes.string,
	color: PropTypes.string,
	hasStroke: PropTypes.bool,
	onClick: PropTypes.func,
	size: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	style: PropTypes.object,
};
