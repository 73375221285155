import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Carousel, CarouselButton, CarouselContainer, CarouselItem, CarouselPagination } from '../Carousel';
import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import PressItem from './PressItem/PressItem';
import PressLogoBar from './PressLogoBar/PressLogoBar';
import { SCROLL_DIRECTION } from '../../const/scrollDirection';
import './PressCarousel.css';

const getCarouselItems = (children) =>
	React.Children.map(children, (child, index) => (
		<CarouselItem key={`PressCarousel-CarouselItem-${index}`}>{child}</CarouselItem>
	));

const getLogos = (children) =>
	React.Children.map(children, (child, index) => (
		<img key={`press-logo-img-${index}`} src={child.props.logo} alt={child.props.publisher} />
	));

const PressCarousel = ({ children, isInset, duration, interval, paddingBottom, paddingTop, title: titleText }) => {
	const items = getCarouselItems(children);
	const logos = getLogos(children);
	const title = titleText ? <h3 className="text-center">{titleText}</h3> : null;
	const className = classnames('PressCarousel');

	return (
		<PageFlowContainer isInset={isInset}>
			<div className={className}>
				<CarouselContainer className="max-width-component" paddingBottom={paddingBottom} paddingTop={paddingTop}>
					{title}
					<div className="press-carousel-section">
						<Carousel duration={duration} interval={interval}>
							{items}
						</Carousel>
						<CarouselButton direction={SCROLL_DIRECTION.LEFT} />
						<CarouselButton direction={SCROLL_DIRECTION.RIGHT} />
					</div>
					<PressLogoBar className="hide-for-small-only">{logos}</PressLogoBar>
					<CarouselPagination className="text-center" />
				</CarouselContainer>
			</div>
		</PageFlowContainer>
	);
};

PressCarousel.displayName = 'PressCarousel';

PressCarousel.propTypes = {
	children: (props, propName, componentName) => {
		const prop = props[propName];
		let error = null;
		React.Children.forEach(prop, (child) => {
			if (child.type !== PressItem) {
				error = new Error(`${componentName}' can only have children of type 'PressItem'`);
			}
		});

		return error;
	},
	duration: PropTypes.number,
	interval: PropTypes.number,
	isInset: PropTypes.bool,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	title: PropTypes.string
};

PressCarousel.defaultProps = {
	duration: 500,
	interval: 8000,
	isInset: false
};

export default PressCarousel;
