import { apiGetData } from './api.http.actions';
import { queryStringFromJson, queryStringPartFromJson } from '../../helpers/locationUtils';
import requireParams from '../../helpers/requireParams';

export const getCardTemplates = (props) => (dispatch) => {
	const propObj = {
		...props
	};
	delete propObj.numImages;
	const queryParams = queryStringFromJson(propObj);
	const numImagesString = props.numImages
		? props.numImages.reduce((total, number) => `${total}&numImages=${number}`, '')
		: '';
	const paramsString = `${queryParams}${numImagesString}`;
	const url = `/cardtemplates?${paramsString}`;

	return dispatch(apiGetData(url))
		.then((data) => data.cardTemplates);
};

export const getCardTemplateOrientationSet = (cardId) => (dispatch) => {
	const url = `/cardtemplates/${cardId}/orientationset`;

	return dispatch(apiGetData(url));
};

export const getCardTemplatesFilters = ({
	cat = '',
	nestCategories = false,
} = {}) => (dispatch) => {
	const queryParams = {};

	if (cat) queryParams.parentCategoryId = cat;
	if (nestCategories) queryParams.nestCategories = nestCategories;

	return dispatch(apiGetData(`${'/cardtemplates/filters'}${queryStringPartFromJson(queryParams)}`));
};

export const getCardTemplateById = (id) => (dispatch) => {
	requireParams({ id }, getCardTemplateById.name);

	return dispatch(apiGetData(`/cardtemplates/${id}`));
};
