import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getImage } from '../multiColumnHelper';
import Icon from '../../Icon/Icon';

import './ExpandableColumn.css';

class ExpandableColumn extends Component {
	constructor(props) {
		super(props);

		this.state = { expanded: false };

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.setState((prevState) => ({ expanded: !prevState.expanded }));
	}

	render() {
		const { children, color, imageSize, imageText, imageUrl, title } = this.props;
		const { expanded } = this.state;
		const image = getImage(imageUrl, imageText, 'column-header-image show-for-medium', imageSize);
		const className = classnames('ExpandableColumn', expanded ? 'expanded' : null);

		return (
			<div className={className} style={{ color }}>
				{image}
				<h5 onClick={this.handleClick}>
					<span>{title}</span>
					<span className="expand-button show-for-small-only">
						<Icon name="chevron-down" />
					</span>
				</h5>
				<div className="content">{children}</div>
			</div>
		);
	}
}

ExpandableColumn.displayName = 'ExpandableColumn';

ExpandableColumn.propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	imageSize: PropTypes.string,
	imageText: PropTypes.string,
	imageUrl: PropTypes.string,
	title: PropTypes.string
};

export default ExpandableColumn;
