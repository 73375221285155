import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import './RichTextArea.css';

const RichTextArea = ({ isInset, className: classNameProps, content, paddingBottom, paddingTop, style }) => {
	if (!content) return null;

	const className = classnames('RichTextArea', classNameProps);

	return (
		<PageFlowContainer
			className={className}
			isInset={isInset}
			paddingTop={paddingTop}
			paddingBottom={paddingBottom}
			style={style}
		>
			{content}
		</PageFlowContainer>
	);
};

RichTextArea.displayName = 'RichTextArea';

RichTextArea.propTypes = {
	isInset: PropTypes.bool,
	className: PropTypes.string,
	content: PropTypes.node,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	style: PropTypes.object
};

RichTextArea.defaultProps = {
	isInset: false
};

export default RichTextArea;
