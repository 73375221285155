import { useCallback, useEffect, useState } from 'react';

export default ({
	onQuickClicks,
	requiredClicks,
}) => {
	const [clicks, setClicks] = useState(0);
	const [clickTimer, setClickTimer] = useState(null);

	useEffect(() => {
		return () => clickTimer && clearTimeout(clickTimer);
	}, [clickTimer]);

	const handleClick = useCallback(() => {
		if (clickTimer) clearTimeout(clickTimer);

		const newClicks = clicks + 1;

		if (newClicks >= requiredClicks) {
			onQuickClicks && onQuickClicks();
			setClicks(0);
		} else {
			setClicks(clicks + 1);
		}

		setClickTimer(setTimeout(() => setClicks(0), 250));
	}, [clicks, clickTimer, onQuickClicks, requiredClicks]);

	return {
		handleClick,
	};
};
