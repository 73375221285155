import { queryStringFromJson } from '../../helpers/locationUtils';
import { apiGetData, apiPostRecieveData } from './api.http.actions';

export const getSavedCodesForUser = ({ productId, bookCreationModelType }) => (dispatch) => {
	return dispatch(apiGetData(`/couponCodes/savedForUser?${queryStringFromJson({
		productId,
		bookCreationModelType,
	})}`));
};

export const postCouponCodeAddToUser = (couponCodeId) => (dispatch) => {
	return dispatch(apiPostRecieveData('/couponCodes/addToUser', { couponCodeId }));
};
