import React, { Component } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import Hammer from 'hammerjs';
import classnames from 'classnames';

import { sortPages } from './carouselHelper';
import CarouselItem from './CarouselItem/CarouselItem';
import withCarouselContext from './withCarouselContext';
import { SCROLL_DIRECTION } from '../../const';
import './Carousel.css';

class Carousel extends Component {
	constructor(props) {
		super(props);

		this.itemsRef = React.createRef();
		this.placeholderRef = React.createRef();

		this.next = this.next.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.handleSwipeLeft = this.handleSwipeLeft.bind(this);
		this.handleSwipeRight = this.handleSwipeRight.bind(this);
	}

	componentDidMount() {
		this.setPages();

		// Configure Hammer for touch (swipe) events
		this.hammer = new Hammer.Manager(this.itemsRef.current, {
			recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]]
		});
		this.hammer.on('swipeleft', this.handleSwipeLeft);
		this.hammer.on('swiperight', this.handleSwipeRight);

		// Add event listener for window resize to recalculate page size
		window.addEventListener('resize', this.handleResize);

		// Start timer
		const { interval, onStartTimer } = this.props;
		onStartTimer(this.next, interval);
	}

	componentDidUpdate(prevProps, prevState) {
		const { pageNumber: prevPageNumber } = prevProps.context;
		const { autoScroll, pageNumber } = this.props.context;
		if (prevPageNumber === pageNumber) {
			return;
		}

		this.setScrollPosition().then(() => {
			if (autoScroll) {
				const { interval, onStartTimer } = this.props;
				onStartTimer(this.next, interval);
			}
		});
	}

	componentWillUnmount() {
		if (this.hammer) {
			this.hammer.off('swipeleft', this.handleSwipeLeft);
			this.hammer.off('swiperight', this.handleSwipeRight);
		}
		window.removeEventListener('resize', this.handleResize);
	}

	getCarouselItems() {
		const { children: childrenProp } = this.props;
		const { pageNumber, pageSize, prevPageNumber, scrollDirection } = this.props.context;
		const children = React.Children.toArray(childrenProp);
		const items = children.concat(
			// Add placeholders so we get consistent spacing
			new Array(pageSize - (children.length % pageSize)).fill(<CarouselItem />)
		);

		return sortPages(
			items.map((child, index) => React.cloneElement(child, { key: `CarouselItem-${index}` })),
			prevPageNumber,
			pageNumber,
			pageSize,
			scrollDirection
		);
	}

	goTo(page, forceChange = false) {
		const { onGoToPage } = this.props;
		onGoToPage(page, forceChange);
	}

	handleClick(index) {
		this.goTo(index, true);
	}

	handleResize() {
		const { onUpdateContext } = this.props;
		onUpdateContext(
			{
				pageCount: 0
			},
			this.setPages.bind(this)
		);
	}

	handleSwipeLeft() {
		const { pageCount, pageNumber } = this.props.context;
		const page = pageNumber + 1;
		if (page < pageCount) {
			this.goTo(page, true);
		}
	}

	handleSwipeRight() {
		const { pageNumber } = this.props.context;
		const page = pageNumber - 1;
		if (page >= 0) {
			this.goTo(page, true);
		}
	}

	next() {
		const { pageCount, pageNumber } = this.props.context;
		if (pageCount <= 1) {
			return;
		}
		const page = (pageNumber + 1) % pageCount;
		this.goTo(page, false);
	}

	setPages() {
		const { onUpdateContext } = this.props;
		const container = this.itemsRef.current;
		const placeholder = this.placeholderRef.current;

		return new Promise((resolve) => {
			const { children } = this.props;
			const { offsetWidth: containerWidth } = container;
			const { offsetWidth: itemWidth } = placeholder;
			const pageSize = itemWidth ? Math.round(containerWidth / itemWidth) : 1;
			const pageCount = pageSize ? Math.ceil(React.Children.count(children) / pageSize) : 1;
			onUpdateContext(
				{
					pageCount,
					pageNumber: 0,
					pageSize,
					scrollIncrement: containerWidth
				},
				resolve
			);
		});
	}

	setScrollPosition() {
		const { scrollIncrement, scrollDirection } = this.props.context;
		const { duration, easing } = this.props;
		const container = this.itemsRef.current;
		const currentScrollLeft = scrollDirection === SCROLL_DIRECTION.RIGHT ? 0 : scrollIncrement;
		const scrollLeft = scrollDirection === SCROLL_DIRECTION.RIGHT ? scrollIncrement : 0;

		container.scrollLeft = currentScrollLeft;

		return anime({
			targets: container,
			scrollLeft,
			duration,
			easing,
			loop: false
		}).finished;
	}

	render() {
		const { className: classNameProp, context } = this.props;
		const { pageCount } = context;
		const items = !pageCount ? <div ref={this.placeholderRef} className="CarouselItem" /> : this.getCarouselItems();
		const className = classnames('Carousel', classNameProp);

		return (
			<div className={className}>
				<div ref={this.itemsRef} className="carousel-items">
					<div className="flex-container flex-dir-row">{items}</div>
				</div>
			</div>
		);
	}
}

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
	children: (props, propName, componentName) => {
		const prop = props[propName];
		let error = null;
		React.Children.forEach(prop, (child) => {
			if (child.type !== CarouselItem) {
				error = new Error(`'${componentName}' children should be of type 'CarouselItem'`);
			}
		});

		return error;
	},
	className: PropTypes.string,
	context: PropTypes.object,
	duration: PropTypes.number,
	easing: PropTypes.string,
	interval: PropTypes.number,
	onGoToPage: PropTypes.func,
	onStartTimer: PropTypes.func,
	onUpdateContext: PropTypes.func
};

Carousel.defaultProps = {
	duration: 500,
	easing: 'easeInOutSine',
	interval: 5000
};

export default withCarouselContext(Carousel);
