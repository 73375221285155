import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconMinusThin = (props) => (
	<Svg
		{...props}
		name="minus-thin"
		viewBox="0 0 15 3"
	>
		<path
			d="M1.23438 2.66504H13.9609C14.4971 2.66504 14.9541 2.2168 14.9541 1.66309C14.9541 1.11816 14.4971 0.661133 13.9609 0.661133H1.23438C0.71582 0.661133 0.241211 1.11816 0.241211 1.66309C0.241211 2.2168 0.71582 2.66504 1.23438 2.66504Z"
			fill="inherit"
		/>
	</Svg>
);

IconMinusThin.displayName = 'IconMinusThin';

IconMinusThin.propTypes = iconProps;

IconMinusThin.defaultProps = defaultIconProps;

export default IconMinusThin;
