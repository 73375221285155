import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FooterSections from './FooterSections';
import FooterLinksSection from './FooterLinksSection';
import './CommonFooter.css';

const CommonFooter = ({ backgroundColor, base, children: childrenProp, className, logo, social }) => {
	const children = childrenProp.hasOwnProperty('length') ? childrenProp : [childrenProp];
	const sectionArray = [...children, social];
	const style = backgroundColor ? { backgroundColor } : null;

	return (
		<footer className={classnames('CommonFooter', className)} style={style}>
			<div className="footer-content">
				{logo}
				<FooterSections sectionArray={sectionArray} />
			</div>
			{base}
		</footer>
	);
};

CommonFooter.displayName = 'CommonFooter';

CommonFooter.propTypes = {
	backgroundColor: PropTypes.string,
	base: PropTypes.node,
	children: (props, propsName, componentName) => {
		let error = null;
		React.Children.forEach((prop, child) => {
			if (child.type !== FooterLinksSection) {
				error = new Error(`'${componentName}' children should be of type 'FooterLinksSection'`);
			}
		});

		return error;
	},
	className: PropTypes.any,
	logo: PropTypes.node,
	social: PropTypes.node
};

CommonFooter.defaultProps = {
	children: []
};

export default CommonFooter;
