import axios from 'axios';
import deep from 'deep-get-set';

import * as OAuth2 from '../oauth2/oauth2';
import { instagramCaptionToTagsArray } from '../../helpers/instagram/instagram.helpers';
import * as InstagramResponseModel from '../../model/dataSource/media/instagram/instagramMediaResponse.model';
import * as InstagramMediaItemResponseModel from '../../model/dataSource/media/instagram/instagramMediaItemResponse.model';
import { imageOnlyFilter } from '../../model/dataSource/media/instagram/instagramMediaItemResponse.model';

const INSTAGRAM_GRAPH_URL = 'https://graph.instagram.com';

/**
 * Opens an Instagram authentication window.
 * Sets up callback functions on this window so the child window can communicate back.
 *
 * @param {string} url The Instagram authorization url to use.
 */
export const authenticate = (url) => {
	return OAuth2.authenticate(url);
};

const getMedia = ({ count, pagination, token }) => {
	const childFields = 'id,media_type,media_url,timestamp';
	const fields = `id,media_type,media_url,caption,timestamp,children{${childFields}}`;
	const fieldsParam = `&fields=${fields}`;

	const after = deep(pagination, 'next');
	const afterParam = after ? `&after=${after}` : '';

	const limitParam = `&limit=${count}`;

	const url = `${INSTAGRAM_GRAPH_URL}/me/media?access_token=${token}${fieldsParam}${afterParam}${limitParam}`;

	return axios.get(url)
		.then((resp) => InstagramResponseModel.instagramMediaResponseFromJson(resp.data))
};

export const flattenCarousel = (carousel) => {
	return (carousel.children || []).reduce((soFar, carouselItem) => {
		const tags = carousel.caption ? instagramCaptionToTagsArray(carousel.caption) : undefined;
		const itemCopy = {
			...carouselItem,
			tags
		};

		if (itemCopy.mediaUrl === carousel.mediaUrl) itemCopy.caption = carousel.caption;

		return InstagramMediaItemResponseModel.isImage(itemCopy)
			? [ ...soFar, itemCopy ]
			: soFar;
	}, []);
};

export const flattenCarousels = (media) => {
	return (media || []).reduce((soFar, item) => {
		if (InstagramMediaItemResponseModel.isCarousel(item)) {
			return [ ...soFar, ...flattenCarousel(item) ];
		} else {
			return [ ...soFar, item ];
		}
	}, []);
};

export const getPhotos = ({ count, token, pagination }) => {
	return getMedia({ count, token, pagination })
		.then((instagramMedia) => {
			const { media, ...rest } = instagramMedia;

			const withFlattenedCarousels = flattenCarousels(media);
			const withOnlyImages = withFlattenedCarousels.filter(imageOnlyFilter);

			return {
				media: withOnlyImages,
				...rest
			};
		})
};

export const getInstagramUser = ({ token }) => {
	return axios.get(`${INSTAGRAM_GRAPH_URL}/me?access_token=${token}&fields=username`)
		.then((resp) => {
			if (resp.status !== 200) throw new Error('Failed to load Instagram User');

			return deep(resp, 'data');
		});
};
