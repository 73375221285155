import deep from 'deep-get-set';

import * as checkoutAnalyticsActions from './checkout.analytics.actions';
import * as ACTIONS from '../actionTypes';
import * as addressActions from '../address.actions';
import * as api from '../api/api.actions';
import { loadApiSettings } from '../apiSettings/apiSettings.actions';
import { showThrobber, hideThrobber } from '../app.actions';
import { setCheckoutSourceCookie, setAppCookie } from '../appCookie.actions';
import { maybeOpenLoginPage } from '../user.actions';
import { loadBooksSkinny } from '../book.actions';
import { receiveEntities } from '../entity.actions';
import { loadGroupCoverBundle } from '../groups.actions';
import { loadingError, loadingInit, LOADING_KEYS, loadingSuccess, loadingClearErrors } from '../loading.actions';
import { loadPaymentMethods } from '../paymentMethod.actions';
import { loadUpgradePricing } from '../pricing.actions';
import { loadProductPriceTiers } from '../product.actions';
import { openModalById } from '../view.actions';
import { CHECKOUT_STEP_LOCATION_MAP, CHECKOUT_STEPS } from '../../const/checkoutSteps.const';
import { CHECKOUT_TYPES } from '../../const/checkoutTypes.const';
import { ENTITIES } from '../../const/entities/entities';
import { MODAL_NAMES } from '../../const/modalNames.const';
import { getVolumeFromBookId } from '../../helpers/books/books';
import { isGroupCoverADesignerBundle, isGroupTypeOfSeries, isGroupTypeOfCards, getGroupProductFilter } from '../../helpers/groups/groups';
import { addressSelectionModalModel } from '../../model/addressSelectionModal.model';
import { cartFromJson, normalizeCart } from '../../model/cart.model';
import { codeFromJson } from '../../model/code.model';
import { couponCodesFromJsonArray, normalizeCouponCodes } from '../../model/couponCode.model';
import { giftNoteLayoutFromJson } from '../../model/giftNoteLayout.model';
import { modalModel } from '../../model/modal.model';
import { normalizeOrderOptions, orderOptionsFromJson } from '../../model/orderOptions.model';
import { normalizeShippingOptionsViewResponse } from '../../model/shippingOptionsViewResponse.model';
import { selectIsRegisteredUser } from '../../selectors/user.selectors';
import {
	selectCartItemCount,
	selectCartItemIdToRemove,
	selectCartItems,
	selectCartItemsAreShippable,
	selectCartNextStepLocation,
	selectCartPaymentMethodIdOrFirstPaymentMethodId,
	selectCartPriceAfterShippingMethodChangeCookieKey,
	selectCartSubtotal,
	selectDefaultCheckoutAddressId,
	selectHasRequiredCheckoutInfo,
	selectIsCartPriceDifferentSinceShippingMethodChange,
	selectMaybeFasterCheaperGrade,
	selectCartId,
} from '../../selectors/checkout.selectors';
import { selectGroupMap } from '../../selectors/groups.selectors';
import { selectSherpa } from '../../selectors';

const applyCouponError = (err) => ({ type: ACTIONS.APPLY_COUPON_CODE_ERROR, err });
const applyCouponInit = () => ({ type: ACTIONS.APPLY_COUPON_CODE_INIT });
const applyCouponSuccess = () => ({ type: ACTIONS.APPLY_COUPON_CODE_SUCCESS });
const clearCouponError = (err) => ({ type: ACTIONS.CLEAR_COUPON_CODE_ERROR, err });
const clearCouponInit = () => ({ type: ACTIONS.CLEAR_COUPON_CODE_INIT });
const clearCouponSuccess = (cartId) => ({ type: ACTIONS.CLEAR_COUPON_CODE_SUCCESS, cartId });
const loadGiftNoteLayoutSuccess = (giftNoteLayout) => ({ type: ACTIONS.LOAD_GIFT_NOTE_LAYOUT_SUCCESS, data: { giftNoteLayout } });
const loadGiftNoteWrappedTextSuccess = (giftNoteFormatted) => ({ type: ACTIONS.LOAD_GIFT_NOTE_WRAPPED_TEXT_SUCCESS, data: { giftNoteFormatted } });
const loadShoppingCartError = (err) => ({ type: ACTIONS.LOAD_SHOPPING_CART_ERROR, err });
const setCartId = (cartId) => ({ type: ACTIONS.SET_CART_ID, data: { cartId } });
export const setCartItemForRemoval = (itemId) => ({ type: ACTIONS.SET_CART_ITEM_FOR_REMOVAL, data: { itemId } });
export const unsetCartItemForRemoval = () => ({ type: ACTIONS.UNSET_CART_ITEM_FOR_REMOVAL });

export const applyCouponCode = (codeString) => (dispatch) => {
	dispatch(showThrobber(applyCouponCode.name));
	dispatch(applyCouponInit());

	return dispatch(testCouponCode(codeString))
		.then(({ couponCode }) => dispatch(api.putCartCouponCode(couponCode.id)))
		.then(() => dispatch(applyCouponSuccess()))
		.then(() => dispatch(loadActiveCart()))
		.catch((err) => dispatch(applyCouponError(err)))
		.finally(() => dispatch(hideThrobber(applyCouponCode.name)));
};

export const armPromoSuggestions = () => (dispatch, getState) => {
	const cartItemCount = selectCartItemCount(getState());
	const suggestionMultiplier = 2;

	return (cartItemCount === 0)
		? Promise.resolve()
		: dispatch(api.getPromoSuggestions(cartItemCount * suggestionMultiplier))
			.then((suggestions) => {
				return Promise.all(suggestions.map((suggestion) => dispatch(api.putPromoSuggestions(suggestion.PromoId))));
			});
};

export const calculateGiftNoteLines = (giftNoteText, font, width, fontSize) => (dispatch) => {
	return dispatch(api.getWrappedText(giftNoteText, font, width, fontSize))
		.then((response) => dispatch(loadGiftNoteWrappedTextSuccess(response.data)));
};

export const cartIconClicked = () => (dispatch, getState) => {
	window.location.href = selectSherpa(getState()).cart();
};

export const cartItemQuantityChanged = (itemId, quantity) => (dispatch) => {
	if (quantity === 0) {
		return dispatch(setCartItemForRemoval(itemId));
	}

	dispatch(showThrobber(cartItemQuantityChanged.name));

	return dispatch(api.putCartItemQuantity(itemId, quantity))
		.then(() => dispatch(loadActiveCart()))
		.finally(() => dispatch(hideThrobber(cartItemQuantityChanged.name)));
};

export const clearCoupon = () => (dispatch, getState) => {
	dispatch(showThrobber(clearCoupon.name));
	dispatch(clearCouponInit());

	return dispatch(api.deleteCartCoupon())
		.then(() => dispatch(clearCouponSuccess(selectCartId(getState()))))
		.then(() => dispatch(loadActiveCart()))
		.catch((err) => dispatch(clearCouponError(err)))
		.finally(() => dispatch(hideThrobber(clearCoupon.name)));
};

export const giftNoteChanged = (giftNote) => (dispatch) => {
	return dispatch(api.putCartGiftNote(giftNote))
		.then(() => dispatch(loadActiveCart()));
};

export const initializeShoppingCart = () => (dispatch) => {
	dispatch(showThrobber(initializeShoppingCart.name));
	dispatch(checkoutAnalyticsActions.tagShoppingCartLoad());

	return Promise.all([
		dispatch(createOrRecalculateCart()),
		dispatch(loadGiftNoteLayout()),
		dispatch(loadUpgradePricing()),
	])
		.then(() => dispatch(synchronizeCartItemProducts()))
		.finally(() => dispatch(hideThrobber(initializeShoppingCart.name)));
};

export const loadActiveCart = () => (dispatch) => {
	return dispatch(api.getActiveCart())
		.then((data) => dispatch(loadShoppingCartSuccess(data)))
		.catch((err) => dispatch(loadShoppingCartError(err)));
};

export const loadSavedCodesForUser = ({ productId, bookCreationModelType }) => (dispatch) => {
	return dispatch(api.getSavedCodesForUser({ productId, bookCreationModelType }))
		.then(({ couponCodes }) => couponCodes)
		.then((couponCodesJson) => {

			const codes = couponCodesFromJsonArray(couponCodesJson);
			const normalized = normalizeCouponCodes(codes);

			const entities = {
				...normalized.entities,
				[ENTITIES.COUPON_CODE_IDS]: normalized.result,
			};

			dispatch(receiveEntities(entities, loadSavedCodesForUser.name));
		});
};

export const loadGiftNoteLayout = () => (dispatch) => {
	return dispatch(api.getGiftNoteTextLayoutValues())
		.then((response) => dispatch(loadGiftNoteLayoutSuccess(giftNoteLayoutFromJson(response.data))));
};

export const loadOrderOptions = () => (dispatch) => {
	return dispatch(api.getOrderOptions())
		.then((orderOptionsJson) => {

			const normalized = normalizeOrderOptions(orderOptionsFromJson(orderOptionsJson));
			dispatch(receiveEntities(normalized.entities, loadOrderOptions.name));

			return orderOptionsJson; // Maintain legacy untransformed data
		})
		.catch((err) => dispatch(loadingError(LOADING_KEYS.LOAD_ORDER_OPTIONS, err)));
};

export const loadRequiredCheckoutData = () => (dispatch) => {
	dispatch(loadingClearErrors());

	return Promise.all([
		dispatch(loadOrderOptions()),
		dispatch(loadRequiredUserCheckoutData()),
		dispatch(loadApiSettings()),
	]);
};

export const loadRequiredUserCheckoutData = ({ clearLoadingErrors = true } = {}) => (dispatch, getState) => {
	if (clearLoadingErrors) dispatch(loadingClearErrors());

	if (selectIsRegisteredUser(getState())) {
		return Promise.all([
			dispatch(loadPaymentMethods()),
			dispatch(addressActions.loadShippingAddresses()),
			dispatch(loadShippingMethods()),
		]);
	}

	return Promise.resolve();
};

export const loadShippingMethods = () => (dispatch) => {
	dispatch(loadingInit(LOADING_KEYS.LOAD_SHIPPING_METHODS));

	return dispatch(api.get_shoppingCarts_cartId_shippingOptions_view())
		.then((shippingOptions) => normalizeShippingOptionsViewResponse(shippingOptions))
		.then((normalized) => dispatch(receiveEntities(normalized.entities, loadShippingMethods.name)))
		.then(() => dispatch(loadingSuccess(LOADING_KEYS.LOAD_SHIPPING_METHODS)))
		.catch((err) => dispatch(loadingError(LOADING_KEYS.LOAD_SHIPPING_METHODS, err)));
};

const loadShoppingCartSuccess = (cartJson) => (dispatch) => {
	const cart = cartFromJson(cartJson);

	cart.items.forEach((item) => {
		if (item.bookId && isGroupTypeOfSeries(item.group)) dispatch(loadBooksSkinny(item.groupId, getVolumeFromBookId(item.bookId)));
		if (item.groupId && isGroupCoverADesignerBundle(item.group)) dispatch(loadGroupCoverBundle(item.groupId));
		if (item.product && item.product.id && isGroupTypeOfCards(item.group)) dispatch(loadProductPriceTiers(item.product.id));
	});

	const normalized = normalizeCart(cart);
	dispatch(receiveEntities(normalized.entities, loadShoppingCartSuccess.name));
	dispatch(setCartId(cart.id));
};

export const openAddressSelectionCheckoutModal = () => (dispatch, getState) => {
	return dispatch(addressActions.openAddressSelectionModal(addressSelectionModalModel({
		addressId: selectDefaultCheckoutAddressId(getState()),
		cancelCallback: () => dispatch(checkoutAnalyticsActions.tagShippingAddressOverlayCancel),
		doneCallback: (addressId) => dispatch(saveSelectedAddressIdToCart(addressId)),
		onAddressChangeEvent: () => { },
		onAddressEditEvent: (addressId, result) => dispatch(checkoutAnalyticsActions.tagShippingAddressOverlayEditAddress(addressId, result)),
		onAddressNewEvent: (addressId) => dispatch(checkoutAnalyticsActions.tagShippingAddressOverlayAddNewAddress(addressId)),
		onAddressSubmitEvent: () => { },
	})));
};

export const saveSelectedAddressIdToCart = (addressId) => (dispatch, getState) => {
	dispatch(showThrobber(saveSelectedAddressIdToCart.name));

	return dispatch(api.putCartShippingAddress(addressId))
		.then(() => dispatch(createOrRecalculateCart()))
		.then(() => dispatch(loadShippingMethods()))
		.then(() => dispatch(updateCartShippingMethod(selectMaybeFasterCheaperGrade(getState()))))
		.then(() => dispatch(loadActiveCart()))
		.finally(() => dispatch(hideThrobber(saveSelectedAddressIdToCart.name)));
};

export const openShippingMethodSelectionModal = () => (dispatch) => {
	dispatch(openModalById(modalModel({
		id: MODAL_NAMES.SHIPPING_METHOD_SELECTION
	})));
};

export const proceedToCheckout = (history) => (dispatch) => {
	dispatch(checkoutAnalyticsActions.tagShoppingCartCheckout());
	dispatch(setCheckoutSourceCookie(CHECKOUT_TYPES.CART));

	return dispatch(routeUserToAppropriateCheckoutStep(history));
};

export const removeGiftNote = () => (dispatch) => {
	dispatch(showThrobber(removeGiftNote.name));

	return dispatch(api.deleteCartGiftNote())
		.then(dispatch(loadActiveCart()))
		.finally(() => dispatch(hideThrobber(removeGiftNote.name)));
};

export const removeItemFromCart = () => (dispatch, getState) => {
	const itemId = selectCartItemIdToRemove(getState());

	dispatch(showThrobber(removeItemFromCart.name));

	return dispatch(api.deleteCartItem(itemId))
		.then(() => dispatch(unsetCartItemForRemoval()))
		.then(() => dispatch(loadActiveCart()))
		.finally(() => dispatch(hideThrobber(removeItemFromCart.name)));
};

export const createOrRecalculateCart = () => (dispatch) => {
	dispatch(loadingInit(LOADING_KEYS.LOAD_SHOPPING_CART));

	return dispatch(api.postCart())
		.then((data) => dispatch(loadShoppingCartSuccess(data)))
		.then(() => dispatch(armPromoSuggestions()))
		.then(() => dispatch(loadingClearErrors()))
		.then(() => dispatch(loadingSuccess(LOADING_KEYS.LOAD_SHOPPING_CART)))
		.catch((err) => dispatch(loadShoppingCartError(err)));
};

export const routeUserToAppropriateCheckoutStep = (history, notEnoughInfoLocationOverride) => (dispatch, getState) => {
	dispatch(showThrobber(routeUserToAppropriateCheckoutStep.name));

	return dispatch(loadRequiredUserCheckoutData({ clearLoadingErrors: false }))
		.then(() => {
			const hasEnoughInfo = selectHasRequiredCheckoutInfo(getState());

			if (hasEnoughInfo) {
				const shippingAddressId = selectDefaultCheckoutAddressId(getState());
				const paymentMethodId = selectCartPaymentMethodIdOrFirstPaymentMethodId(getState());

				return Promise.all([
					dispatch(api.putCartShippingAddress(shippingAddressId)),
					dispatch(api.putCartPaymentMethod(paymentMethodId)),
				])
					.then(() => dispatch(loadActiveCart()))
					.then(() => {
						const cartPriceHasChanged = selectIsCartPriceDifferentSinceShippingMethodChange(getState());
						const cartItemsAreShippable = selectCartItemsAreShippable(getState());
						if (cartPriceHasChanged && cartItemsAreShippable) {
							history.push(CHECKOUT_STEP_LOCATION_MAP[CHECKOUT_STEPS.SHIPPING_METHOD]);
						} else {
							history.push(CHECKOUT_STEP_LOCATION_MAP[CHECKOUT_STEPS.REVIEW]);
						}
					});
			} else {
				const nextStepUrl = !!notEnoughInfoLocationOverride
					? notEnoughInfoLocationOverride
					: selectCartNextStepLocation(getState());

				dispatch(maybeOpenLoginPage(history, nextStepUrl));
			}
		})
		.finally(() => dispatch(hideThrobber(routeUserToAppropriateCheckoutStep.name)));
};

const synchronizeCartItemProducts = () => (dispatch, getState) => {
	const cartItems = selectCartItems(getState());
	const groups = selectGroupMap(getState());

	let cartUpdateNeeded = false;

	return Promise.all(cartItems.map((cartItem) => {
		const group = groups[cartItem.group];

		const bookId = deep(cartItem, 'bookId');
		const filter = getGroupProductFilter(group);

		return !bookId
			? Promise.resolve()
			: dispatch(api.getProductSingles({ bookId, filter }))
				.then((products) => {
					const groupProductId = String(deep(products, 'data.products.0.ID'));
					const cartItemProductId = String(deep(cartItem, 'product'));
					const cartItemId = deep(cartItem, 'id');

					return (groupProductId === cartItemProductId)
						? Promise.resolve()
						: dispatch(api.putCartItemProductId(cartItemId, groupProductId))
							.then(() => { cartUpdateNeeded = true; });
				});
	}))
		.then(() => (cartUpdateNeeded) ? dispatch(loadActiveCart()) : Promise.resolve());
};

export const testCouponCode = (codeString) => (dispatch) => {
	return dispatch(api.getCode(codeString))
		.then((codeJson) => codeFromJson(codeJson));
};

export const updateCartShippingMethod = (shippingMethod) => (dispatch, getState) => {
	dispatch(showThrobber(updateCartShippingMethod.name));
	dispatch(loadingInit(LOADING_KEYS.SAVE_SHIPPING_METHOD));

	return dispatch(api.putCartShippingGrade(shippingMethod))
		.then(() => dispatch(loadActiveCart()))
		.then(() => dispatch(setAppCookie(selectCartPriceAfterShippingMethodChangeCookieKey(getState()), selectCartSubtotal(getState()))))
		.then(() => dispatch(loadingSuccess(LOADING_KEYS.SAVE_SHIPPING_METHOD)))
		.finally(() => dispatch(hideThrobber(updateCartShippingMethod.name)));
};
