import get4Random from './get4Random';

/**
 * Generates a unique UUID.
 */
export default function generateUUID() {
	return (
		get4Random() +
		get4Random() +
		'-' +
		get4Random() +
		'-' +
		get4Random() +
		'-' +
		get4Random() +
		'-' +
		get4Random() +
		get4Random() +
		get4Random()
	);
}
