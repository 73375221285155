import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Accordian from '../Accordian/Accordian';
import AccordianItem from '../Accordian/AccordianItem';
import CtaButton from '../CtaButton/CtaButton';
import productSpecsData from './ProductSpecs.data';
import * as analyticsActions from '../../../../actions/analytics.actions';
import './ProductSpecs.css';

const ProductSpecs = ({
	analyticsActions,
}) => (
	<section className="instagram-quickflow-page-product-specs padding padding-bottom-none">
		<h3>Product Specs</h3>
		<Accordian>
			<AccordianItem
				title="Product Specs"
				renderContent={() => productSpecsData.map((productSpec, index) => (
					<p
						key={index}
						className="margin-none"
					>{productSpec}</p>
				))}
				onOpen={() => analyticsActions.triggerAnalyticsEvent('IGSeriesLanding_Expand', {
					attr1: 'specs'
				})}
			/>
		</Accordian>
		<CtaButton trackingName="Product Specs" />
		<hr />
	</section>
);

ProductSpecs.propTypes = {
	analyticsActions: PropTypes.object,
};

ProductSpecs.displayName = 'ProductSpecs';

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
});

export default connect(
	null,
	mapDispatchToProps,
)(ProductSpecs);
