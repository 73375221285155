import React from 'react';

import Faq from './Faq';
import Accordian from '../Accordian/Accordian';
import reviewData from './Faqs.data';
import './Faqs.css';

const Faqs = () => (
	<section className="instagram-quickflow-page-faqs padding">
		<h3>FAQs</h3>
		<Accordian>
			{reviewData.map((review, index) => (
				<Faq
					key={index}
					{...review}
				/>
			))}
		</Accordian>
	</section>
);

export default Faqs;
