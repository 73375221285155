import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import withCarouselContext from '../../Carousel/withCarouselContext';
import './PressLogoBar.css';

class PressLogoBar extends Component {
	handleClick(index) {
		const { onGoToPage } = this.props;
		onGoToPage(index, true);
	}

	render() {
		const { children, className: classNameProp, context } = this.props;
		const { pageNumber } = context;
		const logos = React.Children.map(children, (child, index) => (
			<div
				key={`press-logo-${index}`}
				className={`press-logo${pageNumber === index ? ' active' : ''}`}
				onClick={this.handleClick.bind(this, index)}
			>
				{child}
			</div>
		));
		const className = classnames('PressLogoBar flex-container flex-dir-row align-middle align-center', classNameProp);

		return <div className={className}>{logos}</div>;
	}
}

PressLogoBar.displayName = 'PressLogoBar';

PressLogoBar.propTypes = {
	children: (props, propName, componentName) => {
		const prop = props[propName];
		let error = null;
		React.Children.forEach(prop, (child) => {
			if (child.type !== 'img') {
				error = new Error(`'${componentName}' can only have children of type 'img'`);
			}
		});

		return error;
	},
	className: PropTypes.string,
	context: PropTypes.object,
	onGoToPage: PropTypes.func
};

export default withCarouselContext(PressLogoBar);
