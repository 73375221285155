import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { LOAD_STATUS } from '../const/loadStatus.const';
import { REDUCERS } from '../const/reducers.const';

export const selectLoadingState = (state) => deep(state, `${REDUCERS.LOADING}`);

export const selectLoadingStatusMap = createSelector(
	selectLoadingState,
	(loadingState) => deep(loadingState, 'status') || {}
);

export const selectLoadingErrorMap = createSelector(
	selectLoadingState,
	(loadingState) => deep(loadingState, 'error') || {}
);

export const selectLoadingStatusFunc = createSelector(
	selectLoadingStatusMap,
	(statusMap) => (key) => statusMap.hasOwnProperty(key) ? statusMap[key] : LOAD_STATUS.NOT_STARTED
);

export const selectIsLoadSuccessfulFunc = createSelector(
	selectLoadingStatusMap,
	(statusMap) => (key) => statusMap.hasOwnProperty(key) && statusMap[key] === LOAD_STATUS.SUCCESS
);

export const selectIsStateLoadingFunc = createSelector(
	selectLoadingStatusMap,
	(statusMap) => (key) => statusMap.hasOwnProperty(key) && (statusMap[key] === LOAD_STATUS.INITIALIZING || statusMap[key] === LOAD_STATUS.LOADING)
);

export const selectIsStateInitializingFunc = createSelector(
	selectLoadingStatusFunc,
	(loadingStatusFunc) => (key) => {
		const cartLoadingState = loadingStatusFunc(key);

		return cartLoadingState === LOAD_STATUS.INITIALIZING || cartLoadingState === LOAD_STATUS.NOT_STARTED;
	}
);
