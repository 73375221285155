import axios from 'axios';
import deep from 'deep-get-set';

import { RECEIVE_BRANCH_DATA } from './actionTypes';
import { apiPost } from './api/api.http.actions';
import { handleActionUri } from './actionUri.actions';
import { CHATBOOKS_ACTIONS } from '../const/actionUri.const';
import { BRANCH_PARAMS, DEFAULT_BRANCH_LINK_DATA } from '../const/branch.const';
import Branch from '../helpers/analytics/Branch/Branch';
import { containsAny } from '../helpers/strings/strings';
import { selectBranchKey, selectIsBranchEnabled, selectSiteUrl } from '../selectors/config.selectors';

export const receiveBranchData = (branchErr, branchData) => ({ type: RECEIVE_BRANCH_DATA, data: { branchData, branchErr } });

export const init = (history, location) => (dispatch, getState) => {
	const state = getState();
	const branchKey = selectBranchKey(state);
	const isBranchEnabled = selectIsBranchEnabled(state);

	if (isBranchEnabled) {
		Branch.init(branchKey, (err, data) => {
			dispatch(receiveBranchData(err, data));

			if (err) {
				console.error(err);

				return;
			}
			console.log('branch data', deep(data, 'data'));

			const key = deep(data, 'data_parsed.key');
			const referrerPersonId = deep(data, 'data_parsed.person_id');
			const feature = deep(data, ['data_parsed', '~feature']);
			const actionUri = deep(data, 'data_parsed.ActionUri');

			const allowedActionUris = [
				CHATBOOKS_ACTIONS.IG_MERGE,
			];

			if (key && referrerPersonId && feature === 'referral') {
				dispatch(apiPost('/referrals/acceptInvite', {
					key,
					referrerPersonId
				}));
			} else if (actionUri && containsAny(actionUri, allowedActionUris)) {
				dispatch(handleActionUri(actionUri, history, location))
					.catch((err) => console.error(err));
			}
		});
	}
};

export const generateBranchUrl = (data = {}) => (dispatch, getState) => {
	const branchKey = selectBranchKey(getState());

	return axios({
		method: 'post',
		url: 'https://api2.branch.io/v1/url',
		data: {
			branch_key: branchKey,
			data: {
				...DEFAULT_BRANCH_LINK_DATA,
				[BRANCH_PARAMS.FALLBACK_URL]: selectSiteUrl(getState()),
				...data,
			}
		},
		headers: {
			'Content-Type': 'application/json',
		}
	})
		.then((resp) => resp.status === 200 && resp.data.url);
};
