import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { SPACING } from '../../const';
import { useIsHover } from '../../hooks';

const Container = styled.div`
	position: relative;
`;

const SocialPostImage = styled.img`
	object-fit: cover;
`;

const positionAbsoluteFullCoverage = css`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const SocialPostHoverOverlay = styled.div`
	${positionAbsoluteFullCoverage};
	background-color: ${(props) => props.backgroundColor};
`;

const SocialPostHoverOverlayBackground = styled.div`
	${positionAbsoluteFullCoverage};
	background-color: ${(props) => props.backgroundColor};
	opacity: .6;
`;

const QuoteTextOverlay = styled.div`
	${positionAbsoluteFullCoverage};
	display: grid;
	align-content: center;
	color: ${(props) => props.color};
	font-family: Inter;
	font-size: 16px;
	line-height: 22.5px;
	padding-left: ${SPACING.MEDIUM};
	padding-right: ${SPACING.MEDIUM};
	text-align: center;
`;

const QuoteTextBelow = styled.div`
	padding-top: ${SPACING.MEDIUM};
	color: ${(props) => props.color};
	text-align: center;
`;

const SocialPost = ({
	className,
	isHoverOverlayEnabled,
	post,
	socialImageHoverColor,
	socialQuoteTextColor,
}) => {
	const { hoverProps, isHover } = useIsHover();

	return (
		<Container
			className={className}
			{...hoverProps}>
			<SocialPostImage src={post.imageUrl} alt={post.imageAlt} />
			{(isHover && isHoverOverlayEnabled) && (
				<SocialPostHoverOverlay>
					<SocialPostHoverOverlayBackground backgroundColor={socialImageHoverColor} />
					<QuoteTextOverlay
						color={socialQuoteTextColor}>
						{post.quote}
					</QuoteTextOverlay>
				</SocialPostHoverOverlay>
			)}
			{!isHoverOverlayEnabled && (
				<QuoteTextBelow
					color={socialQuoteTextColor}>
					{post.quote}
				</QuoteTextBelow>
			)}
		</Container>
	);
};

SocialPost.displayName = 'SocialPost';

SocialPost.propTypes = {
	className: PropTypes.string,
	isHoverOverlayEnabled: PropTypes.bool,
	post: PropTypes.shape({
		imageAlt: PropTypes.string,
		imageUrl: PropTypes.string,
		quote: PropTypes.string,
	}),
	socialImageHoverColor: PropTypes.string,
	socialQuoteTextColor: PropTypes.string,
};

SocialPost.defaultProps = {
	isHoverOverlayEnabled: true,
};

export default SocialPost;