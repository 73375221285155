import { createSelector } from 'reselect';
import { chatbooksApi } from '@chtbks/api';

import { selectAccessToken } from './user.selectors';
import { selectApiUrl } from './config.selectors';

export const selectChatbooksApi = createSelector(
	selectApiUrl,
	selectAccessToken,
	(baseUrl, accessToken) => chatbooksApi({
		accessToken,
		baseUrl,
	})
);
