import deep from 'deep-get-set';

export const mediaRectFromJson = (json = {}) => ({
	height: deep(json, 'Height'),
	origHeight: deep(json, 'OrigHeight'),
	origWidth: deep(json, 'OrigWidth'),
	width: deep(json, 'Width'),
	x: deep(json, 'X'),
	y: deep(json, 'Y'),
});
