import PropTypes from 'prop-types';

/**
 * Creates a logInModalModel data object.
 *
 * @param {object} logInModalModel The logInModalModel object.
 * @param {string} logInModalModel.email An initialized email address
 * @param {string} logInModalModel.form The form to initially show
 * @param {func} logInModalModel.cancelCallback A callback called after modal is closed without signing in
 * @param {func} loginModalModel.showSignUp A boolean flag determining whether to show the sign up section
 * @param {func} loginModalModel.showSsoType An SSO_TYPE value indicating if the login modal should display email, Facebook, Google, or Apple login
 * @param {func} logInModalModel.signedInCallback A callback called after sign in complete
 */
export const logInModalModel = ({
	email,
	form,
	cancelCallback,
	showSignUp,
	showSsoType,
	signedInCallback,
}) => ({
	email,
	form,
	cancelCallback,
	showSignUp,
	showSsoType,
	signedInCallback,
});

export const logInModalPropType = PropTypes.shape({
	email: PropTypes.string,
	form: PropTypes.string,
	cancelCallback: PropTypes.func,
	showSignUp: PropTypes.bool,
	showSsoType: PropTypes.number,
	signedInCallback: PropTypes.func,
});
