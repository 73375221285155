import { normalize, schema } from 'normalizr';

import { collageLayoutsSchema, collageLayoutsFromJsonArray } from './collageLayout.model';
import { ENTITIES } from '../const/entities/entities';

export const collageLayoutsByBookSizeFromJson = (bookSize, collageLayoutJsonArray = []) => ({
	bookSize,
	layouts: collageLayoutsFromJsonArray(bookSize, collageLayoutJsonArray),
});

export const collageLayoutsByBookSizeSchema = new schema.Entity(ENTITIES.COLLAGE_LAYOUT_IDS_BY_BOOK_SIZE, {
	layouts: collageLayoutsSchema,
}, {
	idAttribute: 'bookSize',
});

export const normalizeCollageLayoutsByBookSize = (layouts) => normalize(layouts, collageLayoutsByBookSizeSchema);
