import { queryStringPartFromJson } from '../locationUtils';
import { CHATBOOKS_URIS, CHATBOOKS_ACTION_URI_PROTOCOL, CHATBOOKS_ACTIONS } from '../../const/actionUri.const';

export const makeCreateActionUri = () => `${withChatbooksProtocol(CHATBOOKS_ACTIONS.CREATE)}`; // TODO - implement 'create' ActionURI

export const makeDuplosDialogueActionUri = (duplosKey) => `${withChatbooksProtocol(CHATBOOKS_ACTIONS.DUPLO_DIALOG)}${queryStringPartFromJson({ key: duplosKey })}`;

export const makeDuplosExplainerActionUri = (product) => `${withChatbooksProtocol(CHATBOOKS_ACTIONS.DUPLOS_EXPLAINER)}${queryStringPartFromJson({ product })}`;

export const makeHelpActionUri = () => `${withChatbooksProtocol(CHATBOOKS_ACTIONS.HELP)}`;

export const makeInstagramQuickflowActionUri = (shouldGoToReadyPage) => `${CHATBOOKS_URIS.IG_AUTH_QUICKFLOW}${queryStringPartFromJson({ shouldGoToReadyPage })}`;

export const makeInstagramReauthenticateActionUri = () => CHATBOOKS_URIS.IG_REAUTH;

const withChatbooksProtocol = (actionKey) => `${CHATBOOKS_ACTION_URI_PROTOCOL}${actionKey}`;
