import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ModalBody.css';

const ModalBody = ({
	children,
	className,
	useStandardPadding,
}) => (
	<div
		className={classnames([
			'chatponent-modal-body',
			{'standard-modal-padding': useStandardPadding},
			className,
		])}
	>
		{children && children}
	</div>
);

ModalBody.displayName = 'ModalBody';

ModalBody.propTypes = {
	useStandardPadding: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
};

ModalBody.defaultProps = {
	useStandardPadding: false, // Convert all React modals to use this padding. Once they do, remove this option or make it default.
}

export default ModalBody;
