export default [
	{
		title: 'Super Easy & Automatic',
		text: 'You already did the work when you posted on Instagram! Connect your IG and we automatically turn every 60 photos into a book. You always have the option to edit, or send to print as is.',
		image: 'https://cdn.chatbooks.com/mk/20-02-IG-series-PDP-build-your-collection.jpg',
	},
	{
		title: 'Build Your Collection',
		text: 'Start books from your first Instagram post or choose a specific date as your starting point. Only pay for the books when they go to print.',
		image: 'https://cdn.chatbooks.com/mk/20-02-IG-series-PDP-great-quality.jpg',
	},
	{
		title: 'Money Back Guarantee',
		text: 'Don\'t love how your book prints, ships, feels, smells, or anything in between? Reach out to our amazing customer service team and we\'ll get your book reprinted or refunded with zero hassle.',
		image: 'https://cdn.chatbooks.com/mk/20-02-IG-series-PDP-money-back-guarantee.jpg',
	},
	{
		title: 'Great Quality',
		text: 'Books feature high resolution photos, archival quality paper, and professional grade ink. You won\'t believe it costs less than lunch!',
		image: 'https://cdn.chatbooks.com/mk/20-02-IG-series-PDP-super-easy-automatic.jpg',
	},
];
