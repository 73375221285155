import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

const IconFacebookLogo = (props) => (
	<Svg
		{...props}
		name="facebook-logo"
		viewBox="0 0 30 30"
	>
		<path
			d="M30 15.0917C30 6.75676 23.2843 0 15 0C6.71572 0 0 6.75676 0 15.0917C0 22.6242 5.48525 28.8678 12.6562 30V19.4541H8.84766V15.0917H12.6562V11.7668C12.6562 7.98444 14.8957 5.89518 18.322 5.89518C19.9632 5.89518 21.6797 6.18994 21.6797 6.18994V9.90391H19.7883C17.925 9.90391 17.3438 11.0673 17.3438 12.2608V15.0917H21.5039L20.8389 19.4541H17.3438V30C24.5147 28.8678 30 22.6242 30 15.0917Z"
			fill="inherit"
		/>
	</Svg>
);

IconFacebookLogo.displayName = 'IconFacebookLogo';

IconFacebookLogo.propTypes = iconProps;

IconFacebookLogo.defaultProps = defaultIconProps;

export default IconFacebookLogo;
