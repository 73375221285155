import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconPencil = (props) => (
	<Svg
		{...props}
		name="pencil"
		viewBox="0 0 18 18"
	>
		<path
			fill="inherit"
			fillRule="nonzero"
			d="M0 14.253v3.75h3.75l11.06-11.06-3.75-3.75L0 14.253zm17.71-10.21a.996.996 0 0 0 0-1.41L15.37.293a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
		/>
	</Svg>
);

IconPencil.displayName = 'IconPencil';

IconPencil.propTypes = iconProps;

IconPencil.defaultProps = defaultIconProps;

export default IconPencil;
