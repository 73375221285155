import React from 'react';
import PropTypes from 'prop-types';

import { getStackedBoxComponent } from './BoxStacked.helpers';

const BoxStacked = ({
	children,
	className,
	index,
	length,
}) => {
	const StackedBox = getStackedBoxComponent(length, index);

	return (
		<StackedBox className={className}>
			{children}
		</StackedBox>
	);
};

BoxStacked.displayName = 'BoxStacked';

BoxStacked.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	index: PropTypes.number,
	length: PropTypes.number,
};

export default BoxStacked;
