export const DATA_SOURCE_TYPE = {
	NONE: 0,
	LOCAL: 1,
	INSTAGRAM: 2,
	FACEBOOK: 3,
	GOOGLE_PHOTOS: 4,
	DROPBOX: 5,
	PUBLIC_INSTAGRAM: 6,
	INSTAGRAM_GRAPH: 7,
};

export const DATA_SOURCE_NAMES = {
	COMPUTER: 'computer',
	FACEBOOK: 'facebook',
	GOOGLE_PHOTOS: 'googlePhotos',
	INSTAGRAM: 'instagram',
};
