import config from '../helpers/config';

const rudderstackConfig = config.get('analytics.rudderstack');

function loadRudderAnalytics(writeKey, dataPlaneUrl) {
    var rudderanalytics = window.rudderanalytics = window.rudderanalytics || [];
    rudderanalytics.methods = [
        "load", "page", "track", "identify", "alias", "group", "ready", "reset",
        "getAnonymousId", "setAnonymousId", "getUserId", "getUserTraits",
        "getGroupId", "getGroupTraits", "startSession", "endSession", "getSessionId"
    ];

    rudderanalytics.factory = function (method) {
        return function () {
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            rudderanalytics.push(args);
            return rudderanalytics;
        };
    };

    for (var i = 0; i < rudderanalytics.methods.length; i++) {
        var key = rudderanalytics.methods[i];
        rudderanalytics[key] = rudderanalytics.factory(key);
    }

    function loadRudderStackScript() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js';
        var firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    }

    loadRudderStackScript();

    rudderanalytics.load(writeKey, dataPlaneUrl);
}

if (!process.env.SERVER && rudderstackConfig.enabled) {
    loadRudderAnalytics(rudderstackConfig.writeKey, rudderstackConfig.dataPlaneUrl);
}
