import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SocialPost from './SocialPost';
import TaperedLine from '../BetterInTheAppAndLoveItGuarantee/TaperedLine';
import {
	Carousel,
	CarouselButton,
	CarouselContainer,
	CarouselItem,
	CarouselPagination,
	PageFlowContainer,
	withWindowSize,
} from '../../components';
import {
	DEVICE_WIDTH,
	mediaQueryStartingFrom,
	mediaQueryUpTo,
	SCROLL_DIRECTION,
	SPACING,
} from '../../const';
import { useIsHover } from '../../hooks';

const Container = styled.div`
	background-color: ${(props) => props.backgroundColor};
`;

const ContentContainer = styled(PageFlowContainer)`
	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		padding-top: ${SPACING.BIGGER};
		padding-left: ${SPACING.BIGGER};
		padding-right: ${SPACING.BIGGER};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		padding-top: 104px;
		padding-bottom: 80px;
	}
`;

const Title = styled.div`
	color: ${(props) => props.color};
	font-family: Circular-Pro-Medium;
	text-align: center;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 21px;
		line-height: 28px;
		letter-spacing: 0.0125em;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 48px;
		line-height: 56px;
	}
`;

const Subcopy = styled.div`
	color: ${(props) => props.color};
	text-align: center;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-family: Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		padding-top: 8px;
		padding-bottom: 24px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-family: Circular-Pro-Medium;
		font-size: 23px;
		line-height: 33px;
		padding-top: 32px;
		padding-bottom: 104px;
	}
`;

const DesktopSocialPostsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	padding-bottom: 104px;
`;

const Pagination = styled(CarouselPagination)`
	text-align: center;
	opacity: ${(props) => props.isVisible ? 1 : 0};
`;

const CarouselSocialPost = styled(SocialPost)`
	margin: auto;
	max-width: 250px;
`;

const Separator = styled(TaperedLine)`
	padding-left: ${SPACING.BIGGER};
	padding-right: ${SPACING.BIGGER};
`;

const HomePageCommunityQuotes = ({
	backgroundColor,
	paginationColor,
	separatorColor,
	socialImageHoverColor,
	socialPosts,
	socialQuoteTextColor,
	subcopy,
	subcopyColor,
	title,
	titleColor,
	windowSize,
}) => {
	const isMobile = windowSize.width < DEVICE_WIDTH.TABLET;

	const { hoverProps, isHover } = useIsHover();

	if (!socialPosts || !socialPosts.length) return null;

	return (
		<Container
			{...hoverProps}
			backgroundColor={backgroundColor}>
			<ContentContainer>
				<Title color={titleColor}>{title}</Title>
				<Subcopy color={subcopyColor}>{subcopy}</Subcopy>
				{isMobile ? (
					<CarouselContainer>
						<Carousel>
							{socialPosts.map((post) => (
								<CarouselItem
									key={post.quote}
									gutter={false}>
									<CarouselSocialPost
										key={post.quote}
										post={post}
										socialQuoteTextColor={socialQuoteTextColor}
										isHoverOverlayEnabled={false}
									/>
								</CarouselItem>
							))}
						</Carousel>
						{isHover && (<CarouselButton direction={SCROLL_DIRECTION.LEFT} color={paginationColor} />)}
						{isHover && (<CarouselButton direction={SCROLL_DIRECTION.RIGHT} color={paginationColor} />)}
						<Pagination
							isVisible={(isHover || isMobile)}
							color={paginationColor}
						/>
					</CarouselContainer>
				) : (
					<DesktopSocialPostsContainer>
						{socialPosts.map((post) => (
							<SocialPost
								key={post.quote}
								post={post}
								socialImageHoverColor={socialImageHoverColor}
								socialQuoteTextColor={socialQuoteTextColor}
							/>
						))}
					</DesktopSocialPostsContainer>
				)}
				<Separator color={separatorColor} />
			</ContentContainer>
		</Container>
	);
};

HomePageCommunityQuotes.displayName = 'HomePageCommunityQuotes';

HomePageCommunityQuotes.propTypes = {
	backgroundColor: PropTypes.string,
	paginationColor: PropTypes.string,
	separatorColor: PropTypes.string,
	socialImageHoverColor: PropTypes.string,
	socialPosts: PropTypes.arrayOf(PropTypes.shape({
		imageAlt: PropTypes.string,
		imageUrl: PropTypes.string,
		quote: PropTypes.string,
	})),
	socialQuoteTextColor: PropTypes.string,
	subcopy: PropTypes.string,
	subcopyColor: PropTypes.string,
	title: PropTypes.string,
	titleColor: PropTypes.string,
	windowSize: PropTypes.object,
};

export default withWindowSize(HomePageCommunityQuotes);
