import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconMailBox = (props) => (
	<Svg
		{...props}
		name="mail-box"
		viewBox="0 0 120 120"
	>
		<path
			d="M100.47,98.5H18.68a2,2,0,0,1-2-2V62.71A20.2,20.2,0,0,1,36.89,42.54H79.58a22.88,22.88,0,0,1,22.86,22.85V96.53A2,2,0,0,1,100.47,98.5ZM20.64,94.57H98.5V65.39A18.94,18.94,0,0,0,79.58,46.47H36.89A16.26,16.26,0,0,0,20.64,62.71Z"
		/>
		<path
			d="M54.54,98.5H18.68a2,2,0,0,1-2-2V62.43a19.9,19.9,0,0,1,39.79,0v34.1A2,2,0,0,1,54.54,98.5Zm-33.9-3.93H52.57V62.43a16,16,0,0,0-31.93,0Z"
		/>
		<path
			d="M62.14,62.61a2,2,0,0,1-2-2V24.29a2,2,0,0,1,3.93,0V60.64A2,2,0,0,1,62.14,62.61Z"
		/>
		<path
			d="M81,37.88H62.14a2,2,0,0,1-2-2V24.29a2,2,0,0,1,2-2H81a2,2,0,0,1,2,2V35.92A2,2,0,0,1,81,37.88ZM64.1,34H79V26.26H64.1Z"
		/>
		<path
			d="M39.47,68.41H30.84a2,2,0,1,1,0-3.93h8.63a2,2,0,0,1,0,3.93Z"
		/>
	</Svg>
);

IconMailBox.displayName = 'IconMailBox';

IconMailBox.propTypes = iconProps;

IconMailBox.defaultProps = defaultIconProps;

export default IconMailBox;
