import { objectMap } from '@chtbks/helpers';

export const CHATBOOKS_ACTION_URI_PROTOCOL = 'chatbooks://';

export const CHATBOOKS_ACTIONS = {
	ACCEPT_INVITE: 'accept_invite',
	CALLBACK: 'callback',
	CONFETTI: 'pop',
	CREATE: 'create',
	DUPLO_DIALOG: 'duplodialog',
	DUPLOS_EXPLAINER: 'duploexplainer',
	HELP: 'help',
	IG_AUTH_QUICKFLOW: 'igauth_quickflow',
	IG_MERGE: 'igmerge',
	IG_REAUTH: 'igreauth',
	LIVE_CHAT: 'live_chat',
	ORDERS: 'orders',
	PROFILE: 'profile',
	QUICK_FLOW: 'quickflow',
	RELOAD_CART: 'reloadcart',
	SIGN_IN: 'sign_in',
	SIGN_OUT: 'sign_out',
};

export const CHATBOOKS_URIS = objectMap(CHATBOOKS_ACTIONS, (value) => `${CHATBOOKS_ACTION_URI_PROTOCOL}${value}`);
