import { convertNumberTwoDecimalsString } from '../numbers/numbers';

export const isDate = (date) => (date instanceof Date) && !isNaN(date.valueOf());

export const isDateOlderThanXHours = (date, hours) => {
	if (!date || !hours) return false;

	const millisecondsPerSecond = 1000;
	const secondsPerMinute = 60;
	const minutesPerHour = 60;

	const now = new Date().getTime();
	const then = date.getTime();

	return now - then > hours * minutesPerHour * secondsPerMinute * millisecondsPerSecond;
};

export const formatDateYYYYMMDD = (date) => {
	// date to "YYY-MM-DD" string
	const dateObj = new Date(date);

	const y = String(dateObj.getFullYear());
	const m = convertNumberTwoDecimalsString(dateObj.getMonth());
	const d = convertNumberTwoDecimalsString(dateObj.getDate());

	return `${y}-${m}-${d}`;
};
