import { schema } from 'normalizr';

import { ENTITIES } from '../../../const/entities/entities';

export const dataSourceMediaModel = ({
	caption,
	children,
	date,
	height,
	id,
	latitude,
	longitude,
	standardResUrl,
	source,
	tags,
	thumbnailUrl,
	width,
}) => ({
	caption,
	children,
	date,
	height,
	id,
	latitude,
	longitude,
	source,
	standardResUrl,
	tags,
	thumbnailUrl,
	width,
});

const dataSourceMediaSchema = new schema.Entity(ENTITIES.DATA_SOURCE_MEDIA);
export const dataSourceMediasSchema = new schema.Array(dataSourceMediaSchema);
