import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const ServerRouteStatus = ({ children, httpStatusCode }) => (
	<Route
		render={({ staticContext }) => {
			if (staticContext) staticContext.status = httpStatusCode;

			return children;
		}}
	/>
);

ServerRouteStatus.displayName = 'ServerRouteStatus';

ServerRouteStatus.propTypes = {
	children: PropTypes.node,
	httpStatusCode: PropTypes.number,
};

export default ServerRouteStatus;