import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Downshift from 'downshift';
import { IconCaretDown } from '../Icons';

import { COLOR } from '../../const';
import './SelectBox.css';

const SelectBox = ({
	className,
	dropdownClassName,
	id,
	items,
	itemToKey,
	itemToString,
	onChange,
	renderLabel,
	renderItem,
	renderInput,
	renderToggleButton,
	selectedItem,
}) => {
	if (!id) return null;

	const itemKey = (item, index) => itemToKey ? itemToKey(item, index) : item;

	return (
		<Downshift
			itemToString={itemToString || (renderInput ? () => { } : undefined)}
			selectedItem={selectedItem}
			onChange={onChange}
			children={({
				getInputProps,
				getItemProps,
				getLabelProps,
				getMenuProps,
				getToggleButtonProps,
				isOpen,
				highlightedIndex,
				selectedItem,
			}) => (
					<div
						className={classnames([className, 'SelectBox'])}
					>
						{!!renderLabel && renderLabel({ getLabelProps })}
						<div className="selection-wrap">
							<div className="selection-input-wrap">
								{renderInput ? renderInput({ getInputProps, selectedItem }) : (
									<input
										className="selection-input"
										{...getInputProps()}
									/>
								)}
							</div>
							<div className="toggle-button-wrap">
								{renderToggleButton ? renderToggleButton({ getToggleButtonProps }) : (
									<button className="toggle-button" {...getToggleButtonProps()}>
										<IconCaretDown color={COLOR.BLACK} size={28} />
									</button>
								)}
							</div>
						</div>
						{!!isOpen && (
							<div className="select-box-ul-wrap">
								<ul
									className={classnames(['select-box-ul', dropdownClassName])}
									{...getMenuProps()}
								>
									{items.map((item, index) => (
										<li
											className="select-box-li"
											key={itemKey(item, index)}
											selected={selectedItem === item}
											{...getItemProps({
												key: itemKey(item, index),
												index,
												item,
											})}
										>
											{renderItem ? renderItem({
												item,
												isHighlighted: highlightedIndex === index,
												isSelected: selectedItem === item,
											}) : (
													<div
														className={classnames(['default-item-div', { 'highlighted': highlightedIndex === index }])}
													>
														{item}
													</div>
												)}
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				)}
		/>
	);
};

SelectBox.displayName = 'SelectBox';

SelectBox.propTypes = {
	className: PropTypes.string,
	dropdownClassName: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
	itemToKey: PropTypes.func,
	itemToString: PropTypes.func,
	onChange: PropTypes.func,
	renderInput: PropTypes.func,
	renderLabel: PropTypes.func,
	renderItem: PropTypes.func,
	renderToggleButton: PropTypes.func,
	selectedItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.object, PropTypes.string])
};

SelectBox.defaultProps = {
	items: [],
};

export default SelectBox
