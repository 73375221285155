import * as ACTIONS from './actionTypes';
import { makeDismissExcludesCookieKey, makeFirstBookTooltipCookieKey, COOKIES, makeCardScreensViewedCookieKey } from '../helpers/cookies';

export const deleteAppCookie = (key) => ({ type: ACTIONS.DELETE_APP_COOKIE, key });
export const clearAppStringOverridesCookie = () => ({ type: ACTIONS.CLEAR_APP_STRING_OVERRIDES });
export const setAppCookie = (key, value) => ({ type: ACTIONS.SET_APP_COOKIE, data: { key, value } });
export const setAppStringOverrideCookieByKey = (key, value) => ({ type: ACTIONS.SET_APP_STRING_OVERRIDE_BY_KEY, data: { key, value } });
export const setCheckoutSourceCookie = (checkoutSource) => ({ type: ACTIONS.SET_CHECKOUT_SOURCE, data: { checkoutSource } });
export const setGdprDisplayedCookie = () => ({ type: ACTIONS.GDPR_DISPLAYED });
export const setMadeToSuffer = () => ({ type: ACTIONS.MADE_TO_SUFFER });
export const setUuidCookie = (uuid) => ({ type: ACTIONS.SET_UUID_COOKIE, data: { uuid } });

export const deleteDismissExcludeCookie = (groupId) => (dispatch) => {
	return dispatch(deleteAppCookie(makeDismissExcludesCookieKey(groupId)));
};

export const deleteFirstBookTooltipCookie = (groupId) => (dispatch) => {
	return dispatch(deleteAppCookie(makeFirstBookTooltipCookieKey(groupId)));
};

export const deleteTempCardCookie = () => (dispatch) => {
	return dispatch(deleteAppCookie(COOKIES.APP.TEMP_CARD));
};

export const setCardScreensViewed = (groupId, screensViewed) => (dispatch) => {
	return dispatch(setAppCookie(makeCardScreensViewedCookieKey(groupId), screensViewed));
};

export const setDismissExcludeCookie = (groupId) => (dispatch) => {
	return dispatch(setAppCookie(makeDismissExcludesCookieKey(groupId), true));
};

export const setPrintedBooksCookie = (printedBooks) => (dispatch) => {
	return dispatch(setAppCookie(COOKIES.APP.PRINTED_BOOKS, printedBooks));
};

export const setTempCardCookie = (groupId, isDirty) => (dispatch) => {
	return dispatch(setAppCookie(COOKIES.APP.TEMP_CARD, {
		id: groupId,
		dirty: isDirty,
	}));
};

export const setVisitedAppCookie = () => (dispatch) => {
	return dispatch(setAppCookie(COOKIES.APP.APP_VISITED, true));
};

export const setVisitedBlogCookie = () => (dispatch) => {
	return dispatch(setAppCookie(COOKIES.APP.BLOG_VISITED, true));
};

export const setVisitedMarketingSiteCookie = () => (dispatch) => {
	return dispatch(setAppCookie(COOKIES.APP.MARKETING_VISITED, true));
};
