import React from 'react';
import PropTypes from 'prop-types';

import ConfigurableLink from '../../ConfigurableLink/ConfigurableLink';
import './Tile.css';

export const Tile = ({
	action,
	analyticEvent,
	analyticObj,
	backgroundColor,
	color,
	image,
	isPrimary,
	linkElement,
	linkElementPathAttr,
	onClick,
	path,
	title
}) => {
	let headingStyles = color ? { color } : {};
	if (backgroundColor) {
		headingStyles = {
			...headingStyles,
			backgroundColor
		};
	}
	const heading = isPrimary ? <h2 style={headingStyles}>{title}</h2> : <h4 style={headingStyles}>{title}</h4>;
	let containerProps = {
		className: `Tile ${isPrimary ? 'primary-tile' : 'secondary-tile'}`
	};
	containerProps = path
		? {
				...containerProps,
				action,
				analyticObj,
				analyticEvent,
				linkElement,
				linkElementPathAttr,
				path
		  }
		: {
				...containerProps,
				onClick: (e) => {
					onClick && onClick(e);
				}
		  };
	const Container = path ? ConfigurableLink : 'div';

	return (
		<Container {...containerProps}>
			<div style={{ backgroundImage: `url('${image}')` }}>
				<div className="spacer" />
				{heading}
			</div>
		</Container>
	);
};

Tile.displayName = 'Tile';

Tile.propTypes = {
	action: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	backgroundColor: PropTypes.string,
	color: PropTypes.string,
	image: PropTypes.string,
	isPrimary: PropTypes.bool,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	onClick: PropTypes.func,
	path: PropTypes.string,
	title: PropTypes.string
};

Tile.defaultProps = {
	isPrimary: false
};

export default Tile;
