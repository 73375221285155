export default [
	{
		title: 'Can I include captions and dates?',
		text: 'Yes—you can automatically include them if you wish! Turn dates and/or captions on or off as you please.',
	},
	{
		title: 'What if I have multiple photos per Instagram post?',
		text: 'Each photo in your post will be printed on its own separate page.',
	},
	{
		title: 'When will my book arrive?',
		text: 'It typically takes 2-4 days for your book to be printed. Your book will then ship based on the shipping speed you select. You’ll receive an estimated arrival date at checkout, and when each additional book prints and ships.',
	},
	{
		title: 'Will I be able to edit my books?',
		text: 'You will get an email and push notification (if you desire) when your book fills with 60 images—then you will have 3 days to edit your book before it prints. If you need more than 3 days to edit your book, you can delay your printing and just print it when you’re ready!',
	},
	{
		title: 'What goes on my cover?',
		text: 'The first picture in your book is automatically loaded as the cover, but you can edit your book to use any picture you want. You can also choose to add exclusive Rifle Paper Co. floral covers to your Instagram Series Books.',
	},
	{
		title: 'What if I just want my IG pictures from after my son was born?',
		text: 'You can choose any start date you wish for your Instagram Book Series! Start from the first photo you ever uploaded (remember those filters?!), or a date you choose. You can even start from today.',
	},
	{
		title: 'I have 15 books—do I have to buy them all today?',
		text: 'You can choose! Purchase your entire set today, or choose to get 1, 2, or 5 books each month until you catch up.',
	},
	{
		title: 'Can I pull pictures from multiple Instagram accounts?',
		text: 'Yes! Just go to “Setting” within your book Series and you can add multiple IG accounts as photo sources into one Series.',
	},
];
