import styled from 'styled-components';

import Button from '../Button';

const PillButton = styled(Button)`
	border-radius: 20px;
	padding: 0.5rem 3rem;
`;

PillButton.displayName = 'PillButton';

export default PillButton;