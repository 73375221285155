import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccordianIcon from './AccordianIcon';
import ConfigurableLink from '../ConfigurableLink/ConfigurableLink';
import './FooterLinksSection.css';

const FooterLinksSection = ({ children, title}) => {

	const [ isOpen, setIsOpen ] = useState(false);

	const links = children.map((child, index) => <li key={`FooterSection-Link-${index}`}>{child}</li>);

	return (
		<nav className="FooterSection">
			<h4 onClick={() => setIsOpen(!isOpen)}>
				{title} <AccordianIcon open={isOpen} />
			</h4>
			<ul className={`menu vertical ${isOpen ? 'open' : ''}`}>{links}</ul>
		</nav>
	);
}

FooterLinksSection.propTypes = {
	children: (props, propsName, componentName) => {
		let error = null;
		React.Children.forEach((prop, child) => {
			if (child.type !== ConfigurableLink) {
				error = new Error(`'${componentName}' children should be of type 'ConfigurableLink'`);
			}
		});

		return error;
	},
	title: PropTypes.string
};

FooterLinksSection.defaultProps = {
	children: []
};

export default FooterLinksSection;
