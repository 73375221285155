import deep from 'deep-get-set';
import values from 'lodash/values';

export const isObject = (maybeObject) => {
	return maybeObject !== undefined
		&& maybeObject !== null
		&& maybeObject.constructor === Object;
}

export function objectSafeParse(jsonString) {
	try {
		return JSON.parse(jsonString);
	} catch (e) {
		return {};
	}
}

export function objectToKeyValuePairs(object = {}) {
	if (!object) return [];

	return Object.keys(object).map((key) => ({
		key,
		value: object[key],
	}));
};

/**
 * Gets an objects values off of it. Order not guaranteed.
 *
 * @param {object} object The object to get values from.
 */
export function objectValues(object = {}) {
	if (!object) return [];

	return values(object);
};


/**
 * Deep check two different values
 * If the deep check with the first string doesn't return a value, it tries the second string
 *
 * @param {object} obj The object to get values from.
 * @param {string} str1 String for using in deep
 * @param {string} str2 String for using in deep
 */
export const deepOr = (obj, str1, str2) => deep(obj, str1) || deep(obj, str2);
