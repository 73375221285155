import { schema } from 'normalizr';
import deep from 'deep-get-set';

import { ENTITIES } from '../const/entities/entities';

const makeCollageLayoutId = (bookSize, collageLayoutId) => `bookSize(${bookSize})-collageLayoutId(${collageLayoutId})`;

const rectangleFromJson = (json = {}) => ({
	x: deep(json, 'X'),
	y: deep(json, 'Y'),
	width: deep(json, 'Width'),
	height: deep(json, 'Height'),
});

const imageItemsFromJson = (json = {}) => ({
	index: deep(json, 'Index'),
	rectangle: rectangleFromJson(deep(json, 'Rectangle')),
});

const pageTemplateLayoutModelFromJson = (json = {}) => ({
	height: deep(json, 'Height'),
	imageItems: (deep(json, 'ImageItems') || []).map((imageItem) => imageItemsFromJson(imageItem)),
	pageBleed: deep(json, 'PageBleed'),
	width: deep(json, 'Width'),
});

const collageLayoutFromJson = (bookSize, json = {}) => {
	const collageLayoutId = deep(json, 'ID');

	return {
		id: makeCollageLayoutId(bookSize, collageLayoutId),
		collageLayoutId,
		name: deep(json, 'Name'),
		pageTemplateLayoutModel: pageTemplateLayoutModelFromJson(deep(json, 'PageTemplateLayoutModel')),
		staticPreviewUrl: deep(json, 'StaticPreviewUrl'),
	};
};

export const collageLayoutsFromJsonArray = (bookSize, jsonArray = []) => {
	return jsonArray.map((json) => collageLayoutFromJson(bookSize, json));
};

const collageLayoutSchema = new schema.Entity(ENTITIES.COLLAGE_LAYOUT);

export const collageLayoutsSchema = new schema.Array(collageLayoutSchema);
