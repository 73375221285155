import { interpolate } from '@chtbks/api';
import { objectMap } from '@chtbks/helpers';

import { isTruthyString } from '../bool/bool';
import { isAppString } from '../../model/appString.model';

export const asValue = (Value) => ({ Value });

/**
 * Retrieves an appString's Value and interpolates it with any given params
 *
 * If the appString is not here, it returns the key wrapped in braces - `[[key]]`
 *
 * Additionally, params can contain a nested appString value which will get translated within this translation.
 *
 * For example, if a desired translation has a param like this "Remove {{ item }}", and it's desired to put
 * another translated value inside this, an appString can be passed in to that param value.
 *
 * ```
 * const item = appStringModel({
 *	 id: 'web.Photo Book' // 'Photo Book'
 * })
 *
 * appStringModel({
 *	 id: 'web.removeItem' // 'Remove {{ item }}
 *	 params: {
 *		 item
 *	 }
 * });
 * ```
 *
 * The above example will translate to 'Remove Photo Book'. It can also result in 'Remove Cards', 'Remove Prints', 'Remove Post Cards', etc.
 *
 * @param {object} appStringsMap The map of appStrings by their key
 * @param {string} key The key of the appString needing translation
 * @param {object} params Any parameters to pass to the appString interpolation, a param value could be a nested appString
 */
const appStringTranslate = (appStringsMap, key, params) => {
	const value = getAppStringValueFromMap(appStringsMap, key);

	const translatedParams = objectMap(params, (param) => {
		return isAppString(param)
			? getAppStringAndTranslate(appStringsMap, param.id, param.params)
			: param
		}
	);

	if (value === '') return value;
	if (value === undefined) return value;

	return interpolate(value, translatedParams);
};

export const getAppStringAndTranslate = (appStringsMap, key, params) => {
	const appString = appStringTranslate(appStringsMap, key, params)

	if (appString === '' || appString !== undefined) {

		return appString;
	}

	return '';
}

export const getAppStringAndTranslateUndefinedIfDoesNotExist = (appStringsMap, key, params) => {
	const appString = appStringTranslate(appStringsMap, key, params);

	return (appString === '') ? undefined : appString;
}


/**
 * Retrieves an appString's Value from a map of app strings
 *
 * @param {object} appStringsByKey The map of appStrings by their key
 * @param {string} key The key of the appString to get the Value from
 */
export const getAppStringValueFromMap = (appStringsByKey, key) => (appStringsByKey[key] || {}).Value;

/**
 * Determines if an appString is an "enabled" value
 *
 * @param {string} appString A given app string
 */
export const isAppStringEnabled = (appString) => {
	if (typeof appString === 'string') return isTruthyString(appString);

	return !!appString;
};


export const isAppStringNamespaceInMap = (loadedNamespaceMap, namespace) => {
	if (!namespace || typeof namespace !== 'string') return false;

	for (const loadedNamespace of Object.keys(loadedNamespaceMap)) {
		if (namespace.startsWith(loadedNamespace)) return true;
	};

	return false;
};
