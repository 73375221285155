import React from 'react';

import theFiveMinutePhotoBookData from './InstagramBookSeriesSection.data';
import CtaButton from '../CtaButton/CtaButton';
import Stars from '../Stars/Stars';
import DangerouslyTranslate from '../../../../components/Translate/DangerouslyTranslate';
import './InstagramBookSeriesSection.css';

const InstagramBookSeriesSection = () => (
	<section className="instagram-book-series-section">
		<div className="padding-top-bigger padding-right padding-left">
			<h2 className="title"><strong><DangerouslyTranslate id="web.ig.quickflow.landing.heroexplainer" /></strong></h2>

			<ul>
				{theFiveMinutePhotoBookData.map((item, index) => (
					<li key={index}>
						<img
							alt={item.title}
							src={item.image}
						/>
						<p>{item.title}</p>
					</li>
				))}
			</ul>
		</div>

		<CtaButton
			className="margin-left margin-right margin-top-bigger"
			trackingName="How It Works"
		/>

		<div className="padding-top-bigger padding-bottom-bigger padding-right-big padding-left-big">
			<div className="price">
				$10 per book + Free Shipping
			</div>

			<div className="reviews padding-top-smaller">
				<Stars />
				4.8 (104k Reviews)
			</div>
		</div>
	</section>
);

export default InstagramBookSeriesSection;
