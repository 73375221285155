import { apiPostRecieveData } from './api.http.actions';

export const postShippingOptionsCalculate = (
	productId,
	shippingAddressId,
	groupId,
	quantity,
	sharableId,
	sharableKey,
	orderId,
) => (dispatch) => {
	return dispatch(apiPostRecieveData('/shippingOptions/calculate', {
		productId,
		shippingAddressId,
		groupId,
		quantity,
		sharableId,
		sharableKey,
		orderId,
	}));
};
