import React from 'react';
import PropTypes from 'prop-types';

import UsernameInstagram from './UsernameInstagram';
import UsernameTwitter from './UsernameTwitter';
import { SOCIAL_PLATFORMS } from '../../const/socialPlatforms';

const User = ({ name, username, avatarUrl, platform }) => {
	if (platform !== SOCIAL_PLATFORMS.INSTAGRAM && platform !== SOCIAL_PLATFORMS.TWITTER) return null;

	return (
		<div className="flex-container flex-grow flex-dir-row align-middle">
			<div className="flex-shrink">
				<div className="avatar">
					<img src={avatarUrl} alt={name || username} />
				</div>
			</div>
			<div className="flex-grow user">
				<UsernameTwitter name={name} platform={platform} username={username} />
				<UsernameInstagram platform={platform} username={username} />
			</div>
		</div>
	);
};

User.displayName = 'User';

User.propTypes = {
	name: PropTypes.string,
	username: PropTypes.string,
	avatarUrl: PropTypes.string,
	platform: PropTypes.string
};

export default User;
