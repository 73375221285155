import { LANGUAGE_KEYS } from '../../const/language.const';

/**
 * Format a date string into a nice human-readable string
 * @param date Date string
 * @returns {string}
 */
export default (date) =>
	new Date(date).toLocaleDateString(LANGUAGE_KEYS.en_US, {
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	});
