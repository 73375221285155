import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ConfigurableLink from '../ConfigurableLink/ConfigurableLink';
import './FooterLogo.css';

const FooterLogo = ({ analyticEvent, path, src }) => {
	if (!src) return null;

	const img = <img src={src} alt="Chatbooks" className="logo" />;

	if (!path) {
		return img;
	}

	return (
		<ConfigurableLink
			action="Link"
			analyticObj={{
				attr2: 'logo'
			}}
			analyticEvent={analyticEvent}
			linkElement={RouterLink}
			linkElementPathAttr={'to'}
			path={path}
		>
			{img}
		</ConfigurableLink>
	);
};

FooterLogo.propTypes = {
	analyticEvent: PropTypes.func,
	path: PropTypes.string,
	src: PropTypes.string
};

export default FooterLogo;
