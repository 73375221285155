import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as analyticsActions from '../../actions/analytics.actions';
import * as instagramReauthActions from '../../actions/instagramQuickReauth.actions';
import Events from '../../helpers/analytics/Events';
import './Cta.css';

const Cta = ({
	analyticsActions,
	history,
	instagramReauthActions,
	isFailure,
	isVisible,
	location,
}) => {
	if (!isVisible) return null;

	return (
		<div className="margin-right-small margin-left-small">
			<button
				className="button cta"
				onClick={() => {
					const trackingActionObject = (isFailure) ? Events.QuickReauthStatus_Action : Events.QuickReauthLanding_Action;

					analyticsActions.clickTracking(trackingActionObject, { attr1: 'reconnect' });
					instagramReauthActions.authenticateAndReconnectInstagramDatasources(history, location);
				}}
			>
				Reconnect Instagram
			</button>
		</div>
	);
};

Cta.displayName = 'Cta';

Cta.propTypes = {
	analyticsActions: PropTypes.object,
	history: PropTypes.object,
	instagramReauthActions: PropTypes.object,
	isFailure: PropTypes.bool,
	isVisible: PropTypes.bool,
	location: PropTypes.object,
};

Cta.defaultProps = {
	isFailure: false,
	isVisible: false,
};

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
	instagramReauthActions: bindActionCreators(instagramReauthActions, dispatch),
});

export default connect(
	null,
	mapDispatchToProps,
)(withRouter(Cta));
