import React from 'react';
import PropTypes from 'prop-types';

import useLongPress from './useLongPress';
import useQuickClicks from './useQuickClicks';
import ConfigurableLink from '../ConfigurableLink/ConfigurableLink';
import './FooterBase.css';

const FooterBase = ({
	action,
	analyticEvent,
	copyright: copyrightText,
	linkElement,
	linkElementPathAttr,
	onEasterEgg,
	privacyPolicy: privacyPolicyLink,
	support: supportNumber,
	supportPrefix,
	termsOfUse: termsOfUseLink
}) => {
	const { handleClick } = useQuickClicks({
		onQuickClicks: onEasterEgg,
		requiredClicks: 4,
	});
	const { handleTouchEnd, handleTouchStart } = useLongPress({
		onLongPress: onEasterEgg,
		secondsRequired: 4,
	});

	const linkProps = {
		action,
		analyticEvent,
		linkElement,
		linkElementPathAttr
	};

	const year = new Date().getFullYear();

	return (
		<div className="FooterBase">
			<ul>
				{!!copyrightText && (
					<li
						onClick={handleClick}
						onTouchStart={handleTouchStart}
						onTouchEnd={handleTouchEnd}>
						Copyright &copy; {copyrightText} {year}.
					</li>
				)}
				{!!supportNumber && (
					<li>{`${supportPrefix ? supportPrefix + ' ': ''}${supportNumber}`}</li>
				)}
				{!!termsOfUseLink && (
					<li>
						<ConfigurableLink
							{...linkProps}
							analyticObj={{
								attr2: 'Terms of Use'
							}}
							path={termsOfUseLink}
						>
							Terms of Use
						</ConfigurableLink>
					</li>
				)}
				{!!privacyPolicyLink && (
					<li>
						<ConfigurableLink
							{...linkProps}
							analyticObj={{
								attr2: 'Privacy Policy'
							}}
							path={privacyPolicyLink}
						>
							Privacy Policy
						</ConfigurableLink>
					</li>
				)}
			</ul>
		</div>
	);
}

FooterBase.propTypes = {
	action: PropTypes.string,
	analyticEvent: PropTypes.func,
	copyright: PropTypes.string,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	onEasterEgg: PropTypes.func,
	privacyPolicy: PropTypes.string,
	support: PropTypes.string,
	supportPrefix: PropTypes.string,
	termsOfUse: PropTypes.string
};

FooterBase.defaultProps = {
	action: 'Link',
	copyright: 'Chatbooks, Inc.',
	linkElement: 'a',
	linkElementPathAttr: 'href',
	supportPrefix: ''
};

export default FooterBase;
