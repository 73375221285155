import { getProductPriceTiers, getProductsSubscriptions } from './api/api.actions';
import { receiveEntities } from './entity.actions';
import { normalizeGroupSubscriptionsResponse, groupSubscriptionsResponseFromJson } from '../model/groupSubscriptionsResponse.model';
import { normalizeProductPriceTiers } from '../model/productPriceTier.model';

export const loadProductPriceTiers = (productId) => (dispatch) => {
	return dispatch(getProductPriceTiers(productId))
		.then((productPriceTiers) => normalizeProductPriceTiers(productPriceTiers))
		.then((normalized) => dispatch(receiveEntities(normalized.entities, loadProductPriceTiers.name)));
};

export const loadGroupProductSubscriptions = ({ groupId, filter, sharableId, sharableKey } = {}) => (dispatch) => {
	return dispatch(getProductsSubscriptions({ groupId, filter, sharableId, sharableKey }))
		.then((groupSubscriptionsResponse) => {

			const normalized = normalizeGroupSubscriptionsResponse(groupSubscriptionsResponseFromJson(groupId, groupSubscriptionsResponse));
			dispatch(receiveEntities(normalized.entities, loadGroupProductSubscriptions.name));

			return groupSubscriptionsResponse; // Maintain legacy untransformed data
		});
};