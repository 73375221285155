import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ToggleButton.css';

const ToggleButton = ({
	callback,
	className,
	currentValue,
	text,
	value,
}) => {
	const isActive = value === currentValue;

	return (
		<button
			className={classnames(
				'button',
				className,
				{
					hollow: isActive,
					inActive: !isActive,
				}
			)}
			onClick={() => callback(value)}
			type="button"
		>
			{text || value}
		</button>
	);
};

ToggleButton.displayName = 'ToggleButton';

ToggleButton.propTypes = {
	callback: PropTypes.func,
	className: PropTypes.string,
	currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text: PropTypes.node,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ToggleButton.defaultProps = {
	className: '',
};

export default ToggleButton;
