import { useScript } from './useScript';
import config from '../helpers/config';

export function useAppleAuth() {
	const APPLE_AUTH_SCRIPT = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
	const { clientId, redirectURI } = config.get('apple');

	useScript(
		APPLE_AUTH_SCRIPT, {
		callback: () => {
			window.AppleID.auth.init({
				clientId,
				scope: 'email name',
				redirectURI,
				usePopup: true,
			});
		},
	});
};
