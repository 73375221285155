import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.APP], action) => {
	const { type, data } = action;
	switch (type) {
		case ACTIONS.DECREMENT_THROBBER_COUNT: {
			let count = state.throbberVisible - 1;
			if (count < 0) {
				count = 0;
			}

			return {
				...state,
				throbberVisible: count,
				loading: count > 0,
				loaded: count <= 0
			};
		}
		case ACTIONS.INCREMENT_THROBBER_COUNT: {
			const count = state.throbberVisible + 1;

			return {
				...state,
				throbberVisible: count,
				loading: true,
				loaded: false
			};
		}
		case ACTIONS.SET_BOOK_COLLAGE_MODAL_OPEN: {
			const { isOpen } = action;

			return {
				...state,
				isBookCollageModalOpen: isOpen
			};
		}
		case ACTIONS.SET_MODAL_OPEN: {
			const { isOpen } = action;

			return {
				...state,
				isModalOpen: isOpen
			};
		}
		case ACTIONS.SET_CONTEXT_VISIBLE:
			return {
				...state,
				contextVisible: data
			};
		case ACTIONS.SET_PREVIEW_URL:
			const previewUrl = data.previewUrl;

			return {
				...state,
				previewUrl
			};
		case ACTIONS.ANGULAR_LOADED:
			return {
				...state,
				isAngularLoading: false
			};
		case ACTIONS.ANGULAR_LOADING:
			return {
				...state,
				isAngularLoading: true
			};
		case ACTIONS.SET_SCROLLED:
			return {
				...state,
				isScrolled: true,
				isScrolledToTop: false,
			};
		case ACTIONS.SET_SCROLLED_FROM_BOTTOM:
			return {
				...state,
				isScrolledToBottom: false,
			};
		case ACTIONS.SET_SCROLLED_TO_BOTTOM:
			return {
				...state,
				isScrolledToBottom: true,
			};
		case ACTIONS.SET_SCROLLED_TO_TOP:
			return {
				...state,
				isScrolled: false,
				isScrolledToBottom: false,
				isScrolledToTop: true
			};
		default:
			return state;
	}
};
