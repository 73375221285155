import React from 'react';
import PropTypes from 'prop-types';

const HeadingWrapper = ({
	children,
	textStyle
}) => {
	if (!textStyle) return children;

	switch (textStyle) {
		case 'largeHeader': return <h1>{children}</h1>;
		case 'mediumHeader': return <h2>{children}</h2>;
		case 'smallHeader': return <h3>{children}</h3>;
		default:
			return children;
	};
};

HeadingWrapper.propTypes = {
	children: PropTypes.node,
	textStyle: PropTypes.string,
};

export default HeadingWrapper;
