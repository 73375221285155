import React from 'react';
import PropTypes from 'prop-types';

import { SOCIAL_PLATFORMS } from '../../const/socialPlatforms';

const UsernameInstagram = ({ username, platform }) => {
	if (platform !== SOCIAL_PLATFORMS.INSTAGRAM) return null;

	return <span className="name">{username}</span>;
};

UsernameInstagram.displayName = 'UsernameInstagram';

UsernameInstagram.propTypes = {
	username: PropTypes.string,
	platform: PropTypes.string
};

export default UsernameInstagram;
