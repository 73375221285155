import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import deep from 'deep-get-set';

import connectContext from '../../../helpers/connectContext';
import { Consumer } from '../../../helpers/context';
import './Overlay.css';

export const Overlay = ({ expanded, onRequestCollapse, openDropdowns }) => {
	if (!expanded && Object.keys(openDropdowns).length === 0) return null;

	const classes = classnames('overlay', { 'links-o': expanded, 'products-o': openDropdowns[`products`] });

	return (
		<div
			onClick={() => {
				onRequestCollapse && onRequestCollapse();
			}}
			className={classes}
		/>
	);
};

Overlay.propTypes = {
	expanded: PropTypes.bool,
	onRequestCollapse: PropTypes.func,
	openDropdowns: PropTypes.object
};

Overlay.defaultProps = {
	openDropdowns: {},
	expanded: false
};

const mapContextToProps = (context) => {
	const expanded = deep(context, 'expanded');
	const onRequestCollapse = deep(context, 'onRequestCollapse');
	const openDropdowns = deep(context, 'openDropdowns');

	return {
		expanded,
		onRequestCollapse,
		openDropdowns
	};
};

export default connectContext(Consumer, mapContextToProps)(Overlay);
