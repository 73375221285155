import React from 'react';
import PropTypes from 'prop-types';

import { convertActionUri, convertImageUrl } from '../DuploBlock.helpers';
import Wrapper from '../Wrapper/Wrapper';
import MaybeLinkWrap from '../../MaybeLinkWrap/MaybeLinkWrap';
import './ImageWithCaption.css';

const ImageWithCaption = ({
	actionUri,
	analyticEvent,
	analyticObj,
	imageCaption,
	imageCaptionURL,
}) => (
	<Wrapper className="duplo-block duplo-block-image-with-caption">
		<MaybeLinkWrap
			analyticEvent={analyticEvent}
			analyticObj={analyticObj}
			path={actionUri && convertActionUri(actionUri)}
		>
			<img
				src={convertImageUrl(imageCaptionURL)}
				alt=""
				className="gallery-image"
			/>
			<p className="caption">{imageCaption}</p>
		</MaybeLinkWrap>
	</Wrapper>
);

ImageWithCaption.propTypes = {
	actionUri: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	imageCaption: PropTypes.string,
	imageCaptionURL: PropTypes.string,
};

export default ImageWithCaption;
