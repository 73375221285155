import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MaybeLinkWrap from '../../../components/MaybeLinkWrap/MaybeLinkWrap';
import SquareImage from '../../../components/SquareImage/SquareImage';
import { CONFIGURABLE_LINK_TYPES, SPACING } from '../../../const';

const SimilarProductDiv = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: ${SPACING.MEDIUM};
`;

const MaybeLinkStyled = styled(MaybeLinkWrap)`
	color: inherit;
	text-decoration: none;
	text-transform: none;
	text-decoration-color: none;

	${SimilarProductDiv}:hover & {
		color: inherit;
		text-decoration: none;
		text-transform: none;
		text-decoration-color: none;
	}
`;

const ProductImageContainer = styled.div`
	padding-bottom: ${SPACING.MEDIUM};
`;

const ProductDetailsDiv = styled.div`
	text-align: left;
`;

const ProductTitleDiv = styled.div`
	color: #1C1C1E;
	font-size: 20px;
	font-weight: 700;
	letter-spacing: 0px;
	line-height: 27px;
	margin-bottom: 10px;
	text-align: left;

	${SimilarProductDiv}:hover & {
		text-decoration: underline;
	}
`;

const ProductSubTextDiv = styled.div`
	color: #8E8E93;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.25px;
	line-height: 22px;
	text-align: left;
`;

const SimilarProduct = ({ product }) => (
	<SimilarProductDiv>
		<MaybeLinkStyled
			path={product.link}
			linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK}>
			<ProductImageContainer>
				<SquareImage
					src={product.imageUrl}
					alt={product.imageAlt}/>
			</ProductImageContainer>
			<ProductDetailsDiv>
				<ProductTitleDiv>
					{product.title}
				</ProductTitleDiv>
				<ProductSubTextDiv>
					{product.subtext}
				</ProductSubTextDiv>
			</ProductDetailsDiv>
		</MaybeLinkStyled>
	</SimilarProductDiv>
);

SimilarProduct.displayName = 'SimilarProduct';

SimilarProduct.propTypes = {
	product: PropTypes.object,
};

export default SimilarProduct;
