import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BrandValue from './BrandValue';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import withWindowSize from '../../components/WindowSize/withWindowSize';
import {
	DEVICE_WIDTH,
	mediaQueryBetween,
	mediaQueryStartingFrom,
	mediaQueryUpTo,
	SCROLL_DIRECTION,
	SPACING,
} from '../../const';
import { useIsHover } from '../../hooks';
import {
	Carousel,
	CarouselButton,
	CarouselContainer,
	CarouselItem,
	CarouselPagination,
} from '../../components';

const BrandValuesContainer = styled(PageFlowContainer)`
	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		padding: ${SPACING.BIG};
		padding-bottom: ${SPACING.BIGGER};
		padding-top: ${SPACING.BIGGER};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		padding-bottom: 117px;
		padding-top: 88px;
	}
`;

const MissionText = styled.div`
	color: #333333;
	display: flex;
	flex-direction: column;
	font-family: Circular-Pro-Medium;
	margin-bottom: ${SPACING.BIGGEST};
	text-align: center;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 21px;
		line-height: 28px;
		letter-spacing: 0.0125em;
		margin: auto;
		max-width: 230px;
		padding-bottom: ${SPACING.BIG};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 40px;
		line-height: 46.88px;
	}
`;

const BrandValuesRow = styled.div`
	display: grid;
	margin-left: 10%;
	margin-right: 10%;
	justify-items: center;

	${mediaQueryBetween(DEVICE_WIDTH.TABLET, DEVICE_WIDTH.LAPTOP)} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		margin-left: 20%;
		margin-right: 20%;
		row-gap: ${SPACING.MEDIUM};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.LAPTOP)} {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
`;

const BrandValues = ({
	brandValues,
	missionText,
	windowSize,
}) => {
	const { hoverProps, isHover } = useIsHover();

	if (!brandValues || !brandValues.length) return null;

	const isMobile = windowSize.width < DEVICE_WIDTH.TABLET;

	return (
		<BrandValuesContainer
			{...hoverProps}>
			<MissionText>
				{missionText}
			</MissionText>
			{isMobile ? (
				<CarouselContainer>
					<Carousel
						duration={500}
						interval={5000}>
						{brandValues.map((brandValue, index) => (
							<CarouselItem
								key={index}
								gutter={false}>
								<BrandValue key={brandValue.title} brandValue={brandValue} />
							</CarouselItem>
						))}
					</Carousel>
					{isHover && (<CarouselButton direction={SCROLL_DIRECTION.LEFT} color="#00CBB0" />)}
					{isHover && (<CarouselButton direction={SCROLL_DIRECTION.RIGHT} color="#00CBB0" />)}
					<CarouselPagination className="text-center" color="#00CBB0" />
				</CarouselContainer>
			) : (
				<BrandValuesRow>
					{brandValues.map((brandValue) => (
						<BrandValue key={brandValue.title} brandValue={brandValue} />
					))}
				</BrandValuesRow>
			)}
		</BrandValuesContainer>
	);
};

BrandValues.displayName = 'BrandValues';

BrandValues.propTypes = {
	brandValues: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		pngAlt: PropTypes.string,
		pngUrl: PropTypes.string,
		gifAlt: PropTypes.string,
		gifUrl: PropTypes.string,
	})),
	missionText: PropTypes.string,
	windowSize: PropTypes.object,
};

export default withWindowSize(BrandValues);
