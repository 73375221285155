import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconPrinter = (props) => (
	<Svg
		{...props}
		name="printer"
		viewBox="0 0 120 120"
	>
		<path
			d="M85.17,47.07H34.83a1,1,0,0,1-1-1V19.22a1,1,0,0,1,1-1H85.17a1,1,0,0,1,1,1V46.05A1,1,0,0,1,85.17,47.07ZM35.84,45H84.16V20.24H35.84Z"
		/>
		<path
			d="M85.17,101.79H34.83a1,1,0,0,1-1-1V74a1,1,0,0,1,1-1H85.17a1,1,0,0,1,1,1v26.83A1,1,0,0,1,85.17,101.79Zm-49.33-2H84.16V75H35.84Z"
		/>
		<path
			d="M98.12,85.93h-13a1,1,0,0,1,0-2H97.1V47.07H22.9V83.9H34.83a1,1,0,1,1,0,2H21.88a1,1,0,0,1-1-1V46.05a1,1,0,0,1,1-1H98.12a1,1,0,0,1,1,1V84.91A1,1,0,0,1,98.12,85.93Z"
		/>
		<path
			d="M92.57,75H27.44a1,1,0,1,1,0-2H92.57a1,1,0,0,1,0,2Z"
		/>
		<path
			d="M93.88,47.07H26.12a1,1,0,0,1-1-1v-8.7a1,1,0,0,1,1-1h8.71a1,1,0,1,1,0,2h-7.7V45H92.87V38.37h-7.7a1,1,0,0,1,0-2h8.71a1,1,0,0,1,1,1v8.7A1,1,0,0,1,93.88,47.07Z"
		/>
		<path
			d="M88.5,58.32a3.27,3.27,0,1,1,3.26-3.26A3.26,3.26,0,0,1,88.5,58.32Zm0-4.5a1.24,1.24,0,1,0,0,2.47,1.24,1.24,0,0,0,0-2.47Z"
		/>
		<path
			d="M80.13,58.32a3.27,3.27,0,1,1,3.27-3.26A3.27,3.27,0,0,1,80.13,58.32Zm0-4.5a1.24,1.24,0,1,0,1.23,1.24A1.24,1.24,0,0,0,80.13,53.82Z"
		/>
	</Svg>
);

IconPrinter.displayName = 'IconPrinter';

IconPrinter.propTypes = iconProps;

IconPrinter.defaultProps = defaultIconProps;

export default IconPrinter;
