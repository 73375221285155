export const ORDER_FORM_SECTIONS = {
	ADDRESS: 'addressState',
	CODE: 'codeState',
	PAYMENT_METHOD: 'paymentMethodState',
	SHIPPING_METHOD: 'shippingState',
	SUBSCRIPTION: 'subscriptionState',
};

export const ORDER_FORM_SECTION_STATES = {
	CHOSEN: 'CHOSEN',
	CHOOSE: 'CHOOSE',
	ADD: 'ADD',
};

export const TOGGLE_TYPES = {
	ADD: 'ADD',
	CHOOSE: 'CHOOSE',
};