import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';
import { safeToString } from '@chtbks/helpers';

import * as MomentMediaModel from './momentMedia.model'
import { ENTITIES } from '../const/entities/entities';
import { isLayoutCollage } from '../helpers/groups/groups';

export const momentFromJson = (json = {}) => {
	const mediaPhoto = deep(json, 'Media.Photo');
	const photo = mediaPhoto ? MomentMediaModel.momentMediaFromJson(mediaPhoto) : undefined;
	const pageLayoutType = deep(json, 'PageLayoutType');
	const text = deep(json, 'Text');
	const caption = isLayoutCollage(pageLayoutType) ? text : deep(json, 'Media.Caption')

	return {
		caption,
		captionHidden: deep(json, 'CaptionHidden'),
		downloadUrl: deep(json, 'Media.DownloadUrl'),
		height: deep(json, 'Media.Photo.Height'),
		id: safeToString(deep(json, 'ID')),
		isContinuation: deep(json, 'IsContinuation'),
		isLocked: deep(json, 'IsLocked'),
		mediaId: deep(json, 'Media.MediaID'),
		pageLayoutType,
		photo,
		previousMomentId: safeToString(deep(json, 'PreviousMomentId')) || null,
		skewyPageUrl: deep(json, 'SkewyPageUrl'),
		skewyPrintUrl: deep(json, 'SkewyPrintUrl'),
		text,
		width: deep(json, 'Media.Photo.Width'),
	};
};

export const momentsFromJson = (jsonArray = []) => jsonArray.map(momentFromJson);

export const maybeTransformMoment = (moment) => deep(moment, 'id') ? moment : momentFromJson(moment);

export const momentSchema = new schema.Entity(ENTITIES.MOMENT);
export const momentsSchema = new schema.Array(momentSchema);

export const normalizeMoment = (moment) => normalize(moment, momentSchema);

export const getDownloadUrl = (moment) => deep(moment, 'downloadUrl');

export const getHiResImageUrl = (moment) => {
	const iPhoneHiResSlideshow = MomentMediaModel.getIPhoneHiResSlideshow(getPhoto(moment));
	const downloadUrl = getDownloadUrl(moment);
	const isGoogle = (/^http[s]?:\/\/lh3.googleusercontent.com/i.test(iPhoneHiResSlideshow));

	return isGoogle
		? downloadUrl.replace(/\/s300\//, '/s1280/')
		: iPhoneHiResSlideshow;
};

const getPhoto = (moment) => deep(moment, 'photo');
