import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Instructions from './Instructions/Instructions'
import Benefits from '../components/Benefits/Benefits';
import Faqs from '../components/Faqs/Faqs';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import HeroInfo from '../components/HeroInfo/HeroInfo';
import InfoCards from '../components/InfoCards/InfoCards';
import ProductSpecs from '../components/ProductSpecs/ProductSpecs';
import Reviews from '../components/Reviews/Reviews';
import UserGeneratedImages from '../components/UserGeneratedImages/UserGeneratedImages';
import * as instagramQuickFlowActions from '../../../actions/instagramQuickFlow.actions';
import * as analyticsActions from '../../../actions/analytics.actions';
import * as appActions from '../../../actions/app.actions';
import { Provider } from '../../../helpers/context/context';
import { queryStringToJson } from '../../../helpers/locationUtils';
import { appStringModel } from '../../../model/appString.model';
import { selectUserIdString } from '../../../selectors/user.selectors';
import { selectInstagramDataSourceMedia } from '../../../selectors/dataSourceMedia.selectors';
import './SeriesPreview.css';

export const SERIES_PREVIEW_URL = '/series-preview';

class SeriesPreview extends Component {

	constructor(props) {
		super(props);

		this.generateBranchLink = this.generateBranchLink.bind(this);
		this.saveSeries = this.saveSeries.bind(this);
		this.triggerLoadEvent = this.triggerLoadEvent.bind(this);
	}

	componentDidMount() {
		const { appActions, instagramQuickFlowActions, location, personId, photos } = this.props;
		const { branchLink, groupId, token } = queryStringToJson(location.search);

		if (groupId && personId) {
			this.triggerLoadEvent();
		}

		if (token) {
			instagramQuickFlowActions.loadInstagramSeriesPreviewPhotos(token);
		}

		if (personId && token) {
			this.saveSeries();
		}

		if (groupId && photos.length && !branchLink) {
			this.generateBranchLink();
		}

		if (!branchLink) appActions.showThrobber(this.componentDidMount.name);
	}

	componentDidUpdate(prevProps) {
		const { location: prevLocation, personId: prevPersonId, photos: prevPhotos } = prevProps;
		const { branchLink: prevBranchLink, groupId: prevGroupId, token: prevToken } = queryStringToJson(prevLocation.search);

		const { appActions, instagramQuickFlowActions, location, personId, photos } = this.props;
		const { branchLink, groupId, token } = queryStringToJson(location.search);

		if (!(prevGroupId && prevPersonId) && (groupId && personId)) {
			this.triggerLoadEvent();
		}

		if (!prevToken && token) {
			instagramQuickFlowActions.loadInstagramSeriesPreviewPhotos(token);
		}

		if (!(prevPersonId && prevToken) && (personId && token)) {
			this.saveSeries();
		}

		if (!(prevPhotos.length && prevGroupId) && (groupId && photos.length) && !branchLink) {
			this.generateBranchLink();
		}

		if (!prevBranchLink && branchLink) appActions.hideThrobber(this.componentDidUpdate.name);
	}

	triggerLoadEvent() {
		const { analyticsActions, location, personId } = this.props;
		const { groupId } = queryStringToJson(location.search);

		analyticsActions.triggerAnalyticsEvent('IGSeriesPreview_Load', {
			chatgroupid: groupId,
			personid: personId,
		});
	}

	saveSeries() {
		const { history, instagramQuickFlowActions, location } = this.props;
		const { groupId, token } = queryStringToJson(location.search);

		if (!groupId) {
			instagramQuickFlowActions.saveSeries(history, token);
		}
	}

	generateBranchLink() {
		const { history, instagramQuickFlowActions, location, photos } = this.props;
		const { groupId, token } = queryStringToJson(location.search);

		instagramQuickFlowActions.generateIGMergeBranchLink(history, groupId, photos, token);
	}

	render() {
		const { location } = this.props;
		const { branchLink, groupId } = queryStringToJson(location.search);

		return (
			<div className="instagram-quickflow-page instagram-series-preview-page">
				<Provider
					value={{
						ctaText: appStringModel({ id: 'web.ig.quickflow.preview.cta.text' }),
						onCtaClick: () => {
							analyticsActions.clickTracking('IGSeriesPreview_Continue', {
								chatgroupId: groupId,
							});
							window.location.href = branchLink;
						},
					}}
				>
					<Instructions />
					<HeroInfo />
					<HeroBanner />
					<Benefits />
					<InfoCards />
					<Faqs />
					<ProductSpecs />
					<Reviews />
					<UserGeneratedImages />
				</Provider>
			</div>
		);
	}
}

SeriesPreview.displayName = 'SeriesPreview';

SeriesPreview.propTypes = {
	analyticsActions: PropTypes.object,
	appActions: PropTypes.object,
	history: PropTypes.object,
	instagramQuickFlowActions: PropTypes.object,
	location: PropTypes.object,
	personId: PropTypes.string,
	photos: PropTypes.array,
};

const mapStateToProps = (state) => ({
	personId: selectUserIdString(state),
	photos: selectInstagramDataSourceMedia(state),
});

const mapDispatchToProps = (dispatch) => ({
	analyticsActions: bindActionCreators(analyticsActions, dispatch),
	appActions: bindActionCreators(appActions, dispatch),
	instagramQuickFlowActions: bindActionCreators(instagramQuickFlowActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(SeriesPreview));
