export const COOKIES = {
	APP: {
		APP_STRING_OVERRIDE: 'appStringOverride',
		APP_VISITED: 'appVisited',
		BLOG_VISITED: 'blogVisited',
		BOOK_UPGRADE_MODAL_DISMISSED: 'bookUpgradeModalDismissed',
		CARD_SCREENS_VIEWED: 'cardScreensViewed',
		CHECKOUT_SOURCE: 'checkoutSource',
		DEV_TOOL_OVERLAY: 'devToolOverlay',
		DISMISS_INSTAGRAM_LEGACY_REAUTH: 'legacyReauth',
		DISMISS_EXCLUDE_TOOLTIP_COOKIE: 'dismissExcludeTooltip',
		FIRST_BOOK_TOOLTIP_COOKIE: 'dismissFirstBookCookie',
		GDPR_DISPLAYED: 'gdprDisplayed',
		MADE_TO_SUFFER: 'madeToSuffer',
		MARKETING_VISITED: 'marketingVisited',
		PRINTED_BOOKS: 'printedBooks',
		TEMP_CARD: 'tempCard',
		UUID: 'cbUUID',
		VISITING_USER_LANGUAGE: 'lcid',
	}
};

export const makeCardScreensViewedCookieKey = (groupId) => `${COOKIES.APP.CARD_SCREENS_VIEWED}-${groupId}`;
export const makeDismissExcludesCookieKey = (groupId) => `${COOKIES.APP.DISMISS_EXCLUDE_TOOLTIP_COOKIE}-${groupId}`;
export const makeFirstBookTooltipCookieKey = (groupId) => `${COOKIES.APP.FIRST_BOOK_TOOLTIP_COOKIE}-${groupId}`;

export const getCookieExpiration = () => new Date(Date.now() + 1000 * 60 * 60 * 24 * 365);
