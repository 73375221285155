import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconClose = (props) => (
	<Svg
		{...props}
		name="close"
		viewBox="0 0 20 20"
	>
		<g strokeWidth="1" fillRule="evenodd">
			<g transform="translate(1.000000, 1.000000)">
				<circle stroke="inherit" fill="inherit" cx="9" cy="9" r="9" />
				<path
					d="M5.31818182,5.31818182 L13.1389121,13.1389121" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square"
				/>
				<path
					d="M5.31818182,5.31818182 L13.0884121,13.0884121" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" transform="translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000)"
				/>
			</g>
		</g>
	</Svg>
);

IconClose.displayName = 'IconClose';

IconClose.propTypes = iconProps;

IconClose.defaultProps = defaultIconProps;

export default IconClose;
