import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconMinus = (props) => (
	<Svg
		{...props}
		name="minus"
		viewBox="0 0 8 2"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-502.000000, -628.000000)" fill="inherit">
				<rect x="502" y="628" width="8" height="2" />
			</g>
		</g>
	</Svg>
);

IconMinus.displayName = 'IconMinus';

IconMinus.propTypes = iconProps;

IconMinus.defaultProps = defaultIconProps;

export default IconMinus;
