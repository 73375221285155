import React from 'react';
import PropTypes from 'prop-types';

import normalizeMeasurement from '../../helpers/normalizeMeasurement';

export const Icon = ({ url, text, margin, wrapperElem }) => {
	if (!url) return null;

	const marginBottom = normalizeMeasurement(margin);
	const style = { marginBottom };
	const WrapperElem = wrapperElem;

	return (
		<WrapperElem className="icon-image" style={style}>
			<img src={url} alt={text || 'Icon'} />
		</WrapperElem>
	);
};

Icon.propTypes = {
	url: PropTypes.string,
	text: PropTypes.string,
	margin: PropTypes.string,
	wrapperElem: PropTypes.string
};

Icon.defaultProps = {
	wrapperElem: 'div'
};

export default Icon;
