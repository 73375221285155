import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import clickHandler from '../../helpers/clickHandler';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';

export const Svg = (props) => (
	<svg
		className={classnames(props.className, 'Icon', `icon-${props.name}`)}
		stroke={props.hasStroke ? props.color : undefined}
		fill={props.color}
		height={props.height || props.size}
		onClick={(e) => clickHandler(props.onClick, e)}
		viewBox={props.viewBox}
		width={props.size}
		style={props.style}
	>
		{props.children}
	</svg>
);

Svg.propTypes = {
	...iconProps,
	children: PropTypes.node,
    name: PropTypes.string,
	viewBox: PropTypes.string,
};

Svg.defaultProps = defaultIconProps;

export default Svg;
