import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { REDUCERS } from '../const/reducers.const';
import { COOKIES, makeFirstBookTooltipCookieKey, makeDismissExcludesCookieKey, makeCardScreensViewedCookieKey } from '../helpers/cookies';

export const selectAppCookiesState = (state) => deep(state, `${REDUCERS.APP_COOKIE}`);

/**
 * The appCookie is hydrated asynchronously. Until the `_persist.rehydrated` value is true, the default values will come through.
 */
export const selectAreCookiesRehydrated = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, '_persist.rehydrated') || false
);

export const selectAppStringOverrideMap = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.APP_STRING_OVERRIDE) || {}
);

export const selectAppStringOverrideKeyValuePairs = createSelector(
	selectAppStringOverrideMap,
	(appStringOverrides) => Object.keys(appStringOverrides).map((key) => ({
		key,
		value: appStringOverrides[key],
	}))
);

export const selectBookUpgradeModalDismissed = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.BOOK_UPGRADE_MODAL_DISMISSED) || false
);

export const selectCardScreensViewedCookieFunc = createSelector(
	selectAppCookiesState,
	(appCookie) => (groupId) => appCookie[makeCardScreensViewedCookieKey(groupId)] || [false, false, false]
);

export const selectCheckoutSourceCookie = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.CHECKOUT_SOURCE) || {}
);

export const selectIsFirstBookTooltipDismissedFunc = createSelector(
	selectAppCookiesState,
	(appCookie) => (groupId) => appCookie[makeFirstBookTooltipCookieKey(groupId)] || false
);

export const selectIsExcludedMomentsTooltipDismissedFunc = createSelector(
	selectAppCookiesState,
	(appCookie) => (groupId) => appCookie[makeDismissExcludesCookieKey(groupId)] || false
);

export const selectIsMadeToSuffer = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.MADE_TO_SUFFER) || false
);

export const selectHasGdprNoticeAlreadyDisplayed = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.GDPR_DISPLAYED) || false
);

export const selectPrintedBooksCookie = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.PRINTED_BOOKS)
);

export const selectTempCardCookie = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.TEMP_CARD)
);

export const selectTempCardId = createSelector(
	selectTempCardCookie,
	(tempCardCookie) => deep(tempCardCookie, 'id')
);

export const selectUserHasVisitedApp = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.APP_VISITED) || false
);

export const selectUserHasVisitedBlog = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.BLOG_VISITED) || false
);

export const selectUserHasVisitedMarketing = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.MARKETING_VISITED) || false
);

export const selectUuidCookie = createSelector(
	selectAppCookiesState,
	(appCookie) => deep(appCookie, COOKIES.APP.UUID) || ''
);
