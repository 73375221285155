import React from 'react';

import dataUserGeneratedImages from './UserGeneratedImages.data';
import CtaButton from '../CtaButton/CtaButton';
import {
	CarouselItem,
	SelectableCarousel,
} from '../../../../chatponents';
import './UserGeneratedImages.css';

const UserGeneratedImages = () => (
	<div className="instagram-quickflow-page-user-generated-images">
		<SelectableCarousel
			duration={500}
			interval={5000}
		>
			{dataUserGeneratedImages.map((ugi, index) => (
				<CarouselItem
					key={index}
				>
					<img
						alt=""
						src={ugi.image}
					/>
					<p className="username">{ugi.username}</p>
				</CarouselItem>
			))}
		</SelectableCarousel>
		<CtaButton trackingName="User Generated Images" />
	</div>
);

export default UserGeneratedImages;
