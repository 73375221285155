import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { AsyncComponentProvider } from 'react-async-component';
import PropTypes from 'prop-types';

import AsyncApp from './AsyncApp';
import { create as createDefaultStore } from '../../store';

class Root extends Component {
	constructor(props) {
		super(props);
		this.state = { persistorBootstrapped: false };
		this.store = props.reduxStore || createDefaultStore();

		this.handlePersistorState = this.handlePersistorState.bind(this);
	}

	componentDidMount() {
		this.persistor = persistStore(this.store);

		this.unsubscribe = this.persistor.subscribe(this.handlePersistorState);
	}

	componentWillUnmount() {
		this.unsubscribe && this.unsubscribe();
	}

	handlePersistorState() {
		const { bootstrapped } = this.persistor.getState();

		if (bootstrapped) {
			this.setState({ persistorBootstrapped: true });
			this.unsubscribe && this.unsubscribe();
		}
	}

	render() {
		const { rehydrateState } = this.props;
		const { persistorBootstrapped } = this.state;

		return (
			<AsyncComponentProvider rehydrateState={rehydrateState}>
				<ReduxProvider store={this.store}>
					<BrowserRouter>
						<AsyncApp showThrobber={!persistorBootstrapped} store={this.store} />
					</BrowserRouter>
				</ReduxProvider>
			</AsyncComponentProvider>
		);
	}
}

Root.propTypes = {
	reduxStore: PropTypes.object,
	rehydrateState: PropTypes.object
};

Root.displayName = 'Root';

export default Root;
