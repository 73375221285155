import {
	QUICKFLOW_SOURCE_CAMERA_ROLL,
	QUICKFLOW_SOURCE_FACEBOOK,
	QUICKFLOW_SOURCE_FAVORITES,
} from '../QuickFlow.const';

export const returnSourceIdFromSourceString = (source) => {
	const SOURCE_LOCAL_ID = 1
	const SOURCE_FACEBOOK_ID = 3
	const SOURCE_FAVORITES_ID = 99

	switch (source) {
		case QUICKFLOW_SOURCE_CAMERA_ROLL:
			return SOURCE_LOCAL_ID
		case QUICKFLOW_SOURCE_FACEBOOK:
			return SOURCE_FACEBOOK_ID
		case QUICKFLOW_SOURCE_FAVORITES:
			return SOURCE_FAVORITES_ID;
		default:
			return null;
	}
};
