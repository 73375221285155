import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TwoVertImagesDiv = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	column-gap: 10px;
`;

const TwoProductImagesSideBySide = ({
	verticalImage2Alt,
	verticalImage2Url,
	verticalImageAlt,
	verticalImageUrl,
}) => (
	<TwoVertImagesDiv>
		<img src={verticalImageUrl} alt={verticalImageAlt} />
		<img src={verticalImage2Url} alt={verticalImage2Alt} />
	</TwoVertImagesDiv>
);

TwoProductImagesSideBySide.displayName = 'TwoProductImagesSideBySide';

TwoProductImagesSideBySide.propTypes = {
	verticalImage2Alt: PropTypes.string,
	verticalImage2Url: PropTypes.string,
	verticalImageAlt: PropTypes.string,
	verticalImageUrl: PropTypes.string,
};

export default TwoProductImagesSideBySide;
