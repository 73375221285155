import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconAddPhoto = (props) => (
	<Svg
		{...props}
		name="add-photo"
		viewBox="0 0 58 46"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path
				d="M48,2 L2,2 L2,36 L6,36 L6,38 L0,38 L0,0 L50,0 L50,6 L48,6 L48,2 L48,2 Z M56,10 L10,10 L10,44 L56,44 L56,10 L56,10 Z M8,46 L8,8 L58,8 L58,46 L8,46 L8,46 Z"
				fill="inherit"
				transform="translate(29.000000, 23.000000) scale(-1, 1) translate(-29.000000, -23.000000)"
			/>
			<path
				d="M27.0033175,24.9936975 L27.0033175,18.5021008 C27.0033175,17.6722689 26.3288732,17 25.4989495,17 C24.6669247,17 23.9945814,17.6722689 23.9945814,18.5021008 L23.9945814,24.9936975 L17.504368,24.9936975 C16.6723432,24.9936975 16,25.6659664 16,26.4957983 C16,27.3277311 16.6723432,28.0021008 17.504368,28.0021008 L23.9945814,28.0021008 L23.9945814,34.4936975 C23.9945814,35.3256303 24.6690258,36 25.4989495,36 C26.3288732,36 27.0033175,35.3277311 27.0033175,34.4936975 L27.0033175,28.0021008 L33.495632,28.0021008 C34.3276568,28.0021008 35,27.3298319 35,26.4957983 C35,25.6680672 34.3276568,24.9936975 33.495632,24.9936975 L27.0033175,24.9936975 Z"
				fill="inherit"
			/>
		</g>
	</Svg>
);

IconAddPhoto.displayName = 'IconAddPhoto';

IconAddPhoto.propTypes = iconProps;

IconAddPhoto.defaultProps = defaultIconProps;

export default IconAddPhoto;
