import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
	Carousel,
	CarouselButton,
	CarouselContainer,
	CarouselItem,
	CarouselPagination,
	MaybeLinkWrap,
	PageFlowContainer,
	withWindowSize,
} from '../../components';
import {
	CONFIGURABLE_LINK_TYPES,
	DEVICE_WIDTH,
	mediaQueryStartingFrom,
	mediaQueryUpTo,
	SCROLL_DIRECTION,
	SPACING,
} from '../../const';
import { useIsHover } from '../../hooks';

const Container = styled.div`
	background-color: ${(props) => props.backgroundColor};
`;

const ContentContainer = styled(PageFlowContainer)`
	padding-bottom: 20px;
	padding-top: 78px;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		padding-left: ${SPACING.MEDIUM};
		padding-right: ${SPACING.MEDIUM};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		padding-left: ${SPACING.BIGGER};
		padding-right: ${SPACING.BIGGER};
	}
`;

const noLinkStyles = css`
	color: inherit;
	text-decoration: none;
	text-transform: none;
	text-decoration-color: none;

	&:hover {
		color: inherit;
		text-decoration: none;
		text-transform: none;
		text-decoration-color: none;
	}
`;

const MaybeLinkStyled = styled(MaybeLinkWrap)`
	${noLinkStyles};

	display: grid;
	margin: auto;
	justify-items: center;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		row-gap: ${SPACING.BIG};
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		row-gap: ${SPACING.BIGGER};
	}
`;

const Logo = styled.img`
	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		max-height: 75px;
		max-width: 300px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		max-height: 100px;
		max-width: 600px;
	}
`;

const QuoteTextContainer = styled.div`
	font-family: Circular-Pro-Medium;
	text-align: center;
	color: #333333;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 21px;
		line-height: 28px;
		max-width: 320px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 25px;
		line-height: 35px;
		max-width: 670px;
	}
`;

const Pagination = styled(CarouselPagination)`
	text-align: center;
`;

const PressQuoteV2 = ({
	backgroundColor,
	paginationColor,
	quotes,
}) => {
	const { hoverProps, isHover } = useIsHover();

	if (!quotes || !quotes.length) return null;

	return (
		<Container
			backgroundColor={backgroundColor}
			{...hoverProps}>
			<ContentContainer>
				<CarouselContainer>
					<Carousel>
						{quotes.map((quote) => (
							<CarouselItem
								key={quote.quote}
								gutter={false}>
								<MaybeLinkStyled
									path={quote.url}
									linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK}
									target="_blank">
									<Logo src={quote.imageUrl} alt={quote.imageAlt} />
									<QuoteTextContainer>
										{quote.quote}
									</QuoteTextContainer>
								</MaybeLinkStyled>
							</CarouselItem>
						))}
					</Carousel>
					{isHover && (<CarouselButton direction={SCROLL_DIRECTION.LEFT} color={paginationColor} />)}
					{isHover && (<CarouselButton direction={SCROLL_DIRECTION.RIGHT} color={paginationColor} />)}
					<Pagination
						color={paginationColor}
					/>
				</CarouselContainer>
			</ContentContainer>
		</Container>
	);
};

PressQuoteV2.displayName = 'PressQuoteV2';

PressQuoteV2.propTypes = {
	backgroundColor: PropTypes.string,
	paginationColor: PropTypes.string,
	quotes: PropTypes.arrayOf(PropTypes.shape({
		imageAlt: PropTypes.string,
		imageUrl: PropTypes.string,
		quote: PropTypes.string,
		quoteTextColor: PropTypes.string,
		url: PropTypes.string,
	})),
};

export default withWindowSize(PressQuoteV2);
