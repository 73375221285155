import React from 'react';
import PropTypes from 'prop-types';

import BetterInTheApp from './BetterInTheApp';
import LoveItGuarantee from './LoveItGuarantee';

const BetterInTheAppAndLoveItGuarantee = ({
	betterInTheAppBackgroundColor,
	betterInTheAppDescription,
	betterInTheAppTitle,
	demoImageAlt,
	demoImageUrl,
	loveItGuarantee,
	loveItGuaranteeBackgroundColor,
	sealImageAlt,
	sealImageUrl,
}) => (
	<>
		<BetterInTheApp
			betterInTheAppBackgroundColor={betterInTheAppBackgroundColor}
			betterInTheAppDescription={betterInTheAppDescription}
			betterInTheAppTitle={betterInTheAppTitle}
			demoImageAlt={demoImageAlt}
			demoImageUrl={demoImageUrl}
		/>
		<LoveItGuarantee
			betterInTheAppBackgroundColor={betterInTheAppBackgroundColor}
			loveItGuarantee={loveItGuarantee}
			loveItGuaranteeBackgroundColor={loveItGuaranteeBackgroundColor}
			sealImageAlt={sealImageAlt}
			sealImageUrl={sealImageUrl}
		/>
	</>
);

BetterInTheAppAndLoveItGuarantee.displayName = 'BetterInTheAppAndLoveItGuarantee';

BetterInTheAppAndLoveItGuarantee.propTypes = {
	betterInTheAppBackgroundColor: PropTypes.string,
	betterInTheAppDescription: PropTypes.string,
	betterInTheAppTitle: PropTypes.string,
	demoImageAlt: PropTypes.string,
	demoImageUrl: PropTypes.string,
	loveItGuarantee: PropTypes.string,
	loveItGuaranteeBackgroundColor: PropTypes.string,
	sealImageAlt: PropTypes.string,
	sealImageUrl: PropTypes.string,
};

export default BetterInTheAppAndLoveItGuarantee;
