import React from 'react';

import dataHeroBanner from './HeroBanner.data';
import {
	Carousel,
	CarouselButton,
	CarouselContainer,
	CarouselItem,
	SCROLL_DIRECTION
} from '../../../../chatponents';

const HeroBanner = () => (
	<div className="instagram-quickflow-page-hero-banner">
		<CarouselContainer>
			<Carousel
				duration={500}
				interval={5000}
			>
				{dataHeroBanner.map((image, index) => (
					<CarouselItem
						key={index}
						gutter={false}
					>
						<img
							alt=""
							src={image}
						/>
					</CarouselItem>
				))}
			</Carousel>
			<CarouselButton direction={SCROLL_DIRECTION.LEFT} />
			<CarouselButton direction={SCROLL_DIRECTION.RIGHT} />
		</CarouselContainer>
	</div>
);

export default HeroBanner;
