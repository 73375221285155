import deep from 'deep-get-set';

import { editPathForCards, editPathForBooks } from './groupEditPath';
import {
	isTypeBook,
	isTypeCards,
	isTypeCustom,
	isTypeCustomBook,
	isTypeCustomBookOrLayflat,
	isTypeCustomPrints,
	isTypeHighlightsBook,
	isTypeHighlightsGiftBook,
	isTypeLayflat,
	isTypePrints,
	isTypeSeries,
	isTypeSeriesBook,
	isTypeSeriesPrints,
	isTypeSubscriptionBook,
	isTypeWallTile,
} from './groupTypes';
import { BOOK_CREATION_MODEL_TYPES } from '../../const/bookCreationModelTypes.const';
import { BOOK_SIZE_STRINGS, BOOK_SIZES } from '../../const/bookSizes.const';
import { CARD_EDGE_TYPES } from '../../const/cards/cardEdgeTypes.const';
import { CARD_TYPES } from '../../const/cardTypes.const';
import { DATA_SOURCE_NAMES } from '../../const/dataSource.const';
import { GROUP_PROPERTIES } from '../../const/groupProperties.const';
import { LAYOUT_TYPES } from '../../const/layoutTypes.const';
import { appStringModel } from '../../model/appString.model';

// Group data
export const getGroupActiveCoverBundleId = (group) => deep(group, 'activeCoverBundleId');

export const getGroupBookCreationModelType = (group) => deep(group, 'bookCreationModelType');

export const getGroupBookSize = (group) => deep(group, 'bookSize');

export const getGroupBookSizeStr = (group) => {
	const bookSize = getGroupBookSize(group);

	return bookSize != null && deep(BOOK_SIZE_STRINGS, `${bookSize}`);
};

export const getGroupCoverBundle = (group, groupCoverBundles = {}) => deep(groupCoverBundles, `${getGroupActiveCoverBundleId(group)}`) || {};

export const getGroupDeleteKey = (group) => {
	if (isGroupTypeOfSeriesPrints(group)) return 'web.Delete Print Series';
	if (isGroupTypeOfCustomPrints(group)) return 'web.Delete Print Pack';
	if (isGroupTypeOfSeriesBook(group)) return 'web.Delete Series';
	if (isGroupTypeOfCards(group)) return 'web.delete_card';

	return 'web.Delete Book';
};

export const getGroupEditPath = (group) => isGroupTypeOfCards(group) ? editPathForCards(getGroupId(group)) : editPathForBooks(getGroupId(group));

export const getGroupId = (group) => deep(group, 'id');

export const getGroupIsHardcover = (group) => deep(group, 'isHardcover');

const getGroupBookSizeAppString = (group) => {
	const bookSizeAppStringMap = {
		[BOOK_SIZES.SIX_BY_SIX]: appStringModel({id: 'web.6x6' }),
		[BOOK_SIZES.EIGHT_BY_EIGHT]: appStringModel({ id: 'web.8x8' }),
		[BOOK_SIZES.TEN_BY_TEN]: appStringModel({ id: 'web.10x10' }),
	};

	return bookSizeAppStringMap[getGroupBookSize(group)] || appStringModel({ id: '' });
};

export const getGroupProductAttributeAppStrings = (group) => {
	const product = appStringModel({ id: 'web.cart.seriesAdditionalCopy' });
	const size = getGroupBookSizeAppString(group);
	const type = isGroupHardcover(group)
		? appStringModel({ id: 'web.cart.seriesCheckoutHardcover' })
		: appStringModel({ id: 'web.cart.seriesCheckoutSoftcover' });
	const premium = isGroupCoverADesignerBundle(group)
		? appStringModel({ id: 'web.cart.seriesCheckoutPremium' })
		: appStringModel({ id: '' });

	return { premium, product, size, type };
};


export const getGroupDescriptionAppStrings = (group) => {
	const appStrings = [];

	if (isGroupTypeOfBook(group)) {
		const bookSize = getGroupBookSize(group);
		if (isBookSize6x6(bookSize)) appStrings.push(appStringModel({ id: 'web.6x6' }));
		if (isBookSize8x8(bookSize)) appStrings.push(appStringModel({ id: 'web.8x8' }));
		if (isBookSize10x10(bookSize)) appStrings.push(appStringModel({ id: 'web.10x10' }));
		if (getGroupIsHardcover(group)) appStrings.push(appStringModel({ id: 'web.Hard Cover' }));
	}
	if (isGroupTypeOfSeries(group)) appStrings.push(appStringModel({ id: 'web.Photo Book Series' }));
	if (isGroupTypeOfCustomBook(group)) appStrings.push(appStringModel({ id: 'web.Custom Book' }));
	if (isGroupTypeOfLayflat(group)) appStrings.push(appStringModel({ id: 'web.Layflat' }));
	if (isGroupTypeOfSeriesPrints(group)) appStrings.push(appStringModel({ id: 'web.Print Series' }));
	if (isGroupTypeOfCustomPrints(group)) appStrings.push(appStringModel({ id: 'web.Custom Print Pack' }));
	if (isGroupTypeOfHighlightsGiftBook(group)) appStrings.push(appStringModel({ id: 'web.Highlights Book' }));
	if (isGroupTypeOfCards(group)) appStrings.push(appStringModel({ id: 'web.cards' }));

	return appStrings;
};

export const getGroupProductAppString = (group) => {
	if (isGroupTypeOfSeriesPrints(group)) return appStringModel({ id: 'web.Photo Book Series' });
	if (isGroupTypeOfCustomPrints(group)) return appStringModel({ id: 'web.Prints' });
	if (isGroupTypeOfPostCards(group)) return appStringModel({ id: 'web.Post Cards' });
	if (isGroupTypeOfCards(group)) return appStringModel({ id: 'web.Cards' });

	return appStringModel({ id: 'web.Photo Book' });
};

export const getGroupProperties = (group) => deep(group, 'properties') || {};

export const getGroupTitle = (group) => deep(group, 'title') || '';

export const getGroupTotalPageCount = (group) => deep(group, 'totalPageCount') || 0;

export const getGroupProductFilter = (group) => (isGroupTypeOfSeries(group) || isGroupTypeOfCustomBook(group))
	? JSON.stringify({ hardcover: isGroupHardcover(group) })
	: null;

export const getNewGroupTitleAppStringKey = (bookCreationModelType, source) => {
	const src = source ? source.toLowerCase() : '';

	switch (bookCreationModelType) {
		case BOOK_CREATION_MODEL_TYPES.MONTH_BOOK: {
			return 'web.My Highlights Book';
		}
		case BOOK_CREATION_MODEL_TYPES.SIXTY_PAGE_SERIES:
		case BOOK_CREATION_MODEL_TYPES.SIXTY_PAGE_SERIES_GRANDFATHER: {
			if (src === DATA_SOURCE_NAMES.INSTAGRAM) return 'web.My Instagram';
			if (src === DATA_SOURCE_NAMES.FACEBOOK) return 'web.My Facebook';
			if (src === DATA_SOURCE_NAMES.GOOGLE_PHOTOS) return 'web.My Google';

			return 'web.My Photos';
		}
		case BOOK_CREATION_MODEL_TYPES.CUSTOM_BOOK:
		case BOOK_CREATION_MODEL_TYPES.LAYFLAT: {
			return '';
		}
		case BOOK_CREATION_MODEL_TYPES.THIRTY_PAGE_HIGHLIGHT: {
			return 'web.My Highlights Book';
		}
		case BOOK_CREATION_MODEL_TYPES.SERIES_PRINTS: {
			if (src === DATA_SOURCE_NAMES.INSTAGRAM) return 'web.My Instagram Prints';
			if (src === DATA_SOURCE_NAMES.FACEBOOK) return 'web.My Facebook Prints';

			return 'web.My Prints';
		}
		case BOOK_CREATION_MODEL_TYPES.CUSTOM_PRINTS: {
			return 'web.My Prints';
		}
		case BOOK_CREATION_MODEL_TYPES.GIFT_CARD: {
			return 'web.Gift Card';
		}
		case BOOK_CREATION_MODEL_TYPES.HIGHLIGHTS_GIFT_BOOK: {
			return 'web.My Highlights Book';
		}
		case BOOK_CREATION_MODEL_TYPES.CARDS: {
			if (src === DATA_SOURCE_NAMES.INSTAGRAM) return 'web.My Instagram Cards';
			if (src === DATA_SOURCE_NAMES.FACEBOOK) return 'web.My Facebook Cards';
			if (src === DATA_SOURCE_NAMES.GOOGLE_PHOTOS) return 'web.My Google Cards';

			return 'web.My Cards';
		}
		default: {
			return '';
		}
	}
};

export const isGroupCardEdgeRounded = (group) => {
	const properties = getGroupProperties(group);
	const edgeType = deep(properties, `${GROUP_PROPERTIES.CARD_EDGE_TYPE}`);

	return String(edgeType) === String(CARD_EDGE_TYPES.ROUNDED);
};

export const isGroupCoverADesignerBundle = (group) => !!getGroupActiveCoverBundleId(group);

export const isGroupLocked = (group) => isGroupTypeOfCustomBook(group) && deep(group, 'isSingleOrdered');

export const isGroupHardcover = (group) => isGroupTypeOfBook(group) && deep(group, 'isHardcover');

export const isGroupSubscribed = (group) => deep(group, 'isSubscribed');

// Book Size
export const isBookSize6x6 = (bookSize) => bookSize === BOOK_SIZES.SIX_BY_SIX;

export const isBookSize8x8 = (bookSize) => bookSize === BOOK_SIZES.EIGHT_BY_EIGHT;

export const isBookSize10x10 = (bookSize) => bookSize === BOOK_SIZES.TEN_BY_TEN;

export const isGroup6x6 = (group) => isBookSize6x6(getGroupBookSize(group));

export const isGroup8x8 = (group) => isBookSize8x8(getGroupBookSize(group));

export const isGroup10x10 = (group) => isBookSize10x10(getGroupBookSize(group));

// Layouts
export const isLayoutCollageFourPhoto = (layout) => layout === LAYOUT_TYPES.COLLAGE_FOUR_PHOTO;

export const isLayoutCollageTwoLandscape = (layout) => layout === LAYOUT_TYPES.COLLAGE_TWO_LANDSCAPE;

export const isLayoutCollageTwoPortrait = (layout) => layout === LAYOUT_TYPES.COLLAGE_TWO_PORTRAIT;

export const isLayoutCollageThreeLandscape = (layout) => layout === LAYOUT_TYPES.COLLAGE_THREE_LANDSCAPE;

export const isLayoutCollageThreePortrait = (layout) => layout === LAYOUT_TYPES.COLLAGE_THREE_PORTRAIT;

export const isLayoutCollageSixSquare = (layout) => layout === LAYOUT_TYPES.COLLAGE_SIX_SQUARE;

export const isLayoutCollage = (layout) =>
	isLayoutCollageFourPhoto(layout) || isLayoutCollageTwoLandscape(layout) || isLayoutCollageTwoPortrait(layout) || isLayoutCollageThreeLandscape(layout) || isLayoutCollageThreePortrait(layout) || isLayoutCollageSixSquare(layout);

// Group types
export const isGroupTypeOfBook = (group) => isTypeBook(getGroupBookCreationModelType(group));

export const isGroupTypeOfCards = (group) => isTypeCards(getGroupBookCreationModelType(group));

export const isGroupTypeOfCustom = (group) => isTypeCustom(getGroupBookCreationModelType(group));

export const isGroupTypeOfCustomBook = (group) => isTypeCustomBook(getGroupBookCreationModelType(group));

export const isGroupTypeOfCustomBookOrLayflat = (group) => isTypeCustomBookOrLayflat(getGroupBookCreationModelType(group));

export const isGroupTypeOfCustomPrints = (group) => isTypeCustomPrints(getGroupBookCreationModelType(group));

export const isGroupTypeOfHighlightsBook = (group) => isTypeHighlightsBook(getGroupBookCreationModelType(group));

export const isGroupTypeOfHighlightsGiftBook = (group) => isTypeHighlightsGiftBook(getGroupBookCreationModelType(group));

export const isGroupTypeOfLayflat = (group) => isTypeLayflat(getGroupBookCreationModelType(group));

export const isGroupTypeOfPostCards = (group) => getGroupProperties(group)[GROUP_PROPERTIES.CARD_TYPE] === CARD_TYPES.POST_CARD;

export const isGroupTypeOfPrints = (group) => isTypePrints(getGroupBookCreationModelType(group));

export const isGroupTypeOfSeries = (group) => isTypeSeries(getGroupBookCreationModelType(group));

export const isGroupTypeOfSeriesBook = (group) => isTypeSeriesBook(getGroupBookCreationModelType(group));

export const isGroupTypeOfSeriesPrints = (group) => isTypeSeriesPrints(getGroupBookCreationModelType(group));

export const isGroupTypeOfSubscriptionBook = (group) => isTypeSubscriptionBook(getGroupBookCreationModelType(group));

export const isGroupTypeOfWallTile = (group) => isTypeWallTile(getGroupBookCreationModelType(group));
