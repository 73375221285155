import PropTypes from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import { MaybeLinkWrap, withWindowSize } from '../../components';
import { CONFIGURABLE_LINK_TYPES, DEVICE_WIDTH } from '../../const';
import normalizeMeasurement from '../../helpers/normalizeMeasurement';

const AdaptiveImg = styled.img`
	height: auto;
	width: 100%;
	padding-bottom: ${({ paddingBottom }) => normalizeMeasurement(paddingBottom)};
	padding-top: ${({ paddingTop}) => normalizeMeasurement(paddingTop)};
`;

const HomePageBanner = ({
	altText,
	analyticEvent,
	analyticObj,
	breakPoint,
	desktopSrc,
	linkCallback,
	linkUrl,
	mobileLinkUrl,
	mobileSrc,
	paddingBottom,
	paddingTop,
	windowSize,
}) => {
	const [isSquished, setIsSquished] = useState(false);
	const mobilePath = mobileLinkUrl || linkUrl;

	useLayoutEffect(() => {
		setIsSquished(windowSize.width <= breakPoint)
	}, [breakPoint, windowSize.width]);

	const src = isSquished ? mobileSrc : desktopSrc;

	if (!src) {
		return null;
	}

	return (
		<MaybeLinkWrap
			analyticEvent={analyticEvent}
			analyticObj={analyticObj}
			linkCallback={linkCallback}
			linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK}
			path={isSquished ? mobilePath : linkUrl}
		>
			<AdaptiveImg
				alt={altText}
				paddingBottom={paddingBottom}
				paddingTop={paddingTop}
				src={src}
			/>
		</MaybeLinkWrap>
	);
};

HomePageBanner.propTypes = {
	altText: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	breakPoint: PropTypes.number,
	desktopSrc: PropTypes.string,
	linkCallback: PropTypes.func,
	linkUrl: PropTypes.string,
	mobileLinkUrl: PropTypes.string,
	mobileSrc: PropTypes.string,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	windowSize: PropTypes.object,
};

HomePageBanner.defaultProps = {
	breakPoint: DEVICE_WIDTH.LAPTOP,
};

export default withWindowSize(HomePageBanner);
