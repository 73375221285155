/**
 * Throw an error if parameter is undefined.
 * @param param
 * @param paramName
 * @param functionName
 */
export default (param, paramName, functionName) => {
	if (typeof param === 'undefined') {
		throw new Error(paramName + ' is required by function api.' + functionName + '().');
	}
};
