import React from 'react';

import FormItem from '../FormItem/FormItem';

const FormGroup = ({ children }) => <div className="FormGroup grid-x grid-margin-x align-middle">{children}</div>;

FormGroup.propTypes = {
	children: (props, propName, componentName) => {
		const prop = props[propName];
		let error = null;
		React.Children.forEach(prop, (child) => {
			if (child.type !== FormItem) {
				error = new Error(`'${componentName}' children should be of type 'FormItem'`);
			}
		});

		return error;
	}
};

export default FormGroup;
