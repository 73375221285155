import React from 'react';
import PropTypes from 'prop-types';

import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';

const PodcastEmbed = ({ isInset, paddingBottom, paddingTop, src }) => {
	if (!src) return null;

	return (
		<PageFlowContainer className="PodcastEmbed" isInset={isInset} paddingTop={paddingTop} paddingBottom={paddingBottom}>
			<iframe
				src={src}
				width="100%"
				title="Podcast Embed"
				frameBorder="0"
				scrolling="no"
				allowFullScreen={true}
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				oallowfullscreen="true"
				msallowfullscreen="true"
			/>
		</PageFlowContainer>
	);
};

PodcastEmbed.displayName = 'PodcastEmbed';

PodcastEmbed.propTypes = {
	isInset: PropTypes.bool,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string,
	src: PropTypes.string
};

PodcastEmbed.defaultProps = {
	isInset: false
};

export default PodcastEmbed;
