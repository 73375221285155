import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import * as locationSelectors from './location.selectors';
import { REDUCERS } from '../const/reducers.const';
import { toLowerCase, undefinedToEmptyString, slugFormat } from '../helpers/strings/strings';
import { hasLength } from '../helpers/arrays/arrays';

export const selectCardsState = (state) => deep(state, `${REDUCERS.CARDS}`);

export const selectCardTemplateAttributes = createSelector(
	selectCardsState,
	(cards) => deep(cards, 'cardTemplateAttributes')
);

export const selectIsCardTemplateAttributesLoaded = createSelector(
	selectCardTemplateAttributes,
	(cardTemplateAttributes) => !!cardTemplateAttributes
);

export const selectCardTemplateAttributeKeys = createSelector(
	selectCardsState,
	(cards) => deep(cards, 'cardTemplateAttributeKeys')
);

export const selectCategoryId = createSelector(
	selectCardsState,
	(cards) => deep(cards, 'categoryId')
);

export const selectSubCategoryId = createSelector(
	selectCardsState,
	(cards) => deep(cards, 'subcategoryId')
);

export const selectCardsTemplateFilters = createSelector(
	selectCardsState,
	(cards) => deep(cards, 'filters') || {}
);

export const selectCardsTemplateFilterKeys = createSelector(
	selectCardsState,
	(cards) => deep(cards, 'filterKeys') || []
);

export const selectCardsTemplates = createSelector(
	selectCardsState,
	(cards) => deep(cards, 'cardTemplates') || {}
);

export const selectCardTemplateQueries = createSelector(
	selectCardsState,
	(cards) => deep(cards, 'cardTemplateQueries') || {}
);

export const selectCategoryName = createSelector(
	selectCategoryId,
	selectCardTemplateAttributes,
	(categoryId, cardTemplateAttributes) => deep(cardTemplateAttributes, `${categoryId}.name`)
);

export const selectSubCategoryName = createSelector(
	selectSubCategoryId,
	selectCardTemplateAttributes,
	(subCategoryId, cardTemplateAttributes) => deep(cardTemplateAttributes, `${subCategoryId}.name`)
);

export const selectUrlWithoutQueryParams = createSelector(
	selectCategoryName,
	selectSubCategoryName,
	(categoryName, subCategoryName) => {
		const subcat = (!!subCategoryName) ? `/${slugFormat(subCategoryName)}` : '';

		return `/${slugFormat(categoryName)}${subcat}`;
	}
);

export const selectCardsTemplateFiltersMapped = createSelector(
	selectCardsTemplateFilters,
	selectCardsTemplateFilterKeys,
	(filters, filterKeys) => filterKeys
		.map((filterKey) => deep(filters, `${filterKey}`))
		.filter((filter) => !!filter && !!filter.optionKeys && !!filter.options)
		.map((filter) => ({
			...filter,
			options: filter.optionKeys.map((optionKey) => deep(filter, `options.${optionKey}`))
		}))
);

export const selectCurrentCardTemplateFiltersAsIds = createSelector(
	locationSelectors.selectLocationQueryParams,
	selectCardsTemplateFiltersMapped,
	(currentCardTemplateFilters, cardsTemplateFiltersMapped) =>
		cardsTemplateFiltersMapped
			.reduce((totalOptionFilter, filter) => {
				const queryValue = deep(currentCardTemplateFilters, `${filter.slug}`) || [];
				const queryValueSet = new Set(queryValue.map(toLowerCase));
				const updatedArray = filter.options
					.filter((option) => queryValueSet.has(option.slug))
					.map((option) => option.id);

				if (!!updatedArray && updatedArray.length) {
					return {
						...totalOptionFilter,
						[filter.slug]: updatedArray
					};
				}

				return totalOptionFilter;
			}, {})
);

export const selectCardTemplateAttributesMapped = createSelector(
	selectCardTemplateAttributes,
	selectCardTemplateAttributeKeys,
	(cardTemplateAttributes, cardTemplateAttributeKeys) => {
		const mapping = (key) => {
			const resp = deep(cardTemplateAttributes, `${key}`);

			if (!!resp.children) {
				return {
					...resp,
					children: resp.children.map(mapping)
				};
			}

			return resp;
		};

		return cardTemplateAttributeKeys.map(mapping);
	}
);

export const selectCurrentCardTemplateObject = createSelector(
	selectCardTemplateAttributes,
	selectCategoryId,
	(cardTemplateAttributes, categoryId) => deep(cardTemplateAttributes, `${categoryId}`)
);

export const selectCurrentSubCategories = createSelector(
	selectCardTemplateAttributes,
	selectCurrentCardTemplateObject,
	(cardTemplateAttributes, currentCardTemplateObject) => {
		if (!currentCardTemplateObject || !currentCardTemplateObject.children) return [];

		return currentCardTemplateObject.children.map((key) => deep(cardTemplateAttributes, `${key}`));
	}
);

export const selectCurrentCardTemplateFilters = createSelector(
	locationSelectors.selectLocationQueryParams,
	selectCardsTemplateFiltersMapped,
	(locationQueryParams, cardsTemplateFiltersMapped) =>
		cardsTemplateFiltersMapped
			.filter((filter) => !!deep(locationQueryParams, `${filter.slug}`))
);

export const selectFilterParamStrings = createSelector(
	locationSelectors.selectLocationQueryParams,
	selectCardsTemplateFilterKeys,
	(locationQueryParams, cardsTemplateFilterKeys) => {
		const filterString = cardsTemplateFilterKeys
			.reduce((total, filterKey) => {
				const currentFilter = deep(locationQueryParams, `${filterKey}`);

				if (!currentFilter) return total;

				const optionsString = currentFilter.map((filterOption) => `${filterKey}=${filterOption}`);

				return [
					...total,
					...optionsString
				];
			}, []).join('&');

		return (!!filterString) ? `?${filterString}` : filterString;
	}
);

export const selectCurrentCardTemplateFilterQueryString = createSelector(
	selectUrlWithoutQueryParams,
	selectFilterParamStrings,
	(urlWithoutQueryParams, filterParamStrings) => `${urlWithoutQueryParams}${filterParamStrings}`
);

export const selectSubCatOrCat = createSelector(
	selectCategoryId,
	selectSubCategoryId,
	(categoryId, subCategoryId) => subCategoryId || categoryId
);

export const selectCardsTemplateKey = createSelector(
	selectCategoryId,
	selectSubCategoryId,
	selectCardsTemplateFiltersMapped,
	selectCurrentCardTemplateFiltersAsIds,
	(categoryId, subCategoryId, cardTemplateFiltersMapped, currentCardTemplateFiltersAsIds) => {
		const categoryString = `category(${undefinedToEmptyString(categoryId)}):`;
		const subcategoryString = `subcategory(${undefinedToEmptyString(subCategoryId)}):`;
		const filterString = cardTemplateFiltersMapped
			.map((cardTemplateFilter) => {
				let value = deep(currentCardTemplateFiltersAsIds, `${cardTemplateFilter.slug}`);

				if (!!value) {
					const areAllSelected = value.length === cardTemplateFilter.options.length;

					if (areAllSelected) {
						value = null;
					}
				}

				return `${cardTemplateFilter.slug}(${undefinedToEmptyString(value)})`;
			})
			.join(':');

		return `${categoryString}${subcategoryString}${filterString}`;
	}
);

export const selectCurrentCardFilterArray = createSelector(
	locationSelectors.selectLocationQueryParams,
	selectCardsTemplateFilterKeys,
	(currentCardTemplateFilters, filterKeys) =>
		filterKeys
			.filter((key) => !!currentCardTemplateFilters[key])
			.reduce((total, filterKey) => {
				const filterValues = currentCardTemplateFilters[filterKey];
				const filterObject = filterValues.map((filterValue) => ({
					filterKey,
					filterValue
				}));

				return [
					...total,
					...filterObject
				];
			}, [])
);

export const selectCurrentLibraryItem = createSelector(
	selectCardsTemplateKey,
	selectCardTemplateQueries,
	(cardsTemplateKey, cardTemplateQueries) => deep(cardTemplateQueries, `${cardsTemplateKey}`) || []
);

export const selectIsInLibrary = createSelector(
	selectCurrentLibraryItem,
	(currentLibraryItem) => hasLength(currentLibraryItem)
);

export const selectCurrentCardTemplates = createSelector(
	selectCurrentLibraryItem,
	selectCardsTemplates,
	(currentLibraryItem, cardsTemplates) =>
		currentLibraryItem
			.map((id) => deep(cardsTemplates, `${id}`))
);

export const selectHasCurrentCardTemplates = createSelector(
	selectCurrentCardTemplates,
	(currentCardTemplates) => hasLength(currentCardTemplates)
);
