import deep from 'deep-get-set';
import { schema } from 'normalizr';

import { appStringModel } from './appString.model';
import { lineItemModel } from './cartLineItem.model';
import { giftFromJson } from './gift.model';
import { giftCodeFromJson, giftCodeSchema } from './giftCode.model';
import { groupFromJson, groupSchema } from './group.model';
import { getPriceDiscountSubtotal, getPricePromoLabels, priceFromJson } from './price.model';
import { getCardsPerPack, productFromJson, productSchema } from './product.model';
import { getVolumeFromBookId } from '../helpers/books/books';
import { isGroupTypeOfCards, isGroupTypeOfPostCards, getGroupCoverBundle, getGroupEditPath, getGroupTitle, isGroupTypeOfSeries, getGroupProductAppString, isGroupTypeOfSeriesBook, isGroupTypeOfWallTile } from '../helpers/groups/groups';
import { calculateSeriesVolumePrice, getSeriesVolumeProductAttributeAppStrings } from '../helpers/seriesVolume/seriesVolume';

export const cartItemFromJson = (json = {}) => {
	const group = deep(json, 'Group');
	const groupId = deep(json, 'GroupId');
	const gift = deep(json, 'Gift');
	const giftCode = deep(json, 'GiftCode');

	return {
		bookId: deep(json, 'BookId'),
		id: String(deep(json, 'Id')),
		gift: gift && giftFromJson(gift),
		giftCode: giftCode && giftCodeFromJson(giftCode),
		group: group && groupFromJson(group),
		groupId: groupId && String(groupId),
		price: priceFromJson(deep(json, 'Price')),
		product: productFromJson(deep(json, 'Product')),
		quantity: deep(json, 'Quantity')
	};
};

export const cartItemSchema = new schema.Entity('cartItem', {
	giftCode: giftCodeSchema,
	group: groupSchema,
	product: productSchema,
});

export const getCartItemCouponDetail = (item) => {
  const price = getCartItemPriceObject(item);
  const couponCode = deep(price, 'couponCode');
  const couponDiscount = deep(price, 'couponDiscount');

  return {
    couponCode,
    couponDiscount,
  };
};

export const getCartItemCoverPhoto = (item, groups, books) => {
	const group = getCartItemGroup(item, groups);
	const book = getCartItemBook(item, books);

	return isGroupTypeOfSeries(group) ? book.skewyUrl : group.skewyUrl;
};

export const getCartItemDiscountSubtotal = (item) => getPriceDiscountSubtotal(getCartItemPriceObject(item));

export const getCartItemEditPath = (item, groups) => {
	if (item.groupId) {
		const group = getCartItemGroup(item, groups);

		return getGroupEditPath(group);
	}
};

export const getCartItemIsGiftCard = (item) => {
	return !!deep(item, 'gift');
};

export const getCartItemRemoveAppString = (item, groups) => {
	const group = getCartItemGroup(item, groups);

	const itemProductAppString = getCartItemProductAppString(group);

	return appStringModel({
		id: 'web.removeItem',
		params: { item: itemProductAppString }
	});
};

export const getCartItemPriceObject = (item) => deep(item, 'price');

export const getCartItemProductAppString = (group) => {
	return isGroupTypeOfSeriesBook(group)
		? appStringModel({ id: 'web.Additional Copy' })
		: getGroupProductAppString(group);
};

export const getCartItemSubtotal = (item) => deep(item, 'price.subtotal');

export const getCartItemTitle = (item, groups) => {
	if (getCartItemIsGiftCard(item)) return appStringModel({ id: 'web.cartItem.giftCard.title' });

	const group = getCartItemGroup(item, groups);

	return getGroupTitle(group);
};

export const getCartItemTotal = (item) => deep(item, 'price.total');

export const getCartItemGroup = (item, groups) => {
	const groupId = deep(item, 'group');

	return deep(groups, `${groupId}`) || {};
};

export const getCartItemBook = (item, books) => {
	const bookId = deep(item, 'bookId');

	return deep(books, `${bookId}`) || {};
};

export const getCartItemProduct = (item, products) => {
	return deep(products, `${deep(item, 'product')}`);
};

export const getCartItemQuantity = (item, group, product) => {
	const quantity = deep(item, 'quantity') || 0;

	return isGroupTypeOfCards(group)
		? quantity * getCardsPerPack(product)
		: quantity;
};

export const getGeneralCartItemLineItems = (item) => {
	const itemPriceDetailToLineItem = (detail) => lineItemModel({
		price: detail.price,
		description: detail.subtitle,
	});

	return (deep(item, 'price.summary.details') || []).map(itemPriceDetailToLineItem);
};

export const getSeriesVolumeCartItemLineItems = (item, group, books, groupCoverBundles, seriesPricing) => {
	const groupCoverBundle = getGroupCoverBundle(group, groupCoverBundles);
	const book = getCartItemBook(item, books);

	if (!isGroupTypeOfSeries(group) || !book.id) return [];

	const { premium, product, size, type } = getSeriesVolumeProductAttributeAppStrings(group, getVolumeFromBookId(book.id), groupCoverBundle);

	const volumeDescriptionLineItem = lineItemModel({
		description: appStringModel({
			id: 'web.cart.product.description',
			params: { size, premium, type, product },
		}),
		isPriceVisible: false
	});

	const volumePriceLineItem = lineItemModel({
		description: book.bookLabel,
		price: calculateSeriesVolumePrice(getVolumeFromBookId(book.id), group.bookSize, group.coverType, groupCoverBundle, seriesPricing)
	});

	return [
		volumeDescriptionLineItem,
		volumePriceLineItem,
		...getGeneralCartItemLineItems(item),
	];
};

export const getWallTileLineItems = (group) => {
	if (!isGroupTypeOfWallTile(group)) return [];

	return [
		lineItemModel({
			description: appStringModel({
				id: 'web.cart.wall-tiles-in-set',
			}),
			quantity: group.totalPageCount,
		})
	];
};

export const calculatePricePerCard = (item, group, product) => {
	const subtotal = getCartItemSubtotal(item) || 0;
	const quantity = getCartItemQuantity(item, group, product);

	if (quantity === 0) return 0;

	return subtotal / quantity;
};

export const getCardCartItemLineItems = (item, group, products) => {
	if (!isGroupTypeOfCards(group)) return [];

	const lineItem = lineItemModel({
		price: {
			amount: calculatePricePerCard(item, group, getCartItemProduct(item, products)),
			suffix: appStringModel({ id: 'web.cart.cardUnit' }),
		},
		description: appStringModel({
			id: isGroupTypeOfPostCards(group) ? 'web.cart.postcardsLineItem' : 'web.cart.cardsLineItem'
		})
	});

	return [
		lineItem,
		...getGeneralCartItemLineItems(item),
	];
};

export const getGiftCardCartItemLineItems = (item) => {
	const gift = deep(item, 'gift');
	if (!gift) return [];

	const recipientEmail = deep(gift, 'recipientEmail');
	const amount = deep(item, 'price.subtotal');

	if (recipientEmail) {
		const emailTypeLineItem = lineItemModel({
			description: appStringModel({ id: 'web.cart.emailGiftCardLineItem' }),
			price: { amount },
		});

		const emailToLineItem = lineItemModel({
			description: appStringModel({ id: 'web.cart.emailToLineItem', params: { email: recipientEmail } }),
			isPriceVisible: false,
		});

		return [
			emailTypeLineItem,
			emailToLineItem,
			...getGeneralCartItemLineItems(item),
		];
	}

	const printedTypeLineItem = lineItemModel({
		description: appStringModel({ id: 'web.cart.printedGiftCardLineItem' }),
		price: { amount },
	});

	return [
		printedTypeLineItem,
		...getGeneralCartItemLineItems(item),
	];
};

export const getCartItemLineItems = (item, groups, books, groupCoverBundles, products, seriesPricing) => {
	const group = getCartItemGroup(item, groups);
	const book = getCartItemBook(item, books);

	if (deep(item, 'gift')) return getGiftCardCartItemLineItems(item);
	if (isGroupTypeOfSeries(group) && book.id) return getSeriesVolumeCartItemLineItems(item, group, books, groupCoverBundles, seriesPricing);
	if (isGroupTypeOfWallTile(group)) return getWallTileLineItems(group);
	if (isGroupTypeOfCards(group)) return getCardCartItemLineItems(item, group, products);

	return getGeneralCartItemLineItems(item);
};

export const getCartItemQuantitySummary = (item, groups, products) => {
	const quantity = getCartItemQuantity(item, getCartItemGroup(item, groups), getCartItemProduct(item, products));
	const title = getCartItemTitle(item, groups);

	return appStringModel({
		id: 'web.checkout.orderSummary.item.description',
		params: {
			quantity,
			title: title || '[NO TITLE]',
		},
	});
};

export const getCartItemPromoLabels = (item) => {
	return getPricePromoLabels(getCartItemPriceObject(item));
};
