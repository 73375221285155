import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './RadioInput.css';

const RadioInput = ({
	checked,
	children,
	className,
	disabled,
	id,
	label,
	name,
	onBlur,
	onChange,
	value,
}) => {
	if (!id) return null;

	return (
		<div className={classnames(className, 'RadioInput', { disabled })}>
			<label
				htmlFor={id}
				className={classnames({ disabled })}
			>
				<input
					type="radio"
					checked={checked}
					disabled={disabled}
					id={id}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					value={value}
				/>
				<div className={classnames(disabled ? 'disabled-check' : 'check')} />
				{label}
			</label>
			<div className="contents">
				{children}
			</div>
		</div>
	);
};

RadioInput.displayName = 'RadioInput';

RadioInput.propTypes = {
	checked: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.node,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RadioInput