import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconUpload = (props) => (
	<Svg
		{...props}
		name="upload"
		viewBox="0 0 58 46"
	>
		<path
			d="M52.684 45.5H5.316a4.737 4.737 0 0 1-4.737-4.737V5.237A4.737 4.737 0 0 1 5.316.5h47.368a4.737 4.737 0 0 1 4.737 4.737v35.526a4.737 4.737 0 0 1-4.737 4.737zm-.006-6.526l.006-32.553c0-.654-.53-1.184-1.184-1.184h-45c-.654 0-1.184.53-1.184 1.184v32.611c0 .238.154.448.38.52.226.071.923.026.923.026L20.092 21.26a1.48 1.48 0 0 1 1.21-.628h1.009c.481 0 .933.235 1.21.629l13.807 19.12a.9.9 0 0 0 .736.382h.687a.904.904 0 0 0 .74-1.424l-3.088-4.387a1.526 1.526 0 0 1 .034-1.803l2.776-3.645a1.48 1.48 0 0 1 1.178-.583h2.53c.462 0 .898.216 1.178.583l7.494 9.836a.604.604 0 0 0 1.085-.366zm-13.02-17.158a5.921 5.921 0 1 1 0-11.842 5.921 5.921 0 0 1 0 11.842z"
			fillRule="nonzero"
			fill="inherit"
		/>
	</Svg>
);

IconUpload.displayName = 'IconUpload';

IconUpload.propTypes = iconProps;

IconUpload.defaultProps = defaultIconProps;

export default IconUpload;
