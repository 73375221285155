import deep from 'deep-get-set';
import { schema } from 'normalizr';
import { ENTITIES } from '../const/entities/entities';

export const giftCodeFromJson = (json = {}) => ({
	code: deep(json, 'Code'),
	id: String(deep(json, 'ID')),
	sendToEmail: deep(json, 'SendToEmail'),
	url: deep(json, 'Url'),
});

export const giftCodeSchema = new schema.Entity(ENTITIES.GIFT_CODE);
