import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from '../Wrapper/Wrapper';
import './Quote.css';

const Quote = ({
	quote,
	author,
	ratingURL,
}) => (
	<Wrapper className="duplo-block duplo-block-quote">
		<a
			href={ratingURL}
		>
			<div className="quote">{quote}</div>
			<div className="author">- {author}</div>
		</a>
	</Wrapper>
);

Quote.propTypes = {
	quote: PropTypes.string,
	author: PropTypes.string,
	ratingURL: PropTypes.string,
};

export default Quote;
