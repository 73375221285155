import deep from 'deep-get-set';
import { createSelector } from 'reselect';
import { selectGroupsState } from './groups.selectors';
import { ENTITIES } from '../const/entities/entities';

export const selectDuploMap = createSelector(
	selectGroupsState,
	(groupState) => deep(groupState, `${ENTITIES.DUPLO}`)
);

export const selectDuploValueByKeyFunc = createSelector(
	selectDuploMap,
	(duploMap) => (key) => {
		const duploValuePathArrayBecauseKeyHasPeriodsInIt = [`${key}`, 'value'];

		return deep(duploMap, duploValuePathArrayBecauseKeyHasPeriodsInIt);
	}
);
