import React from 'react';

import './Banner.css';

export const Banner = () => (
	<div className="quickflow-banner">
		<div className="quickflow-banner-text">
			<h1>Try a Photo Book Series</h1>
			<p>Every 60 photos uploaded creates a new book. Your book is completely customizable with options to remove photos, change covers, and edit captions. </p>
		</div>

		<div className="quickflow-banner-badge">
			<p>$10 + Free Shipping</p>
		</div>

		<img
			className="quickflow-banner-image"
			src="/assets/img/book-stack-mobile.png"
			alt="Quick Flow"
		/>
	</div>
);

Banner.displayName = 'Banner';

export default Banner;
