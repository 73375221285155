export const SPACING_NUMBERS = {
	BIGGEST: 60,
	BIGGER: 40,
	BIG: 30,
	MEDIUM: 20,
	SMALL: 10,
	SMALLER: 5,
};

export const SPACING = {
	BIGGEST: `${SPACING_NUMBERS.BIGGEST}px`,
	BIGGER: `${SPACING_NUMBERS.BIGGER}px`,
	BIG: `${SPACING_NUMBERS.BIG}px`,
	MEDIUM: `${SPACING_NUMBERS.MEDIUM}px`,
	SMALL: `${SPACING_NUMBERS.SMALL}px`,
	SMALLER: `${SPACING_NUMBERS.SMALLER}px`,
};
