import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const bookFromJson = (json = {}) => ({
	bookLabel: deep(json, 'BookLabel'),
	cover: deep(json, 'Cover'),
	coverMediaId: deep(json, 'CoverMediaID'),
	id: deep(json, 'ID'),
	isLocked: deep(json, 'IsLocked'),
	skewyUrl: deep(json, 'SkewyUrl'),
});

export const booksFromJsonArray = (jsonArray = []) => {
	return !Array.isArray(jsonArray) ? [] : jsonArray.map(bookFromJson);
};

export const bookSchema = new schema.Entity(ENTITIES.BOOK);

export const booksSchema = new schema.Array(bookSchema);

export const normalizeBooks = (books) => normalize(books, booksSchema);
