import React from 'react';
import PropTypes from 'prop-types';

import Benefit from './Benefit';
import benefitsData from './Benefits.data';
import './Benefits.css';

const Benefits = ({
	className,
}) => (
	<section className={`instagram-quickflow-page-benefits padding ${className}`}>
		<h3>Benefits</h3>
		<ul className="instagram-quickflow-page-benefits-list">
			{benefitsData.map((item, index) => (
				<Benefit
					key={index}
					item={item}
					number={index + 1}
				/>
			))}
		</ul>
	</section>
);

Benefits.propTypes = {
	className: PropTypes.string,
};

export default Benefits;
