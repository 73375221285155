import deep from 'deep-get-set';

export function makeSherpaLinkResolver(relativePath, owningSite, currentSite) {
	/**
	 * Builds a URL, configured to go to the correct site or stay on the current site without reloading the Single Page App (SPA)
	 *
	 * @param {string} extraQuery Any extra query params
	 * @param {object} options Configuration options
	 * @param {boolean} options.forceAbsolute If local URLs should be forced to be full URLs
	 * @returns A URL, configured to go to the correct location across multiple sites.
	 */
	function sherpaLinkResolver(extraQuery = '', options) {
		const url = new URL(relativePath, owningSite.origin);
		const extraQueryParams = new URLSearchParams(extraQuery);

		extraQueryParams.forEach((value, key) => {
			url.searchParams.delete(key);
			url.searchParams.append(key, value);
		});

		const forceAbsolute = !!deep(options, 'forceAbsolute');

		return owningSite.name === currentSite.name && !forceAbsolute ? `${url.pathname}${url.search}` : url.href;
	};

	return sherpaLinkResolver;
}
