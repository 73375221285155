import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const FormError = ({
	error,
	errorId,
	errorMessage,
	invalid,
	submitted,
	validationClassName,
	validationMessage
}) => {
	let errorContent;
	if (submitted && error) {
		errorContent = errorMessage;
	} else if (invalid && validationMessage) {
		errorContent = validationMessage;
	}

	return errorContent ? (
		<div id={errorId} className={classnames('grid-x', 'form-error', 'form-validation-message', validationClassName)}>
			<div className="cell small-12">{errorContent}</div>
		</div>
	) : null;
};

FormError.propTypes = {
	error: PropTypes.any,
	errorId: PropTypes.any,
	errorMessage: PropTypes.any,
	invalid: PropTypes.any,
	submitted: PropTypes.any,
	validationClassName: PropTypes.any,
	validationMessage: PropTypes.any,
};

export const ResetButton = ({ backgroundColor, color, onClick, submitted, success, text }) =>
	submitted && success && text ? (
		<button type="button" className="button primary large" style={{ backgroundColor, color }} onClick={onClick}>
			{text}
		</button>
	) : null;

ResetButton.propTypes = {
	backgroundColor: PropTypes.any,
	color: PropTypes.any,
	onClick: PropTypes.func,
	submitted: PropTypes.any,
	success: PropTypes.any,
	text: PropTypes.any
};

export const SubmitButton = ({ backgroundColor, color, sending, submitted, success, text }) =>
	!submitted || !success ? (
		<button type="submit" className="button primary large" disabled={sending} style={{ backgroundColor, color }}>
			{text}
		</button>
	) : null;

SubmitButton.propTypes = {
	backgroundColor: PropTypes.any,
	color: PropTypes.any,
	sending: PropTypes.any,
	submitted: PropTypes.any,
	success: PropTypes.any,
	text: PropTypes.any,
};
