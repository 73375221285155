import React from 'react';
import PropTypes from 'prop-types';

import IconEmptyCart from './IconEmptyCart';
import IconFullCart from './IconFullCart';
import _defaultIconProps from './_defaultIconProps';
import _iconProps from './_iconProps';
import './IconCart.css';

const IconCart = ({ color, itemCount, size }) => (
	<span className="IconCart">
		{itemCount ? (
			<IconFullCart count={itemCount} size={size} color={color} />
		) : (
			<IconEmptyCart size={size} color={color} />
		)}
	</span>
);

IconCart.displayName = 'IconCart';

IconCart.defaultProps = {
	..._defaultIconProps,
	color: '#111111',
	itemCount: 0,
	size: 35
};

IconCart.propTypes = {
	..._iconProps,
	itemCount: PropTypes.number,
};

export default IconCart;
