import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

export default (state = defaultState[REDUCERS.CARDS], action) => {
	const { type } = action;

	switch (type) {
		case ACTIONS.SET_CARD_INITIAL_QUERY_DATA: {
			const categoryId = deep(action, 'categoryId');
			const subcategoryId = deep(action, 'subcategoryId');

			return {
				...state,
				categoryId,
				subcategoryId,
			};
		}

		case ACTIONS.FETCH_CARD_TEMPLATE_ATTRIBUTES: {
			const cardAttributes = deep(action, 'cardAttributes');
			const cardAttributeKeys = deep(action, 'cardAttributeKeys');

			return {
				...state,
				cardTemplateAttributes: cardAttributes,
				cardTemplateAttributeKeys: cardAttributeKeys,
			};
		}

		case ACTIONS.FETCH_CARD_TEMPLATES: {
			return {
				...state,
				cardTemplates: {
					...deep(state, 'cardTemplates'),
					...deep(action, 'cardTemplates'),
				},
				cardTemplateQueries: {
					...deep(state, 'cardTemplateQueries'),
					...deep(action, 'cardTemplateQueries'),
				}
			};
		}

		case ACTIONS.SET_CARD_TEMPLATE: {
			const cardTemplate = deep(action, 'cardTemplate');
			const id = deep(cardTemplate, 'id');

			return {
				...state,
				cardTemplates: {
					...state.cardTemplates,
					[id]: cardTemplate
				},
			};
		}

		case ACTIONS.SET_CARD_TEMPLATE_ORIENTATION_SET: {
			return {
				...state,
				cardTemplates: {
					...deep(state, 'cardTemplates'),
					...deep(action, 'cardTemplates'),
				},
			};
		}

		case ACTIONS.FETCH_CARD_TEMPLATE_FILTERS: {
			const filters = deep(action, 'filters');
			const filterKeys = deep(action, 'filterKeys');

			return {
				...state,
				filters,
				filterKeys
			};
		}

		default:
			return state;
	}
};
