import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ImageWithHiddenHoverImage from './ImageWithHiddenHoverImage';
import { COLOR, DEVICE_WIDTH, mediaQueryStartingFrom, mediaQueryUpTo, SPACING } from '../../const';
import { useIsHover } from '../../hooks';

const BrandValueContainer = styled.div`
	display: grid;
	margin: auto;
	justify-items: center;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		max-width: 190px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		max-width: 200px;
	}
`;

const Image = styled.img`
	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		max-height: 100px;
		max-width: 100px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		max-height: 100px;
		max-width: 100px;
	}
`;

const Title = styled.div`
	color: #333333;
	font-family: Circular-Pro-Medium;
	margin-top: ${SPACING.SMALL};
	text-align: center;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 16px;
		line-height: 18.75px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 18px;
		line-height: 21px;
	}
`;

const Subtitle = styled.div`
	color: ${COLOR.GRAY_700};
	font-family: Inter;
	font-style: normal;
	margin-top: ${SPACING.MEDIUM};
	text-align: center;

	${mediaQueryUpTo(DEVICE_WIDTH.TABLET)} {
		font-size: 14px;
		line-height: 18px;
	}
	${mediaQueryStartingFrom(DEVICE_WIDTH.TABLET)} {
		font-size: 16px;
		line-height: 24px;
		font-weight: normal;
	}
`;

const BrandValue = ({
	brandValue,
}) => {
	const { hoverProps, isHover } = useIsHover();

	if (!brandValue) return null;

	return (
		<BrandValueContainer
			key={brandValue.title}
			{...hoverProps}
		>
			<ImageWithHiddenHoverImage
				isHovered={isHover}
				render={({
					getFrontProps,
					getBackProps,
				}) => (
					<>
						<Image src={brandValue.gifUrl} alt={brandValue.gifAlt} {...getBackProps()} />
						<Image src={brandValue.pngUrl} alt={brandValue.pngAlt} {...getFrontProps()} />
					</>
				)}
			/>
			<Title>{brandValue.title}</Title>
			<Subtitle>{brandValue.subtitle}</Subtitle>
		</BrandValueContainer>
	);
};

BrandValue.displayName = 'BrandValue';

BrandValue.propTypes = {
	brandValue: PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string,
		pngAlt: PropTypes.string,
		pngUrl: PropTypes.string,
		gifAlt: PropTypes.string,
		gifUrl: PropTypes.string,
	}),
};

export default BrandValue;
