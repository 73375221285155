import { queryStringFromJson } from '../../helpers/locationUtils';
import { apiGetData, apiPostRecieveData, apiPost } from './api.http.actions';

export const createChatCard = (title, frontTemplateId, cardType) => (dispatch) => {
	const data = {
		title,
		frontTemplateId,
		cardType
	};
	const url = '/chatcards/CreateWithGroup';

	return dispatch(apiPostRecieveData(url, data));
};

export const getChatCardByGroupId = (chatgroupId) => (dispatch) => {
	return dispatch(apiGetData(`/chatcards/LoadFromGroupId?${queryStringFromJson({ chatgroupId })}`));
};

export const getChatCardsOrientationSwapInfo = (chatgroupId) => (dispatch) => {
	return dispatch(apiGetData(`/chatcards/GetOrientationSwapInfo?${queryStringFromJson({ chatgroupId })}`));
};

export const postChatCardsSetBackFillOption = (chatgroupId, cardBackFillOption) => (dispatch) => {
	return dispatch(apiPost('/chatcards/SetBackFillOption', { chatgroupId, cardBackFillOption }));
};

export const postChatCardsRemovePhoto = (groupId, isFront, index) => (dispatch) => {
	return dispatch(apiPost('/chatcards/RemovePhoto', {
		chatgroupId: groupId,
		isFront,
		index,
	}));
};

export const postChatCardsSetAccentColor = (groupId, colorId) => (dispatch) => {
	return dispatch(apiPostRecieveData('/chatcards/SetAccentColor', {
		chatgroupId: groupId,
		accentColorId: colorId
	}));
};

export const postChatCardsSetAddress = (groupId, address) => (dispatch) => {
	return dispatch(apiPostRecieveData('/chatcards/SetAddress', {
		chatgroupId: groupId,
		returnAddress: address,
	}));
};

export const postChatCardsSetCaption = (groupId, isFront, index, caption) => (dispatch) => {
	return dispatch(apiPostRecieveData('/chatcards/SetCaption', {
		chatgroupId: groupId,
		isFront,
		index,
		caption,
	}));
};

export const postChatCardsSetBackTemplate = (groupId, templateId) => (dispatch) => {
	return dispatch(apiPostRecieveData('/chatcards/SetBackTemplate', {
		chatgroupId: groupId,
		backTemplateId: templateId
	}));
};

export const postChatCardsSetCardEdgeType = (groupId, edgeType) => (dispatch) => {
	return dispatch(apiPostRecieveData('/chatcards/SetCardEdgeType', {
		chatgroupId: groupId,
		cardEdgeType: edgeType
	}));
};

export const postChatCardsSetFoilOption = (groupId, foilOption) => (dispatch) => {
	return dispatch(apiPostRecieveData('/chatcards/SetFoilOption', {
		chatgroupId: groupId,
		foilOption,
	}));
};

export const postChatCardsSetFrontTemplate = (groupId, templateId) => (dispatch) => {
	return dispatch(apiPostRecieveData('/chatcards/SetFrontTemplate', {
		chatgroupId: groupId,
		frontTemplateId: templateId
	}));
};

export const postChatCardsSetPhoto = (groupId, isFront, index, momentId) => (dispatch) => {
	return dispatch(apiPost('/chatcards/SetPhoto', {
		chatgroupId: groupId,
		isFront,
		index,
		momentId
	}));
};

export const postChatCardsSetPrintReturnAddress = (groupId, printReturnAddress) => (dispatch) => {
	return dispatch(apiPost('/chatcards/SetPrintReturnAddress', {
		chatgroupId: groupId,
		printReturnAddress,
	}));
};
