import React from 'react';
import PropTypes from 'prop-types';

import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconError = (props) => (
	<Svg
		{...props}
		name="error"
		viewBox="0 0 24 23"
	>
		<path
			d="M3.02434 22.1327H20.9757C22.7971 22.1327 24 20.758 24 19.1084C24 18.6043 23.874 18.1002 23.599 17.6191L14.6062 1.52363C14.0449 0.515513 13.0253 0 12.0057 0C10.9862 0 9.94367 0.515513 9.39379 1.52363L0.400955 17.6305C0.126014 18.1002 0 18.6043 0 19.1084C0 20.758 1.20286 22.1327 3.02434 22.1327Z"
			fill="inherit"
		/>
		<path
			d="M10.8258 13.0024C10.8601 13.7356 11.2726 14.1365 12.0057 14.1365C12.716 14.1365 13.1284 13.747 13.1628 12.9909L13.3346 8.06492C13.369 7.29738 12.7962 6.7475 11.9943 6.7475C11.1924 6.7475 10.6196 7.28592 10.6539 8.05346L10.8258 13.0024Z"
			fill={props.color2}
		/>
		<path
			d="M10.5279 17.1379C10.5279 17.9399 11.158 18.4897 12.0057 18.4897C12.842 18.4897 13.4835 17.9513 13.4835 17.1379C13.4835 16.3246 12.842 15.7862 12.0057 15.7862C11.158 15.7862 10.5279 16.3246 10.5279 17.1379Z"
			fill={props.color2}
		/>
	</Svg>
);

IconError.displayName = 'IconError';

IconError.propTypes = {
	...iconProps,
	color2: PropTypes.string,
};

IconError.defaultProps = {
	...defaultIconProps,
	color2: 'white',
};

export default IconError;
