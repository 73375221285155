import React from 'react';
import PropTypes from 'prop-types';
import deep from 'deep-get-set';

import connectContext from '../../../helpers/connectContext';
import { Consumer } from '../../../helpers/context';
import MenuLink from '../MenuLink/MenuLink';
import './RightHandLinks.css';

export const RightHandLinks = ({ onToggleDropdown, openDropdowns, rightHandLinks }) => {
	if (rightHandLinks.length === 0) return null;

	return (
		<li className="right-hand-links">
			<ul className="menu align-right">
				{rightHandLinks.map((link, i) => (
					<MenuLink
						key={i}
						analyticObj={{
							attr2: link.label
						}}
						dropdownKey={`rhl-${i}`}
						dropdownOpen={openDropdowns[`rhl-${i}`]}
						dropdownCallback={(key) => {
							onToggleDropdown && onToggleDropdown(key);
						}}
						{...link}
					/>
				))}
			</ul>
		</li>
	);
};

RightHandLinks.propTypes = {
	rightHandLinks: PropTypes.array,
	onToggleDropdown: PropTypes.func,
	openDropdowns: PropTypes.object
};

RightHandLinks.defaultProps = {
	openDropdowns: {},
	rightHandLinks: []
};

const mapContextToProps = (context) => {
	const openDropdowns = deep(context, 'openDropdowns');
	const onToggleDropdown = deep(context, 'onToggleDropdown');
	const rightHandLinks = deep(context, 'rightHandLinks');

	return {
		openDropdowns,
		onToggleDropdown,
		rightHandLinks
	};
};

export default connectContext(Consumer, mapContextToProps)(RightHandLinks);
