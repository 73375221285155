import { useCallback, useEffect, useState } from 'react';

export default ({
	onLongPress,
	secondsRequired = 1,
}) => {
	const [touchTimer, setTouchTimer] = useState(null);

	useEffect(() => {
		return () => touchTimer && clearTimeout(touchTimer);
	}, [touchTimer]);

	const handleTouchStart = useCallback(() => {
		setTouchTimer(setTimeout(() => {
			onLongPress && onLongPress();
		}, secondsRequired * 1000));
	}, [onLongPress, secondsRequired]);

	const handleTouchEnd = useCallback(() => clearTimeout(touchTimer), [touchTimer]);

	return {
		handleTouchEnd,
		handleTouchStart,
	};
};