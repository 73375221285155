import deep from 'deep-get-set';
import { createSelector } from 'reselect';

import { selectInstagramGraphApiConfig } from './config.selectors';
import { queryStringFromJson } from '../helpers/locationUtils';

export const selectInstagramRedirectUri = createSelector(
	selectInstagramGraphApiConfig,
	(config) => deep(config, 'redirectUri')
);

export const selectInstagramUrlFunc = createSelector(
	selectInstagramGraphApiConfig,
	(instagramConfig) => (intentActionUri) => `https://api.instagram.com/oauth/authorize?${queryStringFromJson({
		app_id: instagramConfig.appId,
		redirect_uri: instagramConfig.redirectUri,
		scope: 'user_profile,user_media',
		response_type: 'code',
		state: intentActionUri && encodeURIComponent(intentActionUri),
	})}`
);
