import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getIsExternalLink } from './ConfigurableLink.helpers';
import './ConfigurableLink.css';

export const ConfigurableLink = ({
	action,
	analyticEvent,
	analyticObj,
	ariaLabel,
	children,
	className,
	clickHandler,
	linkCallback,
	linkElement,
	linkElementPathAttr,
	path,
	target,
}) => {
	if (!!path) path = path.trim();

	let LinkElem = 'a';
	const linkElemProps = {};
	const isExternalLink = getIsExternalLink(action, path);

	if (ariaLabel) linkElemProps['aria-label'] = ariaLabel;
	if (target) linkElemProps.target = target;

	if (linkCallback) {
		if (path) linkElemProps.href = path;

		linkElemProps.onClick = (e) => {
			e.preventDefault();
			analyticEvent && analyticEvent(analyticObj);
			linkCallback && linkCallback(path, e);
		};
	} else if (isExternalLink) {
		if (!/^https?:\/\//.test(path)) {
			LinkElem = linkElement;
			linkElemProps[linkElementPathAttr] = path;
		} else {
			// Use href for external links
			linkElemProps.href = path;
		}

		if (analyticEvent) {
			linkElemProps.onClick = () => {
				analyticEvent(analyticObj);
			};
		}
	} else {
		linkElemProps.onClick = () => {
			analyticEvent && analyticEvent(analyticObj);
			clickHandler && clickHandler(action, path);
		};
	}

	const classes = classnames('configurable-link', className);

	return (
		<LinkElem
			className={classes}
			{...linkElemProps}
		>
			{children}
		</LinkElem>
	);
};

ConfigurableLink.displayName = 'ConfigurableLink';

ConfigurableLink.defaultProps = {
	action: 'Link',
	linkElement: 'a',
	linkElementPathAttr: 'href',
	path: '#'
};

ConfigurableLink.propTypes = {
	action: PropTypes.string,
	ariaLabel: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.any,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	clickHandler: PropTypes.func,
	linkCallback: PropTypes.func,
	linkElement: PropTypes.any,
	linkElementPathAttr: PropTypes.string,
	path: PropTypes.string,
	target: PropTypes.string,
};

export default ConfigurableLink;
