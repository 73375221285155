import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ThrobberBox from './ThrobberBox';
import { useDebouncedValue } from '../../hooks';

const fullScreenOverlay = css`
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 99999;
`;

const Container = styled.div`
	${fullScreenOverlay};
	display: flex;
	align-items: middle;
	flex-direction: column;
`;

const Throbber = ({ delay, visible }) => {
	const show = useDebouncedValue(visible, delay);

	if (!show) return null;

	return (
		<Container>
			<ThrobberBox />
		</Container>
	);
};

Throbber.displayName = 'Throbber';

Throbber.propTypes = {
	delay: PropTypes.number,
	visible: PropTypes.bool
};

Throbber.defaultProps = {
	delay: 0,
	visible: false,
};

export default Throbber;
