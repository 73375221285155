import { isFalsyString } from '../bool/bool';

// Convert camel case to space
export const camelCaseToSpace = (str) => str.replace(/([A-Z0-9])/g, ' $1');

export const containsAny = (str, values = []) => {
	if (typeof str !== 'string' || !Array.isArray(values)) return false;

	return values.some((val) => str.includes(val));
};

export const containsEvery = (str, values = []) => {
	if (typeof str !== 'string' || !Array.isArray(values)) return false;

	return values.every((val) => str.includes(val));
};

// Format newline
export const formatNewline = (str) => str.replace(/\n/g, '<br>');

export const formatRemoveFirstLine = (str) => str.replace(/^.*\n/i, '');

/**
 * Whether a string is empty or not.
 * @param {string} str
 */
export const isEmptyString = (str) => str === '';

/**
 * Whether a variable is a string or not.
 * @param {any} str Some parameter to test.
 */
export const isString = (str) => typeof str === 'string';

/**
 * Whether a string matches 1-2 digits or an empty string.
 * @param {string} str
 */
export const isTwoDigitWholeNumber = (str) => /^\d?\d?$/.test(str);


// Only Characters
export const onlyCharacters = (str) => {
	return str ? str.replace(/[^a-z0-9\s]/gi, '') : '';
};

/**
 * Strip non ascii characters from string and return result
 * @param str String
 */
export const stripNonAscii = (str) => str.replace(/[^\x20-\x7E]/g, '');

// Convert to title Case
export const toTitle = (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();

// Convert to lower Case
export const toLowerCase = (str) => str.toLowerCase();

// If undefined return ''
export const undefinedToEmptyString = (str) => !!str ? str : '';

/**
 * Return null if a false string
 * @param {string} str
 */
export const nullIfFalseString = (str) => (isFalsyString(str)) ? null : str;

/**
 * Return string lowercase and with dashes
 * @param {string} str
 */
export const slugFormat = (str) => {
	if (!str) return null;

	return str
		.toLowerCase()
		.replace(/%20/g, '-')
		.replace(/ /g, '-');
};

export const splitCamelCaseToString = (s) => s.split(/(?=[A-Z])/).join(' ');
