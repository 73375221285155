import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { REDUCERS } from '../const/reducers.const';

const defaultFormState = {
	error: null,
	submitted: false,
	success: false
};

export default (state = defaultState[REDUCERS.FORM], action) => {
	const { type, data } = action;
	const { name } = data || {};
	const formState = state[name] || defaultFormState;
	switch (type) {
		case ACTIONS.FORM_ERROR:
			return {
				...state,
				[name]: {
					...formState,
					error: data.error
				}
			};
		case ACTIONS.FORM_RESET:
			return {
				...state,
				[name]: { ...defaultFormState }
			};
		case ACTIONS.FORM_SUBMIT:
			return {
				...state,
				[name]: {
					...formState,
					error: null,
					submitted: true
				}
			};
		case ACTIONS.FORM_SUCCESS:
			return {
				...state,
				[name]: {
					...formState,
					success: true
				}
			};
		default:
			return state;
	}
};
