import React from 'react';
import PropTypes from 'prop-types';

import './ToggleButtons.css';

const ToggleButtons = ({
	children,
	className,
}) => (
	<div className={`toggle-buttons-group-buttons ${className}`}>
		{children}
	</div>
);

ToggleButtons.displayName = 'ToggleButtons';

ToggleButtons.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

ToggleButtons.defaultProps = {
	className: '',
};

export default ToggleButtons;
