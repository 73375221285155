export const HAPPYKIT_FLAG_KEYS = {
	AUTH_PAGES: 'auth_pages',
	BRAND_REFRESH: 'brand_refresh',
	COMMUNITY_HOME_PAGE: 'community_home_page',
	NEW_USER_PROFILE: 'new_user_profile',
	NX_APP_HOME_PAGE: 'nx_app_home_page',
	NX_APP_NEW_GIFTCARD_PAGE: 'nx_app_new_giftcard_page',
	PRICING_PAGE_UPDATE: 'pricing_page_update',
	// REDIRECT_TO_NEW_CART: 'redirect_to_new_cart', // TODO - remove from happykit
	SOCIAL_FEED: 'social_feed',
	SUBS_CELEBRATION_PAGE: 'subs_celebration_page',
	UPDATED_ORDER_HISTORY: 'updated_order_history',
};