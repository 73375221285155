import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

export const apiSettingsFromJson = (json = {}) => ({
	isDev: deep(json, 'IsDev'),
	stripePublishableKey: deep(json, 'StripePublishableKey'),
});

export const normalizeApiSettings = (apiSettings) => {
	return normalize(apiSettings, apiSettingsSchema);
};

const apiSettingsSchema = new schema.Entity('apiSettings', {}, {
	idAttribute: () => 'apiSettings'
});