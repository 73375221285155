import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from '../reducers';
import config from '../helpers/config';

const composeEnhancers = (!config.isProduction() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middleware = [thunk];

export const create = (initialState, cookieJar) =>
	createStore(reducers(cookieJar), initialState, composeEnhancers(applyMiddleware(...middleware)));
