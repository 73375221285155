import React from 'react';

import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconTrashCan = (props) => {
	const { size } = props;
	const heightRatio = 16;
	const widthRatio = 14	;
	const height = size * heightRatio / widthRatio;
	const width = size * widthRatio / heightRatio;

	return (
		<Svg
			{...props}
			name="trash"
			width={width}
			height={height}
			viewBox="0 0 14 16"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 3H12.9999H11V1H10V0H4V1H3V3H1.10254H0V5H1.2411L2.00321 16H12.0404L12.8523 5H14V3ZM10.9296 5H3.12744L3.99988 14H9.99988L10.9296 5ZM5 3H9V2H5V3Z"
				fill="inherit"
			/>
		</Svg>
	);
};

IconTrashCan.displayName = 'IconTrashCan';

IconTrashCan.propTypes = iconProps;

IconTrashCan.defaultProps = {
	...defaultIconProps,
	size: 16,
};

export default IconTrashCan;
