import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { shippingGradeFromJson, shippingGradeSchema } from './shippingGrade.model';
import { ENTITIES } from '../const/entities/entities';

export const shippingOptionPresentationFromJson = (json = {}) => {
	const id = deep(json, 'Id');

	const disabled = !deep(json, 'Summary');

	const shippingOption = {
		id: id !== undefined ? String(id) : undefined,
		disabled,
		displayName: deep(json, 'DisplayName'),
		shippingGrade: shippingGradeFromJson(deep(json, 'Summary.ShippingGrade')),
		cost: deep(json, 'Summary.Cost'),
		costText: deep(json, 'Summary.CostText'),
		hasHolidayArrival: !!deep(json, 'Summary.HolidayArrival'),
		holidayArrival: {
			iconUrl: deep(json, 'Summary.HolidayArrival.IconUrl') || '',
			message: deep(json, 'Summary.HolidayArrival.Message') || '',
			severity: deep(json, 'Summary.HolidayArrival.Severity'),
		},
		isFree: deep(json, 'Summary.IsFree'),
		estimatedArrivalDescription: deep(json, 'Summary.EstimatedArrival.End.MonthDayYear'),
		includesTracking: deep(json, 'Summary.IncludesTracking'),
		includesTrackingText: deep(json, 'Summary.IncludesTrackingText'),
	};

	if (!disabled) {
		shippingOption.estimatedArrivalDate = new Date(deep(json, 'Summary.EstimatedArrival.End.Iso8601'));
	}

	return shippingOption;
};


export const shippingOptionsPresentationFromJson = (jsonShippingOptions = []) => {
	return jsonShippingOptions.map((shippingOptionJson) => shippingOptionPresentationFromJson(shippingOptionJson));
};

export const normalizeShippingOptionsPresentation = (shippingOption) => {
	return normalize(shippingOption, shippingOptionsPresentationSchema);
};

export const shippingOptionPresentationSchema = new schema.Entity(ENTITIES.SHIPPING_OPTION, {
	shippingGrade: shippingGradeSchema,
});

export const shippingOptionsPresentationSchema = new schema.Array(shippingOptionPresentationSchema);

