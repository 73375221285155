import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconDelete = (props) => (
	<Svg
		{...props}
		name="delete"
		viewBox="0 0 14 16"
	>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g transform="translate(-1.000000, 0.000000)">
				<path
					d="M11,1 L12,1 L12,3 L10,3 L10,2 L6,2 L6,3 L4,3 L4,1 L5,1 L5,0 L11,0 L11,1 Z M13.8523867,5 L13.0405135,16 L3.00333607,16 L2.24122651,5 L1,5 L1,3 L15,3 L15,5 L13.8523867,5 Z"
					fill="inherit"
				/>
			</g>
		</g>
	</Svg>
);

IconDelete.displayName = 'IconDelete';

IconDelete.propTypes = iconProps;

IconDelete.defaultProps = defaultIconProps;

export default IconDelete;
