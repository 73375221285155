import deep from 'deep-get-set';
import { normalize, schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const excludedMomentsDateInfoFromJson = (groupId, json = {}) => ({
	excludedCount: deep(json, 'ExcludedCount'),
	groupId,
	monthlyCounts: deep(json, 'MonthlyCounts'),
	similarPhotoGroupCount: deep(json, 'SimilarPhotoGroupCount'),
});

export const excludedMomentsDateInfoSchema = new schema.Entity(ENTITIES.EXCLUDED_MOMENTS_DATE_INFO, {}, {
	idAttribute: 'groupId',
});

export const normalizeExcludedMomentsDateInfo = (dateInfo) => normalize(dateInfo, excludedMomentsDateInfoSchema);
