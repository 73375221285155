import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import classnames from 'classnames';

import { CONFIGURABLE_LINK_TYPES } from '../../const';
import normalizeMeasurement from '../../helpers/normalizeMeasurement';
import MaybeLinkWrap from '../../components/MaybeLinkWrap/MaybeLinkWrap';
import PageFlowContainer from '../../components/PageFlowContainer/PageFlowContainer';
import './AdaptiveImage.css';

class AdaptiveImage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isMobile: this.props.isMobile
		};

		this.delayedCallback = this.delayedCallback.bind(this);
		this.setMobile = this.setMobile.bind(this);
	}

	componentDidMount() {
		this.setMobile();
		window.addEventListener('resize', this.delayedCallback);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.delayedCallback);
	}

	delayedCallback = debounce(this.setMobile, 500);

	setMobile() {
		this.setState({
			isMobile: window.innerWidth < this.props.breakPoint
		});
	}

	render() {
		const {
			altText,
			analyticEvent,
			analyticObj,
			desktopSrc,
			isInset,
			mobileLinkUrl,
			mobileSrc,
			linkCallback,
			linkUrl,
			paddingBottom,
			paddingTop
		} = this.props;

		const src = this.state.isMobile ? mobileSrc : desktopSrc;

		if (!src) return null;

		const classname = this.state.isMobile ? 'mobile-image' : 'desktop-image';
		const mobilePath = mobileLinkUrl || linkUrl;
		const path = this.state.isMobile ? mobilePath : linkUrl;
		const style = {
			paddingBottom: normalizeMeasurement(paddingBottom),
			paddingTop: normalizeMeasurement(paddingTop)
		};
		const className = classnames('AdaptiveImage');

		return (
			<PageFlowContainer className={className} isInset={isInset}>
				<MaybeLinkWrap
					analyticEvent={analyticEvent}
					analyticObj={analyticObj}
					linkCallback={linkCallback}
					linkType={CONFIGURABLE_LINK_TYPES.ROUTER_LINK}
					path={path}
				>
					<img alt={altText} className={`adaptive-image ${classname}`} style={style} src={src} />
				</MaybeLinkWrap>
			</PageFlowContainer>
		);
	}
}

AdaptiveImage.displayName = 'AdaptiveImage';

AdaptiveImage.propTypes = {
	altText: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	breakPoint: PropTypes.number,
	desktopSrc: PropTypes.string,
	isInset: PropTypes.bool,
	isMobile: PropTypes.bool,
	linkCallback: PropTypes.func,
	linkUrl: PropTypes.string,
	mobileLinkUrl: PropTypes.string,
	mobileSrc: PropTypes.string,
	paddingBottom: PropTypes.string,
	paddingTop: PropTypes.string
};

AdaptiveImage.defaultProps = {
	breakPoint: 768,
	isInset: false,
	isMobile: false
};

export default AdaptiveImage;
