export default [
	'6x6-inch and 8x8-inch size options',
	'Softcover and Hardcover options',
	'Available in white, grey, and navy, or Rifle Paper Co. floral prints',
	'Archival quality ink is certified to resist fading for 200 years',
	'Professional-grade print quality of over 300 DPI',
	'Paper milled in Italy with a focus on sustainability',
	'Perfect bound book binding using PUR adhesive for a stronger, long-lasting finish ',
	'Made in the USA',
];
