import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './FourColumn.css';

import PageFlowContainer from '../PageFlowContainer/PageFlowContainer';
import Polaroid from '../../prismic/Polaroid/Polaroid';
import { ASPECT_RATIOS } from '../../prismic/Polaroid/AspectRatios';

const FourColumn = ({ isInset, paddingTop, paddingBottom, polaroids: polaroidsProps }) => {
	const atLeastOneHasSubText = !!polaroidsProps.find((polaroid) => polaroid.subtext);

	const polaroids = polaroidsProps.map((polaroid, index) => (
		<Polaroid
			key={`Polaroid-${index}`}
			aspectRatio={ASPECT_RATIOS._1x1}
			showSubtext={atLeastOneHasSubText}
			{...polaroid}
		/>
	));
	const className = classnames('FourColumn-Container');

	return (
		<PageFlowContainer className={className} isInset={isInset} paddingTop={paddingTop} paddingBottom={paddingBottom}>
			{polaroids}
		</PageFlowContainer>
	);
};

FourColumn.displayName = 'FourColumn';

FourColumn.propTypes = {
	isInset: PropTypes.bool,
	paddingTop: PropTypes.string,
	paddingBottom: PropTypes.string,
	polaroids: PropTypes.arrayOf(PropTypes.object)
};

FourColumn.defaultProps = {
	isInset: false,
	polaroids: []
};

export default FourColumn;
