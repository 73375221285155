import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TaperedLineSpan = styled.span`
	display: block;
	border: none;
	color: 'white';
	height: 1px;
	background: ${(props) => {
		const color = props.color || '#666';

		return `linear-gradient(to right, transparent, ${color} 30%, ${color} 70%, transparent)`;
	}};
`;

const TaperedLine = ({
	className,
	color,
}) => (
	<div className={className}>
		<TaperedLineSpan
			color={color}
		/>
	</div>
);

TaperedLine.displayName = 'TaperedLine';

TaperedLine.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
};

export default TaperedLine;
