import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MultipleProductImages from './MultipleProductImages';
import SingleProductImage from './SingleProductImage';

const ProductImagesDiv = styled.div`
	justify-self: center;
`;

const ProductImages = ({
	className,
	horizontalImageAlt,
	horizontalImageUrl,
	verticalImage2Alt,
	verticalImage2Url,
	verticalImageAlt,
	verticalImageUrl,
}) => {
	if (!horizontalImageUrl && !verticalImageUrl && !verticalImage2Url) return null;

	return (
		<ProductImagesDiv className={className}>
			{!!horizontalImageUrl && (
				<SingleProductImage
					horizontalImageAlt={horizontalImageAlt}
					horizontalImageUrl={horizontalImageUrl}
				/>
			)}
			{(!!verticalImageUrl && !!verticalImage2Url) && (
				<MultipleProductImages
					verticalImage2Alt={verticalImage2Alt}
					verticalImage2Url={verticalImage2Url}
					verticalImageAlt={verticalImageAlt}
					verticalImageUrl={verticalImageUrl}
				/>
			)}
		</ProductImagesDiv>
	);
};

ProductImages.displayName = 'ProductImages';

ProductImages.propTypes = {
	className: PropTypes.string,
	horizontalImageAlt: PropTypes.string,
	horizontalImageUrl: PropTypes.string,
	verticalImage2Alt: PropTypes.string,
	verticalImage2Url: PropTypes.string,
	verticalImageAlt: PropTypes.string,
	verticalImageUrl: PropTypes.string,
};

export default ProductImages;