import deep from 'deep-get-set';

import defaultState from './defaultState';
import * as ACTIONS from '../actions/actionTypes';
import { isReceiveEntitiesAction } from '../actions/entity.actions';
import { BOOK_COVER_LAYOUTS } from '../const/bookCoverLayouts.const';
import { ENTITIES } from '../const/entities/entities';
import { REDUCERS } from '../const/reducers.const';
import { VIEW } from '../const/view.const';
import { receiveGroupExcludedMomentsPage } from '../model/groupExcludedMomentsPage.model';
import { receiveEntityObjects, receiveEntityArrayReplacement } from '../helpers/entity/entity.helpers';

export default (state = defaultState[REDUCERS.GROUPS], action) => {
	const { type } = action;

	if (isReceiveEntitiesAction(type)) {
		const { data } = action;

		return {
			...state,
			...receiveEntityArrayReplacement(state, data, ENTITIES.BOOK_COVER_COLOR_IDS),
			...receiveEntityArrayReplacement(state, data, ENTITIES.COVER_BUNDLE_IDS),
			...receiveEntityArrayReplacement(state, data, ENTITIES.DATA_SOURCE_IDS),
			...receiveEntityArrayReplacement(state, data, ENTITIES.GROUP_IDS),
			...receiveEntityObjects(state, data, ENTITIES.BOOK_COVER_COLOR),
			...receiveEntityObjects(state, data, ENTITIES.COLLAGE_LAYOUT_IDS_BY_BOOK_SIZE),
			...receiveEntityObjects(state, data, ENTITIES.COLLAGE_LAYOUT),
			...receiveEntityObjects(state, data, ENTITIES.COLLAGE_MEDIA),
			...receiveEntityObjects(state, data, ENTITIES.COLLAGE),
			...receiveEntityObjects(state, data, ENTITIES.COVER_BUNDLE),
			...receiveEntityObjects(state, data, ENTITIES.COVER_CROP),
			...receiveEntityObjects(state, data, ENTITIES.COVER_TEMPLATE),
			...receiveEntityObjects(state, data, ENTITIES.CROP_RECT),
			...receiveEntityObjects(state, data, ENTITIES.CURRENCY_MARKET),
			...receiveEntityObjects(state, data, ENTITIES.CURRENCY),
			...receiveEntityObjects(state, data, ENTITIES.DATA_SOURCE_MEDIA_PAGE),
			...receiveEntityObjects(state, data, ENTITIES.DATA_SOURCE_MEDIA),
			...receiveEntityObjects(state, data, ENTITIES.DATA_SOURCE),
			...receiveEntityObjects(state, data, ENTITIES.DUPLO),
			...receiveEntityObjects(state, data, ENTITIES.EXCLUDED_MOMENTS_DATE_INFO),
			...receiveEntityObjects(state, data, ENTITIES.EXCLUDED_MOMENTS_PAGE),
			...receiveEntityObjects(state, data, ENTITIES.GROUP_COVER_BUNDLE),
			...receiveEntityObjects(state, data, ENTITIES.GROUP_INVITE_CODE),
			...receiveEntityObjects(state, data, ENTITIES.GROUP_ORDER_CALCULATED_SHIPPING_OPTION),
			...receiveEntityObjects(state, data, ENTITIES.GROUP_ORDER_CALCULATED_SHIPPING_OPTIONS_RESPONSE),
			...receiveEntityObjects(state, data, ENTITIES.GROUP_SERIES_PRICE),
			...receiveEntityObjects(state, data, ENTITIES.GROUP_SUBSCRIPTION_ORDERS),
			...receiveEntityObjects(state, data, ENTITIES.GROUP_SUBSCRIPTIONS_RESPONSE),
			...receiveEntityObjects(state, data, ENTITIES.GROUP),
			...receiveEntityObjects(state, data, ENTITIES.LOCAL_MEDIA_FILE),
			...receiveEntityObjects(state, data, ENTITIES.MEDIA),
			...receiveEntityObjects(state, data, ENTITIES.ORDER_CALCULATED_SHIPPING_OPTION),
			...receiveEntityObjects(state, data, ENTITIES.ORDER_SHIPPING_OPTION),
			...receiveEntityObjects(state, data, ENTITIES.ORDER),
			...receiveEntityObjects(state, data, ENTITIES.PERSON),
			...receiveGroupExcludedMomentsPage(state, data),
		};
	}

	switch (type) {
		case ACTIONS.COLLAGE_RECEIVE_MEDIA: {
			const updatedMedia = deep(state, 'collageMedia') || {};
			const intialCollage = deep(state, 'collage') || {};
			const collageId = deep(action, 'collageId');
			const media = deep(action, 'media') || [];
			const mediaIndexArray = [];

			media.forEach((collage) => {
					updatedMedia[collage.id] = collage;
					mediaIndexArray.push(collage.id);
				});

			return {
				...state,
				[ENTITIES.COLLAGE]: {
					...state[ENTITIES.COLLAGE],
					[collageId]: {
						...intialCollage[collageId],
						media: mediaIndexArray
					}
				},
				[ENTITIES.COLLAGE_MEDIA]: {
					...state[ENTITIES.COLLAGE_MEDIA],
					...updatedMedia,
				},
			};
		}
		case ACTIONS.COLLAGE_ADD_REPLACEMENT_IMAGE: {
			const activeCollageMedia = deep(action, 'activeCollageMedia');
			const activeMomentId = deep(action, `${VIEW.ACTIVE_MOMENT_ID}`);
			const collageMedia = deep(action, 'collageMedia');

			const newCollageMedia = {};

			const activeCollageMediaMap = activeCollageMedia.reduce((accumulator, media) => ({
				...accumulator,
				[media.id]: media
			}), {});

			collageMedia.forEach((media) => {
				if (!activeCollageMediaMap[media.id] && !newCollageMedia[media.id]) {
					newCollageMedia[media.id] = media;
				}
			});

			const newMediaIdList = collageMedia
				.sort((a, b) => a.index - b.index)
				.map((media) => media.id);

			const newCollage = {
				...deep(state, `${ENTITIES.COLLAGE}`),
				[activeMomentId]: {
					...deep(state, `${ENTITIES.COLLAGE}.${activeMomentId}`),
					media: newMediaIdList,
				}
			};

			return {
				...state,
				collage: newCollage,
				collageMedia: {
					...deep(state, `${ENTITIES.COLLAGE_MEDIA}`),
					...newCollageMedia,
				},
			};
		}
		case ACTIONS.COLLAGE_DELETE_MEDIA: {
			const id = deep(action, 'id');
			const momentId = deep(action, 'momentId');

			const newCollage = { ...state.collage };
			if (newCollage[momentId]) {
				const filteredMedia = newCollage[momentId].media.filter((mediaMediaId) => mediaMediaId !== id);

				if (filteredMedia.length === 0) {
					delete newCollage[momentId];
				} else {
					newCollage[momentId].media = filteredMedia;
				}
			}

			const newCollageMedia = { ...state.collageMedia };
			if (newCollageMedia[id]) {
				delete newCollageMedia[id];
			}

			return {
				...state,
				collage: newCollage,
				collageMedia: newCollageMedia,
			};
		}

		case ACTIONS.UPDATE_GROUP_CUSTOM_COVER_TEMPLATE: {
			const groupId = deep(action, 'groupId');
			const customCoverTemplateId = deep(action, 'customCoverTemplateId');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						customCoverTemplateId,
					},
				},
			};
		}

		case ACTIONS.UPDATE_GROUP_IS_DISPLAY_CAPTIONS: {
			const groupId = deep(action, 'groupId');
			const isDisplayCaptionOn = deep(action, 'isOn');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						isDisplayCaptionOn,
					}
				}
			};
		}

		case ACTIONS.UPDATE_GROUP_IS_DISPLAY_DATE: {
			const groupId = deep(action, 'groupId');
			const isDisplayDateOn = deep(action, 'isOn');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						isDisplayDateOn,
					}
				}
			};
		}

		case ACTIONS.UPDATE_GROUP_IS_DISPLAY_FRONT_TITLE: {
			const groupId = deep(action, 'groupId');
			const isDisplayFrontTitleOn = deep(action, 'isOn');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						isDisplayFrontTitleOn,
					}
				}
			};
		}

		case ACTIONS.UPDATE_GROUP_IS_DISPLAY_LOCATION: {
			const groupId = deep(action, 'groupId');
			const isDisplayLocationOn = deep(action, 'isOn');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						isDisplayLocationOn,
					}
				}
			};
		}

		case ACTIONS.UPDATE_GROUP_IS_DISPLAY_VOLUME_NUMBER: {
			const groupId = deep(action, 'groupId');
			const isDisplayVolumeNumberOn = deep(action, 'isOn');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						isDisplayVolumeNumberOn,
					}
				}
			};
		}

		case ACTIONS.UPDATE_GROUP_IS_HARDCOVER: {
			const groupId = deep(action, 'groupId');
			const isHardcover = deep(action, 'isHardcover');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						isHardcover,
					}
				}
			};
		}

		case ACTIONS.BOOK_LAYOUT_OPTIONS_RECEIVE: {
			const id = deep(action, 'id');
			const object = deep(action, 'object') || {};

			return {
				...state,
				bookLayoutOptions: {
					...state.bookLayoutOptions,
					[id]: object,
				},
			};
		}

		case ACTIONS.UPDATE_BOOK_COVER_LAYOUT_VERSION: {
			const groupId = deep(action, 'groupId');
			const coverColorId = deep(action, 'coverColorId');

			return {
				...state,
				bookLayoutOptions: {
					...state.bookLayoutOptions,
					[groupId]: {
						...deep(state, `bookLayoutOptions.${groupId}`),
						coverLayoutVersion: BOOK_COVER_LAYOUTS.UPGRADED,
						coverColorId,
					},
				},
			};
		}

		case ACTIONS.UPDATE_GROUP_REMOVE_PERSON: {
			const groupId = deep(action, 'groupId');
			const group = deep(state, `groups.${groupId}`);

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...group,
						people: group.people.filter((personId) => personId !== action.personId),
					}
				}
			};
		}

		case ACTIONS.UPDATE_BOOK_COVER_COLOR_ID: {
			const groupId = deep(action, 'groupId');
			const coverColorId = deep(action, 'coverColorId');

			return {
				...state,
				bookLayoutOptions: {
					...state.bookLayoutOptions,
					[groupId]: {
						...deep(state, `bookLayoutOptions.${groupId}`),
						coverColorId,
					},
				},
			};
		}

		case ACTIONS.UPDATE_GROUP_BOOK_SIZE: {
			const groupId = deep(action, 'groupId');
			const bookSize = deep(action, 'bookSize');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						bookSize,
					}
				}
			};
		}

		case ACTIONS.UPDATE_GROUP_TITLE: {
			const groupId = deep(action, 'groupId');
			const title = deep(action, 'title');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...deep(state, `groups.${groupId}`),
						title,
					}
				}
			};
		}

		case ACTIONS.GROUP_COVER_BUNDLE_REMOVED: {
			const newCoverBundles = deep(state, `${ENTITIES.GROUP_COVER_BUNDLE}`);
			const groupId = deep(action, 'groupId');

			for (const coverBundleId in newCoverBundles) {
				const coverBundle = newCoverBundles[coverBundleId];

				if (coverBundle.groupId === groupId) {
					delete newCoverBundles[coverBundle.id];
					break;
				}
			}

			return {
				...state,
				[ENTITIES.GROUP_COVER_BUNDLE]: newCoverBundles,
			};
		}

		case ACTIONS.GROUP_COVER_BUNDLE_UPDATE: {
			const groupId = deep(action, 'groupId');
			const activeCoverBundleId = deep(action, 'activeCoverBundleId');

			return {
				...state,
				groups: {
					...state.groups,
					[groupId]: {
						...state.groups[groupId],
						activeCoverBundleId,
					}
				}
			};
		}

		case ACTIONS.GROUP_FILE_UPLOADED: {
			const fileId = deep(action, 'fileId');

			return {
				...state,
				[ENTITIES.LOCAL_MEDIA_FILE]: {
					...state[ENTITIES.LOCAL_MEDIA_FILE],
					[fileId]: {
						...state[ENTITIES.LOCAL_MEDIA_FILE][fileId],
						isUploaded: true,
					},
				}
			}
		}

		default:
			return state;
	}
};
