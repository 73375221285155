import React from 'react';
import PropTypes from 'prop-types';

import BoxStacked from '../BoxStacked/BoxStacked';
import RadioInput from '../RadioInput/RadioInput';

const RadioInputBoxesStacked = ({ items }) => items.map((item, index) => {
	const { checked, children, disabled, id, name, onBlur, onChange, value, label } = item;

	return <BoxStacked
		key={id}
		length={items.length}
		index={index}
	>
		<RadioInput
			className="stacked-input"
			checked={checked}
			disabled={disabled}
			id={id}
			name={name}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			label={label}
			children={children}
		/>
	</BoxStacked>
});

RadioInputBoxesStacked.displayName = 'RadioInputBoxesStacked';

RadioInputBoxesStacked.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		checked: PropTypes.bool,
		className: PropTypes.string,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string,
		label: PropTypes.node,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}))
};

export default RadioInputBoxesStacked