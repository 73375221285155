import { createSelector } from 'reselect';

import { selectUserLanguage } from './user.selectors';
import { selectDefaultLanguage } from './config.selectors';

export const selectCurrentLang = createSelector(
	selectUserLanguage,
	selectDefaultLanguage,
	(userLanguage, defaultLanguage) => userLanguage || defaultLanguage
);
