import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ModalHeader.css';

const ModalHeader = ({
	className: classNameProps,
	children
}) => (
	<div className={classnames('chatponent-modal-header', classNameProps)}>
		{children && children}
	</div>
);

ModalHeader.displayName = 'ModalHeader';

ModalHeader.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default ModalHeader;
