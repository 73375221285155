import config from '../helpers/config';

if (!process.env.SERVER && config.isProduction()) {
	/* eslint-disable */
	(function(i, s, o, g, r, a, m) {
		i['GoogleAnalyticsObject'] = r;
		(i[r] =
			i[r] ||
			function() {
				(i[r].q = i[r].q || []).push(arguments);
			}),
			(i[r].l = 1 * new Date());
		(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
		a.async = 1;
		a.src = g;
		m.parentNode.insertBefore(a, m);
	})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
	window.ga('create', 'UA-48699703-1', 'auto');
	window.ga('create', 'UA-48699703-6', 'auto', 'combinedTracker');
	window.ga('combinedTracker.require', 'ecommerce');
	window.ga('allPlatforms.require', 'ecommerce');
	/* eslint-enable */
}

/**
 * Sends a Google Analytics 'pageview' event.
 *
 * @param {object} options Some options to set before sending the 'pageview' event.
 */
export const sendPageView = ({ location, page, title } = {}) => {
	if (config.isProduction()) {
		if (location) window.ga('set', 'location', location);
		if (page) window.ga('set', 'page', page);
		if (title) window.ga('set', 'title', title);
		window.ga('send', 'pageview');
	}
};
