import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MaybeLinkWrap from '../MaybeLinkWrap/MaybeLinkWrap';
import './Breadcrumb.css';

export const Breadcrumb = ({
	analyticEvent,
	analyticObj,
	index,
	isBold,
	length,
	linkCallback,
	title,
	to
}) => {
	if (!title) return null;

	return (
		<li className={classnames([
			'Breadcrumb',
			{ 'is-bold': !!isBold },
			{ 'is-last': index === length - 1 }
		])}>
			<MaybeLinkWrap
				analyticEvent={analyticEvent}
				analyticObj={analyticObj}
				linkCallback={linkCallback}
				path={to}
			>{title}</MaybeLinkWrap>
		</li>
	);
};

Breadcrumb.propTypes = {
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	index: PropTypes.number,
	isBold: PropTypes.bool,
	length: PropTypes.number,
	linkCallback: PropTypes.func,
	title: PropTypes.string,
	to: PropTypes.string
};

export default Breadcrumb;
