export const SHIPPING_METHODS = {
	STANDARD: '0',
	PREMIUM: '1',
	EXPRESS: '2',
};

export const SHIPPING_METHOD_ID_TO_ANALYTICS_VALUE_MAP = {
	[SHIPPING_METHODS.STANDARD]: 'standard',
	[SHIPPING_METHODS.PREMIUM]: 'premium',
	[SHIPPING_METHODS.EXPRESS]: 'express',
};