import React from 'react';
import defaultIconProps from './_defaultIconProps';
import iconProps from './_iconProps';
import Svg from './Svg';

export const IconCheckmarkCircledTwoColors = (props) => (
	<Svg
		{...props}
		viewBox="0 0 24 24"
		name="checkmark-circled"
	>
		<path
			d="M12 24C18.5799 24 24 18.5825 24 12.0057C24 5.42898 18.5684 0 12 0C5.4201 0 0 5.42898 0 12.0057C0 18.5825 5.4201 24 12 24Z"
			fill="inherit"
		/>
		<path
			d="M9.25552 17.4692C9.60002 17.8709 9.97896 18.089 10.4498 18.089C10.9091 18.089 11.3225 17.8594 11.6096 17.4347L17.4316 8.45911C17.6038 8.18364 17.7416 7.87375 17.7416 7.59828C17.7416 6.93257 17.1445 6.48494 16.5129 6.48494C16.111 6.48494 15.755 6.70301 15.4794 7.15065L10.4153 15.208L7.78566 11.9484C7.4871 11.5925 7.17705 11.4319 6.78662 11.4319C6.14356 11.4319 5.61533 11.9484 5.61533 12.6141C5.61533 12.924 5.71867 13.2224 5.95982 13.4978L9.25552 17.4692Z"
			fill={props.color2}
		/>
	</Svg>
);

IconCheckmarkCircledTwoColors.displayName = 'IconCheckmarkCircledTwoColors';

IconCheckmarkCircledTwoColors.propTypes = iconProps;

IconCheckmarkCircledTwoColors.defaultProps = {
	...defaultIconProps,
	color2: '#FFFFFF',
};

export default IconCheckmarkCircledTwoColors;
