import deep from 'deep-get-set';
import { useEffect } from 'react';

import { noop } from '../helpers/functions/functions';
import { parseHash, queryStringToJson } from '../helpers/locationUtils';
import { O_AUTH_2_FAILURE_CALLBACK, O_AUTH_2_SUCCESS_CALLBACK } from '../services/oauth2/oauth2';

export function useOAuth2({
	getAccessToken,
	handleWindowDidNotClose,
	location,
}) {
	useEffect(() => {
		const search = deep(location, 'search') || '';
		const params = queryStringToJson(search);

		const hash = deep(location, 'hash') || '';
		const hashParams = parseHash(hash);

		const authParams = {
			...params,
			...hashParams,
		};

		const handleAuthFailure = (data, error) => {
			const callback = deep(window, `opener.${O_AUTH_2_FAILURE_CALLBACK}`) || noop;
			callback(data, error);
			window && window.close();
		};

		const handleAuthSuccess = (token) => {
			const callback = deep(window, `opener.${O_AUTH_2_SUCCESS_CALLBACK}`) || noop;
			callback(token);
			window && window.close();

			const intentActionUri = authParams.state;

			if (intentActionUri && handleWindowDidNotClose) handleWindowDidNotClose(token, intentActionUri);
		};

		if (authParams.error) {
			handleAuthFailure(authParams);
		} else if (authParams.code || authParams.access_token) {
			getAccessToken(authParams)
				.then(handleAuthSuccess)
				.catch((error) => handleAuthFailure(authParams, error));
		} else {
			window && window.close();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
