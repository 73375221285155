import React from 'react';
import PropTypes from 'prop-types';

const Space = ({
	space
}) => (
	<div
		className="duplo-block duplo-block-space"
		style={{ height: `${space}px` }}
	/>
);

Space.propTypes = {
	space: PropTypes.number,
};

export default Space;
