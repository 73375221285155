import deep from 'deep-get-set';
import { schema } from 'normalizr';

import { ENTITIES } from '../const/entities/entities';

export const countryFromJson = (json = {}) => ({
		code: deep(json, 'Code'),
		name: deep(json, 'Name'),
		postalCodeFieldName: deep(json, 'PostalCodeFieldName'),
		postalCodeFieldRequired: deep(json, 'PostalCodeFieldRequired'),
		postalCodeFormatExplainer: deep(json, 'PostalCodeFormatExplainer'),
		postalCodeRegex: deep(json, 'PostalCodeRegex'),
		regionFieldName: deep(json, 'RegionFieldName'),
		regionFieldRequired: deep(json, 'RegionFieldRequired'),
		regions: deep(json, 'Regions'),
});

export const countrySchema = new schema.Entity(ENTITIES.COUNTRY, {}, {
	idAttribute: 'code'
});