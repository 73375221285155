import React from 'react';
import PropTypes from 'prop-types';

import Badge from './Badge';
import { convertActionUri } from '../DuploBlock.helpers';
import Wrapper from '../Wrapper/Wrapper';
import MaybeLinkWrap from '../../MaybeLinkWrap/MaybeLinkWrap';
import './Badges.css';

const Badges = ({
	actionUri,
	analyticEvent,
	analyticObj,
	badges,
}) => (
	<Wrapper className="duplo-block duplo-block-badges">
		<MaybeLinkWrap
			analyticEvent={analyticEvent}
			analyticObj={analyticObj}
			path={actionUri && convertActionUri(actionUri)}
		>
			<ul>
				{badges.map((badge, index) => (
					<Badge
						key={index}
						{...badge}
					/>
				))}
			</ul>
		</MaybeLinkWrap>
	</Wrapper>
);

Badges.propTypes = {
	actionUri: PropTypes.string,
	analyticEvent: PropTypes.func,
	analyticObj: PropTypes.object,
	badges: PropTypes.array,
};

export default Badges;
