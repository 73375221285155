import React from 'react';
import PropTypes from 'prop-types';

import Bullet from './Bullet';
import Wrapper from '../Wrapper/Wrapper';
import './LargeBullets.css';

const LargeBullets = ({
	largeBullets,
}) => (
	<Wrapper className="duplo-block duplo-block-large-bullets">
		<ul>
			{largeBullets.map((bullet, index) => (
				<Bullet
					key={index}
					index={index}
					{...bullet}
				/>
			))}
		</ul>
	</Wrapper>
);

LargeBullets.propTypes = {
	largeBullets: PropTypes.array,
};

export default LargeBullets;
